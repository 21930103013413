import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './../App.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function AboutUs() {
  return (
    <React.Fragment>
      <CssBaseline />

      <Navbar transparentTop={true} />
      <main style={{ paddingBottom: "unset" }}>
        {/* Hero unit */}
        <Container className={'banner'}>
          <img src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header-aboutus.png" style={{ width: "100%", height: "570px" }} />
          <div></div>
          <Row className={'content-aboutus'}>
            <Typography
              align="center"
              fontSize={'80px'}
              fontWeight={'bold'}
              color={'white'}
              textTransform={'uppercase'}
            >
              About Us
            </Typography>
            <Typography
              align="center"
              fontSize={'20px'}
              color={'white'}
            >
              Tokket is a gamified social platform that blends social media, games, knowledge, and rewards.
            </Typography>
          </Row>

        </Container>
        <Container>
          <Row style={{marginBottom: "5px"}}>
            <Col md={6} xs={12}>
              <img src="https://tokketcontent.blob.core.windows.net/tokketweb/about1.jpg" style={{ width: "100%" }} alt="" />
            </Col>
            <Col md={6} xs={12} style={{ display: "flex", alignItems: "center" }}>
              <div className={'detail-info-panel'}>
                <h4 style={{ fontSize: "45px", fontWeight: "bold" }}>Mission</h4>
                <div style={{ fontSize: "16px" }}>
                Our mission is to make the world more positive and productive. We want to encourage positive and meaningful conversations. Our goal is to create apps and games that are not only fun, but you can also learn and grow from.
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{marginBottom: "5px"}}>
            <Col md={{ span: 7, order: 2 }} xs={12}>
              <img src="https://tokketcontent.blob.core.windows.net/tokketweb/about2.jpg" style={{ width: "100%" }} alt="" />
            </Col>
            <Col md={{ span: 5, order: 1 }} xs={12} style={{ display: "flex", alignItems: "center" }}>
              <div className={'detail-info-panel'}>
                <h4 style={{ fontSize: "45px", fontWeight: "bold" }}>Making Studying Fun</h4>
                <div style={{ fontSize: "16px" }}>
                Students spend a lot of time on social media and games but not a lot of time studying. By combining school work with online activities they enjoy doing, students will have more fun studying thus spend more time studying. They can socially interact with their classmates on their school work and play with the knowledge and information they have to learn.
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{marginBottom: "5px"}}>
            <Col md={6} xs={12}>
              <img src="https://tokketcontent.blob.core.windows.net/tokketweb/about3.jpg" style={{ width: "100%" }} alt="" />
            </Col>
            <Col md={6} xs={12} style={{ display: "flex", alignItems: "center" }}>
              <div className={'detail-info-panel'}>
                <h4 style={{ fontSize: "45px", fontWeight: "bold" }}>Giving Virtual Rewards</h4>
                <div style={{ fontSize: "16px" }}>
                Tokket will go further and reward you with virtual currency. There will be interesting and useful virtual items to spend it on!
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>
  );
}

export default AboutUs; 