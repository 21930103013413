import React from 'react'
import Typography from "@mui/material/Typography";
import '../styles.css'
import './css/TokView.css';
import './css/AddClassTok.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactionContainer from './ReactionContainer';
import Comments from './Comments';
import Button from 'react-bootstrap/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { addSection, getClassTok, getSections, patchSection, patchTok, updateClassTok } from '../services/classServices';
import { getSetUserId, getToken, getUser } from '../services/accountServices';
import { deleteClassTok, deleteSection, updateSection, addClassTok, getGroups } from '../services/classServices';
import Modal from 'react-bootstrap/Modal';
import WaitSpinner from './WaitSpinner';
import { faTimes, faImage, faCaretDown, faArrowLeft, faLock, faRefresh, faArrowDown, faAngleDown, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Cropper from "react-cropper";
import "./css/cropper.css";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { convertRelative, extractYoutubeID, isYoutubeLink, textDisplayFormat, textDisplayReverse, unique } from '../services/helperTools';
import { createRef } from 'react';

import Subfeed from './Subfeed';

import { upperCasesFirstLetter } from '../services/helperTools';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Moment from 'moment';
import { textDisplay } from '../services/helperTools';

import TokBack from './TokBack';

import YouTube from 'react-youtube';
import AddClassTok from './AddClassTok';
import ProfileView from './ProfileView';

import { Box } from '@mui/system';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from '@mui/material/IconButton';
import $ from "jquery";
import { clone, cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import { Avatar, Link } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import ReportItem from './ReportItem';
import ReactSizeResponce from './ReactSizeResponce';
import ClassTokIcon from './ClassTokIcon';
import { Tok } from '../interfaces/TokInterface';
import { Qna, Section } from '../interfaces/SectionInterface';
import { Group } from '../interfaces/GroupInterface';
import { TokUser } from '../interfaces/TokUserInterface';
const channels = [
    {
        level: "Family & Friends", ref: "familyfriends", status: 1, next: [
            { level: "Accomplishments", ref: "accomplishments", status: 2 },
            { level: "Anniversaries", ref: "anniversaries", status: 2 },
            { level: "Birthday", ref: "birthday", status: 2 },
            { level: "Children", ref: "children", status: 2 },
            { level: "Faith", ref: "faith", status: 2 },
            { level: "Food", ref: "food", status: 2 },
            { level: "Graduation", ref: "graduation", status: 2 },
            { level: "Help", ref: "help", status: 2 },
            { level: "Job", ref: "job", status: 2 },
            { level: "Memoriam", ref: "memoriam", status: 2 },
            { level: "New Home", ref: "newhome", status: 2 },
            { level: "Pets", ref: "pets", status: 2 },
            { level: "School", ref: "school", status: 2 },
            { level: "Travel", ref: "travel", status: 2 },
            { level: "Social Events", ref: "socialevents", status: 2 }
        ]
    },

    {
        level: "Health", ref: "health", status: 1, next: [
            { level: "Medical", ref: "medical", status: 2 },
            { level: "Mental", ref: "mental", status: 2 },
            { level: "Nutritional", ref: "nutritional", status: 2 },
            { level: "Physical", ref: "physical", status: 2 },
            { level: "Spiritual", ref: "spiritual", status: 2 }
        ]
    },

    {
        level: "Spiritual", ref: "spiritual", status: 2, next: [
            { level: "Buddhism", ref: "buddhism", status: 3 },
            { level: "Christianity", ref: "christianity", status: 3 },
            { level: "Hinduism", ref: "hinduism", status: 3 },
            { level: "Islam", ref: "islam", status: 3 },
            { level: "Judaism", ref: "judaism", status: 3 },
            { level: "New Age", ref: "newage", status: 3 }
        ]
    },
    {
        level: "How To", ref: "howto", status: 1, next: [
            { level: "Sports", ref: "sports", status: 2 },
        ]
    },
    {
        level: "Sports", ref: "sports", status: 2, next: [
            { level: "Basketball", ref: "basketball", status: 3 },
            { level: "Pickleball", ref: "pickleball", status: 3 }
        ]
    },
    {
        level: "Technology", ref: "technology", status: 1, next: [
            { level: "Artificial Intelligence (AI)", ref: "artificialintelligence", status: 2 },
        ]
    },
    { level: "Interpersonal", ref: "interpersonal", status: 1 },
    { level: "World", ref: "world", status: 1 },
]
const MySwal = withReactContent(Swal);

interface Channel {
    level: string;
    ref: string;
    status: number;
    next?: Channel[];
}

interface States {
    tok: Tok;
    GroupId: string;
    tokenSection: string;
    sectionLimit: number;
    deletingClasstok: string;
    sectionLoader: boolean;
    viewSection: boolean;
    targetSection: null | Section;
    editSection: boolean;
    editSectionShow: boolean;
    relativeDate: string;
    CropperShow: boolean;
    CropperSrc: string;
    CopyTokShow: boolean;
    CopyTokShowMode: string;
    CopyTokLevel1: string;
    CopyTokLevel2: string;
    CopyTokLevel2List: Channel[];
    CopyTokLevel3: string;
    CopyTokLevel3List: Channel[];
    GroupsCopy: Group[];
    GroupsCopyLoader: boolean;
    GroupCopyId: string;
    TokBackActive: boolean;
    userid: string;
    UserView: boolean;
    UserInfo: TokUser;
    GoToTok: Tok;
    GoToTokZIndex: number;
    CommunityLoader: boolean;
    Communities: Group[];
    CommunityCopyId: string;
    CopyTokQnaShow: boolean;
    CopyTokQnaSections: Section[];
    CopyTokQnaSet: boolean;
    CopyRefreshView: boolean;
    AnswerQuestionList: Qna[];
    items: string;
    oldUiActive: boolean;
    mobileView: boolean;
    show: boolean;
}

interface Props {
    tok: Tok;
    groupId: string;
    linkZIndex: number;
    items: string;
    deleteTok: (data: Tok) => void;
    updateTok: (data: Tok) => void;
}

class TokView extends React.Component<Props, States> {
    cropperRef: React.RefObject<any>;
    ReactionInaccurateContainer: React.RefObject<any>;
    CommentCounterRef: React.RefObject<any>;
    addTokBtnRef: React.RefObject<any>;
    reportItemRef: React.RefObject<any>;
    commentSwitchUiRef: React.RefObject<any>;
    reactionSwitchUiRef: React.RefObject<any>;
    constructor(props) {
        let tok = props.tok ? props.tok : {};
        super(props);
        var dateToday = new Date();
        this.state = {
            tok: tok,
            GroupId: props.groupId ? props.groupId : "",
            tokenSection: "",
            sectionLimit: 0,
            deletingClasstok: "",
            sectionLoader: false,
            viewSection: false,
            targetSection: null,
            editSection: false,
            editSectionShow: true,
            relativeDate: tok["created_time"] ? convertRelative(tok.created_time) != "undefineds" ? Moment(tok.created_time).format('M/DD/YYYY') : Moment(dateToday).format('M/DD/YYYY') : Moment(dateToday).format('M/DD/YYYY'),
            CropperShow: false,
            CropperSrc: "",
            CopyTokShow: false,
            CopyTokShowMode: "",
            CopyTokLevel1: "",
            CopyTokLevel2: "",
            CopyTokLevel2List: [],
            CopyTokLevel3: "",
            CopyTokLevel3List: [],
            GroupsCopy: [],
            GroupsCopyLoader: false,
            GroupCopyId: "",
            TokBackActive: false,
            userid: "",
            UserView: false,
            UserInfo: {} as TokUser,
            GoToTok: {} as Tok,
            GoToTokZIndex: props.linkZIndex ? props.linkZIndex + 1 : 1500,
            CommunityLoader: false,
            Communities: new Array,
            CommunityCopyId: "",
            CopyTokQnaShow: false,
            CopyTokQnaSections: new Array,
            CopyTokQnaSet: false,
            CopyRefreshView: false,
            AnswerQuestionList: [],
            items: props.items ? props.items : "",
            oldUiActive: false,
            mobileView: false,
            show: false,
        };
        this.deleteTok = this.deleteTok.bind(this);
        this.getSections = this.getSections.bind(this);
        this.showHideAnswer = this.showHideAnswer.bind(this);

        this.viewSection = this.viewSection.bind(this);
        this.closeModals = this.closeModals.bind(this);
        this.showHideAnswerTargetSection = this.showHideAnswerTargetSection.bind(this);

        this.setEditSection = this.setEditSection.bind(this);
        this.setEditSectionValues = this.setEditSectionValues.bind(this);
        this.setEditSectionQnaValues = this.setEditSectionQnaValues.bind(this);
        this.setEditSectionAddQna = this.setEditSectionAddQna.bind(this);
        this.confirmEditSection = this.confirmEditSection.bind(this);

        this.deleteSection = this.deleteSection.bind(this);

        this.showHideEditSection = this.showHideEditSection.bind(this);

        this.cropperRef = createRef();
        this.handleCropperClose = this.handleCropperClose.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.onImageSet = this.onImageSet.bind(this);

        this.copyTokShow = this.copyTokShow.bind(this);
        this.setCopyTokLevel = this.setCopyTokLevel.bind(this);
        this.copyTokConfirm = this.copyTokConfirm.bind(this);
        this.getGroupsCopy = this.getGroupsCopy.bind(this);
        this.setCopyGroup = this.setCopyGroup.bind(this);

        this.ReactionInaccurateContainer = createRef();
        this.CommentCounterRef = createRef();

        this.setTokBack = this.setTokBack.bind(this);
        this.closeTokBack = this.closeTokBack.bind(this);

        this.addTokBtnRef = createRef();
        this.reportItemRef = createRef();
        this.editTok = this.editTok.bind(this);
        this.updateTok = this.updateTok.bind(this);

        this.goToTok = this.goToTok.bind(this);

        this.viewProfileSet = this.viewProfileSet.bind(this);
        this.closeProfile = this.closeProfile.bind(this);

        this.updateTokLink = this.updateTokLink.bind(this);
        this.deleteTokLink = this.deleteTokLink.bind(this);
        this.closeGoToTok = this.closeGoToTok.bind(this);

        this.viewProfileUser = this.viewProfileUser.bind(this);

        this.setTokCopy = this.setTokCopy.bind(this);

        this.getCopyCommunities = this.getCopyCommunities.bind(this);
        this.setCopyCommunity = this.setCopyCommunity.bind(this);

        this.updatePostedTo = this.updatePostedTo.bind(this);

        this.setCopySectionAnswer = this.setCopySectionAnswer.bind(this);
        this.copyQnaTok = this.copyQnaTok.bind(this);

        this.refreshCopyOptions = this.refreshCopyOptions.bind(this);

        this.answerEnabled = this.answerEnabled.bind(this);
        this.addGroupAnswersSet = this.addGroupAnswersSet.bind(this);
        this.createAnswers = this.createAnswers.bind(this);

        this.getAnswers = this.getAnswers.bind(this);
        this.showHideAnswerQuestion = this.showHideAnswerQuestion.bind(this);

        this.bookmarkTok = this.bookmarkTok.bind(this);
        this.createSections = this.createSections.bind(this);

        this.reportTok = this.reportTok.bind(this);

        this.listSectionScroll = this.listSectionScroll.bind(this);

        this.toggleUi = this.toggleUi.bind(this);
        this.commentSwitchUiRef = createRef();
        this.reactionSwitchUiRef = createRef();

        this.sizeResponse = this.sizeResponse.bind(this);
    }
    sizeResponse(e) {
        const { mobileView } = this.state;
        if (mobileView != e) {
            try {
                this.reactionSwitchUiRef.current.setAttribute("data-mode", "mobile");
                this.reactionSwitchUiRef.current.setAttribute("data-value", e);
                this.reactionSwitchUiRef.current.click();
            } catch (err) { }
            try {
                this.commentSwitchUiRef.current.setAttribute("data-mode", "mobile");
                this.commentSwitchUiRef.current.setAttribute("data-value", e);
                this.commentSwitchUiRef.current.click();
            } catch (err) { }
            this.setState({
                mobileView: e
            });
        }
    }

    toggleUi() {
        var { oldUiActive } = this.state;
        try {
            this.reactionSwitchUiRef.current.setAttribute("data-mode", "oldui");
            this.reactionSwitchUiRef.current.setAttribute("data-value", !oldUiActive);
            this.reactionSwitchUiRef.current.click();
        } catch (err) { }
        try {
            this.commentSwitchUiRef.current.setAttribute("data-mode", "oldui");
            this.commentSwitchUiRef.current.setAttribute("data-value", !oldUiActive);
            this.commentSwitchUiRef.current.click();
        } catch (err) { }
        this.setState({
            oldUiActive: !oldUiActive
        });
    }
    async deleteTok() {
        const { tok, items } = this.state;
        MySwal.fire({
            title: <p>{items != "bookmarks" ? "Are you sure want to delete?" : "Are you sure want to remove from Bookmarks?"}</p>,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333",
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                var bookmarkDeleteMsg;
                if (items != "bookmarks") {
                    MySwal.fire({
                        title: <p>Deleting Tok</p>,
                        allowOutsideClick: false
                    });
                    MySwal.showLoading()
                } else {
                    bookmarkDeleteMsg = toast.loading('Deleting Bookmark', {
                        id: bookmarkDeleteMsg,
                    });
                }
                try {
                    deleteClassTok(tok.id, tok.pk).then(response => {
                        if (items != "bookmarks") {
                            MySwal.hideLoading();
                        }

                        if(response){
                            if (response.data.status_code == 200) {
                                if (items != "bookmarks") {
                                    MySwal.update({
                                        title: 'Deleted!',
                                        icon: "success"
                                    });
                                } else {
                                    toast.success('Deleted', {
                                        id: bookmarkDeleteMsg,
                                    });
                                }
                                try {
                                    this.props.deleteTok(tok);
                                } catch (err) { }
                            } else {
                                if (items != "bookmarks") {
                                    MySwal.update({
                                        title: <p>An Error Occured</p>,
                                        icon: "warning"
                                    });
                                } else {
                                    toast.error('An Error Occured', {
                                        id: bookmarkDeleteMsg,
                                    });
                                }
                            }
                        }
                    }).catch(response => {
                        if (items != "bookmarks") {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        } else {
                            toast.error('An Error Occured', {
                                id: bookmarkDeleteMsg,
                            });
                        }
                    }).finally(() => {
                        if (items != "bookmarks") {
                            MySwal.hideLoading();
                        }
                    })
                } catch (err) {
                    if (items != "bookmarks") {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                        MySwal.hideLoading();
                    } else {
                        toast.error('An Error Occured', {
                            id: bookmarkDeleteMsg,
                        });
                    }
                }
            }
        })

    }

    async componentDidMount() {
        const { tok } = this.state;
        var userid = await getSetUserId();
        const profile = localStorage.getItem("profile");
        var profileJson = {};
        if (profile) {
            profileJson = JSON.parse(profile);
        }
        if (tok.tok_group == "Mega" || tok.tok_group == "Q&a") {
            this.getSections();
        }
        this.setState({
            userid: profileJson["id"] ? profileJson["id"] : userid
        });
    }
    async getSections() {
        const { tok, tokenSection, sectionLimit } = this.state;
        await getToken();
        this.setState({
            sectionLoader: true
        });
        const query = {
            descending: false
        }
        if (tokenSection) {
            query["pagination"] = tokenSection;
        }
        let feedId = tok.id;
        const response = await getSections(query, feedId);
        var tempTok = tok;
        if(response){
            if (response["data"]) {
                if (response["data"]["result"]) {
                    if (sectionLimit == 0) {
                        tempTok.sections = response.data.result;
                    } else {
                        tempTok.sections = tempTok.sections.concat(response.data.result);
                    }
                }
            }
    
            if (tok["tok_group"] && response.data["result"]) {
                if (tok["tok_group"] == "Q&a") {
                    for (var x = 0; x < response.data["result"].length; x++) {
                        let sectionId = response.data["result"][x]["id"];
                        var { AnswerQuestionList } = this.state;
                        AnswerQuestionList[sectionId] = {
                            "loading": true
                        }
                        this.setState({
                            AnswerQuestionList: AnswerQuestionList
                        }, () => {
                            this.getAnswers(sectionId);
                        });
                    }
                }
            }
        }
        this.setState({
            tok: tempTok,
            tokenSection: response["data"]["pagination"] ? response["data"]["pagination"] : "",
            sectionLimit: sectionLimit + 5,
            sectionLoader: false
        });
    }

    getAnswers(id) {
        getSections({}, id).then(res => {
            var { AnswerQuestionList } = this.state;
            if (res["data"]["result"]) {
                let sections = AnswerQuestionList[id] ? AnswerQuestionList[id]["sections"] : new Array;
                let newSections = new Array;
                let getSections = res["data"]["result"];
                if (sections) {
                    for (let x = 0, matchFound = false; x < getSections.length; x++, matchFound = false) {
                        for (let y = 0; y < sections.length; y++) {
                            if (getSections[x]["id"] == sections[y]["id"]) {
                                matchFound = true;
                            }
                        }
                        if (!matchFound) {
                            newSections.push(getSections[x]);
                        }
                    }
                } else {
                    newSections = getSections;
                }
                AnswerQuestionList[id]["sections"] = sections ? sections.concat(newSections) : newSections;
                this.setState({
                    AnswerQuestionList: AnswerQuestionList
                });
            }
        }).catch(err => {
        }).finally(() => {
            var { AnswerQuestionList } = this.state;
            AnswerQuestionList[id]["loading"] = false;
            this.setState({
                AnswerQuestionList: AnswerQuestionList
            });
        })
    }

    showHideAnswerQuestion(e) {
        var { tok } = this.state;
        var sectionindex = e.currentTarget.getAttribute("data-sectionindex");
        var qnaIndex = e.currentTarget.getAttribute("data-qnaindex");
        tok["sections"][sectionindex]["question_answer"][qnaIndex]["show"] = tok["sections"][sectionindex]["question_answer"][qnaIndex]["show"] ? !tok["sections"][sectionindex]["question_answer"][qnaIndex]["show"] : true;
        this.setState({
            tok: tok
        });
    }

    showHideAnswer(e) {
        const { tok } = this.state;
        var section = e.currentTarget.getAttribute("data-section");
        var qna = e.currentTarget.getAttribute("data-qna");
        var tempTok = tok;
        if (tempTok.sections[section].question_answer[qna]["hide"]) {
            tempTok.sections[section].question_answer[qna]["hide"] = false;
        } else {
            tempTok.sections[section].question_answer[qna]["hide"] = true;
        }
        this.setState({
            tok: tempTok
        });
    }
    viewSection(e) {
        const sectionJsonString = e.target.getAttribute("data-section");
        const sectionJson = JSON.parse(sectionJsonString);
        this.setState({
            viewSection: true,
            targetSection: sectionJson
        });
    }
    closeModals() {
        this.setState({
            viewSection: false,
            editSection: false,
            targetSection: null,
            editSectionShow: true,
            CropperShow: false,
            CropperSrc: "",
            CopyTokShow: false,
            CopyTokShowMode: "",
            CopyTokLevel1: "",
            CopyTokLevel2: "",
            CopyTokLevel2List: [],
            CopyTokLevel3: "",
            CopyTokLevel3List: [],
            GroupCopyId: "",
            CopyTokQnaShow: false
        });
    }
    showHideAnswerTargetSection(e) {
        const { targetSection } = this.state;
        var qna = e.currentTarget.getAttribute("data-qna");
        var tempSection = targetSection;
        if (tempSection.question_answer[qna]["hide"]) {
            tempSection.question_answer[qna]["hide"] = false;
        } else {
            tempSection.question_answer[qna]["hide"] = true;
        }
        this.setState({
            targetSection: tempSection
        });
    }
    setEditSection(e) {
        const sectionJsonString = e.target.getAttribute("data-section");
        const sectionJson = JSON.parse(sectionJsonString);
        this.setState({
            editSection: true,
            targetSection: sectionJson
        });
    }
    setEditSectionValues(e) {
        const { targetSection } = this.state;
        const value = e.target.value;
        const variable = e.target.name;
        var tempSection = targetSection;
        if (variable) {
            tempSection[variable] = variable.includes("number") ? value.length == 0 ? 1 : parseInt(value) == 0 ? 1 : parseInt(value) : value;
            this.setState({
                targetSection: tempSection
            });
        }
    }
    setEditSectionQnaValues(e) {
        const { targetSection } = this.state;
        const value = e.target.value;
        const variables = e.target.name;
        var tempSection = targetSection;
        if (value && variables) {
            const variable = variables.split("-")
            tempSection.question_answer[variable[1]][variable[0]] = value;
            this.setState({
                targetSection: tempSection
            });
        }
    }
    setEditSectionAddQna() {
        const { targetSection } = this.state;
        var tempSection = targetSection;
        tempSection.question_answer.push({
            question: "",
            answer: "",
        })
        this.setState({
            targetSection: tempSection
        })
    }
    showHideEditSection() {
        const { editSectionShow } = this.state;

        this.setState({
            editSectionShow: !editSectionShow
        });
    }
    deleteSection(e) {
        const sectionJsonString = e.target.getAttribute("data-section");
        const sectionJson = JSON.parse(sectionJsonString);
        MySwal.fire({
            title: <p>Delete Section?</p>,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            allowOutsideClick: false,
            icon: "warning",
            confirmButtonColor: "#c82333"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Section</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading();
                deleteSection(sectionJson.id, sectionJson.pk, sectionJson.tok_id).then(response => {
                    if(response){
                        if (response.status == 200) {
                            MySwal.update({
                                title: <p>Section Deleted</p>,
                                icon: "success"
                            });
                            const { tok } = this.state;
                            var tempTok = tok;
                            for (var x = 0; tempTok.sections.length; x++) {
                                if (tempTok.sections[x].id == sectionJson.id) {
                                    tempTok.sections.splice(x, 1);
                                    break;
                                }
                            }
                            this.setState({
                                tok: tempTok
                            });
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                            MySwal.hideLoading();
                        }
                    }
                }).catch(err => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }).finally(() => {
                    MySwal.hideLoading();
                })
            }
        }).catch((result) => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
            MySwal.hideLoading();
        });
    }
    confirmEditSection() {
        const { targetSection, tok } = this.state;
        let sections = cloneDeep(tok["sections"]);
        let ogSection = sections.filter(item => item["id"] == targetSection["id"] && item["pk"] == targetSection["pk"])[0];
        let patchObject = diff(ogSection, targetSection);
        MySwal.fire({
            title: <p>Updating Section</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        patchObject["section_number"] = targetSection["section_number"];
        patchSection(patchObject, ogSection["id"], ogSection["pk"]).then(response => {
            if(response){
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Section Updated</p>,
                        icon: "success"
                    });
                    MySwal.hideLoading();
                    const { tok } = this.state;
                    var tempTok = tok;
                    for (var x = 0; tempTok.sections.length; x++) {
                        if (tempTok.sections[x].id == targetSection.id) {
                            tempTok.sections[x] = targetSection;
                            break;
                        }
                    }
                    this.setState({
                        tok: tempTok
                    });
                    this.closeModals();
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                }
            }
        });
    }
    handleCropperClose() {
        this.setState({
            CropperShow: false,
            CropperSrc: ""
        });
    }
    onCrop() {
        const cropper = this.cropperRef.current?.cropper;
        var { targetSection } = this.state;
        targetSection["image"] = cropper.getCroppedCanvas().toDataURL();
        this.setState({
            CropperShow: false,
            targetSection: targetSection
        });
    }
    onImageSet(e) {
        this.setState({
            CropperShow: true,
            CropperSrc: URL.createObjectURL(e.target.files[0])
        });
    }
    copyTokShow(e) {
        let type = e.currentTarget.getAttribute("data-type");
        MySwal.fire({
            title: <p>{type != "qna" ? "Copy to?" : "Post Q&A Tok to:"}</p>,
            allowOutsideClick: false,
            icon: "question",
            showDenyButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            html:
                <div className={'copytok-menu-container'}>
                    {type == "qna" ?
                        <Button variant={'warning'} data-group={type} onClick={this.setTokCopy} data-type="MyTok">My Tok</Button> : ""}
                    <Button className={'group-btn'} data-group={type} onClick={this.setTokCopy} data-type="Group">Group</Button>
                    <Button onClick={this.setTokCopy} data-group={type} data-type="Channel">Tok Channel</Button>
                    <Button variant={'success'} onClick={this.setTokCopy} data-group={type} data-type="Community">Community</Button>
                    <Button variant={'secondary'} onClick={this.setTokCopy} data-group={type}>Cancel</Button>
                </div>
        }).then((result) => {
        }).catch((result) => {
        });
    }
    setTokCopy(e) {
        var type = e.currentTarget.getAttribute("data-type");
        var group = e.currentTarget.getAttribute("data-group");
        MySwal.close();
        if (type) {
            this.setState({
                CopyTokShow: true,
                CopyTokShowMode: type,
                CopyTokQnaSet: true,
                CopyRefreshView: false
            }, () => {
                if (type == "MyTok") {
                    this.copyTokConfirm();
                } else if (type == "Group") {
                    this.setState({
                        CopyRefreshView: true
                    }, () => {
                        this.getGroupsCopy();
                    });
                }
            });
        }
    }
    addGroupAnswersSet() {
        this.setState({
            CopyTokShow: true,
            CopyTokShowMode: "GroupAnswers",
            CopyTokQnaSet: true,
            CopyRefreshView: false
        }, () => {
            this.copyTokConfirm();
        });
    }
    createAnswers(sections, currentCount, sectionCount) {
        MySwal.fire({
            title: <p>Adding Sections {currentCount} of {sections.length}</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        let section = cloneDeep(sections[sectionCount]);
        section["section_number"] = sectionCount;
        addSection(section, section.id, "").then(response => {
            if(response){
                if (response.status == 200) {
                    currentCount++;
                    sectionCount++;
                    if (sectionCount >= sections.length) {
                        this.setState({
                            show: false
                        });
                        MySwal.update({
                            title: <p>Sections Added</p>,
                            icon: "success"
                        });
                        MySwal.hideLoading();
                        this.setState({
                            CopyTokQnaShow: false,
                        });
                    } else {
                        this.createAnswers(sections, currentCount, sectionCount);
                    }
                } else {
                    MySwal.update({
                        title: <p>A error occured</p>,
                        icon: "error"
                    });
                    MySwal.hideLoading();
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>A error occured</p>,
                icon: "error"
            });
            MySwal.hideLoading();
        })
    }
    setCopyTokLevel(e) {
        const value = e.target.value;
        const level = e.target.name;
        const { CopyTokShowMode } = this.state;
        this.setState({
            CommunityCopyId: ""
        });
        var next = [];
        if (level != "level3") {
            for (let x = 0; x < channels.length; x++) {
                if ((value == channels[x].ref) && channels[x]["next"]) {
                    next = channels[x]["next"]
                    break;
                }
            }
        }
        switch (level) {
            case "level1":
                this.setState({
                    CopyTokLevel1: value,
                    CopyTokLevel2List: next,
                    CopyRefreshView: false
                });
                break;
            case "level2":
                this.setState({
                    CopyTokLevel2: value,
                    CopyTokLevel3List: next,
                    CopyRefreshView: false
                });
                break;
            case "level3":
                this.setState({
                    CopyTokLevel3: value,
                    CommunityLoader: true,
                    CommunityCopyId: "",
                    CopyRefreshView: true
                }, () => {
                    if (CopyTokShowMode == "Community") {
                        const { CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
                        this.getCopyCommunities(CopyTokLevel1, CopyTokLevel2, CopyTokLevel3);
                    }
                });
                break;
        }
    }

    getCopyCommunities(level1, level2, level3) {
        let query = {
            level0: level1 ? upperCasesFirstLetter(level1) : "",
            level1: level2 ? upperCasesFirstLetter(level2) : "",
            level2: level3 ? upperCasesFirstLetter(level3) : "",
            //remove label once addgroup is adjusted
            label: "classgroup",
            items: "channelgroups"
        }
        getGroups(query, "channelgroups").then(res => {
            const { CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
            if (level1 == CopyTokLevel1 && level2 == CopyTokLevel2 && level3 == CopyTokLevel3) {
                this.setState({
                    Communities: res.data.data["results"]
                });
            }
        }).catch(err => { }).
            finally(() => {
                const { CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
                if (level1 == CopyTokLevel1 && level2 == CopyTokLevel2 && level3 == CopyTokLevel3) {
                    this.setState({
                        CommunityLoader: false
                    });
                }
            })
    }

    refreshCopyOptions() {
        const { CopyTokShowMode, CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
        if (CopyTokShowMode == "Community") {
            this.getCopyCommunities(CopyTokLevel1, CopyTokLevel2, CopyTokLevel3);
        } else if (CopyTokShowMode == "Group") {
            this.getGroupsCopy();
        }
    }

    copyTokConfirm() {
        var { userid, CopyTokQnaSet, CopyTokQnaShow, CopyTokQnaSections, tok, CopyTokLevel1, CopyTokLevel2, CopyTokLevel3, CopyTokShowMode, GroupCopyId, GroupsCopy, CommunityCopyId, Communities } = this.state;
        if (CopyTokQnaSet && !CopyTokQnaShow) {
            let sections = cloneDeep(tok.sections);
            for (let x = 0; x < sections.length; x++) {
                for (let y = 0; y < sections[x].question_answer.length; y++) {
                    sections[x]["question_answer"][y]["original_answer"] = cloneDeep(sections[x]["question_answer"][y].answer);
                    sections[x]["question_answer"][y].answer = "";
                }
            }

            this.setState({
                CopyTokQnaShow: true,
                CopyTokQnaSections: sections,
                CopyTokShow: false
            });
        } else {
            var copiedTo = [];
            tok["master_copy"] = false;
            tok["is_original"] = false;

            tok["level1"] = CopyTokLevel1 ? upperCasesFirstLetter(CopyTokLevel1) : "";
            tok["level2"] = CopyTokLevel2 ? upperCasesFirstLetter(CopyTokLevel2) : "";
            tok["level3"] = CopyTokLevel3 ? upperCasesFirstLetter(CopyTokLevel3) : "";
            if (tok["level1"]) {
                var levelFeed = {
                    feed: tok["level1"],
                    subfeed: null
                }
                if (tok["level2"]) {
                    levelFeed["subfeed"] = {
                        feed: tok["level2"],
                        subfeed: null
                    }
                    if (tok["level3"]) {
                        levelFeed["subfeed"]["subfeed"] = {
                            feed: tok["level3"],
                            subfeed: null
                        }
                    }
                }
                copiedTo.push(levelFeed);
            }
            if (CommunityCopyId) {
                tok["community_id"] = CommunityCopyId;
            }
            else if (GroupCopyId) {
                tok["group_id"] = GroupCopyId;
                for (let x = 0; x < GroupsCopy.length; x++) {
                    if (GroupCopyId == GroupsCopy[x].id) {
                        tok["group_name"] = GroupsCopy[x]["name"] ? GroupsCopy[x]["name"] : "";
                        break;
                    }
                }
                let groupFeed = {
                    feed: tok["group_name"],
                    subfeed: null
                }
                copiedTo.push(groupFeed);
            } else {
                tok["group_id"] = "";
            }
            MySwal.fire({
                title: <p>{CopyTokShowMode == "GroupAnswers" ? "Adding Answers" : "Copying Tok"}</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();

            var tempTok = cloneDeep(tok);
            if (!tempTok["copied_from"]) {
                tempTok["copied_from"] = new Array;
            }
            if (tempTok["community_id"]) {
                tempTok["copied_from"] = tempTok["copied_from"].concat({
                    feed: "Community",
                    subfeed: null
                });
            } else if (tempTok["pk"].includes(tok["user_id"])) {
                tempTok["copied_from"] = tempTok["copied_from"].concat({
                    feed: "My Toks",
                    subfeed: null
                });
            } else if (tempTok["pk"].includes(tempTok["group_id"])) {
                tempTok["copied_from"] = tempTok["copied_from"].concat({
                    feed: "Group",
                    subfeed: null
                });
            }
            let feedId = "";
            switch (CopyTokShowMode) {
                case "Community":
                    feedId = tempTok["community_id"];
                case "Channel":
                    let tempFeedId = "";
                    if (tempTok["level1"]) {
                        tempFeedId = textDisplay(tempTok["level1"]).toLowerCase();
                        if (tempTok["level2"]) {
                            tempFeedId = tempFeedId + "-" + textDisplay(tempTok["level2"]).toLowerCase();
                            if (tempTok["level3"]) {
                                tempFeedId = tempFeedId + "-" + textDisplay(tempTok["level3"]).toLowerCase();
                            }
                        }
                    }
                    if (feedId && tempFeedId) {
                        feedId = tempFeedId + "-" + feedId;
                    } else {
                        feedId = tempFeedId;
                    }
                    break;
                case "Group":
                    feedId = tempTok["group_id"];
                    break;
                case "MyTok":
                    feedId = userid;
                    break;
            }
            if (CopyTokQnaShow) {
                tempTok.sections = CopyTokQnaSections;
            }
            if (CopyTokShowMode == "GroupAnswers") {
                this.createAnswers(CopyTokQnaSections, 1, 0);
            } else {
                addClassTok(tempTok, feedId).then(response => {
                    MySwal.hideLoading();
                    if(response){
                        if (response.status == 200) {
                            let newTok = response.data.result;
                            tok["copied_from"] = new Array;
                            if (tok["posted_to"]) {
                                tok["posted_to"] = tok["posted_to"].concat(copiedTo);
                            }
                            else {
                                tok["posted_to"] = copiedTo;
                            }
                            tok["posted_to"] = unique(tok["posted_to"], "feed")
                            this.updatePostedTo(tok["posted_to"]);
    
                            if ((newTok["tok_group"] == "Mega" || newTok["tok_group"] == "Q&a") && newTok.id) {
                                this.createSections(newTok, 1, 0);
                            }
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }
                }).catch(err => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }).finally(() => {
                    MySwal.hideLoading();
                })
            }
        }
    }

    createSections(newTok, addCounter = 1, sectionNumber = 0) {
        const { CopyTokShowMode } = this.state;
        MySwal.fire({
            title: <p>Adding Sections {addCounter} of {newTok.sections.length}</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        let section = cloneDeep(newTok.sections[sectionNumber]);
        section["section_number"] = sectionNumber;
        section["tok_id"] = newTok.id;
        addSection(section, newTok.id, "").then(response => {
            if(response){
                if (response.status == 200) {
                    addCounter++;
                    sectionNumber++;
                    if (sectionNumber >= newTok.sections.length) {
                        this.setState({
                            show: false
                        });
                        MySwal.update({
                            title: <p>Sections Added</p>,
                            icon: "success"
                        });
                        MySwal.hideLoading();
                        var tempTok = cloneDeep(newTok);
                        const localUser = localStorage.getItem("userdata");
                        if (localUser) {
                            tempTok["created_time"] = new Date().toLocaleString() + '';
                        }
                        const { CopyTokQnaShow } = this.state;
                        if (CopyTokQnaShow) {
                            this.setState({
                                CopyTokQnaShow: false,
                            }, () => {
                                //this.props.addViewTok(tempTok)
                                let urlLink = "";
                                switch (CopyTokShowMode) {
                                    case "Community":
                                        urlLink = tempTok["community_id"];
                                    case "Channel":
                                        let levelLink = "";
                                        if (newTok["level1"]) {
                                            levelLink = textDisplayReverse(newTok["level1"]).toLowerCase();
                                            if (newTok["level2"]) {
                                                levelLink = levelLink + "/" + textDisplayReverse(newTok["level2"]).toLowerCase();
                                                if (newTok["level3"]) {
                                                    levelLink = levelLink + "/ " + textDisplayReverse(newTok["level3"]).toLowerCase();
                                                }
                                            }
                                        }
                                        //add community later until further discusion
                                        urlLink = "tokchannels/" + levelLink;
                                        break;
                                    case "Group":
                                        urlLink = "group/" + newTok["group_id"];
                                        break;
                                    case "MyTok":
                                        urlLink = "classtoks";
                                        break;
                                }
                                window.open(urlLink, "_self");
                            });
                        }
                    } else {
                        this.createSections(newTok, addCounter, sectionNumber);
                    }
                } else {
                    MySwal.update({
                        title: <p>A error occured</p>,
                        icon: "error"
                    });
                    MySwal.hideLoading();
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>A error occured</p>,
                icon: "error"
            });
            MySwal.hideLoading();
        })
    }

    updatePostedTo(posted = new Array) {
        let { tok } = this.state;
        tok["posted_to"] = posted;
        updateClassTok(tok).then(res => {
        }).catch(err => {

        }).finally(() => {

        })
        try {
            this.props.updateTok(tok);
        } catch (err) { }


        MySwal.update({
            title: 'Copied!',
            icon: "success"
        });
        this.setState({
            tok: tok
        });
    }
    async getGroupsCopy() {
        var { GroupsCopy } = this.state;
        const localUser = localStorage.getItem("userdata");
        await getToken();

        var userid = "";
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson.id !== undefined)
                userid = localUserJson.id;

        }
        const query = { userid: userid }

        this.setState({
            GroupsCopyLoader: true
        });
        const response = await getGroups(query);
        if (response) {
            if (response["status"] == 200) {
                GroupsCopy = response.data.result;
            }
        }
        this.setState({
            GroupsCopy: GroupsCopy,
            GroupsCopyLoader: false
        });
    }
    setCopyGroup(e) {
        const value = e.target.value;
        this.setState({
            GroupCopyId: value
        });
    }
    setCopyCommunity(e) {
        const value = e.target.value;
        this.setState({
            CommunityCopyId: value
        });
    }
    setTokBack() {
        this.setState({
            TokBackActive: true
        });
    }
    closeTokBack() {
        this.setState({
            TokBackActive: false
        });
    }
    editTok() {
        this.addTokBtnRef.current.click();
    }
    reportTok() {
        this.reportItemRef.current.click();
    }
    updateTok(e) {
        this.props.updateTok(e);
        this.setState({
            tok: e
        });
    }

    goToTok(e) {
        const { tok } = this.state;
        const tokid = e.target.getAttribute("data-id");
        MySwal.fire({
            title: <p>Getting Tok Info</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        getClassTok(tokid, "classtoks0").then(res => {
            if(res){
                if (res["status"] == 200) {
                    if (res["data"]["resource"]) {
                        var hrefLink = "/classtoks/tok/" + res["data"]["resource"]["id"] + "/" + res["data"]["resource"]["pk"];
                        if (res["data"]["resource"]["level1"]) {
                            hrefLink = "/tokchannels/tok/" + res["data"]["resource"]["id"] + "/" + res["data"]["resource"]["pk"];
                        }
                        window.history.pushState({}, "Tokket", hrefLink);
                        this.setState({
                            GoToTok: res["data"]["resource"]
                        });
                    } else {
                        MySwal.update({
                            title: <p>Tok no longer exist.</p>,
                            icon: "warning"
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
            MySwal.close();
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    viewProfileSet(e) {
        MySwal.fire({
            title: <p>Loading User Info</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        getUser(e).then(res => {
            if(res){
                if (res["data"]["status_code"] == 200) {
                    if (res["data"]["result"]) {
                        this.setState({
                            UserInfo: res["data"]["result"],
                            UserView: true
                        });
                        MySwal.close();
                    } else {
                        MySwal.update({
                            title: <p>User no longer exist.</p>,
                            icon: "warning"
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    closeProfile() {
        this.setState({
            UserInfo: {} as TokUser,
            UserView: false
        });
    }
    updateTokLink(updatedTok) {
        if (updatedTok["id"]) {
            this.setState({
                GoToTok: updatedTok
            });
        }
    }
    deleteTokLink(deleteTok) {
        if (deleteTok["id"]) {
            this.setState({
                GoToTok: {} as Tok
            });
        }
    }
    closeGoToTok() {
        const { tok } = this.state;
        var hrefLink = "/classtoks/tok/" + tok["id"] + "/" + tok["pk"];
        if (tok["level1"]) {
            hrefLink = "/tokchannels/tok/" + tok["id"] + "/" + tok["pk"];
        }
        window.history.pushState({}, "Tokket", hrefLink);
        this.setState({
            GoToTok: {} as Tok
        });
    }
    viewProfileUser() {
        const { tok } = this.state;
        this.viewProfileSet(tok["user_id"]);
    }
    setCopySectionAnswer(e) {
        const { CopyTokQnaSections } = this.state;
        const value = e.target.value;
        let data = e.target.name.split("-");

        let sections = cloneDeep(CopyTokQnaSections);
        let sectionIndex = data[0];
        let questionIndex = data[1];

        sections[sectionIndex].question_answer[questionIndex]["answer"] = value;

        this.setState({
            CopyTokQnaSections: sections
        });
    }

    copyQnaTok() {

    }

    answerEnabled() {
        const { tok } = this.state;
        let tempTok = cloneDeep(tok);
        if (tempTok["answer_enabled"]) {
            tempTok["answer_enabled"] = false;
        } else {
            tempTok["answer_enabled"] = true;
        }
        let patchObject = diff(tok, tempTok);
        MySwal.fire({
            title: <p>{tempTok["answer_enabled"] ? "Enabling" : "Disabling"} Answers.</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        let patchObjectKeys = Object.keys(patchObject);
        for (let x = 0; x < patchObjectKeys.length; x++) {
            patchObject[patchObjectKeys[x]] = cloneDeep(tempTok[patchObjectKeys[x]]);
        }
        patchTok(patchObject, tok["id"], tok["pk"]).then(res => {
            if(res){
                if (res.status == 200) {
                    MySwal.update({
                        title: <p>Update successful.</p>,
                        icon: "success"
                    });
                    this.setState({
                        tok: tempTok,
                    });
                    try {
                        this.updateTok(tempTok);
                    } catch (err) {
                    }
    
                } else {
                    MySwal.update({
                        title: <p>Update failed.</p>,
                        icon: "success"
                    });
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    bookmarkTok() {
        const { tok, userid } = this.state;
        var bookmarkMsg = toast.loading('Saving Tok to Bookmarks.');
        let bkTok = cloneDeep(tok);

        let createBkTokSections = this.createSections;
        addClassTok(bkTok, userid, "bookmarks").then(response => {
            if(response){
                if (response.status == 200) {
                    if (bkTok.tok_group == "Mega" || bkTok.tok_group == "Q&a") {
                        createBkTokSections(response["data"]["result"], 1, 0);
                    } else {
                        toast.success('Bookmark successful', {
                            id: bookmarkMsg,
                        });
                    }
                } else {
                    toast.error('An Error Occured', {
                        id: bookmarkMsg,
                    });
                }
            }
        }).catch(err => {
            toast.error('An Error Occured', {
                id: bookmarkMsg,
            });
        }).finally(() => {
        });
    }
    listSectionScroll(e) {
        const { tokenSection, sectionLoader } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !sectionLoader && tokenSection) {
            this.getSections();
        }
    }

    render() {
        var { mobileView, oldUiActive, items, GroupId, AnswerQuestionList, CopyRefreshView, CopyTokQnaSet, CopyTokQnaSections, CopyTokQnaShow, CommunityCopyId, Communities, CommunityLoader, GoToTokZIndex, GoToTok, UserInfo, UserView, userid, TokBackActive, GroupsCopy, GroupsCopyLoader, CopyTokLevel1, CopyTokLevel2, CopyTokLevel2List, CopyTokLevel3, CopyTokLevel3List, CopyTokShow, CopyTokShowMode, CropperShow, CropperSrc, relativeDate, tok, tokenSection, sectionLimit, sectionLoader, viewSection, targetSection, editSection, editSectionShow } = this.state;
        const hrefLink = "/classtoks/tok/" + tok.id + "/" + tok.pk;
        var isChannel = false;
        if (tok["level1"]) {
            isChannel = true;
        }
        var secondary: JSX.Element;
        var title = "";
        var avatar = 'https://tokketcontent.blob.core.windows.net/tokketweb/default.png';
        let disableCopyQnaPost = true;
        let questionCount = 0;
        let answerCount = 0;
        if (CopyTokQnaShow) {
            for (let x = 0; x < CopyTokQnaSections.length; x++) {
                for (let y = 0; y < CopyTokQnaSections[x].question_answer.length; y++) {
                    if (CopyTokQnaSections[x]["question_answer"][y].answer) {
                        answerCount++;
                    }
                    questionCount++;
                }
            }
            if (questionCount == answerCount) {
                disableCopyQnaPost = false;
            }
        }

        if (tok.title_enabled) {
            title = tok.title_display;
        }
        if (tok.user_photo) {
            avatar = tok.user_photo;
        }
        if (tok.tok_group == "Mega" || tok.tok_group == "Q&a") {
        }
        let indents = new Array;
        if (tok["is_indent"]) {
            indents = cloneDeep(tok["is_indent"]);
        }
        let details = new Array;
        let tempDetailList = cloneDeep(tok["details"]);
        if (details && indents.includes(true) && (tok.tok_group == "Detail" || tok.tok_group == "Detailed" || tok.tok_group == "List")) {
            let tempSubDetails = new Array;
            for (let x = 0; x < tempDetailList.length; x++) {
                if (indents[x]) {
                    tempSubDetails.push({
                        text: tempDetailList[x],
                        index: x
                    });
                    if (x == tempDetailList.length - 1) {
                        details[details.length - 1]["sub"] = cloneDeep(tempSubDetails);
                    }
                } else {
                    if (tempSubDetails.length > 0) {
                        details[details.length - 1]["sub"] = cloneDeep(tempSubDetails);
                        tempSubDetails = new Array;
                    }
                    details.push({
                        text: tempDetailList[x],
                        index: x
                    });
                }
            }
        } else if (tok["details"] && (tok.tok_group == "Detail" || tok.tok_group == "Detailed" || tok.tok_group == "List")) {
            for (let x = 0; x < tempDetailList.length; x++) {
                details.push({
                    text: tempDetailList[x],
                    index: x
                });
            }
        }
        switch (tok.tok_group) {
            case "Detail":
            case "Detailed":
            case "List":
                let listStype = "disc";
                switch (tok["bullet_kind"]) {
                    case "Letters":
                        listStype = "lower-alpha"
                        break;
                    case "Numbers":
                        listStype = "decimal"
                        break;
                }
                secondary = <Typography variant="body2" color="text.secondary" style={{ fontSize: '26px', paddingTop: "15px" }}>
                    <List dense={true} style={{ listStyleType: listStype, textAlign: 'left', maxWidth: '100%', paddingLeft: '25px' }} >
                        {details ?
                            details.map((dtl) => (

                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', display: 'list-item', flexShrink: 0, textAlign: "left" }}>
                                    <Row>
                                        <Col style={{ display: "flex", alignItems: "center", fontSize: '26px' }}>
                                            {dtl["text"]}
                                            {tok["detail_tok_links"][dtl["index"]] ?
                                                <Button style={{ marginLeft: "5px", fontSize: "20px" }} data-id={tok["detail_tok_links"][dtl["index"]]} onClick={this.goToTok}>Go to Link</Button> : ""
                                            }
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Row>
                                                <Col xs={"auto"} style={{ padding: "unset" }}>
                                                    <Comments iconModeSize={"medium"} iconMode={true} itemId={tok.id} item={tok} Pk={tok["pk"] ? tok["pk"] : ""} detailNo={dtl["index"]} userCommentActive={true} viewProfileSet={this.viewProfileSet} commentsViewActive={true} hideCommentButton={false} commentCounterRef={undefined} commentSwitchUiRef={undefined} disableInitialization={undefined} />
                                                </Col>
                                                <Col xs={"auto"} style={{ padding: "unset" }}>
                                                    <ReactionContainer updateTok={this.updateTok} item={tok} key={tok["id"] + "-" + tok["pk"] + "-reaction" + dtl["index"]} LeftDisplay={true} OwnerId={tok["user_id"] ? tok["user_id"] : ""} Id={tok["id"] ? tok["id"] : ""} DetailNum={dtl["index"]} Pk={tok["pk"] ? tok["pk"] : ""} reactionSwitchUiRef={undefined} enableShare={undefined} TokSection={undefined} QuestionNum={undefined} columnView={undefined} fbShare={undefined} shareLink={undefined} commentCounterRef={undefined} groupId={undefined} linkZIndex={undefined} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {tok["detail_images"] ?
                                            <Col xs={12}>
                                                {tok["detail_images"][dtl["index"]] ?
                                                    <>
                                                        {isYoutubeLink(tok["detail_images"][dtl["index"]]) ?
                                                            <YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(tok["detail_images"][dtl["index"]])} /> :
                                                            <img src={tok["detail_images"][dtl["index"]]} style={{ maxWidth: "100%" }} />
                                                        }
                                                    </> : ""
                                                }
                                            </Col> : ""
                                        }
                                    </Row>

                                    {dtl["sub"] ?
                                        <List dense={true} style={{ listStyleType: listStype, textAlign: 'left', maxWidth: '100%', paddingLeft: '25px' }} >
                                            {dtl["sub"].map((sub) => (
                                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', display: 'list-item', flexShrink: 0, textAlign: "left" }}>
                                                    <Row>
                                                        <Col style={{ display: "flex", alignItems: "center", fontSize: '26px' }}>
                                                            {sub["text"]}
                                                            {tok["detail_tok_links"][sub["index"]] ?
                                                                <Button style={{ marginLeft: "5px", fontSize: "20px" }} data-id={tok["detail_tok_links"][sub["index"]]} onClick={this.goToTok}>Go to Link</Button> : ""
                                                            }
                                                        </Col>
                                                        <Col className={'col-auto'}>
                                                            <ReactionContainer reactionSwitchUiRef={undefined} enableShare={undefined} TokSection={undefined} QuestionNum={undefined} columnView={undefined} fbShare={undefined} shareLink={undefined} commentCounterRef={undefined} groupId={undefined} linkZIndex={undefined} updateTok={this.updateTok} item={tok} key={tok["id"] + "-" + tok["pk"] + "-reaction" + sub["index"]} LeftDisplay={true} OwnerId={tok["user_id"] ? tok["user_id"] : ""} Id={tok["id"] ? tok["id"] : ""} DetailNum={sub["index"]} Pk={tok["pk"] ? tok["pk"] : ""} />
                                                        </Col>
                                                        {tok["detail_images"] ?
                                                            <Col xs={12}>
                                                                {tok["detail_images"][sub["index"]] ?
                                                                    <>
                                                                        {isYoutubeLink(tok["detail_images"][sub["index"]]) ?
                                                                            <YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(tok["detail_images"][sub["index"]])} /> :
                                                                            <img src={tok["detail_images"][sub["index"]]} style={{ maxWidth: "100%" }} />
                                                                        }
                                                                    </> : ""
                                                                }
                                                            </Col> : ""
                                                        }
                                                    </Row>
                                                    <Comments iconMode={false} itemId={tok.id} item={tok} Pk={tok["pk"] ? tok["pk"] : ""} detailNo={sub["index"]} userCommentActive={true} viewProfileSet={this.viewProfileSet} commentsViewActive={true} hideCommentButton={true} commentCounterRef={undefined} commentSwitchUiRef={undefined} iconModeSize={undefined} disableInitialization={undefined} />
                                                </ListItem>
                                            ))}
                                        </List> : ""}
                                </ListItem>
                            )) : ""
                        }
                    </List>
                </Typography>
                break;
            case "Mega":
            case "Q&a":
                secondary = <Typography variant="body2" color="text.secondary" style={{ fontSize: '1rem', paddingTop: "15px" }}>
                    {oldUiActive && tok.tok_group == "Q&a" ?
                        <div style={{ display: "inline-grid" }}>
                            {tok.master_copy ?
                                <div className={'btn'} style={{ borderColor: 'gray', color: 'gray', marginBottom: "5px" }}>
                                    <span style={{ marginRight: "5px" }}>
                                        User Answers are disabled
                                    </span>
                                    <FontAwesomeIcon icon={faLock} />
                                </div> : ""
                            }

                            <Row style={{ margin: "unset" }}>
                                {tok.master_copy ?
                                    <Button variant="success" onClick={this.copyTokShow} data-type="qna">Create Answerable Post</Button> :
                                    tok["user_id"] == userid ?
                                        <>
                                            <Col xs={'auto'}>
                                                <Button variant="success" data-type="qna" onClick={this.answerEnabled}>{tok["answer_enabled"] ? "Hide" : "Show"} your own answers</Button>
                                            </Col>
                                            <Col xs={'auto'}>
                                                <Button variant="success" data-type="qna" disabled={!tok["answer_enabled"]}>Unlock User Answers</Button>
                                            </Col>
                                            <Col xs={'auto'}>
                                                <Button variant="success" onClick={this.copyTokShow} data-type="qna">Duplicate Q&A Tok</Button>
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={'auto'}>
                                                <Button variant="success" onClick={this.addGroupAnswersSet} data-type="qna">Add User Answers</Button>
                                            </Col>
                                            <Col xs={'auto'}>
                                                <Button variant="success" onClick={this.copyTokShow} data-type="qna">Duplicate Q&A Tok</Button>
                                            </Col>
                                        </>
                                }
                            </Row>

                        </div> : ""
                    }
                    <List dense={true} style={{ maxWidth: '100%', paddingLeft: '25px' }}>

                        {tok["sections"] ?
                            tok.sections.map((section, index) => (
                                <>
                                    {index < sectionLimit ?
                                        <ListItem className={'row'} sx={{ paddingTop: '0px', display: 'flex', flexShrink: 0, textAlign: "left" }}>
                                            <Col style={{ fontSize: "1rem", fontWeight: "bold" }}>
                                                <div style={{ textAlign: "center" }}>
                                                    Section {index + 1}
                                                </div>
                                                <div style={{ color: "black" }}>
                                                    {tok.tok_group == "Mega" ? section.title : section.content}
                                                </div>
                                            </Col>
                                            <Dropdown className={'col-auto section-menu'} style={{ marginLeft: "auto" }}>
                                                <Dropdown.Toggle variant="success" style={{ color: "black", backgroundColor: "unset", border: "unset", padding: "unset", position: "relative", margin: "unset" }}>
                                                    <FontAwesomeIcon icon={faEllipsis} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item data-section={JSON.stringify(section)} onClick={this.viewSection}>View</Dropdown.Item>
                                                    <Dropdown.Item data-section={JSON.stringify(section)} onClick={this.setEditSection}>Edit</Dropdown.Item>
                                                    <Dropdown.Item data-section={JSON.stringify(section)} onClick={this.deleteSection}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {tok.tok_group == "Q&a" ?
                                                <div className={'col-12'} style={{ marginLeft: "15px" }}>
                                                    {section.question_answer ? section.question_answer.map((qna, qnaIndex) => (
                                                        <>
                                                            {qna.question ?
                                                                <>
                                                                    <div style={{ fontSize: "1rem" }} className={'qna-section-item'}>
                                                                        <span className={'qna-section-item-before'}>Question {qnaIndex + 1}: </span>
                                                                        <span>{qna.question}</span>
                                                                    </div>
                                                                    <div className={'qna-section-item'} style={{ marginLeft: "10px" }}>
                                                                        <span className={'qna-section-item-before'}>Answer {qnaIndex + 1}: </span>
                                                                        {!tok["master_copy"] && tok["user_id"] == userid ?
                                                                            tok["answer_enabled"] ?
                                                                                <span style={{ fontSize: "16px", cursor: "pointer" }}>
                                                                                    {qna.answer}
                                                                                </span>
                                                                                : "(Answer Hidden)"
                                                                            : ""
                                                                        }
                                                                        {!tok["master_copy"] && !tok["group_id"] && tok["user_id"] == userid ?
                                                                            <span style={{ fontSize: "16px", cursor: "pointer" }} onClick={this.showHideAnswer} data-section={index} data-qna={qnaIndex}>
                                                                                {qna["hide"] ?
                                                                                    <span>
                                                                                        {qna.answer}
                                                                                        <span className={'qna-section-answer-status'}>
                                                                                            (Click to hide)
                                                                                        </span>
                                                                                    </span> :
                                                                                    <span className={'qna-section-answer-status'}>
                                                                                        (Click for Answer)
                                                                                    </span>
                                                                                }
                                                                            </span> : ""
                                                                        }
                                                                    </div>
                                                                    {AnswerQuestionList[section["id"]] && tok["group_id"] ?
                                                                        <div className={'answer-container'}>
                                                                            {AnswerQuestionList[section["id"]]["loading"] ?
                                                                                <WaitSpinner /> :
                                                                                <>
                                                                                    <div className={'show-hide-user-answer-header'} onClick={this.showHideAnswerQuestion} data-sectionindex={index} data-qnaindex={qnaIndex} >
                                                                                        {qna["show"] ? "Hide" : "Show"} User Answers
                                                                                        <FontAwesomeIcon icon={faAngleDown} style={{ transform: qna["show"] ? "rotate(180deg)" : "" }} />
                                                                                    </div>
                                                                                    {AnswerQuestionList[section["id"]]["sections"] ?

                                                                                        AnswerQuestionList[section["id"]]["sections"].map((answer, answerIndex) => (
                                                                                            <>
                                                                                                {answer["id"] != section["id"] ?
                                                                                                    <>
                                                                                                        {qna["show"] ?
                                                                                                            <Row style={{ margin: "unset" }}>
                                                                                                                <div className={'answer-photo'}>
                                                                                                                    <Avatar sx={{ border: `2px solid #542a7d` }} src={answer["user_photo"] ? answer["user_photo"] : 'https://tokketcontent.blob.core.windows.net/tokketweb/default.png'}></Avatar>
                                                                                                                </div>
                                                                                                                <Col xs={'auto'} className={'answer-display-name'}>
                                                                                                                    {answer["user_display_name"] ? answer["user_display_name"] : ""}:
                                                                                                                </Col>
                                                                                                                <Col xs={'auto'} className={'answer-main'}>
                                                                                                                    {answer["question_answer"][qnaIndex] ? answer["question_answer"][qnaIndex]["answer"] : ""}
                                                                                                                </Col>
                                                                                                            </Row> : ""
                                                                                                        }
                                                                                                    </> : ""}
                                                                                            </>
                                                                                        )) : ""
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div> : ""
                                                                    }
                                                                </> : ""}
                                                        </>
                                                    )) : ""}
                                                </div>
                                                : ""}
                                        </ListItem>
                                        : ""}
                                </>
                            )) : ""
                        }
                    </List>
                    {tokenSection && !sectionLoader ?
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="primary" onClick={this.getSections}>Load More</Button>
                        </div>
                        : ""}
                    {sectionLoader ?
                        <WaitSpinner />
                        : ""
                    }
                </Typography>
                break;
            default:
                secondary = <Typography className={'line-clamp-5'} variant="body2" color="text.secondary" style={{ fontSize: '26px', paddingTop: "15px", display: "flex", alignItems: "center" }}>
                    {tok.secondary_text}
                    {tok["tok_link"] ?
                        <Button style={{ marginLeft: "5px" }} data-id={tok["tok_link"]} onClick={this.goToTok}>Go to Link</Button> : ""
                    }
                </Typography>
                break;
        }

        if (tok["posted_to"]) {
            tok["posted_to"] = unique(tok["posted_to"], "feed")
        } else {
            tok["posted_to"] = new Array;
        }
        if (tok["copied_from"]) {
            tok["copied_from"] = unique(tok["copied_from"], "feed")
        } else {
            tok["copied_from"] = new Array;
        }
        let disableCopy = false;
        if (CopyTokShowMode == "Community" && !CommunityCopyId) {
            disableCopy = true;
        }
        let editTokTarget = cloneDeep(tok);
        if (editTokTarget["sections"]) {
            delete editTokTarget["sections"];
        }
        return (
            <div onScroll={this.listSectionScroll} style={{ position: "relative", overflowY: "scroll", height: "100%", padding: "0px 30px" }}>
                {GoToTok["id"] ?
                    <div className={'tokview-toklink-container'} style={{ zIndex: GoToTokZIndex }}>
                        <Box style={{ height: "100%", width: "100%", position: "absolute", top: "0", left: "0", overflow: "hidden auto", zIndex: "1300", backgroundColor: "white" }}>
                            <AppBar style={{ backgroundColor: "#884bdf", minHeight: "65px" }}>
                                <Toolbar>
                                    <IconButton size="large"
                                        color="inherit"
                                    >
                                        <FontAwesomeIcon size="lg" icon={faArrowLeft} onClick={this.closeGoToTok} />
                                    </IconButton>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ fontWeight: "bold" }}>
                                        Tok Info
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Box style={{ marginTop: "65px", position: "relative" }}>
                                <TokView groupId={undefined} items={undefined} tok={GoToTok} linkZIndex={GoToTokZIndex} deleteTok={this.deleteTokLink} updateTok={this.updateTokLink} />
                            </Box>
                        </Box>
                    </div> : ""
                }
                <Modal className={'dark-background-2 profile-view'} fullscreen={true} show={UserView} onHide={this.closeProfile} style={{ zIndex: "1406" }}>
                    <Modal.Header className={'dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <Typography fontWeight={"bold"} marginRight={"auto"}>View Profile</Typography>

                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeProfile} />
                        </div>

                    </Modal.Header>
                    <Modal.Body className={'dark-background-2'}>
                        {UserView ?
                            <ProfileView user={UserInfo} displayToksSets={true} changePasswordTrigger={undefined} profile={undefined} updateLoginData={undefined} updateProfileData={undefined} /> : ""
                        }

                    </Modal.Body>
                </Modal>
                <ReportItem id={editTokTarget["id"]} itemLabel={"tok"} label={"report"} reportItemRef={this.reportItemRef} />
                <AddClassTok receiveData={undefined} customTitle={undefined} publicfeed={undefined} key={editTokTarget["id"] + "-" + editTokTarget["pk"] + "-edittok"} customClass={"hide"} tok={editTokTarget} defaulttok={tok} updateTok={this.updateTok} edit={true} addTokBtnRef={this.addTokBtnRef} />
                {viewSection ?
                    <Modal size='lg' show={true} onHide={this.closeModals} style={{ zIndex: "1300" }}>
                        <Modal.Header>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <Typography fontWeight={"bold"} marginRight={"auto"}>View Section</Typography>

                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Typography marginBottom={"20px"} align='center' fontWeight={"bold"} fontSize={'20px'}>{tok.tok_group == "Mega" ? targetSection.title : targetSection.content}</Typography>
                            {tok.tok_group == "Mega" ?
                                <Typography align='center' fontSize={'20px'} dangerouslySetInnerHTML={{ __html: textDisplayFormat(targetSection.content) }} /> :
                                <>
                                    {targetSection.question_answer.map((qna, qnaIndex) => (
                                        <>
                                            {qna.question.length > 0 && qna.answer.length > 0 ?
                                                <>
                                                    <div style={{ fontSize: "20px" }} className={'qna-section-item'}>
                                                        <span className={'qna-section-item-before'}>Question {qnaIndex + 1}: </span>
                                                        <span>{qna.question}</span>
                                                    </div>
                                                    <div className={'qna-section-item'} style={{ marginLeft: "10px" }}>
                                                        <span className={'qna-section-item-before'}>Answer {qnaIndex + 1}: </span>
                                                        <span style={{ fontSize: "16px", cursor: "pointer" }} onClick={this.showHideAnswerTargetSection} data-qna={qnaIndex}>
                                                            {qna["hide"] ?
                                                                <span>
                                                                    {qna.answer}
                                                                    <span className={'qna-section-answer-status'}>
                                                                        (Click to hide)
                                                                    </span>
                                                                </span> :
                                                                <span className={'qna-section-answer-status'}>
                                                                    (Click for Answer)
                                                                </span>
                                                            }
                                                        </span>
                                                    </div>
                                                </> : ""}
                                        </>
                                    ))}
                                </>
                            }
                        </Modal.Body>
                    </Modal>
                    : ""
                }
                {editSection ?
                    <>
                        <Modal size='lg' show={CropperShow} onHide={this.handleCropperClose} style={{ zIndex: "1301" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleCropperClose} />
                                    Cropper
                                    <Button variant="success" onClick={this.onCrop}>Crop</Button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Cropper
                                    src={CropperSrc}
                                    style={{ height: 400, width: "100%" }}
                                    initialAspectRatio={16 / 9}
                                    ref={this.cropperRef}
                                />

                            </Modal.Body>
                        </Modal>
                        <Modal size='lg' show={true} onHide={this.closeModals} style={{ zIndex: "1300" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                                    <Typography fontWeight={"bold"}>Edit Section</Typography>
                                    <Button variant="success" onClick={this.confirmEditSection}>Update</Button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className={"col-12 section-item-container"}>
                                    <Col style={{ position: "relative", marginBottom: "10px" }}>
                                        <Row style={{ margin: "unset", display: "flex", alignItems: "center" }}>
                                            <div className={'col-auto'} style={{ padding: "unset", cursor: "pointer", marginRight: "5px" }}>
                                                <FontAwesomeIcon size='lg' className={'caret-down-icon'} icon={faCaretDown} onClick={this.showHideEditSection} style={{ transform: editSectionShow ? "" : "rotate(180deg)" }} />
                                            </div>
                                            <div className={'col-auto'} style={{ padding: "unset", marginRight: "5px" }}>
                                                <FontAwesomeIcon style={{ cursor: "pointer" }} size='xl' onClick={(e) => e.currentTarget.parentNode.querySelector("input").click()} icon={faImage} />
                                                <input type="file" onChange={this.onImageSet} accept="image/*" hidden />
                                            </div>
                                            {tok["tok_group"] == "Mega" ?
                                                <TextField className={'col'} onChange={this.setEditSectionValues} name={'title'} value={targetSection.title} label={"Title/Main Text"} inputProps={{ maxLength: 200 }} />
                                                : ""}
                                        </Row>
                                        {tok["tok_group"] == "Mega" ?
                                            <p className={'character-count'}>{targetSection.title ? targetSection.title.length : ""}/600</p>
                                            : ""}
                                    </Col>
                                    {editSectionShow ?
                                        <>
                                            {
                                                targetSection.image ?
                                                    <Col xs={12} style={{ marginBottom: "10px" }}>
                                                        <img style={{ maxWidth: "100%" }} src={targetSection.image} />
                                                        <div style={{ width: "100%", margin: "5px" }}>
                                                            <Button data-position="image" variant="primary">
                                                                Remove Image
                                                            </Button>
                                                        </div>
                                                    </Col> : ""
                                            }
                                            <Col xs={12}>
                                                <TextField style={{ width: "100%" }} multiline minRows={3} onChange={this.setEditSectionValues} name={`content`} label={"Content"} value={targetSection.content} inputProps={{ maxLength: 150000 }} />
                                                <p className={'character-count'}>{targetSection.content ? targetSection.content.length : "0"}/150000</p>
                                            </Col></> : ""
                                    }
                                    <Col xs={12}>
                                        <TextField style={{ width: "100%" }} onChange={this.setEditSectionValues} name={`section_number`} label={"Section Number"} value={targetSection.section_number} />
                                    </Col>
                                    {tok["tok_group"] == "Q&a" ?
                                        <>
                                            {targetSection.question_answer.map((qna, qnaIndex) => (
                                                <Row style={{ paddingRight: "unset" }}>
                                                    <Col xs={12} style={{ paddingRight: "unset", width: "95%", marginLeft: "auto", position: "relative" }}>
                                                        <TextField style={{ width: "100%" }} onChange={this.setEditSectionQnaValues} name={`question-${qnaIndex}`} label={`Question ${qnaIndex + 1}`} value={qna.question} inputProps={{ maxLength: 600 }} />
                                                        <p className={'character-count'}>{qna.question ? qna.question.length : "0"}/600</p>
                                                        {qnaIndex != 0 ?
                                                            <div className={'item-close qna-close'}>
                                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'red'} size={'lg'} icon={faTimes} data-qna={qnaIndex} />
                                                            </div> : ""
                                                        }

                                                    </Col>
                                                    <Col xs={12} style={{ paddingRight: "unset", width: "90%", marginLeft: "auto" }}>
                                                        <TextField style={{ width: "100%" }} onChange={this.setEditSectionQnaValues} name={`answer-${qnaIndex}`} label={`Answer ${qnaIndex + 1}`} value={qna.answer} inputProps={{ maxLength: 600 }} />
                                                        <p className={'character-count'}>{qna.answer ? qna.answer.length : "0"}/600</p>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Button onClick={this.setEditSectionAddQna} variant="primary" style={{ width: "max-content", marginLeft: "calc(var(--bs-gutter-x) * .5 + 10%)" }}>
                                                + Add Question
                                            </Button>
                                        </>
                                        : ""}
                                </Row>
                            </Modal.Body>
                        </Modal>
                    </>
                    : ""
                }
                {CopyTokQnaShow ?
                    <Modal size='lg' show={true} onHide={this.closeModals} style={{ zIndex: "1300" }}>
                        <Modal.Header>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                                <Typography fontWeight={"bold"}>Add Answers {answerCount}/{questionCount}</Typography>
                                <Button variant="success" disabled={disableCopyQnaPost} onClick={this.copyTokConfirm}>Post</Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <List className={'qna-section-list-copy'} dense={true} style={{ maxWidth: '100%', paddingLeft: '25px' }} >
                                {CopyTokQnaSections ?
                                    CopyTokQnaSections.map((section, index) => (
                                        <ListItem className={'row'} sx={{ paddingTop: '0px', paddingBottom: '0px', display: 'list-item', flexShrink: 0, textAlign: "left" }}>
                                            <div className={'col-auto'} style={{ fontSize: "24px", fontWeight: "bold" }}>{tok.tok_group == "Mega" ? section.title : section.content}</div>
                                            {tok.tok_group == "Q&a" ?
                                                <div className={'col-12'} style={{ marginLeft: "15px" }}>
                                                    {section.question_answer ? section.question_answer.map((qna, qnaIndex) => (
                                                        <>
                                                            {qna.question ?
                                                                <>
                                                                    <div style={{ fontSize: "20px" }} className={'qna-section-item'}>
                                                                        <span className={'qna-section-item-before'}>Question {qnaIndex + 1}: </span>
                                                                        <span>{qna.question}</span>
                                                                    </div>
                                                                    <div className={'qna-section-item'} style={{ marginLeft: "10px" }}>
                                                                        <span className={'qna-section-item-before'}>Answer {qnaIndex + 1}: </span>
                                                                        <span style={{ fontSize: "16px", cursor: "pointer" }} data-section={index} data-qna={qnaIndex}>
                                                                            (Answer Hidden)
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginTop: "5px" }}>
                                                                        <TextField style={{ width: "100%" }} label={`Your Answer`} name={`${index}-${qnaIndex}`} onChange={this.setCopySectionAnswer} inputProps={{ maxLength: 600 }} />
                                                                        <p className={'character-count'}>{qna.answer ? qna.answer.length : "0"}/600</p>
                                                                    </div>
                                                                </> : ""}
                                                        </>
                                                    )) : ""}
                                                </div>
                                                : ""}
                                        </ListItem>
                                    )) : ""
                                }
                            </List>
                        </Modal.Body>
                    </Modal> : ""
                }

                {CopyTokShow ?
                    <Modal size='lg' show={CopyTokShow} onHide={this.closeModals} style={{ zIndex: "1301" }}>
                        <Modal.Header>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                                {GroupId && CopyTokShowMode == "Group" ?
                                    "Create Personal Copy" :
                                    "Copy Tok " + CopyTokShowMode
                                }
                                <div>
                                    {CopyRefreshView ?
                                        <Button variant="primary" onClick={this.refreshCopyOptions} style={{ marginRight: "5px" }}>
                                            <FontAwesomeIcon icon={faRefresh} />
                                        </Button> : ""
                                    }

                                    <Button variant="success" disabled={disableCopy} onClick={this.copyTokConfirm}>Copy</Button>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ height: "55vh", overflow: "auto", overflowX: "hidden" }}>
                            {CopyTokShowMode == "Channel" || CopyTokShowMode == "Community" ?
                                <Row className={'copytok-tokchannel-container'}>
                                    <Col className={'copytok-tokchannel-item'} xs={12}>
                                        <TextField style={{ width: "100%" }} select label="Level 1" name="level1" onChange={this.setCopyTokLevel}>
                                            {channels.map(channel => {
                                                if (channel.status == 1) {
                                                    return (
                                                        <MenuItem key={channel.ref} value={channel.ref}>
                                                            {channel.level}
                                                        </MenuItem>
                                                    )
                                                }
                                            })}
                                        </TextField>
                                    </Col>

                                    {CopyTokLevel2List.length > 0 ?
                                        <Col className={'copytok-tokchannel-item'} xs={12}>
                                            <TextField style={{ width: "100%" }} select label="Level 2" name="level2" onChange={this.setCopyTokLevel}>
                                                {CopyTokLevel2List.map(channel => {
                                                    return (
                                                        <MenuItem key={channel.ref} value={channel.ref}>
                                                            {channel.level}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </Col> : ""
                                    }
                                    {CopyTokLevel3List.length > 0 ?
                                        <Col className={'copytok-tokchannel-item'} xs={12}>
                                            <TextField style={{ width: "100%" }} select label="Level 3" name="level3" onChange={this.setCopyTokLevel}>
                                                {CopyTokLevel3List.map(channel => {
                                                    return (
                                                        <MenuItem key={channel.ref} value={channel.ref}>
                                                            {channel.level}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </Col> : ""
                                    }
                                    {CopyTokLevel3 && CopyTokShowMode == "Community" ?
                                        <>
                                            <div style={{ fontWeight: "600" }}>Communities:</div>
                                            {CommunityLoader ?
                                                <WaitSpinner /> :
                                                Communities ?
                                                    <RadioGroup name="copytok-group" onChange={this.setCopyCommunity} style={{ display: "flex", overflow: "hidden scroll", flexWrap: "nowrap", maxHeight: "230px" }}>
                                                        {Communities.map(community => {
                                                            return (
                                                                <Row className={'col-12'}>
                                                                    <Col>
                                                                        <Radio
                                                                            value={community.id}
                                                                        />
                                                                        {community.name}
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                    : ""
                                            }
                                        </> : ""
                                    }
                                </Row> :
                                CopyTokShowMode == "Group" ?
                                    GroupsCopyLoader ? <WaitSpinner /> :
                                        GroupsCopy ?
                                            <RadioGroup name="copytok-group" onChange={this.setCopyGroup}>
                                                {GroupsCopy.map(group => {
                                                    return (
                                                        <Row className={'col-12'}>
                                                            <Col>
                                                                <Radio
                                                                    value={group.id}
                                                                />
                                                                {group.name}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </RadioGroup> : ""
                                    : ""
                            }
                        </Modal.Body>
                    </Modal> : ""
                }
                {TokBackActive ?
                    <TokBack tok={tok} closeTokBack={this.closeTokBack} /> : ""
                }
                <Row>
                    <Col md={oldUiActive ? 4 : "auto"} sm={9} xs={9}>
                        <Row>
                            <Col className={'col-auto'} style={{ padding: 'unset' }}>
                                <div onClick={this.viewProfileUser} className={'tok-view-avatar'} style={{ backgroundImage: `url(${avatar})` }}></div>
                            </Col>
                            <Col>
                                <Row>
                                    <Col md={12}>
                                        <Row style={{ alignItems: "center" }}>
                                            <Col onClick={this.viewProfileUser} className={'col-auto'} style={{ paddingRight: 'unset' }}>
                                                <b>{tok.user_display_name}</b>
                                            </Col>
                                            <Col className={'col-auto'} style={{ paddingRight: '5px' }}>
                                                {relativeDate}
                                            </Col>
                                            <Col className={'col-auto'}>
                                                <Row style={{ alignItems: "center", alignContent: "center" }}>
                                                    <Col className={'col-auto'} style={{ padding: 'unset', paddingBottom: '1px' }}>
                                                        <RemoveRedEyeIcon />
                                                    </Col>
                                                    <Col className={'col-auto'} style={{ padding: 'unset' }}>
                                                        0
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col md={12} style={{ fontSize: "small" }}><b>{title}</b></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{ span: oldUiActive ? 6 : true, order: oldUiActive ? 1 : undefined }} sm={{ span: 12, order: oldUiActive ? 2 : undefined }} xs={{ span: 12, order: oldUiActive || mobileView ? 2 : undefined }}>
                        {oldUiActive ?
                            <Typography component="div" style={{ fontWeight: "bold", fontSize: "31px" }}>
                                {tok.primary_text}
                            </Typography> :
                            <>
                                <Row className={'tok-info'}>
                                    <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 2 } : {}}>
                                        <div><b>{isChannel ? "Subchannel" : "Category"}</b>: <a>{isChannel ? tok["level1"] : tok["category_id"] ? tok["category_id"] : tok["category"] ? tok["category"] : ""}</a></div>

                                    </Col>
                                    <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 1 } : {}}>
                                        <div><b>Format</b>: <a>{isChannel ? tok["level2"] : tok.tok_group} <ClassTokIcon icon={tok.tok_group} height={"21"} width={"21"} /></a></div>

                                    </Col>
                                    <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 3 } : {}}>
                                        <div><b>{isChannel ? "Channel" : "Class Name"}</b>: <a>{isChannel ? tok["level3"] : tok.category}</a></div>
                                    </Col>
                                    {isChannel ?
                                        <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 4 } : {}}>
                                            <div><b>Category</b>: <a>{tok["category_id"] ? tok["category_id"] : tok["category"] ? tok["category"] : ""}</a></div>
                                        </Col> : ""
                                    }
                                    <Col xs={"auto"} style={{ marginLeft: "auto" }}>
                                        <label className={'label-option orange-option'} onClick={this.setTokBack}>Tok Back</label>
                                    </Col>
                                </Row>
                                {/*Qna Row*/}
                                {tok["tok_group"] == "Q&a" ?
                                    <Row className={"qna-row"}>
                                        <div className={'qna-row-title'}>
                                            Q&a
                                        </div>
                                        {tok["master_copy"] ?
                                            <div className={'tok-info-detail-container'} style={{ color: "black", fontWeight: "400", width: "fit-content" }}>
                                                Creator's Copy
                                            </div> :
                                            <>
                                                {!tok.master_copy && tok["user_id"] == userid && GroupId ?
                                                    <>
                                                        <div className={'tok-info-detail-container'} style={{ width: "100%", color: "black", fontWeight: "bold" }}>
                                                            Group's Copy
                                                        </div>
                                                        <label className={"label-option green-option"} style={{ width: "unset" }} onClick={this.copyTokShow} data-type="qna">Create Answerable Post</label>
                                                    </> : ""
                                                }
                                                {tok["user_id"] == userid ?
                                                    <>
                                                        <Col xs={'auto'}>
                                                            <label className={"label-option green-option"} data-type="qna" onClick={this.answerEnabled}>{tok["answer_enabled"] ? "Hide" : "Show"} your own answers</label>
                                                        </Col>
                                                        <Col xs={'auto'}>
                                                            <label className={tok["answer_enabled"] ? "label-option green-option" : "label-option green-option disabled"} data-type="qna">Unlock User Answers</label>
                                                            {/*<label className={"label-option green-option"} data-type="qna" disabled={!tok["answer_enabled"]}>Unlock User Answers</label> */}
                                                        </Col>
                                                        <Col xs={'auto'}>
                                                            <label className={"label-option green-option"} onClick={this.copyTokShow} data-type="qna">Duplicate Q&A Tok</label>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>
                                                        <Col xs={'auto'}>
                                                            <label className={"label-option green-option"} onClick={this.addGroupAnswersSet} data-type="qna">Add User Answers</label>
                                                        </Col>
                                                        <Col xs={'auto'}>
                                                            <label className={"label-option green-option"} onClick={this.copyTokShow} data-type="qna">Duplicate Q&A Tok</label>
                                                        </Col>
                                                    </>}
                                            </>}
                                    </Row> : ""
                                }
                            </>
                        }

                    </Col>
                    <Col md={{ span: oldUiActive ? 2 : "auto", order: oldUiActive ? 2 : undefined }} sm={{ span: 3, order: oldUiActive ? 1 : undefined }} xs={{ span: 3, order: oldUiActive || mobileView ? 1 : undefined }}>
                        <Row style={{ alignItems: "center" }}>
                            <Dropdown className={'col-auto'} style={{ padding: "unset", margin: "unset" }}>
                                <Dropdown.Toggle variant="success" id="manage-toktileview" style={{ fontSize: "1rem", backgroundColor: "unset", color: "var(--bs-link-color)", border: "unset", padding: "unset", position: "relative", margin: "unset", fontWeight: "bold" }}>
                                    Manage
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {tok["user_id"] == userid ? <Dropdown.Item onClick={this.editTok}>Edit</Dropdown.Item> : ""}
                                    {items != "bookmarks" ? <Dropdown.Item onClick={this.bookmarkTok}>Save</Dropdown.Item> : ""}
                                    {tok["user_id"] == userid ? <Dropdown.Item onClick={this.deleteTok}>{items == "bookmarks" ? "Remove Bookmark" : "Delete"}</Dropdown.Item> : ""}
                                    <Dropdown.Item onClick={this.copyTokShow}>Copy</Dropdown.Item>
                                    <Dropdown.Item onClick={this.reportTok}>Report Tok</Dropdown.Item>
                                    <Dropdown.Item onClick={this.toggleUi}>Toggle View</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: oldUiActive ? 3 : "auto", order: oldUiActive ? 3 : undefined }} sm={{ span: 12, order: oldUiActive ? 4 : undefined }} xs={{ span: 12, order: oldUiActive ? 4 : undefined }}>
                        {oldUiActive ?
                            <Row className={'tok-info'}>
                                <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 2 } : {}}>
                                    <div><u>{isChannel ? "Subchannel" : "Category"}</u></div>
                                    <div><a>{isChannel ? tok["level1"] : tok["category_id"] ? tok["category_id"] : tok["category"] ? tok["category"] : ""}</a></div>
                                </Col>
                                <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 1 } : {}}>
                                    <div><u>Type</u></div>
                                    <div><a>{isChannel ? tok["level2"] : tok.tok_group}</a></div>
                                </Col>
                                <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 3 } : {}}>
                                    <div><u>{isChannel ? "Channel" : "Class Name"}</u></div>
                                    <div><a>{isChannel ? tok["level3"] : tok.category}</a></div>
                                </Col>
                                {isChannel ?
                                    <Col className={'tok-info-detail-container'} xs={isChannel ? { order: 4 } : {}}>
                                        <div><u>Category</u></div>
                                        <div><a>{tok["category_id"] ? tok["category_id"] : tok["category"] ? tok["category"] : ""}</a></div>
                                    </Col> : ""
                                }
                                {tok.master_copy ?
                                    <div className={'tok-info-detail-container'} style={{ width: "100%", color: "red", fontWeight: "bold" }}>
                                        Creator's Copy
                                    </div> : !tok.master_copy && tok["user_id"] == userid && GroupId ?
                                        <div className={'tok-info-detail-container'} style={{ width: "100%", color: "red", fontWeight: "bold" }}>
                                            Group's Copy
                                        </div>
                                        : ""}

                            </Row> : ""}
                        <ReactionContainer TokSection={undefined} QuestionNum={undefined} fbShare={undefined} shareLink={undefined} groupId={undefined} linkZIndex={undefined} DetailNum={undefined} LeftDisplay={undefined} enableShare={true} reactionSwitchUiRef={this.reactionSwitchUiRef} columnView={!oldUiActive} updateTok={this.updateTok} item={tok} key={tok["id"] + "-" + tok["pk"] + "-reaction"} commentCounterRef={this.CommentCounterRef} OwnerId={tok["user_id"] ? tok["user_id"] : ""} Id={tok["id"] ? tok["id"] : ""} Pk={tok["pk"] ? tok["pk"] : ""} />
                        {/* 
                        <div style={{ marginTop: "5px" }}></div>
                        */}
                        {mobileView ?
                            <Col md={{ span: oldUiActive ? 7 : true, order: oldUiActive ? 4 : undefined }} sm={{ span: 12, order: oldUiActive ? 3 : undefined }} xs={{ span: 12, order: oldUiActive ? 3 : undefined }}>
                                {!oldUiActive ?
                                    <Typography component="div" style={{ fontWeight: "bold", fontSize: "31px" }}>
                                        {tok.primary_text}
                                    </Typography> : ""
                                }

                                {tok.image ?
                                    <>
                                        {isYoutubeLink(tok.image) ?
                                            <YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(tok.image)} /> :
                                            <img src={tok.image} style={{ maxWidth: "100%" }} />
                                        }
                                    </>
                                    : ""
                                }
                                {secondary}
                                {!oldUiActive && (tok["notes"] || tok["source_link"] || tok["source_notes"]) ?
                                    <>
                                        {tok["notes"].length ?
                                            <div style={{ fontSize: "13px" }}>
                                                <span style={{ fontWeight: "600" }}>Note: </span>
                                                <span style={{ fontStyle: "italic" }}>{tok["notes"]}</span>
                                            </div> : ""
                                        }
                                        {tok["source_notes"].length ?
                                            <div style={{ fontSize: "13px" }}>
                                                <span style={{ fontWeight: "600" }}>Source Note: </span>
                                                <span style={{ fontStyle: "italic" }}>{tok["source_notes"]}</span>
                                            </div> : ""
                                        }
                                        {tok["source_link"].length ?
                                            <div style={{ fontSize: "13px" }}>
                                                <span style={{ fontWeight: "600" }}>Source Link: </span>
                                                <Link href={tok["source_link"]} style={{ fontStyle: "italic" }} target={'_blank'}>
                                                    {tok["source_link"]}
                                                </Link>
                                            </div> : ""
                                        }
                                    </> : ""
                                }
                            </Col> : ""
                        }
                        <div style={{ marginTop: "5px", display: oldUiActive || mobileView ? "" : "flex", justifyContent: oldUiActive || mobileView ? "unset" : "center" }}>
                            <Comments commentSwitchUiRef={this.commentSwitchUiRef} iconMode={!oldUiActive} detailNo={-1} key={tok["id"] + "-" + tok["pk"] + "-comments"} viewProfileSet={this.viewProfileSet} commentCounterRef={this.CommentCounterRef} hideCommentButton={undefined} itemId={tok.id} item={tok} Pk={tok["pk"] ? tok["pk"] : ""} userCommentActive={true} commentsViewActive={true} iconModeSize={undefined} disableInitialization={undefined} />
                        </div>
                    </Col>
                    {!mobileView ?
                        <Col md={{ span: oldUiActive ? 7 : true, order: oldUiActive ? 4 : undefined }} sm={{ span: 12, order: oldUiActive ? 3 : undefined }} xs={{ span: 12, order: oldUiActive ? 3 : undefined }}>
                            {!oldUiActive ?
                                <Typography component="div" style={{ fontWeight: "bold", fontSize: "31px" }}>
                                    {tok.primary_text}
                                </Typography> : ""
                            }

                            {tok.image ?
                                <>
                                    {isYoutubeLink(tok.image) ?
                                        <YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(tok.image)} /> :
                                        <img src={tok.image} style={{ maxWidth: "100%" }} />
                                    }
                                </>
                                : ""
                            }
                            {secondary}
                            {!oldUiActive && (tok["notes"] || tok["source_link"] || tok["source_notes"]) ?
                                <>
                                    {tok["notes"].length ?
                                        <div style={{ fontSize: "13px" }}>
                                            <span style={{ fontWeight: "600" }}>Note: </span>
                                            <span style={{ fontStyle: "italic" }}>{tok["notes"]}</span>
                                        </div> : ""
                                    }
                                    {tok["source_notes"].length ?
                                        <div style={{ fontSize: "13px" }}>
                                            <span style={{ fontWeight: "600" }}>Source Note: </span>
                                            <span style={{ fontStyle: "italic" }}>{tok["source_notes"]}</span>
                                        </div> : ""
                                    }
                                    {tok["source_link"].length ?
                                        <div style={{ fontSize: "13px" }}>
                                            <span style={{ fontWeight: "600" }}>Source Link: </span>
                                            <Link href={tok["source_link"]} style={{ fontStyle: "italic" }} target={'_blank'}>
                                                {tok["source_link"]}
                                            </Link>
                                        </div> : ""
                                    }
                                </> : ""
                            }
                        </Col> : ""
                    }
                    {/* Turn Below to dropdown/ Put at info bar/ Need details in regard to higher levels in channels before doing it */}
                    {tok["posted_to"] || tok["copied_from"] ?
                        <Col md={{ span: "auto" }}>
                            {tok["posted_to"] ?
                                <>{tok["posted_to"].length > 0 ?
                                    <div>
                                        <Typography style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "underline" }}>Copied To:</Typography>
                                        <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px", padding: "unset" }} >
                                            {tok["posted_to"].map((postedto) => (
                                                <ListItem style={{ padding: "unset", display: "list-item" }}>
                                                    <ListItemText
                                                        primary={postedto["feed"] ? <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>{textDisplay(postedto["feed"])}</Typography> : ""}
                                                        style={{ margin: "unset" }}
                                                    />
                                                    {postedto["subfeed"] ?
                                                        <Subfeed postto={postedto["subfeed"]} decrementalFontSize={14} /> : ""
                                                    }
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div> : ""}</>
                                : ""
                            }
                            {tok["copied_from"] ?
                                <>
                                    {tok["copied_from"].length > 0 ?
                                        <div>
                                            <Typography style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "underline" }}>Copied From:</Typography>
                                            <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px", padding: "unset" }} >
                                                {tok["copied_from"].map((copiedto) => (
                                                    <ListItem style={{ padding: "unset", display: "list-item" }}>
                                                        <ListItemText className={'elipsis-one-line-parent'}
                                                            primary={copiedto["feed"] ? <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>{textDisplay(copiedto["feed"])}</Typography> : ""}
                                                            style={{ margin: "unset" }}
                                                        />
                                                        {copiedto["subfeed"] ?
                                                            <Subfeed postto={copiedto["subfeed"]} decrementalFontSize={18} /> : ""
                                                        }
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                        : ""}
                                </>

                                : ""
                            }
                        </Col> : ""
                    }
                    {oldUiActive ?
                        <>
                            <Col md={{ span: 2, order: 5 }} sm={{ span: 12, order: 5 }} xs={{ span: 12, order: 5 }}>
                                <Button className={'tok-back-btn'} onClick={this.setTokBack}>Tok Back</Button>
                                {tok["posted_to"] ?
                                    <>{tok["posted_to"].length > 0 ?
                                        <div>
                                            <Typography style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "underline" }}>Copied To:</Typography>
                                            <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px", padding: "unset" }} >
                                                {tok["posted_to"].map((postedto) => (
                                                    <ListItem style={{ padding: "unset", display: "list-item" }}>
                                                        <ListItemText
                                                            primary={postedto["feed"] ? <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>{textDisplay(postedto["feed"])}</Typography> : ""}
                                                            style={{ margin: "unset" }}
                                                        />
                                                        {postedto["subfeed"] ?
                                                            <Subfeed postto={postedto["subfeed"]} decrementalFontSize={14} /> : ""
                                                        }
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div> : ""}</>
                                    : ""
                                }
                                {tok["copied_from"] ?
                                    <>
                                        {tok["copied_from"].length > 0 ?
                                            <div>
                                                <Typography style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "underline" }}>Copied From:</Typography>
                                                <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px", padding: "unset" }} >
                                                    {tok["copied_from"].map((copiedto) => (
                                                        <ListItem style={{ padding: "unset", display: "list-item" }}>
                                                            <ListItemText className={'elipsis-one-line-parent'}
                                                                primary={copiedto["feed"] ? <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>{textDisplay(copiedto["feed"])}</Typography> : ""}
                                                                style={{ margin: "unset" }}
                                                            />
                                                            {copiedto["subfeed"] ?
                                                                <Subfeed postto={copiedto["subfeed"]} decrementalFontSize={18} /> : ""
                                                            }
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                            : ""}
                                    </>

                                    : ""
                                }
                            </Col>
                            {tok["notes"] || tok["source_link"] || tok["source_notes"] ?
                                <Col md={{ span: 6, order: 5, offset: 4 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                                    {tok["notes"].length ?
                                        <div style={{ fontSize: "13px" }}>
                                            <span style={{ fontWeight: "600" }}>Note: </span>
                                            <span style={{ fontStyle: "italic" }}>{tok["notes"]}</span>
                                        </div> : ""
                                    }
                                    {tok["source_notes"].length ?
                                        <div style={{ fontSize: "13px" }}>
                                            <span style={{ fontWeight: "600" }}>Source Note: </span>
                                            <span style={{ fontStyle: "italic" }}>{tok["source_notes"]}</span>
                                        </div> : ""
                                    }
                                    {tok["source_link"].length ?
                                        <div style={{ fontSize: "13px" }}>
                                            <span style={{ fontWeight: "600" }}>Source Link: </span>
                                            <Link href={tok["source_link"]} style={{ fontStyle: "italic" }} target={'_blank'}>
                                                {tok["source_link"]}
                                            </Link>
                                        </div> : ""
                                    }
                                </Col> : ""
                            }
                        </> : ""
                    }
                </Row>
                <div className={"exception"}>
                    <Toaster />
                </div>
                <ReactSizeResponce response={this.sizeResponse} />
            </div >
        )
    }
}
export default TokView;