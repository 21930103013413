import React from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal from 'react-bootstrap/Modal';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Button } from 'react-bootstrap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { idFormat } from '../services/helperTools';
import { addReaction } from '../services/reactionServices';
import { Reaction } from '../interfaces/ReactionInterface';

const MySwal = withReactContent(Swal);

const reportList = [
    {
        display_message: "Sexual Content", message: "Sexual Content",
        sub_report: [
            { display_message: "Graphic Sexual Activity", message: "Graphic sexual activity" },
            { display_message: "Nudity", message: "Nudity" },
            { display_message: "Suggestive, but Without Nudity", message: "Suggestive, but without nudity" },
            { display_message: "Content Involving Minors", message: "Content involving minors" },
            { display_message: "Abusive Title or Description", message: "Abusive title or description" },
            { display_message: "Other Sexual Content", message: "Other sexual content" }
        ]
    },
    {
        display_message: "Violent or Repulsive Content", message: "Violent or repulsive content",
        sub_report: [
            { display_message: "Adults Fighting", message: "Adults fighting" },
            { display_message: "Physical Attack", message: "Physical attack" },
            { display_message: "Youth violence", message: "Youth violence" },
            { display_message: "Animal abuse", message: "Animal abuse" }
        ]
    },
    {
        display_message: "Harmful dangerous acts", message: "Harmful dangerous acts",
        sub_report: [
            { display_message: "Pharmaceutical or drug abuse", message: "Pharmaceutical or drug abuse" },
            { display_message: "Abuse of fire or explosives", message: "Abuse of fire or explosives" },
            { display_message: "Suicide or self injury", message: "Suicide or self injury" },
            { display_message: "Other dangerous acts", message: "Other dangerous acts" },
        ]
    },
    {
        display_message: "Child Abuse", message: "Child abuse",
    },
    {
        display_message: "Promotes Terrorism", message: "Promotes terrorism",
    },
    {
        display_message: "Spam or Misleading", message: "Spam or misleading",
        sub_report: [
            { display_message: "Mass Advertising", message: "Mass advertising" },
            { display_message: "Pharmaceutical Drugs for Sale", message: "Pharmaceutical drugs for sale" },
            { display_message: "Misleading Text", message: "Misleading text" },
            { display_message: "Misleading Thumbnail", message: "Misleading thumbnail" },
            { display_message: "Scams / Fraud", message: "Scams / Fraud" },
        ]
    },
    {
        display_message: "Infringes my Rights", message: "Infringes my rights",
        sub_report: [
            { display_message: "Infringes my Copyright", message: "Infringes my copyright" },
            { display_message: "Invades my Privacy", message: "Invades my privacy" },
            { display_message: "Other Legal Claim", message: "Other legal claim" },
        ]
    },
]
interface SubReport {
    display_message: string;
    message: string
}
interface Report extends SubReport {
    sub_report: SubReport[]
}
interface States {
    itemLabel: string;
    label: string;
    itemId: string;
    activeReport: Report;
    subActiveReport: SubReport;
    show: boolean;
    reports: Report[];
}

interface Props {
    itemLabel: string;
    label: string;
    id: string;
    reportItemRef: React.RefObject<any>;
}

class ReportItem extends React.Component<Props, States> {
    reportItemRef: React.RefObject<any>;
    constructor(props) {
        super(props);
        this.state = {
            itemLabel: props["itemLabel"] ? props["itemLabel"] : "",
            label: props["label"] ? props["label"] : "",
            itemId: props["id"] ? props["id"] : "",
            activeReport: {} as Report,
            subActiveReport: {} as SubReport,
            show: false,
            reports: reportList as Report[]
        };

        this.reportItemRef = props.reportItemRef;
        this.setReport = this.setReport.bind(this);
        this.closeReport = this.closeReport.bind(this);
        this.submitReport = this.submitReport.bind(this);
        this.showReport = this.showReport.bind(this);
        this.setSubReport = this.setSubReport.bind(this);
    }

    setReport(e) {
        const { reports } = this.state;
        const value = e.target.value;

        this.setState({
            activeReport: reports.filter(report => report.message == value)[0],
            subActiveReport: {} as SubReport
        });
    }
    setSubReport(e) {
        const { activeReport } = this.state;
        const value = e.target.value;
        this.setState({
            subActiveReport: activeReport["sub_report"].filter(report => report.message == value)[0]
        });
    }
    closeReport() {
        this.setState({
            show: false,
            activeReport: {} as Report,
            subActiveReport: {} as SubReport
        });
    }
    showReport() {
        this.setState({
            show: true
        });
    }
    submitReport() {
        const { itemLabel, label, itemId, activeReport, subActiveReport } = this.state;
        const reportItem = {
            label: label ? label : "report",
            item_label: itemLabel ? itemLabel : "",
            item_id: itemId ? itemId : "",
            message: subActiveReport["message"] ? subActiveReport["message"] : activeReport["message"] ? activeReport["message"] : ""
            //Add User Info on service.
        }
        MySwal.fire({
            title: <p>Submitting Report</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        addReaction(reportItem, "", "reports").then(res => {
            if(res){
                if (res["data"]["status_code"] == 200 && res["data"]["result"]) {
                    MySwal.update({
                        title: <p>Report Submitted.</p>,
                        icon: "success"
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    render() {
        const { show, reports, activeReport, subActiveReport } = this.state;
        return (
            <>
                {/* Adjust Button to UI if needed */}
                <Button ref={this.reportItemRef} onClick={this.showReport} hidden />
                <Modal size='lg' show={show} onHide={this.closeReport} style={{ zIndex: "1207" }}>
                    <Modal.Header>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeReport} />
                            Report Item
                            <Button variant="success" onClick={this.submitReport} disabled={!activeReport["message"]}>Report</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <RadioGroup name="report-list" onChange={this.setReport}>
                            {reports.map(report => {
                                return (
                                    <Row className={'col-12'}>
                                        <Col>
                                            <Radio
                                                value={report.message}
                                            />
                                            {report.display_message}
                                        </Col>
                                        {activeReport["message"] ?
                                            activeReport["message"] == report.message && report["sub_report"] ?
                                                <Col xs={12} style={{ padding: "8px 15px 0px" }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`${"report-sub-label" + idFormat(activeReport["message"])}`}>Choose One</InputLabel>
                                                        <Select
                                                            labelId={`${"report-sub-label" + idFormat(activeReport["message"])}`}
                                                            id={`${"report-sub-" + idFormat(activeReport["message"])}`}
                                                            onChange={this.setSubReport}
                                                        >
                                                            {report["sub_report"].map(subreport => {
                                                                return (
                                                                    <MenuItem value={subreport.message}>{subreport.display_message}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Col> : ""
                                            : ""
                                        }
                                    </Row>
                                )
                            })}
                        </RadioGroup>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ReportItem;