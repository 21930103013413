import React, { createRef, useRef, useState } from 'react'
import '../styles.css'
import './css/ClassSetsContainer.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from 'react-bootstrap';
import Typography from "@mui/material/Typography";
import TokTileContainer from './TokTileContainer';
import AddEditClassSet from './AddEditClassSet';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteSet } from '../services/classServices';
import { getToken } from '../services/accountServices';
import AddToksToSet from './AddToksToSet';
import RemoveToksToSet from './RemoveToksToSet';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Set } from '../interfaces/SetInterface';
import { Tok } from '../interfaces/TokInterface';
const MySwal = withReactContent(Swal);

interface States {
    LoadActive: boolean;
    Set: Set;
    closeButton: React.RefObject<any>;
    Toks: Tok[];
}

interface Props {
    removeSet: (data: any) => void;
    updateSet: (data: any) => void;
    closeView: (data: any) => void;
    set: Set;
    closeButton: boolean;
}

class SetView extends React.Component<Props, States> {
    addClassSetTrigger: React.RefObject<any>;
    addToksToSetTrigger: React.RefObject<any>;
    removeToksToSetTrigger: React.RefObject<any>;
    addExistingClassTokTransferRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            LoadActive: false,
            Set: props.set,
            closeButton: props.closeButton,
            Toks: {} as Tok[]
        };

        this.updateSet = this.updateSet.bind(this);
        this.deleteSetConfirmation = this.deleteSetConfirmation.bind(this);

        this.addClassSetTrigger = createRef();
        this.addClassSetCall = this.addClassSetCall.bind(this);
        this.closeSet = this.closeSet.bind(this);
        this.addToksToSetTrigger = createRef();
        this.addToksToSetCall = this.addToksToSetCall.bind(this);

        this.setTokData = this.setTokData.bind(this);
        this.removeToksToSetTrigger = createRef();
        this.removeToksToSetCall = this.removeToksToSetCall.bind(this);

        this.addExistingClassTokTransferRef = createRef();

    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.getUserToken();
    }
    async getUserToken() {
        await getToken();
    }

    deleteSetConfirmation(e) {
        const { Set } = this.state;
        MySwal.fire({
            title: <p>Are you sure want to delete?</p>,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Set</p>,
                    allowOutsideClick: false,
                });
                MySwal.showLoading()
                try {
                    deleteSet(Set).then(result => {
                        if (result) {
                            if (result.status == 200) {
                                MySwal.update({
                                    title: <p>Set Deleted</p>,
                                    icon: "success"
                                });
                                try {
                                    this.removeSet();
                                } catch (err) {
                                }
                            } else {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }).catch(err => {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }).finally(() => {
                        MySwal.hideLoading();
                    });
                } catch (err) {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                }
            }
        })
    }
    removeSet() {
        const { Set } = this.state;
        this.props.removeSet(Set);
    }
    updateSet(e) {
        var { Set, Toks } = this.state;
        let tempToks = Toks["items"] ? Toks["items"] : {}
        try {
            if (e["ids"]) {
                for (let x = 0; x < tempToks.length; x++) {
                    let removeTok = true;
                    for (let y = 0; y < e["ids"].length; y++) {
                        if (tempToks[x]["id"] == e["ids"][y] && tempToks[x]["pk"] == e["tokpks"][y]) {
                            removeTok = false;
                            break;
                        }
                    }
                    if (removeTok) {
                        tempToks.splice(x, 1);
                    }
                }
            }

        } catch (err) {
        }
        if (e["addtoks"]) {
            let tempToAddToks = e["tokstobeadded"];
            if (e["ids"]) {
                for (let x = 0; x < tempToAddToks.length; x++) {
                    for (let y = 0; y < e["ids"].length; y++) {
                        if (tempToAddToks[x]["id"] == e["ids"][y]) {
                            tempToAddToks[x]["pk"] = e["tokpks"][y]
                            break;
                        }
                    }
                }
            }
            tempToks = tempToks.concat(e["tokstobeadded"]);
            this.addExistingClassTokTransferRef.current.setAttribute("data-data", JSON.stringify(tempToAddToks));
        } else {
            this.addExistingClassTokTransferRef.current.setAttribute("data-data", JSON.stringify(tempToks));
            this.addExistingClassTokTransferRef.current.setAttribute("data-overwrite", true);
        }
        this.addExistingClassTokTransferRef.current.click();
        Toks["items"] = tempToks;
        try {
            this.props.updateSet(e);
        } catch (err) { }
        this.setState({
            Set: e,
            Toks: Toks
        });
    }
    addClassSetCall() {
        this.addClassSetTrigger.current.click();
    }
    closeSet() {
        this.props.closeView(true);
    }

    addToksToSetCall() {
        this.addToksToSetTrigger.current.click();
    }

    setTokData(toks) {
        this.setState({
            Toks: toks
        })
    }
    removeToksToSetCall() {
        const { Toks } = this.state;
        let tokString = JSON.stringify(Toks["items"]);

        this.removeToksToSetTrigger.current.setAttribute("data-toks", tokString);
        this.removeToksToSetTrigger.current.setAttribute("data-token", Toks["continuationToken"]);
        this.removeToksToSetTrigger.current.click();
    }
    render() {
        const { Set, closeButton, Toks } = this.state;
        console.log(Set);
        return (
            <div>
                <Row>
                    {closeButton ? <Col md={{ order: 1, span: "auto" }} xs={{ order: 1, span: 6 }}><Button onClick={this.closeSet}>Back</Button></Col> : ""}


                    <Col md={{ order: 2, span: true }} xs={{ order: 3, span: 12 }}>
                        {Set["image"] ?
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <PhotoProvider>
                                    <PhotoView src={Set["image"]}>
                                        <img src={Set["image"]} alt="" style={{ cursor: "pointer", maxHeight: "500px", maxWidth: "100%", borderRadius: "8px" }} />
                                    </PhotoView>
                                </PhotoProvider>
                            </div> : ""
                        }
                        <Typography fontSize={"36px"} fontWeight={"bold"} textAlign={"center"}>{Set.name}</Typography>
                        <Typography color={"#818181"} fontSize={"16px"} textAlign={"center"}>
                            Type: {Set["tok_group"] == "Playable" || Set["tok_group"] == "Non-Playable" ? Set["tok_group"] :
                                <>{Set["tok_group"] == "Mega" ? "Non-Playable" : "Playable"}</>
                            } | Category: {Set.category} <br />
                            {Set.description}
                        </Typography>
                    </Col>
                    <Col md={{ order: 3, span: "auto", offset: 0 }} xs={{ order: 2, span: 6, offset: closeButton ? undefined : 6 }} style={{ textAlign: "right" }}>
                        <Dropdown style={{ marginLeft: "auto" }}>
                            <Dropdown.Toggle variant='secondary' className={'dropdown-remove'}>
                                Manage Set
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>Print</Dropdown.Item>
                                <Dropdown.Item onClick={this.addClassSetCall}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={this.deleteSetConfirmation}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Typography color={"#212529"} fontSize={"20px"} textAlign={"center"}>{Toks["items"] ? Toks["items"].length : "0"} Toks</Typography>
                    </Col>
                    <Col md={4} xs={12} style={{ cursor: 'pointer', height: "101px", backgroundImage: "url(https://tokketcontent.blob.core.windows.net/tokketweb/tokcards.jpg)", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></Col>
                    <Col md={4} xs={12} style={{ cursor: 'pointer', height: "101px", backgroundImage: "url(https://tokketcontent.blob.core.windows.net/tokketweb/tokmatch.jpg)", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></Col>
                    <Col md={4} xs={12} style={{ cursor: 'pointer', height: "101px", backgroundImage: "url(https://tokketcontent.blob.core.windows.net/tokketweb/tokchoice_rectangle_violet.jpg)", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Col xs={'auto'} style={{ marginLeft: "auto", padding: "unset" }}>
                        <Button onClick={this.addToksToSetCall}>+ Add Toks to Set</Button>
                    </Col>
                    <Col xs={'auto'}>
                        <Button variant={'danger'} onClick={this.removeToksToSetCall}>- Remove Toks from Set</Button>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <TokTileContainer bgColor={undefined} fontColor={undefined} proxyTitleBarActive={undefined} levelTitle={undefined} CommunityId={undefined} CommunityName={undefined} groupName={undefined} itemBase={undefined} tokTileTitleBar={undefined} title={undefined} items={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={this.addExistingClassTokTransferRef} saveTokData={this.setTokData} CardViewOption={true} DisableNavbar={true} query={{ set_id: Set.id }} />
                </div>
                <AddEditClassSet addClassSetTrigger={this.addClassSetTrigger} set={Set} updateSet={this.updateSet} />
                <AddToksToSet addToktoSetShow={undefined} set={Set} updatedSet={this.updateSet} addToksToSetTrigger={this.addToksToSetTrigger} />
                <RemoveToksToSet set={Set} toks={Toks["items"]} token={Toks["continuationToken"]} updatedSet={this.updateSet} removeToksToSetTrigger={this.removeToksToSetTrigger} />
            </div>
        );
    }
}

export default SetView;