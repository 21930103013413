import axios from 'axios';
import { getAuth,onAuthStateChanged } from 'firebase/auth';
import { getSetToken, getSetUserId } from './accountServices';
const apiUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;
const apiCode = process.env.REACT_APP_API_CODE;
var token = window.localStorage.getItem("authToken")? window.localStorage.getItem("authToken") : "";

var userdata = [];
if(window.localStorage.getItem("userdata")){
    try {
        userdata = JSON.parse(window.localStorage.getItem("userdata"));
    } catch(err) { }
}

export async function deleteTok(id) {
    if(id){
        return await axios.delete(`${apiUrl}${apiVersion}/tok/${id}?code=${apiCode}`,
        {
            headers: {
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'Authorization': "Bearer " + await getSetToken()
            }
        });
    }else{
        return false;
    }   
}

export async function addTok(tok) {
    if(tok){
        return await axios.post(`${apiUrl}${apiVersion}/tok?code=${apiCode}`,tok,
        {
            headers: {
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'items': tok['items']? tok['items']: "",
                'tokgroupid': tok['tokgroupid']? tok['tokgroupid']: "",
                'toktypeid': tok['toktypeid']? tok['toktypeid']: "",
                'categoryid': tok['categoryid']? tok['categoryid']: "",
                'Authorization': "Bearer " + await getSetToken(),
                'userid': await getSetUserId(),
            }
        });
    }else{
        return false;
    }   
}

export async function updateTok(tok) {
    if(tok){
        return await axios.put(`${apiUrl}${apiVersion}/tok/${tok["id"]}?code=${apiCode}`,tok,
        {
            headers: {
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'Authorization': "Bearer " + await getSetToken(),
                'userid': await getSetUserId()
            }
        });
    }else{
        return false;
    }   
}

export async function getTokPaks(query = []) {
    if(await getSetUserId() && token){
        return await axios.get(`${apiUrl}${apiVersion}/tokpaks?code=${apiCode}`,
        {
            headers: {
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'Authorization': "Bearer " + await getSetToken(),
                'userid': await getSetUserId(),
                'groupid': query["group_id"]? query["group_id"]: "",
                'tokpaktype': query["tokpaktype"]? query["tokpaktype"]: "",
                'pk': query["pkbase"]? query["pkbase"]: "",
            }
        });
    }else{
        return false;
    }   
}

export async function deleteTokPak(id, pk) {
    if(await getSetUserId() && token && id && pk){
        return await axios.delete(`${apiUrl}${apiVersion}/tokpak/${id}?code=${apiCode}`,
        {
            headers: {
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk,
                'userid': await getSetUserId()
            }
        });
    }else{
        return false;
    }   
}