import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import './css/TokChannelTitlebar.css';
import { randomString, textDisplay } from '../services/helperTools';

const TokChannelTitlebar = (props) => {
    const [open, setLevel] = React.useState(false);
    const settingLevel = (level) => {
        setLevel(level);
        props.sendData(level);
    };
    const LevelDetail = props.LevelDetail;
    const Level1 = props.Level1;
    const Level2 = props.Level2;
    const Level3 = props.Level3;
    const CurrentLevel = props.CurrentLevel;
    const baseChannelHref = props.classchannel ? "/classchannels/" : "/tokchannels/";
    var baseHref = baseChannelHref + Level1;
    var currentHref = baseChannelHref + Level1;
    const PreviousLevel = props.previousLevel;
    if (CurrentLevel == "2") {
        baseHref = baseChannelHref + Level1 + "/" + Level2;
        currentHref = baseHref + (Level3 ? "/" + Level3 : "");
    } else if (CurrentLevel == "1") {
        currentHref = baseChannelHref + Level1 + (Level2 ? "/" + Level2: "");
    }
    function renderTokChannelPage(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        const hrefParts = event.currentTarget.getAttribute("href").split("/");
        let level1 = "";
        let level2 = "";
        let level3 = "";
        const color = event.currentTarget.getAttribute("data-color");
        if (hrefParts[2]) {
            level1 = hrefParts[2];
            if (hrefParts[3]) {
                level2 = hrefParts[3];
                if (hrefParts[4]) {
                    level3 = hrefParts[4];
                }
            }
        }
        const query = {
            level0: level1,
            level1: level2,
            level2: level3,
            color: color
        }
        props.tokchannelData(query);
        props.updateLeftNav(query);
    }
    if (props.LevelDetail) {
        var hrefLinks = new Array;
        var keyList = new Array;
        LevelDetail.forEach(channel => {
            let level = channel["level2"] ? channel["level2"] : channel["level1"] ? channel["level1"] : channel["level0"] ? channel["level0"] : "";

            const tempHref = baseHref + (baseHref.slice(baseHref.length - level.length) == level ? "" : "/" + level);
            keyList.push(randomString(10));
            hrefLinks.push(tempHref);
        });
        return (
            <Box className={'tokchannel-pill-container'}>
                {LevelDetail.map((level, index) => (
                    <a data-color={level["color"] ? level["color"] : ""} onClick={renderTokChannelPage} className={hrefLinks[index] == currentHref? "tokchannel-pill active" : "tokchannel-pill"} href={hrefLinks[index]} key={keyList[index]} style={{ display: 'block', borderColor: level["color"] ? `#${level["color"]}` : "", backgroundColor: hrefLinks[index] == currentHref ? `#${level["color"]}` : "" }}>
                        {hrefLinks[index] == baseHref? "All ": ""} {level["name"]? level["name"]: textDisplay(level["level2"] ? level["level2"] : level["level1"] ? level["level1"] : level["level0"] ? level["level0"] : "")}
                    </a>
                ))}
            </Box>
        );
    } else {
        return (
            <Box className={'tokchannel-pill-container'}>
                <a data-color={ ""} className={'tokchannel-pill active'} href={baseHref} style={{ display: 'block', borderColor:  "", backgroundColor: "", padding: "unset" }}>
                    {PreviousLevel.toLowerCase() == "owned" ? "" : "All"} {PreviousLevel}
                </a>
            </Box>
        )
    }
}
export default TokChannelTitlebar