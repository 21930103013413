import React from 'react'
import { useEffect } from 'react';
import '../styles.css'
import Grid from "@mui/material/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { sendRequestGroup } from '../services/classServices';
const GroupTile = (props) => {
    const MySwal = withReactContent(Swal);
    const card = props.card;
    const color = props.color;
    var userid = "";
    try {
        const localUser = JSON.parse(localStorage.getItem("userdata"));
        userid = localUser.id;
    } catch (err) { }
    function cardSendBack() {
        props.receiveData(card)
    }
    const ownerAvatar = "https://tokketcontent.blob.core.windows.net/tokketweb/default.png";
    function requestJoined(e) {
        const request = {
            label: "classgrouprequest",
            receiver_id: card["user_id"] ? card["user_id"] : "",
            sender_id: userid,
            name: card["name"] ? card["name"] : "",
            status: 0,
            remarks: "Pending",
            message: "Request to join group.",
            group_id: card["id"] ? card["id"] : "",
            group_pk: card["pk"] ? card["pk"] : "",
            members: card["members"] ? Number(card["members"]): 0,
            group_kind: card["group_kind"] ? card["group_kind"] : "",
            description: card["description"] ? card["description"] : "",
            school: card["school"] ? card["school"] : "",
            image: card["image"] ? card["image"] : "",
            group_owner_id: card["user_id"] ? card["user_id"] : "",
        }
        MySwal.fire({
            title: <p>Request to join group.</p>,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Send',
            icon: "question",
            confirmButtonColor: "#388e3c"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Sending Request</p>,
                    allowOutsideClick: false,
                    confirmButtonText: 'Requested'
                });
                MySwal.showLoading();
                sendRequestGroup(request).then(response => {
                    MySwal.hideLoading();
                    if(response){
                        if (response["status"] == 200) {
                            MySwal.update({
                                title: 'Request sent.',
                                icon: "success"
                            });
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }
                }).catch((result) => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                });
            }
        });
        e.stopPropagation();
    }
    return (
        <Grid className={`grouptile-${card.id}-${card.pk}`} item key={card} xs={12} sm={6} md={4} lg={4} >
            <Container onClick={cardSendBack} style={{ height: "300px", color: "white", backgroundColor: `#${color}`, cursor: "pointer", padding: "unset" }}>
                <Container style={{ fontWeight: "bold", fontSize: "28px", textAlign: "center", backgroundColor: "#f7f7f7", color: "black", minHeight: "50px", padding: ".75rem 1.25rem" }}>
                    <div>{card.name}</div>
                    <div style={{ fontSize: "14px", fontWeight: "100" }}>Members: {card.members}</div>
                </Container>
                <Container style={{ height: "4.5em", padding: "1rem" }}>
                    <Row>
                        <Col className={'col-3'}>
                            <div style={{ width: "max-content", display: "grid", justifyItems: "center" }}>
                                <div style={{ backgroundImage: `url(${ownerAvatar})`, height: "40px", width: "40px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: "white", borderRadius: "100%" }}></div>
                                <div>{card.user_display_name}</div>
                            </div>
                        </Col>
                        <Col className={'col-6'} style={{ textAlign: "center" }}>
                            <div>{card["group_kind"] ? <>{card.group_kind}</> : "None"}</div>
                            <div className={'line-clamp-3'} style={{ overflow: "hidden" }}>{card["description"] ? <>{card.description}</> : ""}</div>
                        </Col>
                        <Col className={'col-3'}>
                            {card["ismember"] || (userid ? card["pk"].includes(userid) : false) ?
                                <div style={{ backgroundColor: "black", color: "white", borderRadius: "10px", fontWeight: "bold", textAlign: "center", textTransform: "capitalize", padding: "2px", fontSize: "14px" }}>Joined</div> :
                                <div onClick={requestJoined} style={{ backgroundColor: "#112379", color: "white", borderRadius: "10px", fontWeight: "bold", textAlign: "center", textTransform: "capitalize", padding: "2px", fontSize: "14px" }}>{card["user_display_name"]? card["user_display_name"]: "Request"}</div>
                            }

                        </Col>
                        <Col className={'col-12'} style={{ fontSize: "55px", textTransform: "uppercase", fontWeight: "600", textAlign: "center" }}>{card.name.slice(0, 3)}</Col>
                    </Row>
                </Container>
            </Container>
        </Grid>
    )
}

export default GroupTile;