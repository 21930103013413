import React from 'react'
import '../styles.css'
import './css/TokPaksContainer.css';
import ButtonReact from 'react-bootstrap/Button';
import { faChalkboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dropdown from 'react-bootstrap/Dropdown';
const TokPakPresentationItem = (props) => {
    var tok = props.item;
    return (
        <Row className={'tokpak-presentation-item'} style={{ margin: "unset", alignContent: "flex-start" }}>
            <Col xs={12} style={{ textAlign: "center" }}>
                <h1 style={{ fontWeight: "600" }}>{tok["primary_text"]}</h1>
            </Col>
            <Col xs={12} className={'row'} style={{ margin: "unset" }}>
                {tok["is_detail_based"] ?
                    <>
                        <hr />
                        <List dense={true} className={'tokpak-presentation-list-container'} style={{ listStyleType: tok["bullet_kind"] ? tok["bullet_kind"].toLowerCase() == "numbers" ? "decimal" : tok["bullet_kind"].toLowerCase() == "letters" ? "lower-alpha" : "disc" : "disc" }}>
                            {tok["details"].map((detail) => (
                                <>
                                    {detail != null ?
                                        <ListItem style={{ padding: "unset", display: "list-item" }}>
                                            <div style={{ margin: "unset", fontSize: "30px" }}>
                                                {detail}
                                            </div>
                                        </ListItem> : ""
                                    }
                                </>
                            ))}
                        </List>
                    </>
                    : <h1 style={{ textAlign: "center" }}>{tok["secondary_text"]}</h1>}
            </Col>
        </Row>
    )
}

export default TokPakPresentationItem;