import React from 'react'
import { useEffect } from 'react';
import '../styles.css'
import './css/GroupView.css';
import './css/AddClassTok.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPenToSquare, faPrint, faRefresh, faPaperclip, faPaperPlane, faTrashCan, faTimes, faUserGroup, faGear } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import TokTileContainer from './TokTileContainer';

import TextField from '@mui/material/TextField';
import ShimmerChatMsg from './ShimmerChatMsg';
import WaitSpinner from './WaitSpinner';
import { Typography } from '@mui/material';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddEditClassGroup from './AddEditClassGroup';
import { deleteGroup, getGroup, updateGroup } from '../services/classServices';
import { getToken } from '../services/accountServices';
import Modal from 'react-bootstrap/Modal';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DocumentsContainer from './DocumentsContainer';
import { uploadFile } from '../services/fileService';
import { createRef } from 'react';
import { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import FileLoader from './FileLoader';
import { addTok } from '../services/tokServices';
import TokPaksContainer from './TokPaksContainer';
import AddExisitngClassTok from './AddExisitngClassTok';
import GroupMembers from './GroupMembers';
import PictureContainer from './PictureContainer';
import { idFormat } from '../services/helperTools';
import ClassSetsContainer from './ClassSetsContainer'
import AddExisitngClassSet from './AddExisitngClassSet';
import GroupChat from './GroupChat';
import InviteUsers from './InviteUsers';
import { PhotoProvider, PhotoView } from 'react-photo-view';
const notificationOptions = [
    { value: 'schedule', label: 'Schedule', },
    { value: 'notifications', label: 'Reminder' },
]
const groupTemp = {
    schedule: ["", "", "", "", "", "", "", "", "", ""],
    notifications: ["", "", "", "", "", "", "", "", "", ""]
}

const GroupView = (props) => {
    const addClassTokRef = useRef();
    const addClassSetRef = useRef();
    const addTokPakRef = useRef();
    const addExistingClassTokRef = useRef();
    const addExistingClassSetRef = useRef();
    const addExistingClassTokTransferRef = useRef();
    const addExistingClassSetTransferRef = useRef();
    const addPicTriggerRef = useRef();
    const addDocTriggerRef = useRef();
    const refreshChatRef = useRef();
    const MySwal = withReactContent(Swal);
    const [newFile, setNewFile] = useState({});
    const [group, setGroup] = useState(() => {
        if (props.group) {
            return {
                ...props.group,
                ...groupTemp
            };
        } else {
            return groupTemp;
        }
    });
    const [groupMode, setGroupMode] = useState("classtoks");
    const [showAddEditClassGroup, setShowAddEditClassGroup] = useState(false);
    const [notificationUpdate, setNotificationUpdate] = useState("");
    const [notificationHolder, setNotificationHolder] = useState("");
    const level1 = props.level1;
    const level2 = props.level2;
    const level3 = props.level3;
    useEffect(() => {
        async function fetchToken() {
            await getToken();
        }
        fetchToken();
    }, []);

    function closeView() {
        props.closeView(true)
    }
    const updateGroupProp = (data) => {
        setGroup(data);
        try {
            props.updateGroup(data);
        } catch (err) {
        }
    }
    const deleteGroupCall = (e) => {
        MySwal.fire({
            title: <p>Are you sure want to delete?</p>,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Group</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading()
                try {
                    deleteGroup(group.id, group.pk).then(response => {
                        MySwal.hideLoading();
                        if(response){
                            if (response.status == 200) {
                                MySwal.update({
                                    title: 'Deleted!',
                                    icon: "success"
                                });
                                props.removeGroup(group);
                                closeView();
                                try {
                                    document.querySelector('.grouptile-' + group.id + "-" + group.pk).remove();
                                } catch (err) { }
                            } else {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }
                        }
                    });
                } catch (err) {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                }
            }
        })
    }
    const CloseNotificationUpdate = (e) => {
        setNotificationUpdate("");
    }
    const SetNotificationValues = (e) => {
        const value = e.target.value;
        const index = Number(e.target.name);
        var tempNotificationHolder = notificationHolder;
        notificationHolder[notificationUpdate][index] = value;
        setNotificationHolder(notificationHolder => ({
            ...notificationHolder,
            ...tempNotificationHolder
        }));
    }
    const setUpdateGroup = () => {
        var tempGroup = group;
        if (notificationUpdate) {
            tempGroup = {
                ...tempGroup,
                ...notificationHolder
            }
        }
        MySwal.fire({
            title: <p>Updating Group</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        updateGroup(tempGroup).then(response => {
            if(response){
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Success</p>,
                        icon: "success"
                    });
                    setGroup(tempGroup)
                    props.updateGroup(tempGroup);
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        });
    }
    const setNotificationUpdateModal = (e) => {
        const mode = e.currentTarget.getAttribute("data-mode");
        var tempNoticationSet = groupTemp;
        var tempGroup = [];
        tempGroup["schedule"] = group["schedule"];
        tempGroup["notifications"] = group["notifications"];
        setNotificationHolder(tempNoticationSet => ({
            ...tempNoticationSet,
            ...tempGroup
        }));
        setNotificationUpdate(mode);
    }

    const addClassTokTrigger = event => {
        addClassTokRef.current.querySelector(".toktile-add-btn").click();
    }
    const addClassSetTrigger = event => {
        addClassSetRef.current.click();
    }
    const addTokPakTrigger = event => {
        addTokPakRef.current.click();
    }
    const addExistingClassTokTrigger = event => {
        addExistingClassTokRef.current.click();
    }
    const addExistingClassSetTrigger = event => {
        addExistingClassSetRef.current.click();
    }
    const addPicTrigger = event => {
        addPicTriggerRef.current.click();
    }
    const addDocTrigger = event => {
        addDocTriggerRef.current.click();
    }
    const [OpenSetting, SetOpenSetting] = useState(false);
    const openSettings = event => {
        SetOpenSetting(true);
    }
    const closeSettings = event => {
        SetOpenSetting(false);
    }
    //getGroup(group.id, "").then(response => {
    //    console.log(response);
    //})
    const refreshActiveContainer = e => {
        if (groupMode == "chat") {
            refreshChatRef.current.click();
        }
    }
    return (

        <div className={'dark-background-2'} style={{ backgroundColor: "#f8f9fa", padding: "10px", borderRadius: "10px" }}>

            <Modal size='lg' show={notificationUpdate == "" ? false : true} onHide={CloseNotificationUpdate} style={{ zIndex: "1300" }}>
                <Modal.Header>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} onClick={CloseNotificationUpdate} size={'lg'} icon={faTimes} />
                        <Typography fontWeight={"bold"}>Add Schedule/Reminder</Typography>
                        <Button onClick={setUpdateGroup} variant="primary" style={{ display: "flex", alignItems: "center" }}>
                            <span>Update</span>
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={{ marginBottom: "1rem" }}>
                            <TextField style={{ width: "100%" }} select label="Format" value={notificationUpdate} onChange={(e) => setNotificationUpdate(e.target.value)}>
                                {notificationOptions.map((notificationOption) => (
                                    <MenuItem key={notificationOption.value} value={notificationOption.value}>
                                        {notificationOption.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                    </Row>
                    <Row className={'section-item-container'}>
                        {notificationHolder[notificationUpdate] ?
                            <>
                                {notificationHolder[notificationUpdate].map((notification, index) => (
                                    <Col className={"col-12"}>
                                        <TextField style={{ width: "100%" }} onChange={SetNotificationValues} value={notification} label={`${notificationUpdate == 'schedule' ? 'Schedule' : 'Reminder'} ${index + 1}`} name={index} inputProps={{ maxLength: 200 }} />
                                        <p className={'character-count'}>{notification.length}/200</p>
                                    </Col>
                                ))}
                            </> : ""
                        }
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal className={'dark-background-2'} show={OpenSetting} onHide={closeSettings} style={{ zIndex: "1206" }}>
                <Modal.Header className={'dark-background-2'}>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <Typography fontWeight={"bold"} marginRight={"auto"}>Settings</Typography>

                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={closeSettings} />
                    </div>

                </Modal.Header>
                <Modal.Body className={'dark-background-2'}>
                    <Button variant={'danger'} onClick={deleteGroupCall}>Delete Group</Button>
                </Modal.Body>
            </Modal>
            <div style={{ color: "white", backgroundColor: "rgb(51, 72, 139)", display: "flex", padding: "12px", alignItems: "center", fontSize: "22px" }}>
                <div>
                    {!props["directLoad"] ?
                        <FontAwesomeIcon icon={faArrowLeft} onClick={closeView} style={{ cursor: "pointer" }} /> : ""
                    }

                </div>
                <div style={{ textAlign: "center", width: "100%" }}>
                    {group.name}
                </div>
            </div>
            <Row className={'group-head'} style={{ margin: "unset" }}>
                <Col md={4} xs={12} sm={12} style={{
                        textTransform: "uppercase", fontSize: "80px", fontWeight: "300", position: "relative", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "red", color: "white",
                        backgroundImage: group["image"] ? `url(${group.image})` : "",
                        backgroundSize: group["image"] ? "cover" : "",
                        backgroundRepeat: group["image"] ? "no-repeat" : "",
                        cursor: "pointer"
                    }}
                    onClick={(e) => e.currentTarget.querySelector("img").click()}
                >
                    {/*<IconButton style={{ backgroundColor: "buttonface", color: "black", borderRadius: "unset", position: "absolute", top: "0", left: "0" }}>
                        <MenuIcon />
                    </IconButton>*/}
                    {group["image"] ?
                        <PhotoProvider>
                            <PhotoView src={group.image}>
                                <img src={group.image} alt="" style={{display: "none"}} />
                            </PhotoView>
                        </PhotoProvider>
                        : group.name.slice(0, 3)}
                </Col>
                <Col md={4} xs={6} sm={6} style={{ position: "relative" }}>
                    <div style={{ textDecoration: "underline", fontWeight: "bold" }}>
                        <span>Schedule:</span>
                        <FontAwesomeIcon onClick={setNotificationUpdateModal} data-mode={'schedule'} icon={faPenToSquare} style={{ cursor: "pointer", marginLeft: "5px" }} />
                    </div>
                    <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px" }} >
                        {group["schedule"] ?
                            group["schedule"].map((notification) => (
                                <>
                                    {notification ?
                                        <ListItem style={{ padding: "unset", display: "list-item" }}>
                                            <ListItemText className={'elipsis-one-line-parent'}
                                                primary={notification}
                                                style={{ margin: "unset" }}
                                            />
                                        </ListItem> : <></>}
                                </>
                            )) : ""
                        }
                    </List>
                </Col>
                <Col md={4} xs={6} sm={6} style={{ position: "relative" }}>
                    <div style={{ textDecoration: "underline", fontWeight: "bold" }}>
                        <span>Reminders:</span>
                        <FontAwesomeIcon onClick={setNotificationUpdateModal} data-mode={'notifications'} icon={faPenToSquare} style={{ cursor: "pointer", marginLeft: "5px" }} />
                    </div>
                    <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px" }} >
                        {group["notifications"] ?
                            group["notifications"].map((notification) => (
                                <>
                                    {notification ?
                                        <ListItem style={{ padding: "unset", display: "list-item" }}>
                                            <ListItemText className={'elipsis-one-line-parent'}
                                                primary={notification}
                                                style={{ margin: "unset" }}
                                            />
                                        </ListItem> : <></>}
                                </>
                            )) : ""
                        }
                    </List>
                </Col>
                <Col md={4} xs={12} sm={12} style={{ position: "relative", padding: "unset" }}>
                    <Row style={{ margin: "unset" }}>
                        <Col style={{ padding: "unset", fontSize: "14px", fontWeight: "bold" }}>
                            <div>Name: {group.name}</div>
                            <div>School: {group.school}</div>
                        </Col>
                        <div style={{ padding: "unset", display: "grid", width: "max-content" }}>
                            <DropdownButton variant="secondary" className={"group-info-btn"} style={{ margin: "2.5px 0px" }} title={'Group Info'} drop={'start'}>
                                <Dropdown.Item style={{ backgroundColor: "#157347", fontWeight: "bold", color: "white" }}>
                                    <GroupMembers group={group} />
                                </Dropdown.Item>

                                <Dropdown className={'dropdown-item'} onClick={(e) => setShowAddEditClassGroup(true)} style={{ backgroundColor: "rgb(49, 83, 143)", fontWeight: "bold", color: "white", cursor: "pointer" }}>
                                    <AddEditClassGroup icon={"pen"} triggerBtn={"Edit"} updateGroup={updateGroupProp} customStyle={'backgroundColor: "rgb(49, 83, 143)", fontWeight: "bold", color: "white"'} group={group} />
                                </Dropdown>
                                <Dropdown.Item onClick={openSettings} style={{ backgroundColor: "gray", fontWeight: "bold", color: "white" }}>
                                    <FontAwesomeIcon icon={faGear} />
                                    Settings
                                </Dropdown.Item>
                            </DropdownButton>

                            <InviteUsers group={group} />
                        </div>
                    </Row>
                </Col>
                <Col md={4} xs={12} sm={12} style={{ position: "relative", display: "flex", alignItems: "center" }}>
                    <Button onClick={addClassTokTrigger} variant="success" style={{ display: groupMode == "classtoks" ? "flex" : "none", alignItems: "center", width: "100%", justifyContent: "center" }}>
                        <div className={'parrot-write-icon'}></div>
                        <span>Add Tok</span>
                    </Button>

                    <Button onClick={addClassSetTrigger} variant="success" style={{ display: groupMode == "classsets" ? "flex" : "none", alignItems: "center", width: "100%", justifyContent: "center" }}>
                        <div className={'parrot-write-icon'}></div>
                        <span>Add Set</span>
                    </Button>
                    <Button onClick={addTokPakTrigger} variant="success" style={{ display: groupMode == "tokpaks" ? "flex" : "none", alignItems: "center", width: "100%", justifyContent: "center" }}>
                        <div className={'parrot-write-icon'}></div>
                        <span>Add Tok Pak</span>
                    </Button>
                </Col>
                <Col md={4} xs={12} sm={12} style={{ position: "relative", display: "flex", alignItems: "center" }}>
                    <Button onClick={addExistingClassTokTrigger} variant="success" style={{ display: groupMode == "classtoks" ? "flex" : "none", alignItems: "center", width: "100%", justifyContent: "center" }}>
                        <div className={'parrot-write-icon'}></div>
                        <span>Add Existing Tok</span>
                    </Button>
                    <Button variant="success" onClick={addExistingClassSetTrigger} style={{ display: groupMode == "classsets" ? "flex" : "none", alignItems: "center", width: "100%", justifyContent: "center" }}>
                        <div className={'parrot-write-icon'}></div>
                        <span>Add Existing Set</span>
                    </Button>
                </Col>
                <Row style={{ margin: "unset", padding: "unset", display: "flex", alignItems: "center", height: "fit-content" }}>
                    <Col md={true} xs={12} sm={12} style={{ padding: "unset" }}>
                        <Row style={{ margin: "unset", padding: "unset", display: "flex", alignItems: "center" }}>
                            <Col md={2} xs={12} sm={12} style={{ padding: "unset" }}>
                                <div onClick={() => setGroupMode("classtoks")} className={groupMode == "classtoks" ? 'tokgroupmenu-btn active' : "tokgroupmenu-btn"}>Toks</div>
                            </Col>
                            <Col md={2} xs={12} sm={12} style={{ padding: "unset" }}>
                                <div onClick={() => setGroupMode("chat")} className={groupMode == "chat" ? 'tokgroupmenu-btn active' : "tokgroupmenu-btn"}>Chat</div>
                            </Col>
                            <Col md={2} xs={12} sm={12} style={{ padding: "unset" }}>
                                <div onClick={() => setGroupMode("pics")} className={groupMode == "pics" ? 'tokgroupmenu-btn active' : "tokgroupmenu-btn"}>Pics</div>
                            </Col>
                            <Col md={2} xs={12} sm={12} style={{ padding: "unset" }}>
                                <div onClick={() => setGroupMode("doc")} className={groupMode == "doc" ? 'tokgroupmenu-btn active' : "tokgroupmenu-btn"}>Documents</div>
                            </Col>
                            <Col md={2} xs={12} sm={12} style={{ padding: "unset" }}>
                                <div onClick={() => setGroupMode("classsets")} className={groupMode == "classsets" ? 'tokgroupmenu-btn active' : "tokgroupmenu-btn"}>Sets</div>
                            </Col>
                            <Col md={2} xs={12} sm={12} style={{ padding: "unset" }}>
                                <div onClick={() => setGroupMode("tokpaks")} className={groupMode == "tokpaks" ? 'tokgroupmenu-btn active' : "tokgroupmenu-btn"}>Tok Paks</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={'auto'} xs={12} sm={12} style={{ padding: "unset" }}>
                        <Row style={{ margin: "unset", padding: "unset", display: "flex", alignItems: "center" }}>
                            <Col md={3} xs={12} sm={12} style={{ padding: "unset", display: "flex", alignItems: "center" }}>
                                <FontAwesomeIcon size='xl' icon={faPrint} style={{ cursor: "pointer", marginRight: "10px" }} />
                                <FontAwesomeIcon size='xl' icon={faRefresh} style={{ cursor: "pointer" }} onClick={refreshActiveContainer} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Row className={'group-body'} style={{ margin: "unset" }}>
                <Col className={"col-12 note-this"} style={{ padding: "unset" }}>
                    <div style={{ display: groupMode == "classtoks" ? "" : "none" }}>
                        <TokTileContainer addExistingClassTokTransferRef={addExistingClassTokTransferRef} addClassTokRef={addClassTokRef} query={{ group_id: group["id"] ? group["id"] : "", publicfeed: false }} GroupName={group["name"] ? group["name"] : ""} />
                    </div>

                    <div style={{ display: groupMode == "chat" ? "" : "none" }}>
                        <GroupChat refreshChatRef={refreshChatRef} groupId={group.id ? group.id : ""} />
                    </div>

                    <div style={{ display: groupMode == "pics" ? "" : "none" }}>
                        <PictureContainer addPicTriggerRef={addPicTriggerRef} group={group} query={{ group_id: group.id }} />
                    </div>

                    <div style={{ display: groupMode == "doc" ? "" : "none" }}>
                        <DocumentsContainer addDocTriggerRef={addDocTriggerRef} query={{ group_id: group.id }} group={group} />
                    </div>

                    {/*<div style={{ display: groupMode == "members" ? "" : "none" }}>
                        <Typography style={{ fontWeight: "bold", fontSize: "28px", color: "#818181" }}>
                            List of Members
                        </Typography>
                     s   <hr />
                        <Row style={{ margin: "unset", display: "block" }}>
                            <Row className={'member-list-item col-6'} style={{ margin: "unset", padding: "unset" }}>
                                <div style={{ height: "46px", width: "46px", backgroundImage: `url(${group.user_photo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundColor: "white" }}></div>
                                <div style={{ width: "max-content" }}>{group.user_display_name}</div>
                                <div className={'owner-badge'}>Owner</div>
                            </Row>
                            <div className={'col-6'} style={{ marginTop: "5px" }}><WaitSpinner /></div>
                        </Row>
                    </div>*/}

                    <div style={{ display: groupMode == "classsets" ? "" : "none" }}>
                        {/* classset view*/}
                        <ClassSetsContainer studyGameMode={undefined} studyGameModeRef={undefined} customTitleEnabled={undefined} customTitle={undefined} MenuKind={undefined} PublicFeed={undefined} level1={level1} level2={level2} level3={level3} addExistingClassSetTransferRef={addExistingClassSetTransferRef} Navbar={true} HideNavbar={true} communityid={group["community_id"] ? group["community_id"] : ""} addClassSetRef={addClassSetRef} groupid={group.id} query={{ group_id: group.id, grouponly: true }} />
                    </div>

                    <div style={{ display: groupMode == "tokpaks" ? "" : "none" }}>
                        <TokPaksContainer MenuKind={true} query={{ group_id: group["id"] ? group["id"] : "" }} addTokPakRef={addTokPakRef} />
                    </div>
                </Col>
            </Row>
            <AddExisitngClassTok showTriggerRefBtn={addExistingClassTokRef} addExistingClassTokTransferRef={addExistingClassTokTransferRef} ShowTriggerBtn={false} Group={group} />
            <AddExisitngClassSet communityid={group["community_id"] ? group["community_id"] : ""} showTriggerRefBtn={addExistingClassSetRef} addExistingClassSetTransferRef={addExistingClassSetTransferRef} ShowTriggerBtn={false} Group={group} />
        </div>
    );
}

export default GroupView;