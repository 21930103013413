import React, { createRef } from 'react'
import { useEffect } from 'react';
import '../styles.css'
import './css/AddClassTok.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { width } from '@mui/system';
import { faImage, faCaretDown, faTimes, faCheck, faClipboard, faPaperclip, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from '@mui/material/Checkbox';
import { addClassTok, addSection, getClassTok, getGroups, patchTok, updateClassTok } from '../services/classServices';
import CircularProgress from '@mui/material/CircularProgress';
import { useRef } from "react";
import Cropper from "react-cropper";
import "./css/cropper.css";
import { isEditable } from '@testing-library/user-event/dist/utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import arrayShuffle from 'array-shuffle';
import './css/TokHandleMenu.css';
import { extractYoutubeID, isYoutubeLink, textDisplay, capitalizeFirstLetter, textDisplayReverse, upperCasesFirstLetter } from '../services/helperTools';
import { getToken } from '../services/accountServices';
import ButtonMaterial from '@mui/material/Button';
import SearchTokLink from './SearchTokLink';
import YouTube from 'react-youtube';
import WaitSpinner from './WaitSpinner';
import $ from "jquery";
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import { Tok } from '../interfaces/TokInterface';
import { Section, Qna } from '../interfaces/SectionInterface';
import { Group } from '../interfaces/GroupInterface';
import { PostedTo } from '../interfaces/PostedToInterface';

const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
const emptyDetailsSet = ["", "", ""];
const emptyQna = {
    question: "",
    answer: "",
} as Qna
const emptySection = {
    label: "toksection",
    user_id: "",
    tok_id: "",
    tok_type_id: "",
    title: "",
    image: "",
    content: "",
    section_number: 0,
    show: true,
    question_answer: [cloneDeep(emptyQna)],
    pk: "",
} as Section;

interface EmptyTokSet extends Tok {
    details_list_show: boolean[];
    detail_tok_links_names: string[];
    show_autopaste_details: boolean;
    temp_detail_images: string[];
}
const emptyTokSet: Partial<EmptyTokSet> = {
    label: "tok",
    answer_enabled: false,
    tok_group: "",
    reference_id: "",
    tok_type: "",
    category: "",
    primary_text: "",
    secondary_text: "",
    notes: "",
    details: cloneDeep(emptyDetailsSet),
    language: "english",
    user_id: "",
    image: "",
    secondary_image: "",
    sections: [cloneDeep(emptySection)],
    master_copy: false,
    level1: "",
    level2: "",
    level3: "",
    color_main_hex: "#FFFFFF",
    details_list_show: [true],
    is_indent: [false, false, false],
    detail_images: ["", "", ""],
    detail_tok_links: ["", "", ""],
    detail_tok_links_names: ["", "", ""],
    show_autopaste_details: false,
    temp_detail_images: [""],
    source_notes: "",
    source_link: ""
};
const tokgroups = [
    {
        value: '',
        label: 'Choose Format',
    },
    {
        value: 'Basic',
        label: '• Basic (Only 1 Detail, playable)',
    },
    {
        value: 'Detailed',
        label: '• Detailed (2-10 Details, playable)',
    },
    {
        value: 'Mega',
        label: '• Mega (Unlimited Sections of 150k chars, not playable)',
    },
    {
        value: 'Pic',
        label: '• Pic (Basic with only picture on tile)',
    },
    {
        value: 'List',
        label: '• List (Unlimited Details, not playable)',
    },
    {
        value: 'Q&a',
        label: "• Q&A (Creator's Version)",
    },
];
const bullets = [
    {
        value: '',
        label: 'Choose Bullet Type',
    },
    {
        value: 'Bullets',
        label: 'Bullets',
    },
    {
        value: 'Numbers',
        label: 'Numbers',
    },
    {
        value: 'Letters',
        label: 'Letters',
    },
];
const channels = [
    {
        level: "Family & Friends", ref: "familyfriends", status: 1, next: [
            { level: "Accomplishments", ref: "accomplishments", status: 2 },
            { level: "Anniversaries", ref: "anniversaries", status: 2 },
            { level: "Birthday", ref: "birthday", status: 2 },
            { level: "Children", ref: "children", status: 2 },
            { level: "Faith", ref: "faith", status: 2 },
            { level: "Food", ref: "food", status: 2 },
            { level: "Graduation", ref: "graduation", status: 2 },
            { level: "Help", ref: "help", status: 2 },
            { level: "Job", ref: "job", status: 2 },
            { level: "Memoriam", ref: "memoriam", status: 2 },
            { level: "New Home", ref: "newhome", status: 2 },
            { level: "Pets", ref: "pets", status: 2 },
            { level: "School", ref: "school", status: 2 },
            { level: "Travel", ref: "travel", status: 2 },
            { level: "Social Events", ref: "socialevents", status: 2 }
        ]
    },

    {
        level: "Health", ref: "health", status: 1, next: [
            { level: "Medical", ref: "medical", status: 2 },
            { level: "Mental", ref: "mental", status: 2 },
            { level: "Nutritional", ref: "nutritional", status: 2 },
            { level: "Physical", ref: "physical", status: 2 },
            { level: "Spiritual", ref: "spiritual", status: 2 }
        ]
    },

    {
        level: "Spiritual", ref: "spiritual", status: 2, next: [
            { level: "Buddhism", ref: "buddhism", status: 3 },
            { level: "Christianity", ref: "christianity", status: 3 },
            { level: "Hinduism", ref: "hinduism", status: 3 },
            { level: "Islam", ref: "islam", status: 3 },
            { level: "Judaism", ref: "judaism", status: 3 },
            { level: "New Age", ref: "newage", status: 3 }
        ]
    },
    {
        level: "How To", ref: "howto", status: 1, next: [
            { level: "Sports", ref: "sports", status: 2 },
        ]
    },
    {
        level: "Sports", ref: "sports", status: 2, next: [
            { level: "Basketball", ref: "basketball", status: 3 },
            { level: "Pickleball", ref: "pickleball", status: 3 }
        ]
    },
    {
        level: "Technology", ref: "technology", status: 1, next: [
            { level: "Artificial Intelligence (AI)", ref: "artificialintelligence", status: 2 },
        ]
    },
    { level: "Interpersonal", ref: "interpersonal", status: 1 },
    { level: "World", ref: "world", status: 1 },
]
const MySwal = withReactContent(Swal);
interface States {
    customClass: string;
    customTitle: string;
    sectionCountCropper: number;
    detailCountCropper: number;
    tempDetailCountCropper: number;
    searchTokLinkDetail: number;
    cropperSrc: string;
    cropperPosition: string;
    cropperShow: boolean;
    colorPickShow: boolean;
    colorPick: string;
    isPrivate: boolean;
    isTokChannel: boolean;
    visibleStateWarning: string[];
    defaultTok: Tok;
    tok: Tok;
    editTok: boolean;
    detailImagesShow: boolean;
    showOptionalFields: boolean;
    showYoutubeVideo: boolean;
    tempYoutubeLink: string;
    tempYoutubeLinkDetail: number;
    tempManageYoutubeLinkDetail: number;
    GroupCopy: Group;
    CommunityCopy: {
        level1: string;
        level2: string;
        level3: string;
        id: string;
        name: string;
    };
    CopyTokShow: boolean;
    CopyTokShowMode: string;
    CopyTokLevel1: string;
    CopyTokLevel2: string;
    CopyTokLevel2List: { [key: string]: any };
    CopyTokLevel3: string;
    CopyTokLevel3List: { [key: string]: any };
    CopyTokLevel: {
        level1: string;
        level2: string;
        level3: string;
        id: string; name: string;
    };
    CommunityLoader: boolean;
    Communities: Group[];
    GroupsCopy: Group[];
    GroupsCopyLoader: boolean;
    TempCommunityCopy: Group;
    TempGroupCopy: Group;
    defaultEditTok: Tok;
    show: boolean;
}

interface Props {
    updateTok: (tok: Tok) => void;
    receiveData: (data: Tok) => void;
    customTitle: string;
    publicfeed: boolean;
    tok: Tok;
    customClass: string;
    edit: boolean;
    defaulttok: Tok;
    addTokBtnRef: React.RefObject<any>;
    community_name?: string;
}
class AddClassTok extends React.Component<Props, States> {
    cropperRef: React.RefObject<any>;
    searchTokTrigger: React.RefObject<any>;
    autoPasteField: React.RefObject<any>;
    addTokBtnRef: React.RefObject<any>;
    constructor(props: any) {
        super(props);

        let isPrivate = true;
        let isTokChannel = false;
        var visibleList = new Array;
        let tempTokSet = cloneDeep(emptyTokSet);
        if (props.tok) {
            tempTokSet = $.extend(true, {}, props.tok);
            tempTokSet = {
                ...cloneDeep(emptyTokSet),
                ...tempTokSet
            }
        }
        if (props.tok["level1"]) {
            if (props.tok["level1"].length) {
                isTokChannel = true;
                if (props.tok["level1"].toLowerCase() == "familyfriends" || props.tok["group_id"]) {
                    isPrivate = true;
                } else {
                    isPrivate = false;
                }
                visibleList.push({
                    text: `${isPrivate ? "Private: " : "Public: "}` + "Visible to " + textDisplay(props.tok["level1"]),
                    ref: props.tok["level1"]
                });
                if (props.tok["level2"]) {
                    visibleList.push({
                        text: `${isPrivate ? "Private: " : "Public: "}` + "Visible to " + textDisplay(props.tok["level2"]),
                        ref: props.tok["level2"]
                    });
                    if (props.tok["level3"]) {
                        visibleList.push({
                            text: `${isPrivate ? "Private: " : "Public: "}` + "Visible to " + textDisplay(props.tok["level3"]),
                            ref: props.tok["level3"]
                        });
                    }
                }
            }
        } else if (props.publicfeed) {
            isPrivate = false;
        }

        if (props.tok["group_name"] && props.tok["group_id"]) {
            visibleList.push({
                text: `${isPrivate ? "Private: " : "Public: "}` + "Visible to " + props.tok["group_name"],
                ref: props.tok["level3"]
            });
        }
        let defaultEditTok = {} as Tok;
        if (props.edit) {
            if (tempTokSet["sections"]) {
                tempTokSet["sections"] = new Array;
            }
            defaultEditTok = props.defaulttok;
        }

        this.state = {
            customClass: props.customClass,
            customTitle: props.customTitle,
            sectionCountCropper: -1,
            detailCountCropper: -1,
            tempDetailCountCropper: -1,
            searchTokLinkDetail: -1,
            cropperSrc: null,
            cropperPosition: null,
            show: false,
            cropperShow: false,
            colorPickShow: false,
            colorPick: "#FFFFFF",
            isPrivate: isPrivate,
            isTokChannel: isTokChannel,
            visibleStateWarning: visibleList,
            defaultTok: $.extend(true, {}, tempTokSet),
            tok: $.extend(true, {}, tempTokSet),
            editTok: props.edit,
            detailImagesShow: false,
            showOptionalFields: false,
            showYoutubeVideo: false,
            tempYoutubeLink: "",
            tempYoutubeLinkDetail: -1,
            tempManageYoutubeLinkDetail: -1,
            GroupCopy: {} as Group,
            CommunityCopy: {
                level1: "",
                level2: "",
                level3: "",
                id: "",
                name: ""
            },
            CopyTokShow: false,
            CopyTokShowMode: "",
            CopyTokLevel1: "",
            CopyTokLevel2: "",
            CopyTokLevel2List: [],
            CopyTokLevel3: "",
            CopyTokLevel3List: [],
            CopyTokLevel: {
                level1: "",
                level2: "",
                level3: "",
                id: "",
                name: "",
            },
            CommunityLoader: false,
            Communities: new Array,
            GroupsCopy: [],
            GroupsCopyLoader: false,
            TempCommunityCopy: {} as Group,
            TempGroupCopy: {} as Group,
            defaultEditTok: defaultEditTok
        };

        this.cropperRef = createRef();
        this.searchTokTrigger = createRef();
        this.autoPasteField = createRef();
        this.addTokBtnRef = props.addTokBtnRef;

        this.onCrop = this.onCrop.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCropperClose = this.handleCropperClose.bind(this);
        this.handleColorClose = this.handleColorClose.bind(this);
        this.handleColorSet = this.handleColorSet.bind(this);
        this.setPickColor = this.setPickColor.bind(this);
        this.setPickColorTemp = this.setPickColorTemp.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.SetTokValues = this.SetTokValues.bind(this);
        this.SetDetailValues = this.SetDetailValues.bind(this);
        this.SetSectionValues = this.SetSectionValues.bind(this);
        this.ShowHideSectionContent = this.ShowHideSectionContent.bind(this);
        this.AddDetail = this.AddDetail.bind(this);
        this.RemoveDetail = this.RemoveDetail.bind(this);
        this.AddSection = this.AddSection.bind(this);
        this.AddQna = this.AddQna.bind(this);
        this.RemoveSection = this.RemoveSection.bind(this);
        this.RemoveSectionQna = this.RemoveSectionQna.bind(this);
        this.onImageSet = this.onImageSet.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.createClassTok = this.createClassTok.bind(this);
        this.addTokToList = this.addTokToList.bind(this);
        this.createSections = this.createSections.bind(this);
        this.ShowHideDetailsList = this.ShowHideDetailsList.bind(this);
        this.ShowHideAutopaste = this.ShowHideAutopaste.bind(this);
        this.PasteTokLink = this.PasteTokLink.bind(this);
        this.removeTokLink = this.removeTokLink.bind(this);
        this.removeTokDetailImage = this.removeTokDetailImage.bind(this);
        this.searchTokModalCall = this.searchTokModalCall.bind(this);
        this.autoPasteDetails = this.autoPasteDetails.bind(this);
        this.convertSubbullet = this.convertSubbullet.bind(this);
        this.addTempDetailImageRow = this.addTempDetailImageRow.bind(this);
        this.detailImagesClose = this.detailImagesClose.bind(this);
        this.manageDetailImagesShow = this.manageDetailImagesShow.bind(this);
        this.setDetailImages = this.setDetailImages.bind(this);
        this.removeTempDetailImageRow = this.removeTempDetailImageRow.bind(this);
        this.closeYoutubeVideo = this.closeYoutubeVideo.bind(this);
        this.linkVideoSet = this.linkVideoSet.bind(this);
        this.viedoLinkInputCall = this.viedoLinkInputCall.bind(this);
        this.SetShowOptionalFields = this.SetShowOptionalFields.bind(this);

        this.setTokCopy = this.setTokCopy.bind(this);
        this.closeCopyTok = this.closeCopyTok.bind(this);
        this.getCopyCommunities = this.getCopyCommunities.bind(this);
        this.setCopyCommunity = this.setCopyCommunity.bind(this);
        this.getGroupsCopy = this.getGroupsCopy.bind(this);
        this.setCopyGroup = this.setCopyGroup.bind(this);
        this.setCopyConfirm = this.setCopyConfirm.bind(this);
        this.setCopyTokLevel = this.setCopyTokLevel.bind(this);

        this.createCopyTok = this.createCopyTok.bind(this);
        this.removeCopy = this.removeCopy.bind(this);

        this.updateTokTile = this.updateTokTile.bind(this);
    }

    setTokCopy(e) {
        var type = e.currentTarget.getAttribute("data-type");
        if (type) {
            this.setState({
                CopyTokShow: true,
                CopyTokShowMode: type
            }, () => {
                if (type == "Group") {
                    this.getGroupsCopy();
                }
            });
        }
    }
    removeCopy(e) {
        var type = e.currentTarget.getAttribute("data-type");
        switch (type) {
            case "Group":
                this.setState({
                    GroupCopy: {} as Group
                });
                break;
            case "Community":
                this.setState({
                    CommunityCopy: {
                        level1: "",
                        level2: "",
                        level3: "",
                        id: "",
                        name: ""
                    }
                });
                break;
            case "Channel":
                this.setState({
                    CopyTokLevel: {
                        level1: "",
                        level2: "",
                        level3: "",
                        id: "",
                        name: ""
                    }
                });
                break;
        }
    }
    closeCopyTok() {
        this.setState({
            CopyTokShow: false,
            CopyTokShowMode: ""
        });
    }

    setCopyTokLevel(e) {
        const value = e.target.value;
        const level = e.target.name;
        const { CopyTokShowMode } = this.state;
        this.setState({
            CommunityCopy: {
                level1: "",
                level2: "",
                level3: "",
                id: "",
                name: ""
            },
            TempCommunityCopy: {} as Group
        });
        var next = [];
        if (level != "level3-add") {
            for (let x = 0; x < channels.length; x++) {
                if ((value == channels[x].ref) && channels[x]["next"]) {
                    next = channels[x]["next"]
                    break;
                }
            }
        }
        switch (level) {
            case "level1-add":
                this.setState({
                    CopyTokLevel1: value,
                    CopyTokLevel2List: next
                });
                break;
            case "level2-add":
                this.setState({
                    CopyTokLevel2: value,
                    CopyTokLevel3List: next
                });
                break;
            case "level3-add":
                this.setState({
                    CopyTokLevel3: value,
                    CommunityLoader: true,
                    CommunityCopy: {
                        level1: "",
                        level2: "",
                        level3: "",
                        id: "",
                        name: ""
                    },
                }, () => {
                    if (CopyTokShowMode == "Community") {
                        const { CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
                        this.getCopyCommunities(CopyTokLevel1, CopyTokLevel2, CopyTokLevel3);
                    }
                });
                break;
        }
    }

    getCopyCommunities(level1, level2, level3) {
        getGroups({
            level0: level1 ? upperCasesFirstLetter(level1) : "",
            level1: level2 ? upperCasesFirstLetter(level2) : "",
            level2: level3 ? upperCasesFirstLetter(level3) : "",
            //remove label once addgroup is adjusted
            label: "classgroup",
            items: "channelgroups"
        }, "channelgroups").then(res => {
            const { CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
            if (level1 == CopyTokLevel1 && level2 == CopyTokLevel2 && level3 == CopyTokLevel3) {
                this.setState({
                    Communities: res.data["results"] ? res.data["results"] : new Array
                });
            }
        }).catch(err => { }).
            finally(() => {
                const { CopyTokLevel1, CopyTokLevel2, CopyTokLevel3 } = this.state;
                if (level1 == CopyTokLevel1 && level2 == CopyTokLevel2 && level3 == CopyTokLevel3) {
                    this.setState({
                        CommunityLoader: false
                    });
                }
            })
    }

    setCopyCommunity(e) {
        const value = e.target.value;
        const { Communities } = this.state;
        let tempCommunity = {};
        for (let x = 0; x < Communities.length; x++) {
            if (Communities[x]["id"] == value) {
                tempCommunity = Communities[x];
            }
        }
        this.setState({
            TempCommunityCopy: tempCommunity as Group
        });
    }

    setCopyGroup(e) {
        const value = e.target.value;
        const { GroupsCopy } = this.state;
        let tempGroup = {};
        for (let x = 0; x < GroupsCopy.length; x++) {
            if (GroupsCopy[x]["id"] == value) {
                tempGroup = GroupsCopy[x];
            }
        }
        this.setState({
            TempGroupCopy: tempGroup as Group
        });
    }
    async getGroupsCopy() {
        var { GroupsCopy } = this.state;
        const localUser = localStorage.getItem("userdata");
        await getToken();

        var userid = "";
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson.id !== undefined)
                userid = localUserJson.id;

        }
        const query = { userid: userid }

        this.setState({
            GroupsCopyLoader: true
        });
        const response = await getGroups(query);
        if (response) {
            if (response["status"] == 200) {
                GroupsCopy = response.data.result;
            }
        }
        this.setState({
            GroupsCopy: GroupsCopy,
            GroupsCopyLoader: false
        });
    }

    setCopyConfirm() {
        const { CopyTokShowMode, TempCommunityCopy, TempGroupCopy, CopyTokLevel1, CopyTokLevel2, CopyTokLevel3, tok } = this.state;
        switch (CopyTokShowMode) {
            case "Group":
                this.setState({
                    GroupCopy: TempGroupCopy as Group,
                    CopyTokShow: false
                });
                break;
            case "Community":
                this.setState({
                    CommunityCopy: {
                        level1: CopyTokLevel1,
                        level2: CopyTokLevel2,
                        level3: CopyTokLevel3,
                        id: TempCommunityCopy["id"],
                        name: TempCommunityCopy["name"],
                    },
                    CopyTokShow: false
                });
                break;
            case "Channel":
                this.setState({
                    CopyTokLevel: {
                        level1: CopyTokLevel1,
                        level2: CopyTokLevel2,
                        level3: CopyTokLevel3,
                        id: "",
                        name: "",
                    },
                    CopyTokShow: false
                });
                break;
        }
    }

    onCrop() {
        const cropper = this.cropperRef.current?.cropper;
        const { tok, tempDetailCountCropper, detailCountCropper, sectionCountCropper, cropperPosition } = this.state;
        var tempTok = tok;
        if (tempDetailCountCropper > -1) {
            let index = tempDetailCountCropper;
            let tempTok = tok;
            let tempDetailImages = tempTok["temp_detail_images"];
            const detailImages = tempTok["detail_images"];
            let ogDetailImages = new Array;
            for (let x = 0; x < detailImages.length; x++) {
                ogDetailImages.push(detailImages[x]);
            }
            tempTok["detail_images"] = ogDetailImages;
            tempDetailImages[index] = cropper.getCroppedCanvas().toDataURL();
            tempTok["temp_detail_images"] = tempDetailImages;
        } else if (detailCountCropper > -1) {
            let index = detailCountCropper;
            let tempTok = tok;
            let tempDetailImages = tempTok["detail_images"];
            if (index > tempDetailImages.length) {
                let currentIndentSize = tempDetailImages.length;
                for (let x = index - currentIndentSize; x < index; x++) {
                    tempDetailImages.push("");
                }
            }
            tempDetailImages[index] = cropper.getCroppedCanvas().toDataURL();
            tempTok["detail_images"] = tempDetailImages;
        } else if (sectionCountCropper > -1) {
            tempTok.sections[sectionCountCropper][cropperPosition] = cropper.getCroppedCanvas().toDataURL();
        } else {
            tempTok[cropperPosition] = cropper.getCroppedCanvas().toDataURL();
        }
        this.setState({
            cropperShow: false,
            sectionCountCropper: -1,
            detailCountCropper: -1,
            tempDetailCountCropper: -1,
            tok: tempTok
        });
    }

    handleClose() {
        this.setState({
            show: false
        });
    }

    handleCropperClose() {
        this.setState({
            cropperShow: false
        });
    };

    handleColorClose() {
        this.setState({
            colorPickShow: false
        });
    }

    handleColorSet() {
        const { tok } = this.state;
        this.setState({
            colorPickShow: true,
            colorPick: tok["color_main_hex"]
        });
    }

    setPickColor() {
        const { tok, colorPick } = this.state;
        var tempTok = tok;
        tempTok["color_main_hex"] = colorPick;
        this.setState({
            colorPickShow: false,
            tok: tempTok
        });
    }

    setPickColorTemp(e) {
        const color = e.target.getAttribute("data-color");
        this.setState({
            colorPick: "#" + color
        });
    }

    handleShow() {
        const { defaultTok, tok } = this.state;
        this.setState({
            tok: $.extend(true, {}, defaultTok),
            show: true
        });
    }

    SetTokValues(e) {
        const { tok } = this.state;
        var value = e.target.value;
        const dataType = e.target.name;
        if (dataType == "master_copy") {
            value = e.target.checked;
        }
        var tempTok = tok;

        tempTok[dataType] = value;
        //if (dataType != "bullet_kind" || (tok.tok_group != "Detailed" || tok.tok_group != "List")) {
        //    tempTok.details = cloneDeep(emptyDetailsSet);
        //    tempTok.details_list_show = [true]
        //}
        if (dataType == "tok_group" && (value == "Mega" || value == "Q&a")) {
            if (tempTok.sections) {
                let temporarySections = new Array;
                temporarySections.push(cloneDeep(emptySection));
                tempTok.sections = temporarySections;
            }
        }
        this.setState({
            tok: tempTok
        });
    }

    SetDetailValues(e) {
        const { tok } = this.state;
        const value = e.target.value;
        const dataType = e.target.name;

        var tempTok = tok;
        tempTok.details[dataType] = value;
        this.setState({
            tok: tempTok
        });
    }

    SetSectionValues(e) {
        const { tok } = this.state;
        const value = e.target.value;
        const data = e.target.name.split("-");
        const sectionN = parseInt(data[1]);
        const type = data[0];
        var tempTok = cloneDeep(tok);
        if (data[2]) {
            const qna_count = parseInt(data[2]);
            tempTok.sections[sectionN].question_answer[qna_count][type] = value;
        } else {
            tempTok.sections[sectionN][type] = value;
        }
        this.setState({
            tok: tempTok
        });
    }

    ShowHideSectionContent(e) {
        if (e.currentTarget.getAttribute("data-id")) {
            const { tok } = this.state;
            var tempTok = tok;
            tempTok.sections[e.currentTarget.getAttribute("data-id")].show = !tempTok.sections[e.currentTarget.getAttribute("data-id")].show;
            this.setState({
                tok: tempTok
            });
        }
    }

    AddDetail(e) {
        const { tok } = this.state;
        var tempTok = tok;
        tempTok.details.push("");
        if (Math.ceil(tempTok.details.length / 10) > tempTok["details_list_show"].length) {
            const toAdd = Math.ceil(tempTok.details.length / 10) - tempTok["details_list_show"].length;
            for (let x = 0; x < toAdd; x++) {
                tempTok["details_list_show"].push(true);
            }
        }
        this.setState({
            tok: tempTok
        });
    }

    RemoveDetail(e) {
        if (e.currentTarget.getAttribute("data-id")) {
            const { tok } = this.state;
            var tempTok = tok;
            tempTok.details.splice(e.currentTarget.getAttribute("data-id"), 1);
            if (Math.ceil(tempTok.details.length / 10) < tempTok["details_list_show"].length) {
                const toAdd = tempTok["details_list_show"].length - Math.ceil(tempTok.details.length / 10);
                for (let x = 0; x < toAdd; x++) {
                    tempTok["details_list_show"].pop();
                }
            }
            this.setState({
                tok: tempTok
            });
        }
    }

    AddSection(e) {
        const { tok } = this.state;
        var tempTok = tok;
        tempTok.sections.push(cloneDeep(emptySection));
        this.setState({
            tok: tempTok
        });
    }

    AddQna(e) {
        if (e.currentTarget.getAttribute("data-section")) {
            const { tok } = this.state;
            var tempTok = tok;
            tempTok.sections[e.currentTarget.getAttribute("data-section")].question_answer.push(cloneDeep(emptyQna));
            this.setState({
                tok: tempTok
            });
        }
    }

    RemoveSection(e) {
        if (e.currentTarget.getAttribute("data-id")) {
            const { tok } = this.state;
            var tempTok = tok;
            tempTok.sections.splice(e.currentTarget.getAttribute("data-id"), 1);
            this.setState({
                tok: tempTok
            });
        }
    }

    RemoveSectionQna(e) {
        if (e.currentTarget.getAttribute("data-section") && e.currentTarget.getAttribute("data-qna")) {
            const { tok } = this.state;
            var tempTok = tok;
            tempTok.sections[e.currentTarget.getAttribute("data-section")].question_answer.splice(e.currentTarget.getAttribute("data-qna"), 1);
            this.setState({
                tok: tempTok
            });
        }
    }

    onImageSet(e) {
        if (e.currentTarget.getAttribute("data-position")) {
            if (e.currentTarget.getAttribute("data-section")) {
                this.setState({
                    sectionCountCropper: e.currentTarget.getAttribute("data-section"),
                });
            }
            if (e.currentTarget.getAttribute("data-detail")) {
                this.setState({
                    detailCountCropper: Number(e.currentTarget.getAttribute("data-detail")),
                });
            }
            if (e.currentTarget.getAttribute("data-tempdetail")) {
                this.setState({
                    tempDetailCountCropper: Number(e.currentTarget.getAttribute("data-tempdetail")),
                });
            }
            this.setState({
                cropperSrc: URL.createObjectURL(e.currentTarget.files[0]),
                cropperPosition: e.currentTarget.getAttribute("data-position"),
                cropperShow: true,
            });
        }
    }

    removeImage(e) {
        if (e.currentTarget.getAttribute("data-position")) {
            const { tok } = this.state;
            var tempTok = tok;
            if (e.currentTarget.getAttribute("data-tempdetail")) {
                const detailImages = tempTok["detail_images"];
                let ogDetailImages = new Array;
                for (let x = 0; x < detailImages.length; x++) {
                    ogDetailImages.push(detailImages[x]);
                }
                tempTok["detail_images"] = ogDetailImages;
                tempTok["temp_detail_images"][e.currentTarget.getAttribute("data-tempdetail")] = "";
            } else if (e.currentTarget.getAttribute("data-section")) {
                tempTok.sections[e.currentTarget.getAttribute("data-section")][e.currentTarget.getAttribute("data-position")] = "";
            } else {
                tempTok[e.currentTarget.getAttribute("data-position")] = "";
            }
            this.setState({
                tok: tempTok
            });
        }
    }

    createClassTok(e) {
        const { editTok, customTitle, tok, isPrivate, CopyTokLevel, GroupCopy, CommunityCopy, defaultTok, defaultEditTok } = this.state;
        MySwal.fire({
            title: <p>{editTok ? "Updating" : "Adding"} {customTitle ? customTitle : <>Tok</>}</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        if (tok["tok_group"] == "Q&a") {
            tok["master_copy"] = true;
        }
        if (editTok) {
            let patchObject = diff(defaultTok, tok);
            if ('details' in patchObject) {
                patchObject["details"] = tok["details"];
            }
            let patchObjectKeys = Object.keys(patchObject);
            for (let x = 0; x < patchObjectKeys.length; x++) {
                patchObject[patchObjectKeys[x]] = cloneDeep(tok[patchObjectKeys[x]]);
            }
            let newSections = new Array;
            let sectionCount = 0;
            if (patchObject["sections"] && defaultEditTok["sections"]) {
                newSections = cloneDeep(patchObject["sections"]);
                if (defaultEditTok["section_count"]) {
                    sectionCount = cloneDeep(defaultEditTok["section_count"]);
                }
                patchObject["sections"] = defaultEditTok["sections"].concat(patchObject["sections"]);
            }
            patchTok(patchObject, tok["id"], tok["pk"]).then(res => {
                if (res) {
                    if (res.status == 200) {
                        if (patchObject["sections"]) {
                            let newTok = cloneDeep(tok);
                            newTok["sections"] = new Array;
                            let newDefaultTok = cloneDeep(newTok);
                            this.setState({
                                tok: newTok,
                                defaultTok: newDefaultTok
                            }, () => {
                                this.createSections(tok["id"], 1, sectionCount, newSections, 0);
                            });
                        } else {
                            MySwal.update({
                                title: <p>Update successful.</p>,
                                icon: "success"
                            });
                            let tempTok = cloneDeep(tok);
                            window.localStorage.setItem("copiedTok", JSON.stringify(tempTok));
                            this.setState({
                                defaultTok: tempTok,
                                show: false
                            });
                            try {
                                this.updateTokTile(tempTok);
                            } catch (err) {
                            }
                        }


                    } else {
                        MySwal.update({
                            title: <p>Update failed.</p>,
                            icon: "success"
                        });
                    }
                }

            }).catch(err => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        } else {
            let listCopy = new Array;
            if (CopyTokLevel["level1"]) {
                listCopy.push("level");
            }
            if (GroupCopy["id"]) {
                listCopy.push("group");
            }
            if (CommunityCopy["id"]) {
                listCopy.push("community");
            }
            let feedId = "";
            if (tok["group_id"]) {
                feedId = tok["group_id"];
            } else if (tok["set_id"]) {
                feedId = tok["set_id"];
            } else if (tok["level1"]) {
                feedId = textDisplayReverse(tok["level1"]).toLowerCase();
                if (tok["level2"]) {
                    feedId = feedId + "-" + textDisplayReverse(tok["level2"]).toLowerCase();
                    if (tok["level3"]) {
                        feedId = feedId + "-" + textDisplayReverse(tok["level3"]).toLowerCase();
                    }
                }
                if (tok["community_id"]) {
                    feedId = feedId + "-" + tok["community_id"];
                }
            }
            addClassTok(tok, feedId).then(response => {
                var tokdata = {};
                if (response) {
                    if (response["data"]["result"]) {
                        tokdata = {
                            ...cloneDeep(emptyTokSet),
                            ...response["data"]["result"]
                        };
                    }
                    if (response.status == 200) {
                        if (listCopy.length == 0) {
                            MySwal.update({
                                title: <p>{customTitle ? customTitle : <>Tok</>} Added</p>,

                            });
                            MySwal.update({
                                icon: "success"
                            });
                            MySwal.hideLoading();
                            this.setState({
                                tok: tokdata as Tok
                            });
                        } else {
                            let ogTok = cloneDeep(tokdata);
                            this.setState({
                                tok: tokdata as Tok
                            }, () => {
                                MySwal.close();
                                this.createCopyTok(0, listCopy, ogTok);
                            });

                        }
                        if (tokdata) window.localStorage.setItem("copiedTok", JSON.stringify(tokdata));

                        if ((tok["tok_group"] == "Mega" || tok["tok_group"] == "Q&a") && tokdata["id"]) {
                            this.createSections(tokdata["id"], 1, 0);
                        } else {
                            this.setState({
                                show: false
                            });
                            this.addTokToList(tokdata);
                        }
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                        MySwal.hideLoading();
                    }
                }

            }).catch(response => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
                MySwal.hideLoading();
            })
        }
    }

    createCopyTok(count = 0, listCopy = new Array, ogTok) {
        var { CopyTokLevel, GroupCopy, CommunityCopy, tok } = this.state;
        let feedid = "";
        let postedTo = {};

        tok["copied_from"] = new Array;
        tok["posted_to"] = new Array;
        if (tok["community_id"]) {
            tok["copied_from"] = tok["copied_from"].concat({
                feed: "Community",
                subfeed: {} as PostedTo
            });
        } else if (tok["pk"].includes(tok["user_id"])) {
            tok["copied_from"] = tok["copied_from"].concat({
                feed: "My Toks",
                subfeed: {} as PostedTo
            });
        } else if (tok["pk"].includes(tok["group_id"])) {
            tok["copied_from"] = tok["copied_from"].concat({
                feed: "Group",
                subfeed: {} as PostedTo
            });
        }
        if (listCopy[count] == "level") {
            tok["level1"] = CopyTokLevel["level1"];
            tok["level2"] = CopyTokLevel["level2"];
            tok["level3"] = CopyTokLevel["level3"];
            if (tok["level1"]) {
                feedid = tok["level1"].toLowerCase();
                postedTo = {
                    feed: tok["level1"],
                    subfeed: {} as PostedTo
                }
                if (tok["level2"]) {
                    feedid = feedid + "-" + tok["level2"].toLowerCase();
                    postedTo["subfeed"] = {
                        feed: tok["level2"],
                        subfeed: {} as PostedTo
                    }
                    if (tok["level3"]) {
                        feedid = feedid + "-" + tok["level3"].toLowerCase();
                        postedTo["subfeed"]["subfeed"] = {
                            feed: tok["level3"],
                            subfeed: {} as PostedTo
                        }
                    }
                }
            }
        } else if (listCopy[count] == "group") {
            tok["group_id"] = GroupCopy["id"];
            feedid = tok["group_id"];
            postedTo = {
                feed: GroupCopy["name"],
                subfeed: {} as PostedTo
            }
        } else if (listCopy[count] == "community") {
            tok["level1"] = CommunityCopy["level1"];
            tok["level2"] = CommunityCopy["level2"];
            tok["level3"] = CommunityCopy["level3"];
            tok["community_id"] = CommunityCopy["id"];
            if (tok["level1"]) {
                feedid = tok["level1"].toLowerCase();
                if (tok["level2"]) {
                    feedid = feedid + "-" + tok["level2"].toLowerCase();
                    if (tok["level3"]) {
                        feedid = feedid + "-" + tok["level3"].toLowerCase();
                    }
                }
            }
            feedid = feedid + "-" + tok["community_id"];
            postedTo = {
                feed: CommunityCopy["name"],
                subfeed: {} as PostedTo
            }
        }
        let currentCount = count + 1;
        let msg = "Creating Copy " + currentCount + " out of " + listCopy.length;
        if (count == 0) {
            MySwal.fire({
                title: msg,
                showConfirmButton: false
            });
        } else {
            MySwal.update({
                title: msg,
                showConfirmButton: false
            });
        }
        let updateTokTileTemp = this.updateTokTile;
        MySwal.showLoading();

        addClassTok(tok, feedid).then(response => {
            if (!ogTok["posted_to"]) {
                ogTok["posted_to"] = new Array;
            }
            ogTok["posted_to"] = ogTok["posted_to"].concat(postedTo);
            if (response) {
                if (response.status == 200) {
                    updateClassTok(ogTok).then(updateRes => {
                        if (updateRes) {
                            if (updateRes.status == 200) {
                                let copyTok = cloneDeep(ogTok);
                                if (currentCount < listCopy.length) {

                                    this.setState({
                                        tok: ogTok
                                    }, () => {
                                        this.createCopyTok(currentCount, listCopy, copyTok);
                                    });

                                } else {
                                    try {
                                        updateTokTileTemp(ogTok);
                                        this.setState({
                                            tok: ogTok
                                        });
                                        MySwal.hideLoading();
                                        MySwal.update({
                                            title: "Success",
                                            icon: "success",
                                            showConfirmButton: true
                                        });
                                    } catch (err) {
                                    }
                                }
                            }
                        }
                    }).catch(err => {

                    }).finally(() => {

                    })
                }
            }
        }).catch(err => {
            MySwal.hideLoading();
            MySwal.update({
                title: <p>Error</p>,
                icon: "error",
                showConfirmButton: true
            });
        }).finally(() => {

        })
    }

    updateTokTile(tok: any) {
        this.props.updateTok(tok);
    }

    addTokToList(data: any) {
        this.props.receiveData(data);
    }

    createSections(id, addCounter = 1, sectionNumber = 0, newSections = new Array, newSectionCount = 0) {
        const { tok, defaultEditTok, editTok, defaultTok } = this.state;
        let targetTok = editTok ? cloneDeep(defaultEditTok) : cloneDeep(tok);
        MySwal.fire({
            title: <p>Adding Sections {addCounter} of {newSections.length > 0 ? newSections.length : targetTok.sections.length}</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        let section = newSections.length > 0 ? newSections[newSectionCount] : cloneDeep(targetTok.sections[sectionNumber]);
        section["section_number"] = sectionNumber + 1;
        section["tok_id"] = id;
        addSection(section, id, "").then(response => {
            if (response) {
                if (response.status == 200) {
                    addCounter++;
                    sectionNumber++;
                    newSectionCount++;

                    let targetCount = newSections.length > 0 ? newSectionCount : sectionNumber;
                    let targetMaxCount = newSections.length > 0 ? newSections.length : targetTok.sections.length;

                    if (targetCount >= targetMaxCount) {
                        this.setState({
                            show: false
                        });
                        MySwal.update({
                            title: <p>Sections Added</p>,
                            icon: "success"
                        });
                        MySwal.hideLoading();
                        var tempTok = targetTok;
                        tempTok["section_count"] = sectionNumber;
                        if (editTok) {
                            window.localStorage.setItem("copiedTok", JSON.stringify(tempTok));
                            tempTok.sections.push(section);
                            try {
                                this.updateTokTile(tempTok);
                            } catch (err) {
                            }
                        } else {
                            tempTok["created_time"] = new Date();
                            this.props.receiveData(tempTok)
                        }
                        var newTok = cloneDeep(tok);
                        var newDefaultTok = cloneDeep(defaultTok);
                        var newDefaultEditTok = cloneDeep(defaultEditTok);
                        newTok["section_count"] = sectionNumber;
                        newDefaultTok["section_count"] = sectionNumber;
                        newDefaultEditTok["section_count"] = sectionNumber;
                        if (!newDefaultEditTok["sections"]) {
                            newDefaultEditTok["sections"] = new Array;
                        }
                        newDefaultEditTok.sections.push(section);
                        this.setState({
                            tok: newTok,
                            defaultTok: newDefaultTok,
                            defaultEditTok: newDefaultEditTok,
                        });
                    } else {
                        var newTok = cloneDeep(tok);
                        var newDefaultTok = cloneDeep(defaultTok);
                        var newDefaultEditTok = cloneDeep(defaultEditTok);
                        newTok["section_count"] = sectionNumber;
                        newDefaultTok["section_count"] = sectionNumber;
                        newDefaultEditTok["section_count"] = sectionNumber;
                        if (!newDefaultEditTok["sections"]) {
                            newDefaultEditTok["sections"] = new Array;
                        }
                        newDefaultEditTok.sections.push(section);
                        this.setState({
                            tok: newTok,
                            defaultTok: newDefaultTok,
                            defaultEditTok: newDefaultEditTok,
                        }, () => {
                            this.createSections(id, addCounter, sectionNumber, newSections, newSectionCount);
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>A error occured</p>,
                        icon: "error"
                    });
                    MySwal.hideLoading();
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>A error occured</p>,
                icon: "error"
            });
            MySwal.hideLoading();
        })
    }
    ShowHideDetailsList(e) {
        const { tok } = this.state;
        var tempTok = tok;
        if (e.currentTarget.getAttribute("data-list-count")) {
            tempTok["details_list_show"][e.currentTarget.getAttribute("data-list-count")] = !tempTok["details_list_show"][e.currentTarget.getAttribute("data-list-count")];
        }
        this.setState({
            tok: tempTok
        });
    }

    ShowHideAutopaste(e) {
        const { tok } = this.state;
        var tempTok = tok;
        tempTok["show_autopaste_details"] = !tempTok["show_autopaste_details"];
        this.setState({
            tok: tempTok
        });
    }

    PasteTokLink(e) {
        const { tok, searchTokLinkDetail } = this.state;
        let tempTok = tok;
        let copiedTok = window.localStorage.getItem("copiedTok") ? JSON.parse(window.localStorage.getItem("copiedTok")) : "";
        let setTokLink = false;
        let detail = -1;
        try {
            detail = Number(e.currentTarget.getAttribute("data-detail"));
        } catch (err) { }
        if (searchTokLinkDetail > -1) {
            detail = searchTokLinkDetail;
        }

        if (copiedTok) {
            if (copiedTok["id"]) {
                setTokLink = true;
            }
        }
        if (setTokLink) {
            if (detail > -1) {
                let tempTok = tok;
                let tempDetailTokLinks = tempTok["detail_tok_links"];
                //let tempDetailTokLinksNames = tempTok["detail_tok_links_names"]
                if (detail > tempDetailTokLinks.length) {
                    let currentIndentSize = tempDetailTokLinks.length;
                    for (let x = detail - currentIndentSize; x < detail; x++) {
                        tempDetailTokLinks.push("");
                        //tempDetailTokLinksNames.push("");
                    }
                }
                tempDetailTokLinks[detail] = copiedTok["id"];
                tempTok["detail_tok_links"] = tempDetailTokLinks;

                //tempDetailTokLinksNames[detail] = copiedTok["primary_text"].substring(0, 50) + "...";
                //tempTok["detail_tok_links_names"] = tempDetailTokLinksNames;
                tempTok["details"][detail] = copiedTok["primary_text"];
            } else {
                tempTok["tok_link"] = copiedTok["id"];
                //tempTok["tok_link_name"] = copiedTok["primary_text"].substring(0, 50) + "...";
                tempTok["secondary_text"] = copiedTok["primary_text"];
            }

            this.setState({
                tok: tempTok
            });
        } else {
            MySwal.fire({
                title: <p>No copied Tok.</p>,
                allowOutsideClick: false,
                icon: "info"
            });
        }

    }

    removeTokLink(e) {
        const { tok } = this.state;
        let detail = Number(e.currentTarget.getAttribute("data-detail"));
        let tempTok = tok;
        if (detail > -1) {
            tempTok["detail_tok_links"][detail] = "";
            tempTok["details"][detail] = "";
        } else {
            tempTok["tok_link"] = "";
            tempTok["secondary_text"] = "";
        }
        this.setState({
            tok: tempTok
        }, () => {
            var { tok, defaultTok } = this.state;
        });
    }

    removeTokDetailImage(e) {
        const { tok } = this.state;
        let detail = Number(e.currentTarget.getAttribute("data-detail"));
        let tempTok = tok;
        if (detail > -1) {
            tempTok["detail_images"][detail] = "";
        }
        this.setState({
            tok: tempTok
        });
    }

    searchTokModalCall(e) {
        let detail = Number(e.currentTarget.getAttribute("data-detail"));
        if (detail > -1) {
            this.setState({
                searchTokLinkDetail: detail
            });
        }
        this.searchTokTrigger.current.click();
    }

    convertSubbullet(e) {
        const { tok } = this.state;
        let index = Number(e.currentTarget.getAttribute("data-index"));
        let tempTok = tok;
        let tempIndentList = tempTok["is_indent"];
        if (index > tempIndentList.length) {
            let currentIndentSize = tempIndentList.length;
            for (let x = index - currentIndentSize; x < index; x++) {
                tempIndentList.push(false);
            }
        }
        tempIndentList[index] = !tempIndentList[index];
        tempTok["is_indent"] = tempIndentList;
        this.setState({
            tok: tempTok
        });
    }

    autoPasteDetails() {
        const { tok } = this.state;
        let autoPasteValue = this.autoPasteField.current.querySelector("textarea").value;
        let autoPasteArray = autoPasteValue.split(/\n/);
        let tempTok = tok;
        tempTok["details"] = autoPasteArray;
        this.setState({
            tok: tempTok
        });
    }

    manageDetailImagesShow(e) {
        const { tok } = this.state;
        let tempTok = tok;
        tempTok["temp_detail_images"] = tempTok["detail_images"];
        this.setState({
            tok: tempTok,
            detailImagesShow: true
        });
    }

    detailImagesClose() {
        this.setState({
            detailImagesShow: false
        });
    }

    addTempDetailImageRow(e) {
        const { tok } = this.state;
        let tempTok = tok;
        tempTok["temp_detail_images"].push("");
        this.setState({
            tok: tempTok,
        });
    }

    removeTempDetailImageRow(e) {
        const { tok } = this.state;
        let tempTok = tok;
        let removeIndex = Number(e.currentTarget.getAttribute("data-index"));
        tempTok["temp_detail_images"].splice(removeIndex, 1);
        this.setState({
            tok: tempTok,
        });
    }

    setDetailImages(e) {
        const { tok } = this.state;
        let tempTok = tok;
        tempTok["detail_images"] = tempTok["temp_detail_images"];
        this.setState({
            tok: tempTok,
        });
        this.detailImagesClose();
    }

    viedoLinkInputCall(e) {
        var modalNode = document.querySelectorAll('.modal[tabindex="-1"]');
        for (let x = 0; x < modalNode.length; x++) {
            modalNode[x].removeAttribute('tabindex');
            modalNode[x].classList.add('js-swal-fixed');
        }

        if (e.currentTarget.getAttribute("data-detail")) {
            this.setState({
                tempYoutubeLinkDetail: Number(e.currentTarget.getAttribute("data-detail")),
            });
        }
        if (e.currentTarget.getAttribute("data-tempdetail")) {
            this.setState({
                tempManageYoutubeLinkDetail: Number(e.currentTarget.getAttribute("data-tempdetail")),
            });
        }
        MySwal.fire({
            title: "Enter a Youtube Link:",
            input: 'url',
            showCancelButton: true,
            confirmButtonText: "Enter"
        }).then((result) => {
            if (result.isConfirmed && isYoutubeLink(result.value)) {
                this.setState({
                    tempYoutubeLink: result.value,
                    showYoutubeVideo: true
                });
            }

            var modalNode = document.querySelectorAll('.modal.js-swal-fixed');
            for (let x = 0; x < modalNode.length; x++) {
                modalNode[x].setAttribute('tabindex', '-1');
                modalNode[x].classList.remove('js-swal-fixed');
            }
        });
    }

    closeYoutubeVideo() {
        this.setState({
            tempYoutubeLinkDetail: -1,
            showYoutubeVideo: false
        });
    }

    linkVideoSet(e) {
        const { tok, tempYoutubeLink, tempYoutubeLinkDetail, tempManageYoutubeLinkDetail } = this.state;
        let tempTok = tok;
        if (tempManageYoutubeLinkDetail > -1) {
            let index = tempManageYoutubeLinkDetail;
            let tempTok = tok;
            let tempDetailImages = tempTok["temp_detail_images"];
            const detailImages = tempTok["detail_images"];
            let ogDetailImages = new Array;
            for (let x = 0; x < detailImages.length; x++) {
                ogDetailImages.push(detailImages[x]);
            }
            tempTok["detail_images"] = ogDetailImages;
            tempDetailImages[index] = tempYoutubeLink;
            tempTok["temp_detail_images"] = tempDetailImages;
        } else if (tempYoutubeLinkDetail > -1) {
            tempTok["detail_images"][tempYoutubeLinkDetail] = tempYoutubeLink
        } else {
            tempTok["image"] = tempYoutubeLink;
        }
        this.setState({
            tok: tempTok,
            showYoutubeVideo: false,
            tempYoutubeLinkDetail: -1,
            tempManageYoutubeLinkDetail: -1
        });
    }

    SetShowOptionalFields() {
        const { showOptionalFields } = this.state;
        this.setState({
            showOptionalFields: !showOptionalFields
        });
    }
    render() {
        const { TempCommunityCopy, GroupCopy, CopyTokLevel, GroupsCopy, GroupsCopyLoader, CommunityLoader, Communities, CopyTokLevel1, CopyTokLevel2, CopyTokLevel2List, CopyTokLevel3, CopyTokLevel3List, CommunityCopy, CopyTokShow, CopyTokShowMode, isTokChannel, defaultTok, editTok, visibleStateWarning, tok, showOptionalFields, showYoutubeVideo, tempYoutubeLink, tempYoutubeLinkDetail, tempManageYoutubeLinkDetail, detailImagesShow, cropperSrc, cropperPosition, customClass, customTitle, cropperShow, show, colorPickShow, colorPick, isPrivate } = this.state;

        var requiredFields = [
            "primary_text",
            "category",
        ];
        var disablePost = false;
        if (!isTokChannel) {
            requiredFields.push("tok_type");
        }
        if (tok["tok_group"] == "Basic" || tok["tok_group"] == "Pic") {
            requiredFields.push("secondary_text");
        } else if (tok["tok_group"] == "Detailed" || tok["tok_group"] == "List") {
            if (tok["details"]) {
                for (let x = 0; x < tok["details"].length; x++) {
                    if (!tok["details"][x]) {
                        disablePost = true;
                    }
                }
            } else {
                disablePost = true;
            }
        } else {
        }
        if (!disablePost) {
            for (let x = 0; x < requiredFields.length; x++) {
                if (!tok[requiredFields[x]]) {
                    disablePost = true;
                    break;
                }
            }
        }
        let disableCopy = false;
        if (CopyTokShowMode == "Community" && !TempCommunityCopy["id"]) {
            disableCopy = true;
        }
        return (
            <>
                <Button className={customClass} ref={this.addTokBtnRef} variant="success" onClick={this.handleShow} style={{ display: "flex", alignItems: "center" }}>
                    <div className={'parrot-write-icon'}></div>
                    <span>Add {customTitle ? customTitle : <>Tok</>}</span>
                </Button>
                <SearchTokLink callTrigger={this.searchTokTrigger} setTokLink={this.PasteTokLink} />
                <Modal size='lg' show={CopyTokShow} onHide={this.closeCopyTok} style={{ zIndex: "1307" }}>
                    <Modal.Header>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeCopyTok} />
                            Copy Tok {CopyTokShowMode}
                            <Button variant="success" disabled={disableCopy} onClick={this.setCopyConfirm}>Set</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ height: "55vh", overflow: "auto", overflowX: "hidden" }}>
                        {CopyTokShowMode == "Channel" || CopyTokShowMode == "Community" ?
                            <Row className={'copytok-tokchannel-container'}>
                                <Col className={'copytok-tokchannel-item'} xs={12}>
                                    <TextField style={{ width: "100%" }} select label="Level 1" name="level1-add" onChange={this.setCopyTokLevel}>
                                        {channels.map(channel => {
                                            if (channel.status == 1) {
                                                return (
                                                    <MenuItem key={channel.ref} value={channel.ref}>
                                                        {channel.level}
                                                    </MenuItem>
                                                )
                                            }
                                        })}
                                    </TextField>
                                </Col>

                                {CopyTokLevel2List.length > 0 ?
                                    <Col className={'copytok-tokchannel-item'} xs={12}>
                                        <TextField style={{ width: "100%" }} select label="Level 2" name="level2-add" onChange={this.setCopyTokLevel}>
                                            {CopyTokLevel2List.map(channel => {
                                                return (
                                                    <MenuItem key={channel.ref} value={channel.ref}>
                                                        {channel.level}
                                                    </MenuItem>
                                                )
                                            })}
                                        </TextField>
                                    </Col> : ""
                                }
                                {CopyTokLevel3List.length > 0 ?
                                    <Col className={'copytok-tokchannel-item'} xs={12}>
                                        <TextField style={{ width: "100%" }} select label="Level 3" name="level3-add" onChange={this.setCopyTokLevel}>
                                            {CopyTokLevel3List.map(channel => {
                                                return (
                                                    <MenuItem key={channel.ref} value={channel.ref}>
                                                        {channel.level}
                                                    </MenuItem>
                                                )
                                            })}
                                        </TextField>
                                    </Col> : ""
                                }
                                {CopyTokLevel3 && CopyTokShowMode == "Community" ?
                                    <>
                                        <div style={{ fontWeight: "600" }}>Communities:</div>
                                        {CommunityLoader ?
                                            <WaitSpinner /> :
                                            Communities ?
                                                <RadioGroup name="copytok-group" onChange={this.setCopyCommunity} style={{ display: "flex", overflow: "hidden scroll", flexWrap: "nowrap", maxHeight: "230px" }}>
                                                    {Communities.map(community => {
                                                        return (
                                                            <Row className={'col-12'}>
                                                                <Col>
                                                                    <Radio
                                                                        value={community.id}
                                                                    />
                                                                    {community.name}
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </RadioGroup>
                                                : ""
                                        }
                                    </> : ""
                                }
                            </Row> :
                            CopyTokShowMode == "Group" ?
                                GroupsCopyLoader ? <WaitSpinner /> :
                                    GroupsCopy ?
                                        <RadioGroup name="copytok-group" onChange={this.setCopyGroup}>
                                            {GroupsCopy.map(group => {
                                                return (
                                                    <Row className={'col-12'}>
                                                        <Col>
                                                            <Radio
                                                                value={group.id}
                                                                data-name={group.name}
                                                            />
                                                            {group.name}
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </RadioGroup> : ""
                                : ""
                        }
                    </Modal.Body>
                </Modal>
                <Modal size='lg' show={cropperShow} onHide={this.handleCropperClose} style={{ zIndex: "1306" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleCropperClose} />
                            Cropper
                            <Button variant="success" onClick={this.onCrop}>Crop</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Cropper
                            src={cropperSrc}
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={16 / 9}
                            ref={this.cropperRef}
                            data-position={cropperPosition}
                        />

                    </Modal.Body>
                </Modal>

                <Modal size='lg' show={showYoutubeVideo} onHide={this.closeYoutubeVideo} style={{ zIndex: "1307" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeYoutubeVideo} />
                            Video Preview
                            <Button variant="success" onClick={this.linkVideoSet}>Link Video</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <YouTube className={'fullwidth-iframe'} videoId={isYoutubeLink(tempYoutubeLink) ? extractYoutubeID(tempYoutubeLink) : ""} />
                    </Modal.Body>
                </Modal>

                <Modal size='lg' show={colorPickShow} onHide={this.handleColorClose} style={{ zIndex: "1306" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleColorClose} />
                            Select Color
                            <Button variant="success" onClick={this.setPickColor}>Set</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Row style={{ marginTop: "10px" }}>
                            {colors.map((color, index) => (
                                <Col onClick={this.setPickColorTemp} data-color={color} xs={3} className={colorPick == "#" + color ? 'color-class-tok-picker-item active' : 'color-class-tok-picker-item'} style={{ backgroundColor: `#${color}` }}>
                                    <div className={'color-tok-picker-active-indicator'}>
                                        <FontAwesomeIcon size={'2xl'} icon={faCheck} />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal size='lg' show={detailImagesShow} style={{ zIndex: "1306" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.detailImagesClose} />
                            Manage Media
                            <Button variant="success" onClick={this.setDetailImages}>Set Image(s)</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        {tok["temp_detail_images"] ? tok["temp_detail_images"].map((image, index) => (
                            <Row style={{ margin: "unset", backgroundColor: "lightgray", borderRadius: "10px", padding: "10px", marginBottom: "10px" }}>
                                <div style={{ borderRadius: "100%", fontWeight: "600", height: "30px", width: "30px", display: "flex", backgroundColor: "black", color: "white", justifyContent: "center", alignItems: "center", marginRight: "5px" }}>
                                    {index + 1}
                                </div>
                                {isYoutubeLink(image) ?
                                    <Col><YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(image)} /></Col> :
                                    <Col style={{ backgroundImage: `url(${image})`, height: image ? "300px" : "", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
                                        {!image ?
                                            <div>
                                                <Button variant='primary' onClick={(e) => e.currentTarget.parentNode.querySelector("input").click()} style={{ marginRight: "5px" }}>Add Image +</Button>
                                                <Button variant='primary' onClick={this.viedoLinkInputCall} data-tempdetail={index}>Add Video +</Button>
                                                <input type="file" accept="image/*" onChange={this.onImageSet} data-position={'image'} data-tempdetail={index} hidden />
                                            </div> : ""
                                        }
                                    </Col>
                                }

                                {image ?
                                    <Col xs={'auto'} style={{ margin: "unset", padding: "unset" }}>
                                        <Row>
                                            <Col xs={'auto'} style={{ color: "green", cursor: "pointer" }}>
                                                <FontAwesomeIcon size={'2x'} icon={faImage} onClick={(e) => e.currentTarget.parentNode.querySelector("input").click()} />
                                                <input type="file" accept="image/*" onChange={this.onImageSet} data-position={'image'} data-tempdetail={index} hidden />
                                            </Col>
                                            <Col xs={'auto'} style={{ color: "red", cursor: "pointer" }} data-index={index} onClick={this.removeTempDetailImageRow}>
                                                <FontAwesomeIcon size={'2x'} icon={faTimes} />
                                            </Col>
                                        </Row>
                                        <Col>
                                            <Button variant='danger' data-tempdetail={index} onClick={this.removeImage} data-position={'image'}>Remove {isYoutubeLink(image) ? "Video" : "Image"}</Button>
                                        </Col>
                                    </Col> : ""
                                }
                            </Row>
                        )) : ""}
                        {!(tok["tok_group"] == "Detailed" && tok.details.length == 10) ?
                            <Button style={{ width: "100%" }} variant='primary' onClick={this.addTempDetailImageRow}>Add Row +</Button>
                            : ""}
                    </Modal.Body>
                </Modal>
                <Modal size='lg' show={show} onHide={this.handleClose} style={{ zIndex: "1305" }}>
                    <Modal.Header className={'text-color-white dark-background-2'} style={{ paddingBottom: `${visibleStateWarning.length == 0 ? 20 : visibleStateWarning.length * 20}px` }}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600", marginBottom: "40px" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleClose} />
                            {tok["group_id"] && tok["group_name"] ?
                                <>{editTok ? "Edit" : "Add"} Tok {editTok ? "from" : "to"} {tok["group_name"]}</> :
                                <>{editTok ? "Edit" : "Add"} {tok["level3"].length > 0 ? textDisplay(tok["level3"]) : tok["level2"].length > 0 ? textDisplay(tok["level2"]) : tok["level1"].length > 0 ? textDisplay(tok["level1"]) : ""} Tok</>
                            }

                            <Button disabled={disablePost} variant="success" onClick={this.createClassTok} style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                <div className={'parrot-write-icon'}></div>
                                <span>{editTok ? "Update" : "Post"}</span>
                                <div style={{ height: "100px", position: "absolute", top: "100%", color: "black", fontSize: "13px", textAlign: "right", right: "0", width: "max-content" }}>
                                    {visibleStateWarning.map((warning) => (
                                        <>
                                            <span>{warning["text"]}</span><br />
                                        </>
                                    ))}
                                </div>
                            </Button>
                        </div>

                    </Modal.Header>
                    <Modal.Body className={'modal-body-addclasstok-height text-color-white dark-background-2'}>

                        <Row>
                            {tok["tok_group"] ?
                                <>
                                    <Col xs={"4"}>
                                        <TextField style={{ width: "100%" }} defaultValue={defaultTok["reference_id"] ? defaultTok["reference_id"] : ""} name="reference_id" onChange={this.SetTokValues} label="Reference ID # (Optional)" inputProps={{ maxLength: 20 }} />
                                        <p className={'character-count'}>{tok["reference_id"] ? tok["reference_id"].length : "0"}/20</p>
                                    </Col>
                                    <Col className={'row'} xs={"8"} style={{ padding: "unset" }}>
                                        <div style={{ width: "60px", paddingLeft: "unset" }}>
                                            <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" style={{ width: "100%" }} />
                                        </div>
                                        <Col>
                                            <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600", fontSize: "12px", height: "fit-content" }}>The Reference ID is used for Grouping and Sorting.</div>
                                        </Col>
                                    </Col>
                                </>
                                : ""}
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: "1rem" }}>
                                <TextField style={{ width: "100%" }} select label="Format" defaultValue={defaultTok["tok_group"] ? defaultTok["tok_group"] : ""} name="tok_group" onChange={this.SetTokValues}>
                                    {tokgroups.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        {tok["tok_group"] ?
                            <Row>
                                {tok["level1"].length == 0 ?
                                    <Col xs={'4'}>
                                        <TextField style={{ width: "100%" }} name="tok_type" defaultValue={defaultTok["tok_type"] ? defaultTok["tok_type"] : ""} onChange={this.SetTokValues} label={customTitle ? customTitle : "Name"} inputProps={{ maxLength: 50 }} />
                                        <p className={'character-count'}>{tok["tok_type"] ? tok["tok_type"].length : "0"}/50</p>
                                    </Col> : ""
                                }

                                <Col xs={tok["level1"].length > 0 ? '8' : '4'}>
                                    <TextField style={{ width: "100%" }} defaultValue={defaultTok["category"] ? defaultTok["category"] : ""} name="category" onChange={this.SetTokValues} label="Category" inputProps={{ maxLength: 50 }} />
                                    <p className={'character-count'}>{tok["category"] ? tok["category"].length : "0"}/50</p>
                                </Col>
                                <Col xs={'4'} style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                    <Button className={'dash-btn'}>
                                        Add Tile Sticker
                                    </Button>
                                </Col>
                                <Col xs={'3'}>
                                    <p style={{ margin: "unset", width: "max-content", cursor: "pointer" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                        <FontAwesomeIcon icon={faImage} />
                                        <b> - Select an Image</b>
                                        <input type="file" accept="image/*" onChange={this.onImageSet} data-position="image" hidden />
                                    </p>
                                </Col>
                                <Col xs={'3'}>
                                    <p style={{ margin: "unset", width: "max-content", cursor: "pointer" }} onClick={this.viedoLinkInputCall}>
                                        <FontAwesomeIcon icon={faVideo} />
                                        <b> - Add an Video Link</b>
                                    </p>
                                </Col>
                                <Col xs={'3'}>
                                    <Button variant="primary" style={{ width: "100%" }}>
                                        Select Tile Color
                                    </Button>
                                </Col>
                                <Col xs={'3'} style={{ height: "100%" }}>
                                    <Row style={{ height: "100%", alignItems: "center" }}>
                                        <span className={"col-auto"}>Selected Color:</span>
                                        <Col className={"selected-color-indicator"} style={{ backgroundColor: tok["color_main_hex"] ? tok["color_main_hex"] : "#FFFFFF" }} onClick={this.handleColorSet}></Col>
                                    </Row>
                                </Col>
                                {tok["tok_group"] == "Detailed" || tok["tok_group"] == "List" ?
                                    <Col className={"col-12"} style={{ marginBottom: "1rem", marginTop: "10px" }}>
                                        <TextField style={{ width: "100%" }} select label="Bullet Type" defaultValue={defaultTok["bullet_kind"] ? defaultTok["bullet_kind"] : ""} name="bullet_kind" onChange={this.SetTokValues}>
                                            {bullets.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>
                                    : ""}
                                {tok["image"] ?
                                    <Row className={"col-12"}>
                                        {isYoutubeLink(tok["image"]) ?
                                            <>
                                                <YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(tok["image"])} />
                                                <Button onClick={this.removeImage} data-position="image" variant='primary' style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)", width: "max-content", marginTop: "top" }}>Remove Video</Button>
                                            </> :
                                            <>
                                                <Col>Selected Image:</Col>
                                                <Col className={"col-12"} style={{ marginTop: "top", maxWidth: "100%" }}><img src={tok["image"]} style={{ maxWidth: "100%" }} /></Col>
                                                <Button onClick={this.removeImage} data-position="image" variant='primary' style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)", width: "max-content", marginTop: "top" }}>Remove Image</Button>
                                            </>
                                        }
                                    </Row>
                                    : ""
                                }

                                <Col style={{ marginTop: "1rem" }} className={"col-12"}>
                                    <TextField style={{ width: "100%" }} name="primary_text" defaultValue={defaultTok["primary_text"] ? defaultTok["primary_text"] : ""} onChange={this.SetTokValues} label="Title/Main Text" inputProps={{ maxLength: 300 }} />
                                    <p className={'character-count'}>{tok["primary_text"] ? tok["primary_text"].length : "0"}/300</p>
                                </Col>

                                {tok["tok_group"] == "Basic" || tok["tok_group"] == "Pic" ?
                                    <>
                                        <Col className={"col-12"}>

                                            <Row style={{ position: "relative" }}>
                                                {tok["tok_link"] ?
                                                    <div style={{ zIndex: "1", position: "absolute", left: "12.5px", top: "0", bottom: "0", display: "flex", alignItems: "center", fontWeight: "600" }}>
                                                        Tok Link: <span style={{ color: "deepskyblue" }}>[[{tok["secondary_text"]}]]</span>
                                                    </div> : ""
                                                }
                                                <Col>
                                                    <TextField style={{ width: "100%" }} disabled={tok["tok_link"] ? true : false} value={tok["tok_link"] ? " " : tok["secondary_text"]} defaultValue={defaultTok["secondary_text"] ? defaultTok["secondary_text"] : ""} name="secondary_text" onChange={this.SetTokValues} label="Detail" inputProps={{ maxLength: 600 }} />
                                                </Col>
                                                {tok["tok_link"] ?
                                                    <div className={'col-auto item-close'} style={{ zIndex: "2" }}>
                                                        <Tooltip title={"Remove Tok Link"}>
                                                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'red'} size={'lg'} icon={faTimes} onClick={this.removeTokLink} data-detail={-1} />
                                                        </Tooltip>
                                                    </div> : ""
                                                }

                                            </Row>

                                            <p className={'character-count'}>{tok["secondary_text"] ? tok["secondary_text"].length : "0"}/600</p>
                                            {tok["tok_link"] ?
                                                <>
                                                    {/*
                                                <div style={{ display: "flex" }}>
                                                    {tok["tok_link_loading"] ?
                                                        <div style={{ zoom: "70%" }}>
                                                            <CircularProgress />
                                                        </div> :
                                                        <ButtonMaterial variant={'outlined'} color={'success'}>{tok["secondary_text"] ? tok["secondary_text"].substring(0, 50) + "..." : ""}</ButtonMaterial>
                                                    }
                                                    <Button variant='danger' onClick={this.removeTokLink} data-detail={-1}>x</Button>
                                                </div>
                                                 */}
                                                </> :
                                                <>
                                                    <ButtonMaterial variant={'outlined'} color={'primary'} onClick={this.PasteTokLink} data-detail={-1}>Paste Tok Link</ButtonMaterial>
                                                    <ButtonMaterial variant={'outlined'} color={'success'} onClick={this.searchTokModalCall} data-detail={-1}>Search for Toks</ButtonMaterial>
                                                </>
                                            }
                                        </Col>
                                        <Col className={"col-6"}>
                                            <p style={{ margin: "unset", width: "max-content", cursor: "pointer" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                                <FontAwesomeIcon icon={faImage} />
                                                <b> - Select an Secondary Image</b>
                                                <input type="file" accept="image/*" onChange={this.onImageSet} data-position="secondary_image" hidden />
                                            </p>
                                        </Col>
                                        {tok["secondary_image"] ?
                                            <Row className={"col-12"}>
                                                <Col>Selected Secondary Image:</Col>
                                                <Col className={"col-12"} style={{ marginTop: "top" }}><img src={tok["secondary_image"]} style={{ maxWidth: "100%" }} /></Col>
                                                <Button onClick={this.removeImage} data-position="secondary_image" variant='primary' style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)", width: "max-content", marginTop: "top" }}>Remove Image</Button>
                                            </Row> : ""
                                        }
                                    </>
                                    : ""}
                                {tok["tok_group"] == "Basic" ?
                                    <>
                                        <div className={"alert alert-primary"} style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)" }}>
                                            <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl2.jpg" className={'tooltip-avatar-img-variant-1 mr-2 rounded-circle'} />
                                            <strong>Tip:</strong> To add more info, use the Notes field below or additional toks
                                        </div>
                                        <Col className={"col-12"}>
                                            <TextField style={{ width: "100%" }} defaultValue={defaultTok["notes"] ? defaultTok["notes"] : ""} multiline minRows={3} name="notes" onChange={this.SetTokValues} label="Notes" inputProps={{ maxLength: 2000 }} />
                                            <p className={'character-count'}>{tok["notes"] ? tok["notes"].length : "0"}/2000</p>
                                        </Col>
                                    </>
                                    : ""}
                                {tok["tok_group"] == "Detailed" || tok["tok_group"] == "List" ?
                                    <div style={{ marginTop: "10px" }}>
                                        {tok["tok_group"] == "Detailed" ?
                                            <Row style={{ marginBottom: "15px" }}>
                                                <Col xs={12}>
                                                    <b style={{ cursor: "pointer", width: "100%", display: "block" }} onClick={this.ShowHideAutopaste}>
                                                        <FontAwesomeIcon className={'caret-down-icon'} icon={faCaretDown} style={{ transform: tok["show_autopaste_details"] ? "rotate(180deg)" : "" }} />
                                                        {tok["show_autopaste_details"] ? "Hide" : "Show"} Autopaste Fields
                                                    </b>
                                                </Col>
                                                <Col className={'row'} xs={6} style={{ alignItems: "center", display: tok["show_autopaste_details"] ? "flex" : "none" }}>
                                                    <div style={{ width: "65px" }}>
                                                        <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" style={{ width: "100%" }} />
                                                    </div>
                                                    <Col>
                                                        <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600", fontSize: "12px", height: "fit-content" }}>Enter the detal information below or press the Autopaste button to paste what you copied into the details by row.</div>
                                                    </Col>
                                                </Col>
                                                <Col className={'row'} xs={6} style={{ padding: "unset", display: tok["show_autopaste_details"] ? "flex" : "none" }}>
                                                    <Col>
                                                        <TextField ref={this.autoPasteField} style={{ width: "100%" }} multiline minRows={3} name="notes" label="Auto Paste Details" inputProps={{ maxLength: 2000 }} />
                                                    </Col>
                                                    <Col xs={'auto'} style={{ padding: "unset" }}>
                                                        <Button variant="primary" onClick={this.autoPasteDetails}>
                                                            Autopaste
                                                        </Button>
                                                    </Col>
                                                </Col>
                                                <Col xs={12}>
                                                    <p style={{ margin: "unset", width: "max-content", cursor: "pointer" }} onClick={this.manageDetailImagesShow}>
                                                        <FontAwesomeIcon icon={faImage} />
                                                        <b> - Manage Media</b>
                                                    </p>
                                                </Col>
                                            </Row> : <></>}
                                        {tok.details.map((detail, index) => (
                                            <>
                                                {tok["tok_group"] == "List" && index % 10 == 0 ?
                                                    <b style={{ cursor: "pointer", width: "100%", display: "block" }} data-list-count={Math.ceil(index / 10)} onClick={this.ShowHideDetailsList}>
                                                        Hide Details {`${index + 1} - ${index + 10}`}
                                                        <FontAwesomeIcon className={'caret-down-icon'} icon={faCaretDown} style={{ transform: tok["details_list_show"][Math.ceil(index / 10)] ? "" : "rotate(180deg)" }} />
                                                    </b> : ""
                                                }
                                                {(tok["tok_group"] == "List" && tok["details_list_show"][Math.floor(index / 10)]) || tok["tok_group"] == "Detailed" ?
                                                    <Row className={"col-12"} style={{ margin: "unset", marginBottom: "15px" }}>
                                                        <Col style={{ padding: "unset", paddingLeft: tok["is_indent"][index] ? "25px" : "unset" }}>
                                                            {tok["detail_images"][index] ?
                                                                <Row style={{ marginBottom: "15px" }}>
                                                                    {isYoutubeLink(tok["detail_images"][index]) ?
                                                                        <YouTube className={'fullwidth-iframe'} videoId={extractYoutubeID(tok["detail_images"][index])} /> :
                                                                        <Col style={{ display: "flex", justifyContent: "center" }}>
                                                                            <img src={tok["detail_images"][index]} style={{ height: "95px", maxWidth: "100%" }} />
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                                : ""
                                                            }
                                                            <div style={{ width: "100%", position: "relative" }}>
                                                                {tok["detail_tok_links"][index] ?
                                                                    <div style={{ position: "absolute", left: "12.5px", top: "0", bottom: "0", display: "flex", alignItems: "center", fontWeight: "600" }}>
                                                                        Tok Link: <span style={{ color: "deepskyblue" }}>[[{detail}]]</span>
                                                                    </div> : ""
                                                                }
                                                                <TextField disabled={tok["detail_tok_links"][index] ? true : false} style={{ width: "100%" }} value={tok["detail_tok_links"][index] ? " " : detail} onChange={this.SetDetailValues} defaultValue={detail ? detail : ""} name={index.toString()} label={`Detail ${index + 1}`} inputProps={{ maxLength: 600 }} />
                                                            </div>
                                                            <p className={'character-count'}>{detail ? detail.length : "0"}/600</p>
                                                            <Row>
                                                                {tok["detail_images"][index] ?
                                                                    <Col xs={'auto'} style={{ padding: "unset" }}>
                                                                        <Button variant='danger' onClick={this.removeTokDetailImage} data-detail={index} style={{ fontSize: "13px" }}>Remove {isYoutubeLink(tok["detail_images"][index]) ? "Video" : "Image"}</Button>
                                                                    </Col> :
                                                                    <>
                                                                        <Col xs={'auto'} style={{ padding: "unset", marginRight: "5px" }}>
                                                                            <p style={{ cursor: "pointer" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                                                                <FontAwesomeIcon size={'2x'} icon={faImage} />
                                                                                <input type="file" accept="image/*" onChange={this.onImageSet} data-detail={index} data-position="image" hidden />
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={'auto'} style={{ padding: "unset" }}>
                                                                            <p style={{ cursor: "pointer" }} onClick={this.viedoLinkInputCall} data-detail={index}>
                                                                                <FontAwesomeIcon size={'2x'} icon={faVideo} />
                                                                            </p>
                                                                        </Col>
                                                                    </>
                                                                }


                                                                {tok["detail_tok_links"][index] ?
                                                                    <>
                                                                        {/*
                                                                    <Col xs={'auto'} style={{ paddingRight: "unset" }}>
                                                                        {tok["detail_tok_links_loading"] ?
                                                                            <>
                                                                                {tok["detail_tok_links_loading"][index] ?
                                                                                    <div style={{ zoom: "70%" }}>
                                                                                        <CircularProgress />
                                                                                    </div> :
                                                                                    <ButtonMaterial variant={'outlined'} color={'success'} style={{ fontSize: "13px" }}>{detail ? detail.substring(0, 50) + "..." : ""}</ButtonMaterial>
                                                                                }
                                                                            </> :
                                                                            <ButtonMaterial variant={'outlined'} color={'success'} style={{ fontSize: "13px" }}>{detail ? detail.substring(0, 50) + "..." : ""}</ButtonMaterial>
                                                                        }

                                                                    </Col>
                                                                    
                                                                    <Col xs={'auto'} style={{ paddingRight: "unset" }}>
                                                                        <Button variant='danger' onClick={this.removeTokLink} data-detail={index} style={{ fontSize: "13px" }}>Remove Tok Link</Button>
                                                                    </Col>
                                                                    */}
                                                                    </> :
                                                                    <>
                                                                        <Col xs={'auto'} style={{ paddingRight: "unset" }}>
                                                                            <ButtonMaterial variant={'outlined'} color={'primary'} style={{ fontSize: "13px" }} onClick={this.PasteTokLink} data-detail={index}>Paste Tok Link</ButtonMaterial>
                                                                        </Col>
                                                                        <Col xs={'auto'} style={{ paddingRight: "unset" }}>
                                                                            <ButtonMaterial variant={'outlined'} color={'success'} style={{ fontSize: "13px" }} onClick={this.searchTokModalCall} data-detail={index}>Search for Toks</ButtonMaterial>
                                                                        </Col>
                                                                    </>
                                                                }
                                                                {index != 0 ?
                                                                    <Col xs={'auto'}>
                                                                        <ButtonMaterial variant={'outlined'} color={'info'} style={{ fontSize: "13px" }} onClick={this.convertSubbullet} data-index={index}>{tok["is_indent"][index] ? "Undo Sub-bullet" : "Convert as Sub-bullet"}</ButtonMaterial>
                                                                    </Col>
                                                                    : ""}
                                                            </Row>
                                                        </Col>
                                                        <div className={'col-auto item-close'}>
                                                            <Tooltip title={tok["detail_tok_links"][index] ? "Remove Tok Link" : "Remove Detail"}>
                                                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'red'} size={'lg'} icon={faTimes} data-id={index} data-detail={index} onClick={tok["detail_tok_links"][index] ? this.removeTokLink : this.RemoveDetail} />
                                                            </Tooltip>
                                                        </div>
                                                    </Row> : ""
                                                }

                                            </>
                                        ))}
                                        {!(tok["tok_group"] == "Detailed" && tok.details.length == 10) ?
                                            <Button variant="primary" onClick={this.AddDetail} style={{ width: "100%" }}>
                                                + Add Detail
                                            </Button>
                                            : ""}
                                    </div>
                                    : ""}
                                {tok["tok_group"] == "Mega" || tok["tok_group"] == "Q&a" ?
                                    <div style={{ marginTop: "10px" }}>
                                        {tok.sections.map((section, index) => (
                                            <Row className={"col-12 section-item-container"}>
                                                <Col style={{ position: "relative" }}>
                                                    <Row style={{ margin: "unset", display: "flex", alignItems: "center" }}>
                                                        <div className={'col-auto'} style={{ padding: "unset", cursor: "pointer" }}>
                                                            <FontAwesomeIcon size='lg' data-id={index} className={'caret-down-icon'} icon={faCaretDown} onClick={this.ShowHideSectionContent} style={{ transform: section.show ? "" : "rotate(180deg)" }} />
                                                        </div>
                                                        <div className={'col-auto section-item-number'} style={{ marginLeft: "5px", marginRight: "5px" }}>{index + 1}</div>
                                                        <div className={'col-auto'} style={{ padding: "unset", marginRight: "5px" }}>
                                                            <FontAwesomeIcon style={{ cursor: "pointer" }} size='xl' onClick={(e) => e.currentTarget.parentNode.querySelector("input").click()} data-id={index} icon={faImage} />
                                                            <input type="file" accept="image/*" onChange={this.onImageSet} data-section={index} data-position="image" hidden />
                                                        </div>
                                                        {tok["tok_group"] == "Mega" ?
                                                            <TextField className={'col'} onChange={this.SetSectionValues} defaultValue={section.title ? section.title : ""} value={section.title} name={`title-${index}`} label={"Title/Main Text"} inputProps={{ maxLength: 200 }} />
                                                            : ""}
                                                    </Row>
                                                    {tok["tok_group"] == "Mega" ?
                                                        <p className={'character-count'}>{section.title ? section.title.length : "0"}/600</p>
                                                        : ""}
                                                    {index != 0 ?
                                                        <div className={'item-close'}>
                                                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'red'} size={'lg'} icon={faTimes} data-id={index} onClick={this.RemoveSection} />
                                                        </div> : ""
                                                    }
                                                </Col>
                                                {section.show ?
                                                    <>
                                                        {section.image ?
                                                            <Col className={"col-12"}>
                                                                <img style={{ maxWidth: "100%" }} src={section.image} />
                                                                <div style={{ width: "100%", margin: "5px" }}>
                                                                    <Button onClick={this.removeImage} data-section={index} data-position="image" variant="primary">
                                                                        Remove Image
                                                                    </Button>
                                                                </div>
                                                            </Col> : ""
                                                        }
                                                        <Col className={"col-12"} style={{ marginTop: "15px" }}>
                                                            <TextField style={{ width: "100%" }} defaultValue={section.content ? section.content : ""} onChange={this.SetSectionValues} multiline minRows={3} name={`content-${index}`} label={"Content"} value={section.content} inputProps={{ maxLength: 150000 }} />
                                                            <p className={'character-count'}>{section.content ? section.content.length : "0"}/150000</p>
                                                        </Col>
                                                    </> : ""
                                                }
                                                {tok["tok_group"] == "Q&a" ?
                                                    <>
                                                        {section.question_answer.map((qna, qnaIndex) => (
                                                            <Row style={{ paddingRight: "unset" }}>
                                                                <Col className={"col-12"} style={{ paddingRight: "unset", width: "95%", marginLeft: "auto", position: "relative" }}>
                                                                    <TextField style={{ width: "100%" }} onChange={this.SetSectionValues} name={`question-${index}-${qnaIndex}`} label={`Question ${qnaIndex + 1}`} value={qna.question} inputProps={{ maxLength: 600 }} />
                                                                    <p className={'character-count'}>{qna.question ? qna.question.length : "0"}/600</p>
                                                                    {qnaIndex != 0 ?
                                                                        <div className={'item-close qna-close'}>
                                                                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'red'} size={'lg'} icon={faTimes} data-section={index} data-qna={qnaIndex} onClick={this.RemoveSectionQna} />
                                                                        </div> : ""}

                                                                </Col>
                                                                <Col className={"col-12"} style={{ paddingRight: "unset", width: "90%", marginLeft: "auto" }}>
                                                                    <TextField style={{ width: "100%" }} onChange={this.SetSectionValues} name={`answer-${index}-${qnaIndex}`} label={`Answer ${qnaIndex + 1}`} value={qna.answer} inputProps={{ maxLength: 600 }} />
                                                                    <p className={'character-count'}>{qna.question ? qna.question.length : "0"}/600</p>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        <Button variant="primary" onClick={this.AddQna} data-section={index} style={{ width: "max-content", marginLeft: "calc(var(--bs-gutter-x) * .5 + 10%)" }}>
                                                            + Add Question
                                                        </Button>
                                                    </>
                                                    : ""}
                                            </Row>
                                        ))}
                                        <Button variant="primary" onClick={this.AddSection} style={{ width: "100%" }}>
                                            + Add Section
                                        </Button>
                                    </div>
                                    : ""}
                                <Col xs={12}>
                                    <b style={{ cursor: "pointer", width: "100%", display: "block" }} onClick={this.SetShowOptionalFields}>
                                        <FontAwesomeIcon className={'caret-down-icon'} icon={faCaretDown} style={{ transform: showOptionalFields ? "" : "rotate(180deg)" }} />
                                        {showOptionalFields ? "Hide" : "Show"} Optional Fields
                                    </b>
                                </Col>
                                <Row className={"col-12"} style={{ margin: "unset", backgroundColor: "lightgray", borderRadius: "10px", padding: "10px", display: showOptionalFields ? "flex" : "none" }}>
                                    <Col xs={12} style={{ marginBottom: "10px" }}>
                                        <TextField className={"text-field-default-color"} defaultValue={defaultTok["source_notes"] ? defaultTok["source_notes"] : ""} style={{ width: "100%" }} name="source_notes" onChange={this.SetTokValues} label="Source Notes" />
                                    </Col>
                                    <Col>
                                        <TextField className={"text-field-default-color"} defaultValue={defaultTok["source_link"] ? defaultTok["source_link"] : ""} style={{ width: "100%" }} name="source_link" onChange={this.SetTokValues} label="Source Link" />
                                    </Col>
                                    <Col xs={'auto'} style={{ padding: "unset", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                        <FontAwesomeIcon icon={faPaperclip} size={'lg'} style={{ transform: "rotate(315deg)" }} />
                                        <b style={{ marginLeft: "5px", fontSize: "18px" }}>Attach a File</b>
                                    </Col>
                                </Row>
                                <Row className={"col-12"} style={{ display: showOptionalFields ? "flex" : "none" }}>
                                    <Col xs={12} style={{ fontWeight: "600" }}>Copy to?</Col>
                                    <Col xs={12} className={'copytok-menu-container'}>
                                        <Button
                                            onClick={GroupCopy && GroupCopy["id"] ? this.removeCopy : this.setTokCopy}
                                            className={GroupCopy && GroupCopy["id"] ? 'remove-copy-btn group-btn' : 'group-btn'}
                                            data-type="Group">
                                            <span className={'default'}>Group</span>
                                            {GroupCopy && GroupCopy["id"] ?
                                                <>
                                                    <span className={'remove-text'}>Remove</span>
                                                    <span style={{ marginLeft: "5px" }}>{GroupCopy["name"]}</span>
                                                </>
                                                : ""
                                            }

                                        </Button>
                                        <Button onClick={CopyTokLevel["level1"] ? this.removeCopy : this.setTokCopy} className={CopyTokLevel["level1"] ? 'remove-copy-btn' : ''} data-type="Channel">
                                            <span className={'default'}>Tok Channel</span>
                                            {CopyTokLevel["level1"] ?
                                                <>
                                                    <span className={'remove-text'}>Remove</span>
                                                    <span style={{ marginLeft: "5px" }}>
                                                        {CopyTokLevel["level1"] ? capitalizeFirstLetter(textDisplay(CopyTokLevel["level1"])) : ""}
                                                        {CopyTokLevel["level2"] ? " - " + capitalizeFirstLetter(textDisplay(CopyTokLevel["level2"])) : ""}
                                                        {CopyTokLevel["level3"] ? " - " + capitalizeFirstLetter(textDisplay(CopyTokLevel["level3"])) : ""}
                                                    </span>
                                                </>
                                                : ""
                                            }
                                        </Button>
                                        <Button onClick={CommunityCopy["id"] ? this.removeCopy : this.setTokCopy} className={CommunityCopy["id"] ? 'remove-copy-btn' : ''} variant={'success'} data-type="Community">
                                            <span className={'default'}>Community</span>
                                            {CommunityCopy["id"] ?
                                                <>
                                                    <span className={'remove-text'}>Remove</span>
                                                    <span style={{ marginLeft: "5px" }}>{CommunityCopy["name"]}</span>
                                                </>
                                                : ""
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Row>
                            : ""}

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default AddClassTok;