import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TokChannelIcon from './TokChannelIcon'
import './css/TokChannelNavbar.css';
import { useState, useEffect } from 'react';
import arrayShuffle from 'array-shuffle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteGroup, getGroups } from '../services/classServices';
import { randomString, textDisplay, textDisplayReverse, upperCasesFirstLetter } from '../services/helperTools';
import { cloneDeep } from 'lodash';
import { Button } from 'react-bootstrap';
import { Co2Sharp } from '@mui/icons-material';
import { useLocalStorage } from '../firebase/useLocalStorage';
import toast, { Toaster } from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
const drawerWidth = 280;
const colors = [
    "#d32f2f", "#C2185B", "#7B1FA2", "#512DA8",
    "#303F9F", "#1976D2", "#0288D1", "#0097A7",
    "#00796B", "#388E3C", "#689F38", "#AFB42B",
    "#FBC02D", "#FFA000", "#F57C00", "#E64A19"
];
const shuffleColors = arrayShuffle(colors);
var colorCounter = -1;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps {
    open: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface DrawerProps {
    open: boolean;
}
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<DrawerProps>
(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': open ? openedMixin(theme) : closedMixin(theme),
    }),
);
const TokChannelNavbar = (props) => {
    const [path, setPath] = useState("");
    let tokchannelNavbarRef = props.tokchannelNavbarRef;
    let tokchannelFavoriteNavbarRef = props.tokchannelFavoriteNavbarRef;
    let loginCallTrigger = props.loginCallTrigger;
    function renderTokChannelPage(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        var btn = event.currentTarget;
        let level1 = btn.getAttribute("data-level0") ? btn.getAttribute("data-level0") : "";
        let level2 = btn.getAttribute("data-level1") ? btn.getAttribute("data-level1") : "";
        let level3 = btn.getAttribute("data-level2") ? btn.getAttribute("data-level2") : "";
        let name = btn.getAttribute("data-name") ? btn.getAttribute("data-name") : "";

        //Active State
        setPath(btn.getAttribute("href") ? btn.getAttribute("href") : path);
        //Active State - End
        //Add To Recents
        const tempRecent = {
            level0: level1,
            level1: level2,
            level2: level3
        }
        let newRecent = cloneDeep(recently);
        tempRecent["name"] = upperCasesFirstLetter(name ? name : level3 ? level3 : level2 ? level2 : level1 ? level1 : "");
        tempRecent["href"] = btn.getAttribute("href");
        if (newRecent.length > 0) {
            newRecent.unshift(tempRecent);
        } else {
            newRecent = new Array;
            newRecent.push(tempRecent);
        }
        let counter = 0;
        newRecent.forEach(level => {
            level["nav_color"] = list[counter];
            counter++;
            if (counter == 16) {
                counter = 0;
            }
        });
        setRecently(newRecent);
        //Add To Recents - End
        props.tokchannelData(tempRecent);
    }
    const [mainChannelShow, setMainChannelShow] = useState(true);
    const [level1List, setLevel1List] = useState(new Array);
    function changeMainChannelShow(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setMainChannelShow(!mainChannelShow);
    }
    const [firebaseUser, setFirebaseUser] = useLocalStorage("authUser", []);
    const [myLearningShow, setMyLearningShow] = useState(true);
    function changeMyLearningShow(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setMyLearningShow(!myLearningShow);
    }

    const [favoritesShow, setFavoritesShow] = useState(true);
    function changeFavoritesShow(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setFavoritesShow(!favoritesShow);
    }

    const [ffChannelsShow, setFfChannelsShow] = useState(true);
    function changeFfChannelsShow(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setFfChannelsShow(!ffChannelsShow);
    }

    const [myChannelsShow, setMyChannelsShow] = useState(false);
    const [myChannelsInitialize, setMyChannelsInitialize] = useState(false);
    function changeMyChannelsShow(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        if (!myChannelsInitialize) {
            fetchChannels("channel", "mychannel");
            setMyChannelsInitialize(true);
        }
        setMyChannelsShow(!myChannelsShow);
    }

    const [recentlyViewedShow, setRecentlyViewedShow] = useState(false);
    const [recentlyViewedInitialize, setRecentlyViewedInitialize] = useState(false);
    function changeRecentlyViewedShow(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        if (!recentlyViewedInitialize) {
            fetchChannels("recentchannel");
            setRecentlyViewedInitialize(true);
        }
        setRecentlyViewedShow(!recentlyViewedShow);
    }

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [list, setList] = useState(shuffleColors);

    const handleDrawerOpen = () => {
        setOpen(true);
        props.sendData(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        props.sendData(false);
    };
    const [marginTopNav, setMarginTopNav] = useState(85);
    //update to user's data
    const [favorites, setFavorites] = useState([]);
    //update to user's data
    const [recently, setRecently] = useState([]);
    const [myChannels, setMyChannels] = useState([]);
    const [ffChannels, setFfChannels] = useState([]);
    const [myLearn, setmyLearn] = useState([]);
    function addNewRecent(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        let level1 = event.target.getAttribute("data-level1");
        let level2 = event.target.getAttribute("data-level2");
        let level3 = event.target.getAttribute("data-level3");
        let name = event.target.getAttribute("data-name");
        let tempRecent = {
            level0: level1,
            level1: level2,
            level2: level3,
            name: name
        }
        let newRecent = cloneDeep(recently);
        if (level2) {
            tempRecent["href"] = tempRecent["href"] + "/" + level2;
            if (level3) {
                tempRecent["href"] = tempRecent["href"] + "/" + level3;
            }
        }
        if (newRecent.length > 0) {
            newRecent.unshift(tempRecent);
        } else {
            newRecent = new Array;
            newRecent.push(tempRecent);
        }
        let counter = 0;
        newRecent.forEach(level => {
            level["nav_color"] = list[counter];
            counter++;
            if (counter == 16) {
                counter = 0;
            }
        });
        setRecently(newRecent);
    }
    function addNewFavorite(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        let level1 = event.target.getAttribute("data-level1");
        let level2 = event.target.getAttribute("data-level2");
        let level3 = event.target.getAttribute("data-level3");
        let name = event.target.getAttribute("data-name");
        let remove = event.target.getAttribute("data-remove") == "true";
        let newFavorite = cloneDeep(favorites);
        if (remove) {
            newFavorite = newFavorite.filter(item => !(item["level0"] == (level1 ? level1 : "") && item["level1"] == (level2 ? level2 : "") && item["level2"] == (level3 ? level3 : "")))
        } else {
            let tempFavorite = {
                level0: level1,
                level1: level2,
                level2: level3,
                name: name
            }
            if (level2) {
                tempFavorite["href"] = tempFavorite["href"] + "/" + level2;
                if (level3) {
                    tempFavorite["href"] = tempFavorite["href"] + "/" + level3;
                }
            }
            if (newFavorite.length > 0) {
                newFavorite.unshift(tempFavorite);
            } else {
                newFavorite = new Array;
                newFavorite.push(tempFavorite);
            }
        }
        newFavorite = newFavorite.sort((x, y) => x["name"].localeCompare(y["name"]));
        let counter = 0;
        newFavorite.forEach(level => {
            level["nav_color"] = list[counter];
            counter++;
            if (counter == 16) {
                counter = 0;
            }
        });
        setFavorites(newFavorite);
    }

    async function fetchChannels(item = "", type = "") {
        var query = {
            label: item,
            items: item + "s"
        }
        if (type) {
            switch (type) {
                case "mychannel":
                    query["user_id"] = firebaseUser["uid"] ? firebaseUser["uid"] : "";
                    break;
            }
        }
        var result = await getGroups(query, "");
        if(result){
            if (result.data["status_code"] == 200 && result.data["result"]) {
                let recents = result.data["result"];
                let counter = 0;
                recents.forEach(level => {
                    level["nav_color"] = list[counter];
                    counter++;
                    if (counter == 16) {
                        counter = 0;
                    }
                });
                if (item == "recentchannel" && recents.length > 0) {
                    setRecently(recents);
                } else if (item == "favoritechannel" && recents.length > 0) {
                    props.setFavorites(result.data["result"]);
    
                    recents = recents.sort((x, y) => x["name"].localeCompare(y["name"]))
                    setFavorites(recents);
                } else if (type == "mychannel" && recents.length > 0) {
                    props.setFavorites(result.data["result"]);
    
                    recents = recents.sort((x, y) => x["name"].localeCompare(y["name"]))
                    setMyChannels(recents);
                }
            }
        }
    }

    useEffect(() => {
        if (!(firebaseUser === null || firebaseUser.length == 0)) {
            fetchChannels("favoritechannel");
        }
        if (props.level1list.length > 0) {

            let tempLevel1List = props.level1list;
            let counter = 0;
            tempLevel1List.forEach(level => {
                level["nav_color"] = list[counter];
                counter++;
                if (counter == 16) {
                    counter = 0;
                }
            });
            setLevel1List(tempLevel1List);
        }
        let pathParts = window.location.pathname.split("/");
        var currentPath = "/" + pathParts[1];
        currentPath = pathParts[2] ? currentPath + "/" + pathParts[2] : currentPath;
        setPath(currentPath);
    }, []);
    {/*
    

    const listenToScroll = () => {
        let heightToHideFrom = 85;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if ((heightToHideFrom - winScroll) >= 0) {
            setMarginTopNav(heightToHideFrom - winScroll);
        } else {
            setMarginTopNav(0)
        }
    };
    */}
    var toastMsg = null;
    function clearAllRecentsInitialize(event) {
        var msg = toast.loading('Deleting Recents');
        toastMsg = msg;
        clearAllRecents(recently, 0);
    }
    function openAddChannel(event) {
        props.openAddChannel({ level: 1 });
    }

    function clearAllRecents(list, count = 0) {
        if (list[count]["id"]) {
            deleteGroup(list[count]["id"], list[count]["pk"]).then(res => {
                count++;
                if (list.length > count) {
                    clearAllRecents(list, count);
                } else {
                    toast.success('Recent Cleared', {
                        id: toastMsg,
                    });
                    setRecently([]);
                }
            }).catch(err => {
                toast.error('An Error Occured', {
                    id: toastMsg,
                });
            }).finally(() => {

            });
        } else {
            count++;
            if (list.length > count) {
                clearAllRecents(list, count);
            } else {
                toast.success('Recent Cleared', {
                    id: toastMsg,
                });
                setRecently([]);
            }
        }
    }
    {/* 
    function loginCall(e) {
        if (firebaseUser === null || firebaseUser.length == 0) {
            e.preventDefault()
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            loginCallTrigger.current.click();
        }
    }
    */}
    return (
        <Box className={"text-color-white dark-background-1"} sx={{ display: 'flex' }} >
            <CssBaseline />
            <Button hidden ref={tokchannelNavbarRef} onClick={addNewRecent} />
            <Button hidden ref={tokchannelFavoriteNavbarRef} onClick={addNewFavorite} />
            <Drawer variant="permanent" open={open} className={"text-color-white dark-background-1"}>
                <List className={"tokchannel-navbar dark-background-1 text-color-white"} style={{ marginTop: `${marginTopNav}px` }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                        style={{ width: "100%", margin: "auto", borderRadius: "unset" }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <Divider style={{ height: "50px" }} sx={{ display: open ? 'block' : 'none' }} />
                    <IconButton onClick={handleDrawerClose} style={{ position: "absolute", right: 0, top: 0, borderRadius: "unset" }} sx={{ display: open ? 'block' : 'none' }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    {/* My Learning 
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            style={{ borderTop: "2.5px solid" }}
                            onClick={changeMyLearningShow}
                        >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                <span style={{ fontWeight: "600" }}>My Learning</span>
                            </ListItemText>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    marginRight: "right"
                                }}
                            >
                                <FontAwesomeIcon icon={myLearningShow ? faCaretDown : faCaretUp} />
                            </ListItemIcon>

                        </ListItemButton>
                    </ListItem>
                    {myLearning.map((tokchannel, index) => (
                        <ListItem key={textDisplay(tokchannel["level2"]? tokchannel["level2"]: tokchannel["level1"]? tokchannel["level1"]: tokchannel["level0"]? tokchannel["level0"]: "") + randomString(12)} hidden={!myLearningShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"]? "/" + tokchannel["level1"] + (tokchannel["level2"]?"/" + tokchannel["level2"]: ""): "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                onClickCapture={renderTokChannelPage} data-ogfill={tokchannel["nav_color"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"]? "/" + tokchannel["level1"] + (tokchannel["level2"]?"/" + tokchannel["level2"]: ""): ""))}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"]? "/" + tokchannel["level1"] + (tokchannel["level2"]?"/" + tokchannel["level2"]: ""): "")) == path ? "active" : ""} />
                                </ListItemIcon>
                                <ListItemText primary={textDisplay(tokchannel["level2"]? tokchannel["level2"]: tokchannel["level1"]? tokchannel["level1"]: tokchannel["level0"]? tokchannel["level0"]: "")} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}*/}
                    {/* Main Channels */}
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            style={{ borderTop: "2.5px solid" }}
                            onClick={changeMainChannelShow}
                        >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                <span style={{ fontWeight: "600" }}>Main Channels</span>
                            </ListItemText>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    marginRight: "right"
                                }}
                            >
                                <FontAwesomeIcon icon={mainChannelShow ? faCaretDown : faCaretUp} />
                            </ListItemIcon>

                        </ListItemButton>
                    </ListItem>
                    <ListItem hidden={!mainChannelShow} disablePadding sx={{ display: 'block' }} className={"tokchannel-nav-btn"}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            style={{ border: "2.5px solid" }} onClick={openAddChannel}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ borderRadius: "100%", width: "30px", height: "30px", display: "flex", alignContent: "center", justifyContent: "center", border: "3px solid", borderColor: "gray" }}>
                                    <FontAwesomeIcon icon={faPlus} size='xl' color={"gray"} />
                                </div>
                            </ListItemIcon>
                            <ListItemText primary={"Add Level 1 Channel"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    {level1List.map((tokchannel, index) => (
                        <ListItem key={textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "") + randomString(12)} hidden={!mainChannelShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                onClickCapture={renderTokChannelPage} data-level0={tokchannel["level0"]} data-level1={tokchannel["level1"]} data-level2={tokchannel["level2"]} data-name={tokchannel["name"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : ""))}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "active" : ""} />
                                </ListItemIcon>
                                <ListItemText primary={tokchannel["name"] ? tokchannel["name"] : textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "")} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {/* Recently Viewed last 5 */}
                    {!(firebaseUser === null || firebaseUser.length == 0) ?
                        <>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    style={{ borderTop: "2.5px solid" }}
                                    onClick={changeRecentlyViewedShow}
                                >
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <span style={{ fontWeight: "600" }}>Recently Viewed</span>
                                    </ListItemText>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            marginRight: "right"
                                        }}
                                    >
                                        <FontAwesomeIcon icon={recentlyViewedShow ? faCaretDown : faCaretUp} />
                                    </ListItemIcon>

                                </ListItemButton>
                            </ListItem>
                            {recently ? <>
                                <ListItem hidden={!recentlyViewedShow} disablePadding sx={{ display: 'block' }} className={"tokchannel-nav-btn"}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        style={{ border: "2.5px solid" }} onClick={clearAllRecentsInitialize}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} width={30} size='xl' />
                                        </ListItemIcon>
                                        <ListItemText primary={"Clear All"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                                {recently.map((tokchannel, index) => (
                                    <ListItem key={textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "") + randomString(12)} hidden={!recentlyViewedShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                            onClickCapture={renderTokChannelPage} data-level0={tokchannel["level0"]} data-level1={tokchannel["level1"]} data-level2={tokchannel["level2"]} data-name={tokchannel["name"]} data-ogfill={tokchannel["nav_color"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : ""))}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "active" : ""} />
                                            </ListItemIcon>
                                            <ListItemText primary={tokchannel["name"] ? tokchannel["name"] : textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "")} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </> : ""}
                        </> : ""
                    }
                    {/* My Learning*/}
                    {!(firebaseUser === null || firebaseUser.length == 0) ?
                        <>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    style={{ borderTop: "2.5px solid" }}
                                    onClick={changeMyLearningShow}
                                >
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <span style={{ fontWeight: "600" }}>My Learning</span>
                                    </ListItemText>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            marginRight: "right"
                                        }}
                                    >
                                        <FontAwesomeIcon icon={myLearningShow ? faCaretDown : faCaretUp} />
                                    </ListItemIcon>

                                </ListItemButton>
                            </ListItem>
                            <ListItem hidden={!myLearningShow} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    style={{ border: "2.5px solid" }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <TokChannelIcon />
                                    </ListItemIcon>
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <NavLink color="inherit" to="/classsets" style={{ textDecoration: "none", color: "black" }}>
                                            Class Sets
                                        </NavLink>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            {myLearn ? <>
                                {myLearn.map((tokchannel, index) => (
                                    <ListItem key={textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "") + randomString(12)} hidden={!myLearningShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                            onClickCapture={renderTokChannelPage} data-level0={tokchannel["level0"]} data-level1={tokchannel["level1"]} data-level2={tokchannel["level2"]} data-name={tokchannel["name"]} data-ogfill={tokchannel["nav_color"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : ""))}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "active" : ""} />
                                            </ListItemIcon>
                                            <ListItemText primary={tokchannel["name"] ? tokchannel["name"] : textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "")} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </> : ""}
                        </> : ""
                    }
                    {/* Favorites */}
                    {!(firebaseUser === null || firebaseUser.length == 0) ?
                        <>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    style={{ borderTop: "2.5px solid" }}
                                    onClick={changeFavoritesShow}
                                >
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <span style={{ fontWeight: "600" }}>Favorites</span>
                                    </ListItemText>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            marginRight: "right"
                                        }}
                                    >
                                        <FontAwesomeIcon icon={favoritesShow ? faCaretDown : faCaretUp} />
                                    </ListItemIcon>

                                </ListItemButton>
                            </ListItem>
                            {favorites ? favorites.map((tokchannel, index) => (
                                <ListItem key={textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "") + randomString(12)} hidden={!favoritesShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                        onClickCapture={renderTokChannelPage} data-level0={tokchannel["level0"]} data-level1={tokchannel["level1"]} data-level2={tokchannel["level2"]} data-name={tokchannel["name"]} data-ogfill={tokchannel["nav_color"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : ""))}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "active" : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={tokchannel["name"] ? tokchannel["name"] : textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "")} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )) : ""}
                        </> : ""
                    }

                    {/* Family and Friends */}
                    {!(firebaseUser === null || firebaseUser.length == 0) ?
                        <>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    style={{ borderTop: "2.5px solid" }}
                                    onClick={changeFfChannelsShow}
                                >
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <span style={{ fontWeight: "600" }}>Family & Friends</span>
                                    </ListItemText>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            marginRight: "right"
                                        }}
                                    >
                                        <FontAwesomeIcon icon={ffChannelsShow ? faCaretDown : faCaretUp} />
                                    </ListItemIcon>

                                </ListItemButton>
                            </ListItem>
                            {ffChannels ? ffChannels.map((tokchannel, index) => (
                                <ListItem key={textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "") + randomString(12)} hidden={!ffChannelsShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                        onClickCapture={renderTokChannelPage} data-level0={tokchannel["level0"]} data-level1={tokchannel["level1"]} data-level2={tokchannel["level2"]} data-name={tokchannel["name"]} data-ogfill={tokchannel["nav_color"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : ""))}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "active" : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={tokchannel["name"] ? tokchannel["name"] : textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "")} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )) : ""}
                        </> : ""
                    }

                    {/* My Channels */}
                    {!(firebaseUser === null || firebaseUser.length == 0) ?
                        <>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    style={{ borderTop: "2.5px solid" }}
                                    onClick={changeMyChannelsShow}
                                >
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <span style={{ fontWeight: "600" }}>My Channels</span>
                                    </ListItemText>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            marginRight: "right"
                                        }}
                                    >
                                        <FontAwesomeIcon icon={myChannelsShow ? faCaretDown : faCaretUp} />
                                    </ListItemIcon>

                                </ListItemButton>
                            </ListItem>
                            {myChannels ? myChannels.map((tokchannel, index) => (
                                <ListItem key={textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "") + randomString(12)} hidden={!myChannelsShow} disablePadding sx={{ display: 'block' }} className={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "tokchannel-nav-btn active" : "tokchannel-nav-btn"}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        style={{ border: "2.5px solid", borderColor: tokchannel["nav_color"] }}
                                        onClickCapture={renderTokChannelPage} data-level0={tokchannel["level0"]} data-level1={tokchannel["level1"]} data-level2={tokchannel["level2"]} data-name={tokchannel["name"]} data-ogfill={tokchannel["nav_color"]} href={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : ""))}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <TokChannelIcon icon={tokchannel.level1} color={tokchannel["nav_color"]} active={("/tokchannels/" + tokchannel["level0"] + (tokchannel["level1"] ? "/" + tokchannel["level1"] + (tokchannel["level2"] ? "/" + tokchannel["level2"] : "") : "")) == path ? "active" : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={tokchannel["name"] ? tokchannel["name"] : textDisplay(tokchannel["level2"] ? tokchannel["level2"] : tokchannel["level1"] ? tokchannel["level1"] : tokchannel["level0"] ? tokchannel["level0"] : "")} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            )) : ""}
                        </> : ""
                    }
                </List>

            </Drawer>
            <div className={"exception"}>
                <Toaster />
            </div>
        </Box>
        
    );
}

export default TokChannelNavbar