import React, { useState, useEffect } from 'react';
import { useLocalStorage } from '../firebase/useLocalStorage';

const IsAuthenticated = (props) => {
    const [firebaseUser, setFirebaseUser] = useLocalStorage("authUser", []);
    const [authenticated, setAuthenticated] = useState(() => {
        if (firebaseUser === null || firebaseUser.length == 0) return false;
        return true;
    });

    useEffect(() => {
        props.isAuthenticated(authenticated);
    }, [authenticated]);

    return null;
};

export default IsAuthenticated;