import ReactDOM from 'react-dom/client';
import { updatePassword } from "firebase/auth";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Navbar from '../components/Navbar';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Footer from '../components/Footer';
import Typography from "@mui/material/Typography";
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import React from 'react';
const ProfileChangePassword = (props) => {
    const navigate = useNavigate();
    //const [previousPassword, setpreviousPassword] = useState(''); // Not included since firebase will validate itself
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPreviousPassword, setShowPreviousPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [isShowChangePassword, SetIsShowChangePassword] = useState(false);
    const changePasswordTrigger = props.changePasswordTrigger;

    const onChangePassword = (e) => {
        e.preventDefault();

        if (onValidatePassword) {
            const user = auth.currentUser;
            updatePassword(user, newPassword).then(() => {
                // Update successful.
                navigate('/', { replace: true });
            }).catch((error) => {
                // An error ocurred
                // ...
                console.log(error);
            });
        }
    }

    const onValidatePassword = () => {
        if (newPassword != confirmNewPassword) return false; // Not the same password

        return true; // All Password validated
    }

    //const handleClickShowPreviousPassword = () => setShowPreviousPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Button onClick={() => SetIsShowChangePassword(true)} ref={changePasswordTrigger} hidden>Change Password Call</Button>
            <Modal show={isShowChangePassword} style={{ zIndex: "1299" }}>
                <Modal.Header>
                    <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => SetIsShowChangePassword(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {/* <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'previousPassword'} type={showPreviousPassword ? 'text' : 'password'} style={{ width: "100%" }} value={previousPassword} onChange={(e) => setPreviousPassword(e.target.value)} label="Old Password"
                                InputProps={{
                                    endAdornment: <InputAdornment >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPreviousPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPreviousPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Col> */}
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'newPassword'} type={showNewPassword ? 'text' : 'password'} style={{ width: "100%" }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} label="New Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"> {/* Add position prop here */}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'confirmNewPassword'} type={showConfirmNewPassword ? 'text' : 'password'} style={{ width: "100%" }} value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} label="Confirm New Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"> {/* Add position prop here */}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <Button variant={'contained'} color={"primary"} style={{ width: "100%" }} onClick={onChangePassword}>Change Password</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProfileChangePassword