import React from 'react'
import '../styles.css'
import './css/ImageSelection.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { getImages, getAvatars, getBadges, getToken } from '../services/accountServices';
import { Row, Col } from 'react-bootstrap';
import arrayShuffle from 'array-shuffle';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createRef } from 'react';
import { Tok } from '../interfaces/TokInterface';
const MySwal = withReactContent(Swal);

interface States {
    items: Tok[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean
    SelectedImage: string;
    resultData: { [key: string]: any };
}

interface Props {
    setImage: (data: string) => void;
    query: { [key: string]: any };
}

class ImageSelection extends React.Component<Props, States> {

    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            SelectedImage: "",
            resultData: {}
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.setImage = this.setImage.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    async getData(customQuery) {
        var { query, items, continuationToken } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
            if (!query["pagination"]) {
                this.setState({
                    items: []
                });
            }
        }
        var response = null;
        var itemResult = new Array;
        if (query["kind"] == "avatars") {
            response = await getAvatars();
            itemResult = response.data["results"]
        } else if (query["kind"] == "badges") {
            response = await getBadges(query);
            itemResult = response.data["results"]
        } else {
            response = await getImages(query);
            itemResult = response.data["results"]
        }
        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            resultData: response.data,
            continuationToken: response.data.continuationToken,
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
    }
    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    loadmore() {
        this.setState({
            LoadMoreActive: true
        });
        var { query, continuationToken } = this.state;
        if (continuationToken) {
            if (query) {
                query.pagination = continuationToken;
            } else {
                query = { "pagination": continuationToken }
            }

            this.getData(query)
        }
    }
    setImage(e) {
        var { items } = this.state;
        const id = e.currentTarget.getAttribute("data-id");
        for (let x = 0; x < items.length; x++) {
            if (items[x].id == id) {
                try {
                    this.props.setImage(items[x].image);
                } catch (err) {
                }
                this.setState({
                    SelectedImage: id
                });
                break;
            }
        }
    }
    render() {
        var { LoadActive, items, LoadMoreActive, continuationToken, SelectedImage } = this.state;
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <div>
                {LoadActive ?
                    <WaitSpinner /> :
                    <Row style={{ margin: "unset" }}>
                        {items ?
                            <>
                                {items.map(image => {
                                    return (
                                        <Col onClick={this.setImage} data-id={image["id"]} className={SelectedImage == image["id"] ? 'image-option-item active' : 'image-option-item'} lg={2} md={3} sm={4} xs={5} style={{ backgroundImage: image ? `url(${image["image"]})` : "" }}>
                                            {image["name"] ? <div className={'name'}>{image["name"]}</div> : ""}
                                        </Col>
                                    )
                                })}
                                {LoadMoreActive ? <WaitSpinner /> : ""}
                            </> : ""
                        }

                    </Row>
                }
            </div>
        );
    }
}

export default ImageSelection;