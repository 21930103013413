import React, { createRef } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import TokChannelBody from '../components/TokChannelBody';
import Login from '../firebase/Login';
import Signup from '../firebase/Signup';
import ProfileChangePassword from '../firebase/ProfileChangePassword';
const TokChannel = (props) => {
  var { level1, level2, level3, tokid, tokpk } = useParams();
  var group = props.group;
  const path = window.location.pathname;
  const loginCallTrigger = createRef();
  const signupTrigger = createRef();
  const changePasswordTrigger = createRef();
  
  if(path == "/bookmarks") {
    level1 = "bookmarks";
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar loginCallTrigger={loginCallTrigger} changePasswordTrigger={changePasswordTrigger} />
      <Login loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} />
      <Signup loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} />
      <ProfileChangePassword changePasswordTrigger={changePasswordTrigger} />
      <TokChannelBody loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} key={level1 + level2 + level3} level1={level1} level2={level2} level3={level3} tokid={tokid} tokpk={tokpk} group={group} classchannel={undefined}/>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>

  )
}

export default TokChannel;