import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './../App.css';
import Navbar from '../components/Navbar';
import ClassTokNavbar from '../components/ClassTokNavbar';
import Footer from '../components/Footer';
import TokTileContainer from '../components/TokTileContainer';
import { useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import GroupsContainer from '../components/GroupsContainer';
import TokHandleContainer from '../components/TokHandleContainer';
import ClassSetsContainer from '../components/ClassSetsContainer';
import { useParams } from 'react-router-dom';
import SetInfoView from '../components/SetInfoView';
import TokInfoView from '../components/TokInfoView';
import GroupInfoView from '../components/GroupInfoView';
import { useNavigate } from 'react-router-dom'
import TokChannelBody from '../components/TokChannelBody';
import { Button } from 'react-bootstrap';
import { createRef } from 'react';
import Login from '../firebase/Login';
import Signup from '../firebase/Signup';
import ProfileChangePassword from '../firebase/ProfileChangePassword';

const ClassToks = (props) => {
  const navigate = useNavigate();
  const mainContainer = useRef();
  const mainBody = useRef();
  const groupJoinedTrigger = useRef();
  const loginCallTrigger = useRef();
  const signupTrigger = useRef();
  const changePasswordTrigger = useRef();
  const { id } = useParams();
  const { pk } = useParams();
  const { level1, level2 } = useParams();
  const group = props.group;
  const sendData = (data) => {
    //swap to ref dom styling instead of, useState was causing a white screen error under specific conditions
    if (data) {
      (mainBody.current as HTMLElement).style.marginLeft = "270px";
    } else {
      (mainBody.current as HTMLElement).style.marginLeft = "75px";
    }
  }
  const classSetPageNavigate = (e) => {
    navigate('/classsets', { replace: true });
  }
  const setNavigationData = (data) => {
    const rootMain = createRoot(mainContainer.current);
    try {
      if (data["href"] && data["state"]) {
        {/*
        Uncomment to enable public tok and remove the if below
        if (data.href == "/classtoks" && data.state == 1) {
          rootMain.render(<TokTileContainer title={"Public Toks"} fontColor={"white"} bgColor={"#512DA8"} query={{ publicfeed: true }}/>);
        } else if (data.href == "/classtoks" && data.state == 2) {
          rootMain.render(<TokTileContainer title={"My Toks"} fontColor={"white"} bgColor={"#112379"} query={{ getusertoks: true }} />);
        }
        */}
        if (data.href == "/classtoks") {
          rootMain.render(<TokTileContainer title={"My Toks"} fontColor={"white"} bgColor={"#112379"} query={{ getusertoks: true }} DisableNavbar={undefined} CardViewOption={undefined} proxyTitleBarActive={undefined} levelTitle={undefined} CommunityId={undefined} CommunityName={undefined} groupName={undefined} itemBase={undefined} tokTileTitleBar={undefined} items={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={undefined} saveTokData={undefined} />);
        }
        if (data.href == "/classgroups") {
          const localUser = JSON.parse(localStorage.getItem("userdata"));
          var localId = "";
          if (localUser) {
            if (localUser.id) {
              localId = localUser.id;
            }
          }
          rootMain.render(<GroupsContainer groupJoinedTrigger={groupJoinedTrigger} EnableTitle={true} Navbar={true} MenuKind={true} CustomTitle={undefined} disableUserId={undefined} CommunityId={undefined} query={undefined} displayWarning={undefined} />);
        }
        if (data.href == "/tokhandles") {
          rootMain.render(<TokHandleContainer query={undefined} />);
        }
        if (data.href.startsWith("/tokchannels/academic")) {
          let levels = data.href.split("/");
          let classLevel1 = "";
          let classLevel2 = "";

          if (levels[2]) {
            classLevel1 = levels[2];

            if (levels[3]) {
              classLevel2 = levels[3];
            }
          }

          if (classLevel1) {
            navigate('/tokchannels/academic', { replace: true });
          }
        }
        if (data["substate"]) {
          if (data.href == "/classsets" && data.state == 1 && data.substate == 1) {
            rootMain.render(<ClassSetsContainer Navbar={true} MenuKind={true} PublicFeed={true} studyGameMode={undefined} studyGameModeRef={undefined} customTitleEnabled={undefined} customTitle={undefined} query={undefined} groupid={undefined} HideNavbar={undefined} communityid={undefined} level1={undefined} level2={undefined} level3={undefined} addClassSetRef={undefined} addExistingClassSetTransferRef={undefined} />);
          } else if (data.href == "/classsets" && data.substate == 2) {
            rootMain.render(<ClassSetsContainer Navbar={true} MenuKind={true} PublicFeed={undefined} studyGameMode={undefined} studyGameModeRef={undefined} customTitleEnabled={undefined} customTitle={undefined} query={undefined} groupid={undefined} HideNavbar={undefined} communityid={undefined} level1={undefined} level2={undefined} level3={undefined} addClassSetRef={undefined} addExistingClassSetTransferRef={undefined} />);
          }
        }
        if (window.location.pathname != data.href) {
          window.history.pushState({}, "Tokket", data.href);
        }
      }
    } catch (err) { }
  }
  var displayBody: JSX.Element;
  if (window.location.pathname.startsWith("/classtoks/tok/")) {
    displayBody = <TokInfoView id={id} pk={pk} />
  } else if (window.location.pathname.startsWith("/classtoks/set/")) {
    displayBody = <SetInfoView id={id} pk={pk} removeSet={classSetPageNavigate} />
  }
  else if (window.location.pathname.startsWith("/classtoks")) {
    //displayBody = <TokTileContainer title={"Public Toks"} query={{ publicfeed: true }} fontColor={"white"} bgColor={"#512DA8"} />
    displayBody = <TokTileContainer title={"My Toks"} fontColor={"white"} bgColor={"#112379"} query={{ getusertoks: true }} DisableNavbar={undefined} CardViewOption={undefined} proxyTitleBarActive={undefined} levelTitle={undefined} CommunityId={undefined} CommunityName={undefined} groupName={undefined} itemBase={undefined} tokTileTitleBar={undefined} items={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={undefined} saveTokData={undefined} />
  } else if (window.location.pathname.startsWith("/classgroups")) {
    try {
      const localUser = JSON.parse(localStorage.getItem("userdata"));
      displayBody = <GroupsContainer groupJoinedTrigger={groupJoinedTrigger} EnableTitle={true} Navbar={true} MenuKind={true} CustomTitle={undefined} disableUserId={undefined} CommunityId={undefined} query={undefined} displayWarning={undefined} />
    } catch (err) { }
  } else if (window.location.pathname.startsWith("/tokhandles")) {
    displayBody = <TokHandleContainer query={undefined} />
  } else if (window.location.pathname.startsWith("/classsets")) {
    try {
      const localUser = JSON.parse(localStorage.getItem("userdata"));
      displayBody = <ClassSetsContainer Navbar={true} MenuKind={true} PublicFeed={true} studyGameMode={undefined} studyGameModeRef={undefined} customTitleEnabled={undefined} customTitle={undefined} query={undefined} groupid={undefined} HideNavbar={undefined} communityid={undefined} level1={undefined} level2={undefined} level3={undefined} addClassSetRef={undefined} addExistingClassSetTransferRef={undefined} />
    } catch (err) { }
  } else if (window.location.pathname.startsWith("/group/")) {
    displayBody = <GroupInfoView id={id} pk={pk} group={group} />
  } else if (window.location.pathname.startsWith("/classchannels")) {
    let levels = window.location.pathname.split("/");
    let classLevel1 = "";
    let classLevel2 = "";

    if (levels[2]) {
      classLevel1 = levels[2];

      if (levels[3]) {
        classLevel2 = levels[3];
      }
    }

    if (classLevel1) {
      displayBody = <TokChannelBody classchannel={true} key={classLevel1 + classLevel2} level1={classLevel1} level2={classLevel2} level3={undefined} tokid={undefined} tokpk={undefined} group={undefined} loginCallTrigger={undefined} signupTrigger={undefined} />;
    }
  }
  const groupJoinedSet = (e) => {
    try {
      let groupString = e ? JSON.stringify(e) : {};
      (groupJoinedTrigger.current as HTMLElement).setAttribute("data-group", groupString as string);
    } catch (err) { }

    (groupJoinedTrigger.current as HTMLElement).click();
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar groupJoinedSet={groupJoinedSet} loginCallTrigger={loginCallTrigger} changePasswordTrigger={changePasswordTrigger} />
      <Login loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} />
      <Signup loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} />
      <ProfileChangePassword changePasswordTrigger={changePasswordTrigger} />
      <ClassTokNavbar sendData={sendData} setNavigationData={setNavigationData} />
      <main ref={mainBody} style={{ marginLeft: '270px' }}>
        {/* Hero unit */}
        <Button onClick={groupJoinedSet} hidden></Button>
        <Container sx={{
          paddingTop: (theme) => theme.spacing(8),
          paddingBottom: (theme) => theme.spacing(8)
        }} maxWidth={false} style={{ paddingTop: "unset" }}>
          {/* End hero unit */}
          <div ref={mainContainer}>
            {displayBody}
          </div>
        </Container>
      </main>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>
  );
}

export default ClassToks; 