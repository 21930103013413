import React from 'react'
import '../styles.css'
import './css/ClassSetsContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { createRef } from 'react';
import { TextField } from '@mui/material';
import ButtonReact from 'react-bootstrap/Button';
import { faFilter, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getClassSets, getClassTok } from '../services/classServices';
import { getToken } from '../services/accountServices';
import ClassSetTile from './ClassSetTile';
import { Row, Col } from 'react-bootstrap';
import SetViewContainer from './SetView';
import arrayShuffle from 'array-shuffle';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '@mui/material';
import Button from 'react-bootstrap/Button';
import AddEditClassSet from './AddEditClassSet';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import QuizToks from './QuizToks';
import TokCards from './TokCards';
import AddToksToSet from './AddToksToSet';
import TokMatch from './TokMatch';
import { randomString } from '../services/helperTools';
import { Set } from '../interfaces/SetInterface';
import { Tok } from '../interfaces/TokInterface';

const MySwal = withReactContent(Swal);
const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
var shuffleColors = arrayShuffle(colors);
var colorCounter = -1;
interface States {
    items: Set[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    SetView: boolean;
    Set: Set;
    MenuKind: string;
    Navbar: boolean;
    CurrentKind: string;
    superItems: Set[];
    superLoadActive: boolean;
    superLoadMoreActive: boolean;
    superContinuationToken: string;
    title: string;
    filterOptions: {
        displayText: string;
        value: string;
        options: {
            displayText: string;
            value: boolean | number;
        }[]
    }[];
    ShowFilter: boolean;
    ShowFilterModal: boolean;
    PublicFeed: boolean;
    CustomTitleEnabled: boolean;
    CustomTitle: string;
    FilterQuery: { [key: string]: any };
    FeedType: number;
    GroupId: string;
    HideNavbar: boolean;
    CommunityId: string;
    PlayTokChoice: boolean;
    PlayToks: Tok[];
    PlayTokActive: boolean;
    PlayTokCards: boolean;
    PlayTokMatch: boolean;
    StudyGameMode: boolean;
    StudyGameSet: string;
    level1: string;
    level2: string;
    level3: string;
    resultData: { [key: string]: any };
    DataisLoaded: boolean;
}
interface Props {
    studyGameMode: boolean;
    studyGameModeRef: React.RefObject<HTMLDivElement> | undefined;
    customTitleEnabled: boolean;
    customTitle: string;
    Navbar: boolean;
    MenuKind: boolean;
    PublicFeed: boolean;
    query: { [key: string]: any };
    groupid: string;
    HideNavbar: boolean;
    communityid: string;
    level1: string;
    level2: string;
    level3: string;
    addClassSetRef: React.RefObject<HTMLDivElement> | undefined;
    addExistingClassSetTransferRef: React.RefObject<HTMLDivElement> | undefined;
}
class ClassSetsContainer extends React.Component<Props, States> {
    searchInput: React.RefObject<any>;
    addTokBtnRef: React.RefObject<any>;
    addClassSetRef: React.RefObject<any>;
    addClassSetTrigger: React.RefObject<any>;
    addExistingClassSetTransferRef: React.RefObject<any>;
    studyGameModeRef: React.RefObject<any>;
    addToksToSetTrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        var customTitleEnabled = false;
        var customTitle = "";
        if (props.customTitleEnabled) {
            customTitleEnabled = props.customTitleEnabled;
            customTitle = props.customTitle;
        }
        var PublicFeed = false;
        if (props.PublicFeed) {
            PublicFeed = props.PublicFeed;
        }

        this.state = {
            DataisLoaded: false,
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query ? props.query : [],
            LoadMoreActive: false,
            SetView: false,
            Set: {} as Set,
            MenuKind: props.MenuKind,
            Navbar: props.Navbar,
            CurrentKind: "sets",

            superItems: [],
            superLoadActive: false,
            superLoadMoreActive: false,
            superContinuationToken: null,

            title: customTitleEnabled ? customTitle : PublicFeed ? "Public Sets" : "My Sets",

            filterOptions: [
                {
                    displayText: "Feed Options", value: "publicfeed", options: [
                        { displayText: "Public", value: true },
                        { displayText: "My Sets", value: false }
                    ]
                },
                {
                    displayText: "Type", value: "feedtype", options: [
                        { displayText: "All", value: 0 },
                        { displayText: "Playable", value: 1 },
                        { displayText: "Non-Playable", value: 2 },
                    ]
                },
            ],
            ShowFilter: false,
            ShowFilterModal: false,

            PublicFeed: PublicFeed,

            CustomTitleEnabled: customTitleEnabled,
            CustomTitle: customTitle,
            FilterQuery: {
                "publicfeed": PublicFeed,
                "feedtype": 0
            },
            FeedType: 0,
            GroupId: props.groupid,
            HideNavbar: props.HideNavbar ? true : false,
            CommunityId: props.communityid,
            PlayTokChoice: false,
            PlayToks: [],
            PlayTokActive: false,
            PlayTokCards: false,
            PlayTokMatch: false,

            StudyGameMode: props.studyGameMode ? props.studyGameMode : false,
            StudyGameSet: "",
            level1: props.level1,
            level2: props.level2,
            level3: props.level3,
            resultData: {}
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.setView = this.setView.bind(this);
        this.setViewClose = this.setViewClose.bind(this);

        this.setKind = this.setKind.bind(this);

        this.setSetSearch = this.setSetSearch.bind(this);
        this.searchInput = createRef();

        this.showHideFilter = this.showHideFilter.bind(this);
        this.setFilterOptions = this.setFilterOptions.bind(this);

        this.addClassSetRef = props.addClassSetRef;
        this.openAddClassSet = this.openAddClassSet.bind(this);
        this.addClassSetTrigger = createRef();
        this.newSet = this.newSet.bind(this);

        this.addExistingClassSetTransferRef = props.addExistingClassSetTransferRef;
        this.transferData = this.transferData.bind(this);
        this.removeSet = this.removeSet.bind(this);

        this.playTokChoice = this.playTokChoice.bind(this);
        this.closePlay = this.closePlay.bind(this);

        this.studyGameModeRef = props.studyGameModeRef;
        this.setStudyGameMode = this.setStudyGameMode.bind(this);

        this.playTokCards = this.playTokCards.bind(this);

        this.addToktoSet = this.addToktoSet.bind(this);
        this.addToksToSetTrigger = createRef();

        this.updatedSet = this.updatedSet.bind(this);

        this.playTokMatch = this.playTokMatch.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    setView(card) {
        const { GroupId } = this.state;
        const hrefLink = "/classtoks/set/" + card.id + "/" + card.pk;
        window.history.pushState({}, "Tokket", hrefLink);
        if (GroupId) {
            (document.getElementsByClassName('group-head')[0] as HTMLElement).style.display = "none";
        }
        this.setState({
            SetView: true,
            Set: card
        });
    }
    setViewClose() {
        const { GroupId } = this.state;
        if (GroupId) {
            const hrefLink = "/group/" + GroupId;
            (document.getElementsByClassName('group-head')[0] as HTMLElement).style.display = "";
            window.history.pushState({}, "Tokket", hrefLink);
        }
        this.setState({
            SetView: false,
            Set: {} as Set
        });
    }
    removeSet(set) {
        console.log(set);
        const { GroupId, items } = this.state;
        if (GroupId) {
            const hrefLink = "/group/" + GroupId;
            (document.getElementsByClassName('group-head')[0] as HTMLElement).style.display = "";
            window.history.pushState({}, "Tokket", hrefLink);
        }
        for (let x = 0; x < items.length; x++) {
            if (items[x]["id"] == set["id"] && items[x]["pk"] == set["pk"]) {
                items.splice(x, 1);
                break;
            }
        }
        this.setState({
            SetView: false,
            Set: {} as Set,
            items: items
        });
    }
    async getData(customQuery) {
        var { FeedType, query, items, PublicFeed, CustomTitle, CustomTitleEnabled, GroupId, continuationToken } = this.state;
        await getToken();
        var titleDisplay = "Sets";
        if (customQuery) {
            query = customQuery;
            if (!customQuery["pagination"]) {
                this.setState({
                    items: [],
                    LoadActive: true
                });
            }
        }
        if (PublicFeed) {
            query["public"] = "true";
            query["userid"] = "";
            titleDisplay = "Public Sets";
        } else {
            try {
                const localUser = JSON.parse(localStorage.getItem("userdata"));
                query["userid"] = localUser.id
                query["public"] = "";
                titleDisplay = "My Sets";
            } catch (err) { }
        }
        query["feedtype"] = FeedType;

        const response = await getClassSets(query);
        var itemResult = response? response.data["result"] ? response.data["result"] : new Array: new Array
        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            resultData: response? response.data: {},
            continuationToken: response? response.data["pagination"] ? response.data["pagination"] : "": "",
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false,
            title: CustomTitleEnabled ? CustomTitle : titleDisplay
        });
    }
    async getSuperData(customQuery) {
        var { query, superItems, PublicFeed, CustomTitle, CustomTitleEnabled } = this.state;
        var titleDisplay = "Sets";
        if (customQuery) {
            query = customQuery;
            this.setState({
                superItems: [],
                LoadActive: true
            });
        }

        if (PublicFeed) {
            query["public"] = "true";
            titleDisplay = "Public Sets";
        } else {
            try {
                const localUser = JSON.parse(localStorage.getItem("userdata"));
                query["userid"] = localUser.id
                titleDisplay = "My Sets";
            } catch (err) { }
        }
        query["label"] = "superset";
        const response = await getClassSets(query);
        var itemResult = response? response.data.result: new Array;
        try {
            if (query["pagination"]) {
                itemResult = superItems.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            superContinuationToken: response? response.data.result["continuationToken"] ? response.data.result["continuationToken"] : "": "",
            superItems: itemResult,
            superLoadActive: false,
            superLoadMoreActive: false,
            title: CustomTitleEnabled ? CustomTitle : titleDisplay
        });
    }
    reload() {
        this.setState({
            LoadActive: true,
            items: []
        });
        this.getData({});
    }
    loadmore() {
        const { LoadMoreActive, continuationToken } = this.state;
        if (!LoadMoreActive && continuationToken) {
            this.setState({
                LoadMoreActive: true
            });
            var { query, } = this.state;
            if (query) {
                query.pagination = continuationToken;
            } else {
                query = { "pagination": continuationToken }
            }

            this.getData(query)
        }
    }
    setKind(btn) {
        const kind = btn.target.getAttribute("data-value");
        var showLoader = true;
        if (kind == "sets") {
            const { query, items } = this.state;
            if (items.length > 0) {
                showLoader = false;
            }
            this.setState({
                LoadActive: showLoader,
                CurrentKind: kind
            });
            if (showLoader) this.getData({});
        } else if (kind == "supersets") {
            const { query, superItems } = this.state;
            if (superItems.length > 0) {
                showLoader = false;
            }
            this.setState({
                superLoadActive: showLoader,
                CurrentKind: kind
            });
            if (showLoader) this.getSuperData({});
        }

    }
    setSetSearch() {
        const search = this.searchInput.current.querySelector("input").value;
        this.setState({
            DataisLoaded: false,
            items: []
        });
        var { query, CurrentKind } = this.state;
        try {
            if (query) {
                query.name = search;
            } else {
                query = { "name": search }
            }
        } catch (err) {
            query = { "name": search }
        }
        if (CurrentKind == "sets") {
            this.getData(query)
        } else if (CurrentKind == "supersets") {
            this.getSuperData({});
        }
    }

    showHideFilter() {
        const { ShowFilter } = this.state;

        this.setState({
            ShowFilter: !ShowFilter
        });
    }

    setFilterOptions(e) {
        const target = e.target.getAttribute("data-target");
        const value = e.target.getAttribute("data-value");
        var { FilterQuery } = this.state;
        switch (target) {
            case "publicfeed":
                if (value == "true") {
                    FilterQuery["publicfeed"] = true
                    this.setState({
                        items: [],
                        LoadActive: true,
                        PublicFeed: true,
                        FilterQuery: FilterQuery
                    }, () => {
                        this.getData({});
                    });
                } else {
                    FilterQuery["publicfeed"] = false
                    this.setState({
                        items: [],
                        LoadActive: true,
                        PublicFeed: false,
                        FilterQuery: FilterQuery
                    }, () => {
                        this.getData({});
                    });
                }
                break;
            case "feedtype":
                FilterQuery[target] = Number(value)
                this.setState({
                    items: [],
                    LoadActive: true,
                    FeedType: Number(value),
                    FilterQuery: FilterQuery
                }, () => {
                    this.getData({});
                });
                break;
        }
    }
    openAddClassSet() {
        this.addClassSetTrigger.current.click();
    }
    newSet(data) {
        var { items } = this.state;
        items.unshift(data);
        this.setState({
            items: items,
        });
    }
    transferData(e) {
        const data = e.target.getAttribute("data-data");
        if (data) {
            var { items } = this.state;
            const dataJson = JSON.parse(data);
            items.unshift(dataJson);
            e.target.setAttribute("data-data", "");
            this.setState({
                items: items,
            });
        }
    }
    async playTokChoice(set) {
        const { GroupId, items } = this.state;
        var setToPlay: Set | undefined;
        var filterItems = items.filter(item => item["id"] == set["id"] && item["pk"] == set["pk"]);
        if(filterItems.length){
            setToPlay = filterItems[0];
        }
        if (setToPlay["ids"].length > 4) {
            MySwal.fire({
                title: <p>Getting Toks Information</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            var toks = new Array;
            for (let x = 0; x < setToPlay["ids"].length; x++) {
                try {
                    let tempTokResult = await getClassTok(setToPlay["ids"][x], setToPlay["tokpks"][x]);

                    let tempTok = tempTokResult["data"]["resource"];
                    toks.push(tempTok);
                } catch (err) { }
            }
            MySwal.close();
            this.setState({
                PlayTokChoice: true,
                PlayToks: toks,
                PlayTokActive: true
            });
        } else {
            MySwal.fire({
                title: <p>Not enough toks, need 4 toks to play.</p>,
                icon: "info",
                allowOutsideClick: false
            });
        }
    }

    async playTokCards(set) {
        const { GroupId, items } = this.state;
        var setToPlay: Set | undefined;
        var filterItems = items.filter(item => item["id"] == set["id"] && item["pk"] == set["pk"]);
        if(filterItems.length){
            setToPlay = filterItems[0];
        }
        MySwal.fire({
            title: <p>Getting Toks Information</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        var toks = new Array;
        for (let x = 0; x < setToPlay["ids"].length; x++) {
            try {
                let tempTokResult = await getClassTok(setToPlay["ids"][x], setToPlay["tokpks"][x]);

                let tempTok = tempTokResult["data"]["resource"];
                toks.push(tempTok);
            } catch (err) { }
        }
        MySwal.close();
        this.setState({
            PlayTokCards: true,
            PlayToks: toks,
            PlayTokActive: true
        });
    }

    async playTokMatch(set) {
        const { GroupId, items } = this.state;
        var setToPlay: Set | undefined;
        var filterItems = items.filter(item => item["id"] == set["id"] && item["pk"] == set["pk"]);
        if(filterItems.length){
            setToPlay = filterItems[0];
        }
        MySwal.fire({
            title: <p>Getting Toks Information</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        var toks = new Array;
        if (setToPlay["ids"].length > 1) {
            for (let x = 0; x < setToPlay["ids"].length; x++) {
                try {
                    let tempTokResult = await getClassTok(setToPlay["ids"][x], setToPlay["tokpks"][x]);

                    let tempTok = tempTokResult["data"]["resource"];
                    toks.push(tempTok);
                } catch (err) { }
            }
        }

        if (toks.length > 1) {
            MySwal.close();
            this.setState({
                PlayTokMatch: true,
                PlayToks: toks,
                PlayTokActive: true
            });
        } else {
            MySwal.hideLoading();
            MySwal.update({
                title: <p>Not Enough Toks</p>,
                icon: "info",
                showConfirmButton: true
            });
        }

    }

    closePlay() {
        this.setState({
            PlayTokChoice: false,
            PlayToks: [],
            PlayTokActive: false,
            PlayTokMatch: false,
            PlayTokCards: false
        });
    }
    setStudyGameMode(e) {
        const mode = e.target.getAttribute("data-mode");
        this.setState({
            StudyGameSet: mode
        });
    }
    addToktoSet(Set) {
        const { level1, level2, level3 } = this.state;
        let setString = JSON.stringify(Set);

        this.addToksToSetTrigger.current.setAttribute("data-set", setString);
        this.addToksToSetTrigger.current.setAttribute("data-level1", level1);
        this.addToksToSetTrigger.current.setAttribute("data-level2", level2);
        this.addToksToSetTrigger.current.setAttribute("data-level3", level3);
        this.addToksToSetTrigger.current.click();
    }
    updatedSet(set) {
        var { items } = this.state;
        for (let x = 0; x < items.length; x++) {
            if (items[x]["id"] == set["id"] && items[x]["pk"] == set["pk"]) {
                items[x] = set;
            }
        }
        this.setState({
            items: items
        });
    }
    render() {
        var { PlayTokCards, PlayTokMatch, StudyGameMode, StudyGameSet, PlayTokChoice, PlayToks, PlayTokActive, CommunityId, HideNavbar, GroupId, FilterQuery, ShowFilter, filterOptions, LoadActive, SetView, Set, items, LoadMoreActive, continuationToken, MenuKind, Navbar, CurrentKind, superItems, superContinuationToken, superLoadActive, superLoadMoreActive, title } = this.state;

        if (SetView) {
            return <SetViewContainer set={Set} closeButton={true} removeSet={this.removeSet} updateSet={this.updatedSet} closeView={this.setViewClose} />
        }
        var activeList = [];
        var activeLoader = false;
        var activeMoreLoader = false;

        switch (CurrentKind) {
            case "sets":
                if (items) {
                    activeList = items;
                    activeLoader = LoadActive;
                    activeMoreLoader = LoadMoreActive;
                }

                break;
            case "supersets":
                if (superItems) {
                    activeList = superItems;
                    activeLoader = superLoadActive;
                    activeMoreLoader = superLoadMoreActive;
                }

                break;
        }
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <div style={{ marginTop: "15px" }}>
                <Button ref={this.addExistingClassSetTransferRef} onClick={this.transferData} data-data="" hidden></Button>
                <Button ref={this.studyGameModeRef} onClick={this.setStudyGameMode} data-mode="" hidden></Button>
                <AddToksToSet set={null} addToktoSetShow={null} updatedSet={this.updatedSet} addToksToSetTrigger={this.addToksToSetTrigger} />
                <Modal className={'modal-full-width'} show={PlayTokActive} style={{ zIndex: "1299" }}>
                    <Modal.Header>
                        <FontAwesomeIcon icon={faTimes} size={'2x'} style={{ cursor: "pointer", marginRight: "0.5rem" }} onClick={this.closePlay} />
                    </Modal.Header>
                    <Modal.Body style={{ position: "relative" }}>
                        {PlayTokChoice ?
                            <QuizToks items={PlayToks} displayScore={true} /> : ""
                        }
                        {PlayTokCards ?
                            <TokCards items={PlayToks} displayScore={true} /> : ""
                        }
                        {PlayTokMatch ?
                            <TokMatch set={undefined} items={PlayToks} closeGame={this.closePlay} /> : ""
                        }
                    </Modal.Body>
                </Modal>
                {Navbar ?
                    <Grid className={'text-color-white'} container spacing={1} style={{ padding: "3px", display: HideNavbar ? "none" : "" }}>
                        {!StudyGameMode ?
                            <>
                                <div style={{ position: "relative" }}>
                                    <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
                                </div>
                                <ButtonReact onClick={this.setSetSearch} variant='primary' style={{ marginRight: "5px" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                                </ButtonReact>
                            </> : ""
                        }

                        <div style={{ marginRight: "auto", display: title ? "flex" : "none", alignItems: "center", fontWeight: "bold", marginLeft: "10px", fontSize: "21px", backgroundColor: "#112379", color: "white", borderRadius: "10px", textAlign: "center", textTransform: "capitalize", padding: "10px 40px" }}>
                            {title}
                        </div>

                        {!StudyGameMode ?
                            <>
                                <ButtonReact variant={'success'} onClick={this.openAddClassSet} ref={this.addClassSetRef}>Add Set</ButtonReact>
                                <AddEditClassSet addClassSetTrigger={this.addClassSetTrigger} newSet={this.newSet} groupId={GroupId} />
                            </> : ""
                        }

                        <div className={'refresh-btn'} onClick={this.reload} style={{ marginLeft: title ? "" : "auto" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                        </div>
                        <div onClick={this.showHideFilter} style={{ display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
                            FILTER
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faFilter} />
                        </div>
                    </Grid>
                    : ""}
                {ShowFilter ?
                    <div className={'col-12'}>
                        <Row style={{ justifyContent: "center" }}>
                            {filterOptions.map((filterOption, index) => (
                                <Col md='auto' sm={6} xs={6}>
                                    <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{filterOption.displayText}</div>
                                    {filterOption.options.map((option) => (
                                        <div style={{ cursor: "pointer", color: FilterQuery[filterOption.value] == option.value ? "#112379" : "" }} onClick={this.setFilterOptions} data-target={filterOption.value} data-value={option.value}>{option.displayText}</div>
                                    ))}
                                </Col>
                            ))}
                        </Row>
                    </div> : ""}
                {MenuKind ?
                    <Row>
                        <ButtonReact variant='secondary' onClick={this.setKind} className={CurrentKind != "sets" ? "kind-option-btn col" : "kind-option-btn col active"} data-value={'sets'} style={{ backgroundColor: '#f711ec' }}>
                            Sets
                        </ButtonReact>
                        <ButtonReact variant='secondary' onClick={this.setKind} className={CurrentKind != "supersets" ? "kind-option-btn col" : "kind-option-btn col active"} data-value={'supersets'} style={{ backgroundColor: '#F57C00' }}>
                            Supersets
                        </ButtonReact>
                    </Row> :
                    ""
                }
                {activeLoader ?
                    <WaitSpinner /> :
                    <Grid container spacing={1} style={{ marginTop: "20px" }} className={"classset-container"}>
                        {activeList.map(card => {
                            colorCounter++;
                            if (colorCounter == 16) {
                                colorCounter = 0;
                            }
                            return (
                                <ClassSetTile key={card["id"] + "-" + card["pk"] + "-" + randomString(11)} card={card} color={shuffleColors[colorCounter]} updatedSet={this.updatedSet} removeSet={this.removeSet} setView={this.setView} addToktoSet={this.addToktoSet} playTokChoice={this.playTokChoice} playTokCards={this.playTokCards} playTokMatch={this.playTokMatch} studyGameSet={StudyGameSet} />
                            )
                        })}
                        {activeMoreLoader ? <WaitSpinner /> : ""}
                    </Grid>
                }

            </div>

        );
    }
}

export default ClassSetsContainer;