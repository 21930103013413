import React from 'react'
import '../styles.css'
import './css/TokHandleContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faMagnifyingGlass, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonReact from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import TokHandleMenu from "./TokHandleMenu";
import { checkTokHandleAvailability, getTokHandles, getToken, getUserTokhandle, postTokHandle } from '../services/accountServices';
import './css/TokHandle.css';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { getProduct } from '../services/purchaseTool';
import { createRoot } from 'react-dom/client';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { addTokhandle } from '../services/paypalServices';
import { cloneDeep, isNull } from 'lodash';
import { TokHandle } from '../interfaces/TokhandleInterface';
import { TokUser } from '../interfaces/TokUserInterface';

const BrandName = process.env.REACT_APP_PAYPAL_BRANDNAME;
const LandingPage = process.env.REACT_APP_PAYPAL_LANDINGPAGE;
const UserAction = process.env.REACT_APP_PAYPAL_USERACTION;
const ShippingPreference = process.env.REACT_APP_PAYPAL_SHIPPINGPREFERENCE;
const MySwal = withReactContent(Swal);

interface PayPalOrderRequest {
    id: string;
    status: string;
    payment_source: {
        paypal: {};
    };
    links: [
        {
            href: string;
            rel: string;
            method: string;
        }
    ];
}

interface States {
    items: TokHandle[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    ActiveTab: string;
    StoreOptions: {
        displayText: string;
        value: number | string;
        maxvalue?: number;
    }[];
    ActiveStoreOption: string;
    Count: number;
    MaxCount: number;
    priceList: {
        displayText: string;
        price: number;
        charactercount?: number;
        bgcolor: string;
        hdcolor: string;
        color: string
    }[];
    PurchaseTokHandleShow: boolean;
    PurchaseTokHandleCounts: number[];
    PurchaseTokhandleList: {
        value: string;
        avail: boolean;
        check: boolean;
        loader: boolean;
        msg: string;
    }[];
    OpenPayPalDropDown: boolean;
    OfferTokHandleShow: boolean;
    OfferPaypalPurchase: boolean;
    userInfo: TokUser;
    OfferTargetHandle: TokHandle;
    preloadHandles: TokHandle[];
    tokHandleMenuToken: string;
    delayTokHandleMenuLoad: boolean;
    resultData: { [key: string]: any };
    OrderRequest: PayPalOrderRequest;
    NewHandles: TokHandle[];
}

interface Props {
    query: { [key: string]: any };
}

class TokHandleContainer extends React.Component<Props, States> {
    paypalOfferHandleContainer: React.RefObject<any>;
    paypalContainer: React.RefObject<any>;
    searchInput: React.RefObject<any>;
    loadmoretrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            NewHandles: new Array,
            OrderRequest: {} as PayPalOrderRequest,
            resultData: {},
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            ActiveTab: "owned",
            StoreOptions: [
                { displayText: "Pricing", value: "price" },
                { displayText: "1 Character", value: 1 },
                { displayText: "2 Characters", value: 2 },
                { displayText: "3 Characters", value: 3 },
                { displayText: "4 Characters", value: 4 },
                { displayText: "5 Characters", value: 5 },
                { displayText: "6 Characters", value: 6 },
                { displayText: "7 - 9 Characters", value: 7, maxvalue: 9 },
                { displayText: "10+ Characters", value: 10 }
            ],
            ActiveStoreOption: "price",
            Count: -1,
            MaxCount: -1,
            priceList: [
                { displayText: "One Character", price: 999, charactercount: 1, bgcolor: "#7986cb", hdcolor: "#303F9F", color: "white" },
                { displayText: "Two Characters", price: 499, charactercount: 2, bgcolor: "#64b5f6", hdcolor: "#1976D2", color: "white" },
                { displayText: "Three Characters", price: 299, charactercount: 3, bgcolor: "#4fc3f7", hdcolor: "#0288D1", color: "white" },
                { displayText: "Four Characters", price: 199, charactercount: 4, bgcolor: "#4dd0e1", hdcolor: "#0097A7", color: "white" },
                { displayText: "Five Characters", price: 99, charactercount: 5, bgcolor: "#4db6ac", hdcolor: "#00796B", color: "white" },
                { displayText: "Six Characters", price: 49, charactercount: 6, bgcolor: "#81c784", hdcolor: "#388E3C", color: "white" },
                { displayText: "Seven to Nine Characters", price: 9.99, charactercount: 9, bgcolor: "#aed581", hdcolor: "#689F38", color: "white" },
                { displayText: "Ten or more Characters", price: 4.99, bgcolor: "#dce775", hdcolor: "#AFB42B", color: "white" },
            ],
            PurchaseTokHandleShow: false,
            PurchaseTokHandleCounts: [1, 2, 3, 4, 5, 6],
            PurchaseTokhandleList: [{ value: "", avail: false, check: false, loader: false, msg: "" }],
            OpenPayPalDropDown: false,
            OfferTokHandleShow: false,
            OfferPaypalPurchase: false,
            userInfo: {} as TokUser,
            OfferTargetHandle: {} as TokHandle,
            preloadHandles: new Array,
            tokHandleMenuToken: "",
            delayTokHandleMenuLoad: false
        };
        this.reload = this.reload.bind(this);
        this.loadmoretrigger = createRef();
        this.loadmore = this.loadmore.bind(this);

        this.setActiveTab = this.setActiveTab.bind(this);
        this.setActiveStoreOption = this.setActiveStoreOption.bind(this);

        this.updateHandle = this.updateHandle.bind(this);
        this.searchInput = createRef();
        this.setSearchTokHandle = this.setSearchTokHandle.bind(this);

        this.openPurchaseHandle = this.openPurchaseHandle.bind(this);
        this.checkHandleAvailabity = this.checkHandleAvailabity.bind(this);
        this.closePurchaseHandle = this.closePurchaseHandle.bind(this);
        this.setTokHandleCount = this.setTokHandleCount.bind(this);
        this.setTokHandleValue = this.setTokHandleValue.bind(this);

        this.createOrder = this.createOrder.bind(this);
        this.paypalContainer = createRef();
        this.setOpenPayPalDropDown = this.setOpenPayPalDropDown.bind(this);

        this.createTokHandle = this.createTokHandle.bind(this);
        this.initializeCreateTokHandle = this.initializeCreateTokHandle.bind(this);

        this.purchaseHandle = this.purchaseHandle.bind(this);
        this.paypalOfferHandleContainer = createRef();
        this.createOfferHandleOrder = this.createOfferHandleOrder.bind(this);
        this.closeOfferHandle = this.closeOfferHandle.bind(this);
        this.setOfferPayPalDropDown = this.setOfferPayPalDropDown.bind(this);

        this.transferTokHandle = this.transferTokHandle.bind(this);

        this.returnPostionClass = this.returnPostionClass.bind(this);

        this.setTokHandleMenuData = this.setTokHandleMenuData.bind(this);
        this.setDelayTokHandleMenuLoad = this.setDelayTokHandleMenuLoad.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                this.setState({
                    userInfo: localUserJson,
                });
            }
        }
        this.setState({
            LoadActive: true
        });
        this.getData({});

    }
    async getData(customQuery) {
        var { query, items } = this.state;

        if (customQuery) {
            query = customQuery;
            this.setState({
                items: [],
                LoadActive: true
            });
        }
        const response = await getTokHandles(query);
        var itemResult = response.data["result"] ? response.data["result"] : new Array;
        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            resultData: response.data,
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
        await getToken();
    }
    setTokHandleMenuData(data) {
        var { preloadHandles } = this.state;
        let token = data["token"] ? data["token"] : "";
        let handles = data["handles"] ? data["handles"].concat(preloadHandles) : preloadHandles;
        this.setState({
            preloadHandles: handles,
            tokHandleMenuToken: token
        });
    }
    reload() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    loadmore() {
        this.setState({
            LoadMoreActive: true
        });
        var { query, continuationToken } = this.state;
        if (query) {
            query.pagination = continuationToken;
        } else {
            query = { "pagination": continuationToken }
        }

        this.getData(query)
    }
    setActiveTab(btn) {
        const tab = btn.target.getAttribute("data-tab");
        this.setState({
            ActiveTab: tab
        });
    }
    setActiveStoreOption(btn) {
        const tab = btn.target.getAttribute("data-tab");
        const limit = btn.target.getAttribute("data-tablimit");
        var count = -1;
        var maxcount = -1;
        var activeTab = tab;
        if (tab && tab != "price") {

            if (Number(tab) > 0) {
                count = Number(tab);
            }
            if (Number(limit) > 0) {
                maxcount = Number(limit);
            } else {
                maxcount = count;
            }
            this.getData({ ">id": count, "<id": maxcount });
        }
        this.setState({
            ActiveStoreOption: activeTab,
            LoadActive: true,
            Count: count,
            MaxCount: maxcount
        });
    }
    setSearchTokHandle(btn) {
        const { Count, MaxCount } = this.state;
        const search = this.searchInput.current.querySelector("input").value;
        this.getData({ "charcount": Count, "limitcharcount": MaxCount, "text": search });
    }
    updateHandle(handle) {
        var { items } = this.state;
        if (items) {
            for (let x = 0; x < items.length; x++) {
                if (items[x].id == handle.id && items[x].pk == handle.pk) {
                    items[x] = handle;
                    break;
                }
            }
        }
        this.setState({
            items: items,
        });
    }
    openPurchaseHandle() {
        this.setState({
            PurchaseTokHandleShow: true,
        });
    }
    checkHandleAvailabity() {
        var { PurchaseTokhandleList } = this.state;
        this.setState({
            OpenPayPalDropDown: false
        });
        for (let x = 0; x < PurchaseTokhandleList.length; x++) {
            PurchaseTokhandleList[x]["avail"] = false;
            PurchaseTokhandleList[x]["msg"] = "";
            PurchaseTokhandleList[x]["check"] = false;
            PurchaseTokhandleList[x]["loader"] = true;
            this.setState({
                PurchaseTokhandleList: PurchaseTokhandleList
            });
            checkTokHandleAvailability(PurchaseTokhandleList[x]["value"]).then(result => {
                if(result){
                    if (result["status"] == 404) {
                        this.setHandleAvailabiliy(x, true, "", PurchaseTokhandleList[x]["value"]);
                    } else if (result["status"] == 200) {
                        this.setHandleAvailabiliy(x, false, "", PurchaseTokhandleList[x]["value"]);
                    } else {
                        this.setHandleAvailabiliy(x, false, "An Error Occured. Try Again.", PurchaseTokhandleList[x]["value"]);
                    }
                }
            }).catch(err => {
                this.setHandleAvailabiliy(x, false, "An Error Occured. Try Again.", PurchaseTokhandleList[x]["value"]);
            });
        }
    }
    setHandleAvailabiliy(index, avail, msg, value) {
        var { PurchaseTokhandleList } = this.state;
        if (PurchaseTokhandleList[index]["value"] == value) {
            PurchaseTokhandleList[index]["avail"] = avail;
            PurchaseTokhandleList[index]["msg"] = msg;
            PurchaseTokhandleList[index]["check"] = true;
            PurchaseTokhandleList[index]["loader"] = false;
            this.setState({
                PurchaseTokhandleList: PurchaseTokhandleList
            });
        }
    }
    setTokHandleCount(e) {
        var value = e.target.value;
        var { PurchaseTokhandleList } = this.state;
        if (PurchaseTokhandleList.length < value) {
            let newList = PurchaseTokhandleList;
            for (let x = PurchaseTokhandleList.length; x < value; x++) {
                newList.push({ value: "", avail: false, check: false, msg: "", loader: false });
            }
            PurchaseTokhandleList = newList;
        } else if (PurchaseTokhandleList.length > value) {
            PurchaseTokhandleList = PurchaseTokhandleList.slice(0, value)
        }
        this.setState({
            PurchaseTokhandleList: PurchaseTokhandleList
        });
    }
    closePurchaseHandle() {
        this.setState({
            PurchaseTokHandleShow: false,
            PurchaseTokhandleList: [{ value: "", avail: false, check: false, loader: false, msg: "" }],
        });
    }
    closeOfferHandle() {
        this.setState({
            OfferTokHandleShow: false,
        });
    }
    setTokHandleValue(e) {
        var value = e.target.value;
        var index = Number(e.target.parentNode.parentNode.getAttribute("data-index"));
        var { PurchaseTokhandleList } = this.state;
        PurchaseTokhandleList[index]["value"] = value;
        PurchaseTokhandleList[index]["avail"] = false;
        PurchaseTokhandleList[index]["check"] = false;
        PurchaseTokhandleList[index]["loader"] = false;
        this.setState({
            PurchaseTokhandleList: PurchaseTokhandleList
        });
    }
    initializeCreateTokHandle(request) {
        this.setState({
            OrderRequest: request
        }, () => {
            this.createTokHandle(0);
        });
    }
    createTokHandle(count) {
        var { OrderRequest, priceList, NewHandles, items, preloadHandles } = this.state;
        if (OrderRequest["handles"] && OrderRequest["order"] && OrderRequest["handles"].length > count) {
            let price = 4.99;
            for (let y = 0; y < priceList.length; y++) {
                if (OrderRequest["handles"].length <= priceList[y]["charactercount"]) {
                    price = priceList[y]["price"];
                    break;
                }
            }
            let tokhandle = {
                id: OrderRequest["handles"][count],
                price_usd: price
            }
            if (count == 0) {
                NewHandles = new Array;
            }
            let currenCount = cloneDeep(count) + 1;
            let message = "Adding Tok Handles " + currenCount + " of " + OrderRequest["handles"].length;
            MySwal.update({
                title: message,
                allowOutsideClick: false
            });
            var delayTolhandleView = this.setDelayTokHandleMenuLoad;
            postTokHandle(tokhandle).then(res => {
                if(res){
                    if (res["data"]["status_code"] == 200) {
                        NewHandles.push(res["data"]["result"]);
                        if (currenCount >= OrderRequest["handles"].length) {
                            let newItems = NewHandles.concat(items);
                            let newPreloadHandles = NewHandles.concat(preloadHandles);
                            MySwal.update({
                                title: <p>Tok Handles Added</p>,
                                icon: "success"
                            });
                            this.setState({
                                items: newItems,
                                preloadHandles: newPreloadHandles,
                                delayTokHandleMenuLoad: true
                            });
    
                            setTimeout(function () {
                                delayTolhandleView(false);
                            }, 500);
                        } else {
                            this.setState({
                                NewHandles: NewHandles
                            }, () => {
                                this.createTokHandle(currenCount);
                            });
                        }
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(err => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }
    }
    setDelayTokHandleMenuLoad(value) {
        this.setState({
            delayTokHandleMenuLoad: value
        });
    }
    createOrder() {
        const { priceList, PurchaseTokhandleList } = this.state;
        let price = 0;
        for (let x = 0; x < PurchaseTokhandleList.length; x++) {
            let tempPrice = 4.99;
            for (let y = 0; y < priceList.length; y++) {
                if (PurchaseTokhandleList[x]["value"].length <= priceList[y]["charactercount"]) {
                    tempPrice = priceList[y]["price"];
                    break;
                }
            }
            price = price + tempPrice;
        }
        let initializeTokHandles = this.initializeCreateTokHandle;
        (window as typeof window & { paypal: any }).paypal.Buttons({
            createOrder: function (data, actions) {

                return actions.order.create({
                    purchase_units: [{
                        reference_id: "tokhandle_tokket",
                        amount: {
                            value: price
                        }
                    }],
                    application_context: {
                        brand_name: BrandName,
                        landing_page: LandingPage,
                        user_action: UserAction,
                        shipping_preference: ShippingPreference
                    }
                });
            },
            onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                    MySwal.fire({
                        title: <p>Adding Tok Handle</p>,
                        allowOutsideClick: false
                    });
                    MySwal.showLoading()
                    var createdate = details.create_time;
                    var splitcdate = createdate.split("-");
                    var expmonth = parseInt(splitcdate[1], 10);
                    expmonth++;
                    var expdate;
                    if (expmonth < 10) {
                        expdate = splitcdate[0] + '-0' + expmonth + '-' + splitcdate[2];
                    } else {
                        expdate = splitcdate[0] + '-' + expmonth + '-' + splitcdate[2];
                    }
                    details.expiration_time = expdate;

                    var order = details;
                    let tokHandleList = new Array;
                    for (let x = 0; x < PurchaseTokhandleList.length; x++) {
                        //value
                        tokHandleList.push(PurchaseTokhandleList[x]["value"]);
                    }
                    let request = {
                        order: order,
                        handles: tokHandleList
                    }
                    initializeTokHandles(request);
                });
            }
        }).render(this.paypalContainer.current);
    }
    setOpenPayPalDropDown() {
        var { OpenPayPalDropDown } = this.state;
        const root = createRoot(this.paypalContainer.current);
        const createOrder = this.createOrder;
        root.render("");
        setTimeout(function () {
            if (!OpenPayPalDropDown) {
                createOrder();
            }

        }, 100);
        this.setState({
            OpenPayPalDropDown: !OpenPayPalDropDown
        });
    }


    createOfferHandleOrder() {
        const { OfferTargetHandle, priceList } = this.state;
        let price = OfferTargetHandle["price_usd"]
        if (price == 0) {
            price = 4.99;
            for (let y = 0; y < priceList.length; y++) {
                if (OfferTargetHandle["id"].length <= priceList[y]["charactercount"]) {
                    price = priceList[y]["price"];
                    break;
                }
            }
        }
        let transferTokHandle = this.transferTokHandle;
        (window as typeof window & { paypal: any }).paypal.Buttons({
            createOrder: function (data, actions) {

                return actions.order.create({
                    purchase_units: [{
                        reference_id: "tokhandle_tokket",
                        amount: {
                            value: price
                        }
                    }],
                    application_context: {
                        brand_name: BrandName,
                        landing_page: LandingPage,
                        user_action: UserAction,
                        shipping_preference: ShippingPreference
                    }
                });
            },
            onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                    MySwal.fire({
                        title: <p>Adding Tok Handle</p>,
                        allowOutsideClick: false
                    });
                    MySwal.showLoading()
                    var createdate = details.create_time;
                    var splitcdate = createdate.split("-");
                    var expmonth = parseInt(splitcdate[1], 10);
                    expmonth++;
                    var expdate;
                    if (expmonth < 10) {
                        expdate = splitcdate[0] + '-0' + expmonth + '-' + splitcdate[2];
                    } else {
                        expdate = splitcdate[0] + '-' + expmonth + '-' + splitcdate[2];
                    }
                    details.expiration_time = expdate;

                    var order = details;
                    transferTokHandle(OfferTargetHandle);
                });
            }
        }).render(this.paypalOfferHandleContainer.current);
    }

    transferTokHandle(tokhandle) {
        if (tokhandle) {
            postTokHandle(tokhandle, true).then(res => {
                if(res){
                    if (res["data"]["status_code"] == 200) {
                        MySwal.fire({
                            title: <p>Tok Handle Transferred</p>,
                            icon: "success"
                        });
                    } else {
                        MySwal.fire({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(err => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }
    }

    setOfferPayPalDropDown() {
        var { OfferPaypalPurchase } = this.state;
        const root = createRoot(this.paypalOfferHandleContainer.current);
        const createOrder = this.createOfferHandleOrder;
        root.render("");
        setTimeout(function () {
            if (!OfferPaypalPurchase) {
                createOrder();
            }

        }, 100);
        this.setState({
            OfferPaypalPurchase: !OfferPaypalPurchase
        });
    }

    purchaseHandle(e) {
        const { items } = this.state;
        var id = e.currentTarget.getAttribute("data-id");
        var pk = e.currentTarget.getAttribute("data-pk");
        var selectItem = items.filter(tokhandle => tokhandle["id"] == id && tokhandle["pk"] == pk);
        if (selectItem) {
            this.setState({
                OfferTargetHandle: selectItem[0],
                OfferTokHandleShow: true
            });
        }
    }
    returnPostionClass(num) {
        switch (num) {
            case 1:
                return "position-option-a";
            case 2:
                return "position-option-b";
            case 3:
                return "position-option-c";
            case 4:
                return "position-option-d";
            default:
                return "position-option-none";
        }
    }
    render() {
        var { delayTokHandleMenuLoad, preloadHandles, tokHandleMenuToken, userInfo, OfferTargetHandle, OfferPaypalPurchase, OfferTokHandleShow, OpenPayPalDropDown, PurchaseTokHandleShow, PurchaseTokhandleList, PurchaseTokHandleCounts, LoadActive, items, LoadMoreActive, continuationToken, ActiveTab, StoreOptions, ActiveStoreOption, priceList } = this.state;
        {/*window.onscroll = function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = window.scrollY + window.innerHeight;
            let modifier = 10;

            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoretrigger.current.click();
                }
            }
        };*/}
        var enabledPurchaseBtn = false;
        var totalAvailables = 0;
        var currentPrice = 0;
        for (let x = 0; x < PurchaseTokhandleList.length; x++) {
            if (PurchaseTokhandleList[x]["check"] && PurchaseTokhandleList[x]["avail"]) {
                totalAvailables++;
                let tempPrice = 4.99;
                for (let y = 0; y < priceList.length; y++) {
                    if (PurchaseTokhandleList[x]["value"].length <= priceList[y]["charactercount"]) {
                        tempPrice = priceList[y]["price"];
                        break;
                    }
                }
                currentPrice = currentPrice + tempPrice;
            }
        }
        if (totalAvailables == PurchaseTokhandleList.length) {
            enabledPurchaseBtn = true;
        }
        let offerPrice = 0;
        if (OfferTargetHandle["id"]) {
            if (OfferTargetHandle["price_usd"] == 0) {
                for (let y = 0; y < priceList.length; y++) {
                    if (OfferTargetHandle["id"].length <= priceList[y]["charactercount"]) {
                        offerPrice = priceList[y]["price"];
                        break;
                    }
                }
            } else {
                offerPrice = OfferTargetHandle["price_usd"];
            }
        }

        return (
            <div style={{ marginTop: "15px" }}>
                <Modal size='lg' show={PurchaseTokHandleShow} onHide={this.closePurchaseHandle} style={{ zIndex: "1306" }}>
                    <Modal.Header style={{ justifyContent: "flex-start" }}>
                        <FontAwesomeIcon onClick={this.closePurchaseHandle} icon={faTimes} size={'2xl'} style={{ cursor: "pointer", marginRight: "0.5rem" }} />
                        <div style={{ fontWeight: "600" }}>Purchase Tok Handle</div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Row>
                            <Col xs={12}>
                                <TextField name="tokhandle-count" style={{ width: "100%" }} select label="Number of Tok Handles" defaultValue="" onChange={this.setTokHandleCount}>
                                    {PurchaseTokHandleCounts.map((option) => (
                                        <MenuItem value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            {PurchaseTokhandleList.map((tokhandle, index) => (
                                <Col xs={12} style={{ marginTop: "15px", position: "relative" }}>
                                    <TextField style={{ width: "100%" }} data-index={index} value={tokhandle["value"]} label={"Create Tok Handle " + (index + 1)} onChange={this.setTokHandleValue} />
                                    {tokhandle["loader"] ?
                                        <div className={'tokhandle-loader-container'}>
                                            <WaitSpinner />
                                        </div> : ""
                                    }
                                    {tokhandle["check"] ?
                                        <>
                                            {tokhandle["avail"] ?
                                                <div className={'tokhandle-loader-container'} style={{ color: "green", alignItems: "center" }}>
                                                    <FontAwesomeIcon size={'2x'} icon={faCheck} />
                                                </div> :
                                                <div className={'tokhandle-loader-container'} style={{ color: "red", alignItems: "center" }}>
                                                    <FontAwesomeIcon size={'2x'} icon={faTimes} />
                                                </div>
                                            }
                                        </> : ""
                                    }
                                </Col>
                            ))}
                            <Col xs={12} style={{ marginTop: "15px" }}>
                                <ButtonReact variant='primary' onClick={this.checkHandleAvailabity} style={{ width: "100%" }}>
                                    Check Availability
                                </ButtonReact>
                            </Col>

                            <Col xs={12} style={{ marginTop: "15px" }}>
                                <Dropdown style={{ width: "100%" }}>
                                    <Dropdown.Toggle variant='primary' onClick={this.setOpenPayPalDropDown} disabled={!enabledPurchaseBtn} style={{ width: "100%" }}>
                                        Create Handle(s)
                                    </Dropdown.Toggle>
                                    <div ref={this.paypalContainer} className={'tokhandle-paypal-custom-dropdown-menu'} style={{ display: OpenPayPalDropDown ? "block" : "none" }}></div>
                                </Dropdown>

                            </Col>
                            {enabledPurchaseBtn ?
                                <Col xs={12} style={{ marginTop: "15px" }}>
                                    <b>Total Price: </b>
                                    ${currentPrice}
                                </Col> : ""
                            }

                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal size='lg' show={OfferTokHandleShow} onHide={this.closeOfferHandle} style={{ zIndex: "1306" }}>
                    <Modal.Header style={{ justifyContent: "flex-start" }}>
                        <FontAwesomeIcon onClick={this.closeOfferHandle} icon={faTimes} size={'2xl'} style={{ cursor: "pointer", marginRight: "0.5rem" }} />
                        <div style={{ fontWeight: "600" }}>Purchase Tok Handle</div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Row>
                            {OfferTargetHandle["id"] ?
                                <Col xs={12} style={{ marginTop: "15px" }}>
                                    <div className={`nameplate-div position-showcase ${this.returnPostionClass(OfferTargetHandle.position)}`} style={{ background: OfferTargetHandle["color"] ? "#" + OfferTargetHandle.color : OfferTargetHandle["image"] ? `url(${OfferTargetHandle.image})` : "" }}>
                                        <div className={'position-displayname'}>{userInfo.display_name}c4</div>
                                        <div className={'position-tokhandle'}>{OfferTargetHandle.id}</div>
                                    </div>
                                </Col> : ""
                            }
                            <Col xs={12} style={{ marginTop: "15px" }}>
                                <Dropdown style={{ width: "100%" }}>
                                    <Dropdown.Toggle variant='primary' onClick={this.setOfferPayPalDropDown} style={{ width: "100%" }}>
                                        Purchase Tok Handle
                                    </Dropdown.Toggle>
                                    <div ref={this.paypalOfferHandleContainer} className={'tokhandle-paypal-custom-dropdown-menu'} style={{ display: OfferPaypalPurchase ? "block" : "none" }}></div>
                                </Dropdown>

                            </Col>
                            {OfferTargetHandle["id"] ?
                                <Col xs={12} style={{ marginTop: "15px" }}>
                                    <b>Total Price: </b>
                                    ${offerPrice}
                                </Col> : ""
                            }
                        </Row>
                    </Modal.Body>
                </Modal>
                <Grid className={'text-color-white'} container spacing={1} style={{ padding: "3px" }}>
                    {/* <div style={{ position: "relative" }}>
                        <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
                    </div>
                    <ButtonReact variant='primary' style={{ marginRight: "5px" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                    </ButtonReact>
                    */}
                    <div style={{ marginRight: "auto", display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "10px", fontSize: "21px", backgroundColor: "#112379", color: "white", borderRadius: "10px", textAlign: "center", textTransform: "capitalize", padding: "10px 40px" }}>
                        Tok Handles
                    </div>
                    <ButtonReact variant={'success'} onClick={this.openPurchaseHandle}>+ Purchase Tok Handle</ButtonReact>
                    {/* <AddClassTok customClass={customClass} /> */}
                    <div className={'refresh-btn'} onClick={this.reload}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                    </div>
                </Grid>
                <div style={{ display: "grid", gridGap: "5px", gridTemplateColumns: "min-content min-content", justifyContent: "center", marginBottom: "10px" }}>
                    <div data-tab={"owned"} onClick={this.setActiveTab} className={ActiveTab == "owned" ? 'tokhandle-tab-btn active btn' : 'tokhandle-tab-btn btn'}>My Tok Handles</div>
                    <div data-tab={"store"} onClick={this.setActiveTab} className={ActiveTab == "store" ? 'tokhandle-tab-btn active btn' : 'tokhandle-tab-btn btn'}>Store</div>
                </div>
                <div style={{ display: ActiveTab == "owned" ? "block" : "none" }}>
                    {!delayTokHandleMenuLoad ?
                        <TokHandleMenu updateHandle={this.updateHandle} handles={preloadHandles} tokHandleMenuToken={tokHandleMenuToken} setTokHandleMenuData={this.setTokHandleMenuData} /> : ""
                    }
                </div>
                <div style={{ display: ActiveTab == "store" ? "block" : "none" }}>
                    <Row style={{ borderBottom: "1px solid #dee2e6", paddingBottom: "1px" }}>
                        {StoreOptions.map((StoreOption, index) => (
                            <Col data-tab={StoreOption.value} data-tablimit={StoreOption.maxvalue} onClick={this.setActiveStoreOption} className={StoreOption.value == ActiveStoreOption ? 'tokhandle-tab-store-btn active' : 'tokhandle-tab-store-btn'}>{StoreOption.displayText}</Col>
                        ))}
                    </Row>
                    {!isNull(ActiveStoreOption) ?
                        <>{ActiveStoreOption == "price" ?
                            <Row>
                                {priceList.map((item, index) => (
                                    <Col md={3} sm={4} xs={6} style={{ padding: "2.5px", color: item["color"], fontSize: "31px", textAlign: "center" }}>
                                        <div style={{ backgroundColor: item["bgcolor"], height: "140px" }}>
                                            <div style={{ backgroundColor: item["hdcolor"], fontWeight: "lighter", fontSize: "20px", padding: "10px 20px" }}>
                                                {item.displayText}
                                            </div>
                                            <div style={{ fontWeight: "bold", marginTop: "20px" }}>
                                                <span style={{ fontSize: "18px", verticalAlign: "top" }}>$</span>
                                                {item.price}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row> : <></>}
                        </> :
                        <>
                            <Row style={{ marginTop: "10px" }}>
                                <Col style={{ position: "relative" }}>
                                    <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px", width: "100%" }} label={`Search`} />
                                </Col>
                                <Col xs={'auto'}>
                                    <ButtonReact variant='primary' onClick={this.setSearchTokHandle} style={{ height: "100%" }}>
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                                    </ButtonReact>
                                </Col>
                            </Row>
                            {LoadActive ?
                                <WaitSpinner />
                                :
                                <>

                                    {items.length == 0 ?
                                        <div style={{ fontWeight: "bold", textAlign: "center" }}>No available Tok Handles.</div> :
                                        <Row>
                                            {items.map((item, index) => (
                                                <Col xs={6} style={{ padding: "unset" }}>
                                                    <div onClick={this.purchaseHandle} data-id={item["id"]} data-pk={item["pk"]} className={'tokhandle-item'} style={{ color: item["color"] ? "white" : "black", backgroundColor: item["color"], border: item["color"] ? "unset" : "", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                        {item.id}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default TokHandleContainer;