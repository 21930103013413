// import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from 'react'  
const apiKey = process.env.REACT_APP_API_KEY;
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "tokket-app.firebaseapp.com",
  databaseURL: "https://tokket-app.firebaseio.com",
  projectId: "tokket-app",
  storageBucket: "tokket-app.appspot.com",
  messagingSenderId: "797656616625",
  appId: "1:797656616625:web:be2f184cc9d7fb80e45c90"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export default app;