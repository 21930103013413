import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from '@mui/system';
import './../App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import TokView from './TokView';
import { getClassTok } from '../services/classServices';
import { getToken } from '../services/accountServices';
import WaitSpinner from './WaitSpinner';
import { Tok } from '../interfaces/TokInterface';
interface States {
    item: Tok;
    id: string;
    pk: string;
    DataisLoaded: boolean;
}

interface Props {
    id: string;
    pk: string;
}

class TokInfoView extends React.Component<Props, States> {

    // Constructor
    constructor(props) {
        super(props);
        const id = props.id;
        const pk = props.pk;
        this.state = {
            item: null,
            id: id,
            pk: pk,
            DataisLoaded: false
        };
        this.addViewTok = this.addViewTok.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.getData();
    }

    async getData() {
        const { id, pk } = this.state;
        await getToken();
        const response = await getClassTok(id, pk);
        if(response){
            if (response.status == 200) {
                this.setState({
                    DataisLoaded: true,
                    item: response.data.result
                });
            }
        }
    }

    addViewTok(tok) {
        //this.props.receiveData(tok);
    }

    render() {
        const { DataisLoaded, item } = this.state;

        if (!DataisLoaded) return <WaitSpinner />;

        return (
            <TokView tok={item} groupId={undefined} linkZIndex={undefined} items={undefined} deleteTok={undefined} updateTok={undefined} />
        );
    }
}
export default TokInfoView; 