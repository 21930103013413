import React from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useState } from 'react';
import { faImage, faTimes, faPenToSquare, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from "react";
import Cropper from "react-cropper";
import "./css/cropper.css";
import { addTokPak, updateTokPak } from '../services/classServices';
import { getToken } from '../services/accountServices';


const MySwal = withReactContent(Swal);
const localUser = localStorage.getItem("userdata");
const bullets = [
    {
        value: '',
        label: 'Choose Bullet Type',
    },
    {
        value: 'bullet',
        label: 'Bullets',
    },
    {
        value: 'number',
        label: 'Numbers',
    }
];
const types = [
    {
        value: '',
        label: 'Choose Tok Pak Type',
    },
    {
        value: 0,
        label: 'Paper',
    },
    {
        value: 1,
        label: 'Presentation',
    },
    {
        value: 2,
        label: 'Practice Test',
    }
];
const localUserJson = localUser ? JSON.parse(localUser) : [];
const emptyTokPak = {
    label: "tokpak",
    name: "",
    classtoks: [],
    bullet_kind: "",
    tokpak_type: null,
    is_public: true,
    group_id: "",
    group: {},
    user_id: localUserJson["id"] ? localUserJson["id"] : "",
    is_edited: false,
    user_photo: localUserJson["user_photo"] ? localUserJson["user_photo"] : "",
    user_display_name: localUserJson["display_name"] ? localUserJson["display_name"] : "",
}
const emptyToks = [[], [], [], [], []];
const AddEditTokPak = (props) => {
    const groupId = props.groupId;
    const communityId = props.communityid;
    const addTokPakTrigger = props.addTokPakRef;
    useEffect(() => {
        async function fetchToken() {
            await getToken();
        }
        fetchToken();
    }, []);
    const [show, setShow] = useState(false);
    const [tokPak, setTokPak] = useState(props.tokpak ? props.tokpak : emptyTokPak);
    const [toks, SetToks] = useState(props.tokpak ? props.tokpak["toks"] ? props.tokpak["toks"] : emptyToks : emptyToks);
    const [editMode, setEditMode] = useState(props.tokpak ? props.tokpak["id"] ? true : false : false);
    const handleClose = () => {
        setShow(false);
        setTokPak(props.tokpak ? props.tokpak : emptyTokPak);
    };
    const handleOpen = () => {
        setShow(true);
    };
    const SetTokPakValues = (e) => {
        var value = e.target.value;
        const dataType = e.target.name;
        var tempTokPak = tokPak;
        tempTokPak[dataType] = value;
        setTokPak(tokPak => ({
            ...tokPak,
            ...tempTokPak
        }));
    }

    const submitTokPak = (e) => {
        MySwal.fire({
            title: <p>{editMode ? "Updating" : "Adding"} Tok Pak</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        var tempTokPak = tokPak;
        if (editMode) {
            updateTokPak(tempTokPak).then(result => {
                if (result) {
                    /*if (result.data.statusCode == 200) {
                        MySwal.update({
                            title: <p>Tok Pak Updated</p>,
                            icon: "success"
                        });
                        try {
                            props.updateTokPak(true);
                        } catch (err) { }
                        setTokPak(tokPak => ({
                            ...tokPak,
                            ...tempTokPak
                        }));
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }*/
                }
            }).catch(error => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        } else {
            if (groupId) {
                tempTokPak["group_id"] = groupId;
                tempTokPak["isgroup"] = true;
            }
            addTokPak(tempTokPak).then(result => {
                /*
                if (result.data["statusCode"]) {
                    if (result.data.statusCode == 200) {
                        MySwal.update({
                            title: <p>Tok Pak Added</p>,
                            icon: "success"
                        });
                        try {
                            props.newTokPak(result.data.resource);
                        } catch (err) { }
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
                */
            }).catch(error => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }
    }
    return (
        <>
            <Button ref={addTokPakTrigger} onClick={handleOpen} hidden>Add Tok Pak test</Button>
            <Modal size='lg' show={show} onHide={handleClose} style={{ zIndex: "1205" }}>
                <Modal.Header>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} onClick={handleClose} icon={faTimes} />
                        Add Tok Pak
                        <Button variant="success" style={{ display: "flex", alignItems: "center" }} onClick={submitTokPak}>
                            <div className={'parrot-write-icon'}></div>
                            <span>Post</span>
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <TextField style={{ width: "100%" }} onChange={SetTokPakValues} value={tokPak["name"] ? tokPak["name"] : ""} name="name" label="Title" inputProps={{ maxLength: 200 }} />
                            <p className={'character-count'}>{tokPak["name"] ? tokPak["name"].length : "0"}/200</p>
                        </Col>
                        <Col xs={12} style={{ marginBottom: "20px" }}>
                            <TextField style={{ width: "100%" }} onChange={SetTokPakValues} value={tokPak["tokpak_type"] || tokPak["tokpak_type"] == 0 ? tokPak["tokpak_type"] : ""} select label="Type" name="tokpak_type">
                                {types.map((type, index) => (
                                    <MenuItem value={type["value"]}>
                                        {type["label"]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={12} style={{ marginBottom: "20px" }}>
                            <TextField style={{ width: "100%" }} onChange={SetTokPakValues} value={tokPak["bullet_kind"] ? tokPak["bullet_kind"] : ""} select label="Bullet Style" name="bullet_kind">
                                {bullets.map((type, index) => (
                                    <MenuItem value={type["value"]}>
                                        {type["label"]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                    </Row>
                    <Row>
                        {toks.map((tok, index) => (
                            <Col xs={12} className={'row'}>
                                <div>Page {index + 1}</div>
                                <Col className={'row'}>
                                    <Button className={'col-4'}>Paste Tok</Button>
                                    <Button className={'col-4'}>Paste Tok</Button>
                                    <Button className={'col-4'}>Paste Tok</Button>
                                </Col>
                            </Col>
                        ))}
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default AddEditTokPak;