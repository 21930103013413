import React from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useState } from 'react';
import { faImage, faTimes, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from "react";
import Cropper from "react-cropper";
import "./css/cropper.css";
import "./css/AddEditClassGroup.css";
import { updateGroup, addGroup, patchGroup } from '../services/classServices';
import { getToken } from '../services/accountServices';
import { cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import { Group } from '../interfaces/GroupInterface';
interface Props {
  group?: Partial<Group>;
  triggerBtn?: string;
  customBtnStyle?: React.CSSProperties;
  customBtnClass?: string;
  icon?: string;
  CommunityId?: string;
  Level0?: string;
  Level1?: string;
  Level2?: string;
  Level3?: string;
  receiveData: (data: Group) => void;
  updateGroup: (group: Group) => void;
}
const AddEditClassGroup = (props: Props) => {
  const MySwal = withReactContent(Swal);
  const localUser = localStorage.getItem("userdata");

  useEffect(() => {
    async function fetchToken() {
      await getToken();
    }
    fetchToken();
  }, []);

  const emptyGroup = {
    label: "group",
    name: "",
    group_kind: "",
    description: "",
    school: "",
    image: "",
    is_community: false,
    community_id: "",
    level0: "",
    level1: "",
    level2: "",
    level3: "",
    level4: "",
    user_id: null
  } as Group;
  const groupTypes = [
    {
      value: '',
      label: 'Choose...',
    },
    {
      value: 'Faith',
      label: '• Faith',
    },
    {
      value: 'Class',
      label: '• Class',
    },
    {
      value: 'Club',
      label: '• Clubs',
    },
    {
      value: 'Study',
      label: '• Study',
    },
    {
      value: 'Team',
      label: '• Teams',
    },
    {
      value: 'Other',
      label: '• Other',
    },
  ];
  var defaultGroupSet = emptyGroup;
  var customBtnStyle = {};
  var customBtnClass = "";
  var triggerBtn = "Add Group";
  var iconBtn: React.ReactElement;
  var mode = "add";
  if (props.group?.id) {
    mode = "edit";
    defaultGroupSet = props.group as typeof emptyGroup;
    defaultGroupSet = {
      ...emptyGroup,
      ...defaultGroupSet
    }
  }
  if (props.triggerBtn) {
    triggerBtn = props.triggerBtn;
  }
  if (props.customBtnStyle) {
    customBtnStyle = props.customBtnStyle;
  }
  if (props.customBtnClass) {
    customBtnClass = props.customBtnClass;
  }
  if (props.icon) {
    switch (props.icon) {
      case "pen":
        iconBtn = <FontAwesomeIcon icon={faPenToSquare} />
        break;
      case "parrot-pen":
        iconBtn = <div className={"parrot-write-icon"}></div>
        break;
    }
  }
  var communityId = "";
  if (props.CommunityId) {
    communityId = props.CommunityId;
  }
  var level0 = "";
  var level1 = "";
  var level2 = "";
  var level3 = "";

  if (props.Level0) {
    level0 = props.Level0;
  }

  if (props.Level1) {
    level1 = props.Level1;
  }

  if (props.Level2) {
    level2 = props.Level2;
  }

  if (props.Level3) {
    level3 = props.Level3;
  }
  const cropperRef = useRef(null);
  const [group, setGroup] = useState(cloneDeep(defaultGroupSet));
  const [groupOG, setGroupOG] = useState(cloneDeep(defaultGroupSet));
  const [show, setShow] = useState(false);
  const [cropperShow, setCropperShow] = useState(false);
  const [cropperSrc, setCropperSrc] = useState(null);
  const handleClose = () => {
    setShow(false);
    setGroup(defaultGroupSet);
  };

  const SetGroupValues = (e) => {
    var value = e.target.value;
    const dataType = e.target.name;
    var tempGroup = group;
    tempGroup[dataType] = value;
    setGroup(group => ({
      ...group,
      ...tempGroup
    }));
  }
  const removeImage = (e) => {
    var tempGroup = group;
    tempGroup["image"] = "";
    setGroup(group => ({
      ...group,
      ...tempGroup
    }));
  }
  const onImageSet = (e) => {
    setCropperSrc(URL.createObjectURL(e.currentTarget.files[0]));
    setCropperShow(true);
  }
  const handleCropperClose = () => {
    setCropperShow(false);
  };
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    var tempGroup = group;
    tempGroup["image"] = cropper.getCroppedCanvas().toDataURL();
    setCropperShow(false);
    setGroup(group => ({
      ...group,
      ...tempGroup
    }));
  };
  function addGroupToList(data) {
    props.receiveData(data)
  }
  const addEditGroupPost = () => {
    if (mode == "edit") {
      MySwal.fire({
        title: <p>Updating Group</p>,
        allowOutsideClick: false
      });
      MySwal.showLoading();
      let patchObject = diff(groupOG, group);
      let patchObjectKeys = Object.keys(patchObject);
      for (let x = 0; x < patchObjectKeys.length; x++) {
        patchObject[patchObjectKeys[x]] = cloneDeep(group[patchObjectKeys[x]]);
      }
      patchGroup(patchObject, groupOG["id"], groupOG["pk"]).then(response => {
        if (response) {
          if (response.status == 200) {
            MySwal.update({
              title: <p>Success</p>,
              icon: "success"
            });
            props.updateGroup(group);
            handleClose();
          } else {
            MySwal.update({
              title: <p>An Error Occured</p>,
              icon: "warning"
            });
          }
        }
      }).catch(response => {
        MySwal.update({
          title: <p>An Error Occured</p>,
          icon: "warning"
        });
      }).finally(() => {
        MySwal.hideLoading();
      });
    } else {
      MySwal.fire({
        title: <p>Adding {level0 ? "Community" : "Group"}</p>,
        allowOutsideClick: false
      });
      MySwal.showLoading();
      let tempGroup = cloneDeep(group);
      let feedId = "";
      if (level0) {
        tempGroup["level0"] = level0.toLowerCase().charAt(0).toUpperCase() + level0.slice(1);
        tempGroup["is_community"] = true;
        if (level1) {
          tempGroup["level1"] = level1.toLowerCase().charAt(0).toUpperCase() + level1.slice(1);
        }
        if (level2) {
          tempGroup["level2"] = level2.toLowerCase().charAt(0).toUpperCase() + level2.slice(1);
        }
        if (level3) {
          tempGroup["level3"] = level3.toLowerCase().charAt(0).toUpperCase() + level3.slice(1);
        }
      }

      if (communityId) {
        tempGroup["community_id"] = communityId;
      }

      addGroup(tempGroup, feedId).then(response => {
        if (response) {
          if (response.status == 200) {
            MySwal.update({
              title: <p>Success</p>,
              icon: "success"
            });
            var groupReturn = response.data.result;
            const localUser = localStorage.getItem("userdata");
            if (localUser) {
              const localUserJson = JSON.parse(localUser);
              if (localUserJson["id"]) {
                if (localUserJson["id"] == response.data.result["user_id"]) {
                  groupReturn["user_photo"] = localUserJson["user_photo"];
                  groupReturn["user_display_name"] = localUserJson["display_name"];
                }
              }
            }
            addGroupToList(groupReturn);
          } else {
            MySwal.update({
              title: <p>An Error Occured</p>,
              icon: "warning"
            });
          }
        }
      }).catch(response => {
        MySwal.update({
          title: <p>An Error Occured</p>,
          icon: "warning"
        });
      }).finally(() => {
        MySwal.hideLoading();
      });
    }
  };
  return (
    <>
      <div style={props.icon ? { display: "flex", alignItems: "center" } : customBtnStyle} className={customBtnClass} onClick={(e) => setShow(true)}>
        {iconBtn}
        {triggerBtn}
      </div>
      <Modal size='lg' show={cropperShow} onHide={handleCropperClose} style={{ zIndex: "1206" }}>
        <Modal.Header>
          <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={handleCropperClose} />
            Cropper
            <Button variant="success" onClick={onCrop}>Crop</Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            src={cropperSrc}
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={16 / 9}
            ref={cropperRef}
          />

        </Modal.Body>
      </Modal>
      <Modal size='lg' show={show} onHide={handleClose} style={{ zIndex: "1205" }}>
        <Modal.Header>
          <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} onClick={handleClose} icon={faTimes} />
            {mode == "add" ? <>Add</> : <>Edit</>} {group["is_community"] ? "Community" : "Group"}
            <Button variant="success" onClick={addEditGroupPost} style={{ display: "flex", alignItems: "center" }}>
              <div className={'parrot-write-icon'}></div>
              <span>{mode == "add" ? <>Post</> : <>Update</>}</span>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {level0 ?
              <b className={"col-12"}>
                Community: {level0 ? level0.toLowerCase().charAt(0).toUpperCase() + level0.slice(1) : ""} {level1 ? ` > ${level1.toLowerCase().charAt(0).toUpperCase() + level1.slice(1)}` : ""} {level2 ? ` > ${level2.toLowerCase().charAt(0).toUpperCase() + level2.slice(1)}` : ""} {level3 ? ` > ${level3.toLowerCase().charAt(0).toUpperCase() + level3.slice(1)}` : ""}
              </b> : ""
            }
            <Col className={"col-12"}>
              <TextField style={{ width: "100%" }} name="name" value={group["name"]} onChange={SetGroupValues} label="Group Name" inputProps={{ maxLength: 35 }} />
              <p className={'character-count'}>{group["name"].length}/35</p>
            </Col>
            {!level0 && !group["is_community"] && !group["community_id"] && !communityId ?
              <Col className={"col-12"} style={{ marginBottom: "20px" }}>
                <TextField style={{ width: "100%" }} select label="Group Type" value={group["group_kind"]} name="group_kind" onChange={SetGroupValues}>
                  {groupTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col> : ""
            }

            <Col className={"col-12"}>
              <TextField style={{ width: "100%" }} name="description" value={group["description"]} onChange={SetGroupValues} label="Description" inputProps={{ maxLength: 200 }} />
              <p className={'character-count'}>{group["description"].length}/200</p>
            </Col>
            {!level0 && !group["is_community"] && !group["community_id"] && !communityId ?
              <Col className={"col-12"}>
                <TextField style={{ width: "100%" }} name="school" value={group["school"]} onChange={SetGroupValues} label="Institution/School" inputProps={{ maxLength: 50 }} />
                <p className={'character-count'}>{group["school"].length}/50</p>
              </Col> : ""
            }
            <Col className={"col-12"} style={{ color: "#818181", fontWeight: "300" }}>
              <div style={{ fontSize: "1.25rem" }}>Select a PNG or JPEG image, max size 20 MB (optional)</div>
              <div style={{ fontSize: "16px" }}>Picture will be displayed as a square or circle - make sure to crop before uploading.</div>
            </Col>
            {group["image"] ?
              <Row className={"col-12"}>
                <Col>Selected Image:</Col>
                <Col className={"col-12"} style={{ marginTop: "top" }}><img src={group["image"]} style={{ maxWidth: "100%" }} /></Col>
                <Button variant='primary' style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)", width: "max-content", marginTop: "top" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                  Browse...
                  <input type="file" accept="image/*" onChange={onImageSet} hidden />
                </Button>
                <Button onClick={removeImage} variant='primary' style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)", width: "max-content", marginTop: "top" }}>Remove Image</Button>
              </Row> :
              <Row className={"col-12"}>
                <Button variant='primary' style={{ marginLeft: "calc(var(--bs-gutter-x) * .5)", width: "max-content", marginTop: "top" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                  Browse...
                  <input type="file" accept="image/*" onChange={onImageSet} hidden />
                </Button>
              </Row>
            }
            <Row className={"col-12"} style={{ marginTop: "20px" }}>
              <div style={{ width: "max-content" }}>
                <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" style={{ width: "54px" }} />
              </div>
              <Col>
                <div className={'speecbubble-darkmode-color'}>Add members by clicking on the Invite Users button in the Group page</div>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddEditClassGroup;