import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TokChannel from './pages/TokChannel';
import AboutUs from './pages/AboutUs';
import ClassToks from './pages/ClassToks';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PasswordReset from './firebase/PasswordReset';
import ChangePassword from './firebase/ChangePassword';
import Faq from './pages/Faq';
import ClassGroup from './pages/ClassGroup';
import "react-image-gallery/styles/css/image-gallery.css";
import 'react-photo-view/dist/react-photo-view.css';
import { useLocalStorage } from 'usehooks-ts';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Main() {
  const [firebaseUser, setFirebaseUser] = useLocalStorage("authUser", []);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    if (firebaseUser === null || firebaseUser.length == 0) return false;
    return true;
  });
  return <>
    <BrowserRouter>
      <Routes>
        <Route index element={<App/>} />

        <Route path="/tokchannels/:level1" element={<TokChannel />} />
        <Route path="/tokchannels/:level1/:level2" element={<TokChannel />} />
        <Route path="/tokchannels/:level1/:level2/:level3" element={<TokChannel />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/tokchannels/tok/:tokid/:tokpk" element={<TokChannel />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/forgotpassword" element={<PasswordReset />} />
        <Route path="/resetpassword" element={<ChangePassword />} />
        
        <Route path="/classtoks" element={<ClassToks />} />
        <Route path="/classchannels/:level1" element={<ClassToks />} />
        <Route path="/classchannels/:level1/:level2" element={<ClassToks />} />
        <Route path="/classsets" element={<ClassToks />} />
        <Route path="/classgroups" element={<ClassToks />} />
        <Route path="/group/:id" element={<ClassGroup />} />
        <Route path="/group/:id/:pk" element={<ClassGroup />} />
        <Route path="/tokhandles" element={<ClassToks />} />
        <Route path="/classtoks/set/:id/:pk" element={<ClassToks />} />
        <Route path="/classtoks/tok/:id/:pk" element={<ClassToks />} />
        <Route path="/bookmarks" element={<TokChannel />} />
        {/* 
        <Route path="/classtoks" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/classchannels/:level1" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/classchannels/:level1/:level2" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/classsets" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/classgroups" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/group/:id" element={isAuthenticated? <ClassGroup />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/group/:id/:pk" element={isAuthenticated? <ClassGroup />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/tokhandles" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/classtoks/set/:id/:pk" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/classtoks/tok/:id/:pk" element={isAuthenticated? <ClassToks />: <Navigate to="/" state={{ callLogin: true }} />} />
        <Route path="/bookmarks" element={isAuthenticated? <TokChannel />: <Navigate to="/" state={{ callLogin: true }} />} />*/}
        {/* <Route path="/login" element={<LoginPage />} /> */}
      </Routes>
    </BrowserRouter></>
}
root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
