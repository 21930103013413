import React from 'react'
import '../styles.css'
import './css/TokPaksContainer.css';
import ButtonReact from 'react-bootstrap/Button';
import { faChalkboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dropdown from 'react-bootstrap/Dropdown';
const TokPakItem = (props) => {
    var item = props.item;
    var color = "";
    var type = "";
    switch (item["tokpak_type"]) {
        case 1:
            color = "#f711ec";
            type = "Presentation";
            break;
        case 2:
            color = "#ffc000";
            type = "Practice Test";
            break;
        default:
            color = "#4472c4";
            type = "Papers";
            break;
    }
    const openPreview = () =>{
        props.openPreview(item);
    }
    const deleteItem = () =>{
        props.deleteItem(item);
    }
    const editItem = () =>{
        props.editItem(item);
    }
    const menuOpen = (event) => {
        event.stopPropagation();
    }
    return (
        <Col className={'row'} xs={4} style={{ margin: "unset", padding: "unset" }} onClick={openPreview}>
            <Col style={{ margin: "5px", borderRadius: "5px", border: "1px solid rgba(0,0,0,.125)", padding: "unset", height: "fit-content" }}>
                <div style={{ backgroundColor: color, color: "white", display: "flex", alignItems: "center", padding: "0.5rem 1.25rem", borderRadius: "5px 5px 0px 0px" }}>
                    {type}
                    <FontAwesomeIcon icon={faChalkboard} style={{ marginLeft: "auto" }} />
                </div>
                <div style={{ padding: "1.25rem 1.25rem 0" }}>
                    <h3 style={{overflowWrap: "anywhere"}}>
                        {item["name"] ? item["name"] : ""}
                    </h3>
                    {item["classtoks"] ?
                        <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px" }}>
                            {item["classtoks"].map((classtok) => (
                                <ListItem style={{ padding: "unset", display: "list-item" }}>
                                    <ListItemText className={'elipsis-one-line-parent'}
                                        primary={classtok["primary_text"]}
                                        style={{ margin: "unset" }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        : ""}
                </div>
                <div style={{ padding: "0 1.25rem 1.25rem", display: "flex" }}>
                    <ButtonReact variant='primary'>
                        {type}
                    </ButtonReact>
                    <Dropdown style={{ width: "fit-content", marginLeft: "auto" }} onClick={menuOpen}>
                        <Dropdown.Toggle variant={'success'}>
                            Manage
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={editItem}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={deleteItem}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Col>
        </Col>
    )
}

export default TokPakItem;