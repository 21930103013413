import React from 'react'
import '../styles.css'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const CommunityTile = (props) => {
    const card = props.card;
    const color = props.color;
    function cardSendBack() {
        props.receiveData(card)
    }
    return (
        <Grid className={`tile-${card.id}-${card.pk}`} item={null} key={card} xs={12} sm={6} md={4} lg={4} >
            <Container onClick={cardSendBack} style={{height: "175px", color: "white", backgroundColor: `#${color}`, display: "grid", alignItems: "center", justifyContent: "center", padding: "8px", alignContent: "center", borderRadius: "15px", cursor: "pointer"}}>
            <Container style={{fontSize: "24px", textAlign:"center"}}>{card["secondary_text"]? card["secondary_text"]: ""}</Container>
                <Container style={{fontWeight: "bold",fontSize: "28px", textAlign:"center"}}>{card["name"]? card["name"]: ""}</Container>
                <Container style={{fontSize: "14px", textAlign:"center"}}>0 Toks, 0 Tok Groups</Container>
            </Container>
        </Grid>
    )
}

export default CommunityTile;