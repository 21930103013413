import React from 'react'
import '../styles.css'
import './css/NavbarNotification.css'
import WaitSpinner from './WaitSpinner';
import { getClassGroupRequests, getNotifications, readNotification, patchRequest } from '../services/classServices';
import { getSetUserId, getToken } from '../services/accountServices';
import { createRef } from 'react';
import { MenuList, } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Typography from "@mui/material/Typography";
import { notifcationMsg } from '../services/helperTools';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import { GroupRequest } from '../interfaces/GroupRequestInterface';
import { Group } from '../interfaces/GroupInterface';
const MySwal = withReactContent(Swal);
interface States {
    items: GroupRequest[];
    notifications: GroupRequest[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    ActiveTab: number;
    NotificationLoadActive: boolean;
    NotificationLoadMoreActive: boolean;
    notificationContinuationToken: string;
    NotificationUnreadCount: number;
    notificationAnchor: Element;
    ActiveTopBar: number;
    resultData: { [key: string]: any };
}

interface Props {
    groupJoinedSet: (group: Group) => void;
    query: { [key: string]: any };
}
class NavbarNotification extends React.Component<Props, States> {
    loadmoretrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        var query = props.query ? props.query : {};

        this.state = {
            items: [],
            notifications: [],
            LoadActive: false,
            continuationToken: null,
            query: query,
            LoadMoreActive: false,
            ActiveTab: 0,
            NotificationLoadActive: false,
            NotificationLoadMoreActive: false,
            notificationContinuationToken: null,
            NotificationUnreadCount: 0,
            notificationAnchor: null,
            ActiveTopBar: 0,
            resultData: {}
        };

        this.reload = this.reload.bind(this);
        this.loadmoretrigger = createRef();
        this.loadmore = this.loadmore.bind(this);

        this.acceptRequest = this.acceptRequest.bind(this);
        this.declineRequest = this.declineRequest.bind(this);

        this.setTab = this.setTab.bind(this);

        this.setNotificationRead = this.setNotificationRead.bind(this);

        this.openNotificationMenu = this.openNotificationMenu.bind(this);
        this.closeNotificationMenu = this.closeNotificationMenu.bind(this);

        this.loadMoreScroll = this.loadMoreScroll.bind(this);

        this.setTopBar = this.setTopBar.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {

        this.setState({
            LoadActive: true,
            NotificationLoadActive: true
        });
        this.getData({});
        this.getNotificationData({});
    }
    async getData(customQuery) {
        var { query, items } = this.state;
        let userid = await getSetUserId();
        if (userid) {
            query["receiver_id"] = userid;
        }
        await getToken();
        if (customQuery) {
            query = customQuery;
            if (!query["pagination"]) {
                this.setState({
                    items: []
                });
            }
        }
        const response = await getClassGroupRequests(query);
        var itemResult = response? response.data["result"]: new Array;
        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            resultData: response["data"],
            continuationToken: response["data"]["pagination_token"],
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
    }

    async getNotificationData(customQuery) {
        try {
            var { query, notifications } = this.state;
            if (customQuery) {
                query = customQuery;
                if (!query["pagination"]) {
                    this.setState({
                        notifications: []
                    });
                }
            }
            const response = await getNotifications(query, "", "");
            var itemResult = response? response.data["result"]: new Array;
            try {
                if (query["pagination"]) {
                    itemResult = notifications.concat(itemResult);
                }
            } catch (err) { }
            let unread = 0;
            for (let x = 0; x < itemResult.length; x++) {
                if (!itemResult[x]["read"]) {
                    unread++;
                }
            }
            this.setState({
                notificationContinuationToken: response["data"]["pagination_token"],
                notifications: itemResult,
                NotificationUnreadCount: unread,
                NotificationLoadActive: false,
                NotificationLoadMoreActive: false
            });
        } catch (err) {
            this.setState({
                notificationContinuationToken: "",
                NotificationLoadActive: false,
                NotificationLoadMoreActive: false
            });
        }
    }

    loadmore() {
        var { continuationToken, notificationContinuationToken, ActiveTab, query } = this.state;
        let LoadMoreActive = false;
        let NotificationLoadMoreActive = false;
        if (ActiveTab == 0) {
            NotificationLoadMoreActive = true;
        } else {
            LoadMoreActive = true;
        }
        this.setState({
            LoadMoreActive: LoadMoreActive,
            NotificationLoadMoreActive: NotificationLoadMoreActive
        }, () => {
            if (query) {
                if (ActiveTab == 0) {
                    query["pagination"] = notificationContinuationToken;
                } else {
                    query["pagination"] = continuationToken;
                }
            } else {

                if (ActiveTab == 0) {
                    query = { "pagination": notificationContinuationToken }
                } else {
                    query = { "pagination": continuationToken }
                }
            }
            if (ActiveTab == 0) {
                this.getNotificationData(query);
            } else {
                this.getData(query)
            }
        });
    }

    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    acceptRequest(e) {
        const { items } = this.state;
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        var selectedRequest = items.find(x => x.id === id && x.pk === pk);
        if (selectedRequest) {
            const group = {
                id: selectedRequest["group_id"],
                pk: selectedRequest["group_pk"],
                user_id: selectedRequest["group_owner_id"],
                group_kind: selectedRequest["group_kind"] ? selectedRequest["group_kind"] : "",
                image: selectedRequest["image"] ? selectedRequest["image"] : "",
                school: selectedRequest["school"] ? selectedRequest["school"] : "",
                name: selectedRequest["name"] ? selectedRequest["name"] : "",
                members: selectedRequest["members"] ? Number(selectedRequest["members"]) + 1 : 2
            }
            let newFeedId = selectedRequest["status"] == 1 ? selectedRequest["receiver_id"] : selectedRequest["status"] == 0 ? selectedRequest["sender_id"] : "";
            MySwal.fire({
                title: <p>Would you like to accept the group invite.</p>,
                allowOutsideClick: false,
                icon: "info",
                confirmButtonText: "Accept",
                cancelButtonText: "Decline",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    MySwal.fire({
                        title: <p>Accepting Request</p>,
                        allowOutsideClick: false
                    });
                    MySwal.showLoading();
                    patchRequest(id, pk, 2, group, newFeedId).then(response => {
                        if (response) {
                            if (response.status == 200) {
                                MySwal.update({
                                    title: <p>Success</p>,
                                    icon: "success"
                                });
                                var { items } = this.state;
                                for (let x = 0; x < items.length; x++) {
                                    if (items[x].id == id && items[x].pk == pk) {
                                        let tempRequest = items[x];
                                        tempRequest["status"] = 2;
                                        tempRequest["message"] = tempRequest["sender_displayname"] + " has joined the group";
                                        items[x] = tempRequest;
                                        break;
                                    }
                                }
                                try {
                                    this.props.groupJoinedSet(group as Group);
                                } catch (err) { }

                                this.setState({
                                    items: items,
                                });
                            } else {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }
                        }
                    }).catch(response => {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }).finally(() => {
                        MySwal.hideLoading();
                    })
                }
            })
        } else {
            MySwal.fire({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }
    }
    declineRequest(e) {
        const { items } = this.state;
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        var selectedRequest = items.find(x => x.id === id && x.pk === pk);
        if (selectedRequest) {
            const group = {
                id: selectedRequest["group_id"],
                pk: selectedRequest["group_pk"],
                user_id: selectedRequest["group_owner_id"],
                group_kind: selectedRequest["group_kind"] ? selectedRequest["group_kind"] : "",
                image: selectedRequest["image"] ? selectedRequest["image"] : "",
                school: selectedRequest["school"] ? selectedRequest["school"] : "",
                name: selectedRequest["name"] ? selectedRequest["name"] : "",
                members: selectedRequest["members"] ? Number(selectedRequest["members"]) : 1
            }
            MySwal.fire({
                title: <p>Declining Request</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            patchRequest(id, pk, 3, group, "").then(response => {
                if (response) {
                    if (response.status == 200 && response.data.resource.status == 3) {
                        MySwal.update({
                            title: <p>Success</p>,
                            icon: "success"
                        });
                        var { items } = this.state;
                        for (let x = 0; x < items.length; x++) {
                            if (items[x].id == id && items[x].pk == pk) {
                                items[x] = response.data.resource;
                                break;
                            }
                        }
                        this.setState({
                            items: items,
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(response => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        } else {
            MySwal.fire({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }
    }

    setTab(e) {
        const tab = Number(e.currentTarget.getAttribute("data-tab"));
        this.setState({
            ActiveTab: tab
        });
    }

    setTopBar(e) {
        const tab = Number(e.currentTarget.getAttribute("data-tab"));
        this.setState({
            ActiveTopBar: tab
        });
    }
    setNotificationRead(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        var { notifications, NotificationUnreadCount } = this.state;
        if (notifications) {
            for (let x = 0; x < notifications.length; x++) {
                if (notifications[x].id == id && notifications[x].pk == pk) {
                    this.readNotificationSet(id, pk);
                    notifications[x]["read"] = true;
                    break;
                }
            }
        }
        this.setState({
            notifications: notifications,
            NotificationUnreadCount: NotificationUnreadCount - 1
        });
    }
    readNotificationSet(id, pk) {
        readNotification(id, pk).then(response => {
        }).catch(response => { })
    }
    openNotificationMenu(e) {
        this.setState({
            notificationAnchor: e.currentTarget
        })
    }
    closeNotificationMenu() {
        this.setState({
            notificationAnchor: null
        })
    }
    loadMoreScroll(e) {
        const { LoadMoreActive, NotificationLoadMoreActive, continuationToken, notificationContinuationToken, ActiveTab } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        var activeToken = notificationContinuationToken;
        var activeLoadMore = NotificationLoadMoreActive;
        if (ActiveTab == 1) {
            activeToken = continuationToken;
            activeLoadMore = LoadMoreActive;
        }
        if ((currentScrollLocation > maxHeight) && !activeLoadMore && activeToken) {
            this.loadmore();
        }
    }
    render() {
        var { ActiveTopBar, items, LoadActive, ActiveTab, NotificationLoadActive, notifications, NotificationUnreadCount, notificationAnchor, LoadMoreActive, NotificationLoadMoreActive } = this.state;
        return (
            <>
                <Box>
                    <div className={'notifcation-btn flex-center menu-option'} onClick={this.openNotificationMenu}>
                        <NotificationsIcon style={{ fontSize: "35px" }} />
                        {NotificationUnreadCount > 0 ?
                            <div className={'notification-count'}>
                                {NotificationUnreadCount >= 9 ? 9 : NotificationUnreadCount}
                                {NotificationUnreadCount > 9 ? <span className={'notification-count-more9'}>+</span> : ""}
                            </div> : ""
                        }
                    </div>
                </Box>
                <Menu
                    sx={{ mt: '45px' }}
                    anchorEl={notificationAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(notificationAnchor)}
                    onClose={this.closeNotificationMenu}
                    className={'notification-menu'}
                >
                    <Row style={{ margin: "unset" }}>
                        <Col xs={'auto'} style={{ padding: "unset" }}><Typography fontSize={22} fontWeight={600} padding={"0px 15px"} marginBottom={"5px"} >Notifications</Typography></Col>
                        <Col xs={'auto'} className={'row'} style={{ marginLeft: "auto" }}>
                            <Col onClick={this.setTopBar} data-tab="0" xs={'auto'} className={(ActiveTopBar == 0 ? 'active ' : '') + 'top-navbar-btn blue'}>Tok Channels</Col>
                            <Col onClick={this.setTopBar} data-tab="1" xs={'auto'} className={(ActiveTopBar == 1 ? 'active ' : '') + 'top-navbar-btn red'}>Toks</Col>
                            <Col onClick={this.setTopBar} data-tab="2" xs={'auto'} className={(ActiveTopBar == 2 ? 'active ' : '') + 'top-navbar-btn lightblue'}>Tok Handles</Col>
                        </Col>
                    </Row>

                    <MenuList className={'notification-item-container'} onScroll={this.loadMoreScroll}>
                        <Row style={{ margin: "0px" }}>
                            <Col data-tab="0" onClick={this.setTab} className={ActiveTab == 0 ? 'notification-tab active' : "notification-tab"} xs={'auto'}>Reactions</Col>
                            <Col data-tab="1" onClick={this.setTab} className={ActiveTab == 1 ? 'notification-tab active' : "notification-tab"} xs={'auto'}>Group Invites</Col>
                        </Row>
                        {ActiveTab == 1 ?
                            <>
                                {LoadActive ?
                                    <WaitSpinner /> :
                                    <>
                                        {items.map((request) => (
                                            <Row className={request["status"] == 0 || request["status"] == 1 ? 'notification-item-unread notification-item' : 'notification-item'}>
                                                <div className={'notification-item-avatar'} style={{ backgroundImage: request["sender_image"] ? `url(${request["sender_image"]})` : `url(https://tokketcontent.blob.core.windows.net/tokketweb/default.png)` }}></div>
                                                <Row className={'col'}>
                                                    <Col xs={true}>
                                                        <Typography fontWeight={600}>{request["sender_displayname"] ? request["sender_displayname"] : ""}</Typography>
                                                        <Typography fontSize={15}>{request["message"] ? request["message"] : ""}</Typography>
                                                    </Col>
                                                    {request["status"] == 0 || request["status"] == 1 ?
                                                        <Col xs={'auto'} className={'flex-center notification-item-btn-container'}>
                                                            <Button onClick={this.acceptRequest} variant='success' style={{ marginRight: "5px" }} data-pk={request["pk"]} data-id={request["id"]}>Accept Invite</Button>
                                                            <Button onClick={this.declineRequest} variant='danger' data-pk={request["pk"]} data-id={request["id"]}>Decline</Button>
                                                        </Col> : ""
                                                    }

                                                </Row>
                                            </Row>
                                        ))}
                                        {LoadMoreActive ?
                                            <WaitSpinner /> : ""
                                        }
                                    </>
                                }
                            </> : ""
                        }

                        {ActiveTab == 0 ?
                            <>
                                {NotificationLoadActive ?
                                    <WaitSpinner /> :
                                    <>
                                        {notifications.map((request) => (
                                            <Row className={request["read"] ? 'notification-item' : 'notification-item-unread notification-item'}>
                                                <div className={'notification-item-avatar'} style={{ backgroundImage: request["sender_photo"] ? `url(${request["sender_photo"]})` : `url(https://tokketcontent.blob.core.windows.net/tokketweb/default.png)` }}></div>
                                                <Row className={'col'}>
                                                    <Col md={true} xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                        <Typography className={'line-clamp-2'} style={{ overflowY: "hidden" }} fontWeight={600}>{request["sender_display_name"] ? request["sender_display_name"] : ""} {request["notification_text"] ? notifcationMsg(request["notification_text"]) : ""}.</Typography>
                                                    </Col>
                                                    <Col md={'auto'} xs={12} className={'flex-center notification-item-btn-container'}>
                                                        <NavLink data-id={request["id"]} data-pk={request["pk"]} onClick={this.setNotificationRead} className={request["read"] ? 'btn btn-primary' : 'btn btn-danger'} to={`/classtoks/tok/${request["item_id"]}/classtoks0`}>View</NavLink>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        ))}
                                        {NotificationLoadMoreActive ?
                                            <WaitSpinner /> : ""
                                        }
                                    </>
                                }
                            </> : ""
                        }
                    </MenuList>
                </Menu>
            </>

        );
    }
}

export default NavbarNotification;