import React from 'react'
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Button from 'react-bootstrap/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import TokketLogoWhite from './../images/TOKKET_White text transparent background.png';
import './css/Navbar.css';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import { maxWidth } from '@mui/system';
import { NavLink, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ProfileView from './ProfileView';
import { useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useEffect } from 'react';
import { useLocalStorage } from '../firebase/useLocalStorage';
import Logout from '../firebase/Logout';
import { getAuth } from 'firebase/auth';
import { getUser, getToken } from '../services/accountServices';
import NavbarNotification from './NavbarNotification';
const pages = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about" },
  { name: "Tok Channels", path: "/tokchannels/mytoks" },
  { name: "Tok Handles", path: "/tokhandles" },
  { name: "Toks", path: "/classtoks" }
];

const Navbar = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [profileView, setProfileView] = useState(false);
  const [firebaseUser, setFirebaseUser] = useLocalStorage("authUser", null);
  const [authToken, setAuthToken] = useLocalStorage("authToken", '');
  const [logindata, setLoginData] = useLocalStorage("userdata", "");
  const [token, setToken] = useState(async () => {
    if (firebaseUser === null) return "";
    var auth = getAuth();
    var user = auth.currentUser;
    if (user === null) return "";

    var nToken = await user.getIdToken(true);
    //console.log(nToken);
    setAuthToken(nToken);

    return nToken;
  });

  const loginCallTrigger = props.loginCallTrigger;
  const handleClick = () => {
    try {
      loginCallTrigger.current.click();
    } catch (err) { }
  };

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  useEffect(() => {
    async function fetchToken() {
      await getToken();
    }
    fetchToken();
  }, []);

  useEffect(() => {
    // getting stored value
    const localUser = localStorage.getItem("userdata");
    const profile = localStorage.getItem("profile");
    if (localUser === null) return;
    const localUserJson = JSON.parse(localUser);
    const profileJson = profile ? JSON.parse(profile) : [];
    if (localUserJson.id === undefined) {
      //console.log(firebaseUser);
      var authUser = firebaseUser;
      if (authUser === null) return;

      getUser(authUser.uid).then(response => {
        if(response){
          if (response["data"]["status_code"] == 200) {
            if (response["data"]["result"] != user) {
              setLoginData(response["data"]["result"]);
              setUser(response["data"]["result"]);
              const localUserJson = JSON.parse(localUser);
              return;
            }
          }
        }
      });
      //console.log("test 1");
      //if(profileJson["id"]){
      //  console.log("test 2");
      //  getUser(profileJson["id"], "InICLtwaN9gbA72OxTRpWcuiKtv1-profiles0").then(response => {
      //    console.log(response);
      //    setProfile(response["data"]["result"]);
      //  });
      //}

    }
    setProfile(profileJson);
    setUser(localUserJson);
  }, [firebaseUser]);

  const [loggedIn, setLoggedIn] = useState(() => {
    if (firebaseUser === null || firebaseUser.length == 0) return false;

    return true;
  });
  const [transparentTop, setTransparentTop] = useState(() => {
    if (props.transparentTop) {
      return props.transparentTop;
    } else {
      return false;
    }
  });
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const openProfile = () => {
    setProfileView(true);
  };
  const closeProfile = () => {
    setProfileView(false);
  };

  const groupJoinedSet = (e) => {
    props.groupJoinedSet(e)
  }
  const [isDarkMode, setDarkMode] = useState(() => {
    // getting stored value
    const localIsDarkMode = localStorage.getItem("isDarkMode");
    if (localIsDarkMode === 'true') {
      document.querySelector("body").classList.add("dark-mode");
      return true;
    } else {
      return false;
    }
  });
  const toggleDarkMode = () => {
    const toggleValue = !isDarkMode;
    setDarkMode(toggleValue);
    localStorage.setItem("isDarkMode", toggleValue.toString());
    if (toggleValue) {
      document.querySelector("body").classList.add("dark-mode");
    } else {
      document.querySelector("body").classList.remove("dark-mode");
    }
  };
  const path = "/" + window.location.pathname.split("/")[1];
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  {
    useEffect(() => {
      document.querySelector("body").addEventListener("scroll", listenToScroll);
      return () =>
        document.querySelector("body").removeEventListener("scroll", listenToScroll);
    }, [])
    const listenToScroll = () => {
      let heightToHideFrom = 65;
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      if ((heightToHideFrom - winScroll) >= heightToHideFrom) {
        setBackgroundColor("transparent")
      } else {
        setBackgroundColor("#138fc2")
      }
    };
    const updateLoginData = (e) => {
      setUser(e);
    }
    const updateProfileData = (e) => {
      setProfile(e);
    }
    return (
      <AppBar className={'dark-background-1'} position={transparentTop ? "fixed" : "sticky"} style={{ backgroundColor: transparentTop ? backgroundColor : "#138fc2", zIndex: "1205", top: "0", boxShadow: "unset", WebkitBoxShadow: "unset", transition: "background-color 0.5s ease" }} sx={{ height: { xs: '100px', md: '65px' } }}>
        <Modal className={'dark-background-2 profile-view'} show={profileView} onHide={closeProfile} style={{ zIndex: "1206" }}>
          <Modal.Header className={'dark-background-2'}>
            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
              <Typography fontWeight={"bold"} marginRight={"auto"}>View Profile</Typography>

              <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={closeProfile} />
            </div>

          </Modal.Header>
          <Modal.Body className={'dark-background-2'}>
            <ProfileView user={user} profile={profile} displayToksSets={true} changePasswordTrigger={props.changePasswordTrigger} updateProfileData={updateProfileData} updateLoginData={updateLoginData} />
          </Modal.Body>
        </Modal>
        <Toolbar>
          <img src={TokketLogoWhite} style={{ maxWidth: '187px' }} />
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, marginLeft: 'auto' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map((page) => (
                <NavLink to={page.path} className={'menu-option-mobile'} key={page.name} onClick={handleCloseNavMenu} >
                  {page.name}
                </NavLink>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 'auto' }}>
            {pages.map((page) => (
              <NavLink to={page.path} key={page.name} onClick={handleCloseNavMenu} style={{
                color: 'white',
                display: 'block',
                textTransform: 'capitalize',
                textDecoration: "none"
              }}>
                <Typography
                  sx={{
                    my: 2,
                  }}
                  className={page.path === path ? "menu-option active" : "menu-option"}
                >
                  {page.name}
                </Typography>
              </NavLink>
            ))}
          </Box>
          {loggedIn ?
            <NavbarNotification query={{ status: 4 }} groupJoinedSet={groupJoinedSet} /> : ""
          }
          <Box>
            {loggedIn ?
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className={'row menu-container-btn'} style={{ padding: '5px 25px', border: '1px solid white', borderRadius: 'unset', borderBottomRightRadius: '20px', borderTopLeftRadius: '20px', background: 'transparent' }}>
                    <div style={{ width: '35px', height: '35px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundImage: (profile["id"] ? profile["user_photo"] ? `url(${profile["user_photo"]})` : `url(${user["user_photo"]})` : user["user_photo"] ? `url(${user["user_photo"]})` : (firebaseUser.photoURL ? `url(${firebaseUser.photoURL})` : 'url("https://tokketcontent.blob.core.windows.net/tokketweb/default.png")')) }}></div>
                    <div className={'col row'} style={{ color: 'white', fontSize: '15px', fontWeight: '500', padding: 'unset', margin: "0px 5px" }}>
                      <div className={'col-12'} style={{ padding: "unset" }}>{profile["id"] ? profile["display_name"] : user["display_name"] ? user["display_name"] : firebaseUser.providerData[0].displayName}</div>
                      <div className={'col-12'} style={{ padding: "unset" }}>
                        {user["is_user_display_handle_enabled"] && user["current_handle"] ?
                          `${user["current_handle"]}` :
                          <>
                            {user["title_enabled"] && user["title_id"] ? `${user["title_id"]}` : ""}
                          </>
                        }
                      </div>
                    </div>
                    <div className={"col-auto"} style={{ margin: 'unset', padding: 'unset' }}><ExpandMoreIcon /></div>

                  </IconButton>
                </Tooltip>
                <Menu

                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu} >
                    <Typography textAlign="center" onClick={openProfile}>Profile</Typography>
                  </MenuItem>
                  <MenuItem onClick={toggleDarkMode}>
                    {isDarkMode ? "Light Mode" : "Dark Mode"}
                    <DarkModeSwitch
                      checked={isDarkMode}
                      moonColor={'black'}
                      sunColor={'black'}
                      size={15}
                      onChange={toggleDarkMode} // Add this prop
                    />
                  </MenuItem>
                  <Logout />
                </Menu>
              </>
              :
              <IconButton onClick={handleClick} sx={{ p: 0 }} className={'row menu-container-btn'} >
                <span className="loginicon">
                  Sign In
                </span>
              </IconButton>
            }

          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}
export default Navbar;