import React, { createRef } from 'react'
import Typography from "@mui/material/Typography";
import { Box } from '@mui/system';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from '@mui/material/IconButton';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles.css';
import './css/TokBack.css';
import { Col, Row } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TokBackCard from './TokBackCard';
import Button from 'react-bootstrap/Button';
import { Tok } from '../interfaces/TokInterface';
import { isNumber } from 'lodash';
const MySwal = withReactContent(Swal);

interface tokbackBlock{
    text: string;
    flip: boolean;
}

interface States {
    blockCounts: (string|number)[];
    tok: Tok;
    primary: string;
    secondary: string;
    tokbackBlocks: tokbackBlock[];
    selectedBlockCount: number;
}

interface Props {
    tok: Tok;
    closeTokBack: () => void;
}

class TokBack extends React.Component<Props, States> {
    blockContainerRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            blockCounts: ['', 3, 4, 5, 6, 7],
            tok: props.tok,
            primary: "",
            secondary: "",
            tokbackBlocks: [],
            selectedBlockCount: 0
        };

        this.blockCountSet = this.blockCountSet.bind(this);
        this.closeTokBack = this.closeTokBack.bind(this);
        this.blockContainerRef = createRef();

        this.primarySecondarySwap = this.primarySecondarySwap.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        const { tok } = this.state;
        let primary = tok["primary_text"];
        let secondary = tok["secondary_text"];
        if (tok["tok_group"] == "Detailed" || tok["tok_group"] == "List" || tok["tok_group"] == "Detail") {
            let tempTokbackBlocks = new Array;
            for (let x = 0; x < tok["details"].length; x++) {
                if (tok["details"][x] != " " && !/\s+/.test(tok["details"][x]) && tok["details"][x] != null) {
                    tempTokbackBlocks.push({
                        text: tok["details"][x],
                        flip: false
                    });
                }
            }
            this.setState({
                primary: primary,
                secondary: secondary,
                tokbackBlocks: tempTokbackBlocks
            });
        } else {
            this.setState({
                primary: primary,
                secondary: secondary,
                tokbackBlocks: []
            });
        }
    }
    blockCountSet(e) {
        var value = e.target.value;
        this.tokbackBlocksSet(value);
    }
    closeTokBack() {
        this.props.closeTokBack();
    }
    primarySecondarySwap() {
        var { tok, selectedBlockCount, primary, secondary } = this.state;
        this.setState({
            tok: tok,
            primary: secondary,
            secondary: primary
        }, () => {
            this.tokbackBlocksSet(selectedBlockCount);
        });
    }
    tokbackBlocksSet(value) {
        const { secondary } = this.state;
        let separatedSecondary = secondary.split(/\s+/).filter(n => n);
        let seperateCount = Math.ceil(separatedSecondary.length / value);

        if (value > separatedSecondary.length) {
            MySwal.fire({
                title: <p>Not compatible with {value} blocks</p>,
                icon: "info",
                allowOutsideClick: false
            });
        } else {
            let wordsPerLine = Math.ceil(separatedSecondary.length / value);
            let blocks = new Array;
            let tempTokbackBlocks = new Array;
            for (let x = 0; x < value; x++) {
                let blockTemp = new Array;
                for (let y = 0; y < wordsPerLine; y++) {
                    let value = separatedSecondary[y + x * seperateCount]
                    blockTemp.push(value)
                }
                blocks.push(blockTemp.join(" "));
                blocks = blocks.filter(n => n);
            }
            for (let x = 0; x < blocks.length; x++) {
                if (blocks[x] != " ") {
                    tempTokbackBlocks.push({
                        text: blocks[x],
                        flip: false
                    });
                }
            }
            if (tempTokbackBlocks.length != value) {
                MySwal.fire({
                    title: <p>Not compatible with {value} blocks</p>,
                    icon: "info",
                    allowOutsideClick: false
                });
            } else {
                //needed to refresh the blocks, without this it will retain previous block text.
                this.setState({
                    tokbackBlocks: [],
                    selectedBlockCount: value
                }, () => {
                    this.setState({
                        tokbackBlocks: tempTokbackBlocks
                    });
                });
            }
        }
    }
    render() {
        const { tokbackBlocks, tok, blockCounts, primary, secondary } = this.state;
        let primarySecondary = primary.split(/\s+/).filter(n => n);
        let compatibleBlocks = new Array;
        for (let x = 1; x < blockCounts.length; x++) {
            if(isNumber(blockCounts[x])){
                if (blockCounts[x] as number <= primarySecondary.length) {
                    compatibleBlocks.push(blockCounts[x]);
                }
            }
        }
        return (
            <Box style={{ height: "100%", width: "100%", position: "fixed", top: "0", left: "0", overflow: "hidden auto", zIndex: "1301", backgroundColor: "white" }}>
                <AppBar style={{ backgroundColor: "#884bdf", minHeight: "65px" }}>
                    <Toolbar>
                        <IconButton size="large"
                            color="inherit"
                            onClick={this.closeTokBack}
                        >
                            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ fontWeight: "bold" }}>
                            Tok Back
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box style={{ marginTop: "65px" }}>
                    <Row>
                        <Col className={'row tokback-detail'} xs={4}>
                            <Col xs={12} style={{ textAlign: "center" }}>Category</Col>
                            <Col xs={12} style={{ textAlign: "center" }}>{tok["category"] ? tok["category"] : ""}</Col>
                        </Col>
                        <Col className={'row tokback-detail'} xs={4}>
                            <Col xs={12} style={{ textAlign: "center" }}>Type</Col>
                            <Col xs={12} style={{ textAlign: "center" }}>{tok["tok_group"] ? tok["tok_group"] : ""}</Col>
                        </Col>
                        <Col className={'row tokback-detail'} xs={4}>
                            <Col xs={12} style={{ textAlign: "center" }}>Class Name</Col>
                            <Col xs={12} style={{ textAlign: "center" }}>{tok["tok_type"] ? tok["tok_type"] : ""}</Col>
                        </Col>
                        {(tok["tok_group"] == "Basic" || tok["tok_group"] == "Pic") && compatibleBlocks.length != 0 ?
                            <Col xs={12} className={'row'} style={{ margin: "unset" }}>
                                <Col xs={"4"} style={{ marginLeft: "auto", display: "flex", justifyContent: "center" }}>
                                    <Button onClick={this.primarySecondarySwap}>Swap Primary and Secondary</Button>
                                </Col>
                            </Col> : ""
                        }

                        <Col xs={12} className={'tokback-detail'} style={{ fontSize: "24px", height: "60px" }}>
                            {primary ? primary : ""}
                        </Col>
                    </Row>
                    {tok["tok_group"] == "Detailed" || tok["tok_group"] == "List" || tok["tok_group"] == "Detail" ?
                        <div style={{ maxWidth: "1210px", margin: "auto" }}>
                            {tokbackBlocks.length > 0 ?
                                <Row style={{ marginTop: "15px" }}>
                                    {tokbackBlocks.map((block, index) => (
                                        <Col xs={12}>
                                            <TokBackCard block={block} />
                                        </Col>
                                    ))}
                                </Row> : ""
                            }
                        </div> :
                        <div style={{ maxWidth: "1210px", margin: "auto" }}>
                            <Row>
                                <Col xs={12}>
                                    <TextField name="tokback" style={{ width: "100%" }} select label="Select number of blocks" onChange={this.blockCountSet}>
                                        {blockCounts.map((count) => (
                                            <MenuItem key={count} value={count}>
                                                {count}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }} ref={this.blockContainerRef}>
                                {tokbackBlocks.map((block, index) => (
                                    <Col xs={12}>
                                        <TokBackCard block={block} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    }
                </Box>
            </Box>
        );
    }
}

export default TokBack;