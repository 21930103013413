import ReactDOM from 'react-dom/client';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Navbar from '../components/Navbar';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Footer from '../components/Footer';
import Typography from "@mui/material/Typography";
import './../App.css';
import { auth } from './firebase';
import React, { useState } from 'react';
const PasswordReset = (props) => {
    const [email, setEmail] = useState('');

    const onSendResetPassword = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email).then(() => {
            // Email sent.
            console.log("Email sent!");
        }).catch((error) => {
            // An error ocurred
            // ...
            console.log(error);
        });
    }

    return (
        <React.Fragment>
        <CssBaseline />

        <Navbar />

        <Container className={'banner'}>
        <img src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header-aboutus.png" style={{ width: "100%", height: "570px" }} />
          <div></div>
        <Row className={'content-aboutus'}>
            <Typography
              align="center"
              fontSize={'80px'}
              fontWeight={'bold'}
              color={'white'}
              textTransform={'uppercase'}
            >
              Forgot Password
            </Typography>
          </Row>
            <Row>
                <Col>
                    <TextField id={'email'} style={{ width: "100%" }} value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
                </Col>
                <Col>
                    <Button variant={'contained'} color={"primary"} style={{ width: "100%" }} onClick={onSendResetPassword}>Send</Button>
                </Col>
            </Row>
        </Container>
        </React.Fragment>
    )
}

export default PasswordReset