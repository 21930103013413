import React from 'react'
import '../styles.css'
import './css/GroupsContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { getGroups, sendRequestGroup } from '../services/classServices';
import { getToken } from '../services/accountServices';
import GroupTile from './GroupTile';
import GroupTileView from './GroupView';
import { createRef } from 'react';
import { TextField } from '@mui/material';
import ButtonReact from 'react-bootstrap/Button';
import { faFilter, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from '@mui/material/Button';
import { Row, Col } from 'react-bootstrap';
import AddEditClassGroup from './AddEditClassGroup';
import arrayShuffle from 'array-shuffle';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { cloneDeep } from 'lodash';
import { Group } from '../interfaces/GroupInterface';

const MySwal = withReactContent(Swal);
const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
const shuffleColors = arrayShuffle(colors);
var colorCounter = -1;
interface States {
    CurrentLink: string;
    items: Group[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    GroupView: boolean;
    Group: string;
    MenuKind: string;
    Navbar: boolean;
    EnableTitle: boolean;
    CommunityId: string;
    CustomTitle: string;
    Kinds: {
        displayText: string;
        value: string;
        color: string;
    }[];
    CurrentKind: string;
    DisplayWarning: boolean | string;
    ForceKind: string;
    DisableUserId: boolean;
    level1: string;
    level2: string;
    level3: string;
    resultData: {};
}

interface Props {
    groupJoinedTrigger: React.RefObject<any>;
    EnableTitle: boolean;
    CustomTitle: string;
    disableUserId: boolean;
    CommunityId: string;
    Navbar: boolean;
    query: { [key: string]: any };
    displayWarning: string;
    MenuKind: boolean;
}
class GroupsContainer extends React.Component<Props, States> {
    searchInput: React.RefObject<any>;
    groupJoinedTrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            CurrentLink: "",
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            GroupView: false,
            Group: "",
            MenuKind: props.MenuKind,
            Navbar: props.Navbar,
            EnableTitle: props.EnableTitle ? props.EnableTitle : false,
            CommunityId: props.CommunityId ? props.CommunityId : "",
            CustomTitle: props.CustomTitle ? props.CustomTitle : "",
            Kinds: [
                { displayText: "Owned Groups", value: "Owned", color: "red" },
                { displayText: "My Groups", value: "All", color: "#f711ec" },
                { displayText: "Faith", value: "Faith", color: "#F57C00" },
                { displayText: "Class", value: "Class", color: "#4472c4" },
                { displayText: "Clubs", value: "Club", color: "#C2185B" },
                { displayText: "Study", value: "Study", color: "#388E3C" },
                { displayText: "Teams", value: "Team", color: "#7B1FA2" },
                { displayText: "Other", value: "Other", color: "blue" }
            ],
            CurrentKind: "",
            DisplayWarning: props.displayWarning ? props.displayWarning : false,
            ForceKind: props.forceKind ? props.forceKind : "",
            DisableUserId: props.disableUserId ? props.disableUserId : false,
            level1: props.level1,
            level2: props.level2,
            level3: props.level3,
            resultData: {}
        };
        this.reload = this.reload.bind(this);
        this.groupView = this.groupView.bind(this);
        this.groupViewClose = this.groupViewClose.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.removeGroup = this.removeGroup.bind(this);
        this.updateGroup = this.updateGroup.bind(this);

        this.setKind = this.setKind.bind(this);
        this.addGroupList = this.addGroupList.bind(this);

        this.setGroupSearch = this.setGroupSearch.bind(this);
        this.searchInput = createRef();

        this.groupJoinedTrigger = props.groupJoinedTrigger;
        this.groupJoined = this.groupJoined.bind(this)
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    groupView(card) {
        var userid = "";
        try {
            const localUser = JSON.parse(localStorage.getItem("userdata"));
            userid = localUser.id;
        } catch (err) { }
        if (card["ismember"] || (userid ? card["pk"].includes(userid) : false) || (card["user_id"] ? card["user_id"] == userid : false)) {
            var currentLink = window.location.pathname;
            var hrefLink = "/group/" + card.id;
            window.history.pushState({}, "Tokket", hrefLink);
            this.setState({
                GroupView: true,
                Group: card,
                CurrentLink: currentLink
            });
        } else {
            MySwal.fire({
                title: "Not a member",
                showCancelButton: true,
                confirmButtonText: "Request to Join",
                icon: "info"
            }).then((result) => {
                if (result.isConfirmed) {
                    const request = {
                        label: "classgrouprequest",
                        receiver_id: card["user_id"] ? card["user_id"] : "",
                        sender_id: userid,
                        user_id: userid,
                        name: card["name"] ? card["name"] : "",
                        status: 0,
                        remarks: "Pending",
                        message: "Request to join group.",
                        group_id: card["id"] ? card["id"] : "",
                        group_pk: card["pk"] ? card["pk"] : "",
                        members: card["members"] ? Number(card["members"]) : 0,
                        group_kind: card["group_kind"] ? card["group_kind"] : "",
                        description: card["description"] ? card["description"] : "",
                        school: card["school"] ? card["school"] : "",
                        image: card["image"] ? card["image"] : "",
                        group_owner_id: card["user_id"] ? card["user_id"] : "",
                    }
                    MySwal.fire({
                        title: <p>Sending Request</p>,
                        allowOutsideClick: false,
                    });
                    MySwal.showLoading();
                    sendRequestGroup(request).then(response => {
                        if(response){
                            if (response["status"] == 200) {
                                MySwal.update({
                                    title: 'Request sent.',
                                    icon: "success"
                                });
                            } else {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }
                        }
                    }).catch((result) => {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }).finally(() => {
                        MySwal.hideLoading();
                    })
                }
            });
        }
    }
    groupViewClose() {
        const { CurrentLink } = this.state;
        window.history.pushState({}, "Tokket", CurrentLink);
        this.setState({
            GroupView: false,
            Group: ""
        });

    }
    async getData(customQuery) {
        var { query, items, ForceKind, DisableUserId } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
            if (!query["pagination"]) {
                this.setState({
                    items: []
                });
            }
        }
        if (ForceKind) {
            query["group_kind"] = ForceKind;
        }
        if (DisableUserId) {
            query["disableUserId"] = true;
        }
        const response = await getGroups(query);

        var itemResult = response.data["result"]

        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        if (itemResult) {
            for (let x = 0; x < itemResult.length; x++) {
                itemResult[x]["ismember"] = true;
            }
        }
        this.setState({
            resultData: response.data,
            continuationToken: response.data["pagination"] ? response.data["pagination"] : "",
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
    }
    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    loadmore() {
        var { query, continuationToken, LoadMoreActive } = this.state;
        if (continuationToken && !LoadMoreActive) {
            if (query) {
                query.pagination = continuationToken;
            } else {
                query = { "pagination": continuationToken }
            }
            this.setState({
                LoadMoreActive: true
            }, () => {
                this.getData(query)
            });
        }
    }
    setKind(btn) {
        const kind = btn.target.getAttribute("data-value");
        this.setState({
            LoadActive: true,
            items: [],
            CurrentKind: kind
        });
        var { query, items } = this.state;
        if (query) {
            query.group_kind = kind;
        } else {
            query = { "group_kind": kind }
        }
        this.getData(query)
    }
    removeGroup(group) {
        var { items, CurrentLink } = this.state;

        if (items) {
            for (let x = 0; x < items.length; x++) {
                if (items[x].id == group.id && items[x].pk == group.pk) {
                    items.splice(x, 1);
                    window.history.pushState({}, "Tokket", CurrentLink);
                    break;
                }
            }
        }
        this.setState({
            items: items,
        });
    }
    updateGroup(group) {
        var { items } = this.state;
        let newGroupList = cloneDeep(items);
        if (items) {
            for (let x = 0; x < items.length; x++) {
                if (items[x].id == group.id && items[x].pk == group.pk) {
                    newGroupList[x] = group;
                }
            }
        }
        this.setState({
            items: newGroupList,
        });
    }
    addGroupList(data) {
        var { items } = this.state;
        if (items) {
            items.unshift(data);
        } else {
            items = new Array;
            items.push(data);
        }

        this.setState({
            items: items,
        });
    }

    setGroupSearch() {
        const search = this.searchInput.current.querySelector("input").value;
        this.setState({
            LoadActive: true,
            items: []
        });
        var { query } = this.state;
        try {
            if (query) {
                query.text = search;
            } else {
                query = { "text": search }
            }
        } catch (err) {
            query = { "text": search }
        }
        this.getData(query)
    }
    groupJoined(btn) {
        const groupString = btn.target.getAttribute("data-group");
        const group = JSON.parse(groupString);
        var { items } = this.state;
        for (let x = 0; x < items.length; x++) {
            if (group["id"]) {
                if (items[x]["id"] == group["id"]) {
                    let tempItem = items[x];
                    tempItem["ismember"] = true;

                    items[x] = tempItem;
                }
            }
        }
        btn.target.setAttribute("data-group", "");
        this.setState({
            items: items,
        });
    }
    render() {
        var { level1, level2, level3, DisplayWarning, CustomTitle, CommunityId, EnableTitle, LoadActive, items, GroupView, Group, LoadMoreActive, continuationToken, MenuKind, Navbar, Kinds, CurrentKind } = this.state;

        if (GroupView) {
            return <GroupTileView level1={level1} level2={level2} level3={level3} group={Group} closeView={this.groupViewClose} removeGroup={this.removeGroup} updateGroup={this.updateGroup} />
        }
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        var displayWarning: JSX.Element;
        switch (DisplayWarning) {
            case "mygrouptokchannel":
                displayWarning = <Row style={{ width: "100%" }}>
                    <div style={{ width: "90px" }}>
                        <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" style={{ width: "100%" }} />
                    </div>
                    <Col>
                        <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600" }}>No groups yet. Go to a Tok Channel Community to join a group.</div>
                    </Col>
                </Row>
                break;
            case "mygrouptokchannelall":
                displayWarning = <Row style={{ width: "100%" }}>
                    <div style={{ width: "90px" }}>
                        <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl2.jpg" style={{ width: "100%" }} />
                    </div>
                    <Col>
                        <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600" }}>No groups yet. Go to a Tok Channel Community to create a group.</div>
                    </Col>
                </Row>
                break;
        }
        return (
            <div style={{ marginTop: "15px" }}>
                <Button data-group ref={this.groupJoinedTrigger} onClick={this.groupJoined} hidden></Button>
                {Navbar ?
                    <Grid className={'text-color-white'} container spacing={1} style={{ padding: "3px" }}>
                        <div style={{ position: "relative" }}>
                            <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
                        </div>
                        <ButtonReact variant='primary' style={{ marginRight: "5px" }} onClick={this.setGroupSearch}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                        </ButtonReact>
                        {EnableTitle ?
                            <div style={{ marginRight: "auto", display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "10px", fontSize: "21px", backgroundColor: "#112379", color: "white", borderRadius: "10px", textAlign: "center", textTransform: "capitalize", padding: "10px 40px" }}>
                                {CustomTitle ?
                                    <>{CustomTitle}</> :
                                    <>Tok Groups - {CurrentKind == "" ? "Owned" : CurrentKind}</>
                                }

                            </div>
                            : <div style={{ marginLeft: "auto" }}></div>
                        }

                        {/* <AddClassTok customClass={customClass} /> */}

                        <AddEditClassGroup updateGroup={undefined} CommunityId={CommunityId} icon={"parrot-pen"} receiveData={this.addGroupList} triggerBtn={"Add Group"} customBtnStyle={{ display: "flex", alignItems: "center" }} customBtnClass={'btn btn-success'} />
                        <div className={'refresh-btn'} onClick={this.reload}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                        </div>
                    </Grid>
                    : ""}
                {MenuKind ?
                    <Row>
                        {Kinds.map((Kind, index) => (
                            <ButtonReact variant='secondary' onClick={this.setKind} className={CurrentKind != Kind.value ? "kind-option-btn col" : "kind-option-btn col active"} data-value={Kind.value} style={{ backgroundColor: Kind.color }}>
                                {Kind.displayText}
                            </ButtonReact>
                        ))}
                    </Row> :
                    ""
                }
                {LoadActive ?
                    <WaitSpinner /> :
                    <Grid container spacing={1}>
                        {items ?
                            <>
                                {items.map(card => {
                                    colorCounter++;
                                    if (colorCounter == 16) {
                                        colorCounter = 0;
                                    }
                                    return (
                                        <GroupTile key={card["id"] + "-" + card["pk"] + "grouptile"} card={card} color={shuffleColors[colorCounter]} receiveData={this.groupView} />
                                    )
                                })}
                                {LoadMoreActive ? <WaitSpinner /> : ""}
                            </> : displayWarning
                        }

                    </Grid>
                }

            </div>

        );
    }
}

export default GroupsContainer;