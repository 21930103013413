import React from 'react';
import { signOut } from "firebase/auth";
import { auth } from './firebase';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";

const Logout = (props) => {

    const onLogout = (e) => {
        e.preventDefault();
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.setItem("authUser", null);
            localStorage.setItem("userdata", "");
            localStorage.setItem("authToken", "");
            localStorage.setItem("profile", "");
            window.location.reload();
          }).catch((error) => {
            // An error happened.
          });
    }

    return (
        <MenuItem onClick={onLogout}>
            <Typography textAlign="center">Logout</Typography>
        </MenuItem>
    )
}

export default Logout