import React from 'react'
import Typography from "@mui/material/Typography";
import { faPenToSquare, faCamera, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles.css'
import { createRef } from 'react';
import { Button } from 'react-bootstrap';
import './css/ProfileView.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Link from '@mui/material/Link';
import { countries } from 'country-data';
import ReactCountryFlag from "react-country-flag";
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import TokTileContainer from './TokTileContainer';
import TextField from '@mui/material/TextField';
import { updateUser, getToken, patchUser, createUser, getUsers, deleteUser } from '../services/accountServices';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Cropper from "react-cropper";
import "./css/cropper.css";
import ImageSelection from './ImageSelection';
import { cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import Calendar from 'react-calendar';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import CountryPicker from './CountryPicker';
import Switch from '@mui/material/Switch';
import Dropdown from 'react-bootstrap/Dropdown';
import { TokUser } from '../interfaces/TokUserInterface';

const MySwal = withReactContent(Swal);
const defaultProfile: Partial<TokUser> = {
    display_name: "",
    label: "profile",
    pin: "",
    is_pin_enabled: false
}
const profileLimitCount = 5;

interface States {
    user: TokUser;
    TempEditUser: TokUser;
    displayToksSets: boolean;
    owner: boolean;
    userData: TokUser;
    ModalEditMode: string;
    ActiveTab: string;
    DisplayModalEditMode: boolean;
    ProfilePictureSelectionModal: boolean;
    ProfilePictureSelectionMode: string;
    cropperSrc: string;
    cropperPosition: string;
    cropperShow: boolean;
    editBio: boolean;
    editWebsite: boolean;
    addEditProfileShow: boolean;
    addEditProfileEditMode: boolean;
    tempOgEditProfile: TokUser;
    tempAddEditProfile: TokUser;
    userProfiles: TokUser[];
    userProfilesSet: boolean;
    profile: TokUser;
    pinInputShow: boolean;
    tempProfile: TokUser;
    tempPin: string;
    showMoreSettings: boolean;
}

interface Props {
    updateProfileData: any;
    updateLoginData: any;
    displayToksSets: any;
    user: any;
    profile: any;
    changePasswordTrigger: React.RefObject<any>;
}

class ProfileView extends React.Component<Props, States> {
    cropperRef: React.RefObject<any>;
    changePasswordTrigger: React.RefObject<any>;
    constructor(props) {
        super(props);
        const userData = props.user ? props.user : {};
        const profile = props.profile ? props.profile : {};
        const localUser = localStorage.getItem("userdata");

        const localUserJson = JSON.parse(localUser);
        let owner = false;
        if (localUserJson["id"] && userData["id"]) {
            if (localUserJson["id"] == userData["id"]) {
                owner = true;
            }
        }
        this.state = {
            user: userData,
            TempEditUser: {} as TokUser,
            displayToksSets: props.displayToksSets ? props.displayToksSets : false,
            owner: owner,
            userData: localUserJson as TokUser,
            ModalEditMode: "",
            ActiveTab: 'classtoks',
            DisplayModalEditMode: false,
            ProfilePictureSelectionModal: false,
            ProfilePictureSelectionMode: "upload",
            cropperSrc: null,
            cropperPosition: null,
            cropperShow: false,
            editBio: false,
            editWebsite: false,
            addEditProfileShow: false,
            addEditProfileEditMode: false,
            tempOgEditProfile: {} as TokUser,
            tempAddEditProfile: cloneDeep(defaultProfile) as TokUser,
            userProfiles: new Array,
            userProfilesSet: false,
            profile: profile,
            pinInputShow: false,
            tempProfile: {} as TokUser,
            tempPin: "",
            showMoreSettings: false
        };
        this.cropperRef = createRef();

        this.changePasswordTrigger = props.changePasswordTrigger;

        this.fetchToken = this.fetchToken.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.ProfilePictureSelection = this.ProfilePictureSelection.bind(this);
        this.SetModalEdit = this.SetModalEdit.bind(this);
        this.CloseModals = this.CloseModals.bind(this);
        this.SetUserUpdateValue = this.SetUserUpdateValue.bind(this);
        this.updateUserTrigger = this.updateUserTrigger.bind(this);
        this.handleCropperClose = this.handleCropperClose.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.onImageSet = this.onImageSet.bind(this);
        this.SetProfilePictureSelectionMode = this.SetProfilePictureSelectionMode.bind(this);
        this.SetActiveTab = this.SetActiveTab.bind(this);
        this.SetImageSelection = this.SetImageSelection.bind(this);

        this.setEditBio = this.setEditBio.bind(this);
        this.closeEditBio = this.closeEditBio.bind(this);
        this.setEditWebsite = this.setEditWebsite.bind(this);
        this.closeEditWebsite = this.closeEditWebsite.bind(this);
        this.setBirthday = this.setBirthday.bind(this);

        this.setCountry = this.setCountry.bind(this);
        this.setUsState = this.setUsState.bind(this);

        this.setAddProfile = this.setAddProfile.bind(this);
        this.closeAddEditProfile = this.closeAddEditProfile.bind(this);
        this.SetTempAddEditProfile = this.SetTempAddEditProfile.bind(this);
        this.SetTempAddEditProfilePinEnabled = this.SetTempAddEditProfilePinEnabled.bind(this);
        this.addSubProfile = this.addSubProfile.bind(this);
        this.addEditPreSubProfile = this.addEditPreSubProfile.bind(this);
        this.getUserProfiles = this.getUserProfiles.bind(this);

        this.setProfile = this.setProfile.bind(this);
        this.cancelSetProfile = this.cancelSetProfile.bind(this);
        this.setTempPin = this.setTempPin.bind(this);

        this.setUpProfile = this.setUpProfile.bind(this);
        this.setMoreSettings = this.setMoreSettings.bind(this);
        this.closeMoreSettings = this.closeMoreSettings.bind(this);
        this.setDeleteCurrentProfile = this.setDeleteCurrentProfile.bind(this);

        this.SetTempProfilePinEnabled = this.SetTempProfilePinEnabled.bind(this);
        this.enterSetProfilePin = this.enterSetProfilePin.bind(this);

        this.deleteProfile = this.deleteProfile.bind(this);
        this.setDeleteSelectedtProfile = this.setDeleteSelectedtProfile.bind(this);

        this.setEditProfile = this.setEditProfile.bind(this);
        this.editSubProfile = this.editSubProfile.bind(this);

        this.destroyProfile = this.destroyProfile.bind(this);

        this.setCoverPhoto = this.setCoverPhoto.bind(this);
    }
    componentDidMount() {
        const { owner } = this.state;
        if (owner) {
            this.getUserProfiles();
        }
        this.fetchToken();
    }
    getUserProfiles() {
        var { user } = this.state;
        let query = {
            label: "profile"
        }
        getUsers(query, user["id"], "profiles").then(response => {
            if (response) {
                if (response["data"]["status_code"] == 200) {
                    this.setState({
                        userProfiles: response["data"]["result"] ? response["data"]["result"] : new Array,
                        userProfilesSet: true
                    });
                }
            }
        });
    }
    async fetchToken() {
        await getToken();
    }
    onChangePassword() {
        var { profile } = this.state;
        try {
            this.changePasswordTrigger.current.click();
        } catch (err) { }
    }
    ProfilePictureSelection() {
        var { user, profile } = this.state;
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            ProfilePictureSelectionModal: true,
        });
    }
    SetModalEdit(e) {
        var { profile, user } = this.state;
        let editMode = "";
        try {
            editMode = e.target.getAttribute("data-mode");
        } catch (err) { }
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            DisplayModalEditMode: true,
            ModalEditMode: editMode
        });
    }
    CloseModals(e) {
        this.setState({
            DisplayModalEditMode: false,
            ProfilePictureSelectionModal: false
        });
    }
    SetUserUpdateValue(e) {
        var { TempEditUser } = this.state;
        var value = e.target.value;
        const dataType = e.target.name;
        var tempUser = cloneDeep(TempEditUser);
        tempUser[dataType] = value;
        this.setState({
            TempEditUser: tempUser
        });
    }

    updateUserTrigger() {
        var { user, TempEditUser, ModalEditMode, profile, userProfiles } = this.state;

        let editedUser = profile["id"] ? cloneDeep(profile) : cloneDeep(user);
        let items = profile["id"] ? "profiles" : "";
        var patchObject = diff(editedUser, TempEditUser);
        let patchObjectKeys = Object.keys(patchObject);
        for (let x = 0; x < patchObjectKeys.length; x++) {
            patchObject[patchObjectKeys[x]] = cloneDeep(TempEditUser[patchObjectKeys[x]]);
        }
        if (profile["id"] && ModalEditMode == "Pin" && TempEditUser["pin"] != TempEditUser["cpin"]) {
            MySwal.fire({
                title: <p>Incorrect PIN & Confirmation PIN</p>,
                allowOutsideClick: false,
                icon: "error"
            });
        } else {
            MySwal.fire({
                title: <p>Updating {ModalEditMode ? ModalEditMode : "User Info"}</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            patchUser(patchObject, editedUser["id"], editedUser["pk"], items).then(response => {
                if (response) {
                    if (response["data"]["status_code"] == 200) {
                        MySwal.update({
                            title: <p>Success</p>,
                            icon: "success"
                        });
                        try {
                            if (profile["id"]) {
                                this.props.updateProfileData(TempEditUser);
                                let y = 0;
                                for (let x = 0; x < userProfiles.length; x++) {
                                    if (userProfiles[x]["id"] == profile["id"]) {
                                        userProfiles[x] = TempEditUser;
                                        break;
                                    }
                                }
                                window.localStorage.setItem("profile", JSON.stringify(TempEditUser));
                            } else {
                                window.localStorage.setItem("userdata", JSON.stringify(TempEditUser));
                                this.props.updateLoginData(TempEditUser);
                            }

                        } catch (err) {
                        }
                        this.setState({
                            user: profile["id"] ? user : cloneDeep(TempEditUser),
                            profile: profile["id"] ? cloneDeep(TempEditUser) : profile,
                            cropperPosition: null,
                            editBio: false,
                            editWebsite: false,
                            userProfiles: userProfiles
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(response => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }
    }

    handleCropperClose() {
        this.setState({
            cropperShow: false
        });
    };
    onCrop() {
        var { TempEditUser, cropperPosition, addEditProfileEditMode, tempAddEditProfile, addEditProfileShow } = this.state;
        const cropper = this.cropperRef.current?.cropper;
        var tempUser = addEditProfileShow ? cloneDeep(tempAddEditProfile) : cloneDeep(TempEditUser);
        tempUser[cropperPosition] = cropper.getCroppedCanvas().toDataURL();
        this.setState({
            cropperShow: false,
            TempEditUser: addEditProfileShow ? TempEditUser : tempUser,
            tempAddEditProfile: addEditProfileShow ? tempUser : tempAddEditProfile,
        });
    };

    onImageSet(e) {
        if (e.currentTarget.getAttribute("data-position")) {
            this.setState({
                cropperSrc: URL.createObjectURL(e.currentTarget.files[0]),
                cropperPosition: e.currentTarget.getAttribute("data-position"),
                cropperShow: true
            });
        }
    }
    SetImageSelection(e) {
        var { TempEditUser } = this.state;
        try {

            let tempUser = cloneDeep(TempEditUser);
            tempUser["user_photo"] = e;
            this.setState({
                TempEditUser: tempUser
            });
        } catch (err) { }
    }
    SetProfilePictureSelectionMode(e) {
        this.setState({
            ProfilePictureSelectionMode: e
        })
    }
    SetActiveTab(e) {
        let activeTab = e.currentTarget.getAttribute("data-mode") ? e.currentTarget.getAttribute("data-mode") : "classtoks";
        this.setState({
            ActiveTab: activeTab
        });
    }
    setEditBio(e) {
        var { user, profile } = this.state;
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            editBio: true
        });
    }
    closeEditBio(e) {
        var { user, profile } = this.state;
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            editBio: false
        });
    }
    setEditWebsite(e) {
        var { user, profile } = this.state;
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            editWebsite: true
        });
    }
    closeEditWebsite(e) {
        var { user, profile } = this.state;
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            editWebsite: false
        });
    }
    setBirthday(e) {
        var { TempEditUser } = this.state;
        const offset = e.getTimezoneOffset()
        var dateData = new Date(e.getTime() - (offset * 60 * 1000))
        TempEditUser["birthday"] = dateData;
        this.setState({
            TempEditUser: TempEditUser
        });
    }
    setCountry(e) {
        var { TempEditUser } = this.state;
        TempEditUser["country"] = e;
        this.setState({
            TempEditUser: TempEditUser
        });
    }
    setUsState(e) {
        var { TempEditUser } = this.state;
        TempEditUser["state"] = e;
        this.setState({
            TempEditUser: TempEditUser
        });
    }
    setAddProfile() {
        const { userProfiles } = this.state;
        if (userProfiles.length >= profileLimitCount) {
            MySwal.fire({
                title: <p>Only 10 Profiles allowed.</p>,
                allowOutsideClick: false,
                icon: "info"
            });
        } else {
            this.setState({
                addEditProfileShow: true,
                addEditProfileEditMode: false
            });
        }
    }

    closeAddEditProfile() {
        this.setState({
            addEditProfileShow: false,
            addEditProfileEditMode: false,
            tempAddEditProfile: cloneDeep(defaultProfile) as TokUser,
            tempOgEditProfile: cloneDeep(defaultProfile) as TokUser
        });
    }
    SetTempAddEditProfile(e) {
        var { tempAddEditProfile } = this.state;
        var value = e.target.value;
        const dataType = e.target.name;
        tempAddEditProfile[dataType] = value;
        this.setState({
            tempAddEditProfile: tempAddEditProfile
        });
    }
    SetTempAddEditProfilePinEnabled() {
        var { tempAddEditProfile } = this.state;
        tempAddEditProfile["is_pin_enabled"] = !tempAddEditProfile["is_pin_enabled"];
        this.setState({
            tempAddEditProfile: tempAddEditProfile
        });
    }
    SetTempProfilePinEnabled() {
        var { TempEditUser } = this.state;
        TempEditUser["is_pin_enabled"] = !TempEditUser["is_pin_enabled"];
        this.setState({
            TempEditUser: TempEditUser
        });
    }
    addSubProfile() {
        var { tempAddEditProfile, user } = this.state;
        MySwal.fire({
            title: <p>Adding Profile</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        tempAddEditProfile["user_id"] = user["id"];
        createUser(tempAddEditProfile, "profiles").then(response => {
            if (response) {
                if (response["data"]["status_code"] == 200) {
                    var { userProfiles } = this.state;
                    MySwal.update({
                        title: <p>Success</p>,
                        icon: "success"
                    });
                    userProfiles.push(response["data"]["result"]);
                    this.setState({
                        userProfiles: userProfiles
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    addEditPreSubProfile() {
        const { addEditProfileShow, userProfiles, userProfilesSet, addEditProfileEditMode } = this.state;
        if (addEditProfileEditMode) {
            this.editSubProfile();
        }
        else {
            let addSubProfile = this.addSubProfile;
            if (userProfiles.length == 0 && userProfilesSet) {
                //add paypal functions here then continue to addSubProfile
                addSubProfile();
            } else {
                addSubProfile();
            }
        }
    }
    editSubProfile() {
        const { tempOgEditProfile, tempAddEditProfile, userProfiles, profile } = this.state;
        if (tempAddEditProfile["pin"] != tempAddEditProfile["cpin"]) {
            MySwal.fire({
                title: <p>Incorrect PIN & Confirmation PIN</p>,
                allowOutsideClick: false,
                icon: "error"
            });
        } else {
            var patchObject = diff(tempAddEditProfile, tempOgEditProfile);
            delete patchObject["cpin"];
            MySwal.fire({
                title: <p>Updating Profile</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            let patchObjectKeys = Object.keys(patchObject);
            for (let x = 0; x < patchObjectKeys.length; x++) {
                patchObject[patchObjectKeys[x]] = cloneDeep(tempOgEditProfile[patchObjectKeys[x]]);
            }
            patchUser(patchObject, tempOgEditProfile["id"], tempOgEditProfile["pk"], "profiles").then(response => {
                if(response){
                    if (response["data"]["status_code"] == 200) {
                        MySwal.update({
                            title: <p>Success</p>,
                            icon: "success"
                        });
                        try {
                            if (profile["id"] == tempAddEditProfile["id"]) {
                                this.props.updateProfileData(tempAddEditProfile);
                                window.localStorage.setItem("profile", JSON.stringify(tempAddEditProfile));
                            }
                        } catch (err) {
                        }
                        for (let x = 0; x < userProfiles.length; x++) {
                            if (userProfiles[x]["id"] == tempAddEditProfile["id"]) {
                                userProfiles[x] = tempAddEditProfile;
                                break;
                            }
                        }
                        this.setState({
                            profile: profile["id"] == tempAddEditProfile["id"] ? cloneDeep(tempAddEditProfile) : profile,
                            cropperPosition: null,
                            userProfiles: userProfiles,
                            addEditProfileShow: false,
                            addEditProfileEditMode: false,
                            tempAddEditProfile: cloneDeep(defaultProfile) as TokUser,
                            tempOgEditProfile: cloneDeep(defaultProfile) as TokUser
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(response => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }
    }
    setProfile(e) {
        const { userProfiles } = this.state;
        let id = e.currentTarget.getAttribute("data-id");
        let profile = userProfiles.filter((product) => product.id == id)[0];
        if (profile["is_pin_enabled"]) {
            this.setState({
                pinInputShow: true,
                tempProfile: profile
            });
        } else {
            MySwal.fire({
                title: <p>Setting Profile</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            var setUpProfile = this.setUpProfile;
            setTimeout(function () {
                setUpProfile(profile);
                MySwal.close();
            }, 1000);
        }

    }
    setUpProfile(profile) {
        window.localStorage.setItem("profile", JSON.stringify(profile));
        this.props.updateProfileData(profile);
        this.setState({
            profile: profile
        });
    }
    cancelSetProfile() {
        this.setState({
            pinInputShow: false,
            tempProfile: {} as TokUser,
            tempPin: ""
        });
    }
    setTempPin(e) {
        var value = e.target.value;
        this.setState({
            tempPin: value
        });
    }
    setMoreSettings() {
        this.setState({
            showMoreSettings: true
        });
    }
    closeMoreSettings() {
        this.setState({
            showMoreSettings: false
        });
    }
    setDeleteCurrentProfile() {
        var { profile } = this.state;
        this.deleteProfile(profile);
    }
    setDeleteSelectedtProfile(e) {
        var { userProfiles } = this.state;
        var id = e.currentTarget.getAttribute("data-id");
        var filtredProfile = userProfiles.filter((item) => item.id == id);
        if (filtredProfile.length > 0) {
            this.deleteProfile(filtredProfile[0]);
        }
    }
    deleteProfile(selectedProfile) {
        var { profile, userProfiles } = this.state;
        MySwal.fire({
            title: <p>Are you sure want to delete profile {selectedProfile["display_name"]}?</p>,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Profile</p>,
                    allowOutsideClick: false,
                });
                MySwal.showLoading()
                deleteUser(selectedProfile, "profiles").then(result => {
                    if(result){
                        if (result["status"] == 200) {
                            MySwal.update({
                                title: <p>Profile Deleted</p>,
                                icon: "success"
                            });
    
                            userProfiles = userProfiles.filter((item) => item.id != selectedProfile["id"]);
                            if (selectedProfile["id"] == profile["id"]) {
                                try {
                                    this.props.updateProfileData([]);
                                } catch (err) {
                                }
                                window.localStorage.setItem("profile", "");
                            }
                            this.setState({
                                userProfiles: userProfiles,
                                profile: selectedProfile["id"] == profile["id"] ? {} as TokUser : profile,
                                showMoreSettings: false
                            });
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }
                }).catch(err => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }).finally(() => {
                    MySwal.hideLoading();
                });
            }
        })
    }
    enterSetProfilePin() {
        const { tempPin, tempProfile } = this.state;
        if (tempPin == tempProfile["pin"]) {
            MySwal.fire({
                title: <p>Setting Profile</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            var setUpProfile = this.setUpProfile;
            var cancelSetProfile = this.cancelSetProfile;
            setTimeout(function () {
                setUpProfile(tempProfile);
                cancelSetProfile();
                MySwal.close();
            }, 3000);
        } else {
            MySwal.fire({
                title: <p>Incorrect PIN</p>,
                allowOutsideClick: false,
                icon: "warning"
            });
        }
    }
    setEditProfile(e) {
        const { userProfiles } = this.state;
        let id = e.currentTarget.getAttribute("data-id");
        let profile = cloneDeep(userProfiles.filter((product) => product.id == id)[0]);
        profile["cpin"] = "";
        this.setState({
            addEditProfileShow: true,
            addEditProfileEditMode: true,
            tempAddEditProfile: cloneDeep(profile),
            tempOgEditProfile: cloneDeep(profile)
        });
    }
    destroyProfile() {
        try {
            this.props.updateProfileData([]);
        } catch (err) {
        }
        window.localStorage.setItem("profile", "");
        this.setState({
            profile: {} as TokUser,
        });
    }
    setCoverPhoto() {
        var { user, profile } = this.state;
        this.setState({
            TempEditUser: profile["id"] ? cloneDeep(profile) : cloneDeep(user),
            ModalEditMode: "Cover Photo",
            DisplayModalEditMode: true
        });
    }
    render() {
        const { showMoreSettings, profile, tempPin, tempProfile, pinInputShow, userProfilesSet, userProfiles, tempAddEditProfile, addEditProfileShow, addEditProfileEditMode, tempOgEditProfile, editBio, editWebsite, user, TempEditUser, displayToksSets, owner, ModalEditMode, ActiveTab, DisplayModalEditMode, ProfilePictureSelectionModal, ProfilePictureSelectionMode, cropperPosition, cropperShow, cropperSrc } = this.state;
        let displayInfo = profile["id"] ? profile : user;
        return (
            <div>
                {owner ?
                    <>
                        <Modal size='lg' show={pinInputShow} onHide={this.cancelSetProfile} style={{ zIndex: "1207" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.cancelSetProfile} />
                                    PIN for {tempProfile["display_name"]}
                                    <Button variant="success" onClick={this.enterSetProfilePin}>Confirm</Button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={12}>
                                        <TextField style={{ width: "100%" }} value={tempPin} label="PIN" onChange={this.setTempPin} inputProps={{ maxLength: 25 }} />
                                        <p className={'character-count'}>{tempPin.length}/25</p>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                        <Modal size='lg' show={showMoreSettings} onHide={this.closeMoreSettings} style={{ zIndex: "1207" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeMoreSettings} />
                                    More Settings
                                    <div></div>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={12}>
                                        <Button onClick={profile["id"] ? this.setDeleteCurrentProfile : undefined} variant='primary' style={{ width: "100%" }}>Delete {profile["id"] ? "Profile" : "Account"}</Button>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                        {addEditProfileShow ?
                            <Modal size='lg' show={addEditProfileShow} onHide={this.closeAddEditProfile} style={{ zIndex: "1207" }}>
                                <Modal.Header>
                                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeAddEditProfile} />
                                        {addEditProfileEditMode ? "Edit" : "Add"} Profile {addEditProfileEditMode ? "- " + tempOgEditProfile["display_name"] : ""}
                                        <Button variant="success" disabled={tempAddEditProfile["display_name"] == ""} onClick={this.addEditPreSubProfile}>{addEditProfileEditMode ? "Edit" : "Add"} Profile</Button>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <div onClick={(e) => e.currentTarget.querySelector("input").click()} className={'profile-avatar'} style={{ marginLeft: "10px", backgroundImage: tempAddEditProfile["user_photo"] ? `url(${tempAddEditProfile.user_photo})` : "url(https://tokketcontent.blob.core.windows.net/tokketweb/default.png)", cursor: owner ? "pointer" : "unset" }}>
                                            {owner ?
                                                <div className={'profile-avatar-edit-message'}>
                                                    <FontAwesomeIcon icon={faCamera} style={{ marginRight: "5px" }} />
                                                    <label>{tempAddEditProfile["user_photo"] ? "Update" : "Add"}</label>
                                                </div> : ""}
                                            <input type="file" accept="image/*" onChange={this.onImageSet} data-position="user_photo" hidden />
                                        </div>
                                        <Col style={{ alignContent: "end" }}>
                                            <TextField style={{ width: "100%" }} value={tempAddEditProfile["display_name"]} name="display_name" onChange={this.SetTempAddEditProfile} label="Display Name" inputProps={{ maxLength: 25 }} />
                                            <p className={'character-count'}>{tempAddEditProfile["display_name"].length}/25</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={addEditProfileEditMode ? "12" : undefined}>
                                            <TextField type='password' style={{ width: "100%" }} value={tempAddEditProfile["pin"]} name="pin" onChange={this.SetTempAddEditProfile} label="PIN" inputProps={{ maxLength: 25 }} />
                                            <p className={'character-count'}>{tempAddEditProfile["pin"].length}/25</p>
                                        </Col>

                                        {addEditProfileEditMode ?
                                            <Col>
                                                <TextField type='password' style={{ width: "100%" }} value={tempAddEditProfile["cpin"]} name="cpin" onChange={this.SetTempAddEditProfile} label="Confirm PIN" inputProps={{ maxLength: 25 }} />
                                                <p className={'character-count'}>{tempAddEditProfile["cpin"].length}/25</p>
                                            </Col> : ""
                                        }
                                        <Col xs={"auto"}>
                                            <div style={{ marginTop: "10px" }}>
                                                <Switch checked={tempAddEditProfile["is_pin_enabled"]} onClick={this.SetTempAddEditProfilePinEnabled} /> Enable PIN
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>
                            : ""}


                        <Modal size='lg' show={DisplayModalEditMode} style={{ zIndex: "1207" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.CloseModals} />
                                    Update {ModalEditMode}
                                    <Button variant="success" onClick={this.updateUserTrigger}>Update</Button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                {ModalEditMode == "Display Name" ?
                                    <>
                                        <TextField style={{ width: "100%" }} value={TempEditUser["display_name"]} name="display_name" onChange={this.SetUserUpdateValue} label="Display Name" inputProps={{ maxLength: 25 }} />
                                        <p className={'character-count'}>{TempEditUser["display_name"].length}/25</p>
                                    </> : ""
                                }
                                {ModalEditMode == "PIN" ?
                                    <Row>
                                        <Col xs={12}>
                                            <TextField type='password' style={{ width: "100%" }} value={TempEditUser["pin"]} name="pin" onChange={this.SetUserUpdateValue} label="PIN" inputProps={{ maxLength: 25 }} />
                                            <p className={'character-count'}>{TempEditUser["pin"].length}/25</p>
                                        </Col>
                                        <Col>
                                            <TextField type='password' style={{ width: "100%" }} value={TempEditUser["cpin"]} name="cpin" onChange={this.SetUserUpdateValue} label="Confirm PIN" inputProps={{ maxLength: 25 }} />
                                            <p className={'character-count'}>{TempEditUser["cpin"] ? TempEditUser["cpin"].length : 0}/25</p>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <div style={{ marginTop: "10px" }}>
                                                <Switch checked={TempEditUser["is_pin_enabled"]} onClick={this.SetTempProfilePinEnabled} /> Enable PIN
                                            </div>
                                        </Col>
                                    </Row> : ""
                                }
                                {ModalEditMode == "Birthday" ?
                                    <div className={"calender-profile-container"}>
                                        <Calendar value={TempEditUser["birthday"]} onChange={this.setBirthday} />
                                    </div> : ""
                                }
                                {ModalEditMode == "Country or State" ?
                                    <CountryPicker country={TempEditUser["country"] ? TempEditUser["country"] : ""} state={TempEditUser["state"] ? TempEditUser["state"] : ""} setCountry={this.setCountry} setUsState={this.setUsState} /> : ""
                                }
                                {ModalEditMode == "Cover Photo" ?
                                    <Row>
                                        <Col xs={12} style={{ height: "300px", backgroundImage: TempEditUser["cover_photo"] ? `url(${TempEditUser["cover_photo"]})` : "", alignContent: "center", textAlign: "center", borderRadius: "10px", marginBottom: "10px", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                            {!TempEditUser["cover_photo"] ?
                                                <div style={{ fontSize: "30px", fontWeight: "600" }}>
                                                    No Cover Photo
                                                </div> : ""}
                                        </Col>
                                        <Button className={"col-12"} variant='primary' onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                            Upload
                                            <input type="file" accept="image/*" onChange={this.onImageSet} data-position="cover_photo" hidden />
                                        </Button>
                                    </Row> : ""
                                }
                            </Modal.Body>
                        </Modal>

                        <Modal size='lg' show={cropperShow} onHide={this.handleCropperClose} style={{ zIndex: "1306" }}>
                            <Modal.Header className={'text-color-white dark-background-2'}>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleCropperClose} />
                                    Cropper
                                    <Button variant="success" onClick={this.onCrop}>Crop</Button>
                                </div>
                            </Modal.Header>
                            <Modal.Body className={'text-color-white dark-background-2'}>
                                <Cropper
                                    src={cropperSrc}
                                    style={{ height: 400, width: "100%" }}
                                    initialAspectRatio={16 / 9}
                                    ref={this.cropperRef}
                                    data-position={cropperPosition}
                                />

                            </Modal.Body>
                        </Modal>

                        <Modal size='lg' show={ProfilePictureSelectionModal} style={{ zIndex: "1207" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <Typography fontWeight={"bold"} marginRight={"auto"}>Select Profile Picture</Typography>

                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.CloseModals} />
                                </div>
                            </Modal.Header>
                            <Modal.Body className={'image-options-body'}>
                                <Tabs activeKey={ProfilePictureSelectionMode} onSelect={this.SetProfilePictureSelectionMode} className={'image-options-tabs'}>
                                    <Tab eventKey="upload" title="Upload Photo" style={{ alignItems: "center", height: "100%" }}>
                                        <Row style={{ margin: "auto", textAlign: "-webkit-center" as any, height: "100%", display: "flex", alignContent: "center" }}>
                                            <Col xs={12} style={{ fontSize: "18px", fontWeight: "600" }}>
                                                Current Photo
                                            </Col>
                                            <Col xs={12} margin>
                                                <div style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", margin: "10px 0px", height: "200px", width: "200px", backgroundImage: TempEditUser ? TempEditUser["user_photo"] ? `url(${TempEditUser["user_photo"]})` : "" : "" }}></div>
                                            </Col>
                                            <Col xs={12}>
                                                <Button variant='outline-dark' onClick={(e) => e.currentTarget.parentNode.querySelector("input").click()}>
                                                    <FontAwesomeIcon icon={faCamera} />
                                                    Select a photo from your computer
                                                </Button>
                                                <input type="file" accept="image/*" onChange={this.onImageSet} data-position="user_photo" hidden />
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="gallery" title="Your Photos">
                                        {owner ?
                                            <ImageSelection query={{ userid: displayInfo["id"] }} setImage={this.SetImageSelection} /> : ""
                                        }

                                    </Tab>
                                    <Tab eventKey="avatars" title="Avatars">
                                        {owner ?
                                            <ImageSelection query={{ kind: "avatars" }} setImage={this.SetImageSelection} /> : ""
                                        }
                                    </Tab>
                                    <Tab eventKey="badges" title="Badges">
                                        {owner ?
                                            <ImageSelection query={{ userid: displayInfo["id"], kind: "badges" }} setImage={this.SetImageSelection} /> : ""
                                        }
                                    </Tab>
                                </Tabs>
                            </Modal.Body>
                            <Modal.Footer style={{ justifyContent: "flex-start" }}>
                                <Button onClick={this.updateUserTrigger} disabled={TempEditUser ? user ? TempEditUser["user_photo"] ? displayInfo["user_photo"] ? TempEditUser["user_photo"] == displayInfo["user_photo"] ? true : false : false : false : false : false}>Set as Profile Picture</Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    : ""
                }
                <div className={'photo-cover'} style={{ backgroundImage: displayInfo["cover_photo"] ? `url(${displayInfo.cover_photo})` : "url(https://tokketcontent.blob.core.windows.net/tokketweb/welcome-bg-temp.png)" }}>
                    {owner ?
                        <Button onClick={this.setCoverPhoto} variant='dark' style={{ marginLeft: "10px", marginTop: "5px" }}>Change Cover Photo</Button> :
                        ""
                    }

                </div>
                <div className={'profile-body'}>
                    <Row style={{ position: "absolute", top: "-60px", alignItems: "center" }}>
                        <div onClick={owner ? this.ProfilePictureSelection : undefined} className={'profile-avatar'} style={{ backgroundImage: displayInfo["user_photo"] ? `url(${displayInfo.user_photo})` : "url(https://tokketcontent.blob.core.windows.net/tokketweb/default.png)", cursor: owner ? "pointer" : "" }}>
                            {owner ?
                                <div className={'profile-avatar-edit-message'}>
                                    <FontAwesomeIcon icon={faCamera} style={{ marginRight: "5px" }} />
                                    <label>Update</label>
                                </div> : ""}
                        </div>
                        {owner ?
                            <Col>
                                <Button variant='primary'>Badges</Button>
                            </Col> :
                            ""
                        }
                    </Row>
                    <Row style={{ marginTop: "60px" }}>
                        <Col xs={12} style={{ textAlign: "center", fontWeight: "bold", fontSize: "25px" }}>{displayInfo["display_name"] ? `${displayInfo.display_name}` : ""}</Col>
                        <Col xs={12} style={{ fontSize: "18px", marginBottom: "15px", textAlign: "center" }}>
                            {displayInfo["is_user_display_handle_enabled"] && displayInfo["current_handle"] ?
                                `${displayInfo["current_handle"]}` :
                                <>
                                    {displayInfo["title_enabled"] && displayInfo["title_id"] ? `${displayInfo["title_id"]}` : ""}
                                </>
                            }
                        </Col>
                        <Col xs={12} style={{ fontSize: "14px", marginBottom: "15px", textAlign: "center" }}>
                            {editBio ?
                                <Row>
                                    <Col xs={true}>
                                        <TextField style={{ width: "100%" }} value={TempEditUser["bio"]} name="bio" onChange={this.SetUserUpdateValue} label="Bio" inputProps={{ maxLength: 300 }} />
                                        <p className={'character-count'}>{TempEditUser["bio"].length}/300</p>
                                    </Col>
                                    <Col xs={'auto'} style={{ padding: "unset" }}>
                                        <Button variant='primary' style={{ height: "50px", width: "50px" }} onClick={this.updateUserTrigger}>
                                            <FontAwesomeIcon icon={faCheck} size={'xl'} />
                                        </Button>
                                    </Col>
                                    <Col xs={'auto'}>
                                        <Button variant='danger' style={{ height: "50px", width: "50px" }} onClick={this.closeEditBio}>
                                            <FontAwesomeIcon icon={faTimes} size={'xl'} />
                                        </Button>
                                    </Col>
                                </Row> :
                                <>
                                    {displayInfo["bio"] ?
                                        `${displayInfo["bio"]}` :
                                        <i>
                                            User's bio data...
                                        </i>
                                    }
                                    {owner ?
                                        <FontAwesomeIcon style={{ marginLeft: "5px", cursor: "pointer" }} icon={faPenToSquare} onClick={this.setEditBio} /> : ""
                                    }
                                </>
                            }

                        </Col>
                        <Col xs={12} style={{ fontSize: "14px", marginBottom: "15px", textAlign: "center" }}>
                            {editWebsite ?
                                <Row>
                                    <Col xs={true}>
                                        <TextField style={{ width: "100%" }} value={TempEditUser["website"]} name="website" onChange={this.SetUserUpdateValue} label="Website" inputProps={{ maxLength: 300 }} />
                                        <p className={'character-count'}>{TempEditUser["website"].length}/300</p>
                                    </Col>
                                    <Col xs={'auto'} style={{ padding: "unset" }}>
                                        <Button variant='primary' style={{ height: "50px", width: "50px" }} onClick={this.updateUserTrigger}>
                                            <FontAwesomeIcon icon={faCheck} size={'xl'} />
                                        </Button>
                                    </Col>
                                    <Col xs={'auto'}>
                                        <Button variant='danger' style={{ height: "50px", width: "50px" }} onClick={this.closeEditWebsite}>
                                            <FontAwesomeIcon icon={faTimes} size={'xl'} />
                                        </Button>
                                    </Col>
                                </Row> :
                                <>
                                    {displayInfo["website"] ?
                                        <Link color="inherit" href={displayInfo["website"]} style={{ textDecoration: "none" }}>
                                            {displayInfo["website"]}
                                        </Link> :
                                        <i>
                                            User's website...
                                        </i>
                                    }
                                    {owner ?
                                        <FontAwesomeIcon style={{ marginLeft: "5px", cursor: "pointer" }} icon={faPenToSquare} onClick={this.setEditWebsite} /> : ""
                                    }
                                </>
                            }

                        </Col>
                        {displayInfo["country"] ?
                            <Col xs={6} md={4} className={'profile-detail-item'}>
                                <b className={'profile-detail-item-header'}>Country</b>
                                <div>{countries[displayInfo["country"].toUpperCase()].name}</div>
                                <ReactCountryFlag svg style={{ width: '35px', height: "25px", placeSelf: "center" }} countryCode={displayInfo["country"]} />
                            </Col> : ""
                        }

                        {displayInfo["email"] || displayInfo["Email"] ?
                            <Col xs={6} md={4} className={'profile-detail-item'}>
                                <b className={'profile-detail-item-header'}>Email</b>
                                <div>{displayInfo["email"] ? displayInfo["email"] : <>{displayInfo["Email"] ? displayInfo["Email"] : ""}</>}</div>
                            </Col> : ""
                        }

                        <Col xs={6} md={4} className={'profile-detail-item'}>
                            <b className={'profile-detail-item-header'}>Coins</b>
                            <div><img src={'https://tokketcontent.blob.core.windows.net/tokketweb/gold.gif'} style={{ width: "25px" }} /></div>
                            <div>{displayInfo["coins"] ? displayInfo["coins"] : "0"}</div>
                        </Col>

                        <Col xs={6} md={4} className={'profile-detail-item'}>
                            <b className={'profile-detail-item-header'}>Level</b>
                            <div>{displayInfo["points"] ? displayInfo["points"] : "0"} points</div>
                        </Col>

                        {displayInfo["birthday"] && (Moment(displayInfo["birthday"]).format('MM/D/YYYY') != "01/1/0001") ?
                            <Col xs={6} md={4} className={'profile-detail-item'}>
                                <b className={'profile-detail-item-header'}>Birthday</b>
                                <div>{Moment(displayInfo["birthday"]).format('MMMM D, YYYY')}</div>
                            </Col> : ""
                        }
                        <Col xs={6} md={4} className={'profile-detail-item'}>
                            <b className={'profile-detail-item-header'}>Joined</b>
                            <div>{Moment(displayInfo["joined"]).format('MMMM D, YYYY')}</div>
                        </Col>
                        {owner ?
                            <>
                                <hr style={{ width: "100%" }} />
                                {
                                    //put menu options here
                                }
                                <div style={{ display: "grid", gridGap: "5px" }}>
                                    <Button variant='primary' onClick={this.SetModalEdit} data-mode={'Display Name'}>Change Display Name</Button>
                                    <Button variant='primary' onClick={this.SetModalEdit} data-mode={'Birthday'}>Change Birthday</Button>
                                    {profile["id"] ?
                                        <>
                                            <Button variant='primary' onClick={this.SetModalEdit} data-mode={'PIN'}>Adjust PIN</Button>
                                        </> :
                                        <Button variant='primary' onClick={this.onChangePassword} data-mode={'Password'}>Change Password</Button>}
                                    <Button variant='primary' onClick={this.SetModalEdit} data-mode={'Title'}>Change Title</Button>
                                    <Button variant='primary'>Handles</Button>
                                    <Button variant='primary' onClick={this.SetModalEdit} data-mode={'Avatar'}>Change Avatar</Button>
                                    <Button variant='primary'>Royalty Membership</Button>
                                    <Button variant='primary' onClick={this.SetModalEdit} data-mode={'Country or State'}>Update Country or State</Button>
                                    <Button variant='primary' disabled={userProfiles.length >= profileLimitCount ? true : !userProfilesSet} onClick={this.setAddProfile}>Add Profile</Button>
                                    {!profile["id"] ?
                                        <Button variant='primary' onClick={this.setMoreSettings}>More Settings</Button> : ""}
                                </div>
                            </>
                            : ""
                        }
                    </Row>
                </div>
                {
                    displayToksSets ?
                        <Row className={'profile-tab-row'}>
                            <Col className={'profile-tab-container row'} xs={12} style={{ margin: "unset" }}>
                                <Col onClick={this.SetActiveTab} data-mode={'classtoks'} className={ActiveTab == "classtoks" ? 'btn profile-tab-btn active' : 'btn profile-tab-btn'}>Toks</Col>
                                <Col onClick={this.SetActiveTab} data-mode={'classsets'} className={ActiveTab == "classsets" ? 'btn profile-tab-btn active' : 'btn profile-tab-btn'}>Sets</Col>
                                {owner && userProfilesSet ?
                                    <Col onClick={this.SetActiveTab} data-mode={'profiles'} className={ActiveTab == "profiles" ? 'btn profile-tab-btn active' : 'btn profile-tab-btn'}>Profiles</Col> : ""
                                }
                            </Col>
                            <Col xs={12} style={{ textAlign: "center", marginTop: "25px", display: ActiveTab == "classtoks" ? "block" : "none" }}>
                                <TokTileContainer title={" "} query={{ userid: displayInfo["id"], publicfedd: false }} DisableNavbar={undefined} CardViewOption={undefined} bgColor={undefined} fontColor={undefined} proxyTitleBarActive={undefined} levelTitle={undefined} CommunityId={undefined} CommunityName={undefined} groupName={undefined} itemBase={undefined} tokTileTitleBar={undefined} items={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={undefined} saveTokData={undefined} />
                            </Col>
                            <Col xs={12} style={{ textAlign: "center", marginTop: "5px", display: ActiveTab == "classsets" ? "block" : "none" }}>
                                Sets Display
                            </Col>
                            <Col xs={12} style={{ textAlign: "center", marginTop: "5px", display: ActiveTab == "profiles" ? "block" : "none" }}>
                                <Row className={'member-list-container'} style={{ margin: "unset", display: "block" }}>
                                    {userProfiles ?
                                        <>
                                            {profile["id"] ?
                                                <Col xs={12} style={{ padding: "unset", margin: "unset", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                                                    <button type="button" className={"btn btn-danger"} style={{ border: "unset" }} onClick={this.destroyProfile}>
                                                        <div style={{ maxWidth: "max-content" }}>Exit Profile</div>
                                                    </button>
                                                </Col> : ""}

                                            {userProfiles.map(member => {
                                                return (
                                                    <Dropdown className={(profile["id"] == member["id"] ? 'active' : "") + ' member-list-item col-12'} style={{ padding: "unset", margin: "unset" }}>
                                                        <Dropdown.Toggle className={'dropdown-remove'} style={{ border: "unset" }}>
                                                            <div style={{ height: "46px", width: "46px", backgroundImage: `url(${member["user_photo"] ? member["user_photo"] : 'https://tokketcontent.blob.core.windows.net/tokketweb/default.png'})`, backgroundPosition: "center", backgroundSize: "contain", backgroundColor: "white" }}></div>
                                                            <div style={{ width: "max-content" }}>{member.display_name}</div>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {profile["id"] != member["id"] ? <Dropdown.Item onClick={this.setProfile} data-id={member["id"]}>Switch Profile</Dropdown.Item> : ""}
                                                            <Dropdown.Item onClick={this.setEditProfile} data-id={member["id"]}>Edit Profile</Dropdown.Item>
                                                            <Dropdown.Item onClick={this.setDeleteSelectedtProfile} data-id={member["id"]}>Delete Profile</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                )
                                            })}
                                        </> : ""
                                    }
                                </Row>
                            </Col>
                        </Row>

                        : ""
                }
            </div >
        )
    }
}

export default ProfileView;