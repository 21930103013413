import { Search } from '@mui/icons-material';
import axios from 'axios';
import { getAuth,onAuthStateChanged } from 'firebase/auth';
import { getSetToken, getSetUserId } from './accountServices';

const apiRoomUrl = process.env.REACT_APP_API_ROOM_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;
const apiCode = process.env.REACT_APP_API_CODE;

const apiUrl = process.env.REACT_APP_API_URL;

var userdata = [];
if(window.localStorage.getItem("userdata")){
    try {
        userdata = JSON.parse(window.localStorage.getItem("userdata"));
    } catch(err) { }
}

export async function connectToGroup(userid = "", groupid = "") {
    if (userid && groupid) {
        return await axios.post(`${apiRoomUrl}/addtoclassgroupchat/${userid}/${groupid}?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web'
                }
            });
    } else {
        return {};
    }
}

export async function sendMessageToGroup(groupid, item = []) {
    if (groupid && Array.isArray(item) && item.length > 0) {
        return await axios.post(`${apiRoomUrl}/sendmessagechat/${groupid}?code=${apiCode}`, item,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web'
                }
            });
    } else {
        return {};
    }
}

export async function deleteMessageInGroup(groupid, item = []) {
    if (groupid && Array.isArray(item) && item.length > 0) {
        return await axios.post(`${apiRoomUrl}/deletemessagechat/${groupid}?code=${apiCode}`, item,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web'
                }
            });
    } else {
        return {};
    }
}

export async function updateMessageInGroup(groupid, item = []) {
    if (groupid && Array.isArray(item) && item.length > 0) {
        return await axios.post(`${apiRoomUrl}/updatemessagechat/${groupid}?code=${apiCode}`, item,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web'
                }
            });
    } else {
        return {};
    }
}

export async function getMessages(groupid) {
    if (groupid) {
        return await axios.get(`${apiUrl}${apiVersion}/message/${groupid}?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'userid': await getSetUserId(),
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return {};
    }
}