import React from 'react'
import '../styles.css'
import './css/TokTileContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import Button from '@mui/material/Button';
import ButtonReact from 'react-bootstrap/Button';
import { apiTest, getClassTok, getToks, getFilterOptions } from '../services/classServices';
import { getToken } from '../services/accountServices';
import TokTile from './TokTile';
import TokTileView from './TokTileView';
import { createRoot } from 'react-dom/client';
import AddClassTok from './AddClassTok';
import { faFilter, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Typography } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { createRef } from 'react';
import TextField from '@mui/material/TextField';
import { Class } from '@mui/icons-material';
import TokTileCard from './TokTileCard';
import arrayShuffle from 'array-shuffle';
import { getUser } from '../services/accountServices';
import ProfileView from './ProfileView';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import toast, { Toaster } from 'react-hot-toast';
import { Tok } from '../interfaces/TokInterface';
import { TokUser } from '../interfaces/TokUserInterface';
const colors = [
  "d32f2f", "C2185B", "7B1FA2", "512DA8",
  "303F9F", "1976D2", "0288D1", "0097A7",
  "00796B", "388E3C", "689F38", "AFB42B",
  "FBC02D", "FFA000", "F57C00", "E64A19"
];
const shuffleColors = arrayShuffle(colors);
var colorCounter = -1;
const MySwal = withReactContent(Swal);

interface States {
  resultData: { [key: string]: any };
  items: Tok[];
  DataisLoaded: boolean;
  continuationToken: string;
  query: { [key: string]: any };
  Level1: string;
  Level2: string;
  Level3: string;
  queryInitial: { [key: string]: any };
  LoadMoreActive: boolean;
  newItems: Tok[];
  filterOptions: {
    displayText: string;
    value: string;
    options?: {
      level1?: any;
      displayText: string;
      value: any;
    }[];
  }[];
  ShowFilter: boolean;
  ShowFilterModal: boolean;
  ShowFilterModalLoader: boolean;
  FilterModalOptions: {
    displayText: string;
    value: string;
    options?: {
      displayText: string;
      value: any;
    }[]
  }[];
  FilterModalTarget: number | "";
  Types: {
    displayText: string;
    value: string;
  }[];
  Classes: any[];
  Categories: any[];
  ShowSort: boolean;
  sortOptions: {
    displayText: string;
    value: string;
  }[];
  ViewTokTile: boolean;
  ViewTokTileData: string;
  DisableNavbar: boolean;
  CardViewOption: boolean;
  Title: string;
  BackgrounColor: string;
  FontColor: string;
  filterQuery: {
    FilterItems: string;
    FilterDisplay: string;
    FilterBy: string;
  }[];
  ViewOptions: {
    displayText: string;
    value: string;
  }[];
  ActiveViewOption: string;
  ProxyTitleBarActive: boolean;
  LevelTitle: boolean;
  CurrentLink: string;
  ShowProfileModal: boolean;
  ShowProfileModalLoader: boolean;
  ProfileData: TokUser;
  ReloadProfileId: string;
  FailedProfileLoad: boolean;
  CommunityId: string;
  CommunityName: string;
  GroupId: string;
  GroupName: string;
  imagetype: number;
  BasePath: string;
  InitializeLoad: boolean;
  SearchActive: boolean;
  itemBase: string;
  toasterMsg: string | null;
}

interface Props {
  DisableNavbar: boolean;
  CardViewOption: boolean;
  bgColor: string;
  fontColor: string;
  proxyTitleBarActive: boolean;
  levelTitle: boolean;
  CommunityId: string;
  CommunityName: string;
  groupName: string;
  itemBase: string;
  tokTileTitleBar: React.RefObject<any>;
  title: string;
  query: { [key: string]: any };
  items: Tok[];
  addClassTokRef: React.RefObject<any>;
  addExistingClassTokTransferRef: React.RefObject<any>;
  saveTokData: (data: any) => void;
}

class TokTileContainer extends React.Component<Props, States> {
  addExistingClassTokTransferRef: React.RefObject<any>;
  AddClassTokRef: React.RefObject<any>;
  searchInput: React.RefObject<any>;
  tokTileTitleBar: React.RefObject<any>;
  // Constructor
  constructor(props) {
    super(props);
    var title = "Toks";
    if (props.title) {
      title = props.title;
    }
    var level1 = "";
    var level2 = "";
    var level3 = "";
    var groupId = "";
    if (props.query) {
      if (props.query["level1"]) {
        if (props.query["level1"] != "mytoks") {
          level1 = props.query["level1"].toLowerCase();
        }
      }
      if (props.query["level2"]) {
        if (props.query["level2"] != "private" && props.query["level2"] != "public") {
          level2 = props.query["level2"].toLowerCase();
        }
      }
      if (props.query["level3"]) {
        level3 = props.query["level3"].toLowerCase();
      }
      groupId = props.query["group_id"] ? props.query["group_id"] : "";
    }
    this.state = {
      resultData: [],
      items: props.items ? props.items : [],
      DataisLoaded: props.items ? true : false,
      continuationToken: "",
      query: props.query ? props.query : {},
      Level1: level1,
      Level2: level2,
      Level3: level3,
      queryInitial: props.query,
      LoadMoreActive: false,
      newItems: [],
      filterOptions: [
        //{
        //displayText: "Feed Options", value: "publicfeed", options: [
        //{ displayText: "Public", value: true },
        //{ displayText: "My Toks", value: false }
        //]
        //},
        {
          displayText: "Filter By", value: "FilterBy", options: [
            { displayText: "Class", value: "getClasses" },
            { displayText: "Category", value: "getCategories" },
            { displayText: "Type", value: "getType" }
          ]
        },
        {
          displayText: "Image", value: "imagetype", options: [
            { displayText: "Both", value: 0 },
            { displayText: "Image", value: 1 },
            { displayText: "No Image", value: 2 },
          ]
        },
      ],
      ShowFilter: false,
      ShowFilterModal: false,
      ShowFilterModalLoader: false,
      FilterModalOptions: [],
      FilterModalTarget: "",
      Types: [
        { displayText: "Basic", value: "Basic" },
        { displayText: "Detailed", value: "Detailed" },
        { displayText: "Mega", value: "Mega" },
        { displayText: "Pic", value: "Pic" },
        { displayText: "List", value: "List" },
        { displayText: "Q&a", value: "Q&a" },
      ],
      Classes: [],
      Categories: [],
      ShowSort: false,
      sortOptions: [
        {
          displayText: "Sort by Reference ID/#", value: "reference_id"
        },
        {
          displayText: "Sort by Title", value: "primary_text"
        },
      ],
      ViewTokTile: false,
      ViewTokTileData: "",
      DisableNavbar: props.DisableNavbar,
      CardViewOption: props.CardViewOption,
      Title: title,
      BackgrounColor: props.bgColor,
      FontColor: props.fontColor,
      filterQuery: [],
      ViewOptions: [
        {
          displayText: "Toks", value: "toks"
        },
        {
          displayText: "Tok Cards", value: "cards"
        },
      ],
      ActiveViewOption: "toks",
      ProxyTitleBarActive: props.proxyTitleBarActive,
      LevelTitle: props.levelTitle ? true : false,
      CurrentLink: "",
      ShowProfileModal: false,
      ShowProfileModalLoader: false,
      ProfileData: {},
      ReloadProfileId: "",
      FailedProfileLoad: true,
      CommunityId: props.CommunityId,
      CommunityName: props.CommunityName,
      GroupId: groupId,
      GroupName: props.GroupName,
      imagetype: 0,
      BasePath: "",
      InitializeLoad: true,
      SearchActive: false,
      itemBase: props.itemBase ? props.itemBase : "",
      toasterMsg: null,
    };

    this.reload = this.reload.bind(this);
    this.loadmore = this.loadmore.bind(this);

    this.showHideFilter = this.showHideFilter.bind(this);
    this.setFilterOptions = this.setFilterOptions.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.setFilterBy = this.setFilterBy.bind(this);
    this.submitFilterBy = this.submitFilterBy.bind(this);

    this.showHideSort = this.showHideSort.bind(this);
    this.setSortOptions = this.setSortOptions.bind(this);

    this.receiveTokTileView = this.receiveTokTileView.bind(this);
    this.deleteTok = this.deleteTok.bind(this);

    this.setTokSearch = this.setTokSearch.bind(this);
    this.searchInput = createRef();
    this.tokTileTitleBar = props.tokTileTitleBar;
    this.setViewOption = this.setViewOption.bind(this);
    this.addTokList = this.addTokList.bind(this);

    this.viewProfileView = this.viewProfileView.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
    this.reloadProfileLoad = this.reloadProfileLoad.bind(this);
    this.updateTok = this.updateTok.bind(this);
    this.AddClassTokRef = props.addClassTokRef;
    this.closeInfoView = this.closeInfoView.bind(this);
    if (!props.items) {
      this.getData({});
    } else {
      try {
        if (props.items.length == 0) {
          this.getData({});
        }
      } catch (err) { }
    }
    this.addExistingClassTokTransferRef = props.addExistingClassTokTransferRef;
    this.transferData = this.transferData.bind(this);
    this.addViewTok = this.addViewTok.bind(this);
    this.setToasterMsg = this.setToasterMsg.bind(this);
    //this.loadTokInfo = this.loadTokInfo.bind(this);
  }
  setToasterMsg(data) {
    var { toasterMsg } = this.state;
    if (data["msg"]) {
      switch (data["type"]) {
        case 1:
          toast.success(data["msg"], {
            id: toasterMsg,
          });
          break;
        case 2:
          toast.error(data["msg"], {
            id: toasterMsg,
          });
          break;
        case 0:
        default:
          toasterMsg = toast.loading(data["msg"]);
          toast.loading(data["msg"], {
            id: toasterMsg,
          });
          break;
      }
      this.setState({
        toasterMsg: toasterMsg
      })
    }
  }
  componentDidMount() {
    let closeInfoTemp = this.closeInfoView;
    window.addEventListener('popstate', function () {
      const path = window.location.pathname;
      let pathParts = path.split("/");
      if (!pathParts.includes("/tok/")) {
        closeInfoTemp();
      }
    });
  }
  /*
  componentDidMount() {
    const path = window.location.pathname;
    if (path.includes("/tok/")) {
      let pathParts = path.split("/");
      let tokid = "";
      let tokpk = "";
      let previousBaseLinkParts = new Array;
      for (let x = 0, getTok = false, idGet = false; x < pathParts.length; x++) {
        if (pathParts[x] == "tok" && !getTok) {
          getTok = true;
        } else if(pathParts[x]) {
          previousBaseLinkParts.push(pathParts[x]);
        }
        if (getTok && idGet) {
          tokpk = pathParts[x];
          break;
        }
        else if (getTok) {
          tokid = pathParts[x];
          idGet = true;
        }
      }
      if (tokid && tokpk && previousBaseLinkParts.length > 0) {
        this.loadTokInfo(tokid, tokpk, previousBaseLinkParts.join("/"));
      }
    } else {
      this.setState({
        BasePath: window.location.pathname
      })
    }
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        console.log("page change!!!");
        return target.apply(thisArg, argArray);
      },
    });
  }
  loadTokInfo(id, pk, basePath) {
    MySwal.fire({
      title: <p>Loading Tok.</p>,
      allowOutsideClick: false
    });
    MySwal.showLoading();
    getClassTok(id, pk).then(res => {
      if (res["status"] == 200) {
        if (res["data"]["resource"]) {
          this.setState({
            ViewTokTile: true,
            ViewTokTileData: res["data"]["resource"],
            CurrentLink: basePath,
            BasePath: basePath
          });
          
        } else {
          MySwal.update({
            title: <p>Tok no longer exist.</p>,
            icon: "warning"
          });
        }
      } else {
        MySwal.update({
          title: <p>An Error Occured</p>,
          icon: "warning"
        });
      }
    }).catch(err => {

    }).finally(() => {
      MySwal.hideLoading();
    })
  }
  */
  async getData(customQuery) {
    var { query, items, LoadMoreActive, imagetype, ShowFilterModal, itemBase } = this.state;
    await getToken();
    if (customQuery) {
      query = customQuery;
      if (LoadMoreActive) {
        this.setState({
          items: [],
          DataisLoaded: false
        });
      }
    }
    if (imagetype) {
      query["imagetype"] = imagetype;
    } else {
      query["imagetype"] = 0;
    }
    const response = await getToks(query, itemBase, "");
    var itemResult = response? response.data["result"] ? response.data.result : new Array: new Array;
    try {
      if (query["pagination"]) {
        itemResult = items.concat(itemResult);
      }
    } catch (err) { }
    try {
      const itemData = {
        query: query,
        items: itemResult,
        continuationToken: response.data["pagination"] ? response.data["pagination"] : ""
      }
      this.props.saveTokData(itemData);
    } catch (err) { }
    if (ShowFilterModal && itemResult.length == 0) {
      MySwal.fire({
        title: <p>No toks exist for this {query["FilterBy"][0] == 0 ? "Class" : query["FilterBy"][0] == 1 ? "Category" : "Type"} {query["FilterDisplay"][0]}</p>,
        allowOutsideClick: false,
        icon: "error"
      });
    }
    this.setState({
      resultData: response.data,
      continuationToken: response.data["pagination"] ? response.data["pagination"] : "",
      items: itemResult,
      DataisLoaded: true,
      LoadMoreActive: false,
      InitializeLoad: false
    });
  }
  reload() {
    this.setState({
      DataisLoaded: false,
      InitializeLoad: true
    }, () => {
      this.getData({});
    });
  }
  loadmore() {
    var { LoadMoreActive, continuationToken, query } = this.state;
    if (!LoadMoreActive && continuationToken.length > 0) {
      this.setState({
        LoadMoreActive: true
      });
      if (query) {
        query.pagination = continuationToken;
      } else {
        query = { "pagination": continuationToken }
      }

      this.getData(query)
    }
  }
  showHideSort() {
    const { ShowSort } = this.state;

    this.setState({
      ShowSort: !ShowSort
    });
  }
  showHideFilter() {
    const { ShowFilter } = this.state;

    this.setState({
      ShowFilter: !ShowFilter
    });
  }
  async getFilterData(query) {
    var { Categories, Classes } = this.state;
    const response = await getFilterOptions(query);
    let filterResults = new Array;
    if(response){
      if (response.data.resultEnum == 1) {
        for (let x = 0; x < response.data.resultObject.results.length; x++) {
          if (response.data.resultObject.results[x]["title"] && response.data.resultObject.results[x]["id"]) {
            let tempItem = { displayText: response.data.resultObject.results[x]["title"], value: response.data.resultObject.results[x]["id"] }
            filterResults.push(tempItem);
          }
        }
        if (query.FilterBy == 1) {
          Classes = filterResults;
        } else if (query.FilterBy == 2) {
          Categories = filterResults;
        }
      }
    }
    this.setState({
      ShowFilterModalLoader: false,
      FilterModalOptions: filterResults,
      FilterModalTarget: query.FilterBy,
      Categories: Categories,
      Classes: Classes
    });
  }
  setFilterOptions(e) {
    const { imagetype } = this.state;
    const target = e.target.getAttribute("data-target");
    const value = e.target.getAttribute("data-value");
    if (target == "imagetype") {
      if (imagetype != Number(value)) {
        this.setState({
          imagetype: Number(value),
          DataisLoaded: false
        }, () => {
          this.getData({});
        });
      }
    } else {
      switch (value) {
        case "getClasses":
          const { Classes } = this.state;
          this.setState({
            ShowFilterModal: true,
            ShowFilterModalLoader: true,
            FilterModalOptions: Classes,
            FilterModalTarget: 1
          });
          if (Classes.length == 0) {
            this.getFilterData({ FilterBy: 1 })
          }
          break;
        case "getCategories":
          const { Categories } = this.state;
          this.setState({
            ShowFilterModal: true,
            ShowFilterModalLoader: true,
            FilterModalOptions: Categories,
            FilterModalTarget: 2
          });
          if (Categories.length == 0) {
            this.getFilterData({ FilterBy: 2 })
          }
          break;
        case "getType":
          const { Types } = this.state;
          this.setState({
            ShowFilterModal: true,
            FilterModalOptions: Types,
            FilterModalTarget: 3
          });
          break;
        default:
          const { query } = this.state;
          var tempQuery = query;
          try {
            tempQuery[target] = value;
          } catch (err) {
            tempQuery = [];
            tempQuery[target] = value;
          }
          this.setState({
            query: tempQuery,
            items: [],
            continuationToken: "",
            DataisLoaded: false
          }, () => {
            this.getData({});
          });
          break;
      }
    }

  }
  setSortOptions(e) {
    const target = e.target.getAttribute("data-target");
    const value = e.target.getAttribute("data-value");
    const { query } = this.state;
    var tempQuery = query;

    try {
      tempQuery["orderby"] = target;
      tempQuery["descending"] = value;
    } catch (err) {
      tempQuery = [];
      tempQuery["orderby"] = target;
      tempQuery["descending"] = value;
    }
    this.setState({
      query: tempQuery,
      items: [],
      continuationToken: ""
    }, () => {
      this.getData({});
    });
  }
  setViewOption(e) {
    const value = e.currentTarget.getAttribute("data-value");
    this.setState({
      ActiveViewOption: value,
    });
  }
  setTokSearch() {
    const search = this.searchInput.current.querySelector("input").value;
    this.setState({
      DataisLoaded: false,
      items: [],
      InitializeLoad: true,
      SearchActive: search ? true : false
    });
    var { query } = this.state;
    if (query) {
      query.searchkey = "primary_text";
      query.searchvalue = search;
    } else {
      query = { "searchkey": "primary_text", "searchvalue": search }
    }

    this.getData(query)
  }
  closeModals() {
    this.setState({
      ShowFilterModal: false,
      ShowProfileModal: false,
      ShowProfileModalLoader: false,
      FailedProfileLoad: false,
    });
  }
  setFilterBy(e) {
    const target = e.target.getAttribute("data-target");
    const value = e.target.getAttribute("data-value");
    const display = e.target.getAttribute("data-display");
    const { filterQuery } = this.state;
    let tempQuery = filterQuery;
    let tempFilterItems: any[] = [];
    let tempFilterDisplays: any[] = [];

    try {
      tempFilterItems = tempQuery["FilterItems"];
      tempFilterDisplays = tempQuery["FilterDisplay"];
      if (!tempFilterItems.includes(value)) {
        tempFilterItems.push(value);
        tempFilterDisplays.push(display);
        tempQuery["FilterItems"] = tempFilterItems;
        tempQuery["FilterDisplay"] = tempFilterDisplays;
      } else {
        const index = tempFilterItems.indexOf(value);
        if (index !== -1) {
          tempFilterItems.splice(index, 1);
          tempFilterDisplays.splice(index, 1);
        }
        tempQuery["FilterItems"] = tempFilterItems;
        tempQuery["FilterDisplay"] = tempFilterDisplays;
      }
    } catch (err) {
      console.log(err);
      tempQuery = [];
      tempQuery["FilterBy"] = target;
      tempFilterItems.push(value);
      tempFilterDisplays.push(display);
      tempQuery["FilterItems"] = tempFilterItems;
      tempQuery["FilterDisplay"] = tempFilterDisplays;
    }
    if (tempFilterItems.length === 0) {
      tempQuery = [];
    } else {
      tempQuery["FilterBy"] = target;
    }
    console.log(tempQuery);
    this.setState({
      filterQuery: tempQuery
    });
  }
  submitFilterBy(e) {
    const { filterQuery } = this.state;
    this.setState({
      DataisLoaded: false,
      items: []
    })
    this.getData(filterQuery);
  }
  receiveTokTileView(data) {
    this.setState({
      ViewTokTile: true,
      ViewTokTileData: data,
      CurrentLink: window.location.pathname
    });
  }
  deleteTok(deleteTok) {
    var { CurrentLink, items, query, continuationToken } = this.state;
    if (CurrentLink) {
      window.history.pushState({}, "Tokket", CurrentLink);
    }
    if (items && deleteTok) {
      for (let x = 0; x < items.length; x++) {
        if (items[x].id == deleteTok.id && items[x].pk == deleteTok.pk) {
          items.splice(x, 1);
          break;
        }
      }
    }
    try {
      const itemData = {
        query: query,
        items: items,
        continuationToken: continuationToken ? continuationToken : ""
      }
      this.props.saveTokData(itemData);
    } catch (err) { }
    this.setState({
      ViewTokTile: false,
      ViewTokTileData: "",
      items: items
    });
  }
  addTokList(data) {
    var { items, query, continuationToken } = this.state;
    items.unshift(data);
    try {
      const itemData = {
        query: query,
        items: items,
        continuationToken: continuationToken ? continuationToken : ""
      }
      this.props.saveTokData(itemData);
    } catch (err) { }
    this.setState({
      items: items,
    });
  }
  viewProfileView(data) {
    if (data) {
      this.getProfileData(data);
      this.setState({
        ShowProfileModal: true,
        ShowProfileModalLoader: true,
        FailedProfileLoad: false,
        ProfileData: {}
      });
    }
  }
  getProfileData(id) {
    getUser(id).then(response => {
      if(response){
        if (response["data"]["status_code"] == 200) {
          if (response["data"]["result"]) {
            this.setState({
              ShowProfileModalLoader: false,
              FailedProfileLoad: false,
              ProfileData: response["data"]["result"]
            });
          }
  
        } else {
          //set a refresh button if failed
          this.setState({
            ReloadProfileId: id,
            ShowProfileModalLoader: false,
            FailedProfileLoad: true
          });
        }
      }
    }).catch(response => {
    }).finally(() => {
    });
  }
  reloadProfileLoad() {
    const { ReloadProfileId } = this.state;
    this.getProfileData(ReloadProfileId);
    this.setState({
      ShowProfileModal: true,
      ShowProfileModalLoader: true,
      FailedProfileLoad: false,
      ProfileData: {}
    });
  }
  updateTok(updatedTok) {
    var { items } = this.state;
    if (items) {
      for (let x = 0; x < items.length; x++) {
        if (items[x].id == updatedTok.id && items[x].pk == updatedTok.pk) {
          items[x] = updatedTok;
          break;
        }
      }
    }
    this.setState({
      items: items
    });
  }
  closeInfoView() {
    var { CurrentLink } = this.state;
    if (CurrentLink) {
      window.history.pushState({}, "Tokket", CurrentLink);
    }
    this.setState({
      ViewTokTile: false,
      ViewTokTileData: ""
    });
  }
  transferData(e) {
    const data = e.target.getAttribute("data-data");
    const overwrite = e.target.getAttribute("data-overwrite");
    if (data) {
      var dataJson = JSON.parse(data);
      var newList = new Array;
      if (overwrite) {
        newList = newList.concat(dataJson);
      } else {
        var { items } = this.state;

        newList = newList.concat(dataJson);
        newList = newList.concat(items);
      }
      e.target.setAttribute("data-data", "");
      e.target.setAttribute("data-removevdata", "");
      this.setState({
        items: newList,
      });
    }
  }
  addViewTok(tok) {
    var { items, CurrentLink } = this.state;
    items.unshift(tok);
    var receiveTokTileView = this.receiveTokTileView;
    this.setState({
      items: items,
    }, () => {
      this.setState({
        ViewTokTile: false,
        ViewTokTileData: ""
      }, () => {
        MySwal.fire({
          title: <p>Opening new Tok</p>,
          allowOutsideClick: false
        });
        MySwal.showLoading();
        window.history.pushState({}, "Tokket", CurrentLink);
        setTimeout(function () {
          MySwal.close();
          receiveTokTileView(tok);
        }, 2000);
      });
    });
  }

  render() {
    var { itemBase, SearchActive, InitializeLoad, filterQuery, BasePath, imagetype, GroupId, GroupName, CommunityId, CommunityName, FailedProfileLoad, ProfileData, ShowProfileModalLoader, ShowProfileModal, LevelTitle, Level1, Level2, Level3, ProxyTitleBarActive, ActiveViewOption, ViewOptions, CardViewOption, FontColor, BackgrounColor, Title, DataisLoaded, items, continuationToken, LoadMoreActive, ShowFilter, filterOptions, query, ShowFilterModal, ShowFilterModalLoader, FilterModalOptions, FilterModalTarget, ShowSort, sortOptions, ViewTokTile, ViewTokTileData, DisableNavbar } = this.state;
    const customClass = "toktile-add-btn margin-right-btn";
    var loadmoreTrigger = this.loadmore;
    document.querySelector("body").addEventListener("scroll", function () {
      let documentHeight = document.body.scrollHeight;
      let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
      let modifier = 10;
      if (currentScroll + modifier > documentHeight) {
        if (DataisLoaded && !LoadMoreActive && continuationToken.length > 0) {
          loadmoreTrigger();
        }
      }
    });
    if (LevelTitle) {
      Title = Level1;
      if (Level2) {
        Title = Title + " - " + Level2;
        if (Level3) {
          Title = Title + " - " + Level3;
        }
      }
    }
    return (
      <Grid ref={this.tokTileTitleBar} container spacing={1} style={{ marginTop: "15px" }}>
        <Button ref={this.addExistingClassTokTransferRef} onClick={this.transferData} data-data="" hidden></Button>
        {ShowProfileModal ?
          <Modal className={'dark-background-2 profile-view'} fullscreen={true} show={true} style={{ zIndex: "1206" }}>
            <Modal.Header className={'dark-background-2'}>
              <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                <Typography fontWeight={"bold"} marginRight={"auto"}>View Profile</Typography>

                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
              </div>

            </Modal.Header>
            <Modal.Body className={'dark-background-2'}>
              {ShowProfileModalLoader ?
                <div style={{ height: '1000px' }}><WaitSpinner /></div> :
                <>
                  {FailedProfileLoad ?
                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                      An Error Occured.
                      <ButtonReact variant='primary' style={{ marginRight: "5px" }} onClick={this.reloadProfileLoad}>
                        Refresh
                      </ButtonReact>
                    </div> :
                    <ProfileView user={ProfileData} displayToksSets={true} changePasswordTrigger={undefined} profile={undefined} updateLoginData={undefined} updateProfileData={undefined} />
                  }
                </>
              }
            </Modal.Body>
          </Modal> : ""
        }
        {ShowFilterModal ?
          <Modal size='lg' show={true} onHide={this.closeModals} style={{ zIndex: "1300" }}>
            <Modal.Header>
              <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                <Typography fontWeight={"bold"}>More Filter Options</Typography>
                <ButtonReact onClick={this.submitFilterBy} variant="success" style={{ display: "flex", alignItems: "center" }}>
                  <span>Apply Filter</span>
                </ButtonReact>
              </div>
            </Modal.Header>
            <Modal.Body className={'filter-options-containers'}>
              {FilterModalOptions.map((option) => (
                <div className={filterQuery["FilterItems"] ? filterQuery["FilterItems"].includes(option.value) ? "active filter-option" : 'filter-option' : "filter-option"} style={{ cursor: "pointer" }} data-display={option.displayText} onClick={this.setFilterBy} data-target={FilterModalTarget} data-value={option.value}>
                  <span>{option.displayText}</span>

                  {filterQuery["FilterItems"] ? filterQuery["FilterItems"].includes(option.value) ? <FontAwesomeIcon icon={faCheckCircle} size={"sm"} /> : <FontAwesomeIcon icon={faCircle} size={"sm"} /> : <FontAwesomeIcon icon={faCircle} size={"sm"} />}
                </div>
              ))}
              {ShowFilterModalLoader ? <WaitSpinner /> : ""}
            </Modal.Body>
          </Modal>
          : ""
        }

        {!DisableNavbar ?
          <Grid ref={this.AddClassTokRef} className={'text-color-white'} container spacing={1} style={{ backgroundColor: BackgrounColor, color: FontColor, padding: "3px", display: ProxyTitleBarActive ? "none" : this.AddClassTokRef ? "none" : "" }}>
            <div style={{ marginRight: "auto", display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "10px", fontSize: "21px" }}>
              {Title}
            </div>
            <div style={{ position: "relative" }}>
              <TextField ref={this.searchInput} className={"search-field text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
            </div>
            <ButtonReact variant='primary' className={'search-btn'} onClick={this.setTokSearch} style={{ marginRight: "5px" }}>
              <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
            </ButtonReact>
            {/* Temporary, will be converted to a scroll event */}
            <AddClassTok edit={false} defaulttok={undefined} addTokBtnRef={undefined} customTitle={Level1 ? "Tok" : ""} publicfeed={query["publicfeed"] ? true : false} tok={{ level1: Level1, level2: Level2, level3: Level3, community_id: CommunityId, community_name: CommunityName, group_id: GroupId, group_name: GroupName }} customClass={customClass} receiveData={this.addTokList} updateTok={this.updateTok} />
            <div className={'refresh-btn'} onClick={this.reload}>
              <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
            </div>
            <div onClick={this.showHideFilter} style={{ display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
              FILTER
              <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faFilter} />
            </div>
            <div onClick={this.showHideSort} style={{ display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
              Sort
              <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faArrowDownShortWide} />
            </div>
          </Grid>
          : ""}

        <div className={'col-12 filter-options'} style={{ display: ShowFilter && !ProxyTitleBarActive ? "" : "none" }}>
          <Row style={{ justifyContent: "center" }}>
            {filterOptions.map((filterOption, index) => (
              <Col md='auto' sm={6} xs={6}>
                <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{filterOption.displayText}</div>

                {filterOption.options.map(option => {
                  let addActiveState = false;
                  if (filterOption.value == "imagetype" && imagetype == option.value) {
                    addActiveState = true;
                  }
                  return (
                    <div style={{ cursor: "pointer" }} onClick={this.setFilterOptions} className={addActiveState ? `${filterOption.value}-${option.value} filter-option active` : `${filterOption.value}-${option.value} filter-option`} data-target={filterOption.value} data-value={option.value}>{option.displayText}</div>
                  )
                })}
              </Col>
            ))}
          </Row>
        </div>
        <div className={'col-12 sort-options'} style={{ display: ShowSort && !ProxyTitleBarActive ? "" : "none" }}>
          <Row style={{ justifyContent: "center" }}>
            {sortOptions.map((sortOption, index) => (
              <Col md='auto' sm={6} xs={6}>
                <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{sortOption.displayText}</div>
                <div style={{ cursor: "pointer" }} onClick={this.setSortOptions} className={`${sortOption.value}-${false}`} data-target={sortOption.value} data-value={false}>Ascending</div>
                <div style={{ cursor: "pointer" }} onClick={this.setSortOptions} className={`${sortOption.value}-${true}`} data-target={sortOption.value} data-value={true}>Descending</div>
              </Col>
            ))}
          </Row>
        </div>
        {Level1 != "familyfriends" && items.length == 0 && !InitializeLoad ?
          <div className={'col-12 row'} style={{ marginTop: "5px" }}>
            <div style={{ width: "60px", paddingLeft: "unset" }}>
              <img src={Level1 ? "https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" : "https://tokketcontent.blob.core.windows.net/tokketweb/tokstarguy1.jpg"} style={{ width: "100%" }} />
            </div>
            <Col>
              <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600", fontSize: "12px", height: "fit-content" }}>
                {SearchActive ? "No tok(s) found." : Level1 ? "Post important memories to these channels. You, your family, and your friends will never miss a post whether the subchannels are checked once a day, once a week, once a month, or once a year." : "No toks yet. Click on the + Add Tok button to enter your first tok and let your friends know that you would like to keep them posted through Tok Channels.."}
              </div>
            </Col>
          </div> : ""
        }
        {Level1 == "familyfriends" ?
          <div className={'col-12 row'} style={{ marginTop: "5px" }}>
            <div style={{ width: "60px", paddingLeft: "unset" }}>
              <img src={items.length == 0 ? "https://tokketcontent.blob.core.windows.net/tokketweb/tokstarguy1.jpg" : items.length % 2 ? "https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl2.jpg" : "https://tokketcontent.blob.core.windows.net/tokketweb/tokstarguy1.jpg"} style={{ width: "100%" }} />
            </div>
            <Col>
              <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600", fontSize: "12px", height: "fit-content" }}>
                {items.length == 0 ? "No toks yet. Click on the + Add Tok button to enter your first tok and let your friends know that you would like to keep them posted through Tok Channels." :
                  items.length == 1 ? "There has only been one post to this subchannel. Add a post to chronicle memories in your life for you, your family, and your friends to view at their convenience." :
                    items.length % 2 ?
                      "Ask your friends to post something to this subchannel at their convenience and you will see it at your convenience since it will always be in the subchannel.." :
                      "Add a post to share important memories in your life with family and friends. They will not miss it since it is always available and will see it when they get a chance to check out Tok Channels."
                }
              </div>
            </Col>
          </div> : ""
        }
        {CardViewOption ?
          <div className={'col-12'}>
            <Row className={'view-tok-option-container'}>
              {ViewOptions.map((option, index) => (
                <Col xs={'auto'} className={ActiveViewOption == option.value ? 'view-tok-option active' : 'view-tok-option'} onClick={this.setViewOption} data-value={option.value}>
                  <div className={'view-tok-option-display'}>{option.displayText}</div>
                </Col>
              ))}
            </Row>
          </div> : ""
        }
        {!DataisLoaded && items ? <WaitSpinner /> :
          <>
            {items.map(card => {
              colorCounter++;
              if (colorCounter == 16) {
                colorCounter = 0;
              }
              return (
                <>
                  {ActiveViewOption == "toks" ?
                    <TokTile setToasterMsg={this.setToasterMsg} updateTok={this.updateTok} card={card} level1={Level1} level2={Level2} level3={Level3} group={GroupName} community={CommunityName} key={`${card.id}-${card.pk}-${colorCounter}`} color={shuffleColors[colorCounter]} receiveData={this.receiveTokTileView} viewProfileView={this.viewProfileView} /> :
                    <TokTileCard card={card} key={`${card.id}-${card.pk}-${colorCounter}`} />
                  }
                </>
              )
            })}</>}

        {LoadMoreActive ? <WaitSpinner /> : ""}
        {ViewTokTile ?
          <TokTileView tok={ViewTokTileData} groupId={GroupId} items={itemBase} closeInfoView={this.closeInfoView} deleteTok={this.deleteTok} updateTok={this.updateTok} addViewTok={this.addViewTok} />
          : ""}
        <div className={"exception"}>
          <Toaster />
        </div>
      </Grid>
    );
  }
}

export default TokTileContainer;