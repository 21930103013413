import React from 'react'
import { useEffect } from 'react';
import '../styles.css'
import './css/CommunityView.css';
import { faArrowLeft, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import TokTileContainer from './TokTileContainer';
import GroupsContainer from './GroupsContainer';
import { updateGroup } from '../services/classServices';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddEditClassGroup from './AddEditClassGroup';
const CommunityView = (props) => {
    const community = props.community;
    const [editDescription, setEditDescription] = useState(false);
    const [editDescriptionValue, setEditDescriptionValue] = useState(community.description);
    const [viewToks, setViewToks] = useState(true);

    const MySwal = withReactContent(Swal);
    const communityColor = "rgb(51, 72, 139)";
    const groupQuery = {
        community_id: community.id
    }
    const tokQuery = {
        pkbase: "channeltoks",
        level1: community.level0,
        level2: community.level1,
        level3: community.level2,
        communityid: community.id,
        publicfeed: false
    }
    function closeView() {
        props.closeView(true)
    }
    const updateDescriptionGroup = (e) => {
        MySwal.fire({
            title: <p>Updating Group Description</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        var tempCommunity = community;
        tempCommunity["description"] = editDescriptionValue;
        updateGroup(tempCommunity).then(response => {
            if(response){
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Success</p>,
                        icon: "success"
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        });
    }
    return (
        <div>
            <div style={{ color: "white", backgroundColor: "rgb(51, 72, 139)", display: "flex", padding: "12px", alignItems: "center", fontSize: "22px" }}>
                <div>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={closeView} style={{ cursor: "pointer" }} />
                </div>
                <div style={{ textAlign: "center", width: "100%" }}>
                    {community["secondary_text"] ? `${community["secondary_text"]}: ` : ""}{community.name}
                </div>
                <div>
                    <AddEditClassGroup
                        updateGroup={undefined}
                        icon={"pen"}
                        triggerBtn={" "}
                        customBtnStyle={{ backgroundColor: "rgb(49, 83, 143)", fontWeight: "bold", color: "white" }}
                        group={community}
                        receiveData={undefined}
                    />
                </div>
            </div>
            {editDescription ?
                <>
                    <div className={'community-info-description'}>
                        <TextField style={{ width: "100%" }} multiline minRows={1} onChange={(e) => setEditDescriptionValue(e.target.value)} value={editDescriptionValue} inputProps={{ maxLength: 300 }} />
                        <div style={{ float: "right", position: "absolute", right: "5px", bottom: "5px" }}>{editDescriptionValue.length}/300</div>
                    </div>
                    <Row style={{ justifyContent: "center", marginTop: "5px" }}>
                        <Col className='col-auto' style={{ padding: "unset", paddingRight: "2.5px" }}><Button variant='contained' color='success' onClick={updateDescriptionGroup}>Save</Button></Col>
                        <Col className='col-auto' style={{ padding: "unset", paddingLeft: "2.5px" }}><Button variant='contained' color='error' onClick={() => setEditDescription(false)}>Close</Button></Col>
                    </Row>
                </>

                : <div className={'community-info-description'}>
                    {community.description}
                    <div style={{ float: "right" }}><FontAwesomeIcon icon={faPenToSquare} style={{ cursor: "pointer" }} onClick={() => setEditDescription(true)} /></div>
                </div>
            }
            <Row style={{ margin: "5px 0px" }}>
                <Col className='col-auto community-info-view-btn' style={{ borderColor: communityColor, color: viewToks ? "white" : communityColor, backgroundColor: viewToks ? communityColor : "" }} onClick={() => setViewToks(true)}>Community Toks</Col>
                <Col className='col-auto community-info-view-btn' style={{ borderColor: communityColor, color: viewToks ? communityColor : "white", backgroundColor: viewToks ? "" : communityColor }} onClick={() => setViewToks(false)}>Groups</Col>
            </Row>
            <div style={{ display: viewToks ? "" : "none" }}>
                <TokTileContainer title={"Community Toks"} CommunityName={community["name"] ? community.name : ""} CommunityId={community.id} query={tokQuery} DisableNavbar={undefined} CardViewOption={undefined} bgColor={undefined} fontColor={undefined} proxyTitleBarActive={undefined} levelTitle={undefined} groupName={undefined} itemBase={undefined} tokTileTitleBar={undefined} items={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={undefined} saveTokData={undefined} />
            </div>
            <div style={{ display: viewToks ? "none" : "unset" }}>
                <GroupsContainer MenuKind={undefined} displayWarning={undefined} groupJoinedTrigger={null} EnableTitle={true} CustomTitle={"Community Groups"} disableUserId={true} CommunityId={community.id} Navbar={true} query={groupQuery} />
            </div>
        </div>
    );
}

export default CommunityView;