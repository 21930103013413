import React from 'react'
import '../styles.css'
import './css/DocumentsContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { addTok } from '../services/tokServices';
import { getToken } from '../services/accountServices';
import { createRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import DocumentSlot from './DocumentSlot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '@mui/material';
import Iframe from 'react-iframe';
import { createRoot } from 'react-dom/client';
import { randomString, idFormat } from '../services/helperTools';
import FileLoader from './FileLoader';
import { uploadFile } from '../services/fileService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Button from 'react-bootstrap/Button';
import { addClassTok, getToks } from '../services/classServices';
import { Tok } from '../interfaces/TokInterface';
import { Group } from '../interfaces/GroupInterface';

const MySwal = withReactContent(Swal);
interface States {
    items: Tok[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    DocumentView: boolean;
    Document: Tok;
    UploadFileView: boolean;
    UploadFileModal: string;
    UploadEnabledBtn: boolean;
    SetFile: {
        url: string;
        file: {
            name: string;
        }
        request: string;
    };
    group: Group;
    resultData: { [key: string]: any };
}

interface Props {
    addDocTriggerRef: React.RefObject<any>;
    query: { [key: string]: any };
    group: Group;
}
class DocumentsContainer extends React.Component<Props, States> {
    addDocTriggerRef: React.RefObject<any>;
    fileContainer: React.RefObject<any>;
    uploadBtn: React.RefObject<any>;
    fileUploadContainer: React.RefObject<any>;
    fileUpload: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            DocumentView: false,
            Document: {} as Tok,
            UploadFileView: false,
            UploadFileModal: "",
            UploadEnabledBtn: false,
            SetFile: null,
            group: props.group,
            resultData: {}
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);
        this.viewDocument = this.viewDocument.bind(this);
        this.closeModals = this.closeModals.bind(this);
        this.removeDocument = this.removeDocument.bind(this);

        this.addDocTriggerRef = props.addDocTriggerRef;
        this.fileContainer = createRef();
        this.uploadBtn = createRef();
        this.fileUploadContainer = createRef();
        this.fileUpload = createRef();
        this.fileUploadStart = this.fileUploadStart.bind(this);
        this.callUploadDocModal = this.callUploadDocModal.bind(this);
        this.closeFileUploadModal = this.closeFileUploadModal.bind(this);
        this.uploadFileConfirm = this.uploadFileConfirm.bind(this);

        this.removeFile = this.removeFile.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    async getData(customQuery) {
        var { query, items, group } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
        }
        const response = await getToks(query, "docs", group["id"] ? group["id"] : "");
        var itemResult = response.data["result"] ? response.data["result"] : [];
        if (customQuery) {
            itemResult = items.concat(itemResult);
        }
        this.setState({
            resultData: response.data,
            continuationToken: response.data.continuationToken,
            items: itemResult,
            LoadActive: false
        });
    }

    reload() {
        this.setState({
            LoadActive: true,
            items: []
        });
        this.getData({});
    }
    loadmore() {
        this.setState({
            LoadActive: true
        });
        var { query, continuationToken } = this.state;
        if (query) {
            query.pagination = continuationToken;
        } else {
            query = { "pagination": continuationToken }
        }

        this.getData(query)
    }
    viewDocument(card) {
        this.setState({
            DocumentView: true,
            Document: card
        });
    }
    removeDocument(card) {
        var { items } = this.state;
        if (items) {
            for (let x = 0; x < items.length; x++) {
                if (items[x].id == card.id && items[x].pk == card.pk) {
                    items.splice(x, 1);
                    break;
                }
            }
        }
        this.setState({
            items: items,
        });
    }
    closeModals() {
        this.setState({
            DocumentView: false,
            Document: {} as Tok
        });
    }

    fileUploadStart(e) {
        const rootMain = createRoot(this.fileContainer.current);
        const file = e.target.files[0];
        const fileId = randomString(10);
        const fileRef = fileId + "-" + file.name;

        rootMain.render(<FileLoader name={file.name} fileRef={fileRef} removeFile={this.removeFile} />);
        e.target.parentNode.style.display = "none";
        uploadFile(file, fileId).then(response => {
            let tempSetFile = response["request"];
            tempSetFile["file"] = e.target.files[0];
            const { UploadEnabledBtn } = this.state;
            if (document.querySelector(".classtok-doc." + fileRef.replace(/[^a-zA-Z0-9]/g, ''))) {
                this.setState({
                    UploadEnabledBtn: true,
                    SetFile: tempSetFile
                });
            }
        }).catch(response => {
        }).finally(() => {
        });
    }
    removeFile(event) {
        const rootMain = createRoot(this.fileContainer.current);
        rootMain.render("");
        this.fileUploadContainer.current.style.display = "flex";
        this.setState({
            SetFile: null,
            UploadEnabledBtn: false,
            UploadFileModal: ""
        });
    }
    callUploadDocModal() {
        this.setState({
            UploadFileView: true,
            UploadFileModal: ""
        });
    }
    closeFileUploadModal() {
        this.setState({
            UploadFileView: false,
            UploadFileModal: ""
        });
    }
    uploadFileConfirm() {
        var { SetFile, group, items } = this.state;
        const localUser = localStorage.getItem("userdata");
        var localUserJson = {};
        if (localUser) {
            localUserJson = JSON.parse(localUser)
        }
        let file = {
            "image": SetFile["url"] ? SetFile["url"] : "",
            "primary_text": SetFile["file"]["name"] ? SetFile["file"]["name"] : "",
            "secondary_text": localUserJson["display_name"] ? localUserJson["display_name"] : "",
            "tok_group": "Basic",
            "is_detail_based": false,
            "is_mega_tok": false,
            "category": "Document",
            "tok_type": group["name"] ? group["name"] : "",
            "group_id": group["id"] ? group["id"] : "",
            "user_photo": localUserJson["user_photo"] ? localUserJson["user_photo"] : "",
            "user_id": localUserJson["id"] ? localUserJson["id"] : "",
            "user_display_name": localUserJson["display_name"] ? localUserJson["display_name"] : "",
            "tok_type_id": "toktype-basic-" + idFormat(group["name"] ? group["name"] : ""),
            "country": localUserJson["country"] ? localUserJson["country"] : "",
            "state": localUserJson["state"] ? localUserJson["state"] : "",
            "items": "tokdocs-toks",
            "label": "tok",
            "tokgroupid": "basic",
            "language": "english"
        }
        MySwal.fire({
            title: <p>Adding Document</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        addClassTok(file, group["id"] ? group["id"] : "", "docs").then(response => {
            if(response){
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Document Added</p>,
                        icon: "success"
                    });
                    this.fileUploadContainer.current.style.display = "block";
                    this.fileContainer.current.style.display = "none";
                    if (items) {
                        items = new Array;
                        items.push(response.data["result"]);
                    } else {
                        items.unshift(response.data["result"]);
                    }
    
                    this.setState({
                        UploadEnabledBtn: false,
                        items: items,
                        UploadFileView: false
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
            MySwal.hideLoading();
        }).catch(response => {

            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
            MySwal.hideLoading();
        }).finally(() => {
        });
    }
    render() {
        var { LoadActive, items, continuationToken, DocumentView, Document, UploadFileView, UploadEnabledBtn } = this.state;
        let itemExist = false;
        if (items) {
            itemExist = true;
        }
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <div>
                <Modal size='lg' show={UploadFileView} onHide={this.closeFileUploadModal} style={{ zIndex: "1300" }}>
                    <Modal.Header>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} onClick={this.closeFileUploadModal} size={'lg'} icon={faTimes} />
                            <Typography fontWeight={"bold"} fontSize={'39px'}>Upload File</Typography>
                            <Button disabled={!UploadEnabledBtn} onClick={this.uploadFileConfirm} variant="primary" style={{ display: "flex", alignItems: "center", opacity: UploadEnabledBtn ? "" : "0%" }}>
                                <span>Upload</span>
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div ref={this.fileUploadContainer} style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{ display: "flex" }} variant='outline-dark' onClick={(e) => { this.fileUpload.current.click() }}>
                                <div style={{ marginRight: "5px" }}>
                                    <FontAwesomeIcon icon={faFile} />
                                </div>
                                Select File
                            </Button>
                            <input ref={this.fileUpload} type="file" onChange={this.fileUploadStart} hidden />
                        </div>
                        <div ref={this.fileContainer}></div>
                    </Modal.Body>
                </Modal>
                <Modal size='lg' show={DocumentView} onHide={this.closeModals} style={{ zIndex: "1206" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <Typography fontWeight={"bold"} marginRight={"auto"}>View Document</Typography>

                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Iframe width={'100%'} styles={{ height: "75vh" }} url={Document["image"] ? "https://docs.google.com/gview?url=" + Document.image + "&embedded=true" : ""} />
                    </Modal.Body>
                </Modal>

                <Row>
                    {!itemExist ?
                        <Row className={'col-auto'}>
                            <div style={{ width: "90px" }}>
                                <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" style={{ width: "100%" }} />
                            </div>
                            <Col>
                                <div className={'speechbubble-doc speechbubble-doc-purple'}>There are no documents yet.</div>
                            </Col>
                        </Row> : ""}
                    <Col xs={'auto'} style={{ margin: !itemExist ? 'auto 0' : 'auto' }}>

                        <Button ref={this.addDocTriggerRef} onClick={this.callUploadDocModal} variant="success" style={{ marginLeft: "auto", fontSize: "24px", display: "flex", alignItems: "center", padding: "15px" }}>
                            <div className={'parrot-write-icon'}></div>
                            <span>Add Document</span>
                        </Button>
                    </Col>
                </Row>

                <Row style={{ padding: "15px 0px", margin: "unset", marginTop: "5px", borderTop: "3px solid", borderBottom: "3px solid", fontSize: "16px" }}>
                    <Col xs={6}>Name</Col>
                    <Col xs={6} style={{ float: "right" }}>
                        Owner
                        <FontAwesomeIcon size='xl' onClick={this.reload} icon={faRefresh} style={{ cursor: "pointer", float: "right" }} />
                    </Col>
                </Row>
                <Row className={'document-container'}>
                    {items ?
                        <>
                            {items.map((item, index) => (
                                <DocumentSlot document={item} viewDocument={this.viewDocument} removeDocument={this.removeDocument} />
                            ))}
                        </> : ""
                    }
                    {LoadActive ? <WaitSpinner /> : ""}
                </Row>
            </div>

        );
    }
}

export default DocumentsContainer;