import React, { useRef } from 'react'
import '../styles.css'
import './css/ClassSetsContainer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faEllipsisVertical, faPlay } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { deleteSet } from '../services/classServices';
import withReactContent from 'sweetalert2-react-content';
import AddEditClassSet from './AddEditClassSet';
const MySwal = withReactContent(Swal);
//import './css/TokTile.css';
const ClassSetTile = (props) => {
    var card = props.card;
    const color = props.color;
    const addClassSetTrigger = useRef();
    const studyGameSet = props.studyGameSet ? props.studyGameSet : false;
    const [showPlayMenu, setShowPlayMenu] = useState(false);
    function setView() {
        props.setView(card)
    }
    function addToktoSet() {
        props.addToktoSet(card)
    }
    const playMenu = (e) => {
        e.stopPropagation();
        setShowPlayMenu(!showPlayMenu);
    }
    function playTokChoiceStart() {
        props.playTokChoice(card);
    }
    function playTokCardsStart() {
        props.playTokCards(card);
    }
    function playTokMatchStart() {
        props.playTokMatch(card);
    }
    const playStudyGame = (e) => {
        if (studyGameSet == "tokchoice") {
            playTokChoiceStart();
        } else if (studyGameSet == "tokcards"){
            playTokCardsStart();
        }
        else if (studyGameSet == "tokmatch"){
            playTokMatchStart();
        }
    }
    const deleteSetConfirmation = (e) => {
        MySwal.fire({
            title: <p>Are you sure want to delete?</p>,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Set</p>,
                    allowOutsideClick: false,
                });
                MySwal.showLoading()
                try {
                    deleteSet(card).then(result => {
                        if(result){
                            if(result.status == 200){
                                MySwal.update({
                                    title: <p>Set Deleted</p>,
                                    icon: "success"
                                });
                                try {
                                    props.removeSet(card);
                                } catch(err) {
                                }
                            } else {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }
                        }else{
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }).catch(err => {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }).finally(() => {
                        MySwal.hideLoading();
                    });
                } catch (err) {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                }
            }
        })
    }
    function updateSet(set) {
        try {
            props.updatedSet(set);
        } catch(err) {
        }
    }
    const addClassSetCall = (set) => {
        try {
            (addClassSetTrigger.current as HTMLButtonElement).click();
        } catch(err) {
        }
    }
    return (
        <>
            {studyGameSet ?
                <div className={'classset-container-item'}>
                    <div className={'classset-container-item-body'} onClick={playStudyGame}>
                        <div style={{ backgroundImage: card["image"] ? `url(${card["image"]})` : ``, height: "110px", minWidth: "110px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: `#${color}`, color: "white", textTransform: "uppercase", fontWeight: "600", fontSize: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {!card["image"] && card["name"]?
                                card["name"].slice(0, 2) : ""
                            }
                        </div>
                        <div style={{ fontSize: "14px", marginLeft: "12px", alignSelf: "center", textAlign: "left" }}>
                            <b>Set Name: {card["name"] ? card["name"] : ""}</b>
                            <div className={'classset-container-item-details'}>
                                <span>
                                    <span>Format: </span>
                                    {card["tok_group"] == "Playable" || card["tok_group"] == "Non-Playable" ? card["tok_group"] :
                                        <>{card["tok_group"] == "Mega" ? "Non-Playable" : "Playable"}</>
                                    }
                                </span>
                                {card["created_time"] ? <span style={{ color: "#808080" }}>• {Moment(card["created_time"]).format('MM/DD/YY')}</span> : ""}
                                {card["ids"] ? <span style={{ color: "#808080" }}>• {card["ids"].length} Toks</span> : ""}
                            </div>
                            {card["user_display_name"] ? <div>{card["user_display_name"]}</div> : ""}
                        </div>
                        <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                    </div>
                </div> :
                <Dropdown className={'classset-container-item'}>
                    <AddEditClassSet set={card} updateSet={updateSet} addClassSetTrigger={addClassSetTrigger}/>
                    <Dropdown.Toggle className={'dropdown-remove classset-container-item-body'} style={{ backgroundColor: "unset", color: "black", border: "unset", padding: "unset" }}>
                        <div style={{ backgroundImage: card["image"] ? `url(${card["image"]})` : ``, height: "110px", minWidth: "110px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundColor: `#${color}`, color: "white", textTransform: "uppercase", fontWeight: "600", fontSize: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {!card["image"] && card["name"]?
                                card["name"].slice(0, 2) : ""
                            }
                        </div>
                        <div style={{ fontSize: "14px", marginLeft: "12px", alignSelf: "center", textAlign: "left" }}>
                            <b>Set Name: {card["name"] ? card["name"] : ""}</b>
                            <div className={'classset-container-item-details'}>
                                <span>
                                    <span>Format: </span>
                                    {card["tok_group"] == "Playable" || card["tok_group"] == "Non-Playable" ? card["tok_group"] :
                                        <>{card["tok_group"] == "Mega" ? "Non-Playable" : "Playable"}</>
                                    }
                                </span>
                                {card["created_time"] ? <span style={{ color: "#808080" }}>• {Moment(card["created_time"]).format('MM/DD/YY')}</span> : ""}
                                {card["ids"] ? <span style={{ color: "#808080" }}>• {card["ids"].length} Toks</span> : ""}
                            </div>
                            {card["user_display_name"] ? <div>{card["user_display_name"]}</div> : ""}
                        </div>
                        <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={addToktoSet}>Add Toks to Set</Dropdown.Item>
                        <hr style={{ margin: "0.5rem 0" }} />
                        <Dropdown.Item onClick={setView}>Manage Toks</Dropdown.Item>
                        <hr style={{ margin: "0.5rem 0" }} />
                        <Dropdown.Item onClick={playMenu}>Play</Dropdown.Item>
                        <Dropdown.Item hidden={!showPlayMenu} onClick={playTokCardsStart}>
                            <FontAwesomeIcon icon={faPlay} style={{ marginRight: "5px" }} />
                            Play Tok Cards
                        </Dropdown.Item>
                        <Dropdown.Item hidden={!showPlayMenu} onClick={playTokChoiceStart}>
                            <FontAwesomeIcon icon={faPlay} style={{ marginRight: "5px" }} />
                            Play Tok Choice
                        </Dropdown.Item>
                        <Dropdown.Item hidden={!showPlayMenu} onClick={playTokMatchStart}>
                            <FontAwesomeIcon icon={faPlay} style={{ marginRight: "5px" }} />
                            Play Tok Match
                        </Dropdown.Item>
                        <hr style={{ margin: "0.5rem 0" }} hidden={!showPlayMenu} />

                        <Dropdown.Item>Replicate</Dropdown.Item>
                        <Dropdown.Item onClick={addClassSetCall}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={deleteSetConfirmation}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }
        </>
    )
}

export default ClassSetTile;