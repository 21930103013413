import React from 'react'
import '../styles.css'
import './css/AddExistingClassSet.css'
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { addClassSet, getClassSets } from '../services/classServices';
import { getToken } from '../services/accountServices';
import { createRef } from 'react';
import { TextField } from '@mui/material';
import ButtonReact from 'react-bootstrap/Button';
import { Button } from 'react-bootstrap';
import { faFilter, faAngleRight, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from 'react-bootstrap';
import AddEditClassGroup from './AddEditClassGroup';
import arrayShuffle from 'array-shuffle';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Moment from 'moment';
import { Set } from '../interfaces/SetInterface';
import { Group } from '../interfaces/GroupInterface';

const MySwal = withReactContent(Swal);
interface States {
    items: Set[];
    LoadActive: boolean;
    continuationToken: null | string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    ShowTriggerBtn: boolean;
    ShowModal: boolean;
    SelectedItem: Set;
    Group: Group;
    resultData: {
        [key: string]: any;
    };
    Token: string;
}

interface Props {
    communityid: string;
    showTriggerRefBtn: React.RefObject<any>;
    addExistingClassSetTransferRef: React.RefObject<any>;
    ShowTriggerBtn: boolean;
    Group: Group;
}
class AddExisitngClassSet extends React.Component<Props, States> {
    showTriggerRefBtn: React.RefObject<any>;
    addExistingClassSetTransferRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            ShowTriggerBtn: props.ShowTriggerBtn ? props.ShowTriggerBtn : false,
            ShowModal: false,
            SelectedItem: { id: "", pk: "" } as Set,
            Group: props.Group ? props.Group as Group : {} as Group,
            resultData: {},
            Token: ""
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.setShowModal = this.setShowModal.bind(this);
        this.showTriggerRefBtn = props.showTriggerRefBtn ? props.showTriggerRefBtn : createRef();

        this.setToAddExistingSet = this.setToAddExistingSet.bind(this);
        this.addSetToGroup = this.addSetToGroup.bind(this);
        this.addExistingClassSetTransferRef = props.addExistingClassSetTransferRef;
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    async getData(customQuery) {
        var { query, items } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
            if (!query["pagination"]) {
                this.setState({
                    items: []
                });
            }
        }
        const response = await getClassSets(query ? query : []);
        var itemResult = response? response.data.result : new Array;
        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            resultData: response? response.data.resource: "",
            continuationToken: response? response.data["pagination"] ? response.data["pagination"] : "": "",
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
    }

    loadmore() {
        this.setState({
            LoadMoreActive: true
        }, () => {
            var { Token } = this.state;
            this.getData(Token)
        });
    }

    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    setShowModal() {
        const { ShowModal } = this.state;
        this.setState({
            ShowModal: !ShowModal
        })
    }
    setToAddExistingSet(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        const { items } = this.state;
        var filterSearch = items.filter(item => item["id"] == id && item["pk"] == pk);
        var selectedItem = filterSearch.length ? filterSearch[0] : { id: "", pk: "" };
        this.setState({
            SelectedItem: selectedItem as Set
        });
    }
    addSetToGroup() {
        var { SelectedItem, Group } = this.state;
        MySwal.fire({
            title: <p>Adding Set to Group</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        SelectedItem["isgroup"] = true;
        SelectedItem["group_id"] = Group.id;
        SelectedItem["isprivate"] = false;
        SelectedItem["ispublic"] = false;
        addClassSet(SelectedItem).then(response => {
            if (response) {
                if (response.status == 200) {
                    if (response.data.statusCode == 200) {
                        MySwal.update({
                            title: <p>Set Added</p>,
                            icon: "success"
                        });
                        try {
                            this.addExistingClassSetTransferRef.current.setAttribute("data-data", JSON.stringify(response.data.resource));
                            this.addExistingClassSetTransferRef.current.click();
                        } catch (err) { }
                        this.setState({
                            SelectedItem: { id: "", pk: "" } as Set
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    render() {
        var { items, ShowTriggerBtn, ShowModal, LoadActive, continuationToken, LoadMoreActive, SelectedItem, Group } = this.state;
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <>
                <Button ref={this.showTriggerRefBtn} style={{ display: ShowTriggerBtn ? "" : "none" }} onClick={this.setShowModal}>Add Existing Set</Button>
                <Modal size='lg' show={ShowModal} style={{ zIndex: "1207" }}>
                    <Modal.Header>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.setShowModal} />
                            Add Existing Set {Group["name"] ? "to " + Group["name"] : ""}
                            <Button variant="success" onClick={SelectedItem["id"] != "" || SelectedItem["pk"] != "" ? this.addSetToGroup : null} disabled={SelectedItem.id != "" || SelectedItem.pk != "" ? false : true}>Add</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ height: "75vh", overflow: "auto", overflowX: "hidden" }}>
                        {!LoadActive ?
                            <Row className={'selection-set-container'}>
                                {items.map((set) => (
                                    <Col onClick={this.setToAddExistingSet} data-id={set.id} data-pk={set.pk} className={SelectedItem["id"] == set.id && SelectedItem["pk"] == set.pk ? 'row selection-set-item active' : 'row selection-set-item'} xs={12}>
                                        <Row>
                                            <Col>{set["name"] ? set["name"] : ""}</Col>
                                            <Col xs={'auto'} className={'badge badge-set float-right'}>Tok Type: {set["tok_group"] ? set["tok_group"] : ""}</Col>
                                            <Col xs={'auto'} className={'badge badge-set float-right'}>{set["created_time"] ? Moment(set["created_time"]).format('DD/MM/YY') : ""}</Col>
                                            <Col xs={'auto'} className={'badge badge-set float-right'}>Toks: {set["ids"] ? set["ids"].length : "0"}</Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row> : <WaitSpinner />
                        }

                    </Modal.Body>
                </Modal>
            </>

        );
    }
}

export default AddExisitngClassSet;