import React from 'react'
import Typography from "@mui/material/Typography";
import '../styles.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FacebookShareButton } from 'react-share';
import { getSetUserId, getToken } from '../services/accountServices';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { faAngleRight, faCheckCircle, faCircle, faFilter, faRefresh, faSortAmountDown, faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addClassToksToSet, getToks, getFilterOptions, addClassTok } from '../services/classServices';
import WaitSpinner from './WaitSpinner';
import './css/AddExistingClassTok.css'
import { cloneDeep } from 'lodash';
import { Set } from '../interfaces/SetInterface';

const MySwal = withReactContent(Swal);
interface Props {
    addToktoSetShow: boolean;
    set: Set;
    addToksToSetTrigger: React.Ref<any>;
    updatedSet: (data: Set) => void;

}

interface States {
    LoadActive: boolean;
    show: boolean;
    Set: Set;
    items: Set[];
    Token: string;
    SelectedItems: Set[];
    LoadMoreActive: boolean;
    level1: string;
    level2: string;
    level3: string;
    initialize: boolean;
    filterOptions: {
        displayText: string;
        value: string;
        options: {
            displayText: string;
            value: string | boolean;
        }[];
    }[];
    ShowFilters: boolean;
    FilterQuery: {
        publicfeed: boolean;
        feedtype: number;
        FilterBy: number;
        FilterItems: any[];
    };
    sortOptions: {
        displayText: string;
        value: string;
    }[];
    ShowSort: boolean;
    SortQuery: string[];
    FilterByModalActive: string;
    FilterLoaderActive: boolean;
    filterResults: {
        [key: string]: any;
    };
    FilterByModalShow: boolean;
}
class AddToksToSet extends React.Component<Props, States> {
    addToksToSetTrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            LoadActive: false,
            show: props.addToktoSetShow,
            Set: props.set,
            items: [],
            Token: "",
            SelectedItems: [],
            LoadMoreActive: false,
            level1: "",
            level2: "",
            level3: "",
            initialize: false,
            filterOptions: [
                {
                    displayText: "Feed Options", value: "publicfeed", options: [
                        { displayText: "Public", value: true },
                        { displayText: "My Sets", value: false }
                    ]
                },
                {
                    displayText: "Filter By", value: "FilterBy", options: [
                        { displayText: "Class", value: "getClasses" },
                        { displayText: "Category", value: "getCategories" },
                        { displayText: "Type", value: "getType" }
                    ]
                },
            ],
            ShowFilters: false,
            FilterQuery: {
                "publicfeed": true,
                "feedtype": 0,
                "FilterBy": 0,
                "FilterItems": []
            },
            sortOptions: [
                {
                    displayText: "Sort by Reference ID/#", value: "reference_id"
                },
                {
                    displayText: "Sort by Title", value: "primary_text"
                },
            ],
            ShowSort: false,
            SortQuery: [],
            FilterByModalActive: null,
            FilterLoaderActive: false,
            filterResults: {},
            FilterByModalShow: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.addToksToSetTrigger = props.addToksToSetTrigger;

        this.addToktoTempSet = this.addToktoTempSet.bind(this);

        this.selectAllItems = this.selectAllItems.bind(this);
        this.unselectAllItems = this.unselectAllItems.bind(this);

        this.loadMoreScroll = this.loadMoreScroll.bind(this);

        this.addToktoSetInitialize = this.addToktoSetInitialize.bind(this);

        this.setFilterOptions = this.setFilterOptions.bind(this);
        this.closeFilterByModal = this.closeFilterByModal.bind(this);
        this.setFilterByItems = this.setFilterByItems.bind(this);
        this.applyFilterByItems = this.applyFilterByItems.bind(this);
        this.showFilterByMenu = this.showFilterByMenu.bind(this);
        this.refresh = this.refresh.bind(this);

        this.showHideSort = this.showHideSort.bind(this);
        this.setSortOptions = this.setSortOptions.bind(this);

        this.addToktoSetPost = this.addToktoSetPost.bind(this);

        this.setFilterOptions = this.setFilterOptions.bind(this);
        this.closeFilterByModal = this.closeFilterByModal.bind(this);
        this.setFilterByItems = this.setFilterByItems.bind(this);
        this.applyFilterByItems = this.applyFilterByItems.bind(this);
        this.showFilterByMenu = this.showFilterByMenu.bind(this);
        this.refresh = this.refresh.bind(this);

        this.showHideSort = this.showHideSort.bind(this);
        this.setSortOptions = this.setSortOptions.bind(this);
    }

    async getData(query = {}) {
        const { Set, items, level1, level2, level3, initialize, Token, FilterQuery, SortQuery } = this.state;
        query["FilterBy"] = 3;
        try {
            if (Set["tok_group"].toLowerCase() == "playable") {
                query["FilterItems"] = ["Basic", "Detail"]
            } else {
                query["FilterItems"] = ["Mega", "Pic", "List"]
            }
        } catch (err) {
            query["FilterItems"] = ["Mega", "Pic", "List"]
        }
        query["pkbase"] = await getSetUserId() + "-classtoks";
        if (level1) {
            query["level1"] = level1;
            if (level2) {
                query["level2"] = level2;
                if (level3) {
                    query["level3"] = level3;
                }
            }
            query["pkbase"] = "channeltoks";

        }

        query["publicfeed"] = false;
        if (FilterQuery["publicfeed"]) {
            query["publicfeed"] = FilterQuery["publicfeed"];
        }
        if (FilterQuery["FilterBy"] > 0 && FilterQuery["FilterItems"].length > 0) {
            query["FilterBy"] = FilterQuery["FilterBy"];
            query["FilterItems"] = FilterQuery["FilterItems"];
        }
        if (SortQuery["orderby"]) {
            query["orderby"] = SortQuery["orderby"];
            query["descending"] = SortQuery["descending"];
        }
        var result = await getToks(query, "", "");
        let token = result.data["pagination"] ? result.data["pagination"] : "";

        var newItemList = new Array;
        if (items) {
            newItemList = items;
        }
        if (result["data"]["result"]) {
            newItemList = newItemList.concat(result["data"]["result"]);
        }

        for (let x = 0; x < Set["ids"].length; x++) {
            for (let y = 0; y < newItemList.length; y++) {
                if (Set["ids"][x] == newItemList[y]["id"]) {
                    newItemList.splice(y, 1);
                    break;
                }
            }
        }

        this.setState({
            LoadActive: false,
            LoadMoreActive: false,
            items: newItemList,
            Token: token,
            initialize: false
        }, () => {
            if (initialize && token && newItemList.length < 15) {
                let query = { "pagination": token }
                this.getData(query);
            }
        });
    }

    showModal(e) {
        const { Set } = this.state;

        try {
            let setString = e.currentTarget.getAttribute("data-set");
            let level1 = e.currentTarget.getAttribute("data-level1") && e.currentTarget.getAttribute("data-level1") != "undefined" ? e.currentTarget.getAttribute("data-level1") : "";
            let level2 = e.currentTarget.getAttribute("data-level2") && e.currentTarget.getAttribute("data-level2") != "undefined" ? e.currentTarget.getAttribute("data-level2") : "";
            let level3 = e.currentTarget.getAttribute("data-level3") && e.currentTarget.getAttribute("data-level3") != "undefined" ? e.currentTarget.getAttribute("data-level3") : "";
            let set = JSON.parse(setString);
            if (Set) {
                if (set["id"] != Set["id"] || set["pk"] != Set["pk"]) {
                    this.setState({
                        show: true,
                        Set: set,
                        LoadActive: true,
                        LoadMoreActive: false,
                        items: [],
                        level1: level1,
                        level2: level2,
                        level3: level3,
                        initialize: true
                    }, () => {
                        this.getData();
                    });
                } else {
                    this.setState({
                        show: true
                    });
                }
            } else {
                this.setState({
                    show: true,
                    Set: set,
                    LoadActive: true,
                    LoadMoreActive: false,
                    items: [],
                    level1: level1,
                    level2: level2,
                    level3: level3,
                    initialize: true
                }, () => {
                    this.getData();
                });
            }
        } catch (err) {
            this.setState({
                show: true,
                LoadActive: true,
                LoadMoreActive: false,
                items: [],
                SelectedItems: [],
                initialize: true
            }, () => {
                this.getData();
            })
        }
    }

    hideModal() {
        this.setState({
            show: false,
            SelectedItems: [],
            LoadMoreActive: false,
            LoadActive: true,
            Token: "",
            items: []
        });
    }
    addToktoTempSet(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        let addTok = true;
        var { items, SelectedItems } = this.state;
        for (let x = 0; x < SelectedItems.length; x++) {
            if (SelectedItems[x]["id"] == id && SelectedItems[x]["pk"] == pk) {
                SelectedItems.splice(x, 1);
                addTok = false;
                break;
            }
        }

        if (addTok) {
            for (let x = 0; x < items.length; x++) {
                if (items[x]["id"] == id && items[x]["pk"] == pk) {
                    SelectedItems.push(items[x]);
                    break;
                }
            }
        }
        this.setState({
            SelectedItems: SelectedItems
        });
    }
    selectAllItems() {
        var { items } = this.state;
        var itemList = new Array;
        for (let x = 0; x < items.length; x++) {
            itemList.push(items[x]);
        }
        this.setState({
            SelectedItems: itemList
        });
    }
    unselectAllItems() {
        this.setState({
            SelectedItems: []
        });
    }
    loadMoreScroll(e) {
        const { LoadMoreActive, Token } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !LoadMoreActive && Token) {
            this.setState({
                LoadMoreActive: true
            }, () => {
                let query = { "pagination": Token }
                this.getData(query);
            });
        }
    }
    addToktoSetInitialize() {
        const { Set, SelectedItems } = this.state;
        MySwal.fire({
            title: <p>Adding Toks to Set</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        this.addToktoSetPost(cloneDeep(SelectedItems), Set["id"], 0);
    }

    addToktoSetPost(tokList = new Array, feedId = "", count = 0) {
        if (tokList.length > count) {
            let tempTok = cloneDeep(tokList[count]);
            let countDisplay = count + 1;
            MySwal.fire({
                title: <p>Adding Tok {countDisplay} out of {tokList.length}</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading();
            let addToktoSetPost = this.addToktoSetPost;
            addClassTok(tempTok, feedId).then(response => {
                MySwal.hideLoading();
                if (response) {
                    if (response.status == 200) {
                        addToktoSetPost(tokList, feedId, countDisplay);
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(err => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
                MySwal.hideLoading();
            }).finally(() => {
            })
        } else {
            MySwal.fire({
                title: <p>All Toks added to Set.</p>,
                allowOutsideClick: false
            });
            try {
                let resultObject = {
                    addtoks: true,
                    tokstobeadded: tokList
                }
                this.props.updatedSet(resultObject as Set);
            } catch (err) { }
            this.setState({
                show: false
            });
        }
    }

    setFilterOptions(e) {
        const target = e.target.getAttribute("data-target");
        const value = e.target.getAttribute("data-value");
        var { FilterQuery } = this.state;
        switch (target) {
            case "publicfeed":
                if (value == "true") {
                    FilterQuery["publicfeed"] = true
                    this.setState({
                        items: [],
                        LoadActive: true,
                        FilterQuery: FilterQuery
                    }, () => {
                        this.getData();
                    });
                } else {
                    FilterQuery["publicfeed"] = false
                    this.setState({
                        items: [],
                        LoadActive: true,
                        FilterQuery: FilterQuery
                    }, () => {
                        this.getData();
                    });
                }
                break;
            case "FilterBy":
                this.showFilterByModal(value);
                break;
            //case "feedtype":
            //    FilterQuery[target] = Number(value)
            //    this.setState({
            //        items: [],
            //        LoadActive: true,
            //        FeedType: Number(value),
            //        FilterQuery: FilterQuery
            //    }, () => {
            //        this.getData();
            //    });
            //    break;
        }
    }
    showFilterByModal(value) {
        var { Set, FilterQuery, FilterByModalActive } = this.state;
        if (FilterByModalActive != value) {
            FilterQuery["FilterItems"] = [];
        }
        this.setState({
            FilterLoaderActive: true,
            filterResults: [],
            FilterByModalShow: true,
            FilterByModalActive: value,
            FilterQuery: FilterQuery
        });
        if (value == "getType") {
            let filterResults = new Array;
            try {
                if (Set["tok_group"].toLowerCase() == "playable") {
                    filterResults = [{ displayText: "Basic", value: "Basic" }, { displayText: "Detail", value: "Detail" }]
                } else {
                    filterResults = [{ displayText: "Mega", value: "Mega" }, { displayText: "Pic", value: "Pic" }, { displayText: "List", value: "List" }]
                }
            } catch (err) {
                filterResults = [{ displayText: "Mega", value: "Mega" }, { displayText: "Pic", value: "Pic" }, { displayText: "List", value: "List" }]
            }
            this.setState({
                FilterLoaderActive: false,
                filterResults: filterResults
            });
        } else {
            getFilterOptions(value == "getClasses" ? { FilterBy: 1 } : { FilterBy: 2 }).then(res => {
                let filterResults = new Array;
                if (res) {
                    if (res.data.resultEnum == 1) {
                        for (let x = 0; x < res.data.resultObject.results.length; x++) {
                            if (res.data.resultObject.results[x]["title"] && res.data.resultObject.results[x]["id"]) {
                                let tempItem = { displayText: res.data.resultObject.results[x]["title"], value: res.data.resultObject.results[x]["id"] }
                                filterResults.push(tempItem);
                            }
                        }
                    }
                }
                this.setState({
                    filterResults: filterResults
                });
            }).catch(err => {

            }).finally(() => {
                this.setState({
                    FilterLoaderActive: false
                });
            })
        }
    }
    closeFilterByModal() {
        var { FilterQuery, FilterByModalActive } = this.state;
        FilterQuery["FilterItems"] = [];
        FilterQuery["FilterBy"] = 0;
        this.setState({
            FilterByModalShow: false,
            FilterByModalActive: "",
            FilterQuery: FilterQuery
        });
    }

    setFilterByItems(e) {
        var { FilterQuery } = this.state;
        const value = e.target.getAttribute("data-value");
        if (!FilterQuery["FilterItems"].includes(value)) {
            FilterQuery["FilterItems"].push(value)
        } else {
            FilterQuery["FilterItems"] = FilterQuery["FilterItems"].filter(item => item !== value);
        }

        this.setState({
            FilterQuery: FilterQuery
        });
    }

    applyFilterByItems(e) {
        var { FilterQuery, FilterByModalActive } = this.state;
        switch (FilterByModalActive) {
            case "getClasses":
                FilterQuery["FilterBy"] = 1;
                break;
            case "getCategories":
                FilterQuery["FilterBy"] = 2
                break;
            case "getType":
                FilterQuery["FilterBy"] = 3;
                break;
        }

        this.setState({
            FilterQuery: FilterQuery,
            FilterByModalShow: false,
            items: [],
            LoadActive: true
        }, () => {
            this.getData();
        });
    }
    showFilterByMenu() {
        const { ShowFilters } = this.state;
        this.setState({
            ShowFilters: !ShowFilters
        })
    }
    refresh() {
        this.setState({
            items: [],
            LoadActive: true,
            initialize: true
        }, () => {
            this.getData();
        });
    }
    showHideSort() {
        const { ShowSort } = this.state;
        this.setState({
            ShowSort: !ShowSort
        })
    }
    setSortOptions(e) {
        const target = e.target.getAttribute("data-target");
        const value = e.target.getAttribute("data-value");
        var { SortQuery } = this.state;
        try {
            SortQuery["orderby"] = target;
            SortQuery["descending"] = value;
        } catch (err) {
            SortQuery = [];
            SortQuery["orderby"] = target;
            SortQuery["descending"] = value;
        }
        this.setState({
            SortQuery: SortQuery,
            items: [],
            LoadActive: true,
            initialize: true
        }, () => {
            this.getData();
        });
    }
    render() {
        const { SortQuery, sortOptions, ShowSort, FilterByModalActive, FilterLoaderActive, filterResults, FilterByModalShow, show, Set, LoadActive, items, SelectedItems, LoadMoreActive, filterOptions, ShowFilters, FilterQuery } = this.state;

        return (
            <>
                <Button ref={this.addToksToSetTrigger} hidden onClick={this.showModal} data-set="" data-level1="" data-level2="" data-level3="">Add Toks to Set</Button>
                {FilterByModalShow ?
                    <Modal size='lg' show={true} onHide={this.closeFilterByModal} style={{ zIndex: "1307" }}>
                        <Modal.Header>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeFilterByModal} />
                                <Typography fontWeight={"bold"}>More Filter Options</Typography>
                                <Button variant="success" style={{ display: "flex", alignItems: "center" }} onClick={this.applyFilterByItems}>
                                    <span>Apply Filter</span>
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body className={'filter-options-containers'}>
                            {

                            }
                            {filterResults.map((option) => (
                                <div className={FilterQuery["FilterItems"] ? FilterQuery["FilterItems"].includes(option.value) ? "active filter-option" : 'filter-option' : "filter-option"} style={{ cursor: "pointer" }} onClick={this.setFilterByItems} data-display={option.displayText} data-value={option.value}>
                                    <span>{option.displayText}</span>

                                    {FilterQuery["FilterItems"] ? FilterQuery["FilterItems"].includes(option.value) ? <FontAwesomeIcon icon={faCheckCircle} size={"sm"} /> : <FontAwesomeIcon icon={faCircle} size={"sm"} /> : <FontAwesomeIcon icon={faCircle} size={"sm"} />}
                                </div>
                            ))}
                            {FilterLoaderActive ? <WaitSpinner /> : ""}
                        </Modal.Body>
                    </Modal>
                    : ""
                }
                {Set ?
                    <Modal size='lg' show={show} onHide={this.hideModal} style={{ zIndex: "1306" }}>
                        <Modal.Header className={'text-color-white dark-background-2'}>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.hideModal} />
                                Add Toks To {Set["name"]}
                                <Button variant="success" onClick={this.addToktoSetInitialize}>Add to Set</Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body className={'text-color-white dark-background-2'}>
                            <Row style={{ alignItems: "center" }}>
                                <Col xs={"auto"} style={{ paddingRight: "unset" }}>
                                    <Button onClick={this.selectAllItems}>Select All</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button onClick={this.unselectAllItems} variant={'secondary'}>Unselect All</Button>
                                </Col>
                                <Col xs={"auto"} style={{ marginLeft: "auto", padding: "unset" }}>
                                    <div style={{ cursor: "pointer" }} onClick={this.refresh}>
                                        <FontAwesomeIcon icon={faRefresh} />
                                    </div>
                                </Col>
                                <Col xs={"auto"}>
                                    <div style={{ fontWeight: "600", cursor: "pointer" }} onClick={this.showHideSort}>
                                        SORT
                                        <FontAwesomeIcon icon={faSortAmountDown} />
                                    </div>
                                </Col>
                                <Col xs={"auto"} style={{ paddingLeft: "unset" }}>
                                    <div style={{ fontWeight: "600", cursor: "pointer" }} onClick={this.showFilterByMenu}>
                                        FILTER
                                        <FontAwesomeIcon icon={faFilter} />
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "center", display: ShowFilters ? "" : "none" }} >
                                {filterOptions.map((filterOption, index) => (
                                    <Col md='auto' sm={6} xs={6}>
                                        <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{filterOption.displayText}</div>
                                        {filterOption.options.map((option) => (
                                            <div style={{ cursor: "pointer", color: filterOption.value == "FilterBy" ? option.value == FilterByModalActive ? "#112379" : "" : FilterQuery["publicfeed"] == option.value ? "#112379" : "" }} data-target={filterOption.value} data-value={option.value} onClick={this.setFilterOptions}>{option.displayText}</div>
                                        ))}
                                    </Col>
                                ))}
                            </Row>
                            <Row style={{ justifyContent: "center", display: ShowSort ? "" : "none" }}>
                                {sortOptions.map((sortOption, index) => (
                                    <Col md='auto' sm={6} xs={6}>
                                        <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{sortOption.displayText}</div>
                                        <div style={{ cursor: "pointer" }} onClick={this.setSortOptions} className={`${sortOption.value}-${false}`} data-target={sortOption.value} data-value={false}>Ascending</div>
                                        <div style={{ cursor: "pointer" }} onClick={this.setSortOptions} className={`${sortOption.value}-${true}`} data-target={sortOption.value} data-value={true}>Descending</div>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col xs={"auto"} style={{ marginLeft: "auto" }}>
                                    {SelectedItems.length} out of {items.length} selected
                                </Col>
                            </Row>
                            {LoadActive ?
                                <WaitSpinner /> :
                                <Row onScroll={this.loadMoreScroll} className={'selection-container'} style={{ height: "65vh", padding: "5px 10px 0px", overflowY: "scroll", overflowX: "hidden", display: "block" }}>
                                    {items.map(tok => {
                                        let selectedItem = false;
                                        for (let x = 0; x < SelectedItems.length; x++) {
                                            if (SelectedItems[x]["id"] == tok["id"] && SelectedItems[x]["pk"] == tok["pk"]) {
                                                selectedItem = true;
                                                break;
                                            }
                                        }
                                        return (
                                            <Col onClick={this.addToktoTempSet} data-id={tok.id} data-pk={tok.pk} className={selectedItem ? 'row selection-item active' : 'row selection-item'} xs={12}>
                                                <div style={{ height: "70px", width: "70px", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: tok["user_photo"] ? `url(${tok["user_photo"]})` : `url("https://tokketcontent.blob.core.windows.net/tokketweb/default.png")` }}></div>
                                                <Col style={{ fontWeight: "600", alignSelf: "center", marginLeft: "10px" }}>
                                                    <div>
                                                        {tok["user_display_name"] ? tok["user_display_name"] : ""}
                                                    </div>
                                                    <div>
                                                        {tok["primary_text"] ? tok["primary_text"] : ""} <span style={{ color: "gray" }}>{tok["tok_type"] ? " • Tok Type: " + tok["tok_type"] : ""}</span>
                                                    </div>
                                                </Col>
                                                {/*<div style={{ position: "absolute", bottom: "5px", right: "10px", width: "fit-content" }}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </div>*/}
                                            </Col>
                                        );
                                    })}
                                    {LoadMoreActive ?
                                        <WaitSpinner /> : ""
                                    }
                                </Row>
                            }
                        </Modal.Body>
                    </Modal> : ""
                }

            </>
        );
    }
}

export default AddToksToSet;