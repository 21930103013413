import React, { useRef, useEffect, createRef } from 'react'
import '../styles.css'
import './css/TokTile.css';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Moment from 'moment';
import CardMediaCustom from './CardMediaCustom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactCountryFlag from "react-country-flag"
import Tooltip from '@mui/material/Tooltip';

import { useState } from 'react';

import { Popover } from 'react-tiny-popover'
import { isYoutubeLink } from '../services/helperTools';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MessageIcon from '@mui/icons-material/Message';
import Comments from './Comments';

import Dropdown from 'react-bootstrap/Dropdown';
import { addReaction } from '../services/reactionServices';
import ClassTokIcon from './ClassTokIcon';

const TokTile = (props) => {
    const card = props.card;
    const color = props.color;
    const level1 = props.level1;
    const level2 = props.level2;
    const level3 = props.level3;
    const group = props.group;
    const community = props.community;
    const secondaryRef = useRef();
    const secondaryMainRef = useRef();
    const [tileVerticalCenter, setTileVerticalCenter] = useState(true);
    const formattedDate = Moment(card.created_time).format('M/DD/YYYY')
    const withImage = (card.image != null ? card.image.length > 1 : false)
    const [DisplayCountry, SetDisplayCountry] = useState(true);
    var disp = "none";
    var viewCount = 0;
    var borderThickness = "4px";
    var doubleTileActive = false;
    var viewMoreText = "";
    if (card["image"]) {
        if (isYoutubeLink(card["image"])) {
            viewMoreText = "View Video and Comments";
        }
    }
    if (!viewMoreText) {
        if (card["master_copy"]) {
            viewMoreText = "View More (Creator's Copy)"
        } else {
            viewMoreText = "View More and Comments";
        }
    }
    var country = card["user_country"] ? card["user_country"] : "";
    if (country.toLowerCase() == "us") {
        //country = card.user_state;
    }
    if (withImage) disp = "";
    var icon: JSX.Element;
    var secondary: JSX.Element;
    function cardSendBack() {
        props.receiveData(card)
    }
    function provileView(e) {
        if (card["user_id"]) {
            props.viewProfileView(card["user_id"]);
        }
        e.stopPropagation();
    }
    let details = new Array;
    let tempDetailList = cloneDeep(card["details"]);
    let indents = new Array;
    if (card["is_indent"]) {
        indents = cloneDeep(card["is_indent"]);
    }
    if (details && indents.includes(true) && (card["tok_group"] == "Detail" || card["tok_group"] == "Detailed" || card["tok_group"] == "List")) {
        let tempSubDetails = new Array;
        for (let x = 0; x < tempDetailList.length; x++) {
            if (indents[x]) {
                tempSubDetails.push({
                    text: tempDetailList[x],
                    index: x
                });
                if (x == tempDetailList.length - 1) {
                    details[details.length - 1]["sub"] = cloneDeep(tempSubDetails);
                }
            } else {
                if (tempSubDetails.length > 0) {
                    details[details.length - 1]["sub"] = cloneDeep(tempSubDetails);
                    tempSubDetails = new Array;
                }
                details.push({
                    text: tempDetailList[x],
                    index: x
                });
            }
        }
    } else if (card["details"] && (card["tok_group"] == "Detail" || card["tok_group"] == "Detailed" || card["tok_group"] == "List")) {
        for (let x = 0; x < tempDetailList.length; x++) {
            details.push({
                text: tempDetailList[x],
                index: x
            });
        }
    }
    let listStype = "disc";
    switch (card["bullet_kind"]) {
        case "Letters":
            listStype = "lower-alpha"
            break;
        case "Numbers":
            listStype = "decimal"
            break;
    }
    let isBasic = false;
    switch (card.tok_group) {
        case "Detail":
        case "Detailed":
            icon = <Tooltip title={"Detailed"}><ClassTokIcon width={"21"} height={"21"} icon={"detail"} /></Tooltip>
            if (details.length) {
                secondary =
                    <div ref={secondaryRef} style={{ height: "fit-content" }}>
                        <Typography variant="body2" style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '14px', marginLeft: "14px" }}>
                            <List dense={true} style={{ listStyleType: listStype, textAlign: 'left', maxWidth: '100%' }} >
                                {details.map((dtl) => {
                                    if (dtl["text"] == null) return '';
                                    if (dtl["text"].length <= 0) return '';
                                    return (
                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'left', display: 'list-item', flexShrink: 0 }}>
                                            <ListItemText className={'elipsis-one-line-parent'}
                                                primary={dtl["text"]}
                                            // secondary={secondary ? 'Secondary text' : null}
                                            />
                                            {dtl["sub"] ?
                                                <List dense={true} style={{ listStyleType: listStype, padding: "0px" }}>
                                                    {dtl["sub"].map((sub) => {
                                                        return (
                                                            <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'left', display: 'list-item', flexShrink: 0 }}>
                                                                <ListItemText className={'elipsis-one-line-parent'}
                                                                    primary={sub["text"]}
                                                                // secondary={secondary ? 'Secondary text' : null}
                                                                />
                                                            </ListItem>
                                                        )
                                                    })}
                                                </List> : ""
                                            }
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Typography>
                    </div>
                if (card.details.length > 6) {
                    doubleTileActive = true;
                }
            }

            borderThickness = "10px";
            break;
        case "List":
            icon = <Tooltip title={"List"}><ClassTokIcon width={"21"} height={"21"} icon={"list"} /></Tooltip>
            if (card.details) {
                secondary =
                    <div ref={secondaryRef} style={{ height: "fit-content" }}>
                        <Typography variant="body2" style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '14px', marginLeft: "14px" }}>
                            <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                                {card.details.map(dtl => {
                                    if (dtl == null) return '';
                                    if (dtl.length <= 0) return '';

                                    return (
                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'left', display: 'list-item', flexShrink: 0 }}>
                                            <ListItemText className={'elipsis-one-line-parent'}
                                                primary={dtl}
                                            // secondary={secondary ? 'Secondary text' : null}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Typography>
                    </div>
            }

            break;
        case "Mega":
            icon = <Tooltip title={"Mega"}><ClassTokIcon width={"21"} height={"21"} icon={"mega"} /></Tooltip>;
            if (card.sections) {
                secondary =
                    <div ref={secondaryRef} style={{ height: "fit-content" }}>
                        <Typography variant="body2" style={{ display: 'flex-start', marginLeft: "14px", justifyContent: 'center', fontSize: '14px' }}>
                            <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                                {card.sections.map(section => {
                                    if (section.title == null) return '';
                                    if (section.title.length <= 0) return '';

                                    return (
                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'left', display: 'list-item', flexShrink: 0 }}>
                                            <ListItemText className={'elipsis-one-line-parent'}
                                                primary={section.title}
                                            // secondary={secondary ? 'Secondary text' : null}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Typography>
                    </div>
            }
            //add condition for section to be more than 4, set doubleTileActive to true
            break;
        case "Q&a":
            icon = <Tooltip title={"Question & Answer"}><ClassTokIcon width={"21"} height={"21"} icon={"qna"} /></Tooltip>
            if (card.sections) {
                let sectionDisplay = card.sections.slice(0, 3);
                secondary =
                    <div ref={secondaryRef} style={{ height: "fit-content" }}>
                        <Typography variant="body2" style={{ display: 'flex', justifyContent: 'center', fontSize: '14px' }}>
                            <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                                {sectionDisplay.map(section => {
                                    if (section.content == null) return '';
                                    if (section.content.length <= 0) return '';

                                    return (
                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'left', display: 'list-item', flexShrink: 0 }}>
                                            <ListItemText className={'elipsis-one-line-parent'}
                                                primary={section.content}
                                            // secondary={secondary ? 'Secondary text' : null}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Typography>
                    </div>
            }
            borderThickness = "20px";
            //add condition for section to be more than 4, set doubleTileActive to true
            break;
        default:
            isBasic = true;
            icon = <Tooltip title={"Basic"}><ClassTokIcon width={"21"} height={"21"} icon={"basic"} /></Tooltip>
            secondary =
                <div style={{ height: "fit-content" }}>
                    <Typography ref={secondaryRef} className={'line-clamp-4'} variant="body2" style={{ textAlign: 'left', fontSize: '14px', overflowWrap: 'break-word' }}>
                        {card.secondary_text}
                    </Typography>
                </div>
            break;
    }
    const OnErrorImage = (e) => {
        SetDisplayCountry(false);
    }
    const [copyTokNotification, SetCopyTokNotification] = useState(false);
    const copyTok = (e) => {
        e.stopPropagation();
        SetCopyTokNotification(true);
        window.localStorage.setItem("copiedTok", JSON.stringify(card));
        setTimeout(function () {
            SetCopyTokNotification(false);
        }, 4000);
    }
    const disableOnClick = (e) => {
        e.stopPropagation();
    }
    var addLikeMsg;
    const addLike = (e) => {
        e.stopPropagation();
        props.setToasterMsg({
            msg: "Adding Like",
            type: 0
        })
        var reaction = {
            "kind": "gemc",
            "item_id": card["id"],
            "detail_num": 0,
            "label": "reaction",
            "owner_id": card["user_id"]
        }
        addReaction(reaction, card["id"]).then(res => {
            if(res){
                if (res["data"]["status_code"] == 200) {
                    let tok = cloneDeep(card);
                    tok["has_gem"] = true;
                    props.updateTok(tok);
                    props.setToasterMsg({
                        msg: 'Success!',
                        type: 1
                    })
                } else {
                    props.setToasterMsg({
                        msg: 'An Error Occured',
                        type: 2
                    })
                }
            }
        }).catch(err => {
            props.setToasterMsg({
                msg: 'An Error Occured',
                type: 2
            })
        }).finally(() => {

        })
    }
    var usePulse = card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? true : false : false;
    var cardColor = card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? card["color_main_hex"] : "#" + color : "#" + color;

    useEffect(() => {
        const handleResize = () => {
          const div = secondaryRef.current;
          const mainDiv = secondaryMainRef.current;
          if (div && mainDiv) {
            const { clientHeight: divClientHeight } = div;
            const { clientHeight: mainDivClientHeight } = mainDiv;
            setTileVerticalCenter(!(mainDivClientHeight === divClientHeight));
          }
        };
      
        handleResize();
      
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [secondaryRef, secondaryMainRef]);
    return (
        <Grid className={`tile-${card.id}-${card.pk}`} item key={card} xs={12} sm={6} md={4} lg={4} >
            <Card className={usePulse ? 'dark-background-2 text-color-white tok-tile-container-pulse' : 'dark-background-2 text-color-white tok-tile-container'} sx={{
                height: "100%",
                padding: "8px",
                margin: "4px",
                borderRadius: "12px",
                maxHeight: "330px",
                minHeight: "330px",
                display: "flex",
                flexDirection: "column",
                border: `${borderThickness} solid ${cardColor}`,
                cursor: "pointer",
                background: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? card["color_main_hex"] : "white" : "white",
                boxShadow: `0 0 0 0 ${cardColor}`
            }}
                onClick={cardSendBack}
            >
                <CardContent className={"toktile-content-container"} style={{ color: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? "white" : "" : "" }}>
                    <Container style={{ marginBottom: "auto", position: "relative", height: "100%" }}>
                        <Row style={{ height: "100%" }}>
                            <Col xs={'auto'}>
                                <Box style={{ display: "grid", placeItems: "center" }}>
                                    <Avatar className={"swing"} sx={{ border: `2px solid #542a7d` }} src={card.user_photo} aria-label="recipe">
                                    </Avatar>
                                    {/*
                                        <Typography sx={{ fontSize: '11px' }}>{formattedDate}</Typography>
                                    */}
                                    <div>
                                        <Typography fontSize={"9px"} textAlign={"center"}>{card.user_display_name}</Typography>
                                        {card["title_display"] ? <Typography fontSize={"9px"} textAlign={"center"}>{card.title_display}</Typography> : ""}
                                    </div>
                                </Box>

                            </Col>
                            <Col style={{ padding: "unset" }}>
                                <Row>

                                    <Col>
                                        <Typography className={'line-clamp-3 tok-tile-title'} gutterBottom variant="h6" sx={{ textAlign: "center" }} style={{ position: "relative", fontWeight: "bold", fontSize: "16px", lineHeight: "21px" }} component="div">
                                            {/*<div className={"first-invisible"}>
                                                <div className={"line-clamp-3 main-text"}>
                                                    {card.primary_text} 
                                                </div>
                                                <div className={'first-line-only'}>
                                                    {card.primary_text}
                                                </div>
                                                <div className={'line-clamp-1 speech-bubble-tile'}>
                                                    {card.primary_text}
                                                </div>
                                            </div>
                                            */}
                                            {/* <div className={'hover-msg'}>{card.primary_text}</div>*/}
                                            <div className={"speech-bubble-tile"}>
                                                <div className={"line-clamp-3"} style={{ overflow: "hidden" }}>
                                                    {card.primary_text}
                                                </div>
                                            </div>
                                        </Typography>

                                    </Col>
                                    <Col xs={'auto'} style={{ display: "grid", justifyContent: "center", placeItems: "center", height: "fit-content" }}>
                                        {DisplayCountry ?
                                            <ReactCountryFlag onError={OnErrorImage} svg style={{ width: '48px', height: "auto" }} countryCode={country} /> :
                                            <div style={{ width: '48px', height: "1px" }}></div>
                                        }
                                        <Button style={{ padding: "unset", fontSize: "10px", color: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? "white" : "" : "" }}>
                                            {group ? group : community ? community : level3 ? level3 : level2 ? level2 : level1 ? level1 : card["tok_type"] ? card["tok_type"] : ""}
                                        </Button>
                                        <Button style={{ padding: "unset", fontSize: "10px", color: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? "white" : "" : "" }}>
                                            {card.category ? card.category : card.category}
                                        </Button>
                                    </Col>

                                </Row>

                            </Col>

                            {!withImage ?
                                <Col xs={12} style={{ padding: "unset" }}>
                                    <Container style={{ overflow: "hidden", display: "grid" }}>
                                        <Container ref={secondaryMainRef} style={{ height: "150px", maxWidth: "", overflow: "hidden", display: "flex", marginTop: "auto", padding: "unset", paddingLeft: "5px" }}>
                                            <Row style={{ width: "100%", display: "-webkit-box", alignContent: tileVerticalCenter? "center": "unset" }}>
                                                {secondary}
                                            </Row>
                                        </Container>
                                    </Container>
                                </Col> :
                                <Col xs={12}>
                                    <CardMediaCustom data={card} withImage={withImage} />
                                </Col>
                            }

                            <Col xs={12} style={{ display: "flex", alignContent: "space-between", flexWrap: "wrap", padding: "unset" }}>


                                <Container style={{ maxHeight: "26px", marginTop: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <RemoveRedEyeIcon /> {viewCount}
                                </Container>
                                <Container style={{ marginTop: "auto" }}>
                                    <Row>
                                        <Col xs={2} style={{ display: "flex", padding: "unset" }}>
                                            {card["has_gem"] ?
                                                <div>
                                                    <FavoriteIcon fontSize={"medium"} style={{ color: '#9e0c0c' }} />
                                                </div> :
                                                <div onClick={addLike} className={'heart-icon'}>
                                                    <FavoriteBorderIcon fontSize={"medium"} className={"heart-border"} />
                                                    <FavoriteIcon fontSize={"medium"} className={"heart-filled"} />
                                                </div>
                                            }
                                            <div onClick={disableOnClick} style={{ marginLeft: "5px" }}>
                                                <Comments iconMode={true} disableInitialization={true} iconModeSize={"medium"} detailNo={-1} key={card["id"] + "-" + card["pk"] + "-comments"} itemId={card["id"]} item={card} Pk={card["pk"] ? card["pk"] : ""} userCommentActive={true} commentsViewActive={true} commentCounterRef={undefined} commentSwitchUiRef={undefined} viewProfileSet={undefined} hideCommentButton={undefined} />
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                            <Typography style={{ fontWeight: "bold", fontSize: "smaller", color: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? "white" : "blue" : "blue", textAlign: "center" }}>
                                                {viewMoreText}
                                            </Typography>
                                        </Col>
                                        <Col xs={2} style={{ display: "flex", padding: "unset", justifyContent: "flex-end" }}>
                                            <div style={{ marginRight: "7px" }}>{icon}</div>
                                            <div>
                                                <Popover
                                                    isOpen={copyTokNotification}
                                                    positions={['right']}
                                                    padding={10}
                                                    reposition={false}
                                                    onClickOutside={() => SetCopyTokNotification(false)}
                                                    content={({ }) => (
                                                        <div style={{ backgroundColor: "white", border: "1px gray solid", padding: "5px 10px" }}>
                                                            <b>Link Copied!</b>
                                                        </div>
                                                    )}
                                                >
                                                    <ClassTokIcon width={"21"} height={"21"} icon={"link"} />
                                                </Popover>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                    {/*
                    <Row>
                        <Col xs={"6"} style={{ color: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? "white" : "" : "" }}>

                            {group ? group : community ? community : level3 ? level3 : level2 ? level2 : level1 ? level1 : card["tok_type"] ? card["tok_type"] : ""}

                        </Col>
                        <Col xs={"6"} style={{ color: card["color_main_hex"] ? !card["color_main_hex"].toLowerCase().includes("ffffff") ? "white" : "" : "" }}>
                            <div>
                                <div>
                                    <span>{card.category ? card.category : card.category}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                        */}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default TokTile;