import React, { useState } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import { useSessionStorage, useLocalStorage } from 'usehooks-ts';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';
import Login from './firebase/Login';
import Signup from './firebase/Signup';
import ProfileChangePassword from './firebase/ProfileChangePassword';
import { createRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { apiTest } from './services/classServices';
import ReportItem from './components/ReportItem';
const AuthContext = React.createContext(null);
const clientId = process.env.REACT_APP_PAYPAL_CLIENTID;

const App = (props) => {
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const loginCallTrigger = createRef();
  const signupTrigger = createRef();
  const changePasswordTrigger = createRef();
  const token = "";
  if (location.state) {
    if (location.state["callLogin"]) {
      setTimeout(function () {
        loginCallTrigger.current.click();
      }, 100)
    }
  }
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://www.paypal.com/sdk/js?client-id=" + clientId;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, ["https://www.paypal.com/sdk/js?client-id=" + clientId]);
  return (
    <AuthContext.Provider value={token}>
      <main>
        <HelmetProvider>
          <Helmet>
            <meta property="fb:app_id" content="2096904330562973" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Tokket" />
            <meta property="og:description" content="Tokket Main Website" />
            <meta property="og:image" content="https://tokketcontent.blob.core.windows.net/tokketweb/favicon.ico" />

            <meta property="twitter:site" content="@@tokkepedia" />
            <meta property="twitter:title" content="Tokket" />
            <meta property="twitter:description" content="Tokket Main Website" />
            <meta property="twitter:image" content="https://tokketcontent.blob.core.windows.net/tokketweb/favicon.ico" />
          </Helmet>
          <CssBaseline />
          <Login loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} />
          <Signup loginCallTrigger={loginCallTrigger} signupTrigger={signupTrigger} />
          <ProfileChangePassword changePasswordTrigger={changePasswordTrigger} />
          <Navbar transparentTop={true} loginCallTrigger={loginCallTrigger} changePasswordTrigger={changePasswordTrigger} />
          <main style={{ paddingBottom: "unset" }}>
            {/* Hero unit */}
            <div sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              padding: (theme) => theme.spacing(8, 0, 6)
            }}>
              <Container className={'banner'}>

                <img className={'web-background'} src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header.png" />
                <div className={'mobile-background'}></div>
                <Row className={'content'}>
                  <Col className={'left-wing'} md={{ order: 1 }} xs={{ order: 2 }}>
                    <Carousel className={'carousel-phone-container'}>
                      <Carousel.Item className={'carousel-phone'}>
                        <div className={'carousel-phone-screen'}>
                          <img
                            className="icon"
                            src="https://tokketcontent.blob.core.windows.net/tokketweb/App Icon_D.jpg"
                            alt="First slide"
                          />
                        </div>

                      </Carousel.Item>
                      <Carousel.Item className={'carousel-phone'}>
                        <div className={'carousel-phone-screen'}>
                          <img
                            className="icon"
                            src="https://tokketcontent.blob.core.windows.net/tokketweb/Tokketpedia_icon2_Final2.png"
                            alt="First slide"
                          />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item className={'carousel-phone'}>
                        <div className={'carousel-phone-screen'}>
                          <img
                            className="icon"
                            src="https://tokketcontent.blob.core.windows.net/tokketweb/App-Icon.png"
                            alt="First slide"
                          />
                        </div>

                      </Carousel.Item>
                    </Carousel>
                  </Col>
                  <Col className={'flat-body'} md={{ span: 'auto', order: 2 }} xs={{ span: 12, order: 1 }}>
                    <Typography
                      align="center"
                      fontSize={'80px'}
                      fontWeight={'bold'}
                      color={'white'}
                    >
                      TOKKET
                    </Typography>
                    <Typography
                      align="center"
                      fontSize={'30px'}
                      fontWeight={'bold'}
                      color={'white'}
                    >
                      The Lifetime Learning Platform
                    </Typography>
                  </Col>
                  <Col className={'right-wing'} md={{ order: 3 }} xs={{ order: 3 }}>
                    <img src={"https://tokketcontent.blob.core.windows.net/tokketweb/restaurant-group-picture.jpg"} />
                  </Col>
                </Row>

              </Container>
              <Container>
                <Row>
                  <Col md={5} xs={12} style={{ display: "flex", alignItems: "flex-end" }}>
                    <div className={'student-body'}>
                      <div style={{ margin: "30px 25px", color: "white" }}>
                        <h2>
                          Tokket is coming out!
                        </h2>
                        <p style={{ fontSize: "16px", fontWeight: "100" }}>Tokket is launching apps and games that are not only fun but people can learn from.</p>
                        <a className={'learn-more-btn'} href={'/about'}>learn more</a>
                      </div>
                    </div>
                  </Col>
                  <Col md={7} xs={12}>
                    <Row>
                      <Col xs={6}>
                        <div className={'detail-info-panel'}>
                          <div style={{ paddingBottom: "10px", textAlign: "center" }}>
                            <img src={"https://tokketcontent.blob.core.windows.net/tokketweb/Tokketpedia_icon2_Final2.png"} style={{ width: "50%", borderRadius: "25px" }} />
                          </div>
                          <h4 style={{ fontSize: "18px", fontWeight: "bold" }}>Tokkepedia</h4>
                          <div style={{ fontSize: "16px" }}>
                            Tokkepedia is the first social and playable knowledge sharing app! Tokkepedia will be released later this year.
                          </div>
                        </div>

                        <div className={'detail-info-panel'}>
                          <div style={{ paddingBottom: "10px", textAlign: "center" }}>
                            <img src={"https://tokketcontent.blob.core.windows.net/tokketweb/App-Icon.png"} style={{ width: "50%", borderRadius: "25px" }} />
                          </div>
                          <h4 style={{ fontSize: "18px", fontWeight: "bold" }}>Alpha Guess</h4>
                          <div style={{ fontSize: "16px" }}>
                            DYK there are millions of initialisms used in business, government, the military, and social media? Play Alpha Guess to have fun learning popular initialisms FTW including the most widely used acronyms. FYI – it's available now on the App Store and Google Play stores!
                          </div>
                          <div style={{ display: "grid", gridGap: "5px" }}>
                            <Button variant="success" href={'https://apps.apple.com/us/app/alpha-guess/id1509100255'} target={'_blank'}>App Store</Button>
                            <Button variant="primary" href={'https://play.google.com/store/apps/details?id=com.tokket.alphaguess'} target={'_blank'}>Google Play</Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className={'detail-info-panel'}>
                          <div style={{ paddingBottom: "10px", textAlign: "center" }}>
                            <img src={"https://tokketcontent.blob.core.windows.net/tokketweb/App Icon_D.jpg"} style={{ width: "50%", borderRadius: "25px" }} />
                          </div>
                          <h4 style={{ fontSize: "18px", fontWeight: "bold" }}>Tok Blitz</h4>
                          <div style={{ fontSize: "16px" }}>
                            Tok Blitz is our inspirational, motivational, and celebrational game! Celebrate with your friends and family by playing this game during get togethers, birthdays, holidays, and other special occasions. Download from:
                          </div>
                          <div style={{ display: "grid", gridGap: "5px" }}>
                            <Button variant="success" href={'https://apps.apple.com/us/app/tok-blitz/id1494095518'} target={'_blank'}>App Store</Button>
                            <Button variant="primary" href={'https://play.google.com/store/apps/details?id=com.tokket.tokblitz'} target={'_blank'}>Google Play</Button>
                          </div>
                        </div>

                        <div className={'detail-info-panel'}>
                          <div style={{ paddingBottom: "10px", textAlign: "-webkit-center" }}>
                            <div class="flexCenter" style={{ height: "135.83px", width: "135.83px", padding: "10px", borderRadius: "25px", backgroundColor: "rgb(51, 72, 139)" }}>
                              <img src={'https://tokketcontent.blob.core.windows.net/tokketweb/classtoklogo.png'} style={{ width: "100%" }} />
                            </div>
                          </div>
                          <h4 style={{ fontSize: "18px", fontWeight: "bold" }}>Toks</h4>
                          <div style={{ fontSize: "16px" }}>
                            Toks equips students for success by making studying fun through socializing and playing! Enjoy interacting with your class notes through various study games. Learn from your classmates or help them by sharing content and commenting on classes. The web app will be ready soon, and the mobile version will follow.
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container sx={{
              paddingTop: (theme) => theme.spacing(8),
              paddingBottom: (theme) => theme.spacing(8)
            }} maxWidth={false}>
              {/* End hero unit */}
              {/*<LoginForm />*/}
            </Container>
          </main>
          {/* Footer */}
          <Footer />
          {/* End footer */}
        </HelmetProvider>


      </main>
    </AuthContext.Provider>
  );
}

export default App; 