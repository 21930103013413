import React, { createRef } from 'react'
import '../styles.css'
import './css/ReactionContainer.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FacebookShareButton } from 'react-share';
import { addReaction, getReactions } from '../services/reactionServices';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Typography from "@mui/material/Typography";
import { cloneDeep } from 'lodash';
import WaitSpinner from './WaitSpinner';
import Login from '../firebase/Login';
import IsAuthenticated from './IsAuthenticated';
import { randomString } from '../services/helperTools';
import { Section } from '../interfaces/SectionInterface';
import { Tok } from '../interfaces/TokInterface';
import { Set } from '../interfaces/SetInterface';
import { Reaction } from '../interfaces/ReactionInterface';

const MySwal = withReactContent(Swal);

interface BooleanReactions {
    gema: boolean;
    gemb: boolean;
    gemc: boolean;
    accurate: boolean;
    inaccurate: boolean;
    all: boolean;
}

interface States {
    ShareHref: string;
    EnableShare: boolean;
    Id: string;
    Pk: string;
    DetailNum: number;
    TokSection: Section;
    QuestionNum: number;
    OwnerId: string;
    HasUserAccurate: boolean;
    HasUserGem: boolean;
    UserDataSuccess: boolean;
    LoadActive: boolean;
    LeftDisplay: boolean;
    ReactionCount: number;
    Item: Tok | Set;
    Reactions: {
        gema: Reaction[];
        gemb: Reaction[];
        gemc: Reaction[];
        accurate: Reaction[];
        inaccurate: Reaction[];
        all: Reaction[];
    };
    ReactionTokens: {
        gema: string;
        gemb: string;
        gemc: string;
        accurate: string;
        inaccurate: string;
        all: string;
    };
    ReactionActiveLoaders: BooleanReactions;
    ReactionInitialLoad: BooleanReactions;
    ReactionViewShow: boolean;
    ActiveReactionTab: string;
    callLoginKey: string;
    columnView: boolean;
    fbShare: boolean;
    shareLink: string;
    mobileView: boolean;
    oldUiView: boolean;
    Token: string;
    LoadMoreActive: boolean;
    authenticated: boolean;
}

interface Props {
    reactionSwitchUiRef: React.RefObject<any>;
    updateTok: (tok: Tok) => void;
    enableShare: boolean;
    Id: string;
    Pk: string;
    TokSection: Section;
    QuestionNum: number;
    OwnerId: string;
    item: Tok | Set;
    columnView: boolean;
    fbShare: boolean;
    shareLink: boolean;
    commentCounterRef: React.RefObject<any>;
    groupId: string;
    linkZIndex: number;
    DetailNum: boolean;
    LeftDisplay: boolean;
}

class ReactionContainer extends React.Component<Props, States> {
    commentCounterRef: React.RefObject<any>;
    loginCallTrigger: React.RefObject<any>;
    reactionSwitchUiRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        var detailNo = -1;
        try {
            if (Number(props["DetailNum"]) >= 0) {
                detailNo = Number(props["DetailNum"]);
            }
        } catch (err) { }
        this.state = {
            authenticated: false,
            LoadMoreActive: false,
            Token: "",
            ShareHref: window.location.href,
            EnableShare: props.enableShare,
            Id: props.Id,
            Pk: props.Pk,
            DetailNum: detailNo,
            TokSection: props.TokSection,
            QuestionNum: props.QuestionNum,
            OwnerId: props.OwnerId,
            HasUserAccurate: false,
            HasUserGem: false,
            UserDataSuccess: false,
            LoadActive: false,
            LeftDisplay: props["LeftDisplay"] ? props["LeftDisplay"] : false,
            ReactionCount: 0,
            Item: props.item ? props.item : {},

            Reactions: {
                "gema": new Array,
                "gemb": new Array,
                "gemc": new Array,
                "accurate": new Array,
                "inaccurate": new Array,
                "all": new Array,
            },
            ReactionTokens: {
                "gema": "",
                "gemb": "",
                "gemc": "",
                "accurate": "",
                "inaccurate": "",
                "all": "",
            },
            ReactionActiveLoaders: {
                "gema": false,
                "gemb": false,
                "gemc": false,
                "accurate": false,
                "inaccurate": false,
                "all": false,
            },
            ReactionInitialLoad: {
                "gema": false,
                "gemb": false,
                "gemc": false,
                "accurate": false,
                "inaccurate": false,
                "all": false,
            },
            ReactionViewShow: false,
            ActiveReactionTab: "all",
            callLoginKey: "",
            columnView: props.columnView ? props.columnView : false,
            fbShare: props.fbShare ? props.fbShare : false,
            shareLink: props.shareLink ? props.shareLink : "",
            mobileView: false,
            oldUiView: false,
        };

        this.postReaction = this.postReaction.bind(this);
        this.inaccurateReactionSet = this.inaccurateReactionSet.bind(this);
        this.inaccurateDisable = this.inaccurateDisable.bind(this);

        this.commentCounterRef = props.commentCounterRef;

        this.getUserReactions = this.getUserReactions.bind(this);
        this.getItemReactions = this.getItemReactions.bind(this);
        this.openReactionViewer = this.openReactionViewer.bind(this);
        this.closeReactionViewer = this.closeReactionViewer.bind(this);
        this.tabReactionView = this.tabReactionView.bind(this);

        this.loadMoreScroll = this.loadMoreScroll.bind(this);

        this.loginCallTrigger = createRef();
        this.callLogin = this.callLogin.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);

        this.reactionSwitchUiRef = props.reactionSwitchUiRef ? props.reactionSwitchUiRef : "";
        this.switchUi = this.switchUi.bind(this);
    }

    componentDidMount() {
        this.setState({
            LoadActive: true,
        });
        this.getData();
    }
    async getData() {
        //will probably make a seperate
        var { Item, DetailNum } = this.state;
        var gemaCount = DetailNum >= 0 ? Item["gemas_details"][DetailNum] : Item["gemas"] ? Item["gemas"] : 0;
        var gembCount = DetailNum >= 0 ? Item["gembs_details"][DetailNum] : Item["gembs"] ? Item["gembs"] : 0;
        var gemcCount = DetailNum >= 0 ? Item["gemcs_details"][DetailNum] : Item["gemcs"] ? Item["gemcs"] : 0;
        var heartCount = DetailNum >= 0 ? Item["hearts_details"][DetailNum] : Item["hearts"] ? Item["hearts"] : 0;
        var sadCount = DetailNum >= 0 ? Item["sads_details"][DetailNum] : Item["sads"] ? Item["sads"] : 0;
        var accurateCount = DetailNum >= 0 ? Item["accurates_details"][DetailNum] : Item["accurates"] ? Item["accurates"] : 0;
        var inaccurateCount = DetailNum >= 0 ? Item["inaccurates_details"][DetailNum] : Item["inaccurates"] ? Item["inaccurates"] : 0;
        var reactionCount = gemaCount + gembCount + gemcCount + accurateCount + inaccurateCount + heartCount + sadCount;
        //getuser owned user data
        this.getUserReactions();
        this.setState({
            LoadActive: true,
            ReactionCount: reactionCount
        });
    }
    getUserReactions() {
        var { Id, DetailNum, Item } = this.state;
        let userdata = JSON.parse(window.localStorage.getItem("userdata"));
        let userid = userdata["id"];

        //configure later on a date to add detail num 
        getReactions({
            item_id: Id,
            user_id: userid
        }, Id).then(res => {
            if(res){
                if (res["data"]["status_code"] == 200) {
                    let hasUserAccurate = false;
                    let hasUserGem = false;
                    if (res["data"]["result"]) {
                        let items = res["data"]["result"];
                        hasUserAccurate = items.filter(item => item["detail_num"] == (DetailNum + 1) && item["kind"].includes("accurate")).length > 0;
                        hasUserGem = items.filter(item => item["detail_num"] == (DetailNum + 1) && item["kind"].includes("gem")).length > 0;
                    }
                    try {
                        if (hasUserGem || hasUserAccurate) {
                            Item["has_gem"] = hasUserGem;
                            Item["has_accurate"] = hasUserAccurate;
                            this.props.updateTok(Item as Tok);
                        }
                    } catch (err) { }
    
                    this.setState({
                        Token: res["data"]["pagination"] ? res["data"]["pagination"] : "",
                        LoadActive: false,
                        LoadMoreActive: false,
                        UserDataSuccess: true,
                        HasUserAccurate: hasUserAccurate,
                        HasUserGem: hasUserGem
                    });
                }
            }
        }).finally(() => {
            this.setState({
                LoadActive: false,
            });
        }).catch(err => {
            this.setState({
                LoadActive: false,
                UserDataSuccess: false
            });
        })
    }
    getItemReactions(type, loaders = {} as BooleanReactions, token = {}, loadmore = false) {
        //add load more function at a later date
        var { Id, DetailNum } = this.state;
        //need to add Detail Num
        if (loaders[type] == false) {
            let query = {
                item_id: Id,
            };
            if (type != "all") {
                query["kind"] = type;
            } else {
                query["!kind"] = "comment";
            }
            if (DetailNum >= 0) {
                query["$detail_num"] = DetailNum + 1;
            }
            if (loadmore) {
                query["pagination"] = token[type]
            }
            loaders[type] = true;
            this.setState({
                ReactionActiveLoaders: loaders
            });

            getReactions(query, Id).then(res => {
                var { Reactions, ReactionTokens, ReactionInitialLoad } = this.state;
                if(res){
                    if (res["data"]["status_code"] == 200) {
                        Reactions[type] = Reactions[type].length == 0 ? !res.data["result"] ? new Array : res.data["result"] : Reactions[type].concat(res.data["result"]);
                        ReactionTokens[type] = res.data["pagination"] ? res.data["pagination"] : "";
                        ReactionInitialLoad[type] = true;
                        this.setState({
                            Reactions: Reactions,
                            ReactionTokens: ReactionTokens,
                            ReactionInitialLoad: ReactionInitialLoad
                        });
                    }
                }
            }).finally(() => {
                var { ReactionActiveLoaders } = this.state;
                ReactionActiveLoaders[type] = false;
                this.setState({
                    ReactionActiveLoaders: ReactionActiveLoaders
                });
            }).catch(err => {
            })
        }
    }

    openReactionViewer() {
        const { Reactions, ReactionActiveLoaders } = this.state;
        if (Reactions["all"].length == 0) {
            this.getItemReactions("all", cloneDeep(ReactionActiveLoaders));
        }
        this.setState({
            ReactionViewShow: true
        });
    }
    closeReactionViewer() {
        this.setState({
            ReactionViewShow: false
        });
    }
    tabReactionView(e) {
        const { Reactions, ReactionActiveLoaders, ReactionInitialLoad } = this.state;
        var type = e.currentTarget.getAttribute("data-type");
        if (ReactionInitialLoad[type] == false) {
            this.getItemReactions(type, cloneDeep(ReactionActiveLoaders));
        }
        this.setState({
            ActiveReactionTab: type
        });
    }
    postReaction(btn) {
        var { Item, Id, DetailNum, TokSection, QuestionNum, OwnerId, HasUserAccurate, HasUserGem, ReactionCount } = this.state;
        const type = btn.currentTarget.getAttribute("data-type");
        if (type) {
            var continuePost = true;
            if (((type == "gemb" || type == "gemc") && HasUserGem) || (type == "accurate" && HasUserAccurate)) {
                continuePost = false;
            }
            if (continuePost) {
                const reactionTest = {
                    "kind": type,
                    "item_id": Id,
                    "detail_num": DetailNum + 1,
                    "label": "reaction",
                    "tok_section": TokSection,
                    "question_number": QuestionNum,
                    "owner_id": OwnerId
                };
                MySwal.fire({
                    title: <p>Adding Reaction</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading();
                let feedId = Id ? Id : "";
                addReaction(reactionTest, feedId).then(response => {
                    if(response){
                        if (response["status"] == 200) {
                            var refTarget = type + "s" + (DetailNum >= 0 ? "_details" : "");
                            if (DetailNum >= 0) {
                                Item[refTarget][DetailNum]++;
                            } else {
                                if (Item[refTarget]) {
                                    Item[refTarget]++
                                } else {
                                    Item[refTarget] = 1
                                }
                            }
                            this.props.updateTok(Item as Tok);
                            MySwal.update({
                                title: <p>Reaction Added</p>,
                                icon: "success"
                            });
                            if (type.includes("gem")) {
                                this.setState({
                                    Item: Item,
                                    HasUserGem: true,
                                    //ReactionCount: ReactionCount + addedCount
                                    ReactionCount: ReactionCount + 1
                                })
                            } else if (type.includes("accurate")) {
                                this.setState({
                                    Item: Item,
                                    HasUserAccurate: true,
                                    //ReactionCount: ReactionCount + 5
                                    ReactionCount: ReactionCount + 1
                                });
                            }
    
                            //MySwal.update({
                            //    title: <p>Reaction Already Exists</p>,
                            //    icon: "warning"
                            //});
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }
                }).finally(() => {
                    MySwal.hideLoading();
                }).catch(err => {
                })
            } else {
                MySwal.fire({
                    title: <p>Reaction Already Exists</p>,
                    allowOutsideClick: false,
                    icon: "warning"
                });
            }

        } else {
            MySwal.fire({
                title: <p>Invalid Reaction</p>,
                allowOutsideClick: false,
                icon: "warning"
            });
        }

    }
    inaccurateReactionSet() {
        const { Id, DetailNum, Pk, HasUserAccurate } = this.state;
        if (HasUserAccurate) {
            MySwal.fire({
                title: <p>Reaction Already Exists</p>,
                allowOutsideClick: false,
                icon: "warning"
            });
        } else {
            try {
                (document.querySelector(`.inaccurate-set[data-id="${Id}"][data-pk="${Pk}"][data-detailno="${DetailNum}"]`) as HTMLElement).click();
            } catch (err) { }
        }
    }
    inaccurateDisable() {
        const { ReactionCount } = this.state;
        this.setState({
            HasUserAccurate: true,
        });
    }

    loadMoreScroll(e) {
        const { ActiveReactionTab, ReactionActiveLoaders, ReactionTokens } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !ReactionActiveLoaders[ActiveReactionTab] && ReactionTokens[ActiveReactionTab]) {
            this.getItemReactions(ActiveReactionTab, ReactionActiveLoaders, ReactionTokens, true);
        }
    }
    isAuthenticated(data) {
        this.setState({
            authenticated: data,
            callLoginKey: ""
        });
    }
    callLogin() {
        this.setState({
            callLoginKey: randomString(10)
        });
    }
    switchUi(e) {
        var { mobileView, oldUiView } = this.state;
        const mode = e.currentTarget.getAttribute("data-mode");
        const value = e.currentTarget.getAttribute("data-value");

        this.setState({
            mobileView: mode == "mobile" && value == "true" ? true : mode == "mobile" && value == "false" ? false : mobileView,
            oldUiView: mode == "oldui" && value == "true" ? true : mode == "oldui" && value == "false" ? false : oldUiView,
        });
    }
    render() {
        var { mobileView, oldUiView, columnView, callLoginKey, authenticated, ReactionActiveLoaders, Reactions, ActiveReactionTab, ReactionViewShow, ReactionCount, Id, Pk, DetailNum, LeftDisplay, LoadActive, HasUserAccurate, HasUserGem, UserDataSuccess, ShareHref, EnableShare } = this.state;
        if (callLoginKey) {
            this.loginCallTrigger.current.click();
        }
        columnView = (oldUiView || mobileView) ? !(oldUiView || mobileView) : columnView;
        return (
            <>
                <Login loginCallTrigger={this.loginCallTrigger} />
                <IsAuthenticated isAuthenticated={this.isAuthenticated} />
                <Button hidden ref={this.reactionSwitchUiRef} data-mode data-value onClick={this.switchUi}></Button>
                <Container onClickCapture={authenticated ? undefined : this.callLogin} style={{ margin: "unset" }}>
                    <Modal className={'dark-background-2'} show={ReactionViewShow} onHide={this.closeReactionViewer} style={{ zIndex: "1406" }}>
                        <Modal.Header className={'dark-background-2'}>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <Typography fontWeight={"bold"} marginRight={"auto"}>View Reactions</Typography>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={!authenticated ? undefined : this.closeReactionViewer} />
                            </div>

                        </Modal.Header>
                        <Modal.Body className={'dark-background-2'}>
                            <Row>
                                <Col className={ActiveReactionTab == "all" ? 'reaction-btn active' : 'reaction-btn'} onClick={!authenticated ? undefined : this.tabReactionView} data-type={'all'}>
                                    All
                                </Col>
                                <Col className={ActiveReactionTab == "gemb" ? 'reaction-btn active' : 'reaction-btn'} onClick={!authenticated ? undefined : this.tabReactionView} data-type={'gemb'}>
                                    <img src="https://a.slack-edge.com/production-standard-emoji-assets/13.0/google-medium/1f4af.png" />
                                </Col>
                                <Col className={ActiveReactionTab == "gemc" ? 'reaction-btn active' : 'reaction-btn'} onClick={!authenticated ? undefined : this.tabReactionView} data-type={'gemc'}>
                                    <img src="https://tokketcontent.blob.core.windows.net/tokketweb/gem1_Purple.png" />
                                </Col>
                                <Col className={ActiveReactionTab == "accurate" ? 'reaction-btn active' : 'reaction-btn'} onClick={!authenticated ? undefined : this.tabReactionView} data-type={'accurate'}>
                                    <CheckIcon style={{ height: "22px", width: "auto" }} />
                                </Col>
                                <Col className={ActiveReactionTab == "inaccurate" ? 'reaction-btn active' : 'reaction-btn'} onClick={!authenticated ? undefined : this.tabReactionView} data-type={'inaccurate'}>
                                    <CloseIcon style={{ height: "22px", width: "auto" }} />
                                </Col>
                            </Row>
                            <Row className={'reactioner-container'} onScroll={this.loadMoreScroll}>
                                {ReactionActiveLoaders[ActiveReactionTab] && Reactions[ActiveReactionTab].length == 0 ?
                                    <WaitSpinner /> :
                                    Reactions[ActiveReactionTab].map(Reaction => {
                                        return (
                                            <Col className={'reactioner'} xs={12}>
                                                <div className={'reactioner-image'} style={{ backgroundImage: Reaction["user_photo"] ? `url(${Reaction["user_photo"]})` : "url(https://tokketcontent.blob.core.windows.net/icons/TOKKET%20smiley%201B_yellow.png)" }} />
                                                <div className={'reactioner-name'}>
                                                    {Reaction["user_display_name"]}
                                                </div>
                                                <div className={'reactioner-icon'}>
                                                    {Reaction["kind"] == "gemb" ? <img src="https://a.slack-edge.com/production-standard-emoji-assets/13.0/google-medium/1f4af.png" /> : ""}
                                                    {Reaction["kind"] == "gemc" ? <img src="https://tokketcontent.blob.core.windows.net/tokketweb/gem1_Purple.png" /> : ""}
                                                    {Reaction["kind"] == "accurate" ? <CheckIcon style={{ height: "22px", width: "auto" }} /> : ""}
                                                    {Reaction["kind"] == "inaccurate" ? <CloseIcon style={{ height: "22px", width: "auto" }} /> : ""}
                                                </div>
                                            </Col>
                                        )
                                    })}
                                {ReactionActiveLoaders[ActiveReactionTab] && Reactions[ActiveReactionTab].length ? <WaitSpinner /> : ""}
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Button onClick={!authenticated ? undefined : this.inaccurateDisable} hidden className={'disable-accurate-set'} data-id={Id} data-pk={Pk} data-detailno={DetailNum}>
                        Disable Accurate
                    </Button>
                    <Row className={'reaction-container'} style={{ flexDirection: columnView ? "column" : "unset" }}>
                        {LoadActive && LeftDisplay ?
                            <Col>
                                <CircularProgress style={{ height: "20px", width: "20px", display: "flex", alignItems: "center" }} />
                            </Col> : ""
                        }
                        {!UserDataSuccess && !LoadActive && LeftDisplay ?
                            <Col>
                                <Tooltip title={"Failed to load user reaction data"}>
                                    <CancelIcon style={{ height: "20px", width: "20px", color: "#DC143C", display: "flex", alignItems: "center" }} />
                                </Tooltip>
                            </Col> : ""
                        }

                        <div className={HasUserGem ? 'reaction-btn reaction-exist' : 'reaction-btn'} onClick={!authenticated ? undefined : this.postReaction} data-type={'gemb'}>
                            <img src="https://a.slack-edge.com/production-standard-emoji-assets/13.0/google-medium/1f4af.png" />
                        </div>
                        <div className={HasUserGem ? 'reaction-btn reaction-exist' : 'reaction-btn'} onClick={!authenticated ? undefined : this.postReaction} data-type={'gemc'}>
                            <img src="https://tokketcontent.blob.core.windows.net/tokketweb/gem1_Purple.png" />
                        </div>
                        <div className={HasUserAccurate ? 'reaction-btn reaction-exist' : 'reaction-btn'} onClick={!authenticated ? undefined : this.postReaction} data-type={'accurate'}>
                            <CheckIcon style={{ height: "22px", width: "auto" }} />
                        </div>
                        <div className={HasUserAccurate ? 'reaction-btn reaction-exist' : 'reaction-btn'} onClick={!authenticated ? undefined : this.inaccurateReactionSet}>
                            <CloseIcon style={{ height: "22px", width: "auto" }} />
                        </div>

                        <div className={'reaction-counter'} style={{ marginLeft: "unset", cursor: "pointer" }} onClick={!authenticated ? undefined : this.openReactionViewer}>
                            {ReactionCount}
                        </div>
                        {LoadActive && !LeftDisplay ?
                            <Col xs={"auto"}>
                                <CircularProgress style={{ height: "20px", width: "20px", display: "flex", alignItems: "center" }} />
                            </Col> : ""
                        }
                        {!UserDataSuccess && !LoadActive && !LeftDisplay ?
                            <Col>
                                <Tooltip title={"Failed to load user reaction data"}>
                                    <CancelIcon style={{ height: "20px", width: "20px", color: "#DC143C", display: "flex", alignItems: "center" }} />
                                </Tooltip>
                            </Col> : ""
                        }
                        {EnableShare ?
                            <FacebookShareButton
                                url={ShareHref}
                                className={'fb-share-btn'}
                                style={{ marginTop: columnView ? "5px" : "", marginLeft: columnView ? "" : "5px" }}
                            >
                                <FacebookIcon />
                            </FacebookShareButton> : ""}
                    </Row>
                </Container>
            </>
        );
    }
}

export default ReactionContainer;