import React from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useState } from 'react';
import { faImage, faTimes, faPenToSquare, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from "react";
import Cropper from "react-cropper";
import "./css/cropper.css";
import "./css/AddEditClassSet.css";
import { addClassSet, patchSet, updateClassSet } from '../services/classServices';
import { getToken } from '../services/accountServices';
import { cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import { Set } from '../interfaces/SetInterface';

const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
const MySwal = withReactContent(Swal);
const localUser = localStorage.getItem("userdata");
const localUserJson = localUser ? JSON.parse(localUser) : [];

interface EmptySet extends Set {
    is_group: false,
}

const emptySet: Partial<EmptySet> = {
    account_type: "individual",
    category: "",
    color_main_hex: "#FFFFFF",
    description: "",
    ids: [],
    is_private: true,
    label: "set",
    name: "",
    privacy: null,
    setids: [],
    tok_group: "",
    tok_type: null,
    tok_type_id: null,
    tokpks: [],
    user_country: null,
    user_display_name: localUserJson["display_name"] ? localUserJson["display_name"] : "",
    user_id: localUserJson["id"] ? localUserJson["id"] : "",
    reference_id: "",
    image: ""
};
const AddEditClassSet = (props) => {
    const groupId = props.groupId;
    const communityId = props.communityid;
    const addClassSetTrigger = props.addClassSetTrigger;
    useEffect(() => {
        async function fetchToken() {
            await getToken();
        }
        fetchToken();
    }, []);
    const [show, setShow] = useState(false);
    const [classSet, setClassSet] = useState(props.set ? cloneDeep(props.set) : emptySet);
    const [classSetOG, setClassSetOG] = useState(props.set ? cloneDeep(props.set) : emptySet);
    const [editMode, setEditMode] = useState(props.set ? props.set["id"] ? true : false : false);
    const handleClose = () => {
        setShow(false);
        setClassSet(props.set ? props.set : emptySet);
    };
    const handleOpen = () => {
        setShow(true);
    };
    const SetSetValues = (e) => {
        var value = e.target.value;
        const dataType = e.target.name;
        var tempSet = classSet;
        tempSet[dataType] = value;
        setClassSet(classSet => ({
            ...classSet,
            ...tempSet
        }));
    }
    const cropperRef = useRef(null);
    const [cropperShow, setCropperShow] = useState(false);
    const [cropperSrc, setCropperSrc] = useState(null);
    const handleCropperClose = () => {
        setCropperShow(false);
    };
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        var tempSet = classSet;
        tempSet["image"] = cropper.getCroppedCanvas().toDataURL();
        setCropperShow(false);
        setClassSet(classSet => ({
            ...classSet,
            ...tempSet
        }));
    };
    const onImageSet = (e) => {
        setCropperSrc(URL.createObjectURL(e.currentTarget.files[0]));
        setCropperShow(true);
    }
    const removeImage = (e) => {
        var tempSet = classSet;
        tempSet["image"] = "";
        setClassSet(classSet => ({
            ...classSet,
            ...tempSet
        }));
    }
    const [colorPickShow, setColorPickShow] = useState(false);
    const [colorPick, setColorPick] = useState("#FFFFFF");
    const handleColorClose = () => {
        setColorPickShow(false);
    }
    const handleColorSet = () => {
        setColorPickShow(true);
        setColorPick(classSet["color_main_hex"]);
    }
    const setPickColor = () => {
        var tempSet = classSet;
        tempSet["color_main_hex"] = colorPick;
        setClassSet(classSet => ({
            ...classSet,
            ...tempSet
        }));
        setColorPickShow(false);
    }
    const setPickColorTemp = (e) => {
        const color = e.target.getAttribute("data-color");
        setColorPick("#" + color);
    }
    const submitClassSet = (e) => {
        MySwal.fire({
            title: <p>{editMode ? "Updating" : "Adding"} Set</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        var tempSet = classSet;
        if (editMode) {
            let patchObject = diff(classSetOG, classSet);
            let patchObjectKeys = Object.keys(patchObject);
            for (let x = 0; x < patchObjectKeys.length; x++) {
                patchObject[patchObjectKeys[x]] = cloneDeep(classSet[patchObjectKeys[x]]);
            }
            patchSet(patchObject, classSetOG["id"], classSetOG["pk"]).then(result => {
                if (result) {
                    if (result["status"]) {
                        if (result.status == 200) {
                            MySwal.update({
                                title: <p>Set Updated</p>,
                                icon: "success"
                            });
                            try {
                                props.updateSet(tempSet);
                            } catch (err) {
                            }
                            setClassSet(classSet => ({
                                ...classSet,
                                ...tempSet
                            }));
                            setClassSetOG(classSetOG => ({
                                ...classSetOG,
                                ...tempSet
                            }));
                            setShow(false);
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(error => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        } else {
            if (groupId) {
                tempSet["group_id"] = groupId;
                tempSet["isgroup"] = true;
            }
            addClassSet(tempSet).then(result => {
                if (result) {
                    if (result.data["status_code"]) {
                        if (result.data.status_code == 200) {
                            MySwal.update({
                                title: <p>Set Added</p>,
                                icon: "success"
                            });
                            try {
                                props.newSet(result.data.result);
                            } catch (err) { }
                            setShow(false);
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }
            }).catch(error => {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }
    }
    return (
        <>
            <Button ref={addClassSetTrigger} onClick={handleOpen} hidden>{editMode ? "Edit" : "Add"} Set</Button>
            <Modal size='lg' show={cropperShow} onHide={handleCropperClose} style={{ zIndex: "1206" }}>
                <Modal.Header>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={handleCropperClose} />
                        Cropper
                        <Button variant="success" onClick={onCrop}>Crop</Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Cropper
                        src={cropperSrc}
                        style={{ height: 400, width: "100%" }}
                        initialAspectRatio={16 / 9}
                        ref={cropperRef}
                    />

                </Modal.Body>
            </Modal>
            <Modal size='lg' show={colorPickShow} onHide={handleColorClose} style={{ zIndex: "1306" }}>
                <Modal.Header className={'text-color-white dark-background-2'}>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={handleColorClose} />
                        Select Color
                        <Button variant="success" onClick={setPickColor}>Set</Button>
                    </div>
                </Modal.Header>
                <Modal.Body className={'text-color-white dark-background-2'}>
                    <Row style={{ marginTop: "10px" }}>
                        {colors.map((color, index) => (
                            <Col onClick={setPickColorTemp} data-color={color} xs={3} className={colorPick == "#" + color ? 'color-class-tok-picker-item active' : 'color-class-tok-picker-item'} style={{ backgroundColor: `#${color}` }}>
                                <div className={'color-tok-picker-active-indicator'}>
                                    <FontAwesomeIcon size={'2xl'} icon={faCheck} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal size='lg' show={show} onHide={handleClose} style={{ zIndex: "1205" }}>
                <Modal.Header>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} onClick={handleClose} icon={faTimes} />
                        {editMode ? "Edit" : "Add"} Set
                        <Button variant="success" style={{ display: "flex", alignItems: "center" }} onClick={submitClassSet}>
                            <div className={'parrot-write-icon'}></div>
                            <span>{editMode ? "Update" : "Create"}</span>
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body className={'modal-body-addclassset-height'}>
                    <Row>
                        <Col xs={12} style={{ fontWeight: "300", fontSize: "1.25rem", color: "gray", textAlign: "center" }}>
                            Sets hold toks so they can be used for games
                        </Col>
                        <Col xs={12} style={{ fontWeight: "600", fontSize: "1.25rem" }}>
                            Enter the Set info
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TextField style={{ width: "100%" }} onChange={SetSetValues} value={classSet["reference_id"] ? classSet["reference_id"] : ""} name="reference_id" label="ID/#: (Optional)" inputProps={{ maxLength: 20 }} />
                            <p className={'character-count'}>{classSet["reference_id"] ? classSet["reference_id"].length : "0"}/20</p>
                        </Col>
                        <Col xs={12} style={{ marginBottom: "20px" }}>
                            <TextField style={{ width: "100%" }} onChange={SetSetValues} value={classSet["tok_group"] ? classSet["tok_group"] : ""} select label="Choose Format" name="tok_group">
                                <MenuItem value={"Playable"}>
                                    Playable
                                </MenuItem>
                                <MenuItem value={"Non-Playable"}>
                                    Non-Playable
                                </MenuItem>
                            </TextField>
                        </Col>
                        <Col xs={12}>
                            <TextField style={{ width: "100%" }} value={classSet["category"] ? classSet["category"] : ""} onChange={SetSetValues} name="category" label="Category" inputProps={{ maxLength: 50 }} />
                            <p className={'character-count'}>{classSet["category"] ? classSet["category"].length : "0"}/50</p>
                        </Col>
                        <Col xs={12}>
                            <TextField style={{ width: "100%" }} value={classSet["name"] ? classSet["name"] : ""} onChange={SetSetValues} name="name" label="Set Name" inputProps={{ maxLength: 35 }} />
                            <p className={'character-count'}>{classSet["name"] ? classSet["name"].length : "0"}/35</p>
                        </Col>
                        <Col xs={12}>
                            <TextField style={{ width: "100%" }} value={classSet["description"] ? classSet["description"] : ""} onChange={SetSetValues} multiline minRows={3} name="description" label="Description" inputProps={{ maxLength: 100 }} />
                            <p className={'character-count'}>{classSet["description"] ? classSet["description"].length : "0"}/100</p>
                        </Col>
                        <Col xs={'auto'}>
                            <Button variant="primary" style={{ width: "100%" }} onClick={handleColorSet}>
                                Select Tile Color
                            </Button>
                        </Col>

                        <Col xs={4}>
                            <Row style={{ height: "100%", alignItems: "center" }}>
                                <span className={"col-auto"}>Selected Color:</span>
                                <Col className={"selected-color-indicator"} style={{ backgroundColor: classSet["color_main_hex"] ? classSet["color_main_hex"] : "#FFFFFF" }} onClick={handleColorSet}></Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <hr />
                        </Col>
                        <Col xs={12}>
                            {classSet["image"] ?
                                <>
                                    <div>Selected Image:</div>
                                    <div style={{ marginTop: "top", marginBottom: "10px" }}><img src={classSet["image"]} style={{ maxWidth: "100%" }} /></div>
                                    <Button variant='primary' style={{ marginRight: "5px", width: "max-content" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                        Browse...
                                        <input type="file" accept="image/*" onChange={onImageSet} hidden />
                                    </Button>
                                    <Button onClick={removeImage} variant='primary' style={{ width: "max-content" }}>Remove Image</Button>
                                </> :
                                <>
                                    <div>Select a PNG or JPEG image, max size 20 MB (optional)</div>
                                    <div>Picture will be displayed as a square or circle - make sure to crop before uploading.</div>
                                    <div>
                                        <Button variant="primary" style={{ width: "160px" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                            Browse...
                                            <input type="file" accept="image/*" onChange={onImageSet} hidden />
                                        </Button>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default AddEditClassSet;