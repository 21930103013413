import React from 'react'
import '../styles.css'
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { createRef } from 'react';
import { TextField } from '@mui/material';
import ButtonReact from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import { getToken } from '../services/accountServices';
import { getToks } from '../services/classServices';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import './css/AddExistingClassTok.css'
import { Tok } from '../interfaces/TokInterface';
interface Props {
    callTrigger: React.RefObject<any>;
    setTokLink: (e: any) => void;
}
interface States {
    items: Tok[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    ShowModal: boolean;
    SelectedItem: Tok;
  }
class SearchTokLink extends React.Component<Props, States> {
    callBtnTrigger: React.RefObject<any>;
    searchInput: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            ShowModal: false,
            SelectedItem: {} as Tok
        };

        this.reload = this.reload.bind(this);
        //this.loadmore = this.loadmore.bind(this);
        this.callBtnTrigger = props.callTrigger;

        this.callModal = this.callModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.searchInput = createRef();
        this.setTokSearch = this.setTokSearch.bind(this);

        this.setTempTokLink = this.setTempTokLink.bind(this);
        this.setTokLink = this.setTokLink.bind(this);

        this.loadMoreScroll = this.loadMoreScroll.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    async getData(customQuery = null) {
        await getToken();
        try {
            var { query, items, continuationToken } = this.state;
            if (customQuery) {
                query = customQuery;
            }
            var result = await getToks(query, "", "");
            if(result){
                if (result.status == 200) {
                    if (continuationToken) {
                        items = items.concat(result.data.resource.results);
                    } else {
                        items = result.data.resource.results;
                    }
                    this.setState({
                        items: items,
                        continuationToken: result.data.resource.continuationToken,
                        LoadActive: false,
                        LoadMoreActive: false
                    });
                }
            }
        } catch (err) { }
    }
    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData();
        });
    }
    callModal() {
        const { items } = this.state;
        if (items.length == 0) {
            this.reload();
        }
        this.setState({
            ShowModal: true
        });
    }
    closeModal() {
        this.setState({
            ShowModal: false
        });
    }
    setTempTokLink(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        const { items, SelectedItem } = this.state;
        var selectedItem: Partial<Tok> = { id: "", pk: "" };
        var filterItems = items.filter(item => id == item.id && pk == item.pk);
        if(filterItems.length){
            selectedItem = filterItems[0];
        }
        this.setState({
            SelectedItem: selectedItem as Tok
        });
    }
    setTokLink() {
        const { SelectedItem } = this.state;
        window.localStorage.setItem("copiedTok", JSON.stringify(SelectedItem));
        try {
            this.props.setTokLink(null);
        } catch (err) { }
        this.closeModal();
    }

    setTokSearch() {
        const search = this.searchInput.current.querySelector("input").value;
        this.setState({
            LoadActive: true,
            items: [],
            SelectedItem: {} as Tok
        });
        var { query } = this.state;
        if (query) {
            query.searchkey = "primary_text";
            query.searchvalue = search;
        } else {
            query = { "searchkey": "primary_text", "searchvalue": search }
        }

        this.getData(query)
    }
    loadMoreScroll(e) {
        const { LoadMoreActive, continuationToken } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !LoadMoreActive && continuationToken) {
            this.setState({
                LoadMoreActive: true
            }, () => {
                let query = { "pagination": continuationToken }
                this.getData(query)
            });
        }
    }
    render() {
        var { LoadActive, items, LoadMoreActive, ShowModal, SelectedItem } = this.state;

        return (
            <>
                <Button hidden ref={this.callBtnTrigger} onClick={this.callModal}></Button>
                <Modal size='lg' show={ShowModal} style={{ zIndex: "1306" }} onHide={this.closeModal}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModal} />
                            Search Toks
                            <ButtonReact variant="primary" onClick={this.setTokLink}>Add Tok Link</ButtonReact>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Row>
                            <Col xs={12} className={'row'} style={{ paddingLeft: "unset", marginLeft: "unset" }}>
                                <Col style={{ position: "relative" }}>
                                    <TextField ref={this.searchInput} className={"search-field text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px", width: "100%" }} label={`Search`} />
                                </Col>
                                <div style={{ width: "55px", margin: "unset", padding: "unset" }}>
                                    <ButtonReact variant='primary' className={'search-btn'} style={{ height: "100%", width: "100%" }} onClick={this.setTokSearch}>
                                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                                    </ButtonReact>
                                </div>
                            </Col>
                            <Col xs={12} style={{ marginTop: "15px", height: "65vh", overflowY: "scroll", overflowX: "hidden" }} onScroll={this.loadMoreScroll}>
                                {LoadActive ?
                                    <WaitSpinner /> :
                                    <Row className={'selection-container'} style={{ margin: "unset", padding: "unset" }}>
                                        {items.map((tok) => (
                                            <Col onClick={this.setTempTokLink} data-id={tok.id} data-pk={tok.pk} className={SelectedItem["id"] == tok.id && SelectedItem["pk"] == tok.pk ? 'row selection-item active' : 'row selection-item'} xs={12}>
                                                <div style={{ height: "70px", width: "70px", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: tok["user_photo"] ? `url(${tok["user_photo"]})` : `url("https://tokketcontent.blob.core.windows.net/tokketweb/default.png")` }}></div>
                                                <Col style={{ fontWeight: "600", alignSelf: "center" }}>
                                                    <div>
                                                        {tok["user_display_name"] ? tok["user_display_name"] : ""}
                                                    </div>
                                                    <div>
                                                        {tok["primary_text"] ? tok["primary_text"] : ""} <span style={{ color: "gray" }}>{tok["tok_type"] ? " • Tok Type: " + tok["tok_type"] : ""}</span>
                                                    </div>
                                                </Col>
                                            </Col>
                                        ))}
                                    </Row>
                                }
                                {LoadMoreActive ?
                                    <WaitSpinner /> : ""
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default SearchTokLink;