import React, { createRef } from 'react'
import '../styles.css'
import './css/NavbarNotification.css'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getToken, getUsers } from '../services/accountServices';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getClassGroupRequests, sendRequestGroup } from '../services/classServices';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import WaitSpinner from './WaitSpinner';
import { Group } from '../interfaces/GroupInterface';
import { TokUser } from '../interfaces/TokUserInterface';

const MySwal = withReactContent(Swal);
interface States {
    LoadActive: boolean;
    showInviteUsers: boolean;
    query: { [key: string]: any };
    group: Group;
    Users: TokUser[];
    Token: string;
    SearchActive: boolean;
    InvitedUsers: string[];
    LoadMoreActive: boolean;
}

interface Props {
    group: Group;
}
class InviteUsers extends React.Component<Props, States> {
    searchInput: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            LoadActive: false,
            showInviteUsers: false,
            query: [],
            group: props.group,
            Users: [],
            Token: "",
            SearchActive: false,
            InvitedUsers: new Array,
            LoadMoreActive: false
        };

        this.setInviteUsers = this.setInviteUsers.bind(this);
        this.closeInviteUsers = this.closeInviteUsers.bind(this);
        this.searchInput = createRef();

        this.searchUsers = this.searchUsers.bind(this);
        this.loadMoreScroll = this.loadMoreScroll.bind(this);

        this.sendInviteUser = this.sendInviteUser.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.getUserToken();
        this.getData();
    }
    async getUserToken() {
        await getToken();
    }
    async getData() {
        var { query, group } = this.state;
        query["group_id"] = group["id"] ? group["id"] : "";
        query["status"] = 1;
        var results = await getClassGroupRequests(query);
    }
    setInviteUsers() {
        this.setState({
            showInviteUsers: true
        })
    }
    closeInviteUsers() {
        this.setState({
            showInviteUsers: false
        })
    }
    searchUsers() {
        const search = this.searchInput.current.querySelector("input").value;
        var query = {
            display_name: search
        };
        this.setState({
            LoadActive: true,
            SearchActive: true
        })
        getUsers(query, "", "user").then(res => {
            if(res){
                if (res["data"]["status_code"] == 200) {
                    this.setState({
                        Users: res["data"]["result"],
                        //Token: res["data"]["continuationToken"]
                    })
                }
            }
        }).catch(err => {
        }).finally(() => {
            this.setState({
                LoadActive: false
            })
        })
    }

    loadMoreSearch(token) {
        var { Users } = this.state;
        var query = {
            token: token
        };
        getUsers(query, "", "user").then(res => {
            if(res){
                if (res["data"]["status_code"] == 200) {
                    this.setState({
                        Users: Users.concat(res["data"]["result"]),
                        //Token: res["data"]["continuationToken"]
                    })
                }
            }
        }).catch(err => {
        }).finally(() => {
            this.setState({
                LoadMoreActive: false
            })
        })
    }
    loadMoreScroll(e) {
        const { LoadMoreActive, Token, SearchActive } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !LoadMoreActive && Token) {
            this.setState({
                LoadMoreActive: true
            }, () => {
                if (SearchActive) {
                    this.loadMoreSearch(Token);
                } else {
                    this.getData();
                }
            });
        }
    }
    sendInviteUser(e) {
        const { group } = this.state;
        const receiverid = e.currentTarget.getAttribute("data-id");
        const localUser = localStorage.getItem("userdata");
        const localUserJson = localUser ? JSON.parse(localUser) : [];

        let request = {
            label: "classgrouprequest",
            receiver_label: "user",
            receiver_id: receiverid,
            sender_id: localUserJson["id"] ? localUserJson["id"] : "",
            sender_displayname: localUserJson["display_name"] ? localUserJson["display_name"] : "",
            sender_image: localUserJson["user_photo"] ? localUserJson["user_photo"] : "",
            sender_label: "user",
            group_id: group["id"] ? group["id"] : "",
            group_pk: group["pk"] ? group["pk"] : "",
            name: group["name"] ? group["name"] : "",
            message: group["name"] ? "You are invited to join group " + group["name"] : "",
            remarks: "Invited",
            status: 1,
            members: group["members"] ? Number(group["members"]) : 0,
            group_kind: group["group_kind"] ? group["group_kind"] : "",
            description: group["description"] ? group["description"] : "",
            school: group["school"] ? group["school"] : "",
            image: group["image"] ? group["image"] : "",
            group_owner_id: group["user_id"] ? group["user_id"] : "",
            user_id: localUserJson["id"],
        }
        MySwal.fire({
            title: <p>Sending Invite to User</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        sendRequestGroup(request).then(res => {
            if (res["status"] == 200) {
                MySwal.update({
                    title: <p>Invite send</p>,
                    icon: "success"
                });
                const { InvitedUsers } = this.state;
                let tempInvitedUsers = InvitedUsers;
                tempInvitedUsers.push(receiverid);
                this.setState({
                    InvitedUsers: tempInvitedUsers
                })
            } else {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    render() {
        var { LoadActive, showInviteUsers, Users, LoadMoreActive, InvitedUsers } = this.state;

        return (
            <>
                <Button variant="primary" style={{ margin: "2.5px 0px" }} onClick={this.setInviteUsers}>Invite Users</Button>
                <Modal show={showInviteUsers} onHide={this.closeInviteUsers} style={{ zIndex: "1306" }}>
                    <Modal.Header>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <Typography fontWeight={"bold"} marginRight={"auto"}>Invite Users</Typography>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeInviteUsers} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ marginTop: "10px", marginBottom: "15px" }}>
                            <Col style={{ position: "relative", paddingRight: "unset" }}>
                                <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px", width: "100%" }} label={`Search`} />
                            </Col>
                            <Col xs={'auto'} style={{ padding: "unset" }}>
                                <Button variant='primary' style={{ height: "100%" }} onClick={this.searchUsers}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                                </Button>
                            </Col>
                            <Col xs={'auto'} style={{ paddingLeft: "unset" }}>
                                <div style={{ width: "40px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                                </div>
                            </Col>
                        </Row>
                        {LoadActive ?
                            <WaitSpinner /> :
                            <Row style={{ margin: "unset" }} className={'invite-user-container'} onScroll={this.loadMoreScroll}>
                                {Users.map((User) => (
                                    <Col xs={12} className={'row notification-item'}>
                                        <div className={'notification-item-avatar'} style={{ backgroundImage: User["user_photo"] ? `url(${User["user_photo"]})` : `url(https://tokketcontent.blob.core.windows.net/tokketweb/default.png)` }}></div>
                                        <Row className={'col'}>
                                            <Col md={true} xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                <Typography className={'line-clamp-2'} style={{ overflowY: "hidden" }} fontWeight={600}>{User["display_name"]}</Typography>
                                            </Col>
                                            <Col md={'auto'} xs={12} className={'flex-center notification-item-btn-container'}>
                                                {!InvitedUsers.includes(User["id"]) ?
                                                    <Button variant={'primary'} onClick={this.sendInviteUser} data-id={User["id"]}>Invite</Button> :
                                                    <Button variant={'primary'} disabled={true}>Invited</Button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                                {LoadMoreActive ?
                                    <WaitSpinner /> : ""
                                }
                            </Row>
                        }

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default InviteUsers;