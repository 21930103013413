import React from 'react'
import '../styles.css'
import './css/PictureContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { getSetUserId, getToken } from '../services/accountServices';
import { createRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes, faBars, faTrashCan, faImage } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '@mui/material';
import Iframe from 'react-iframe';
import Cropper from "react-cropper";
import "./css/cropper.css";
import { idFormat } from '../services/helperTools';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { addTok, deleteTok, updateTok } from '../services/tokServices';
import TextField from '@mui/material/TextField';
import Dropdown from 'react-bootstrap/Dropdown';
import { addClassTok, getToks } from '../services/classServices';
import { Tok } from '../interfaces/TokInterface';
import { Group } from '../interfaces/GroupInterface';
const MySwal = withReactContent(Swal);
interface CustomToggleProps {
    children: React.ReactNode;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleProps>(
    ({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                onClick(e);
            }}
            style={{ cursor: "pointer" }}
        >
            {children}
        </div>
    ),
);

interface States {
    items: Tok[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    PictureView: boolean;
    Picture: Tok;
    CropperShow: boolean;
    UploadModal: boolean;
    Group: Group;
    CaptionText: string;
    UploadModalEditMode: boolean;
    EditPictureTarget: Tok;
    resultData: { [key: string]: any };
    ResultPicture: string;
    CropperSrc: string;
}

interface Props {
    addPicTriggerRef: React.RefObject<any>;
    group: Group;
    query: { [key: string]: any };
}

class PictureContainer extends React.Component<Props, States> {
    loadmoretrigger: React.RefObject<any>;
    addPicTriggerRef: React.RefObject<any>;
    picUpload: React.RefObject<any>;
    cropperRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            PictureView: false,
            Picture: {} as Tok,
            CropperShow: false,
            UploadModal: false,
            Group: props.group,
            CaptionText: "",
            UploadModalEditMode: false,
            EditPictureTarget: {} as Tok,
            resultData: {},
            ResultPicture: "",
            CropperSrc: ""
        };
        this.reload = this.reload.bind(this);
        this.loadmoretrigger = createRef();
        this.loadmore = this.loadmore.bind(this);
        this.viewPicture = this.viewPicture.bind(this);
        this.closeModals = this.closeModals.bind(this);
        this.removePicture = this.removePicture.bind(this);

        this.addPicTriggerRef = props.addPicTriggerRef;
        this.setAddPic = this.setAddPic.bind(this);
        this.handleCropperClose = this.handleCropperClose.bind(this);
        this.cropperRef = createRef();
        this.uploadModalClose = this.uploadModalClose.bind(this);
        this.picUpload = createRef();
        this.uploadPic = this.uploadPic.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.setCaptionText = this.setCaptionText.bind(this);
        this.removeResultPhoto = this.removeResultPhoto.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
        this.editPhoto = this.editPhoto.bind(this);
        this.editPhotoSet = this.editPhotoSet.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    async getData(customQuery) {
        var { query, items, Group } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
        }
        const response = await getToks(query, "pics", Group["id"] ? Group["id"] : "");
        var itemResult = response.data["result"] ? response.data["result"] : [];
        if (customQuery) {
            itemResult = items.concat(itemResult);
        }
        this.setState({
            resultData: response.data,
            continuationToken: response.data["pagination"] ? response.data["pagination"] : "",
            items: itemResult,
            LoadActive: false
        });
    }

    reload() {
        this.setState({
            LoadActive: true,
            items: []
        });
        this.getData({});
    }
    loadmore() {
        const { LoadActive, continuationToken } = this.state;
        if (!LoadActive && continuationToken) {
            var { query } = this.state;
            if (query) {
                query.pagination = continuationToken;
            } else {
                query = { "pagination": continuationToken }
            }
            this.setState({
                LoadActive: true
            });
            this.getData(query)
        }
    }
    viewPicture(pic) {
        const { items } = this.state;
        const id = pic.currentTarget.getAttribute("data-id");
        const pk = pic.currentTarget.getAttribute("data-pk");
        var filterItems = items.filter(item => item["id"] == id && item["pk"] == pk);
        var selectedPic: Tok;
        if (filterItems.length) {
            selectedPic = filterItems[0];
        }
        this.setState({
            PictureView: true,
            Picture: selectedPic
        });
    }
    removePicture(card) {
        var { items } = this.state;
        if (items) {
            for (let x = 0; x < items.length; x++) {
                if (items[x].id == card.id && items[x].pk == card.pk) {
                    items.splice(x, 1);
                    break;
                }
            }
        }
        this.setState({
            items: items,
        });
    }
    closeModals() {
        this.setState({
            PictureView: false,
            Picture: {} as Tok
        });
    }
    setAddPic() {
        this.setState({
            UploadModal: true,
        });
    }
    handleCropperClose() {
        this.setState({
            CropperShow: false,
        });
    }
    uploadModalClose() {
        this.setState({
            UploadModal: false,
            ResultPicture: "",
            UploadModalEditMode: false
        });
    }
    uploadPic(e) {
        const pic = e.currentTarget.files[0];
        this.setState({
            CropperSrc: URL.createObjectURL(pic),
            CropperShow: true
        });
    }

    onCrop() {
        const cropper = this.cropperRef.current?.cropper;
        this.setState({
            ResultPicture: cropper.getCroppedCanvas().toDataURL(),
            CropperShow: false
        });
    }

    uploadPhoto() {
        const { ResultPicture, CaptionText, Group } = this.state;
        const localUser = localStorage.getItem("userdata");
        const localUserJson = JSON.parse(localUser);

        let picture = {
            "image": ResultPicture ? ResultPicture : "",
            "primary_text": CaptionText ? CaptionText : "",
            "secondary_text": localUserJson["display_name"] ? localUserJson["display_name"] : "",
            "tok_group": "Basic",
            "is_detail_based": false,
            "is_mega_tok": false,
            "category": "Image",
            "tok_type": Group["name"] ? Group["name"] : "",
            "group_id": Group["id"] ? Group["id"] : "",
            "user_photo": localUserJson["user_photo"] ? localUserJson["user_photo"] : "",
            "user_id": localUserJson["id"] ? localUserJson["id"] : "",
            "user_display_name": localUserJson["display_name"] ? localUserJson["display_name"] : "",
            "tok_type_id": "toktype-basic-" + idFormat(Group["name"] ? Group["name"] : ""),
            "country": localUserJson["country"] ? localUserJson["country"] : "",
            "state": localUserJson["state"] ? localUserJson["state"] : "",
            "items": "tokpics-toks",
            "label": "tok",
            "tokgroupid": "basic",
            "language": "english"
        }
        MySwal.fire({
            title: <p>Adding Picture</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        addClassTok(picture, Group["id"] ? Group["id"] : "", "pics").then(response => {
            if (response) {
                if (response.status == 200) {
                    if (response.data["result"]) {
                        MySwal.update({
                            title: <p>Picture Added</p>,
                            icon: "success"
                        });
                        var { items } = this.state;
                        items.unshift(response.data["result"]);
                        this.setState({
                            items: items,
                            ResultPicture: "",
                            CaptionText: "",
                            UploadModal: false
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }

                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
            MySwal.hideLoading();
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
            MySwal.hideLoading();
        }).finally(() => {
        });
    }

    setCaptionText(e) {
        var value = e.target.value;
        this.setState({
            CaptionText: value
        });
    }
    removeResultPhoto() {
        this.setState({
            ResultPicture: ""
        });
    }
    deletePicture(e) {
        const { items } = this.state;
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        for (let x = 0; x < items.length; x++) {
            if (items[x]["id"] == id && items[x]["pk"] == pk) {
                MySwal.fire({
                    title: <p>Are you sure want to delete?</p>,
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    icon: "warning",
                    confirmButtonColor: "#c82333",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        MySwal.fire({
                            title: <p>Deleting Picture</p>,
                            showConfirmButton: false,
                            allowOutsideClick: false
                        });
                        MySwal.showLoading()
                        try {
                            deleteTok(id).then(response => {
                                if (response) {
                                    if (response.status == 200) {
                                        MySwal.update({
                                            title: 'Deleted!',
                                            icon: "success"
                                        });
                                        items.splice(x, 1);
                                        this.setState({
                                            items: items
                                        });
                                        this.closeModals();
                                    } else {
                                        MySwal.update({
                                            title: <p>An Error Occured</p>,
                                            icon: "warning"
                                        });
                                    }
                                }
                            }).catch(response => {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }).finally((res) => {
                                MySwal.hideLoading();
                            });
                        } catch (err) {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                            MySwal.hideLoading();
                        }
                    }
                }).catch((result) => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                })
                break;
            }
        }
    }
    editPhoto() {
        var { CaptionText, ResultPicture, EditPictureTarget } = this.state;
        EditPictureTarget["image"] = ResultPicture;
        EditPictureTarget["primary_text"] = CaptionText;
        MySwal.fire({
            title: <p>Updating Picture</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        updateTok(EditPictureTarget).then(response => {
            if (response) {
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Picture updated</p>,
                        icon: "success"
                    });
                    var { items } = this.state;
                    for (let x = 0; x < items.length; x++) {
                        if (items[x]["id"] == EditPictureTarget["id"] && items[x]["pk"] == EditPictureTarget["pk"]) {
                            items[x] = EditPictureTarget;
                            break;
                        }
                    }
                    this.setState({
                        items: items,
                        ResultPicture: "",
                        CaptionText: "",
                        EditPictureTarget: {} as Tok,
                        UploadModalEditMode: false,
                        PictureView: true,
                        Picture: EditPictureTarget,
                        UploadModal: false
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
            MySwal.hideLoading();
        }).catch(response => {

            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
            MySwal.hideLoading();
        }).finally(() => {
        });
    }
    editPhotoSet(e) {
        const { items } = this.state;
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        var pic: Tok;
        var filterItems = items.filter(item => item["id"] == id && item["pk"] == pk);
        if (filterItems.length) {
            pic = filterItems[0];
        }
        this.setState({
            UploadModal: true,
            UploadModalEditMode: true,
            CaptionText: pic["primary_text"] ? pic["primary_text"] : "",
            ResultPicture: pic["image"] ? pic["image"] : "",
            EditPictureTarget: pic,
            PictureView: false
        });
    }
    render() {
        var { UploadModalEditMode, CaptionText, LoadActive, items, continuationToken, PictureView, Picture, CropperShow, CropperSrc, UploadModal, ResultPicture } = this.state;
        let itemExist = false;
        if (items) {
            itemExist = true;
        }
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (!LoadActive && continuationToken.length > 0) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <div>
                <Modal size='lg' show={CropperShow} onHide={this.handleCropperClose} style={{ zIndex: "1208" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleCropperClose} />
                            Cropper
                            <Button variant="success" onClick={this.onCrop}>Crop</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Cropper
                            src={CropperSrc}
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={16 / 9}
                            ref={this.cropperRef}
                        />

                    </Modal.Body>
                </Modal>

                <Modal size='lg' show={UploadModal} onHide={this.uploadModalClose} style={{ zIndex: "1208" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.uploadModalClose} />
                            <span style={{ fontSize: "39px" }}>
                                Tok Pic
                            </span>
                            <Button variant="success" style={{ opacity: ResultPicture ? "" : "0%" }} onClick={UploadModalEditMode ? this.editPhoto : this.uploadPhoto}>{UploadModalEditMode ? "Update" : "Upload"}</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        {ResultPicture ?
                            <div style={{ position: "relative" }}>
                                <div style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px" }} onClick={this.removeResultPhoto}>
                                    <FontAwesomeIcon icon={faTimes} size={'2xl'} color={'crimson'} />
                                </div>
                                <img src={ResultPicture} style={{ width: "100%" }} />
                                <div style={{ marginTop: "15px" }}>
                                    <TextField style={{ width: "100%" }} value={CaptionText} onChange={this.setCaptionText} label="Pic Info: (Optional)" inputProps={{ maxLength: 250 }} />
                                    <p className={'character-count'}>{CaptionText.length}/250</p>
                                </div>
                            </div> :
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", position: "relative" }}>
                                <Button style={{ display: "flex" }} variant='outline-dark' onClick={(e) => { this.picUpload.current.click() }}>
                                    <div style={{ marginRight: "5px" }}>
                                        <FontAwesomeIcon icon={faImage} />
                                    </div>
                                    Select Picture
                                </Button>
                                <input ref={this.picUpload} type="file" onChange={this.uploadPic} hidden />
                            </div>
                        }

                    </Modal.Body>
                </Modal>

                <Modal size='lg' show={PictureView} onHide={this.closeModals} style={{ zIndex: "1206" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <Typography fontWeight={"bold"} marginRight={"auto"} fontSize={"24px"}>View Picture</Typography>

                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeModals} />
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <img src={Picture["image"] ? Picture["image"] : ""} style={{ width: "100%" }} />
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "flex-start" }}>
                        <Row style={{ width: "100%", alignItems: "center" }}>
                            <div style={{ height: "50px", width: "50px", backgroundImage: Picture["user_photo"] ? `url(${Picture["user_photo"]})` : `url("https://tokketcontent.blob.core.windows.net/tokketweb/default.png")`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                            {Picture["user_display_name"] ? <Col xs={'auto'} style={{ fontSize: "24px", fontWeight: "600" }}>{Picture["user_display_name"]}</Col> : ""}
                            <Col xs={'auto'} style={{ marginLeft: "auto" }}>
                                <div className={'trash-delete-btn'} data-id={Picture["id"] ? Picture["id"] : ""} data-pk={Picture["pk"] ? Picture["pk"] : ""} onClick={this.deletePicture}>
                                    <FontAwesomeIcon size={'2x'} icon={faTrashCan} />
                                </div>
                            </Col>
                        </Row>

                    </Modal.Footer>
                </Modal>
                <Row style={{ marginTop: "10px" }}>
                    <Row style={{ padding: "unset", margin: "unset", marginBottom: "15px" }}>

                        {itemExist ?
                            <Row className={'col-auto'}>
                                <div style={{ width: "90px" }}>
                                    <img src="https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg" style={{ width: "100%" }} />
                                </div>
                                <Col>
                                    <div className={'speechbubble-doc speechbubble-doc-purple'}>
                                        {items.length > 0 ?
                                            "Post Pictures here!" :
                                            "There are no pictures yet."
                                        }
                                    </div>
                                </Col>
                            </Row> : ""}
                        <Col xs={'auto'} style={{ margin: !itemExist ? 'auto 0' : 'auto' }}>
                            <Button ref={this.addPicTriggerRef} variant="success" onClick={this.setAddPic} style={{ marginLeft: "auto", fontSize: "24px", display: "flex", alignItems: "center", padding: "15px" }}>
                                <div className={'parrot-write-icon'}></div>
                                <span>Add Pic</span>
                            </Button>
                        </Col>
                    </Row>
                    {items ?
                        <>
                            {items.map((item, index) => (
                                <Col xs={12} sm={6} md={4}>
                                    <div onClick={this.viewPicture} data-id={item["id"]} data-pk={item["pk"]} style={{
                                        height: "300px", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat",
                                        backgroundImage: item["image"] ? `url(${item["image"]})` : ""
                                    }}>
                                    </div>
                                </Col>
                            ))}
                        </> : ""
                    }
                    {LoadActive ? <WaitSpinner /> : ""}
                </Row>
            </div>

        );
    }
}

export default PictureContainer;