import React, { createRef } from 'react'
import '../styles.css'
import './css/TokHandleMenu.css';
import './css/TokHandle.css';
import { getUserTokhandle, updateUser } from '../services/accountServices';
import { Row, Col } from 'react-bootstrap';
import WaitSpinner from './WaitSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCheck, faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import Cropper from "react-cropper";
import "./css/cropper.css";
import Radio from '@mui/material/Radio';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { patchHandle, updateHandle } from '../services/classServices';
import { getToken } from '../services/accountServices';
import arrayShuffle from 'array-shuffle';
import { cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import ClassTokIcon from './ClassTokIcon';
import { TokHandle } from '../interfaces/TokhandleInterface';
import { TokUser } from '../interfaces/TokUserInterface';

const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
const MySwal = withReactContent(Swal);
const shuffleColors = arrayShuffle(colors);
var colorCounter = -1;
const positionOptions = [
    {
        value: 1,
        display_text: 'Display Name on First Row, Tok Handle on 2nd row',
        decoration: "A"
    },
    {
        value: 2,
        display_text: 'Tok Handle only on 1st row',
        decoration: "B"
    }
]

interface States {
    userTokhandles: TokHandle[];
    userInfo: TokUser;
    tokhandleEdit: TokHandle;
    tokhandleEditShow: boolean;
    tokhandleEditGraphicShow: boolean;
    tokhandleEditGraphicActive: string;
    tokhandleEditPositionShow: boolean;
    tokhandleEditPriceShow: boolean;
    cropperShow: boolean;
    cropperSrc: string;
    preloadHandles: TokHandle[];
    token: string;
    defaultTokhandleEdit: TokHandle;
}

interface Props {
    handles: TokHandle[];
    tokHandleMenuToken: string;
    setTokHandleMenuData: (data: {
        handles: TokHandle[]
    }) => void;
    updateHandle: (data: TokHandle) => void;
}

class TokHandleMenu extends React.Component<Props, States> {
    cropperRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            defaultTokhandleEdit: {} as TokHandle,
            userTokhandles: [],
            userInfo: {} as TokUser,
            tokhandleEdit: {} as TokHandle,
            tokhandleEditShow: false,
            tokhandleEditGraphicShow: false,
            tokhandleEditGraphicActive: "",
            tokhandleEditPositionShow: false,
            tokhandleEditPriceShow: false,
            cropperShow: false,
            cropperSrc: null,
            preloadHandles: props.handles ? props.handles : new Array,
            token: props.tokHandleMenuToken ? props.tokHandleMenuToken : ""
        };
        this.cropperRef = createRef();
        this.SetTokHandleSettings = this.SetTokHandleSettings.bind(this);
        this.closeModals = this.closeModals.bind(this);
        this.returnPostionClass = this.returnPostionClass.bind(this);
        this.onImageSet = this.onImageSet.bind(this);
        this.saveTokHandle = this.saveTokHandle.bind(this);
        this.setColor = this.setColor.bind(this);
        this.setTokHandle = this.setTokHandle.bind(this);
        this.setPrice = this.setPrice.bind(this);
        this.setTokhandlePosition = this.setTokhandlePosition.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.handleCropperClose = this.handleCropperClose.bind(this);

        this.setTokhandleEditGraphicShow = this.setTokhandleEditGraphicShow.bind(this);
        this.setTokhandleEditGraphicHide = this.setTokhandleEditGraphicHide.bind(this);
        this.setTokhandleEditPositionShow = this.setTokhandleEditPositionShow.bind(this);
        this.setTokhandleEditPositionHide = this.setTokhandleEditPositionHide.bind(this);
        this.setTokhandleEditPriceShow = this.setTokhandleEditPriceShow.bind(this);
        this.setTokhandleEditPriceHide = this.setTokhandleEditPriceHide.bind(this);
        this.setTokhandleEditGraphicActive = this.setTokhandleEditGraphicActive.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        const { preloadHandles } = this.state;
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                this.setState({
                    userInfo: localUserJson,
                });
            }
            if (localUserJson["id"] && preloadHandles.length == 0) {
                getUserTokhandle(localUserJson.id).then(response => {
                    if(response){
                        if (response.data.status_code == 200 && response.data.result.length > 0) {
                            this.setState({
                                userTokhandles: response.data.result,
                            }, () => {
                                try {
                                    let data = {
                                        handles: response.data.result,
                                        //add token when loadmore is implemented
                                    }
                                    this.props.setTokHandleMenuData(data);
                                } catch (err) { }
                            });
                        }
                    }
                });
            } else {
                this.setState({
                    userTokhandles: preloadHandles
                });
            }
        }
        this.fetchToken();
    }

    async fetchToken() {
        await getToken();
    }
    SetTokHandleSettings(e) {
        const { userTokhandles } = this.state;
        let editTarget = e.currentTarget.getAttribute("data-id");
        for (let x = 0; x < userTokhandles.length; x++) {
            if (userTokhandles[x].id == editTarget) {
                this.setState({
                    tokhandleEdit: userTokhandles[x],
                    defaultTokhandleEdit: cloneDeep(userTokhandles[x]),
                    tokhandleEditShow: true,
                });
                break;
            }
        }
    }

    closeModals() {
        this.setState({
            tokhandleEdit: {} as TokHandle,
            tokhandleEditShow: false,
            tokhandleEditGraphicShow: false,
            tokhandleEditPositionShow: false,
            tokhandleEditPriceShow: false,
        });
    }

    returnPostionClass(num) {
        switch (num) {
            case 1:
                return "position-option-a";
            case 2:
                return "position-option-b";
            case 3:
                return "position-option-c";
            case 4:
                return "position-option-d";
            default:
                return "position-option-none";
        }
    }

    setColor(btn) {
        const { tokhandleEdit } = this.state;
        var tempTokhandle = tokhandleEdit;
        const color = btn.currentTarget.getAttribute("data-color");
        if (tempTokhandle) {
            tempTokhandle["color"] = color;
            tempTokhandle["image"] = "";
        }
        this.setState({
            tokhandleEdit: tempTokhandle
        })
    }

    saveTokHandle(btn) {
        const { tokhandleEdit, defaultTokhandleEdit } = this.state;
        MySwal.fire({
            title: <p>Updating Tok Handle</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        let patchObject = diff(defaultTokhandleEdit, tokhandleEdit);
        let patchObjectKeys = Object.keys(patchObject);
        for (let x = 0; x < patchObjectKeys.length; x++) {
            patchObject[patchObjectKeys[x]] = cloneDeep(tokhandleEdit[patchObjectKeys[x]]);
        }
        patchHandle(patchObject, defaultTokhandleEdit["id"], defaultTokhandleEdit["pk"]).then(response => {
            if(response){
                if (response.data["result"]) {
                    MySwal.update({
                        title: <p>Success</p>,
                        icon: "success"
                    });
                    this.props.updateHandle(tokhandleEdit);
                    this.setState({
                        defaultTokhandleEdit: tokhandleEdit
                    })
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        });
    }

    onImageSet(e) {
        this.setState({
            cropperSrc: URL.createObjectURL(e.currentTarget.files[0]),
            cropperShow: true
        })
    }

    handleCropperClose(e) {
        this.setState({
            cropperShow: false
        })
    }

    onCrop(e) {
        const { tokhandleEdit } = this.state;
        const cropper = this.cropperRef.current?.cropper;
        var tempTokhandle = tokhandleEdit;
        tempTokhandle["image"] = cropper.getCroppedCanvas().toDataURL();
        tempTokhandle["color"] = "";
        this.setState({
            tokhandleEdit: tempTokhandle,
            cropperShow: false
        })
    }

    setTokhandlePosition(btn) {
        const { tokhandleEdit } = this.state;
        const position = Number(btn.currentTarget.value);
        var tempTokhandle = tokhandleEdit;
        tempTokhandle["position"] = position;
        this.setState({
            tokhandleEdit: tempTokhandle
        })
    }

    setPrice(btn) {
        const { tokhandleEdit } = this.state;
        const price = btn.currentTarget.value;
        var tempTokhandle = tokhandleEdit;
        tempTokhandle["price_usd"] = price;
        this.setState({
            tokhandleEdit: tempTokhandle
        })
    };

    setTokHandle(e) {
        var { userTokhandles, userInfo } = this.state;
        const tokhandleid = e.currentTarget.getAttribute("data-id");
        let tokhandle: TokHandle;
        let tempUserInfo = userInfo;
        for (let x = 0; x < userTokhandles.length; x++) {
            if (userTokhandles[x]["id"] == tokhandleid) {
                tokhandle = userTokhandles[x];
                break;
            }
        }
        tempUserInfo["current_handle"] = tokhandle["id"];
        tempUserInfo["handle_color"] = tokhandle["color"] ? tokhandle["color"] : "";
        tempUserInfo["handle_image"] = tokhandle["image"] ? tokhandle["image"] : "";
        tempUserInfo["handle_position"] = tokhandle["position"] ? tokhandle["position"] : 0;

        MySwal.fire({
            title: <p>Setting Tokhandle.</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        updateUser(tempUserInfo).then(res => {
            if (res["status"] == 200) {
                MySwal.update({
                    title: <p>Success</p>,
                    icon: "success"
                });
                localStorage.setItem('userdata', JSON.stringify(tempUserInfo));
                window.location.reload();
                this.setState({
                    userInfo: tempUserInfo
                })
            } else {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        });
    }

    setTokhandleEditGraphicShow(e) {
        this.setState({
            tokhandleEditGraphicShow: true
        })
    }

    setTokhandleEditGraphicHide(e) {
        this.setState({
            tokhandleEditGraphicShow: false
        })
    }

    setTokhandleEditPositionShow(e) {
        this.setState({
            tokhandleEditPositionShow: true,
        })
    }

    setTokhandleEditPositionHide(e) {
        this.setState({
            tokhandleEditPositionShow: false,
        })
    }

    setTokhandleEditPriceShow(e) {
        this.setState({
            tokhandleEditPriceShow: true,
        })
    }

    setTokhandleEditPriceHide(e) {
        this.setState({
            tokhandleEditPriceShow: false,
        })
    }

    setTokhandleEditGraphicActive(btn) {
        const activeEdit = btn.currentTarget.getAttribute("data-mode");
        this.setState({
            tokhandleEditGraphicActive: activeEdit
        });
    }
    render() {
        const { userTokhandles, userInfo, tokhandleEdit, tokhandleEditShow, tokhandleEditGraphicShow, tokhandleEditGraphicActive, tokhandleEditPositionShow, tokhandleEditPriceShow, cropperShow, cropperSrc } = this.state;

        return (
            <div>
                {tokhandleEdit ?
                    <>
                        <Modal size='lg' show={tokhandleEditShow} onHide={this.closeModals} style={{ zIndex: "1206" }}>
                            <Modal.Header className={'text-color-white dark-background-2'}>
                                <div className='nameplate-div nameplate-title'>
                                    Name Plate
                                </div>
                            </Modal.Header>
                            <Modal.Body className={'text-color-white dark-background-2'}>
                                <div className={`nameplate-div position-showcase ${this.returnPostionClass(tokhandleEdit.position)}`} style={{ background: tokhandleEdit["color"] ? "#" + tokhandleEdit.color : tokhandleEdit["image"] ? `url(${tokhandleEdit.image})` : "" }}>
                                    <div className={'position-displayname'}>{userInfo.display_name}c4</div>
                                    <div className={'position-tokhandle'}>{tokhandleEdit.id}</div>
                                </div>
                                <div style={{ display: "grid", gridGap: "5px", marginTop: "10px", maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
                                    <Button variant='primary' onClick={this.setTokhandleEditGraphicShow}>Graphic</Button>
                                    <Button variant='primary' onClick={this.setTokhandleEditPositionShow}>Position</Button>
                                    <Button variant='primary' onClick={this.setTokhandleEditPriceShow}>Set Price</Button>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' onClick={this.closeModals}>Close</Button>
                                <Button variant='success' onClick={this.saveTokHandle}>Apply to Tok Handle</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal size='lg' show={tokhandleEditGraphicShow} style={{ zIndex: "1207" }}>
                            <Modal.Header className={'text-color-white dark-background-2'}>
                                <div className='nameplate-div nameplate-title'>
                                    Graphic
                                </div>
                            </Modal.Header>
                            <Modal.Body className={'text-color-white dark-background-2'}>
                                <div className={`nameplate-div position-showcase ${this.returnPostionClass(tokhandleEdit.position)}`} style={{ background: tokhandleEdit["color"] ? "#" + tokhandleEdit.color : tokhandleEdit["image"] ? `url(${tokhandleEdit.image})` : "" }}>
                                    <div className={'position-displayname'}>{userInfo.display_name}</div>
                                    <div className={'position-tokhandle'}>{tokhandleEdit.id}</div>
                                </div>
                                {tokhandleEditGraphicActive == "" ?
                                    <div style={{ display: "grid", gridGap: "5px", marginTop: "10px", maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
                                        <Button variant='primary' data-mode={"color"} onClick={this.setTokhandleEditGraphicActive}>Change Color</Button>
                                        <Button variant='primary' data-mode={"graphic"} onClick={this.setTokhandleEditGraphicActive}>Change Graphic</Button>
                                    </div>
                                    : ""
                                }
                                {tokhandleEditGraphicActive == "color" ?
                                    <Row style={{ marginTop: "10px" }}>
                                        {shuffleColors.map((color, index) => (
                                            <Col onClick={this.setColor} data-color={color} xs={3} className={tokhandleEdit["color"] == color ? 'color-class-tok-picker-item active' : 'color-class-tok-picker-item'} style={{ backgroundColor: `#${color}` }}>
                                                <div className={'color-tok-picker-active-indicator'}>
                                                    <FontAwesomeIcon size={'2xl'} icon={faCheck} />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                    : ""
                                }
                                {tokhandleEditGraphicActive == "graphic" ?
                                    <p style={{ margin: "auto", marginTop: "10px", width: "max-content", cursor: "pointer", fontSize: "24px" }} onClick={(e) => e.currentTarget.querySelector("input").click()}>
                                        <FontAwesomeIcon icon={faImage} color='green' />
                                        <b> - Add your own graphic</b>
                                        <input type="file" accept="image/*" onChange={this.onImageSet} hidden />
                                    </p>
                                    : ""
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                {tokhandleEditGraphicActive != "" ?
                                    <Button variant='secondary' style={{ marginRight: "auto" }} onClick={this.setTokhandleEditGraphicActive} data-mode={""}>Back</Button>
                                    : ""
                                }

                                <Button variant='danger' onClick={this.setTokhandleEditGraphicHide}>Close</Button>
                                <Button variant='success' onClick={this.saveTokHandle}>Apply to Tok Handle</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal size='lg' show={tokhandleEditPriceShow} style={{ zIndex: "1207" }}>
                            <Modal.Header className={'text-color-white dark-background-2'}>
                                <div className='nameplate-div nameplate-title'>
                                    Price
                                </div>
                            </Modal.Header>
                            <Modal.Body className={'text-color-white dark-background-2'}>
                                <TextField style={{ width: "100%" }} type="number" value={tokhandleEdit["price_usd"]} onChange={this.setPrice} label="Price" inputProps={{ maxLength: 50 }} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' onClick={this.setTokhandleEditPriceHide}>Close</Button>
                                <Button variant='success' onClick={this.saveTokHandle}>Apply to Tok Handle</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal size='lg' show={tokhandleEditPositionShow} style={{ zIndex: "1207" }}>
                            <Modal.Header className={'text-color-white dark-background-2'}>
                                <div className='nameplate-div nameplate-title'>
                                    Position
                                </div>
                            </Modal.Header>
                            <Modal.Body className={'text-color-white dark-background-2'}>
                                {positionOptions.map((positionOption, index) => (
                                    <Row style={{ marginBottom: "5px" }}>
                                        <Col xs={'auto'}>
                                            <Radio
                                                checked={tokhandleEdit.position === positionOption.value}
                                                onChange={this.setTokhandlePosition}
                                                value={positionOption.value}
                                                name="tokhandle-position"
                                            />
                                        </Col>
                                        <Col xs={4}><b>Option {positionOption.decoration}:</b> {positionOption.display_text}</Col>
                                        <Col className={`nameplate-div position-showcase ${this.returnPostionClass(positionOption.value)}`} style={{ background: tokhandleEdit["color"] ? "#" + tokhandleEdit.color : tokhandleEdit["image"] ? `url(${tokhandleEdit.image})` : "" }}>
                                            <div className={'position-displayname'}>{userInfo.display_name}</div>
                                            <div className={'position-tokhandle'}>{tokhandleEdit.id}</div>
                                        </Col>
                                    </Row>
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='danger' onClick={this.setTokhandleEditPositionHide}>Close</Button>
                                <Button variant='success' onClick={this.saveTokHandle}>Apply to Tok Handle</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal size='lg' show={cropperShow} style={{ zIndex: "1208" }}>
                            <Modal.Header>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.handleCropperClose} />
                                    Cropper
                                    <Button variant="success" onClick={this.onCrop}>Crop</Button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Cropper
                                    src={cropperSrc}
                                    style={{ height: 400, width: "100%" }}
                                    initialAspectRatio={16 / 9}
                                    ref={this.cropperRef}
                                />

                            </Modal.Body>
                        </Modal>
                    </> : ""
                }
                <div className={'tokhandle-topbar'}>
                    Current Tok Handle: {userInfo["current_handle"] ? userInfo.current_handle : ""}
                </div>
                {userTokhandles.length ?
                    <div style={{ marginTop: "10px" }}>
                        {userTokhandles.map((tokhandle, index) => (
                            <Row>
                                <Col onClick={this.setTokHandle} data-id={tokhandle["id"]} className={userInfo["current_handle"] ? userInfo.current_handle == tokhandle.id ? 'tokhandle-item row active' : 'tokhandle-item row' : 'tokhandle-item row'} style={{ background: tokhandle["color"] ? "#" + tokhandle.color : tokhandle["image"] ? `url(${tokhandle.image})` : "", color: tokhandle["color"] ? "white" : tokhandle["image"] ? "white" : "" }}>
                                    <div className={'star-btn'}>
                                        <FontAwesomeIcon icon={faStar} size='2xl' />
                                        <div className={'msg'}>Display</div>
                                    </div>
                                    <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{tokhandle.id}</Col>
                                </Col>
                                <Col data-id={tokhandle.id} onClick={this.SetTokHandleSettings} style={{ display: "flex", alignItems: "center", cursor: "pointer" }} xs={'auto'}>
                                    <ClassTokIcon icon={"tokhandles"} color={"black"} />
                                </Col>
                            </Row>
                        ))}
                    </div> :
                    <WaitSpinner />
                }
            </div>
        );
    }
}

export default TokHandleMenu;