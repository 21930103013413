import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import './../App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { getGroup, sendRequestGroup } from '../services/classServices';
import { getSetUserId, getToken } from '../services/accountServices';
import WaitSpinner from './WaitSpinner';
import ClassToks from '../pages/ClassToks';
import TokChannel from '../pages/TokChannel';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Group } from '../interfaces/GroupInterface';
const MySwal = withReactContent(Swal);
interface Props {
    id: string;
    pk: string;
    group: Group;
}

interface States {
    item: Group;
    id: string;
    pk: string;
    DataisLoaded: boolean;
}
class ClassGroupRender extends React.Component<Props, States> {

    // Constructor
    constructor(props) {
        super(props);
        const id = props.id;
        const pk = props.pk;
        var preloadedgroup = props.group;
        this.state = {
            item: preloadedgroup ? preloadedgroup : null,
            id: id,
            pk: pk,
            DataisLoaded: preloadedgroup ? true : false
        };
    }

    componentDidMount() {
        const { DataisLoaded } = this.state;
        if (!DataisLoaded) {
            this.getData();
        }
    }

    async getData() {
        const { id, pk } = this.state;
        await getToken();
        const response = await getGroup(id, pk);
        var userid = await getSetUserId();
        const profile = localStorage.getItem("profile");
        if (profile) {
            const localProfileJson = JSON.parse(profile);
            userid = localProfileJson["id"] ? localProfileJson["id"] : userid;
        }
        if (response) {
            if (response.status == 200) {
                let group = response.data.result;
                if (group["ismember"] || (userid ? group["pk"].includes(userid) : false) || group["user_id"] ? group["user_id"] == userid : false) {
                    this.setState({
                        DataisLoaded: true,
                        item: group
                    });
                } else {
                    MySwal.fire({
                        title: "Not a member",
                        showCancelButton: true,
                        confirmButtonText: "Request to Join",
                        icon: "info"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const request = {
                                label: "classgrouprequest",
                                receiver_id: group["user_id"] ? group["user_id"] : "",
                                sender_id: userid,
                                name: group["name"] ? group["name"] : "",
                                status: 0,
                                remarks: "Pending",
                                message: "Request to join group.",
                                group_id: group["id"] ? group["id"] : "",
                                group_pk: group["pk"] ? group["pk"] : "",
                                members: group["members"] ? Number(group["members"]) : 0,
                                group_kind: group["group_kind"] ? group["group_kind"] : "",
                                description: group["description"] ? group["description"] : "",
                                school: group["school"] ? group["school"] : "",
                                image: group["image"] ? group["image"] : "",
                                group_owner_id: group["user_id"] ? group["user_id"] : "",
                            }
                            MySwal.fire({
                                title: <p>Sending Request</p>,
                                allowOutsideClick: false,
                            });
                            MySwal.showLoading();
                            sendRequestGroup(request).then(response => {
                                if(response){
                                    if (response["status"] == 200) {
                                        MySwal.update({
                                            title: 'Request sent.',
                                            //message: "Redirecting to home page in 5 seconds.",
                                            icon: "success"
                                        });
                                    } else {
                                        MySwal.update({
                                            title: <p>An Error Occured</p>,
                                            //message: "Redirecting to home page in 5 seconds.",
                                            icon: "warning"
                                        });
                                    }
                                }
                            }).catch((result) => {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    //message: "Redirecting to home page in 5 seconds.",
                                    icon: "warning"
                                });
                            }).finally(() => {
                                MySwal.hideLoading();
                                setTimeout(function () {
                                    window.location.href = "/";
                                }, 5000);
                            })
                        } else {
                            if (result.isConfirmed) {
                                window.location.href = "/";
                            }
                        }
                    });
                }
            }
        }
    }

    render() {
        const { DataisLoaded, item } = this.state;
        if (!DataisLoaded) return (
            <React.Fragment>
                <CssBaseline />
                <Navbar />
                <main style={{ marginLeft: '270px' }}>
                    {/* Hero unit */}
                    <Box sx={{
                        backgroundColor: (theme) => theme.palette.background.paper,
                        padding: (theme) => theme.spacing(8, 0, 6),
                    }}>
                    </Box>
                    <WaitSpinner />
                </main>
                {/* Footer */}
                <Footer />
                {/* End footer */}
            </React.Fragment>
        )
        if (item["pk"].startsWith("channelgroups")) return <TokChannel group={item} />
        else return <ClassToks group={item} />
    }
}
export default ClassGroupRender; 