import React, { useState } from 'react'
import '../styles.css'
import './css/TokCards.css';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import arrayShuffle from 'array-shuffle';
import ReactCardFlip from 'react-card-flip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const TokCard = (props) => {
    const [item, SetItem] = useState(()=>{
        return props.tok;
    });
    const [isFlipped, setIsFlipped] = useState(item["isFlip"] ? item["isFlip"] : false);
    const flipCard = (event) => {
        setIsFlipped(!isFlipped);
    }
    const setStarCard = (e) => {
        e.stopPropagation();
        let tempItem = item;
        if(tempItem["star"]){
            tempItem["star"] = false;
        } else {
            tempItem["star"] = true;
        }
        SetItem(tempItem);
        props.setStarCard(tempItem);
    }
    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <div className={item["star"]? 'tok-card star-card' : 'tok-card'} onClick={flipCard}>
                <div className={'tok-card-front'}>
                    {item["primary_text"] ? item["primary_text"] : ""}
                </div>
                <div className={'tok-card-star'} onClick={setStarCard}><FontAwesomeIcon icon={faStar} /></div>
                <h6 className={'tok-card-bottom'}>Click to flip</h6>
            </div>
            <div className={item["star"]? 'tok-card star-card' : 'tok-card'} onClick={flipCard}>
                {item["tok_group"].toLowerCase() == "detail" || item["tok_group"].toLowerCase() == "detailed" ?
                    <div className={'tok-card-front'}>
                        <List className={'tok-card-table'} dense={true}>
                            {item["details"].map(dtl => {
                                if (dtl == null) return '';
                                if (dtl.length <= 0) return '';

                                return (
                                    <ListItem sx={{ padding: "unset", display: 'list-item', flexShrink: 0 }}>
                                        <div className={'elipsis-one-line-parent'} style={{ fontSize: "30px" }}>
                                            {dtl}
                                        </div>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div> :
                    <div className={'tok-card-front'}>
                        {item["secondary_text"] ? item["secondary_text"] : ""}
                    </div>
                }
                <div className={'tok-card-star'} onClick={setStarCard}><FontAwesomeIcon icon={faStar} /></div>
                <h6 className={'tok-card-bottom'}>Click to flip</h6>
            </div>
        </ReactCardFlip>
    )
}

export default TokCard;