import React from 'react'
import Typography from "@mui/material/Typography";
import '../styles.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FacebookShareButton } from 'react-share';
import { addReaction } from '../services/reactionServices';
import { getSetUserId, getToken } from '../services/accountServices';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { faAngleRight, faRefresh, faSortAmountDown, faTimes, fas, faCheckCircle, faCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addClassToksToSet, getToks, removeToksFromSet, getFilterOptions } from '../services/classServices';
import WaitSpinner from './WaitSpinner';
import './css/AddExistingClassTok.css'
import { Set } from '../interfaces/SetInterface';
import { Tok } from '../interfaces/TokInterface';

const MySwal = withReactContent(Swal);

interface FilterItemQuery {
    publicfeed: boolean;
    feedtype: number;
    FilterBy: number;
    displayText: string;
    value: string;
    FilterItems: any[];
}
interface SortQuery  {
    orderby: string;
    descending: boolean;
} 
interface States {
    FilterByModalShow: boolean;
    filterResults: FilterItemQuery[];
    FilterLoaderActive: boolean;
    FilterByModalActive: string;
    LoadActive: boolean;
    show: boolean;
    Set: Set;
    items: Tok[];
    Token: string;
    SelectedItems: Tok[];
    LoadMoreActive: boolean;
    level1: string;
    level2: string;
    level3: string;
    initialize: boolean;
    Toks: Tok[];
    filterOptions: {
        displayText: string;
        value: string;
        options: {
            displayText: string;
            value: string;
        }[];
    }[];
    ShowFilters: boolean;
    FilterQuery: FilterItemQuery;
    sortOptions: {
        displayText: string;
        value: string;
    }[];
    ShowSort: boolean;
    SortQuery: SortQuery;
}

interface Props {
    updatedSet: any;
    set: any;
    toks: any;
    token: any;
    removeToksToSetTrigger: React.RefObject<any>;
}

class RemoveToksToSet extends React.Component<Props, States> {
    removeToksToSetTrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            FilterByModalShow: false,
            filterResults: [] as FilterItemQuery[],
            FilterLoaderActive: false,
            FilterByModalActive: "",
            LoadActive: false,
            show: props.addToktoSetShow,
            Set: props.set,
            items: [],
            Token: props.token,
            SelectedItems: [],
            LoadMoreActive: false,
            level1: "",
            level2: "",
            level3: "",
            initialize: false,
            Toks: props.toks,
            filterOptions: [
                {
                    displayText: "Filter By", value: "FilterBy", options: [
                        { displayText: "Class", value: "getClasses" },
                        { displayText: "Category", value: "getCategories" },
                        { displayText: "Type", value: "getType" }
                    ]
                },
            ],
            ShowFilters: false,
            FilterQuery: {
                "publicfeed": true,
                "feedtype": 0,
                "FilterBy": 0,
                "FilterItems": []
            } as FilterItemQuery,
            sortOptions: [
                {
                    displayText: "Sort by Reference ID/#", value: "reference_id"
                },
                {
                    displayText: "Sort by Title", value: "primary_text"
                },
            ],
            ShowSort: false,
            SortQuery: {} as SortQuery
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.removeToksToSetTrigger = props.removeToksToSetTrigger;

        this.removeToktoTempSet = this.removeToktoTempSet.bind(this);

        this.selectAllItems = this.selectAllItems.bind(this);
        this.unselectAllItems = this.unselectAllItems.bind(this);

        this.loadMoreScroll = this.loadMoreScroll.bind(this);

        this.removeToktoSetPost = this.removeToktoSetPost.bind(this);

        this.setFilterOptions = this.setFilterOptions.bind(this);
        this.closeFilterByModal = this.closeFilterByModal.bind(this);
        this.setFilterByItems = this.setFilterByItems.bind(this);
        this.applyFilterByItems = this.applyFilterByItems.bind(this);
        this.showFilterByMenu = this.showFilterByMenu.bind(this);
        this.refresh = this.refresh.bind(this);

        this.showHideSort = this.showHideSort.bind(this);
        this.setSortOptions = this.setSortOptions.bind(this);
    }

    async getData(query = {}) {
        const { Set, items, FilterQuery, SortQuery } = this.state;

        if (Set["id"]) {
            query["pkbase"] = Set["id"] + "-classtoks";
            query["publicfeed"] = false
        }
        if (FilterQuery["publicfeed"]) {
            query["publicfeed"] = FilterQuery["publicfeed"];
        }
        if (FilterQuery["FilterBy"] > 0 && FilterQuery["FilterItems"].length > 0) {
            query["FilterBy"] = FilterQuery["FilterBy"];
            query["FilterItems"] = FilterQuery["FilterItems"];
        }
        if (SortQuery["orderby"]) {
            query["orderby"] = SortQuery["orderby"];
            query["descending"] = SortQuery["descending"];
        }
        var result = await getToks(query, "", "");
        let token = result["data"]["resource"]["continuationToken"];
        var newItemList = new Array;
        if (items) {
            newItemList = items;
        }
        if (result["data"]["resource"]["results"]) {
            newItemList = newItemList.concat(result["data"]["resource"]["results"]);
        }

        this.setState({
            LoadActive: false,
            LoadMoreActive: false,
            items: newItemList,
            Token: token,
            initialize: false
        });
    }

    showModal(e) {
        const { Set, Toks } = this.state;
        try {
            let setString = e.currentTarget.getAttribute("data-set");
            let set = setString;
            let tokString = e.currentTarget.getAttribute("data-toks");
            let tokToken = e.currentTarget.getAttribute("data-token");
            let toks = tokString ? JSON.parse(tokString) : {};
            if (set) {
                if (set["id"] != Set["id"] || set["pk"] != Set["pk"]) {
                    this.setState({
                        show: true,
                        Set: set,
                        LoadActive: true,
                        LoadMoreActive: false,
                        items: [],
                    }, () => {
                        this.getData();
                    });
                } else {
                    this.setState({
                        show: true
                    });
                }
            } else {
                this.setState({
                    show: true,
                    LoadActive: true,
                    LoadMoreActive: false,
                    items: []
                }, () => {
                    if (Object.keys(toks).length) {
                        this.setState({
                            items: toks,
                            Token: tokToken ? tokToken : "",
                            LoadActive: false,
                        });
                    } else {
                        this.getData();
                    }
                });
            }
        } catch (err) {
            this.setState({
                show: true,
                LoadActive: true,
                LoadMoreActive: false,
                items: [],
                SelectedItems: []
            }, () => {
                this.getData();
            })
        }
    }

    hideModal() {
        this.setState({
            show: false,
            SelectedItems: [],
            LoadMoreActive: false,
            LoadActive: true,
            Token: "",
            items: []
        });
    }
    removeToktoTempSet(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        let removeTok = true;
        var { items, SelectedItems } = this.state;
        for (let x = 0; x < SelectedItems.length; x++) {
            if (SelectedItems[x]["id"] == id && SelectedItems[x]["pk"] == pk) {
                SelectedItems.splice(x, 1);
                removeTok = false;
                break;
            }
        }

        if (removeTok) {
            for (let x = 0; x < items.length; x++) {
                if (items[x]["id"] == id && items[x]["pk"] == pk) {
                    SelectedItems.push(items[x]);
                    break;
                }
            }
        }
        this.setState({
            SelectedItems: SelectedItems
        });
    }
    selectAllItems() {
        var { items } = this.state;
        var itemList = new Array;
        for (let x = 0; x < items.length; x++) {
            itemList.push(items[x]);
        }
        this.setState({
            SelectedItems: itemList
        });
    }
    unselectAllItems() {
        this.setState({
            SelectedItems: []
        });
    }
    loadMoreScroll(e) {
        const { LoadMoreActive, Token } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !LoadMoreActive && Token) {
            this.setState({
                LoadMoreActive: true
            }, () => {
                let query = { "pagination": Token }
                this.getData(query);
            });
        }
    }
    removeToktoSetPost() {
        const { Set, SelectedItems } = this.state;
        let tokids = new Array;
        for (let x = 0; x < SelectedItems.length; x++) {
            tokids.push(SelectedItems[x]["id"]);
        }
        MySwal.fire({
            title: <p>Removing Toks from Set</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading()
        removeToksFromSet(Set, tokids).then(res => {
            if(res){
                if (res["status"] == 200) {
                    if (res["data"]["resultEnum"] == 1) {
                        MySwal.update({
                            title: <p>Toks removed from Set</p>,
                            icon: "success"
                        });
                        this.props.updatedSet(res["data"]["resultObject"]);
                        this.setState({
                            show: false
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    setFilterOptions(e) {
        const target = e.target.getAttribute("data-target");
        const value = e.target.getAttribute("data-value");
        var { FilterQuery } = this.state;
        switch (target) {
            case "publicfeed":
                if (value == "true") {
                    FilterQuery["publicfeed"] = true
                    this.setState({
                        items: [],
                        LoadActive: true,
                        FilterQuery: FilterQuery
                    }, () => {
                        this.getData();
                    });
                } else {
                    FilterQuery["publicfeed"] = false
                    this.setState({
                        items: [],
                        LoadActive: true,
                        FilterQuery: FilterQuery
                    }, () => {
                        this.getData();
                    });
                }
                break;
            case "FilterBy":
                this.showFilterByModal(value);
                break;
            //case "feedtype":
            //    FilterQuery[target] = Number(value)
            //    this.setState({
            //        items: [],
            //        LoadActive: true,
            //        FeedType: Number(value),
            //        FilterQuery: FilterQuery
            //    }, () => {
            //        this.getData();
            //    });
            //    break;
        }
    }
    showFilterByModal(value) {
        var { Set, FilterQuery, FilterByModalActive } = this.state;
        if (FilterByModalActive != value) {
            FilterQuery["FilterItems"] = [];
        }
        this.setState({
            FilterLoaderActive: true,
            filterResults: [] as FilterItemQuery[],
            FilterByModalShow: true,
            FilterByModalActive: value,
            FilterQuery: FilterQuery
        });
        if (value == "getType") {
            let filterResults = new Array;
            try {
                if (Set["tok_group"].toLowerCase() == "playable") {
                    filterResults = [{ displayText: "Basic", value: "Basic" }, { displayText: "Detail", value: "Detail" }]
                } else {
                    filterResults = [{ displayText: "Mega", value: "Mega" }, { displayText: "Pic", value: "Pic" }, { displayText: "List", value: "List" }]
                }
            } catch (err) {
                filterResults = [{ displayText: "Mega", value: "Mega" }, { displayText: "Pic", value: "Pic" }, { displayText: "List", value: "List" }]
            }
            this.setState({
                FilterLoaderActive: false,
                filterResults: filterResults as FilterItemQuery[]
            });
        } else {
            getFilterOptions(value == "getClasses" ? { FilterBy: 1 } : { FilterBy: 2 }).then(res => {
                let filterResults: FilterItemQuery[]
                if(res){
                    if (res.data.resultEnum == 1) {
                        for (let x = 0; x < res.data.resultObject.results.length; x++) {
                            if (res.data.resultObject.results[x]["title"] && res.data.resultObject.results[x]["id"]) {
                                let tempItem = { displayText: res.data.resultObject.results[x]["title"], value: res.data.resultObject.results[x]["id"] }
                                filterResults.push(tempItem as FilterItemQuery);
                            }
                        }
                    }
                }
                this.setState({
                    filterResults: filterResults
                });
            }).catch(err => {

            }).finally(() => {
                this.setState({
                    FilterLoaderActive: false
                });
            })
        }
    }
    closeFilterByModal() {
        var { FilterQuery, FilterByModalActive } = this.state;
        FilterQuery["FilterItems"] = [];
        FilterQuery["FilterBy"] = 0;
        this.setState({
            FilterByModalShow: false,
            FilterByModalActive: "",
            FilterQuery: FilterQuery
        });
    }

    setFilterByItems(e) {
        var { FilterQuery } = this.state;
        const value = e.target.getAttribute("data-value");
        if (!FilterQuery["FilterItems"].includes(value)) {
            FilterQuery["FilterItems"].push(value)
        } else {
            FilterQuery["FilterItems"] = FilterQuery["FilterItems"].filter(item => item !== value);
        }

        this.setState({
            FilterQuery: FilterQuery
        });
    }

    applyFilterByItems(e) {
        var { FilterQuery, FilterByModalActive } = this.state;
        switch (FilterByModalActive) {
            case "getClasses":
                FilterQuery["FilterBy"] = 1;
                break;
            case "getCategories":
                FilterQuery["FilterBy"] = 2
                break;
            case "getType":
                FilterQuery["FilterBy"] = 3;
                break;
        }

        this.setState({
            FilterQuery: FilterQuery,
            FilterByModalShow: false,
            items: [],
            LoadActive: true
        }, () => {
            this.getData();
        });
    }
    showFilterByMenu() {
        const { ShowFilters } = this.state;
        this.setState({
            ShowFilters: !ShowFilters
        })
    }
    refresh() {
        this.setState({
            items: [],
            LoadActive: true,
        }, () => {
            this.getData();
        });
    }
    showHideSort() {
        const { ShowSort } = this.state;
        this.setState({
            ShowSort: !ShowSort
        })
    }
    setSortOptions(e) {
        const target = e.target.getAttribute("data-target");
        const value = e.target.getAttribute("data-value");
        var { SortQuery } = this.state;
        try {
            SortQuery["orderby"] = target;
            SortQuery["descending"] = value;
        } catch (err) {
            SortQuery["orderby"] = target;
            SortQuery["descending"] = value;
        }
        this.setState({
            SortQuery: SortQuery,
            items: [],
            LoadActive: true,
        }, () => {
            this.getData();
        });
    }
    render() {
        const { sortOptions, ShowSort, ShowFilters, filterOptions, FilterQuery, FilterByModalActive, FilterLoaderActive, filterResults, FilterByModalShow, show, Set, LoadActive, items, SelectedItems, LoadMoreActive } = this.state;
        return (
            <>
                <Button ref={this.removeToksToSetTrigger} hidden onClick={this.showModal} data-set="" data-level1="" data-level2="" data-level3="">Remove Toks from Set</Button>
                {FilterByModalShow ?
                    <Modal size='lg' show={true} onHide={this.closeFilterByModal} style={{ zIndex: "1307" }}>
                        <Modal.Header>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeFilterByModal} />
                                <Typography fontWeight={"bold"}>More Filter Options</Typography>
                                <Button variant="success" style={{ display: "flex", alignItems: "center" }} onClick={this.applyFilterByItems}>
                                    <span>Apply Filter</span>
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body className={'filter-options-containers'}>
                            {

                            }
                            {filterResults.map((option) => (
                                <div className={FilterQuery["FilterItems"] ? FilterQuery["FilterItems"].includes(option.value) ? "active filter-option" : 'filter-option' : "filter-option"} style={{ cursor: "pointer" }} onClick={this.setFilterByItems} data-display={option.displayText} data-value={option.value}>
                                    <span>{option.displayText}</span>

                                    {FilterQuery["FilterItems"] ? FilterQuery["FilterItems"].includes(option.value) ? <FontAwesomeIcon icon={faCheckCircle} size={"sm"} /> : <FontAwesomeIcon icon={faCircle} size={"sm"} /> : <FontAwesomeIcon icon={faCircle} size={"sm"} />}
                                </div>
                            ))}
                            {FilterLoaderActive ? <WaitSpinner /> : ""}
                        </Modal.Body>
                    </Modal>
                    : ""
                }
                {Set ?
                    <Modal size='lg' show={show} onHide={this.hideModal} style={{ zIndex: "1306" }}>
                        <Modal.Header className={'text-color-white dark-background-2'}>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.hideModal} />
                                Remove Toks from {Set["name"]}
                                <Button variant="success" onClick={this.removeToktoSetPost}>Remove from Set</Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body className={'text-color-white dark-background-2'}>
                            <Row style={{ alignItems: "center" }}>
                                <Col xs={"auto"} style={{ paddingRight: "unset" }}>
                                    <Button onClick={this.selectAllItems}>Select All</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button onClick={this.unselectAllItems} variant={'secondary'}>Unselect All</Button>
                                </Col>
                                <Col xs={"auto"} style={{ marginLeft: "auto", padding: "unset" }}>
                                    <div style={{ cursor: "pointer" }} onClick={this.refresh}>
                                        <FontAwesomeIcon icon={faRefresh} />
                                    </div>
                                </Col>
                                <Col xs={"auto"}>
                                    <div style={{ fontWeight: "600", cursor: "pointer" }} onClick={this.showHideSort}>
                                        SORT
                                        <FontAwesomeIcon icon={faSortAmountDown} />
                                    </div>
                                </Col>
                                <Col xs={"auto"} style={{ paddingLeft: "unset" }}>
                                    <div style={{ fontWeight: "600", cursor: "pointer" }} onClick={this.showFilterByMenu}>
                                        FILTER
                                        <FontAwesomeIcon icon={faFilter} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ justifyContent: "center", display: ShowFilters ? "" : "none" }} >
                                {filterOptions.map((filterOption, index) => (
                                    <Col md='auto' sm={6} xs={6}>
                                        <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{filterOption.displayText}</div>
                                        {filterOption.options.map((option) => (
                                            <div style={{ cursor: "pointer", color: filterOption.value == "FilterBy" ? option.value == FilterByModalActive ? "#112379" : "" : FilterQuery["publicfeed"] as unknown as string == option.value ? "#112379" : "" }} data-target={filterOption.value} data-value={option.value} onClick={this.setFilterOptions}>{option.displayText}</div>
                                        ))}
                                    </Col>
                                ))}
                            </Row>
                            <Row style={{ justifyContent: "center", display: ShowSort ? "" : "none" }}>
                                {sortOptions.map((sortOption, index) => (
                                    <Col md='auto' sm={6} xs={6}>
                                        <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{sortOption.displayText}</div>
                                        <div style={{ cursor: "pointer" }} onClick={this.setSortOptions} className={`${sortOption.value}-${false}`} data-target={sortOption.value} data-value={false}>Ascending</div>
                                        <div style={{ cursor: "pointer" }} onClick={this.setSortOptions} className={`${sortOption.value}-${true}`} data-target={sortOption.value} data-value={true}>Descending</div>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col xs={"auto"} style={{ marginLeft: "auto" }}>
                                    {SelectedItems.length} out of {items.length} selected
                                </Col>
                            </Row>
                            {LoadActive ?
                                <WaitSpinner /> :
                                <Row onScroll={this.loadMoreScroll} className={'selection-container'} style={{ height: "65vh", padding: "5px 10px 0px", overflowY: "scroll", overflowX: "hidden", display: "block" }}>
                                    {items.map(tok => {
                                        let selectedItem = false;
                                        for (let x = 0; x < SelectedItems.length; x++) {
                                            if (SelectedItems[x]["id"] == tok["id"] && SelectedItems[x]["pk"] == tok["pk"]) {
                                                selectedItem = true;
                                                break;
                                            }
                                        }
                                        return (
                                            <Col onClick={this.removeToktoTempSet} data-id={tok.id} data-pk={tok.pk} className={selectedItem ? 'row selection-item active' : 'row selection-item'} xs={12}>
                                                <div style={{ height: "70px", width: "70px", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: tok["user_photo"] ? `url(${tok["user_photo"]})` : `url("https://tokketcontent.blob.core.windows.net/tokketweb/default.png")` }}></div>
                                                <Col style={{ fontWeight: "600", alignSelf: "center" }}>
                                                    <div>
                                                        {tok["user_display_name"] ? tok["user_display_name"] : ""}
                                                    </div>
                                                    <div>
                                                        {tok["primary_text"] ? tok["primary_text"] : ""} <span style={{ color: "gray" }}>{tok["tok_type"] ? " • Tok Type: " + tok["tok_type"] : ""}</span>
                                                    </div>
                                                </Col>
                                                <div style={{ position: "absolute", bottom: "5px", right: "10px", width: "fit-content" }}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </div>
                                            </Col>
                                        );
                                    })}
                                    {LoadMoreActive ?
                                        <WaitSpinner /> : ""
                                    }
                                </Row>
                            }
                        </Modal.Body>
                    </Modal> : ""
                }

            </>
        );
    }
}

export default RemoveToksToSet;