import ReactDOM from 'react-dom/client';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IconButton from '@mui/material/IconButton';
import { signupUser } from '../services/accountServices';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useState } from 'react';
import React from 'react';

const Signup = (props) => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const [isShowSignup, SetIsShowSignup] = useState(false);

    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const signupTrigger = props.signupTrigger;

    const onSignup = async (e) => {
        e.preventDefault()

        //   await createUserWithEmailAndPassword(auth, email, password)
        //     .then(async (userCredential) => {
        //         // Signed in
        //         const user = userCredential.user;
        //         console.log(user);

        //         var res = await createUser({
        //             label: "user",
        //             display_name: displayName,
        //             // TODO: Add more here..
        //         });
        //         console.log(res);

        //         //window.location.reload();
        //     })
        //     .catch((error) => {
        //         const errorCode = error.code;
        //         const errorMessage = error.message;
        //         console.log(error);
        //         // ..
        //     });
        MySwal.fire({
            title: <p>Signing up user information.</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        var result = await signupUser({
            label: "user",
            display_name: displayName,
            Email: email,
            PasswordHash: password
        });
        MySwal.hideLoading();
        if (result) {
            if (result["data"]["status_code"] == 200) {
                MySwal.update({
                    title: <p>Success.</p>,
                    icon: "success"
                });
                SetIsShowSignup(false);
                props.loginCallTrigger.current.click();
            } else {
                MySwal.update({
                    title: <p>An Error Occured</p>,
                    icon: "warning"
                });
            }
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onLogin = (e) => {
        e.preventDefault();
        SetIsShowSignup(false);
        props.loginCallTrigger.current.click();
    };

    return (
        <>
            <Button onClick={() => SetIsShowSignup(true)} ref={signupTrigger} hidden>SignupNow</Button>
            <Modal show={isShowSignup} style={{ zIndex: "1205" }}>
                <Modal.Header>
                    Register
                    <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => SetIsShowSignup(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'displayName'} style={{ width: "100%" }} value={displayName} onChange={(e) => setDisplayName(e.target.value)} label="Name" />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'email'} style={{ width: "100%" }} value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'password'} type={showPassword ? 'text' : 'password'} style={{ width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)} label="Create Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"> {/* Add position prop here */}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <Button variant={'contained'} color={"primary"} style={{ width: "100%" }} onClick={onSignup}>Register</Button>
                        </Col>
                        <Col xs={12}>
                            <p className="text-sm text-center">
                                Already have an account? {' '}
                                <NavLink onClick={onLogin} to="">
                                    Login
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Signup