import * as React from 'react';
import Row from '@mui/material/Box';
import Col from '@mui/material/Box';
import '../styles.css'

export default function ShimmerChatMsg() {
    return (
        <div className={"container-shimmer radious-shimmer chat-shimmer dark-background-1"}>
            <div className={"wrapper-shimmer row"} style={{ margin: "unset", marginTop: "-5px" }}>
                <div style={{ height: "100%", width: "max-content", padding: "unset" }} className={"flexCenter"}>
                    <div className={"animate-shimmer circle-set-shimmer"} style={{ height: "40px", width: "40px" }}></div>
                </div>
                <div className={"col top-tile-owner-shimmer"}>
                    <div className={"line-shimmer radious-shimmer animate-shimmer"} style={{ marginTop: "unset" }}></div>
                    <div className={"line-shimmer radious-shimmer animate-shimmer"} style={{ marginTop: "5px" }}></div>
                </div>
            </div>
        </div>
    );
}