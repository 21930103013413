import React from 'react'
import '../styles.css'
import './css/TokTile.css';
import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
const TokTileCard = (props) => {
    const card = props.card;
    const [isFlipped, SetIsFlipped] = useState(false);
    var secondary: JSX.Element;
    
    switch (card.tok_group) {
        case "Detail":
        case "Detailed":
            secondary = <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'center' }}>
                <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                    {card.details.map((dtl, index) => {
                        if (dtl == null) return '';
                        if (dtl.length <= 0) return '';
                        let indent = false;
                        if(card["is_indent"]){
                            indent = card["is_indent"][index];
                        }
                        return (
                            <ListItem sx={{ marginLeft: indent ? "10px": "", listStyle: indent? "square": "" , paddingTop: '0px', paddingBottom: '0px', textAlign: 'center', display: 'list-item', flexShrink: 0 }}>
                                <ListItemText className={'elipsis-one-line-parent'}
                                    primary={dtl}
                                // secondary={secondary ? 'Secondary text' : null}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Typography>
            break;
        case "List":
            if (card.details) {
                secondary = <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'center' }}>
                    <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                        {card.details.map((dtl, index) => {
                            if (dtl == null) return '';
                            if (dtl.length <= 0) return '';

                            return (
                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'center', display: 'list-item', flexShrink: 0 }}>
                                    <ListItemText className={'elipsis-one-line-parent'}
                                        primary={dtl}
                                    // secondary={secondary ? 'Secondary text' : null}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Typography>
            }

            break;
        case "Mega":
            if (card.sections) {
                secondary = <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'center' }}>
                    <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                        {card.sections.map(section => {
                            if (section.title == null) return '';
                            if (section.title.length <= 0) return '';

                            return (
                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'center', display: 'list-item', flexShrink: 0 }}>
                                    <ListItemText className={'elipsis-one-line-parent'}
                                        primary={section.title}
                                    // secondary={secondary ? 'Secondary text' : null}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Typography>
            }
            //add condition for section to be more than 4, set doubleTileActive to true
            break;
        case "Q&a":
            if (card.sections) {
                secondary = <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'center' }}>
                    <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%' }} >
                        {card.sections.map(section => {
                            if (section.title == null) return '';
                            if (section.title.length <= 0) return '';

                            return (
                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px', textAlign: 'center', display: 'list-item', flexShrink: 0 }}>
                                    <ListItemText className={'elipsis-one-line-parent'}
                                        primary={section.title}
                                    // secondary={secondary ? 'Secondary text' : null}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Typography>
            }
            //add condition for section to be more than 4, set doubleTileActive to true
            break;
        default:
            secondary = <Typography className={'line-clamp-5'} variant="body2"  fontSize={"30px"} style={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                {card.secondary_text}
            </Typography>
            break;
    }
    return (
        <Col xs={12}>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                <div className={'tok-tile-card'} onClick={(e) => SetIsFlipped(!isFlipped)}>
                    {card.primary_text}
                </div>

                <div className={'tok-tile-card'} onClick={(e) => SetIsFlipped(!isFlipped)}>
                    {secondary}
                </div>
            </ReactCardFlip>
        </Col>
    )
}

export default TokTileCard;