import React from 'react'
import '../styles.css'
import './css/AddExistingClassTok.css'
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { getToks, addClassTok } from '../services/classServices';
import { getToken } from '../services/accountServices';
import { createRef } from 'react';
import { TextField } from '@mui/material';
import ButtonReact from 'react-bootstrap/Button';
import { Button } from 'react-bootstrap';
import { faFilter, faAngleRight, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from 'react-bootstrap';
import AddEditClassGroup from './AddEditClassGroup';
import arrayShuffle from 'array-shuffle';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Group } from '../interfaces/GroupInterface';
import { Tok } from '../interfaces/TokInterface';

const MySwal = withReactContent(Swal);
interface Props {
    query: { [key: string]: any };
    ShowTriggerBtn?: boolean;
    Group?: Group;
    showTriggerRefBtn?: React.Ref<any>;
    addExistingClassTokTransferRef: React.Ref<any>;
}

interface States {
    items: Tok[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    ShowTriggerBtn: boolean;
    ShowModal: boolean;
    SelectedItem: Tok;
    Group: Group;
    resultData: { [key: string]: any };
    Token: string;
}

class AddExisitngClassTok extends React.Component<Props, States> {
    showTriggerRefBtn: React.RefObject<any>;
    addExistingClassTokTransferRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            ShowTriggerBtn: props.ShowTriggerBtn ? props.ShowTriggerBtn : false,
            ShowModal: false,
            SelectedItem: { id: "", pk: "" } as Tok,
            Group: props.Group ? props.Group as Group : {} as Group,
            resultData: {},
            Token: ""
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.setShowModal = this.setShowModal.bind(this);
        this.showTriggerRefBtn = props.showTriggerRefBtn ? props.showTriggerRefBtn : createRef();

        this.setToAddExistingTok = this.setToAddExistingTok.bind(this);
        this.addTokToGroup = this.addTokToGroup.bind(this);
        this.addExistingClassTokTransferRef = props.addExistingClassTokTransferRef;
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    async getData(customQuery) {
        var { query, items } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
            if (!query["pagination"]) {
                this.setState({
                    items: []
                });
            }
        }
        const response = await getToks(query, "", "");
        var itemResult = response.data.result ? response.data.result : new Array;
        try {
            if (query["pagination"]) {
                itemResult = items.concat(itemResult);
            }
        } catch (err) { }
        this.setState({
            resultData: response.data.resource,
            continuationToken: response.data["pagination"] ? response.data["pagination"] : "",
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
    }

    loadmore() {
        this.setState({
            LoadMoreActive: true
        }, () => {
            var { Token } = this.state;
            this.getData(Token)
        });
    }

    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    setShowModal() {
        const { ShowModal } = this.state;
        this.setState({
            ShowModal: !ShowModal
        })
    }
    setToAddExistingTok(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        const { items } = this.state;
        var filterSearch = items.filter(item => item["id"] == id && item["pk"] == pk);
        var selectedItem = filterSearch.length ? filterSearch[0] : { id: "", pk: "" };
        this.setState({
            SelectedItem: selectedItem as Tok
        });
    }
    addTokToGroup() {
        var { SelectedItem, Group } = this.state;
        MySwal.fire({
            title: <p>Adding Tok to Group</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        SelectedItem["is_group"] = true;
        SelectedItem["group_id"] = Group.id;
        SelectedItem["private"] = false;
        SelectedItem["is_private"] = false;
        SelectedItem["is_public"] = false;
        addClassTok(SelectedItem, "").then(response => {
            if (response) {
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Tok Added to Group</p>,
                        icon: "warning"
                    });
                    try {
                        this.addExistingClassTokTransferRef.current.setAttribute("data-data", JSON.stringify(response.data.resultObject));
                        this.addExistingClassTokTransferRef.current.click();
                    } catch (err) { }
                    this.setState({
                        SelectedItem: { id: "", pk: "" } as Tok
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    render() {
        var { items, ShowTriggerBtn, ShowModal, LoadActive, continuationToken, LoadMoreActive, SelectedItem, Group } = this.state;
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <>
                <Button ref={this.showTriggerRefBtn} style={{ display: ShowTriggerBtn ? "" : "none" }} onClick={this.setShowModal}>Add Existing Tok</Button>
                <Modal size='lg' show={ShowModal} style={{ zIndex: "1207" }}>
                    <Modal.Header>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.setShowModal} />
                            Add Existing Tok {Group["name"] ? "to " + Group["name"] : ""}
                            <Button variant="success" onClick={SelectedItem["id"] != "" || SelectedItem["pk"] != "" ? this.addTokToGroup : null} disabled={SelectedItem["id"] != "" || SelectedItem["pk"] != "" ? false : true}>Add</Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ height: "75vh", overflow: "auto", overflowX: "hidden" }}>
                        {!LoadActive ?
                            <Row className={'selection-container'}>
                                {items.map((tok) => (
                                    <Col onClick={this.setToAddExistingTok} data-id={tok.id} data-pk={tok.pk} className={SelectedItem["id"] == tok.id && SelectedItem["pk"] == tok.pk ? 'row selection-item active' : 'row selection-item'} xs={12}>
                                        <div style={{ height: "70px", width: "70px", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: tok["user_photo"] ? `url(${tok["user_photo"]})` : `url("https://tokketcontent.blob.core.windows.net/tokketweb/default.png")` }}></div>
                                        <Col style={{ fontWeight: "600", alignSelf: "center" }}>
                                            <div>
                                                {tok["user_display_name"] ? tok["user_display_name"] : ""}
                                            </div>
                                            <div>
                                                {tok["primary_text"] ? tok["primary_text"] : ""} <span style={{ color: "gray" }}>{tok["tok_type"] ? " • Tok Type: " + tok["tok_type"] : ""}</span>
                                            </div>
                                        </Col>
                                        <div style={{ position: "absolute", bottom: "5px", right: "10px", width: "fit-content" }}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </div>
                                    </Col>
                                ))}
                            </Row> : <WaitSpinner />
                        }

                    </Modal.Body>
                </Modal>
            </>

        );
    }
}

export default AddExisitngClassTok;