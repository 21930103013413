import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import { getReactions, addReaction, deleteReaction, updateReaction, patchReaction } from '../services/reactionServices';
import { getToken } from '../services/accountServices';
import './css/Comments.css';
import { createRef } from 'react';
import WaitSpinner from './WaitSpinner';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faRefresh, faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import Moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import { cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import ButtonBootsrap from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Typography from "@mui/material/Typography";
import toast, { Toaster } from 'react-hot-toast';
import IsAuthenticated from './IsAuthenticated';
import { randomString } from '../services/helperTools';
import Login from '../firebase/Login';
import MessageIcon from '@mui/icons-material/Message';
import { Tok } from '../interfaces/TokInterface';
import { Set } from '../interfaces/SetInterface';
import { Reaction } from '../interfaces/ReactionInterface';
interface CustomToggleProps {
    children: React.ReactNode;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleProps>(
    ({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                onClick(e);
            }}
            style={{ cursor: "pointer" }}
        >
            {children}
        </div>
    ),
);
const MySwal = withReactContent(Swal);
interface States {
    CommentCountLoader: boolean;
    UserCommentActive: boolean;
    CommentsViewActive: boolean;
    HideCommentButton: boolean;
    HideCommentStatus: boolean;
    ItemId: string;
    DetailNo: number;
    Comments: Reaction[];
    Token: string;
    LoadActive: boolean;
    LoadMoreActive: boolean;
    Item: Tok | Set; // add More Interfaces if needed
    EditCommentActive: boolean;
    InaccurateCommentActive: boolean;
    Pk: string;
    CommentCount: number;
    LoadMoreCounter: number;
    ViewCommentModal: boolean;
    callLoginKey: string;
    iconMode: boolean;
    viewIconModeModal: boolean;
    mobileView: boolean;
    oldUiView: boolean;
    iconModeSize: 'inherit' | 'large' | 'medium' | 'small' | any; // putting any temp solution
    disableInitialization: boolean;
    authenticated: boolean;
}
interface Props {
    commentCounterRef: React.RefObject<any>;
    commentSwitchUiRef: React.RefObject<any>;
    iconMode: boolean;
    iconModeSize: 'inherit' | 'large' | 'medium' | 'small' | any;
    viewProfileSet: (data: any) => void;
    disableInitialization: boolean;
    detailNo: number;
    itemId: string;
    Pk: string;
    item: Tok | Set; // add More Interfaces if needed;
    userCommentActive: boolean;
    commentsViewActive: boolean;
    hideCommentButton: boolean;
}
class Comments extends React.Component<Props, States> {
    quillComment: React.RefObject<any>;
    editComment: React.RefObject<any>;
    inaccurateComment: React.RefObject<any>;
    replyComment: React.RefObject<any>;
    commentCounterRef: React.RefObject<any>;
    loginCallTrigger: React.RefObject<any>;
    commentSwitchUiRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        var detailNo = -1;
        try {
            if (Number(props.detailNo) >= 0) {
                detailNo = Number(props.detailNo);
            }
        } catch (err) { }
        this.state = {
            CommentCountLoader: false,
            UserCommentActive: props.userCommentActive ? props.userCommentActive : false,
            CommentsViewActive: props.commentsViewActive ? props.commentsViewActive : false,
            HideCommentButton: props.hideCommentButton ? props.hideCommentButton : false,
            HideCommentStatus: props.hideCommentButton ? props.hideCommentButton : false,
            ItemId: props.itemId ? props.itemId : "",
            DetailNo: detailNo,
            Comments: [],
            Token: "",
            LoadActive: false,
            LoadMoreActive: false,
            Item: props.item ? props.item : "",
            EditCommentActive: false,
            InaccurateCommentActive: false,
            Pk: props.Pk ? props.Pk : "",
            CommentCount: 0,
            LoadMoreCounter: 0,
            ViewCommentModal: false,
            callLoginKey: "",
            iconMode: props.iconMode ? props.iconMode : false,
            viewIconModeModal: false,
            mobileView: false,
            oldUiView: false,
            iconModeSize: props.iconModeSize ? props.iconModeSize : "large",
            disableInitialization: props.disableInitialization ? props.disableInitialization : false,
            authenticated: false,
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);
        this.showComments = this.showComments.bind(this);
        this.sendUserComment = this.sendUserComment.bind(this);

        this.deleteComment = this.deleteComment.bind(this);
        this.quillComment = createRef();
        this.editComment = createRef();
        this.inaccurateComment = createRef();
        this.replyComment = createRef();

        this.setEditComment = this.setEditComment.bind(this);
        this.cancelEditComment = this.cancelEditComment.bind(this);
        this.updateEditComment = this.updateEditComment.bind(this);

        this.inaccurateCommentSet = this.inaccurateCommentSet.bind(this);
        this.inaccurateCommentCancel = this.inaccurateCommentCancel.bind(this);
        this.inaccurateCommentSend = this.inaccurateCommentSend.bind(this);

        this.setReplyComment = this.setReplyComment.bind(this);
        this.cancelReplyComment = this.cancelReplyComment.bind(this);
        this.sendReplyComment = this.sendReplyComment.bind(this);

        this.loadMoreReplies = this.loadMoreReplies.bind(this);
        this.showHideReplies = this.showHideReplies.bind(this);

        this.loadMoreScroll = this.loadMoreScroll.bind(this);

        this.commentCounterRef = props.commentCounterRef;
        this.setCommentCounter = this.setCommentCounter.bind(this);

        this.viewProfileSet = this.viewProfileSet.bind(this);

        this.updateCheck = this.updateCheck.bind(this);
        this.addLike = this.addLike.bind(this);

        this.viewCommentModal = this.viewCommentModal.bind(this);
        this.closeCommentModal = this.closeCommentModal.bind(this);

        this.removeLike = this.removeLike.bind(this);

        this.loginCallTrigger = createRef();
        this.callLogin = this.callLogin.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);

        this.openIconModeModal = this.openIconModeModal.bind(this);
        this.closeIconModeModal = this.closeIconModeModal.bind(this);

        this.switchUi = this.switchUi.bind(this);
        this.commentSwitchUiRef = this.props.commentSwitchUiRef ? this.props.commentSwitchUiRef : null;
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        var { CommentsViewActive, DetailNo, disableInitialization } = this.state;
        if (CommentsViewActive) {
            this.setState({
                LoadActive: true,
                CommentCountLoader: !(DetailNo >= 0)
            });
            if (!disableInitialization) {
                this.getData();
            }
        }
    }
    async getData(Token = "") {
        var { ItemId, DetailNo, Comments } = this.state;
        await getToken();
        const query = {
            item_id: ItemId,
            kind: "comment",
            parent_item: "null",
            $detail_num: DetailNo,
        }

        if (Token) {
            query["pagination"] = Token;
        }
        let feedId = ItemId;
        const response = await getReactions(query, feedId);
        let newComments = response.data["result"] ? response.data["result"] : [];
        let token = response.data["pagination"] ? response.data["pagination"] : "";
        if (newComments) {
            for (let x = 0; x < newComments.length; x++) {
                if (!newComments[x]["replyset"]) {
                    this.getReplies(newComments[x]);
                    this.getUserLikeComment(newComments[x]["id"], newComments[x]["pk"])
                }
            }
        }
        if (Token) {
            Comments = Comments.concat(newComments);
        } else {
            Comments = newComments;
        }
        var { CommentCount } = this.state;
        this.setState({
            Comments: Comments,
            LoadActive: false,
            LoadMoreActive: false,
            Token: token,
            CommentCount: Comments.length,
            CommentCountLoader: false
        });
    }
    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData();
        });
    }
    loadmore() {
        var { LoadMoreCounter } = this.state;
        LoadMoreCounter++;
        this.setState({
            LoadMoreActive: true,
            LoadMoreCounter: LoadMoreCounter
        }, () => {
            var { Token } = this.state;
            this.getData(Token)
        });
    }
    showComments() {
        var { HideCommentStatus } = this.state;
        this.setState({
            HideCommentStatus: !HideCommentStatus
        });
    }
    async sendUserComment() {
        var { Item, DetailNo, Comments, CommentCount } = this.state;
        const comment = this.quillComment.current.value.replace(/<\/?[^>]+(>|$)/g, "");
        let feedId = Item["id"] ? Item["id"] : "";
        const reaction = {
            'label': "reaction",
            'activity_id': "",
            'kind': "comment",
            'ReactionKind': "3",
            'owner_id': Item["user_id"] ? Item["user_id"] : "",
            'category_id': Item["category_id"] ? Item["category_id"] : "",
            'tok_type_id': Item["tok_type_id"] ? Item["tok_type_id"] : "",
            'text': comment,
            'item_id': Item["id"] ? Item["id"] : "",
            'parent_item': "",
            'is_child': false,
            'star_rating': 0,
            'question_number': -1,
            'tok_section': -1,
            'detail_num': DetailNo,
            'is_comment': true,
            'authenticated': ''
        }
        try {
            MySwal.fire({
                title: <p>Adding Comment</p>,
                allowOutsideClick: false
            });
            MySwal.showLoading()
            console.log(reaction);
            const response = await addReaction(reaction, feedId);
            if (response) {
                if (response.status == 200) {
                    MySwal.update({
                        title: <p>Comment Added</p>,
                        icon: "success"
                    });
                    MySwal.hideLoading()
                    let newComment = response["data"]["result"];
                    newComment["replyset"] = true;
                    Comments.unshift(newComment);
                    CommentCount++;
                    this.setState({
                        Comments: Comments,
                        HideCommentStatus: false,
                        CommentCount: CommentCount
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                }
            }
        } catch (err) {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
            MySwal.hideLoading();
        }
    }
    async deleteComment(comment) {
        const id = comment.currentTarget.getAttribute("data-id");
        const pk = comment.currentTarget.getAttribute("data-pk");
        var { Comments, CommentCount } = this.state;

        MySwal.fire({
            title: <p>Are you sure want to delete?</p>,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333"
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Comment</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading()
                try {
                    deleteReaction(id, pk).then(response => {
                        MySwal.hideLoading();
                        if (response.data.result == true) {
                            MySwal.update({
                                title: 'Deleted!',
                                icon: "success"
                            });
                            if (Comments) {
                                for (let x = 0; x < Comments.length; x++) {
                                    if (Comments[x].id == id && Comments[x].pk == pk) {
                                        Comments.splice(x, 1);
                                        CommentCount--;
                                        break;
                                    }
                                }
                            }

                            this.setState({
                                Comments: Comments,
                                CommentCount: CommentCount
                            });
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    });
                } catch (err) {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                }
            }
        })
    }
    setEditComment(comment) {
        const id = comment.currentTarget.getAttribute("data-id");
        const pk = comment.currentTarget.getAttribute("data-pk");
        var { Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            if (Comments[x].id == id && Comments[x].pk == pk) {
                Comments[x]["edit-comment"] = true;
            } else {
                Comments[x]["edit-comment"] = false;
            }
        }
        this.setState({
            Comments: Comments,
            EditCommentActive: true,
            InaccurateCommentActive: false
        });
    }
    cancelEditComment() {
        var { Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            Comments[x]["edit-comment"] = false;
        }
        this.setState({
            Comments: Comments,
            EditCommentActive: false,
            InaccurateCommentActive: false
        });
    }
    updateEditComment(comment) {
        const id = comment.currentTarget.getAttribute("data-id");
        const pk = comment.currentTarget.getAttribute("data-pk");
        const updateText = this.editComment.current.value.replace(/<\/?[^>]+(>|$)/g, "");
        var { Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            if (Comments[x].id == id && Comments[x].pk == pk) {
                let ogComment = cloneDeep(Comments[x]);
                Comments[x]["text"] = updateText;
                MySwal.fire({
                    title: <p>Updating Comment</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading()
                let patchObject = diff(ogComment, Comments[x]);
                let patchObjectKeys = Object.keys(patchObject);
                for (let x = 0; x < patchObjectKeys.length; x++) {
                    patchObject[patchObjectKeys[x]] = cloneDeep(Comments[x][patchObjectKeys[x]]);
                }
                patchReaction(patchObject, Comments[x]["id"], Comments[x]["pk"]).then(response => {
                    MySwal.hideLoading();
                    if (response.data.result == true) {
                        Comments[x]["edit-comment"] = false;
                        MySwal.update({
                            title: 'Updated!',
                            icon: "success"
                        });
                        this.setState({
                            Comments: Comments,
                            EditCommentActive: false,
                            InaccurateCommentActive: false
                        });
                    } else {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }
                }).catch(response => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                    MySwal.hideLoading();
                })
                break;
            }
        }
    }
    inaccurateCommentSet() {
        const { ItemId, Pk, DetailNo, Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            Comments[x]["edit-comment"] = false;
        }
        this.setState({
            Comments: Comments,
            EditCommentActive: false,
            InaccurateCommentActive: true
        });
    }
    inaccurateCommentCancel() {
        this.setState({
            EditCommentActive: false,
            InaccurateCommentActive: false
        });
    }
    async inaccurateCommentSend() {
        var { Item, DetailNo, Comments, ItemId, Pk, DetailNo } = this.state;
        const comment = this.inaccurateComment.current.value.replace(/<\/?[^>]+(>|$)/g, "");
        let feedId = Item["id"] ? Item["id"] : "";
        const reaction: Partial<Reaction> = {
            'label': "reaction",
            'activity_id': "",
            'kind': "inaccurate",
            'ReactionKind': "2",
            'owner_id': Item["user_id"] ? Item["user_id"] : "",
            'category_id': Item["category_id"] ? Item["category_id"] : "",
            'tok_type_id': Item["tok_type_id"] ? Item["tok_type_id"] : "",
            'text': comment,
            'item_id': Item["id"] ? Item["id"] : "",
            'parent_item': "",
            'is_child': false,
            'star_rating': 0,
            'question_number': -1,
            'tok_section': -1,
            'detail_num': DetailNo >= 0 ? DetailNo + 1 : -1,
            'replyset': true
        }
        try {
            var inaccurateMsg = toast.loading('Adding Inaccurate');
            const response = await addReaction(reaction, feedId);
            if(response){
                if (response.status == 200) {
                    if (response["data"]["status_code"] == 200) {
                        toast.success('Inaccurate Added', {
                            id: inaccurateMsg,
                        });
                        const localUser = localStorage.getItem("userdata");
                        if (localUser) {
                            const localUserJson = JSON.parse(localUser);
                            reaction["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                            reaction["user_id"] = localUserJson["id"] ? localUserJson["id"] : "";
                            reaction["created_time"] = new Date();
                            reaction["id"] = response.data.resultObject;
                            reaction["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                        }
                        Comments.unshift(reaction as Reaction);
                        try {
                            (document.querySelector(`.disable-accurate-set[data-id="${ItemId}"][data-pk="${Pk}"][data-detailno="${DetailNo}"]`) as HTMLElement).click();
                        } catch (err) { }
                        this.setState({
                            Comments: Comments,
                            InaccurateCommentActive: false
                        });
                    }
                    /*
                    else if (response.data.resultEnum == 2) {
                        MySwal.update({
                            title: <p>Reaction Already Exists</p>,
                            icon: "warning"
                        });
                        try {
                            document.querySelector(`.disable-accurate-set[data-id="${ItemId}"][data-pk="${Pk}"][data-detailno="${DetailNo}"]`).click();
                        } catch (err) { }
                        this.setState({
                            InaccurateCommentActive: false
                        });
                    } */
                    else {
                        toast.error('An Error Occured', {
                            id: inaccurateMsg,
                        });
                    }
                }
            }
        } catch (err) {
            toast.error('An Error Occured', {
                id: inaccurateMsg,
            });
        } finally {
        }
    }
    setReplyComment(comment) {
        const id = comment.currentTarget.getAttribute("data-id");
        const pk = comment.currentTarget.getAttribute("data-pk");
        var { Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            if (Comments[x].id == id && Comments[x].pk == pk) {
                Comments[x]["reply-comment"] = true;
            } else {
                Comments[x]["reply-comment"] = false;
            }
        }
        this.setState({
            Comments: Comments
        });
    }
    cancelReplyComment() {
        var { Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            Comments[x]["reply-comment"] = false;
        }
        this.setState({
            Comments: Comments
        });
    }
    sendReplyComment(comment) {
        const id = comment.currentTarget.getAttribute("data-id");
        const pk = comment.currentTarget.getAttribute("data-pk");
        const msg = this.replyComment.current.value.replace(/<\/?[^>]+(>|$)/g, "");
        var { Comments, DetailNo, Item } = this.state;
        let targetComment = Comments.filter(comment => comment["id"] == id && comment["pk"] == pk)[0];
        if (targetComment["id"]) {
            let feedId = Item["id"] ? Item["id"] : "";
            var reaction: Partial<Reaction> = {
                'label': "reaction",
                'activity_id': "",
                'kind': "comment",
                'ReactionKind': "3",
                'owner_id': Item["user_id"] ? Item["user_id"] : "",
                'category_id': Item["category_id"] ? Item["category_id"] : "",
                'tok_type_id': Item["tok_type_id"] ? Item["tok_type_id"] : "",
                'text': msg,
                'item_id': Item["id"],
                'parent_item': targetComment.id,
                'is_child': true,
                'star_rating': 0,
                'question_number': -1,
                'tok_section': -1,
                'reaction_id': Item["id"],
                'is_comment': true
            }

            var addingReply = toast.loading('Adding Reply');

            addReaction(reaction, feedId).then(response => {
                if (response["data"]["status_code"] == 200) {
                    if (response["data"]["result"]) {
                        targetComment["reply-comment"] = false;
                        reaction["id"] = response["data"]["result"]["id"];
                        reaction["pk"] = response["data"]["result"]["pk"];
                        const localUser = localStorage.getItem("userdata");
                        if (localUser) {
                            const localUserJson = JSON.parse(localUser);
                            reaction["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                            reaction["user_id"] = localUserJson["id"] ? localUserJson["id"] : "";
                            reaction["created_time"] = new Date();
                            reaction["id"] = response["data"]["result"]["id"];
                            reaction["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                        }
                        if (targetComment["children"]) {
                            targetComment["children"].unshift(reaction as Reaction);
                        } else {
                            let tempArray = new Array;
                            tempArray.push(reaction);
                            targetComment["children"] = tempArray;
                        }
                        toast.success('Success!', {
                            id: addingReply,
                        });
                        this.setState({
                            Comments: Comments
                        });
                    } else {
                        toast.error('An Error Occured', {
                            id: addingReply,
                        });
                    }
                } else {
                    toast.error('An Error Occured', {
                        id: addingReply,
                    });
                }
            }).catch(response => {
                toast.error('An Error Occured', {
                    id: addingReply,
                });
            }).finally(() => { })
        }
    }
    getReplies(comment, token = "") {
        const query = {
            parent_item: comment.id,
            is_child: true
        }
        let feedId = comment["item_id"];
        let children = [];
        let childrenToken = "";
        getReactions(query, feedId).then(response => {
            if (response.data.result.length > 0) {
                if (token == "") {
                    children = response.data.result;
                } else {
                    children = comment["children"].concat(response.data.result);
                }
                childrenToken = response.data["pagination"] ? response.data["pagination"] : ""
            }
        }).catch(response => {
        }).finally(() => {
            var { Comments } = this.state;
            let updateComment = {} as Reaction;
            let commentCount = -1;
            for (let x = 0; x < Comments.length; x++) {
                if (Comments[x]["id"] == comment["id"] && Comments[x]["pk"] == comment["pk"]) {
                    updateComment = cloneDeep(Comments[x]);
                    commentCount = x;
                }
            }

            if (commentCount >= 0) {
                updateComment["replyset"] = true;
                updateComment["children"] = children;
                updateComment["children_token"] = childrenToken;
                Comments[commentCount] = updateComment as Reaction;

                this.setState({
                    Comments: Comments
                });
            }
        })
    }
    loadMoreReplies(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        const { Comments } = this.state;
        for (let x = 0; x < Comments.length; x++) {
            if (Comments[x].id == id && Comments[x].pk == pk) {
                this.getReplies(Comments[x], Comments[x]["children_token"]);
            }
        }
    }
    showHideReplies(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        var { Comments } = this.state;

        for (let x = 0; x < Comments.length; x++) {
            if (Comments[x].id == id && Comments[x].pk == pk) {
                Comments[x]["hidechildren"] = Comments[x]["hidechildren"] ? false : true;
                break;
            }
        }
        this.setState({
            Comments: Comments
        });
    }
    loadMoreScroll(e) {
        const { LoadMoreActive, Token } = this.state;
        const maxHeight = e.currentTarget.scrollHeight;
        const modifer = 10;
        const currentScrollLocation = e.currentTarget.scrollTop + e.currentTarget.offsetHeight + modifer;
        if ((currentScrollLocation > maxHeight) && !LoadMoreActive && Token) {
            this.loadmore();
        }
    }
    setCommentCounter(e) {
        var count = 0;
        var { CommentCount } = this.state;
        try {
            count = Number(e.currentTarget.getAttribute("data-commentcount"));
        } catch (err) { }
        var setComment = CommentCount + count;
        this.setState({
            CommentCount: setComment,
            CommentCountLoader: false
        });
    }
    viewProfileSet(e) {
        const id = e.currentTarget.getAttribute("data-id");
        this.props.viewProfileSet(id);
    }
    updateCheck() {
        var { ItemId, DetailNo, Comments, LoadMoreCounter } = this.state;
        this.setState({
            CommentCountLoader: true,
        });
        const query = {
            item_id: ItemId,
            kind: "comment",
            $detail_num: DetailNo,
        }
        //add token later
        let feedId = ItemId;
        getReactions(query, feedId).then(res => {
            let newComments = res.data["result"];
            let editedComments = new Array;
            let addComments = new Array;
            if (newComments) {
                for (let x = 0; x < newComments.length; x++) {
                    let match = false;
                    for (let y = 0; y < Comments.length; y++) {
                        if (newComments[x]["id"] == Comments[y]["id"]) {
                            match = true;
                            let newComment = newComments[x];
                            newComment["children"] = Comments[y]["children"];
                            newComment["children_token"] = Comments[y]["children_token"];
                            newComment["replyset"] = Comments[y]["replyset"];
                            editedComments.push(newComment);
                        }
                    }
                    if (!match) {
                        addComments.push(newComments[x]);
                    }
                }
            }
            let resultComments = addComments.concat(editedComments);
            for (let x = 0; x < resultComments.length; x++) {
                if (!resultComments[x]["replyset"]) {
                    this.getReplies(resultComments[x]);
                }
            }
            var { CommentCount } = this.state;
            this.setState({
                Comments: resultComments,
                LoadActive: false,
                LoadMoreActive: false,
                CommentCountLoader: false,
                CommentCount: resultComments.length > CommentCount ? resultComments.length : CommentCount
            });
        }).catch(err => {
        }).finally(() => {

        })
    }
    addLike(e) {
        const localUser = localStorage.getItem("userdata");
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");
        const userid = e.currentTarget.getAttribute("data-userid");
        var { Item, DetailNo, Comments, CommentCount } = this.state;
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            const reaction = {
                'label': "reaction",
                'activity_id': "",
                'kind': "like",
                'owner_id': Item["user_id"] ? Item["user_id"] : "",
                'user_id': localUserJson["id"] ? localUserJson["id"] : "",
                'category_id': Item["category_id"] ? Item["category_id"] : "",
                'tok_type_id': Item["tok_type_id"] ? Item["tok_type_id"] : "",
                'item_id': id,
                'parent_item': Item["id"] ? Item["id"] : "",
                'is_child': true,
                'question_number': -1,
                'tok_section': -1,
                'detail_num': DetailNo,
                'user_display_name': localUserJson["display_name"] ? localUserJson["display_name"] : "",
                'user_photo': localUserJson["user_photo"] ? localUserJson["user_photo"] : "",
                'is_comment': false,
                'parent_user': userid
            }
            let feedId = id ? id : "";
            addReaction(reaction, feedId).then(res => {
                if (res.data["result"]) {
                    var { Comments } = this.state;
                    let updatedComments = new Array;
                    for (let x = 0; x < Comments.length; x++) {
                        let updatedComment = Comments[x];
                        if (Comments[x]["id"] == id && Comments[x]["pk"] == pk) {
                            let likeCount = updatedComment["likecount"] ? updatedComment["likecount"] : 0;
                            likeCount++;
                            updatedComment["likecount"] = likeCount;
                            updatedComment["userlike"] = true;
                            updatedComment["hideuserlikeloading"] = true;
                        }

                        updatedComments.push(updatedComment);
                    }
                    this.setState({
                        Comments: updatedComments
                    });
                }
            }).catch(err => {
            }).finally(() => {
            })
        }


    }

    removeLike(e) {
        const id = e.currentTarget.getAttribute("data-id");
        const pk = e.currentTarget.getAttribute("data-pk");

        deleteReaction(id, pk).then(res => {
            let { Comments } = this.state;
            if (res.data["result"]) {
                let updatedComments = new Array;
                for (let x = 0; x < Comments.length; x++) {
                    let updatedComment = Comments[x];
                    if (Comments[x]["reactionid"] == id && Comments[x]["reactionpk"] == pk) {
                        let likeCount = updatedComment["likecount"] ? updatedComment["likecount"] : 0;
                        likeCount--;
                        updatedComment["likecount"] = likeCount;
                        updatedComment["userlike"] = false;
                        updatedComment["reactionid"] = "";
                        updatedComment["reactionpk"] = "";
                    }

                    updatedComments.push(updatedComment);
                }
                this.setState({
                    Comments: updatedComments
                });
            }
        }).catch(err => {

        }).finally(() => { })
    }

    getUserLikeComment(id, pk) {
        var query = {
            item_id: id,
            kind: "like"
        }
        let itemExist = false;
        let likeReaction = {};
        getReactions(query, id).then(res => {
            if (res.data["result"]) {
                itemExist = true;
                likeReaction = cloneDeep(res.data["result"][0]);
            }
        }).catch(err => {
        }).finally(() => {
            var { Comments } = this.state;
            let updatedComments = new Array;
            for (let x = 0; x < Comments.length; x++) {
                let updatedComment = Comments[x];
                if (Comments[x]["id"] == id && Comments[x]["pk"] == pk) {
                    if (itemExist) {
                        let likeCount = updatedComment["likecount"] ? updatedComment["likecount"] : 0;
                        likeCount++;
                        updatedComment["likecount"] = likeCount;
                        updatedComment["userlike"] = true;
                        updatedComment["reactionid"] = likeReaction["id"];
                        updatedComment["reactionpk"] = likeReaction["pk"];
                    }
                    updatedComment["hideuserlikeloading"] = true;
                }

                updatedComments.push(updatedComment);
            }
            this.setState({
                Comments: updatedComments
            });
        })
    }
    viewCommentModal() {
        this.setState({
            ViewCommentModal: true
        });
    }

    closeCommentModal() {
        this.setState({
            ViewCommentModal: false
        });
    }
    isAuthenticated(data) {
        this.setState({
            authenticated: data,
            callLoginKey: ""
        });
    }
    callLogin() {
        this.setState({
            callLoginKey: randomString(10)
        });
    }
    closeIconModeModal() {
        this.setState({
            viewIconModeModal: false
        });
    }
    openIconModeModal() {
        var { disableInitialization, DetailNo } = this.state;
        if (disableInitialization) {
            this.getData();
        }
        console.log(DetailNo);
        this.setState({
            viewIconModeModal: true
        });
    }
    switchUi(e) {
        var { mobileView, oldUiView } = this.state;
        const mode = e.currentTarget.getAttribute("data-mode");
        const value = e.currentTarget.getAttribute("data-value");

        this.setState({
            mobileView: mode == "mobile" && value == "true" ? true : mode == "mobile" && value == "false" ? false : mobileView,
            oldUiView: mode == "oldui" && value == "true" ? true : mode == "oldui" && value == "false" ? false : oldUiView,
        });
    }
    render() {
        var { iconModeSize, mobileView, oldUiView, viewIconModeModal, iconMode, callLoginKey, authenticated, ViewCommentModal, Item, CommentCountLoader, CommentCount, InaccurateCommentActive, ItemId, Pk, DetailNo, EditCommentActive, UserCommentActive, CommentsViewActive, LoadActive, LoadMoreActive, Token, Comments, HideCommentButton, HideCommentStatus } = this.state;
        const localUser = localStorage.getItem("userdata");
        const quillComment = this.quillComment;
        const editComment = this.editComment;
        const inaccurateComment = this.inaccurateComment;
        const replyComment = this.replyComment;
        var avatar = "https://tokketcontent.blob.core.windows.net/tokketweb/default.png";
        iconMode = (oldUiView || mobileView) ? !(oldUiView || mobileView) : iconMode;
        var localUserJson = [];
        if (localUser) {
            localUserJson = JSON.parse(localUser);
            avatar = localUserJson["user_photo"] ? localUserJson["user_photo"] : "https://tokketcontent.blob.core.windows.net/tokketweb/default.png";
        }
        if (callLoginKey) {
            this.loginCallTrigger.current.click();
        }
        let body =
            <span onClickCapture={authenticated ? undefined : this.callLogin}>
                {CommentsViewActive ?
                    <Row>
                        <Col className={'row'} style={{ marginLeft: "32px", fontWeight: "600", fontSize: "18px" }} xs={12}>
                            <Col xs={'auto'} style={{ padding: "unset" }}>{CommentCount > 0 ? CommentCount : 0} Comment{CommentCount > 1 ? "s" : ""}</Col>

                            {CommentCountLoader ?
                                <Col xs={'auto'} style={{ display: "flex", alignItems: "center" }}><CircularProgress style={{ height: "20px", width: "20px" }} /></Col> :
                                <Col xs={'auto'} style={{ cursor: "pointer" }} onClick={!authenticated ? undefined : this.updateCheck}>
                                    <FontAwesomeIcon icon={faRefresh} />
                                </Col>}
                        </Col>

                    </Row> : ""}
                {UserCommentActive && !EditCommentActive && !InaccurateCommentActive ?
                    <Row style={{ alignItems: "center" }}>
                        <div onClick={!authenticated ? undefined : this.viewProfileSet} data-id={localUserJson["id"] ? localUserJson["id"] : ""} style={{ backgroundImage: `url(${avatar})`, height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                        <Col className={'quill-height54px'} style={{ wordBreak: "break-all" }}>
                            <ReactQuill ref={quillComment} modules={{ toolbar: null }} placeholder={"Add an comment..."} theme="snow" />
                        </Col>
                        <div style={{ backgroundImage: "url(https://tokketcontent.blob.core.windows.net/icons/TOKKET%20smiley%201B_yellow.png)", height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", marginRight: "calc(var(--bs-gutter-x) * .5)" }}></div>
                        <Col className={'col-auto'} onClick={!authenticated ? undefined : this.sendUserComment} style={{ backgroundColor: "#007bff", cursor: "pointer", borderRadius: "0.25rem", height: "40px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <SendIcon style={{ color: "white" }} />
                        </Col>
                    </Row> :
                    <>
                        {InaccurateCommentActive ?
                            <Row style={{ alignItems: "center" }}>
                                <div style={{ backgroundImage: `url(${avatar})`, height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                <Col className={'quill-height70px'} style={{ wordBreak: "break-all" }}>
                                    <ReactQuill value={Comment["text"] ? Comment["text"] : ""} ref={inaccurateComment} modules={{ toolbar: null }} placeholder={"Add an Inaccurate..."} theme="snow" />
                                </Col>
                                <Col className={'col-auto'}>
                                    <Col onClick={!authenticated ? undefined : this.inaccurateCommentSend} data-id={Comment["id"]} data-pk={Comment["pk"]} style={{ backgroundColor: "#007bff", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px" }}>
                                        <SendIcon style={{ color: "white" }} />
                                    </Col>
                                    <Col onClick={!authenticated ? undefined : this.inaccurateCommentCancel} style={{ backgroundColor: "#c62828", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <ClearIcon style={{ color: "white" }} />
                                    </Col>
                                </Col>
                            </Row> : ""
                        }
                    </>}
                {LoadActive && !HideCommentStatus ?
                    <WaitSpinner /> :
                    <>
                        {CommentsViewActive && Comments.length && !HideCommentStatus ?
                            <>
                                <Modal className={'dark-background-2'} fullscreen={true} show={ViewCommentModal} onHide={this.closeCommentModal} style={{ zIndex: "1408" }}>
                                    <Modal.Header className={'dark-background-2'}>
                                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                            <Typography fontWeight={"bold"} marginRight={"auto"}>Comments</Typography>

                                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={!authenticated ? undefined : this.closeCommentModal} />
                                        </div>

                                    </Modal.Header>
                                    <Modal.Body className={'dark-background-2'}>
                                        <div className={'comment-container'} onScroll={this.loadMoreScroll}>
                                            {Comments.map((Comment) => (
                                                <Row style={{ fontSize: "14px" }}>
                                                    {Comment["edit-comment"] ?
                                                        <>
                                                            <div style={{ backgroundImage: `url(${avatar})`, height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                                            <Col className={'quill-height70px'} style={{ wordBreak: "break-all" }}>
                                                                <ReactQuill value={Comment["text"] ? Comment["text"] : ""} ref={editComment} modules={{ toolbar: null }} placeholder={"Edit comment..."} theme="snow" />
                                                            </Col>
                                                            <Col className={'col-auto'}>
                                                                <Col onClick={!authenticated ? undefined : this.updateEditComment} data-id={Comment.id} data-pk={Comment.pk} style={{ backgroundColor: "#007bff", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px" }}>
                                                                    <SendIcon style={{ color: "white" }} />
                                                                </Col>
                                                                <Col onClick={!authenticated ? undefined : this.cancelEditComment} style={{ backgroundColor: "#c62828", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <ClearIcon style={{ color: "white" }} />
                                                                </Col>
                                                            </Col>

                                                        </> :
                                                        <>
                                                            <div onClick={!authenticated ? undefined : this.viewProfileSet} data-id={Comment["user_id"]} style={{ backgroundImage: Comment["user_photo"] ? `url(${Comment["user_photo"]})` : "", borderRadius: "100%", height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                                            <Row className={'col'} style={{ fontSize: "13px", alignItems: "center" }}>
                                                                <Col xs={'auto'}>
                                                                    <b>{Comment["user_display_name"] ? Comment["user_display_name"] : ""}</b>
                                                                </Col>
                                                                <Col style={{ marginLeft: "5px", fontSize: "11px", color: "#778899", padding: "unset" }}>
                                                                    {Comment["created_time"] ? Moment(Comment["created_time"]).format('MM/DD/YYYY') : ""}
                                                                </Col>
                                                                <Dropdown style={{ width: "fit-content" }}>
                                                                    <Dropdown.Toggle as={CustomToggle} className={'dropdown-remove'}>
                                                                        <MoreVertIcon />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={!authenticated ? undefined : this.setEditComment} data-id={Comment.id} data-pk={Comment.pk}>Edit</Dropdown.Item>
                                                                        <Dropdown.Item onClick={!authenticated ? undefined : this.deleteComment} data-id={Comment.id} data-pk={Comment.pk}>Delete</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Row>
                                                            <Row className={'col-12'} style={{ marginLeft: "32px" }}>
                                                                {Comment["kind"] ?
                                                                    <>
                                                                        {Comment["kind"].toLowerCase() == "accurate" ?
                                                                            <Col xs={'auto'} style={{ padding: "unset" }}><Badge bg="success">Accurate</Badge></Col> : ""
                                                                        }
                                                                        {Comment["kind"].toLowerCase() == "inaccurate" ?
                                                                            <Col xs={'auto'} style={{ padding: "unset" }}><Badge bg="danger">Inaccurate</Badge></Col> : ""
                                                                        }
                                                                    </> : ""
                                                                }
                                                                <div style={{ padding: "unset" }}>{Comment["text"] ? Comment["text"] : ""}</div>
                                                                <Row style={{ color: "#acb4c2", fontWeight: "500" }}>
                                                                    <Col xs={'auto'} style={{ padding: "unset", display: "-webkit-box", color: Comment["userlike"] ? "#e34d4d" : "#acb4c2" }}>
                                                                        <div style={{ cursor: "pointer" }} onClick={!authenticated ? undefined : Comment["userlike"] ? this.removeLike : this.addLike} data-userid={Comment["user_id"]} data-id={Comment.id} data-pk={Comment.pk}>
                                                                            <FontAwesomeIcon icon={faHeart} />
                                                                        </div>
                                                                        <div style={{ marginLeft: "5px" }}> {Comment["likecount"] ? Comment["likecount"] : 0}</div>
                                                                    </Col>
                                                                    <Col xs={'auto'} style={{ padding: "unset", marginLeft: "5px", cursor: "pointer" }} onClick={!authenticated ? undefined : this.setReplyComment} data-id={Comment.id} data-pk={Comment.pk}>REPLY</Col>
                                                                </Row>
                                                                {Comment["reply-comment"] ?
                                                                    <Row>
                                                                        <div style={{ backgroundImage: `url(${avatar})`, height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", borderRadius: "100%" }}></div>
                                                                        <Col className={'quill-height70px'} style={{ wordBreak: "break-all" }}>
                                                                            <ReactQuill ref={replyComment} modules={{ toolbar: null }} placeholder={"Reply..."} theme="snow" />
                                                                        </Col>
                                                                        <Col className={'col-auto'}>
                                                                            <Col onClick={!authenticated ? undefined : this.sendReplyComment} data-id={Comment.id} data-pk={Comment.pk} style={{ backgroundColor: "#007bff", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px" }}>
                                                                                <SendIcon style={{ color: "white" }} />
                                                                            </Col>
                                                                            <Col onClick={!authenticated ? undefined : this.cancelReplyComment} style={{ backgroundColor: "#c62828", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                <ClearIcon style={{ color: "white" }} />
                                                                            </Col>
                                                                        </Col>
                                                                    </Row> : ""}
                                                                {!Comment["replyset"] ?
                                                                    <WaitSpinner /> : ""
                                                                }
                                                                {Comment["children"] ?
                                                                    <>
                                                                        <div style={{ cursor: "pointer", fontWeight: "600", fontSize: "13px", margin: "unset", padding: "unset" }} data-id={Comment["id"]} data-pk={Comment["pk"]} onClick={!authenticated ? undefined : this.showHideReplies}>
                                                                            <ArrowDropDownIcon style={{ paddingBottom: Comment["hidechildren"] ? "6px" : "", height: "2rem", paddingTop: Comment["hidechildren"] ? "" : "2px", transform: Comment["hidechildren"] ? "" : "rotate(180deg)" }} />
                                                                            <span>{Comment["hidechildren"] ? "Show" : "Hide"} {Comment.children.length} repl{Comment.children.length > 1 ? "ies" : "y"}</span>
                                                                        </div>
                                                                        {Comment["hidechildren"] ? "" :
                                                                            <>
                                                                                {Comment.children.map((Reply) => (
                                                                                    <Row style={{ paddingRight: "7px" }}>
                                                                                        <div onClick={!authenticated ? undefined : this.viewProfileSet} data-id={Reply["user_id"]} style={{ backgroundImage: Reply["user_photo"] ? `url(${Reply["user_photo"]})` : "", borderRadius: "100%", height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                                                                        <Row className={'col'} style={{ fontSize: "13px", alignItems: "center" }}>
                                                                                            <Col xs={'auto'}>
                                                                                                <b>{Reply["user_display_name"] ? Reply["user_display_name"] : ""}</b>
                                                                                            </Col>
                                                                                            <Col style={{ marginLeft: "5px", fontSize: "11px", color: "#778899", padding: "unset" }}>
                                                                                                {Reply["created_time"] ? Moment(Reply["created_time"]).format('MM/DD/YYYY') : ""}
                                                                                            </Col>
                                                                                            {/*
                                                                    <Dropdown style={{ width: "fit-content" }}>
                                                                        <Dropdown.Toggle as={CustomToggle} className={'dropdown-remove'}>
                                                                            <MoreVertIcon />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={!authenticated? {}:this.setEditComment} data-id={Reply.id} data-pk={Reply.pk}>Edit</Dropdown.Item>
                                                                            <Dropdown.Item onClick={!authenticated? {}:this.deleteComment} data-id={Reply.id} data-pk={Reply.pk}>Delete</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                    */}
                                                                                        </Row>
                                                                                        <Row className={'col-12'} style={{ marginLeft: "32px" }}>
                                                                                            <div style={{ padding: "unset" }}>{Reply["text"] ? Reply["text"] : ""}</div>
                                                                                            <Row style={{ color: "#acb4c2", fontWeight: "500" }}>
                                                                                                <Col xs={'auto'} style={{ padding: "unset", display: "-webkit-box" }}>
                                                                                                    <div style={{ cursor: "pointer" }}>
                                                                                                        <FontAwesomeIcon icon={faHeart} />
                                                                                                    </div>
                                                                                                    <div style={{ marginLeft: "5px" }}> 0</div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Row>
                                                                                    </Row>
                                                                                ))}
                                                                                {Comment["children_token"] ?
                                                                                    <Button onClick={!authenticated ? undefined : this.loadMoreReplies} data-id={Comment.id} data-pk={Comment.pk}>Load More</Button> : ""}
                                                                            </>
                                                                        }
                                                                    </> : ""
                                                                }
                                                            </Row>
                                                        </>
                                                    }

                                                </Row>
                                            ))}
                                            {LoadMoreActive ?
                                                <WaitSpinner /> : ""
                                            }
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <ButtonBootsrap variant={'primary'} className={'comment-call-mobile-btn'} onClick={!authenticated ? undefined : this.viewCommentModal}>
                                    View Comments
                                </ButtonBootsrap>
                                <div className={'comment-container comment-containe-main'} onScroll={this.loadMoreScroll}>
                                    {Comments.map((Comment) => (
                                        <Row style={{ fontSize: "14px" }}>
                                            {Comment["edit-comment"] ?
                                                <>
                                                    <div style={{ backgroundImage: `url(${avatar})`, height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                                    <Col className={'quill-height70px'} style={{ wordBreak: "break-all" }}>
                                                        <ReactQuill value={Comment["text"] ? Comment["text"] : ""} ref={editComment} modules={{ toolbar: null }} placeholder={"Edit comment..."} theme="snow" />
                                                    </Col>
                                                    <Col className={'col-auto'}>
                                                        <Col onClick={!authenticated ? undefined : this.updateEditComment} data-id={Comment.id} data-pk={Comment.pk} style={{ backgroundColor: "#007bff", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px" }}>
                                                            <SendIcon style={{ color: "white" }} />
                                                        </Col>
                                                        <Col onClick={!authenticated ? undefined : this.cancelEditComment} style={{ backgroundColor: "#c62828", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <ClearIcon style={{ color: "white" }} />
                                                        </Col>
                                                    </Col>

                                                </> :
                                                <>
                                                    <div onClick={!authenticated ? undefined : this.viewProfileSet} data-id={Comment["user_id"]} style={{ backgroundImage: Comment["user_photo"] ? `url(${Comment["user_photo"]})` : "", borderRadius: "100%", height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                                    <Row className={'col'} style={{ fontSize: "13px", alignItems: "center" }}>
                                                        <Col xs={'auto'}>
                                                            <b>{Comment["user_display_name"] ? Comment["user_display_name"] : ""}</b>
                                                        </Col>
                                                        <Col style={{ marginLeft: "5px", fontSize: "11px", color: "#778899", padding: "unset" }}>
                                                            {Comment["created_time"] ? Moment(Comment["created_time"]).format('MM/DD/YYYY') : ""}
                                                        </Col>
                                                        <Dropdown style={{ width: "fit-content" }}>
                                                            <Dropdown.Toggle as={CustomToggle} className={'dropdown-remove'}>
                                                                <MoreVertIcon />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={!authenticated ? undefined : this.setEditComment} data-id={Comment.id} data-pk={Comment.pk}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={!authenticated ? undefined : this.deleteComment} data-id={Comment.id} data-pk={Comment.pk}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Row>
                                                    <Row className={'col-12'} style={{ marginLeft: "32px" }}>
                                                        {Comment["kind"] ?
                                                            <>
                                                                {Comment["kind"].toLowerCase() == "accurate" ?
                                                                    <Col xs={'auto'} style={{ padding: "unset" }}><Badge bg="success">Accurate</Badge></Col> : ""
                                                                }
                                                                {Comment["kind"].toLowerCase() == "inaccurate" ?
                                                                    <Col xs={'auto'} style={{ padding: "unset" }}><Badge bg="danger">Inaccurate</Badge></Col> : ""
                                                                }
                                                            </> : ""
                                                        }
                                                        <div style={{ padding: "unset" }}>{Comment["text"] ? Comment["text"] : ""}</div>
                                                        <Row style={{ color: "#acb4c2", fontWeight: "500" }}>
                                                            <Col xs={'auto'} style={{ padding: "unset", display: "-webkit-box", color: Comment["userlike"] ? "#e34d4d" : "#acb4c2" }}>
                                                                <div style={{ cursor: "pointer" }} onClick={!authenticated ? undefined : Comment["userlike"] ? this.removeLike : this.addLike} data-userid={Comment["user_id"]} data-id={Comment["userlike"] ? Comment["reactionid"] : Comment.id} data-pk={Comment["userlike"] ? Comment["reactionpk"] : Comment.pk}>
                                                                    <FontAwesomeIcon icon={faHeart} />
                                                                </div>
                                                                <div style={{ marginLeft: "5px" }}> {Comment["likecount"] ? Comment["likecount"] : 0}</div>
                                                            </Col>
                                                            <Col xs={'auto'} style={{ padding: "unset", marginLeft: "5px", cursor: "pointer" }} onClick={!authenticated ? undefined : this.setReplyComment} data-id={Comment.id} data-pk={Comment.pk}>REPLY</Col>
                                                        </Row>
                                                        {Comment["reply-comment"] ?
                                                            <Row>
                                                                <div style={{ backgroundImage: `url(${avatar})`, height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", borderRadius: "100%" }}></div>
                                                                <Col className={'quill-height70px'} style={{ wordBreak: "break-all" }}>
                                                                    <ReactQuill ref={replyComment} modules={{ toolbar: null }} placeholder={"Reply..."} theme="snow" />
                                                                </Col>
                                                                <Col className={'col-auto'}>
                                                                    <Col onClick={!authenticated ? undefined : this.sendReplyComment} data-id={Comment.id} data-pk={Comment.pk} style={{ backgroundColor: "#007bff", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "5px" }}>
                                                                        <SendIcon style={{ color: "white" }} />
                                                                    </Col>
                                                                    <Col onClick={!authenticated ? undefined : this.cancelReplyComment} style={{ backgroundColor: "#c62828", cursor: "pointer", borderRadius: "0.25rem", height: "33px", width: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <ClearIcon style={{ color: "white" }} />
                                                                    </Col>
                                                                </Col>
                                                            </Row> : ""}
                                                        {!Comment["replyset"] ?
                                                            <WaitSpinner /> : ""
                                                        }
                                                        {Comment["children"] ?
                                                            <>
                                                                {Comment["children"].length > 0 ?
                                                                    <div style={{ cursor: "pointer", fontWeight: "600", fontSize: "13px", margin: "unset", padding: "unset" }} data-id={Comment["id"]} data-pk={Comment["pk"]} onClick={!authenticated ? undefined : this.showHideReplies}>
                                                                        <ArrowDropDownIcon style={{ paddingBottom: Comment["hidechildren"] ? "6px" : "", height: "2rem", paddingTop: Comment["hidechildren"] ? "" : "2px", transform: Comment["hidechildren"] ? "" : "rotate(180deg)" }} />
                                                                        <span>{Comment["hidechildren"] ? "Show" : "Hide"} {Comment.children.length} repl{Comment.children.length > 1 ? "ies" : "y"}</span>
                                                                    </div> : ""
                                                                }
                                                                {Comment["hidechildren"] ? "" :
                                                                    <>
                                                                        {Comment.children.map((Reply) => (
                                                                            <Row style={{ paddingRight: "7px" }}>
                                                                                <div onClick={!authenticated ? undefined : this.viewProfileSet} data-id={Reply["user_id"]} style={{ backgroundImage: Reply["user_photo"] ? `url(${Reply["user_photo"]})` : "", borderRadius: "100%", height: "32px", width: "32px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                                                                                <Row className={'col'} style={{ fontSize: "13px", alignItems: "center" }}>
                                                                                    <Col xs={'auto'}>
                                                                                        <b>{Reply["user_display_name"] ? Reply["user_display_name"] : ""}</b>
                                                                                    </Col>
                                                                                    <Col style={{ marginLeft: "5px", fontSize: "11px", color: "#778899", padding: "unset" }}>
                                                                                        {Reply["created_time"] ? Moment(Reply["created_time"]).format('MM/DD/YYYY') : ""}
                                                                                    </Col>
                                                                                    {/*
                                                                    <Dropdown style={{ width: "fit-content" }}>
                                                                        <Dropdown.Toggle as={CustomToggle} className={'dropdown-remove'}>
                                                                            <MoreVertIcon />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={!authenticated? {}:this.setEditComment} data-id={Reply.id} data-pk={Reply.pk}>Edit</Dropdown.Item>
                                                                            <Dropdown.Item onClick={!authenticated? {}:this.deleteComment} data-id={Reply.id} data-pk={Reply.pk}>Delete</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                    */}
                                                                                </Row>
                                                                                <Row className={'col-12'} style={{ marginLeft: "32px" }}>
                                                                                    <div style={{ padding: "unset" }}>{Reply["text"] ? Reply["text"] : ""}</div>
                                                                                    <Row style={{ color: "#acb4c2", fontWeight: "500" }}>
                                                                                        <Col xs={'auto'} style={{ padding: "unset", display: "-webkit-box" }}>
                                                                                            <div style={{ cursor: "pointer" }}>
                                                                                                <FontAwesomeIcon icon={faHeart} />
                                                                                            </div>
                                                                                            <div style={{ marginLeft: "5px" }}> 0</div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Row>
                                                                            </Row>
                                                                        ))}
                                                                        {Comment["children_token"] ?
                                                                            <Button onClick={!authenticated ? undefined : this.loadMoreReplies} data-id={Comment.id} data-pk={Comment.pk}>Load More</Button> : ""}
                                                                    </>
                                                                }
                                                            </> : ""
                                                        }
                                                    </Row>
                                                </>
                                            }

                                        </Row>
                                    ))}
                                    {LoadMoreActive ?
                                        <WaitSpinner /> : ""
                                    }
                                </div>
                            </>
                            : ""}
                    </>
                }
                {HideCommentButton ?
                    <div style={{ textAlign: "-webkit-center" as any }}>
                        <Button variant={'outlined'} color={'error'} style={{ fontSize: "14px" }} onClick={!authenticated ? undefined : this.showComments}>{HideCommentStatus ? "Show Comments" : "Hide Comments"}</Button>
                    </div> : ""
                }
            </span>;
        return (
            <>
                {/*
                Removing causing errors.
                <Login loginCallTrigger={this.loginCallTrigger} />
                */}
                <IsAuthenticated isAuthenticated={this.isAuthenticated} />
                <Button hidden ref={this.commentSwitchUiRef} data-mode data-value onClick={this.switchUi}></Button>
                <Button hidden onClick={!authenticated ? undefined : this.inaccurateCommentSet} className={'inaccurate-set'} data-id={ItemId} data-pk={Pk} data-detailno={DetailNo}>Trigger Inaccurate</Button>
                <Button hidden data-commentcount ref={this.commentCounterRef} onClick={!authenticated ? undefined : this.setCommentCounter}>Set Comment Count</Button>
                <div className={"exception"}>
                    <Toaster />
                </div>
                {iconMode ?
                    <>
                        <div style={{ cursor: "pointer" }} onClick={this.openIconModeModal}>
                            <MessageIcon fontSize={iconModeSize} />
                        </div>
                        <Modal className={'dark-background-2'} size={"lg"} show={viewIconModeModal} onHide={this.closeIconModeModal} style={{ zIndex: "1407" }}>
                            <Modal.Header className={'dark-background-2'}>
                                <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                    <Typography fontWeight={"bold"} marginRight={"auto"}>Comments</Typography>

                                    <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={!authenticated ? undefined : this.closeIconModeModal} />
                                </div>

                            </Modal.Header>
                            <Modal.Body className={'dark-background-2'}>
                                {body}
                            </Modal.Body>
                        </Modal>
                    </> :
                    body}

            </>
        );
    }
}

export default Comments;