import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState, useEffect } from 'react';
import ClassTokIcon from './ClassTokIcon'
import './css/ClassTokNavbar.css';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import '../styles.css';
import { Modal } from 'react-bootstrap';
import { createRef } from 'react';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassSetsContainer from './ClassSetsContainer';
import { useRef } from 'react';

const drawerWidth = 270;
const classTokOptions = [
    //{ title: 'Public Toks', href: '/classtoks', icon: "publictok", state: 1 },
    { title: 'My Toks', href: '/classtoks', icon: "mytoks", state: 2 },
    {
        title: 'Class Channels', href: '/tokchannels/academic', icon: "tokchannel", state: 1,
    },
    {
        title: 'Tok Paks', href: '', icon: "tokpaks", state: 1,
        options: [
            { title: 'Add Tok Paks', modal: "" },
            { title: 'View My Tok Paks', href: '/tokpaks', state: 1 },
            //{ title: 'View Public Tok Paks', href: '/tokpaks', state: 2 },
        ]
    },
    {
        title: 'Sets', href: '/classsets', icon: "classsets", state: 1,
        options: [
            { title: 'View My Sets', href: '/classsets', state: 2 },
            //{ title: 'View Public Sets', href: '/classsets', state: 1 },
        ]
    },
    {
        title: 'Study Games', href: '', icon: "studygames", state: 1,
        options: [
            { title: 'Tok Cards', modal: "tokcards", state: 1 },
            { title: 'Tok Choice', modal: "tokchoice", state: 1 },
            { title: 'Tok Match', modal: "tokmatch", state: 1 },
        ]
    },
    { title: 'Tok Groups', href: '/classgroups', icon: "groups", state: 1 },
    { title: 'Guides', href: '/guides', icon: "guides", state: 1 },
    { title: 'Peerbook', href: '/yearbook', icon: "peerbooks", state: 1 },
    { title: 'Opportunities', href: '/opportunitytok', icon: "opportunities", state: 1 },
    { title: 'Training', href: '/training', icon: "training", state: 1 },
    { title: 'Tok Handles', href: '/tokhandles', icon: "tokhandles", state: 1 },
];
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 20px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 20px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
interface AppBarProps {
    open: boolean;
}
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
interface DrawerProps {
    open: boolean;
}
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<DrawerProps>
(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': open ? openedMixin(theme) : closedMixin(theme),
    }),
);
const path = window.location.pathname;
const ClassTokNavbar = (props) => {
    const theme = useTheme();
    var tempState = 1;
    const [open, setOpen] = useState(true);
    if (props.state) {
        tempState = props.state;
    }
    const [state, setState] = useState(tempState);
    function renderClassTokPage(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        var btn = event.currentTarget;
        var classtokNavDropBtns = document.querySelectorAll(".classtok-nav .classtok-nav-btn .dropdown-menu");
        try {
            classtokNavDropBtns.forEach(classtokNavBtn => {
                const parentNode = classtokNavBtn.parentNode;
                if (parentNode instanceof HTMLElement) {
                    parentNode.classList.remove("display-dropdown");
                }
            });
        } catch (err) { }
        if (btn.getAttribute("data-options") == "true") {
            btn.parentNode.querySelector(".dropdown-menu").parentNode.classList.add("display-dropdown");
        } else {
            var classtokNavBtns = document.querySelectorAll(".classtok-nav .classtok-nav-btn svg");
            classtokNavBtns.forEach(classtokNavBtn => {
                const navBtn = classtokNavBtn as HTMLElement;
                navBtn.style.filter = "";
            });

            try {
                document.querySelector(".classtok-nav .classtok-nav-btn.active").classList.remove('active');
            } catch (err) { }
            btn.parentNode.classList.add('active');
            if (btn.querySelector("svg")) {
                btn.querySelector("svg").style.filter = "invert(100%)";
            }
        }
        if (btn.getAttribute("href")) {
            props.setNavigationData({
                href: btn.getAttribute("href"),
                state: Number(btn.getAttribute("data-state")),
            });
        }
    }
    function renderSubClassTokPage(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        var btn = event.currentTarget;
        var classtokNavDropBtns = document.querySelectorAll(".classtok-nav .classtok-nav-btn .dropdown-menu");
        try {
            classtokNavDropBtns.forEach(classtokNavBtn => {
                const parentNode = classtokNavBtn.parentNode;
                if (parentNode instanceof HTMLElement) {
                    parentNode.classList.remove("display-dropdown");
                }
            });
        } catch (err) { }
        var classtokNavBtns = document.querySelectorAll(".classtok-nav .classtok-nav-btn svg");
        classtokNavBtns.forEach(classtokNavBtn => (classtokNavBtn as HTMLElement).style.filter = "");

        try {
            document.querySelector(".classtok-nav .classtok-nav-btn.active").classList.remove('active');
        } catch (err) { }
        btn.parentNode.parentNode.classList.add('active');
        if (btn.parentNode.parentNode.querySelector("svg")) {
            btn.parentNode.parentNode.querySelector("svg").style.filter = "invert(100%)";
        }

        if (btn.getAttribute("href")) {
            props.setNavigationData({
                href: btn.getAttribute("href"),
                state: Number(btn.getAttribute("data-state")),
                substate: Number(btn.getAttribute("data-substate"))
            });
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
        props.sendData(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        props.sendData(false);
    };
    const [marginTopNav, setMarginTopNav] = useState(85);
    {/*useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])
    const listenToScroll = () => {
        let heightToHideFrom = 85;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if ((heightToHideFrom - winScroll) >= 0) {
            setMarginTopNav(heightToHideFrom - winScroll);
        } else {
            setMarginTopNav(0)
        }
    };
    */}
    window.onclick = function (event) {
        if (!event.target.matches('.droptokket-btn')) {
            var droptokketdownContent = document.querySelectorAll(".droptokketdown-content");
            droptokketdownContent.forEach((classtokNavBtn: HTMLElement) => classtokNavBtn.style.display = "");
        }
    }
    const [showStudyGames, setShowStudyGames] = useState(false);
    const [studyGameMode, setStudyGameMode] = useState("");
    const studyGameModeRef = useRef();
    const modalCall = (e) => {
        let modal = e.target.getAttribute("data-modalcall");
        if (modal == "tokchoice" || modal == "tokcards" || modal == "tokmatch") {
            try {
                setTimeout(function () {
                    (studyGameModeRef.current as HTMLButtonElement).setAttribute("data-mode", modal);
                    (studyGameModeRef.current as HTMLButtonElement).click();
                }, 100);


            } catch (err) { }
            setStudyGameMode(modal)
            setShowStudyGames(true);
        }
    };
    const hideStudyGames = () => {
        setShowStudyGames(false);
    }
    return (
        <Box className={"text-color-white dark-background-1"} sx={{ display: 'flex' }}>
            <Modal size='lg' show={showStudyGames} onHide={hideStudyGames} style={{ zIndex: "1208" }}>
                <Modal.Header className={'text-color-white dark-background-2'}>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <Typography fontWeight={"bold"} marginRight={"auto"}>{studyGameMode == "tokchoice" || studyGameMode == "tokcards" ? <>Pick a set for {studyGameMode == "tokchoice" ? "Tok Choice" : "Tok Cards"}</> : "Study Games"}</Typography>

                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={hideStudyGames} />
                    </div>
                </Modal.Header>
                <Modal.Body className={'text-color-white dark-background-2'}>
                    <ClassSetsContainer PublicFeed={undefined} query={undefined} groupid={undefined} HideNavbar={undefined} communityid={undefined} level1={undefined} level2={undefined} level3={undefined} addClassSetRef={undefined} addExistingClassSetTransferRef={undefined} studyGameMode={true} studyGameModeRef={studyGameModeRef} customTitleEnabled={true} customTitle={''} Navbar={true} MenuKind={false} />
                </Modal.Body>
            </Modal>
            <CssBaseline />
            <Drawer variant="permanent" open={open} className={"classtok-nav-scrollbar text-color-white dark-background-1"}>
                <List className={"classtok-nav text-color-white dark-background-1"} style={{ marginTop: `${marginTopNav}px` }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                        style={{ width: "100%", margin: "auto", borderRadius: "unset" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Divider style={{ height: "50px" }} sx={{ display: open ? 'block' : 'none' }} />
                    <IconButton onClick={handleDrawerClose} style={{ position: "absolute", right: 0, top: 0, borderRadius: "unset" }} sx={{ display: open ? 'block' : 'none' }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    {classTokOptions.map((classTokOption, index) => (
                        <ListItem key={classTokOption.title} disablePadding sx={{ display: 'block' }} className={classTokOption.href == path && classTokOption.state == state ? "classtok-nav-btn exception active" : "classtok-nav-btn exception"}>
                            <ListItemButton className={'droptokket-btn'}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                style={{ border: "2.5px solid", borderColor: "black" }}
                                onClick={renderClassTokPage} data-state={classTokOption.state} data-options={classTokOption.options && classTokOption.state == state ? true : false} data-ogfill={"black"} href={classTokOption.href}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ClassTokIcon icon={classTokOption.icon} color={"black"} active={classTokOption.href == path && classTokOption.state == state ? "active" : ""} />
                                </ListItemIcon>
                                <ListItemText className={'text-color-white'} sx={{ opacity: open ? 1 : 0 }}>
                                    {classTokOption.title}
                                </ListItemText>
                                {classTokOption.options ?
                                    <>
                                        <ExpandMore className={'expand-more'} />
                                        <ExpandLess className={'expand-less'} />
                                    </>
                                    : ""}
                            </ListItemButton>
                            {classTokOption.options ?
                                <div className={'dropdown-menu droptokketdown-content'}>
                                    {classTokOption.options.map((option, optionIndex) => (
                                        <a key={`${classTokOption.icon}-${classTokOption.state}-${option.state}-${optionIndex}`} onClick={option["modal"] ? modalCall : renderSubClassTokPage} data-modalcall={option["modal"] ? option["modal"] : ""} data-state={classTokOption.state} data-substate={option.state} className={'dropdown-item'} href={option.href}>
                                            {option.title}
                                        </a>
                                    ))}
                                </div> : ""}
                        </ListItem>
                    ))}
                </List>

            </Drawer>
        </Box>
    );
}

export default ClassTokNavbar