import React from 'react'
import '../styles.css'
import './css/GroupView.css';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const FileLoader = (props) => {
    const name = props.name;
    var fileRef = props.fileRef;
    const width = props.width;
    const lineClamp = props.lineClamp;
    const removeButton = props.removeButton;
    const removeFile = () => {
        props.removeFile(true);
    };
    return (
        <div className={`classtok-doc ${fileRef.replace(/[^a-zA-Z0-9]/g, '')}`} style={{width: width? width : ""}}>
            <div className={'remove-btn'} onClick={removeFile}><FontAwesomeIcon icon={faTimes}/></div>
            <img className={'classtok-doc-icon'} src="https://tokketcontent.blob.core.windows.net/tokketweb/doc.png" alt="File Icon" />
            <div>
                <div className={lineClamp? 'classtok-doc-name line-clamp line-clamp-' + lineClamp :'classtok-doc-name'}>{name? name: ""}</div>
                <LinearProgress className={'classgroup-progress-bar'} color='success' variant="determinate" value={0} />
            </div>
        </div>
    )
}

export default FileLoader;