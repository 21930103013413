import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from '@mui/system';
import './../App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { getGroup } from '../services/classServices';
import { getToken } from '../services/accountServices';
import WaitSpinner from './WaitSpinner';
import GroupView from './GroupView';
import { Group } from '../interfaces/GroupInterface';
interface States {
    item: Group;
    id: string;
    pk: string;
    DataisLoaded: boolean;
}

interface Props {
    group: Group;
    id: string;
    pk: string;
}
class GroupInfoView extends React.Component<Props, States> {

    // Constructor
    constructor(props) {
        super(props);
        const id = props.id;
        const pk = props.pk;
        var preloadedgroup = props.group;
        this.state = {
            item: preloadedgroup ? preloadedgroup : null,
            id: id,
            pk: pk,
            DataisLoaded: preloadedgroup ? true : false
        };
    }

    componentDidMount() {
        const { DataisLoaded } = this.state;
        if (!DataisLoaded) {
            this.getData();
        }
    }

    async getData() {
        const { id, pk } = this.state;
        await getToken();
        const response = await getGroup(id, pk);
        if (response) {
            if (response.status == 200) {
                this.setState({
                    DataisLoaded: true,
                    item: response.data.resource
                });
            }
        }
    }

    render() {
        const { DataisLoaded, item } = this.state;
        if (!DataisLoaded) return <WaitSpinner />;

        return (
            <GroupView group={item} directLoad={true} />
        );
    }
}
export default GroupInfoView; 