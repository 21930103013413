import React from 'react'
import '../styles.css'
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { getGroups } from '../services/classServices';
import { getToken } from '../services/accountServices';
import CommunityTile from './CommunityTile';
import CommunityTileView from './CommunityView';
import { createRef } from 'react';
import arrayShuffle from 'array-shuffle';
import TextField from '@mui/material/TextField';
import ButtonReact from 'react-bootstrap/Button';
import { faMagnifyingGlass, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddEditClassGroup from './AddEditClassGroup';
import { upperCasesFirstLetter } from '../services/helperTools';
import { Group } from '../interfaces/GroupInterface';

const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
const shuffleColors = arrayShuffle(colors);
var colorCounter = -1;
interface States {
    items: Group[];
    LoadActive: boolean;
    continuationToken: string;
    query: {
        [key: string]: any;
    };
    LoadMoreActive: boolean;
    CommunityView: boolean;
    Community: string;
    Navbar: boolean;
    NavbarProxyActive: boolean;
    Level0: string;
    Level1: string;
    Level2: string;
    Level3: string;
    resultData: {
        [key: string]: any;
    };
}

interface Props {
    Navbar: boolean;
    query: {
        [key: string]: any;
    };
    communityTitleBar: React.RefObject<any>;
    NavbarProxyActive: boolean;
}
class CommunitiesContainer extends React.Component<Props, States> {
    communityTitleBar: React.RefObject<any>;
    searchInput: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query ? props.query : {},
            LoadMoreActive: false,
            CommunityView: false,
            Community: "",
            Navbar: props.Navbar ? props.Navbar : false,
            NavbarProxyActive: props.NavbarProxyActive ? props.NavbarProxyActive : false,
            Level0: props.query ? props.query["level1"] ? props.query["level1"] : "" : "",
            Level1: props.query ? props.query["level2"] ? props.query["level2"] : "" : "",
            Level2: props.query ? props.query["level3"] ? props.query["level3"] : "" : "",
            Level3: props.query ? props.query["level4"] ? props.query["level4"] : "" : "",
            resultData: {}
        };

        this.reload = this.reload.bind(this);
        this.communityView = this.communityView.bind(this);
        this.communityViewClose = this.communityViewClose.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.addCommunityList = this.addCommunityList.bind(this);
        this.communityTitleBar = props.communityTitleBar;
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    communityView(card) {
        this.setState({
            CommunityView: true,
            Community: card
        });
    }
    communityViewClose() {
        this.setState({
            CommunityView: false,
            Community: ""
        });
    }
    async getData(customQuery) {
        var { query, items, Level0, Level1, Level2, Level3 } = this.state;
        await getToken();
        if (customQuery) {
            query = customQuery;
        }
        const response = await getGroups({
            level0: Level0 ? upperCasesFirstLetter(Level0) : "",
            level1: Level1 ? upperCasesFirstLetter(Level1) : "",
            level2: Level2 ? upperCasesFirstLetter(Level2) : "",
            //remove label once addgroup is adjusted
            label: "classgroup",
            items: "channelgroups"
        }, "channelgroups");
        var itemResult = response.data["result"] ? response.data["result"] : new Array;
        if (customQuery) {
            itemResult = items.concat(itemResult);
        }
        this.setState({
            resultData: response.data,
            continuationToken: response.data["pagination"] ? response.data["pagination"] : "",
            items: itemResult,
            LoadActive: false,
            LoadMoreActive: false
        });
    }
    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    loadmore() {
        this.setState({
            LoadMoreActive: true
        });
        var { query, continuationToken } = this.state;
        if (query) {
            query.pagination = continuationToken;
        } else {
            query = { "pagination": continuationToken }
        }

        this.getData(query)
    }
    addCommunityList(data) {
        var { items } = this.state;
        items.unshift(data);
        this.setState({
            items: items,
        });
    }
    render() {
        var { Level0, Level1, Level2, Level3, Navbar, NavbarProxyActive, LoadActive, items, CommunityView, Community, LoadMoreActive, continuationToken } = this.state;

        if (CommunityView) {
            return <CommunityTileView level1={Level0} level2={Level1} level3={Level2} community={Community} closeView={this.communityViewClose} />
        }
        var loadmoreTrigger = this.loadmore;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;
            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });
        return (
            <div>
                {Navbar ?
                    <Grid ref={this.communityTitleBar} className={'text-color-white'} container spacing={1} style={{ padding: "3px", display: NavbarProxyActive ? "none" : "" }}>
                        <div style={{ position: "relative" }}>
                            <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
                        </div>
                        <ButtonReact variant='primary' style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                        </ButtonReact>

                        <AddEditClassGroup
                            Level0={Level0}
                            Level1={Level1}
                            Level2={Level2}
                            Level3={Level3}
                            icon={"parrot-pen"}
                            receiveData={this.addCommunityList}
                            triggerBtn={"Add Community"}
                            customBtnStyle={{ display: "flex", alignItems: "center" }}
                            customBtnClass={'btn btn-success add-community-btn'}
                            updateGroup={(groupId) => console.log(groupId)}
                        />
                        <div className={'refresh-btn'} onClick={this.reload}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                        </div>
                    </Grid>
                    : ""}
                {LoadActive ?
                    <WaitSpinner /> :
                    <Grid container spacing={1}>
                        {items.map(card => {
                            colorCounter++;
                            if (colorCounter == 16) {
                                colorCounter = 0;
                            }
                            return (
                                <CommunityTile card={card} color={shuffleColors[colorCounter]} receiveData={this.communityView} />
                            )
                        })}
                        {LoadMoreActive ? <WaitSpinner /> : ""}
                    </Grid>
                }

            </div>

        );
    }
}

export default CommunitiesContainer;