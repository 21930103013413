import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './../App.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';

const privacyLinks = [
    { displayText: "Information You Provide to Us", secondary: "In order to create an account within the Tokket Platform, you must provide us your email address as well as your date of birth. This is so that we can provide our services to you with appropriate methods under our rules. We will also need your payment information such as your card number and billing address if you would like to purchase upgraded memberships or in-game features. The content that you share in public or private will be collected by us for us to provide you content and experiences that reflect your interests in using our services." },
    { displayText: "How we use your information", secondary: "Your profile will display information such as your username, your previous posts and comments, coins, followers/following, in-game rankings and points, and your membership status. You will be receiving Emails from Tokket regarding registration, account changes, promotions, and policy violations. Device information will be collected in order to better improve your experience with using our services. We may share consented personal information with third party clients. These will serve to help us run our services or advertise their products. We will also use your information to comply with the law as well as in an emergency." },
    { displayText: "How long we keep information", secondary: "We will store your personal information until you request to have it deleted or if you decide to delete your account. We may retain any information that is needed for the law." },
    { displayText: "Cookies", secondary: "Cookies will be used in order to collect and store data. This data will be provided to affiliates and service providers that will help make the services better and more suited for your interests." },
    { displayText: "Children", secondary: "If you are under the age of 13, then the only way to use our services is through a family account that is owned and managed by a parent or guardian. A child subaccount can be created under the family account. The parent or guardian purchases and creates a subaccount and provides consent for a child to use a subaccount within the family account. We will use and share personal information or data in accordance with the guidelines provided by COPPA." },
    { displayText: "Ads and Analytics", secondary: "The info that Tokket collects will contribute to the advertisements being displayed on our services. Advertisements are needed so that we can provide the services free of cost to the public. The advertisements seen will be tailored according to information from user activity and interactions. We will be using Google Analytics in order to measure the number of users that are using our services at any given point." },
    { displayText: "User Rights", secondary: "As a user, you have the right to change or update any information in your account settings. You can request any information of yours that you would like to be deleted. You can also opt out of some emails from Tokket regarding marketing, but not emails regarding account status and legal issues. If you do not agree with the privacy policy, you can simply delete the account and not use our services." },
    { displayText: "Changes for the Privacy Policy", secondary: "We can change the content of the privacy policy at any time. We will let you know if our Privacy Policy has been changed through email or platform notification. It is recommended that you review the updated Privacy Policy before you continue using our services." },
    { displayText: "Contact Us", secondary: "Contact us on support@tokket.com" }
]
function Privacy() {
    return (
        <React.Fragment>
            <CssBaseline />

            <Navbar transparentTop={true} />
            <main style={{ paddingBottom: "unset" }}>
                <Container className={'banner'}>
                    <img src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header-aboutus.png" style={{ width: "100%", height: "570px" }} />
                    <div></div>
                    <Row className={'content-aboutus'}>
                        <Typography
                            align="center"
                            fontSize={'80px'}
                            fontWeight={'bold'}
                            color={'white'}
                            textTransform={'uppercase'}
                        >
                            Privacy Policy
                        </Typography>
                        <Typography
                            align="center"
                            fontSize={'20px'}
                            color={'white'}
                        >
                            Tokket is a gamified social platform that blends social media, games, knowledge, and rewards.
                        </Typography>
                    </Row>

                </Container>
                <Container>
                    <Row style={{ marginBottom: "5px" }}>
                        <Col xs={12} style={{ marginBottom: "30px" }}>
                            <h4 style={{ fontSize: "45px" }}>Our approach</h4>
                            <div style={{ fontSize: "16px", color: "#818181", marginTop: "30px" }}>
                                Tokket is dedicated to making the world more positive and productive. When you share knowledge and information with us, you are helping people become more positive and productive.
                                This Privacy Policy applies to all our Products and Services which are mobile games and apps, web site games and apps, and other services.
                                When you use our products and services, you’ll share some information with us. We believe to be transparent with what we collect from you, how we use it, and how you can have control over both.
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <h4 style={{ fontSize: "45px" }}>Privacy Policy</h4>
                            <List>
                                {privacyLinks.map((privacyLink, index) => (
                                    <ListItem className={'menu-list-link'}>
                                        <Link href={`#section-${index}`}>{privacyLink.displayText}</Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Col>
                        <Col md={8} xs={12}>
                            <List>
                                {privacyLinks.map((privacyLink, index) => (
                                    <ListItem style={{display: "block"}} id={`section-${index}`}>
                                        <h4 style={{ fontSize: "34px" }}>{privacyLink.displayText}</h4>
                                        <div style={{ fontSize: "16px", marginTop: "30px", color: "#818181" }}>
                                            {privacyLink.secondary}
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </Col>
                    </Row>

                </Container>
            </main>
            {/* Footer */}
            <Footer disableAbsolute={true}/>
            {/* End footer */}
        </React.Fragment>
    );
}

export default Privacy; 