import React, { createRef } from 'react'
import '../styles.css'
import '../components/css/GroupView.css'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { HttpTransportType } from '@microsoft/signalr';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ShimmerChatMsg from './ShimmerChatMsg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faFile, faPaperclip, faPaperPlane, faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { connectToGroup, deleteMessageInGroup, getMessages, sendMessageToGroup, updateMessageInGroup } from '../services/chatServices';
import Moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { randomString, idFormat } from '../services/helperTools';
import FileLoader from './FileLoader';
import { uploadFile } from '../services/fileService';
import { createRoot } from 'react-dom/client';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '@mui/material';
import Iframe from 'react-iframe';
import { getToken, getUser } from '../services/accountServices';
import ProfileView from './ProfileView';

const MySwal = withReactContent(Swal);
interface States {
    ChatMsg: string;
    Messages: any[];
    GroupId: string;
    LoadActive: boolean;
    UserData: any; // assuming userdata is an object, if it's an array or other type, adjust accordingly
    connection: null | any; // assuming connection is an object or null, if it's an array or other type, adjust accordingly
    groupConnection: null | any; // assuming groupConnection is an object or null, if it's an array or other type, adjust accordingly
    EditMessage: string;
    UploadSendBtn: boolean;
    SetFile: null | any; // assuming SetFile is an object or null, if it's an array or other type, adjust accordingly
    FileToUpload: any[];
    DocumentView: boolean;
    Document: string;
    UserView: boolean;
    UserInfo: any[];
}

interface Props {
    user: any;
    displayToksSets: any;
}
class GroupChat extends React.Component<Props, States> {
    fileContainer: React.RefObject<any>;
    fileUpload: React.RefObject<any>;
    refreshChatRef: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        var userdata = [];
        if (window.localStorage.getItem("userdata")) {
            try {
                userdata = JSON.parse(window.localStorage.getItem("userdata"));
            } catch (err) { }
        }
        this.state = {
            ChatMsg: "",
            Messages: [],
            GroupId: props.groupId,
            LoadActive: false,
            UserData: userdata,
            connection: null,
            groupConnection: null,
            EditMessage: "",
            UploadSendBtn: true,
            SetFile: null,
            FileToUpload: [],
            DocumentView: false,
            Document: "",
            UserView: false,
            UserInfo: []
        };

        this.setChatMsg = this.setChatMsg.bind(this);
        this.sendChatMsg = this.sendChatMsg.bind(this);
        this.setGroupConnection = this.setGroupConnection.bind(this);
        this.setMessages = this.setMessages.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.emptyChatMsgData = this.emptyChatMsgData.bind(this);

        this.deleteMsg = this.deleteMsg.bind(this);
        this.removeMessage = this.removeMessage.bind(this);
        this.setEditMsg = this.setEditMsg.bind(this);
        this.cancelEditComment = this.cancelEditComment.bind(this);
        this.editMessage = this.editMessage.bind(this);
        this.saveEditMessage = this.saveEditMessage.bind(this);

        this.fileContainer = createRef();
        this.fileUpload = createRef();
        this.fileUploadStart = this.fileUploadStart.bind(this);
        this.triggerInputUpload = this.triggerInputUpload.bind(this);
        this.removeFile = this.removeFile.bind(this);

        this.documentClose = this.documentClose.bind(this);
        this.viewDocument = this.viewDocument.bind(this);

        this.refrechChat = this.refrechChat.bind(this);
        this.refreshChatRef = props.refreshChatRef;

        this.setProviewView = this.setProviewView.bind(this);
        this.closeProfile = this.closeProfile.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        const { UserData, GroupId } = this.state;
        this.getData();
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://tokketdev.azurewebsites.net/chathub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
        this.setState({
            connection: newConnection,
            LoadActive: true
        });
        newConnection.start().then(e => {
            this.state.connection.invoke("GetSignalRConnectionInfoGroup", UserData.id, false, false)
                .catch(function (err) {
                });
            this.state.connection.on('connectionTokChat', connection => {
                this.setGroupConnection(connection);
            });
        }).catch(function (err) {
        });
    }
    async getData() {
        await getToken();
    }
    setChatMsg(msg) {
        this.setState({
            ChatMsg: msg
        });
    }
    sendChatMsg() {
        const { ChatMsg, GroupId, UserData, FileToUpload, SetFile } = this.state;
        var fileNames = [];
        var fileExtensions = [];
        var files = [];
        for (let x = 0; x < FileToUpload.length; x++) {
            if (FileToUpload) {
                fileNames.push(FileToUpload[x]["Name"]);
                fileExtensions.push(FileToUpload[x]["Type"]);
                files.push(FileToUpload[x]["Url"]);
            }
        }
        var item = {
            group_id: GroupId,
            sender_id: UserData["id"],
            message: ChatMsg,
            label: "tokchatmessage",
            display_name: UserData["display_name"],
            image: UserData["user_photo"],
            files: files,
            filesextension: fileExtensions,
            filesname: fileNames,
        }
        sendMessageToGroup(GroupId, item).then(res => {
            if (res["status"] != 200) {
                MySwal.fire({
                    title: "Message failed to send.",
                    allowOutsideClick: false
                });
            } else {
                this.emptyChatMsgData();
            }
        }).catch(err => {
        })
    }
    setGroupConnection(connection) {
        const { UserData, GroupId } = this.state;
        const newGroupConnection = new HubConnectionBuilder()
            .withUrl(connection.url, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => connection.accessToken
            })
            .withAutomaticReconnect()
            .build();
        this.setState({
            groupConnection: newGroupConnection,
        });
        newGroupConnection.start().then(e => {
            var setMessages = this.setMessages;
            var newMessage = this.setMessage;
            var removeMessage = this.removeMessage;
            this.state.connection.invoke("AddToClassGroupRoomChat", UserData.id, GroupId)
                .catch(function (err) {
                });
            this.state.groupConnection.on("TokChatFirstLoad", function (data) {
                setMessages(data["Results"] ? data["Results"] : "");
            });
            this.state.groupConnection.on("tokchat", function (data) {
                newMessage(data);
            });
            this.state.groupConnection.on("tokchatDelete", function (data) {
                removeMessage(data);
            });
        });
    }
    setMessages(msgs) {
        this.setState({
            Messages: msgs ? msgs : [],
            LoadActive: false
        });
    }
    setMessage(msg) {
        var { Messages } = this.state;
        Messages.unshift(msg);
        this.setState({
            Messages: Messages ? Messages : [],
        });
    }
    emptyChatMsgData() {
        this.setState({
            ChatMsg: "",
            FileToUpload: [],
        }, () => {
            this.removeFile();
        });
    }
    deleteMsg(btn) {
        const id = btn.currentTarget.getAttribute("data-id");
        const pk = btn.currentTarget.getAttribute("data-pk");
        const { Messages, UserData, GroupId } = this.state;
        var Msg = null;
        for (let x = 0; x < Messages.length; x++) {
            if (Messages[x]["id"] == id && Messages[x]["pk"] == pk) {
                Msg = Messages[x];
            }
        }
        Msg["sender_id"] = UserData["id"];
        if (Msg) {
            MySwal.fire({
                title: <p>Delete Message</p>,
                showCancelButton: true,
                confirmButtonText: 'Delete',
                icon: "warning",
                confirmButtonColor: "#c82333",
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    MySwal.fire({
                        title: <p>Deleting Message</p>,
                        allowOutsideClick: false
                    });
                    MySwal.showLoading()
                    deleteMessageInGroup(GroupId, Msg).then(response => {
                        if (response) {
                            if (response["status"] == 200) {
                                MySwal.update({
                                    title: 'Deleted!',
                                    icon: "success"
                                });
                            } else {
                                MySwal.update({
                                    title: <p>An Error Occured</p>,
                                    icon: "warning"
                                });
                            }
                        }
                    }).catch(response => {
                        MySwal.update({
                            title: <p>An Error Occured</p>,
                            icon: "warning"
                        });
                    }).finally(() => {
                        MySwal.hideLoading();
                    });

                }
            })
        }
    }
    removeMessage(msg) {
        const { Messages } = this.state;
        for (let x = 0; x < Messages.length; x++) {
            if (Messages[x]["id"] == msg["id"] && Messages[x]["pk"] == msg["pk"]) {
                Messages.splice(x, 1);
            }
        }
        this.setState({
            Messages: Messages ? Messages : [],
        });
    }
    setEditMsg(btn) {
        const id = btn.currentTarget.getAttribute("data-id");
        const pk = btn.currentTarget.getAttribute("data-pk");
        const { Messages } = this.state;
        var editMsg = "";
        for (let x = 0; x < Messages.length; x++) {
            let tempMsg = Messages[x];
            if (Messages[x]["id"] == id && Messages[x]["pk"] == pk) {
                tempMsg["edit"] = true;
                editMsg = tempMsg["message"];
            } else {
                tempMsg["edit"] = false;
            }
            Messages[x] = tempMsg;
        }
        this.setState({
            Messages: Messages ? Messages : [],
            EditMessage: editMsg
        });
    }
    cancelEditComment() {
        const { Messages } = this.state;
        for (let x = 0; x < Messages.length; x++) {
            let tempMsg = Messages[x];
            tempMsg["edit"] = false;
            Messages[x] = tempMsg;
        }
        this.setState({
            Messages: Messages ? Messages : [],
        });
    }
    editMessage(input) {
        const newEditMsg = input.target.value;
        this.setState({
            EditMessage: newEditMsg
        });
    }
    saveEditMessage(btn) {
        const id = btn.currentTarget.getAttribute("data-id");
        const pk = btn.currentTarget.getAttribute("data-pk");
        const { EditMessage, Messages, UserData, GroupId } = this.state;
        var Msg = null;
        for (let x = 0; x < Messages.length; x++) {
            if (Messages[x]["id"] == id && Messages[x]["pk"] == pk) {
                Msg = Messages[x];
            }
        }
        Msg["message"] = EditMessage;
        Msg["sender_id"] = UserData["id"];
        MySwal.fire({
            title: <p>Updating Message</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        updateMessageInGroup(GroupId, Msg).then(response => {
            if (response) {
                if (response["status"] == 200) {
                    MySwal.update({
                        title: 'Updated!',
                        icon: "success"
                    });
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(response => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        });
    }
    fileUploadStart(e) {
        const rootMain = createRoot(this.fileContainer.current);
        var re = /(?:\.([^.]+))?$/;
        const file = e.target.files[0];
        const fileId = randomString(10);
        const fileRef = fileId + "-" + file.name;
        this.setState({
            UploadSendBtn: false,
        });
        rootMain.render(<FileLoader name={file.name} fileRef={fileRef} width={'110px'} lineClamp={2} removeButton={true} removeFile={this.removeFile} />);
        uploadFile(file, fileId).then(response => {
            let tempSetFile = response["request"];
            tempSetFile["file"] = e.target.files[0];
            var newFileToUpload = new Array;
            const tempFile = {
                Type: re.exec(file["name"])[1],
                Name: file["name"],
                Url: encodeURI("https://tokketcontent.blob.core.windows.net/files/" + fileRef)
            }
            newFileToUpload.push(tempFile);
            var { FileToUpload } = this.state;
            if (FileToUpload) {
                FileToUpload = FileToUpload.concat(newFileToUpload);
            } else {
                FileToUpload = newFileToUpload;
            }
            this.setState({
                UploadSendBtn: true,
                SetFile: tempSetFile,
                FileToUpload: FileToUpload
            });
        }).catch(response => {
        }).finally(() => {
        });
    }
    removeFile() {
        const rootMain = createRoot(this.fileContainer.current);
        rootMain.render("");
        this.setState({
            SetFile: null
        });
    }
    triggerInputUpload() {
        this.fileUpload.current.click();
    }
    documentClose() {
        this.setState({
            DocumentView: false,
            Document: ""
        });
    }
    viewDocument(btn) {
        const url = btn.currentTarget.getAttribute("data-url");
        this.setState({
            DocumentView: url ? true : false,
            Document: url
        });
    }
    refrechChat() {
        const { GroupId } = this.state;
        this.setState({
            LoadActive: true
        });
        getMessages(GroupId).then(res => {
            if (res) {
                if (res["status"]) {
                    if (res["status"] == 200) {
                        this.setMessages(res["data"]["results"]);
                    } else {
                        this.setState({
                            LoadActive: false
                        });
                    }
                } else {
                    this.setState({
                        LoadActive: false
                    });
                }
            }
        }).catch(err => {
            this.setState({
                LoadActive: false
            });
        }).finally(() => {

        })
    }
    setProviewView(e) {
        const id = e.currentTarget.getAttribute("data-id");
        MySwal.fire({
            title: <p>Loading User Info</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        getUser(id).then(res => {
            if (res) {
                if (res["data"]["status_code"] == 200) {
                    if (res["data"]["result"]) {
                        this.setState({
                            UserInfo: res["data"]["result"],
                            UserView: true
                        });
                        MySwal.close();
                    } else {
                        MySwal.update({
                            title: <p>User no longer exist.</p>,
                            icon: "warning"
                        });
                    }
                } else {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }
            }
        }).catch(err => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
        }).finally(() => {
            MySwal.hideLoading();
        })
    }
    closeProfile() {
        this.setState({
            UserInfo: [],
            UserView: false
        });
    }
    render() {
        var { UserView, UserInfo, LoadActive, UserData, ChatMsg, Messages, EditMessage, UploadSendBtn, DocumentView, Document } = this.state;
        let editActive = false;
        for (let x = 0; x < Messages.length; x++) {
            let compiledFileData = [];
            if (Messages[x]["edit"]) {
                editActive = true;
            }
            if (Messages[x]["files"] && Messages[x]["filesextension"] && Messages[x]["filesname"]) {
                try {
                    if (Messages[x]["files"].length > 0 && Messages[x]["filesextension"].length > 0 && Messages[x]["filesname"].length > 0) {
                        for (let y = 0; y < Messages[x]["files"].length; y++) {
                            let tempFile = {
                                url: Messages[x]["files"][y],
                                name: Messages[x]["filesname"][y],
                                extension: Messages[x]["filesextension"][y],
                            }
                            compiledFileData.push(tempFile);
                        }
                    }
                } catch (err) { }
            }
            Messages[x]["filedata"] = compiledFileData;
        }
        return (
            <div>
                <Modal className={'dark-background-2 profile-view'} fullscreen={true} show={UserView} onHide={this.closeProfile} style={{ zIndex: "1406" }}>
                    <Modal.Header className={'dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <Typography fontWeight={"bold"} marginRight={"auto"}>View Profile</Typography>

                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeProfile} />
                        </div>

                    </Modal.Header>
                    <Modal.Body className={'dark-background-2'}>
                        {UserView ?
                            <ProfileView user={UserInfo} displayToksSets={true} changePasswordTrigger={undefined} profile={undefined} updateLoginData={undefined} updateProfileData={undefined} /> : ""
                        }
                    </Modal.Body>
                </Modal>
                <Modal size='lg' show={DocumentView} onHide={this.documentClose} style={{ zIndex: "1206" }}>
                    <Modal.Header className={'text-color-white dark-background-2'}>
                        <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                            <Typography fontWeight={"bold"} marginRight={"auto"}>View Document</Typography>

                            <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.documentClose} />
                        </div>
                    </Modal.Header>
                    <Modal.Body className={'text-color-white dark-background-2'}>
                        <Iframe width={'100%'} styles={{ height: "75vh" }} url={Document ? "https://docs.google.com/gview?url=" + Document + "&embedded=true" : ""} />
                    </Modal.Body>
                </Modal>
                <div style={{ height: "300px", overflowX: "hidden", overflowY: editActive ? "hidden" : "scroll", display: "flex", flexDirection: "column-reverse" }}>
                    {LoadActive ?
                        <>
                            <ShimmerChatMsg />
                            <ShimmerChatMsg />
                            <ShimmerChatMsg />
                        </> :
                        <>
                            {Messages.map((Message, index) => (
                                <Row className={'dark-background-2'} style={{ backgroundColor: "white", margin: "5px 10px", padding: "0px 15px", boxShadow: "3px 3px 3px lightgrey", borderRadius: "10px", minHeight: Message["edit"] ? "" : "60px" }}>
                                    <div style={{ width: "max-content", display: "flex", alignItems: "center", padding: "unset" }}>
                                        <Avatar onClick={this.setProviewView} data-id={Message["sender_id"] ? Message["sender_id"] : ""} style={{ padding: "unset" }} sx={{ border: `2px solid #542a7d` }} src={Message["image"] ? Message["image"] : 'https://tokketcontent.blob.core.windows.net/tokketweb/default.png'} aria-label="recipe">
                                        </Avatar>
                                    </div>
                                    <Col className={'row'} style={{ padding: "unset", paddingLeft: "5px", alignContent: "flex-start" }}>
                                        <Col className={'row'} xs={'12'} style={{ alignItems: "center", paddingRight: "unset" }}>
                                            <Col xs={'auto'} style={{ color: "#007bff", fontWeight: "600", fontSize: "13px", paddingRight: "unset" }}>
                                                {Message["display_name"] ? Message["display_name"] : ''}
                                            </Col>
                                            <Col style={{ color: "gray", fontSize: "14px" }}>
                                                {Moment(Message["created_time"]).format('MM/DD/YYYY, hh:mm A')}
                                            </Col>
                                            <Col xs={'auto'} style={{ paddingRight: "unset" }}>
                                                <Dropdown style={{ marginLeft: "auto" }}>
                                                    <Dropdown.Toggle className={'dropdown-remove'} style={{ backgroundColor: "unset", color: "black", border: "unset", padding: "unset" }}>
                                                        <FontAwesomeIcon icon={faEllipsis} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={this.setEditMsg} data-id={Message["id"] ? Message["id"] : ""} data-pk={Message["pk"] ? Message["pk"] : ""}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={this.deleteMsg} data-id={Message["id"] ? Message["id"] : ""} data-pk={Message["pk"] ? Message["pk"] : ""}>Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        </Col>
                                        <Col className={'row'} xs={'12'} style={{ margin: "unset" }}>
                                            {Message["edit"] ?
                                                <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                                    <Col xs={'12'} style={{ padding: "unset", paddingBottom: "10px" }}>
                                                        <TextField style={{ width: "100%" }} value={EditMessage} onChange={this.editMessage} label="Edit Message" />
                                                    </Col>
                                                    <Button onClick={this.cancelEditComment} variant="secondary" style={{ width: "max-content", marginRight: "5px" }}>
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={this.saveEditMessage} data-id={Message["id"] ? Message["id"] : ""} data-pk={Message["pk"] ? Message["pk"] : ""} variant="success" style={{ width: "max-content" }}>
                                                        <span>Save Changes</span>
                                                    </Button>
                                                </Row> :
                                                <Row>
                                                    <Col xs={'12'} style={{ padding: "unset" }}>
                                                        {Message["message"] ? Message["message"] : ""}
                                                    </Col>
                                                </Row>
                                            }
                                            {Message["filedata"].length > 0 ?
                                                <Row>
                                                    {Message["filedata"].map((file, index) => (
                                                        <Dropdown style={{ padding: "unset" }}>
                                                            <Dropdown.Toggle className={'dropdown-remove'} style={{ backgroundColor: "unset", padding: "unset", whiteSpace: "unset", border: "unset" }}>
                                                                <div className={'file-chat-item-container'}>
                                                                    <FontAwesomeIcon icon={faFile} size={'3x'} />
                                                                    <span className={'line-clamp line-clamp-2'} style={{ marginLeft: "5px", fontWeight: "600" }}>{file["name"]}</span>
                                                                </div>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={this.viewDocument} data-url={file["url"]}>View</Dropdown.Item>
                                                                <Dropdown.Item href={file["url"]} target='_blank'>Download</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    ))}
                                                </Row> : ""
                                            }
                                        </Col>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    }

                </div>
                <Row className={'dark-background-2'} style={{ backgroundColor: "white", margin: "5px 10px", padding: "0px 15px" }}>
                    <div style={{ width: "max-content", display: "flex", alignItems: "center", padding: "unset" }}>
                        <Avatar style={{ padding: "unset" }} sx={{ border: `2px solid #542a7d` }} src={UserData["user_photo"] ? UserData["user_photo"] : 'https://tokketcontent.blob.core.windows.net/tokketweb/default.png'} aria-label="recipe">
                        </Avatar>
                    </div>
                    <Col className={'chat-textfield'} style={{ padding: "unset", paddingLeft: "5px" }}>
                        <TextField style={{ width: "100%", padding: "unset" }} multiline minRows={1} onChange={(e) => this.setChatMsg(e.target.value)} placeholder='Enter a chat' value={ChatMsg} inputProps={{ maxLength: 300 }} />
                    </Col>
                    <Col xs={12}>
                        <div ref={this.fileContainer} style={{ padding: "10px 0px 0px 40px" }}></div>
                        <Row style={{ float: "right" }}>
                            <div className={'chat-btn exception'} ref={this.refreshChatRef} onClick={this.refrechChat}>
                                <FontAwesomeIcon size='xl' icon={faRefresh} />
                            </div>
                            <div className={'chat-btn exception'} onClick={this.triggerInputUpload}>
                                <FontAwesomeIcon size='xl' icon={faPaperclip} />
                            </div>
                            <input ref={this.fileUpload} type="file" onChange={this.fileUploadStart} hidden />
                            <div className={'chat-btn exception'} style={{ color: !UploadSendBtn ? "gray" : "" }} onClick={UploadSendBtn ? this.sendChatMsg : undefined}>
                                Send
                                <FontAwesomeIcon size='xl' icon={faPaperPlane} style={{ marginLeft: "5px" }} />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default GroupChat;