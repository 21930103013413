import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from '@mui/system';
import './../App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { getGroupMembers } from '../services/classServices';
import WaitSpinner from './WaitSpinner';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './css/GroupView.css'
import { TokUser } from '../interfaces/TokUserInterface';
import { Group } from '../interfaces/GroupInterface';

interface States {
  items: TokUser[];
  group: Group;
  LoadActive: boolean;
  OpenModal: boolean;
}

interface Props {
  group: Group;
}

class GroupMembers extends React.Component<Props, States> {

  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      group: props.group,
      LoadActive: false,
      OpenModal: false
    };

    this.openGroup = this.openGroup.bind(this);
    this.closeGroup = this.closeGroup.bind(this);
  }

  componentDidMount() {
    this.setState({
      LoadActive: true
    });
    this.getData();
  }

  async getData() {
    const { group } = this.state;
    const response = await getGroupMembers(group.id);
    if (response) {
      if (response.status == 200) {
        this.setState({
          LoadActive: false,
          items: response.data.resource.results
        });
      }
    }
  }
  openGroup() {
    this.setState({
      OpenModal: true
    });
  }
  closeGroup() {
    this.setState({
      OpenModal: false
    });
  }
  render() {
    const { LoadActive, items, group, OpenModal } = this.state;
    return (
      <>
        <div onClick={this.openGroup}>
          <FontAwesomeIcon icon={faUserGroup} />
          Members
        </div>
        <Modal show={OpenModal} style={{ zIndex: "1205" }}>
          <Modal.Header style={{ fontWeight: "600" }}>
            List of Members
            <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "auto", cursor: "pointer" }} onClick={this.closeGroup} />
          </Modal.Header>
          <Modal.Body>
            <Row className={'member-list-container'} style={{ margin: "unset", display: "block" }}>
              <Row className={'member-list-item col-12'} style={{ margin: "unset", padding: "unset" }}>
                <div style={{ height: "46px", width: "46px", backgroundImage: `url(${group.user_photo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundColor: "white" }}></div>
                <div style={{ width: "max-content" }}>{group.user_display_name}</div>
                <div className={'owner-badge'}>Owner</div>
              </Row>
              {LoadActive ?
                <div className={'col-12'} style={{ marginTop: "5px" }}><WaitSpinner /></div> :
                items ?
                  <>
                    {items.map(member => {
                      if (group.user_id != member.id) {
                        return (
                          <Row className={'member-list-item col-12'} style={{ margin: "unset", padding: "unset" }}>
                            <div style={{ height: "46px", width: "46px", backgroundImage: `url(${member.user_photo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundColor: "white" }}></div>
                            <div style={{ width: "max-content" }}>{member.display_name}</div>
                          </Row>
                        )
                      }
                    })}
                  </> : ""
              }
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default GroupMembers; 