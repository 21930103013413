import React from 'react';
import tokChannelMy from './../images/tokchannel-icon-my.svg';
import tokChannelMyg from './../images/tokchannel-icon-myg.svg';
import tokChannelMyt from './../images/tokchannel-icon-myt.svg';
import tokChannelFF from './../images/tokchannel-icon-ff.svg';
import tokChannelHlt from './../images/tokchannel-icon-hlt.svg';
import tokChannelHow from './../images/tokchannel-icon-how.svg';
import tokChannelInp from './../images/tokchannel-icon-inp.svg';
import tokChannelWrl from './../images/tokchannel-icon-wrl.svg';

const TokChannelIcon = (props) => {
  const icon = props.icon;
  let color = props.color;
  const active = props.active;
  if (active === "active") {
    color = "white";
  }
  let src;

  switch (icon) {
    case "my":
      src = tokChannelMy;
      break;
    case "mygroup":
      src = tokChannelMyg;
      break;
    case "myteam":
      src = tokChannelMyt;
      break;
    case "ff":
      src = tokChannelFF;
      break;
    case "health":
      src = tokChannelHlt;
      break;
    case "howto":
      src = tokChannelHow;
      break;
    case "interpersonal":
      src = tokChannelInp;
      break;
    case "world":
      src = tokChannelWrl;
      break;
    default:
      src = tokChannelWrl;
  }

  return (
    <span>
      {src && (
        <img
          src={src}
          width="30"
          height="30"
          style={{
            filter: active === "active" ? "invert(100%)" : "",
            WebkitFilter: active === "active" ? "invert(100%)" : "",
          }}
        />
      )}
    </span>
  );
};

export default TokChannelIcon;