import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { addParam, jsonKeySnakeCaseToCamelCase } from './helperTools';

const apiUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;
const apiCode = process.env.REACT_APP_API_CODE;
const apiImageUrl = process.env.REACT_APP_API_IMAGE_URL;
var token = window.localStorage.getItem("authToken") ? window.localStorage.getItem("authToken") : "";

var userdata = [];
var userid = "";
try {
    userdata = JSON.parse(window.localStorage.getItem("userdata"));
    userid = userdata["id"];
} catch (err) { }
try {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    userid = authUser.uid;
} catch (err) { }
var timerRefreshToken;
export async function getToken() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            //console.log("--------------------");
            var nToken = await user.getIdToken(true);
            window.localStorage.setItem("authToken", JSON.stringify(nToken));
            userid = user.uid;
            token = JSON.stringify(nToken);
            if (timerRefreshToken) {
                clearInterval(timerRefreshToken);
            }

            setTimeout(timerTokenRefrestInitialize, 2000);
            //console.log("--------------------");
            // ...
        } else {
            // User is signed out
            // ...
        }
    });
}

export async function timerTokenRefrestInitialize() {
    if (!timerRefreshToken) {
        timerRefreshToken = setInterval(function () {
            const auth = getAuth();
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    var nToken = await user.getIdToken(true);
                    window.localStorage.setItem("authToken", JSON.stringify(nToken));
                    userid = user.uid;
                    token = JSON.stringify(nToken);
                }
            });
        }, 3300000);
    }
}

export async function getSetToken() {
    return JSON.parse(token);
}

export async function getSetUserId() {
    try {
        let userdata = JSON.parse(window.localStorage.getItem("userdata"));
        userid = userdata["id"];
    } catch (err) { }
    try {
        let authUser = JSON.parse(window.localStorage.getItem("authUser"));
        userid = authUser.uid;
    } catch (err) { }
    return userid;
}

export async function loginUser(username = "", password = "") {
    if (username && password) {
        return await axios.post(`${apiUrl}${apiVersion}/login?code=${apiCode}`, {
            Email: username,
            PasswordHash: password,
            label: "user"
        },
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'email': username,
                    'password': password
                }
            });
    } else {
        return false;
    }
}

export async function getUser(userid = "", pk = "") {
    if(!pk){
        pk = userid;
    }
    if (userid) {
        return await axios.get(`${apiImageUrl}/users/${userid}`,
            {
                headers: {
                    'pk': userid,
                    'Authorization': "Bearer " + JSON.parse(token),
                }
            });
    } else {
        return {};
    }
}

export async function getUserTokhandle(userid = "") {
    if (userid) {
        return await axios.get(`${apiImageUrl}/tokhandles?userCounter=0${userid ? "&feedId=" + userid : ""}`,
            {
                headers: {
                    'userid': userid,
                    'Authorization': "Bearer " + JSON.parse(token),
                }
            });
    } else {
        return false;
    }
}

export async function getTokHandles(query = [], feedId = "") {
    if (query) {
        query = jsonKeySnakeCaseToCamelCase(query);
        let params = "";
        let keys = Object.keys(query)
        for (let x = 0; x < keys.length; x++) {
            params = addParam(params, keys[x], query[keys[x]]);
        }
        return await axios.get(`${apiImageUrl}/tokhandles?userCounter=0${feedId ? "&feedId=" + feedId : ""}${params ? "&" + params : ""}`, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
            }
        });
    } else {
        return false;
    }
}

export async function checkTokHandleAvailability(handle) {
    if (handle) {
        return await axios.get(`${apiImageUrl}/tokhandles/${handle}`,
            {
                headers: {
                    'pk': handle,
                    'Authorization': "Bearer " + JSON.parse(token),
                }
            }).catch(function (err) {
                if (err["response"]) {
                    return err["response"];
                }
                return false;
            })
    } else {
        return false;
    }
}

export async function signupUser(data) {
    if (data) {
        return await axios.post(`${apiImageUrl}/auth/signup`, data,
            {
                headers: {
                    'name': data['display_name'],
                    'email': data['Email'],
                    'password': data['PasswordHash']
                }
            });
    }
    else
        return false;
}

export async function createUser(data, items = "") {
    if (data) {
        return await axios.post(`${apiImageUrl}/users?&userCounter=0${items ? "&items=" + items : ""}${data["user_id"] ? "&feedId=" + data["user_id"] : ""}`, data,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    }
    else
        return false;
}

export async function updateUser(user) {
    if (user) {
        return await axios.put(`${apiImageUrl}/users`, user,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'Authorization': "Bearer " + await getSetToken(),
                    'userid': await getSetUserId()
                }
            });
    } else {
        return false;
    }
}

export async function patchUser(patchObject, id, pk, items = "") {
    if (patchObject && id && pk) {
        return await axios.patch(`${apiImageUrl}/users/${id}`, patchObject,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                    'pk': pk,
                    'items': items
                }
            });
    } else {
        return false;
    }
}

export async function getImages(query) {
    if (query) {
        return await axios.get(`${apiUrl}${apiVersion}/images?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'userid': query["userid"] ? query["userid"] : "",
                    'kind': query["kind"] ? query["kind"] : "user",
                    'Authorization': "Bearer " + JSON.parse(token),
                }
            });
    } else {
        return false;
    }
}
export async function getAvatars() {
    return await axios.get(`${apiUrl}${apiVersion}/avatars?code=${apiCode}`,
        {
            headers: {
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'Authorization': "Bearer " + JSON.parse(token),
            }
        });
}

export async function getBadges(query) {
    if (query) {
        return await axios.get(`${apiUrl}${apiVersion}/badgesuser/${query["userid"] ? query["userid"] : ""}?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'Authorization': "Bearer " + JSON.parse(token),
                }
            });
    } else {
        return false;
    }
}

export async function getUsers(query, feedId = "", items = "") {
    if (query) {
        query = jsonKeySnakeCaseToCamelCase(query);
        let params = "";
        let keys = Object.keys(query)
        for (let x = 0; x < keys.length; x++) {
            params = addParam(params, keys[x] == "displayName" ? "%" + keys[x] : keys[x], query[keys[x]]);
        }
        return await axios.get(`${apiImageUrl}/users?${params ? "&" + params : ""}${items ? "&items=" + items : ""}${feedId ? "&feedId=" + feedId : ""}`,
            {
                headers: {
                    'Authorization': "Bearer " + JSON.parse(token),
                }
            });
    } else {
        return false;
    }
}

export async function postTokHandle(tokhandle, transfer = false) {
    if (tokhandle) {
        var feedId = "";
        tokhandle["user_id"] = await getSetUserId();
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                feedId = localUserJson["id"];
                tokhandle["title_display"] = localUserJson["is_user_display_handle_enabled"] ? localUserJson["current_handle"] ? localUserJson["current_handle"] : "" : localUserJson["title_enabled"] ? localUserJson["title_id"] ? localUserJson["title_id"] : "" : "";
                tokhandle["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                tokhandle["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                tokhandle["user_country"] = localUserJson["country"] ? localUserJson["country"] : "";
                tokhandle["user_state"] = localUserJson["state"] ? localUserJson["state"] : "";
            }
        }
        return await axios.post(`${apiImageUrl}/tokhandles?&userCounter=0${feedId ? "&feedId=" + feedId : ""}${transfer ? "&items=transfer" : ""}`, tokhandle,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function deleteUser(user, items = "") {
    if (user["id"] && user["pk"]) {
        return await axios.delete(`${apiImageUrl}/users/${user.id}`,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                    'pk': user.pk ? user.pk : "",
                    'items': items
                }
            });
    } else {
        return false;
    }
}