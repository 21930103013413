import React from 'react';
import { useParams } from 'react-router-dom';
import ClassGroupRender from '../components/ClassGroupRender';
const ClassGroup = (props) => {
    const { id } = useParams();
    const { pk } = useParams();
    return (
        <ClassGroupRender id={id} pk={pk} group={undefined}/>
    );
}

export default ClassGroup;