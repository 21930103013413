import React from 'react';
import publicTok from './../images/publictok.svg';
import myTok from './../images/mytok.svg';
import tokChannel from './../images/tokchannel.svg';
import tokPaks from './../images/tokpaks.svg';
import classSets from './../images/classsets.svg';
import studyGames from './../images/studygames.svg';
import groups from './../images/groups.svg';
import notifications from './../images/notifications.svg';
import guides from './../images/guides.svg';
import peerbooks from './../images/peerbooks.svg';
import opportunities from './../images/opportunities.svg';
import training from './../images/training.svg';
import tokhandles from './../images/tokhandles.svg';
import basic from './../images/tokgroup/basic-icon.svg';
import detail from './../images/tokgroup/detail-icon.svg';
import list from './../images/tokgroup/list-icon.svg';
import mega from './../images/tokgroup/mega-icon.svg';
import qna from './../images/tokgroup/qna-icon.svg';
import link from './../images/link-icon.svg';

const ClassTokIcon = (props) => {
    const icon = props.icon;
    const active = props.active;
    const height = props.height;
    const width = props.width;
    let src;

    switch (icon ? icon.toLowerCase() : "") {
        case "publictok":
            src = publicTok;
            break;
        case "tokhandles":
            src = tokhandles;
            break;
        case "mytoks":
            src = myTok;
            break;
        case "tokchannel":
            src = tokChannel;
            break;
        case "tokpaks":
            src = tokPaks;
            break;
        case "classsets":
            src = classSets;
            break;
        case "studygames":
            src = studyGames;
            break;
        case "groups":
            src = groups;
            break;
        case "notifications":
            src = notifications;
            break;
        case "guides":
            src = guides;
            break;
        case "peerbooks":
            src = peerbooks;
            break;
        case "opportunities":
            src = opportunities;
            break;
        case "training":
            src = training;
            break;
        case "basic":
            src = basic;
            break;
        case "detail":
            src = detail;
            break;
        case "list":
            src = list;
            break;
        case "mega":
            src = mega;
            break;
        case "qna":
        case "q&a":
            src = qna;
            break;
        case "link":
            src = link;
            break;
        default:
            src = null;
    }

    return (
        <span>
            {src && (
                <img
                    src={src}
                    width={height ? height : "32"}
                    height={width ? width : "32"}
                    style={{
                        filter: active === "active" ? "invert(100%)" : "",
                        WebkitFilter: active === "active" ? "invert(100%)" : "",
                    }}
                />
            )}
        </span>
    );
};

export default ClassTokIcon;