import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import YouTube from 'react-youtube';
import { isYoutubeLink, extractYoutubeID } from '../services/helperTools';
interface Props {
    withImage: boolean;
    data: {
        image: string;
        primary_text: string;
    };
}
export default function CardMediaCustom(props: Props) {
    function readyFocus(player) {
        player.target.g.setAttribute("height", 100)
    }
    if (props.withImage) {
        return (
            <>
                {isYoutubeLink(props.data.image) ?
                    <YouTube className={'fullwidth-iframe'} onReady={readyFocus} videoId={extractYoutubeID(props.data.image)} /> :
                    <CardMedia
                        component="img"
                        height="160"
                        data-src={props.data.image}
                        src={props.data.image}
                        alt={props.data.primary_text}
                    />
                }
            </>
        );
    }
    else return (null);
}