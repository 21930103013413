import React, { createRef, useRef, useState } from 'react'
import '../styles.css'
import './css/TokMatch.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheck, faRotateRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from 'react-bootstrap';
import Typography from "@mui/material/Typography";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import arrayShuffle from 'array-shuffle';
import { arrayEqualPartSplitter } from '../services/helperTools';
import { DndContext } from '@dnd-kit/core';
import { Draggable } from './Draggable';
import { Droppable } from './Droppable';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Set } from '../interfaces/SetInterface';
import { Tok } from '../interfaces/TokInterface';

const MySwal = withReactContent(Swal);

interface States {
    LoadActive: boolean;
    Set: Set;
    Items: Tok[];
    Play: boolean;
    Rounds: {
        // live test
        questions: any[];
        answers: any[];
    }[];
    CurrentRound: number;
    //live test
    PlayerAnswers: any;
    ShowNotification: boolean;
    CorrectCountCurrent: number;
    CorrectCount: number;
}

interface Props {
    set: Set;
    items: Tok[];
    closeGame: () => void;
}

class TokMatch extends React.Component<Props, States> {

    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            LoadActive: false,
            Set: props.set,
            Items: props.items,
            Play: false,
            Rounds: [],
            CurrentRound: 0,
            PlayerAnswers: {
                0: "",
                1: "",
                2: "",
                3: "",
            },
            ShowNotification: false,
            CorrectCountCurrent: 0,
            CorrectCount: 0,
        };

        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.resetAnswer = this.resetAnswer.bind(this);
        this.resetAnswers = this.resetAnswers.bind(this)
        this.checkAnswers = this.checkAnswers.bind(this);
        this.nextRound = this.nextRound.bind(this);
        this.quitGame = this.quitGame.bind(this);
    }

    componentDidMount() {
        const { Items } = this.state;
        let getItems = true;
        try {
            if (Items.length) {
                getItems = false;
            }
        } catch (err) { }
        if (!getItems) {
            this.setGame();
            this.setState({
                Play: true
            });
        } else {
            //get items
        }
    }
    setGame() {
        const { Items } = this.state;
        let tempItems = arrayEqualPartSplitter(arrayShuffle(Items), 4);
        let tempRounds = new Array;
        for (let x = 0; x < tempItems.length; x++) {
            let tempRound = {
                questions: arrayShuffle(tempItems[x]),
                answers: arrayShuffle(tempItems[x]),
            }
            tempRounds.push(tempRound);
        }
        this.setState({
            Rounds: tempRounds,
            CurrentRound: 0
        });
    }
    handleDragEnd({ over, active }) {
        var { PlayerAnswers } = this.state;
        PlayerAnswers[over.id.replace("answer", "")] = active.id.replace("draggable", "");
        this.setState({
            PlayerAnswers: PlayerAnswers
        });
    }
    resetAnswer(btn) {
        const index = btn.currentTarget.getAttribute("data-index");
        var { PlayerAnswers } = this.state;
        PlayerAnswers[index] = "";
        this.setState({
            PlayerAnswers: PlayerAnswers
        });
    }
    resetAnswers() {
        var { PlayerAnswers } = this.state;

        for (let x = 0; x < 4; x++) {
            PlayerAnswers[x] = "";
        }

        this.setState({
            ShowNotification: false,
            PlayerAnswers: PlayerAnswers
        });
    }
    checkAnswers() {
        var { PlayerAnswers, Rounds, CurrentRound, CorrectCount, Items } = this.state;

        var correctCount = 0;
        for (let x = 0; x < 4; x++) {
            if (PlayerAnswers[x] != "") {
                let index = Number(PlayerAnswers[x]);
                if (Rounds[CurrentRound].questions[x].id == Rounds[CurrentRound].answers[index].id && Rounds[CurrentRound].questions[x].pk == Rounds[CurrentRound].answers[index].pk) {
                    correctCount++;
                    PlayerAnswers[x] = 5;
                } else {
                    PlayerAnswers[x] = 6;
                }
            }
        }
        if (CurrentRound + 1 == Rounds.length) {
            MySwal.fire({
                title: <p>Round Over</p>,
                allowOutsideClick: false,
                confirmButtonText: 'Ok',
                icon: "info",
                html: <p>Your Final Score <b>{CorrectCount + correctCount} of {Items.length}</b></p>
            }).then((result) => {
                if (result.isConfirmed) {
                    MySwal.fire({
                        title: <p>Game Over</p>,
                        allowOutsideClick: false,
                        confirmButtonText: 'Quit',
                        icon: "info",
                        confirmButtonColor: "#c82333",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.quitGame();
                        }
                    });
                }
            });
        } else {
            this.setState({
                ShowNotification: true,
                CorrectCountCurrent: correctCount,
                PlayerAnswers: PlayerAnswers
            });
        }

    }
    nextRound() {
        const { CurrentRound, CorrectCountCurrent, CorrectCount } = this.state;
        this.setState({
            CurrentRound: CurrentRound + 1,
            CorrectCount: CorrectCount + CorrectCountCurrent,
            CorrectCountCurrent: 0,
            PlayerAnswers: {
                0: "",
                1: "",
                2: "",
                3: "",
            },
            ShowNotification: false,
        });
    }
    quitGame() {
        this.props.closeGame();
    }
    render() {
        const { Rounds, Play, CurrentRound, PlayerAnswers, ShowNotification, CorrectCountCurrent } = this.state;
        let countAnswers = 0;
        for (let x = 0; x < 4; x++) {
            if (PlayerAnswers[x] != "") {
                countAnswers++;
            }
        }
        return (
            <div>
                <Row className={'tok-match-notification'} style={{ display: ShowNotification ? "flex" : "none" }}>
                    <Col xs={'auto'}>
                        <img src="https://tokketcontent.blob.core.windows.net/tokmatch/coin.gif" style={{ height: "34px" }} />
                    </Col>
                    <Col xs={'auto'} style={{ fontWeight: "600", fontSize: "24px" }}>
                        You Got {CorrectCountCurrent} Correct
                    </Col>
                    <Col xs={'auto'}>
                        <Button variant={'danger'} onClick={this.quitGame}>
                            QUIT
                        </Button>
                    </Col>
                    <Col xs={'auto'} style={{ padding: "unset" }}>
                        <Button onClick={this.resetAnswers}>
                            TRY AGAIN
                        </Button>
                    </Col>
                    <Col xs={'auto'}>
                        <Button variant={'success'} onClick={this.nextRound}>
                            NEXT
                        </Button>
                    </Col>
                </Row>
                <DndContext onDragEnd={this.handleDragEnd}>
                    {Play ?
                        <Row>
                            <Col className={'row'} sm={6} md={12}>
                                {Rounds[CurrentRound].questions.map((question, index) => (
                                    <Col md={true} sm={12} className={'first-div100'}>
                                        <Droppable id={"answer" + index}>
                                            <div className={'tok-match-card'}>
                                                {PlayerAnswers[index] ?
                                                    <>
                                                        {PlayerAnswers[index] == 5 || PlayerAnswers[index] == 6 ?
                                                            <>
                                                                {PlayerAnswers[index] == 5 ?
                                                                    <FontAwesomeIcon icon={faCheck} size='3x' color={'green'} /> :
                                                                    <FontAwesomeIcon icon={faTimes} size='3x' color={'red'} />
                                                                }
                                                            </> :
                                                            <>
                                                                {Rounds[CurrentRound].answers[PlayerAnswers[index]] ?
                                                                    <div className={'tok-match-draggable'}>
                                                                        <div className={'remove-draggable-btn'} data-index={index} onClick={this.resetAnswer}>
                                                                            <FontAwesomeIcon icon={faRotateRight} />
                                                                        </div>
                                                                        {Rounds[CurrentRound].answers[PlayerAnswers[index]]["primary_text"] ? Rounds[CurrentRound].answers[PlayerAnswers[index]]["primary_text"] : ""}
                                                                    </div> : ""
                                                                }
                                                            </>
                                                        }

                                                    </> :
                                                    <>
                                                        {question["tok_group"] == "Detail" || question["tok_group"] == "Detailed" || question["tok_group"] == "List" ?
                                                            <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px" }} >
                                                                {question["details"] ?
                                                                    <>
                                                                        {question["details"].map((detail) => (
                                                                            <ListItem style={{ padding: "unset", display: "list-item" }}>
                                                                                <ListItemText
                                                                                    primary={detail}
                                                                                    style={{ margin: "unset" }}
                                                                                />
                                                                            </ListItem>
                                                                        ))}</> :
                                                                    <>
                                                                        {question["secondary_text"] ? question["secondary_text"] : ""}
                                                                    </>
                                                                }
                                                            </List> :
                                                            <>
                                                                {question["secondary_text"] ? question["secondary_text"] : ""}
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </div>
                                        </Droppable>
                                    </Col>
                                ))}
                            </Col>
                            <Col className={'row'} sm={6} md={12} style={{ marginTop: "15px" }}>
                                {Rounds[CurrentRound].answers.map((answer, index) => (
                                    <ul className={'tok-match-answer col-md col-sm-12'}>
                                        {Object.values(PlayerAnswers).indexOf(index.toString()) > -1 || ShowNotification ?
                                            "" : <Draggable id={"draggable" + index}>
                                                <div className={'tok-match-draggable'}>{answer["primary_text"] ? answer["primary_text"] : ""}</div>
                                            </Draggable>
                                        }

                                    </ul>
                                ))}
                            </Col>
                            <Col className={'row'}>
                                <Col xs={true} style={{ display: "flex" }}>
                                    <Button disabled={ShowNotification || countAnswers != Rounds[CurrentRound].questions.length ? true : false} className={'tok-match-bottom-btn'} onClick={this.checkAnswers} style={{ marginLeft: "auto" }}>
                                        Check
                                    </Button>
                                </Col>
                                <Col xs={true}>
                                    <Button disabled={ShowNotification} variant={'danger'} onClick={this.resetAnswers} className={'tok-match-bottom-btn'}>
                                        Reset
                                    </Button>
                                </Col>
                            </Col>
                        </Row> : ""
                    }
                </DndContext>
                {/* 

                
                {Play ?
                    <Row>
                        <Col className={'row'} sm={6} md={12}>
                            {Rounds[CurrentRound].questions.map((question, index) => (
                                <DragDropContext>
                                    <Droppable droppableId="answer" className={'col-md col-sm-12'}  onDrop={this.onDrop} types={['answer']}>
                                        <ul className={'tok-match-card'}>
                                            {question["primary_text"] ? question["primary_text"] : ""}
                                        </ul>
                                    </Droppable>
                                </DragDropContext>
                                
                            ))}
                        </Col>
                        <Col className={'row'} sm={6} md={12} style={{ marginTop: "15px" }}>
                            {Rounds[CurrentRound].answers.map((answer, index) => (
                                <ul className={'tok-match-answer col-md col-sm-12'}>
                                    <Draggable draggableId='answer'>
                                        <li className={'tok-match-draggable'}>{answer["primary_text"] ? answer["primary_text"] : ""}</li>
                                    </Draggable>
                                </ul>
                            ))}
                        </Col>
                    </Row> : ""
                }
*/}
            </div>
        );
    }
}

export default TokMatch;
