import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import { textDisplay } from '../services/helperTools';
const Subfeed = (props) => {
    const posted = props.postto;
    const fontSize = props.decrementalFontSize? props.decrementalFontSize: 0
    return (
        <List dense={true} style={{ listStyleType: 'disc', textAlign: 'left', maxWidth: '100%', marginLeft: "20px", padding: "unset" }} >
            <ListItem style={{ padding: "unset", display: "list-item" }}>
                <ListItemText
                    primary={posted["feed"] ? <Typography style={{ fontWeight: "bold", fontSize: fontSize? fontSize + "px": "18px" }}>{textDisplay(posted["feed"])}</Typography> : ""}
                    style={{ margin: "unset" }}
                />
                {posted["subfeed"]?
                    <Subfeed postto={posted["subfeed"]} decrementalFontSize={fontSize? fontSize - 2: 0}/> : ""
                }
            </ListItem>
        </List>
    )
}

export default Subfeed;