import ReactDOM from 'react-dom/client';
import { getAuth, setPersistence, signInWithEmailAndPassword, browserLocalPersistence, onIdTokenChanged } from "firebase/auth";
import { auth } from './firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocalStorage } from './useLocalStorage';
import Button from '@mui/material/Button';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TextField from '@mui/material/TextField';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OutlinedInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getUser } from '../services/accountServices';
import { useState } from 'react';
import React from 'react';

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
const Login = (props) => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [logindata, setLoginData] = useLocalStorage("userdata", "");
    const [user, setUser] = useLocalStorage("authUser", []);
    const [authToken, setAuthToken] = useLocalStorage("authToken", '');
    const [isShowLogin, SetIsShowLogin] = useState(false);
    const loginCallTrigger = props.loginCallTrigger;
    const [showPassword, setShowPassword] = useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                MySwal.fire({
                    title: <p>Logging in</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading()
                return signInWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        // Signed in
                        const user = userCredential.user;
                        //console.log(user);
                        // Session login endpoint is queried and the session cookie is set.
                        // CSRF protection should be taken into account.
                        // ...
                        setUser(user);
                        var token = await user.getIdToken();
                        setAuthToken(token);
                        MySwal.update({
                            title: <p>Log in success, refreshing page.</p>,
                            showConfirmButton: false
                        });
                        MySwal.showLoading();

                        // var u = await getUser(user.uid);
                        // setLoginData(u.data);
                        //navigate('/home', { replace: true });

                        window.location.reload();
                    }).catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        var message = "An Error Occured";
                        if (errorCode.includes("user-not-found")) {
                            message = "User not Found."
                        } else if (errorCode.includes("wrong-password")) {
                            message = "Incorrect email or password."
                        }
                        MySwal.fire({
                            title: message,
                            icon: "warning",
                            allowOutsideClick: false
                        });
                        MySwal.hideLoading();
                    });
            })
            .catch((error) => {
                // Handle Errors here.
                MySwal.fire({
                    title: "An Error Occured",
                    allowOutsideClick: false,
                    icon: "warning"
                });
                MySwal.hideLoading();
            });
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSignup = (e) => {
        e.preventDefault();
        console.log("Signing up!");
        SetIsShowLogin(false);
        props.signupTrigger.current.click();
    };

    return (
        <>
            <Button onClick={() => SetIsShowLogin(true)} ref={loginCallTrigger} hidden>Login Call</Button>
            <Modal show={isShowLogin} style={{ zIndex: "1205" }}>
                <Modal.Header>
                    <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => SetIsShowLogin(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'email'} style={{ width: "100%" }} value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <TextField id={'password'} type={showPassword ? 'text' : 'password'} style={{ width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)} label="Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"> {/* Add position prop here */}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                        <Col className={"col-12"} style={{ marginBottom: "15px" }}>
                            <Button variant={'contained'} color={"primary"} style={{ width: "100%" }} onClick={onLogin}>Login</Button>
                        </Col>
                        <Col xs={12}>
                            <p className="text-sm text-center">
                                No account yet? {' '}
                                <NavLink onClick={onSignup} to={""}>
                                    Sign up
                                </NavLink>
                            </p>
                        </Col>
                        <Col xs={12}>
                            <p className="text-sm text-center">
                                <NavLink to="/forgotpassword">
                                    Forgot Password? {' '}
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default Login