import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './../App.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';

const termLinks = [
    { displayText: "Elgibility", secondary:
        [
            {primary: "You must be 13 or the applicable age required by law of your country. Use of our Products and Services is void where prohibited."},
            {primary: "You cannot use our Products and Services if:"},
            {bullet: [
                "You are not of legal age required by law of your country",
                "You are prohibited from receiving our products, services, or software under applicable laws",
                "Your account has been previously disabled for violations of law or policy",
                "You are located in a country that is on the US government's list of prohibited or restricted parties or countries.",
                "You are a convicted sex offender"
            ]},
            {primary: "For children age 13 or younger (or other age of consent where required by local law), Tokket offers a family account (see below) that a parent owns and is responsible for but the children can use."},
        ] },
    { displayText: "User Account Types And Use", secondary:
        [
            {primary: "There are individual account and groups accounts. Both individual and group accounts are owned by one owner however the group accounts can be shared with other users. You are responsible for the account you create on our Products and Service whether it is an individual account or group account. You must provide us with accurate and up to date information. You are responsible for maintaining the security of your account and data, and you are fully responsible for all activities that occur under the account."},
            {primary: "Individual accounts are for use by a single person only. You may not share your individual account with any other person."},
            {primary: "Groups account are for organizations and families to use to represent posts and activities for their organization or family. The organization account will be inactive until at least one subaccount is purchased and setup for the organization account."},
            {primary: "The organization account is a shared account that the organization account owner is responsible for. The organization account owner is responsible for managing and monitoring the activity of the account and organization subaccounts. Everyone in the organization account and subaccount can see each other posts. The organization account owner is also responsible for managing the virtual currencies and purchases which are at the organization account level and not the subaccount level."},
            {primary: "An organization account owner will be required to set up a valid organization payment method for the organization members to use and make purchases on Tokket and that organization account owner will be responsible for all of the organization’s purchases using the organization’s payment method. If an organization account is deleted, or a member leaves the organization group, the organization account may be charged for pending purchases made by organization using the organization account payment method."},
            {primary: "The family account is a shared account that the parent is the account owner for. The parent is responsible for managing and monitoring the activity of the account and child subaccounts. Everyone in the family account and subaccount could see each other posts. The parent is also responsible for managing the virtual currencies and purchases which are at the family account level and not the child subaccount level."},
            {primary: "A parent will be required to set up a valid family payment method for your family members to use to make purchases on Tokket and that parent will be responsible for all of your family members' purchases using the family payment method. If a family group is deleted, or a family member leaves the family group, you may be charged for pending purchases made by family members using the family payment method. When users select a display names or titles for their accounts, it cannot:"},
            {bullet: [
                "Be lewd, crude, abusive, or offensive",
                "Violate any legal prohibition and",
                "Infringe upon the rights of third parties",
                "Use a domain name or URL in your username or title.",
            ]},
            {primary: "For children age 13 or younger (or other age of consent where required by local law), Tokket offers a family account (see below) that a parent owns and is responsible for but the children can use."},
        ] },
    { displayText: "Posting And Submitting Game Or Application Content And Interacting With Other Content", secondary:
        [
            {primary: "Any form of entering and submitting content to our apps, games, sites, and services can be considered as a Post. User content is the sole responsibility of the users who originated the user content. We might review user content but are not obligated to do so and cannot ensure prompt removal of objectionable user content after it has been posted or distributed. Obscene, profane, lewd, crude, defamatory, libelous, offensive, threatening, and abusive language and conduct is prohibited."},
            {primary: "Posting information about another person without that person's consent is prohibited. Posting any content that violates any laws or promotes any criminal activity or enterprise, or provides instructional information about illegal activities including, but not limited to, making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses – all of this is prohibited."},
            {primary: "Posting private or confidential information or do anything that violates someone else's rights, including intellectual property is prohibited. Posting any data, content, or image that is copyrighted, trademarked, illegal, or lacking permission for the right to post is prohibited."},
            {primary: "Sexual related post or content is prohibited. Posting any nudity is not permitted except for photos of historic paintings or sculptures for educational purposes that you have the right or permission to post. Posting content that exploits people in a sexual or violent manner is prohibited."},
            {primary: "Spamming, trolling, abusing, or any form of harassing people is prohibited. Racism, bigotry and other forms of hate content is prohibited. Any content that targets individuals to degrade, shame, or blackmail someone is prohibited. Serious threats of physical or non-physical harm to anyone is prohibited."},
            {primary: 'If you are posting something violent in nature for awareness or educational purpose, start the post with a "Warning" about the violent nature of the post.'},
            {primary: "Soliciting passwords or personally identification information for commercial or unlawful purposes from other users is prohibited. Soliciting personal information from anyone under 18 years old is prohibited."},
            {primary: "Commercial activities or sales without prior written consent from Tokket, such as contests, sweepstakes, bartering, advertising, or pyramid schemes is prohibited. Transmission of junk mail, chain letters, unsolicited mass mailings or instant messages, or spam without prior written consent from Tokket is prohibited."},
            {primary: "We may remove any content that is not appropriate or permitted. Tokket reserves the right to investigate and take appropriate legal action against anyone who violates this agreement including removing the offending Content from the Service, terminating the accounts of such violators, or seeking other legal remediation."},
            {primary: "You grant us a non-exclusive, transferable, royalty-free worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your non private content without any compensation to you. You can end this license anytime by deleting your content or account. However, content will continue to appear if you shared it with others and they have not deleted it."},
            {primary: "You grant us permission to show your username, profile picture, and information about your posts, sets, actions, reactions, or relationships such as likes, views, inaccurates, follows, gems, and comments without any compensation to you."},
        ] },
    { displayText: "Virtual Currencies and Goods", secondary: 
        [
            {primary: "The apps, games, sites and services may include different types of virtual currency such as Coins, Gems, Spins, Lives, Turns, and Eliminators (e.g. Strikes, Smashes, Sticks, etc.) that may be purchased from us for using any acceptable payment method accepted in the apps, games, sites or services if you are a legal adult in your country of residence."},
            {primary: "The apps, games, and services may also include various types of virtual goods such as Avatars, Tokmojis, Stickers, Pretypes, Titles, Subaccounts and Badges. Depending on the rules of the games, apps, sites, or services, Virtual Goods can either be earned or purchased using any acceptable payment method."},
            {primary: "Virtual Currency and Virtual Goods is only for use on our apps, games, and services. Virtual Goods can be purchased on per use basis, limited use by a limited number of users, or exclusively used by you depending on the rules of the virtual good earned or purchased."},
            {primary: "You may give virtual gifts or rewards to others but you are not allowed to sell or send these gifts in exchange for money or an equivalent to money. You agree that your Virtual Currencies and Virtual Goods will be forfeited and your account will be permanently suspended if you attempt to buy or sell any Virtual Currency or Virtual Goods."},
            {primary: "Virtual Currency and Virtual Goods are types of Content. You only get a limited, revocable, personal, non-transferable, and non-sublicensable license to use Virtual Currency and Virtual Goods. You acknowledge that you do not acquire any ownership rights in or to the Virtual Currency, Virtual Goods, or other Content. Any balance of Virtual Goods or Virtual Currency does not reflect any stored value."},
            {primary: "All Virtual Currencies and Virtual Goods shall be forfeited if your account is suspended or terminated for any reason, at our sole and absolute discretion, or if we discontinue the provision of the app, game, site or service. We may cancel, suspend, or terminate your Account and your access to your Virtual Currency, Virtual Goods, the Content, or the Services, is our sole discretion and without prior notice, including, but not limited to, for the following reasons: (a) your Account is inactive (i.e., not used or logged into) for one year; (b) you fail to comply with these Terms; (c) we suspect fraud or misuse by you of Virtual Currency, Virtual Goods, or other Content; (d) we suspect any other unlawful activity associated with your Account; or (e) we are acting to protect the app, game, site services, systems, users, or our reputation or the reputation of any of our partners."},
            {primary: "During the term given to use the Virtual Currency, you have the right to trade your Virtual Currency for the selected Virtual Goods. If you are the legal guardian and you are accepting these Terms on behalf of your child, you accept and acknowledge that your child has your consent to exercise this right independently."},
            {primary: 'Pricing and availability of Virtual Currency and Virtual Goods are subject to change without notice. We reserve the right at any time to change and update our pricing and inventory of Virtual Currency and Virtual Goods. As hereinafter provided, Virtual Currency, Virtual Goods and other Content is provided "as is" without any warranty. Every purchase, sale, and trade of Virtual Currency and Virtual Goods shall be final and non-refundable, unless otherwise determined by us at our discretion.'},
            {primary: "You will not receive any compensation for unused virtual currency or virtual goods when your account is closed either voluntary or involuntary."},
        ] },
    { displayText: "Usage Fees", secondary:
        [
            {bullet: [
                "The apps and games are free of charge. However, the user can pay money to disable advertising or purchase virtual accessories, additional features and extra capabilities. Tokket reserves the right to revise the fees for any purchases, subscriptions, or fees at any time",
                "Refunds: Except where required by law, purchases are non-refundable. The apps and games have been offered for free and costs have been incurred to develop, operate and support the applications and games functions and services.",
            ]},
        ] },
    { displayText: "Additional Rights We Retain", secondary:
        [
            {primary: "Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition, or sale of assets) or by law"}, 
        ] },
    { displayText: "Disclaimer And Limitations Of Liability", secondary:
        [
            {primary: "You use Services at your own risk. You understand and agree that any material or information downloaded or otherwise obtained through the use of the products, site, or services is done at your own risk and you will be solely liable for any damage arising from doing so. No advice or information."},
            {primary: "Although we don't condone it, you may be exposed to user content that might be indecent, offensive, harmful, inaccurate, objectionable, inappropriate or detrimental to third parties. We do not endorse, support, represent or warrant the quality, entirety, truthfulness, accuracy, or reliability of any user content or communications, or endorse any opinions expressed via our apps, games, sites and services. No advice or information received from our products, sites, or services will create any warranty."},
            {primary: "You accept that the use of the products, apps, games, sites and services is at your own risk and responsibility. Under no circumstances will we be liable in any way for any Content or user content, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the viewing, transmission, use or reliance on any content on our site or services or elsewhere, or any content that may be found to be indecent, offensive, harmful, inaccurate, objectionable or otherwise inappropriate, mislabeled or otherwise deceptive."},
            {primary: "Tokket and it’s officers, employees, directors, shareholders, affiliates, and other third party partners disclaim all warranties, conditions and representation of any kind, whether express, implied, statutory or otherwise. Our company makes no representations or warranties about the accuracy or completeness, timeliness, security or reliability of the service or content on the Service or the content of any website or online service linked or integrated with our Service."},
            {primary: "Tokket will have no liability for any: 1. Errors, mistakes, or inaccuracies of content 2. Personal injury, property damage or loss of data from accessing our Service. 3. Any unauthorized access to personal information, user data or our Service. 4. Any interruption of transmission to or from the Service or any deletion of or failure to sto store or transmit any content or communication 5. Any bug, virus, trojan horse or damaging computer code that may be transmitted through the service. 6. Failure of the Service to meet your expectations 7. Any loss or damage of any kind incurred as a result of any content posted or shared through the Service."},
            {primary: "You agree to indemnify and hold Tokket harmless from and against any and all claims, costs, proceedings, demands, losses, damages, and expenses (including, without limitation, reasonable attorney’s fees and legal costs) of any kind or nature, arising from or relating to, any actual or alleged breach of these Terms by you or anyone using your account, or your User Content. If we assume the defense of such a matter, you will reasonably cooperate with us in such defense. You agree to defend (at our request), indemnify and hold us harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable attorney's fees and costs, arising out of or in any way connected with these Terms or your use of the Service. You will cooperate as required by us in the defense of any claim."},
            {primary: "Our Service is provided 'as is,' and we can't guarantee it will be safe and secure or will work perfectly all the time. To the extent permitted by law, we disclaim all warranties, whether express or implied."},
            {primary: "We also don’t control what people and others do or say, and we aren’t responsible for their or your actions or conduct whether online or offline or content including unlawful or objectionable content. We also aren’t responsible for services and features offered by other people or companies, even if you access them through our Service."},
            {primary: "Our responsibility for anything that happens on the products, site, or service is limited as much as the law will allow. If there is an issue with our products, sites, or services, you agree that we won't be responsible ('liable') for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms. This includes when we delete your content, information, or account. Our aggregate liability arising out of or relating to these Terms will not exceed the greater of $50 or the amount you have paid us in the past six months."},
            {primary: "How We Will Handle Disputes."},
            {h4: "Disputes will be resolved on an individual basis."},
            {primary: "You agree to waive the rights to a class action lawsuit."},
        ] },
    { displayText: "Updating These Terms", secondary:
        [
            {primary: "We may change these Terms and will notify you by posting the revised Terms of Service. By continuing to use the Apps, Games, Sites or Services, you are agreeing to the revised Terms."},
            {primary: "If you do not want to agree to the Terms of Service, do not open an account or if the account is already created, just delete it."},
        ] },
]
function Terms() {
    return (
        <React.Fragment>
            <CssBaseline />

            <Navbar transparentTop={true} />
            <main style={{ paddingBottom: "unset" }}>
                <Container className={'banner'}>
                    <img src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header-aboutus.png" style={{ width: "100%", height: "570px" }} />
                    <div></div>
                    <Row className={'content-aboutus'}>
                        <Typography
                            align="center"
                            fontSize={'80px'}
                            fontWeight={'bold'}
                            color={'white'}
                            textTransform={'uppercase'}
                        >
                            Terms of Services
                        </Typography>
                        <Typography
                            align="center"
                            fontSize={'20px'}
                            color={'white'}
                        >
                            Tokket is a gamified social platform that blends social media, games, knowledge, and rewards.
                        </Typography>
                    </Row>

                </Container>
                <Container>
                    <Row style={{ marginBottom: "5px" }}>
                        <Col xs={12} style={{ marginBottom: "30px" }}>
                            <h4 style={{ fontSize: "45px" }}>Our approach</h4>
                            <div style={{ fontSize: "16px", color: "#818181", marginTop: "30px" }}>
                                Tokket is dedicated to making the world more positive and productive. When you share knowledge and information with us, you are helping people become more positive and productive.
                                This Privacy Policy applies to all our Products and Services which are mobile games and apps, web site games and apps, and other services.
                                When you use our products and services, you’ll share some information with us. We believe to be transparent with what we collect from you, how we use it, and how you can have control over both.
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <h4 style={{ fontSize: "45px" }}>Terms of Services</h4>
                            <List>
                                {termLinks.map((privacyLink, index) => (
                                    <ListItem className={'menu-list-link'}>
                                        <Link href={`#section-${index}`}>{privacyLink.displayText}</Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Col>
                        <Col md={8} xs={12}>
                            <List>
                                {termLinks.map((termLink, index) => (
                                    <ListItem style={{display: "block"}} id={`section-${index}`}>
                                        <h4 style={{ fontSize: "34px" }}>{termLink.displayText}</h4>
                                        <div style={{ fontSize: "16px", marginTop: "30px", color: "#818181", display: "grid", gridGap: "20px" }}>
                                            {termLink.secondary.map((secondary, index) => (
                                                <>
                                                    {secondary["primary"]? <div>{secondary["primary"]}</div>: ""}
                                                    
                                                    {secondary["bullet"]?
                                                        <ul style={{ listStyleType: 'circle'}}>
                                                             {secondary["bullet"].map((bullet, index) => (
                                                                    <li>
                                                                        <div>{bullet}</div>
                                                                    </li>
                                                                ))}
                                                        </ul>: ""}
                                                    {secondary["h4"]? <h4>{secondary["h4"]}</h4>: ""}
                                                </>
                                            ))}
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </Col>
                    </Row>

                </Container>
            </main>
            {/* Footer */}
            <Footer />
            {/* End footer */}
        </React.Fragment>
    );
}

export default Terms; 