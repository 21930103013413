import axios from 'axios';
import { getSetToken, getSetUserId, getToken } from './accountServices';
import { addParam, jsonKeySnakeCaseToCamelCase } from './helperTools';
import { cloneDeep } from 'lodash';
import { Tok } from '../interfaces/TokInterface';
import { Section } from '../interfaces/SectionInterface';
import { Group } from '../interfaces/GroupInterface';
import { Set } from '../interfaces/SetInterface';
import { GroupRequest } from '../interfaces/GroupRequestInterface';

const apiUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;
const apiCode = process.env.REACT_APP_API_CODE;
const apiImageUrl = process.env.REACT_APP_API_IMAGE_URL;
var userdata = [];
if (window.localStorage.getItem("userdata")) {
    try {
        userdata = JSON.parse(window.localStorage.getItem("userdata"));
    } catch (err) { }
}

export async function getToks(classtokQuery = {}, items = "", feedId) {
    let level1 = "";
    let level2 = "";
    let level3 = "";
    let publicfeed = false;
    let params = "";
    classtokQuery = jsonKeySnakeCaseToCamelCase(classtokQuery);
    if (classtokQuery["level1"]) {
        if (classtokQuery["level1"] == "mytoks") {
            level1 = "all";
            classtokQuery["getusertoks"] = true;
        } else {
            level1 = classtokQuery["level1"].toLowerCase()
            params = addParam(params, "level1", level1);
        }
    };
    if (classtokQuery["level2"]) {
        if (classtokQuery["level2"] == "private" || classtokQuery["level2"] == "public") {
            level1 = "all";
            classtokQuery["getusertoks"] = true;
            if (classtokQuery["level2"] == "private") {
                publicfeed = false;
            }
        } else {
            level2 = classtokQuery["level2"].toLowerCase()
            params = addParam(params, "level2", level2);
        }
    }
    if (classtokQuery["level3"]) {
        level3 = classtokQuery["level3"].toLowerCase()
        params = addParam(params, "level3", level3);
    }
    if (feedId) {
        params = addParam(params, "feedId", feedId);
        if (classtokQuery["setId"]) {
            params = addParam(params, "set_id", classtokQuery["setId"]);
        } else if (classtokQuery["groupId"]) {
            params = addParam(params, "group_id", classtokQuery["groupId"]);
        }
    }
    else if (classtokQuery["setId"]) {
        params = addParam(params, "feedId", classtokQuery["setId"]);
    } else if (classtokQuery["groupId"]) {
        params = addParam(params, "feedId", classtokQuery["groupId"]);
    } else if (!level1) {
        /*if (classtokQuery["publicfeed"] == true) {
            params = addParam(params, "feedId", "public");
        } else {
            const localProfile = localStorage.getItem("profile");
            params = addParam(params, "feedId", await getSetUserId());
            if(localProfile){
                const localProfileJson = JSON.parse(localProfile);
                if (localProfileJson["id"]) {
                    params = addParam(params, "profile_id", localProfileJson["id"]);
                }
            }
        }*/
        const localProfile = localStorage.getItem("profile");
        params = addParam(params, "feedId", await getSetUserId());
        if (localProfile) {
            const localProfileJson = JSON.parse(localProfile);
            if (localProfileJson["id"]) {
                params = addParam(params, "profile_id", localProfileJson["id"]);
            }
        }
    } else {
        if (level1 == "all") {
            //adjust api since this will involve all level toks owned by user
        } else {
            let levelFeed = level1;
            if (level2) {
                levelFeed = levelFeed + "-" + level2;
                if (level3) {
                    levelFeed = levelFeed + "-" + level3;
                }
            }
            params = addParam(params, "feedId", levelFeed);
        }
    }
    if (classtokQuery["searchkey"] && classtokQuery["searchvalue"]) {
        params = addParam(params, "%" + classtokQuery["searchkey"], classtokQuery["searchvalue"]);
    }
    if (classtokQuery["pagination"]) {
        params = addParam(params, "pagination", classtokQuery["pagination"]);
    }
    return await axios.get(`${apiImageUrl}/toks?${params ? "&" + params : ""}${items ? "&items=" + items : ""}`, {
        headers: {
            /*
            'level1': level1,
            'level2': level2,
            'level3': level3,
            'pagination': classtokQuery["pagination"] ? classtokQuery["pagination"] : "",
            'descending': classtokQuery["descending"] ? String(classtokQuery["descending"]) : true,
            'filterby': classtokQuery["FilterBy"] ? String(classtokQuery["FilterBy"]) : 0,
            'filteritems': classtokQuery["FilterItems"] ? JSON.stringify(classtokQuery["FilterItems"]) : [],
            
            'imagetype': classtokQuery["imagetype"] ? classtokQuery["imagetype"] : 0,
            'orderby': classtokQuery["orderby"] ? classtokQuery["orderby"] : "_ts",
            'communityid': classtokQuery["communityid"] ? classtokQuery["communityid"] : "",
            'pk': classtokQuery["pkbase"] ? classtokQuery["pkbase"] : "",
            'searchkey': classtokQuery["searchkey"] ? classtokQuery["searchkey"] : "",
            'searchvalue': classtokQuery["searchvalue"] ? classtokQuery["searchvalue"] : "",
            'classsetid': classtokQuery["classsetid"] ? classtokQuery["classsetid"] : "",
            'groupid': classtokQuery["group_id"] ? classtokQuery["group_id"] : "",
            'publicfeed': publicfeed,*/
            'Authorization': "Bearer " + await getSetToken(),
        }
    });
}

export async function getClassTok(id = "", pk = "") {
    return await axios.get(`${apiImageUrl}/toks/${id}`, {
        headers: {
            'pk': pk,
            'Authorization': "Bearer " + await getSetToken()
        }
    });
}

export async function updateClassTok(item = null) {
    if (item) {
        return await axios.put(`${apiImageUrl}/toks/${item["id"]}`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken()
            }
        });
    } else {
        return false;
    }
}

export async function patchTok(item = null, id = "", pk = "") {
    if (item && id && pk) {
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                item["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/toks/${id}?increment=accurates/1`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk
            }
        });
    } else {
        return false;
    }
}

export async function patchSet(item = null, id = "", pk = "") {
    if (item && id && pk) {
        const localUser = localStorage.getItem("userdata");
        const localProfile = localStorage.getItem("profile");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                item["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        if (localProfile) {
            const localProfileJson = JSON.parse(localProfile);
            if (localProfileJson["id"]) {
                item["user_display_name"] = localProfileJson["display_name"] ? localProfileJson["display_name"] : "";
                item["user_photo"] = localProfileJson["user_photo"] ? localProfileJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/sets/${id}`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk
            }
        });
    } else {
        return false;
    }
}

export async function patchGroup(item = null, id = "", pk = "") {
    if (item && id && pk) {
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                item["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/groups/${id}`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk
            }
        });
    } else {
        return false;
    }
}

export async function addClassTok(tok: Tok, feedid = "", items = "") {
    var newTok = cloneDeep(tok);
    if (newTok) {
        var feedId = feedid;
        let mainUserid = "";
        try {
            mainUserid = await getSetUserId();
        } catch (err) {

        }
        if (items != "bookmarks") {
            const localUser = localStorage.getItem("userdata");
            const localProfile = localStorage.getItem("profile");
            if (localUser) {
                const localUserJson = JSON.parse(localUser);
                if (localUserJson["id"]) {
                    newTok["user_id"] = await getSetUserId();
                    newTok["title_display"] = localUserJson["is_user_display_handle_enabled"] ? localUserJson["current_handle"] ? localUserJson["current_handle"] : "" : localUserJson["title_enabled"] ? localUserJson["title_id"] ? localUserJson["title_id"] : "" : "";
                    newTok["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                    newTok["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                    newTok["user_country"] = localUserJson["country"] ? localUserJson["country"] : "";
                    newTok["user_state"] = localUserJson["state"] ? localUserJson["state"] : "";
                }
            }
            if (localProfile) {
                const localProfileJson = JSON.parse(localProfile);
                if (localProfileJson["id"]) {
                    newTok["user_id"] = localProfileJson["id"];
                    newTok["profile_id"] = localProfileJson["id"];
                    newTok["title_display"] = localProfileJson["is_user_display_handle_enabled"] ? localProfileJson["current_handle"] ? localProfileJson["current_handle"] : "" : localProfileJson["title_enabled"] ? localProfileJson["title_id"] ? localProfileJson["title_id"] : "" : "";
                    newTok["user_display_name"] = localProfileJson["display_name"] ? localProfileJson["display_name"] : "";
                    newTok["user_photo"] = localProfileJson["user_photo"] ? localProfileJson["user_photo"] : "";
                    newTok["user_country"] = localProfileJson["country"] ? localProfileJson["country"] : "";
                    newTok["user_state"] = localProfileJson["state"] ? localProfileJson["state"] : "";
                }
            }
        }

        newTok["tok_type_id"] = 'toktype-' + newTok.tok_group.toLowerCase() + "-" + newTok.tok_type.toLowerCase();
        if (newTok.tok_group === "Detailed" || newTok.tok_group === "List") {
            newTok["is_detail_based"] = true;
            newTok["accurates_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            newTok["inaccurates_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            newTok["gemas_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            newTok["gembs_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            newTok["gemcs_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            newTok["hearts_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            newTok["sads_details"] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        if (newTok.tok_group === "Mega" || newTok.tok_group === "Q&a") {
            newTok["is_mega_tok"] = true;
        } else {
            delete newTok["sections"];
        }
        if (newTok.master_copy) {
            newTok["has_reactions"] = false;
            newTok["has_comments"] = false;
        } else {
            newTok["has_reactions"] = true;
            newTok["has_comments"] = true;
        }
        return await axios.post(`${apiImageUrl}/toks?userCounter=0${feedId ? "&feedId=" + feedId : "&feedId=" + mainUserid}${items ? "&items=" + items : ""}`, newTok,
            {
                headers: {
                    /*
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'tokgroupid': tok.tok_group.toLowerCase(),
                    'toktypeid': tok.tok_type_id,
                    'category': 'category-' + tok.category.toLowerCase(),
                    'private': isPrivate,
                    'group': copytype == "Group" ? 'true' : tok["group_id"] ? 'true' : 'false',
                    'public': isPublic,
                    
                    'copytype': copytype ? copytype : "",
                    'communityname': tok["community_name"] ? tok["community_name"] : "",*/
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function addSection(section: Section, feedId, groupid = "") {
    if (section && feedId) {
        const localUser = localStorage.getItem("userdata");
        const localProfile = localStorage.getItem("profile");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                section["user_id"] = localUserJson["id"] ? localUserJson["id"] : "";
                section["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                section["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "https://tokketcontent.blob.core.windows.net/tokketweb/default.png";
                section["user_country"] = localUserJson["country"] ? localUserJson["country"] : "";
                section["user_state"] = localUserJson["state"] ? localUserJson["state"] : "";
                section["account_type"] = localUserJson["account_type"] ? localUserJson["account_type"] : "";
                section["group_id"] = groupid ? groupid : "";
            }
        }
        if (localProfile) {
            const localProfileJson = JSON.parse(localProfile);
            if (localProfileJson["id"]) {
                section["user_id"] = localProfileJson["id"] ? localProfileJson["id"] : "";
                section["user_display_name"] = localProfileJson["display_name"] ? localProfileJson["display_name"] : "";
                section["user_photo"] = localProfileJson["user_photo"] ? localProfileJson["user_photo"] : "https://tokketcontent.blob.core.windows.net/tokketweb/default.png";
                section["user_country"] = localProfileJson["country"] ? localProfileJson["country"] : "";
                section["user_state"] = localProfileJson["state"] ? localProfileJson["state"] : "";
                section["account_type"] = localProfileJson["account_type"] ? localProfileJson["account_type"] : "";
                section["group_id"] = groupid ? groupid : "";
            }
        }
        return await axios.post(`${apiImageUrl}/toksections?userCounter=0&feedId=${feedId}`, section,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function getSections(query, feedId = "") {
    if (query) {
        query = jsonKeySnakeCaseToCamelCase(query);
        let params = "";
        let keys = Object.keys(query)
        for (let x = 0; x < keys.length; x++) {
            params = addParam(params, keys[x], query[keys[x]]);
        }
        return await axios.get(`${apiImageUrl}/toksections?userCounter=0${feedId ? "&feedId=" + feedId : ""}${params ? "&" + params : ""}`,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function deleteClassTok(id, pk) {
    if (id && pk) {
        return await axios.delete(`${apiImageUrl}/toks/${id}`,
            {
                headers: {
                    'pk': pk,
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function deleteSection(id, pk, tokid) {
    if (id && pk) {
        return await axios.delete(`${apiImageUrl}/toksections/${id}`,
            {
                headers: {
                    'pk': pk,
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function updateSection(section) {
    if (section) {
        return await axios.put(`${apiImageUrl}/toksections/${section.id}`, section,
            {
                headers: {
                    'pk': section.pk,
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function patchSection(item = null, id = "", pk = "") {
    if (item && id && pk) {
        const localUser = localStorage.getItem("userdata");
        const localProfile = localStorage.getItem("profile");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                item["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        if (localProfile) {
            const localProfileJson = JSON.parse(localProfile);
            if (localProfileJson["id"]) {
                item["user_display_name"] = localProfileJson["display_name"] ? localProfileJson["display_name"] : "";
                item["user_photo"] = localProfileJson["user_photo"] ? localProfileJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/toksections/${id}`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk
            }
        });
    } else {
        return false;
    }
}

export async function getCommunities(query) {
    if (query) {
        return await axios.get(`${apiUrl}${apiVersion}/communities/?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'pk': "channelgroups",
                    'level0': query["level1"] ? query["level1"] : "",
                    'level1': query["level2"] ? query["level2"] : "",
                    'level2': query["level3"] ? query["level3"] : "",
                    'level3': query["level4"] ? query["level4"] : "",
                    'communityid': "",
                    'text': query["search"] ? query["search"] : "",
                    'pagination': query["pagination"] ? query["pagination"] : "",
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function getGroups(query = {}, feedId = "") {
    query = cloneDeep(jsonKeySnakeCaseToCamelCase(query));
    let params = "";
    if (query["text"]) {
        params = addParam(params, "%name", query["text"]);
        delete query["text"];
    }
    if (query["group_kind"]) {
        if (query["group_kind"] != "Owned") {
            params = addParam(params, "group_kind", query["group_kind"]);
        }
        delete query["group_kind"];
    }
    if (!feedId) {
        if (!query["disableuserid"]) {
            //params = addParam(params, "user_id", await getSetUserId());
            params = addParam(params, "feedId", await getSetUserId());
        } else if (query["communityId"]) {
            params = addParam(params, "feedId", query["communityId"]);
        } else {
            if (query["items"]) {
                if (query["items"] != "channelgroups") {
                    params = addParam(params, "feedId", "classgroups");
                }
            } else {
                params = addParam(params, "feedId", "classgroups");
            }
        }
        delete query["disableuserid"];
    } else {
        params = addParam(params, "feedId", feedId);
    }
    let keys = Object.keys(query)
    for (let x = 0; x < keys.length; x++) {
        params = addParam(params, keys[x], query[keys[x]]);
    }

    const localProfile = localStorage.getItem("profile");
    if (localProfile) {
        const localProfileJson = JSON.parse(localProfile);
        if (localProfileJson["id"]) {
            params = addParam(params, "profile_id", localProfileJson["id"]);
        }
    }
    var result = await axios.get(`${apiImageUrl}/groups?${params ? "&" + params : ""}`,
        {
            headers: {
                /*
                'serviceid': 'tokket',
                'deviceplatform': 'web',
                'pk': query["pk"] ? query["pk"] : "",
                'communityid': query["communityid"] ? query["communityid"] : "",
                
                'groupkind': groupkind,
                'pagination': query["pagination"] ? query["pagination"] : "",
                'joined': 'false',
                'text': query["text"] ? query["text"] : "",*/
                'userid': await getSetUserId(),
                'Authorization': "Bearer " + await getSetToken()
            }
        });
    /*
    if (ownerfeed) {
        var resultJoined = await axios.get(`${apiImageUrl}/groups`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'pk': query["pk"] ? query["pk"] : "",
                    'communityid': query["communityid"] ? query["communityid"] : "",
                    'userid': await getSetUserId(),
                    'groupkind': groupkind,
                    'pagination': query["pagination"] ? query["pagination"] : "",
                    'joined': 'true',
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
        try {
            result.data.results = result.data.results.concat(resultJoined.data.results);
        } catch (err) { }
    }*/
    return result;
}

export async function updateGroup(group) {
    if (group) {
        return await axios.put(`${apiImageUrl}/groups/${group.id}?`, group,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function addGroup(group: Group, feedId = "", items = "") {
    if (group) {
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            const localProfile = localStorage.getItem("profile");
            if (localUserJson["id"]) {
                group["user_id"] = localUserJson["id"];
                feedId = localUserJson["id"];
            }
            if (localProfile) {
                const localProfileJson = JSON.parse(localProfile);
                group["user_id"] = localProfileJson["id"] ? localProfileJson["id"] : group["user_id"];
                group["profile_id"] = localProfileJson["id"] ? localProfileJson["id"] : "";
            }
        }
        return await axios.post(`${apiImageUrl}/groups?&userCounter=0${feedId ? "&feedId=" + feedId : "&feedId=" + group["user_id"]}${items ? "&items=" + items : ""}`, group,
            {
                headers: {
                    'userid': await getSetUserId(),
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function deleteGroup(id, pk) {
    if (id && pk) {
        return await axios.delete(`${apiImageUrl}/groups/${id}`,
            {
                headers: {
                    'pk': pk,
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function getFilterOptions(query) {
    if (query) {
        let filterBy = "", recent = true, pagination = "";
        if (query["FilterBy"]) filterBy = query.FilterBy;
        if (query["recent"]) recent = query.recent;
        if (query["pagination"]) pagination = query.pagination;
        return await axios.get(`${apiUrl}${apiVersion}/filterby?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'filterby': filterBy,
                    'recent': recent,
                    'pagination': pagination,
                    'Authorization': "Bearer " + await getSetToken(),
                    'userid': await getSetUserId(),
                }
            });
    } else {
        return false;
    }
}

export async function updateHandle(handle, id = "", pk = "") {
    if (handle && id && pk) {
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                handle["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                handle["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        return await axios.put(`${apiImageUrl}/tokhandles/${id}`, handle,
            {
                headers: {
                    'pk': pk,
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function patchHandle(handle, id, pk) {
    if (handle && id && pk) {
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                handle["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                handle["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/tokhandles/${id}`, handle,
            {
                headers: {
                    'pk': pk,
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function getClassSets(query) {
    if (query) {
        let params = "";
        query = jsonKeySnakeCaseToCamelCase(query);
        const localUser = localStorage.getItem("userdata");
        if (query["name"]) {
            params = addParam(params, "name", query["name"]);
        }
        if (query["group_id"]) {
            params = addParam(params, "feedId", query["group_id"]);
        } else {
            params = addParam(params, "feedId", await getSetUserId());
        }
        const localProfile = localStorage.getItem("profile");
        if (localProfile) {
            const localProfileJson = JSON.parse(localProfile);
            if (localProfileJson["id"]) {
                params = addParam(params, "profile_id", localProfileJson["id"]);
            }
        }
        return await axios.get(`${apiImageUrl}/sets?${params ? "&" + params : ""}`,
            {
                headers: {
                    /* 
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'groupid': query["groupid"] ? query["groupid"] : "",
                    
                    'pagination': query["pagination"] ? query["pagination"] : "",
                    'pk': query["pk"] ? query["pk"] : "classsets",
                    'label': query["label"] ? query["label"] : "",
                    'public': query["public"] ? query["public"] : "",
                    'name': query["name"] ? query["name"] : "",
                    'grouponly': query["grouponly"] ? query["grouponly"] : "",
                    'feedtype': query["feedtype"] ? query["feedtype"] : "0",*/
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function getClassSet(id = "", pk = "") {
    if (!pk) {
        pk = await getSetUserId() + "-sets0"
    }
    return await axios.get(`${apiImageUrl}/sets/${id}`, {
        headers: {
            'pk': pk,
            'Authorization': "Bearer " + await getSetToken()
        }
    });
}

export async function getClassGroupRequests(query) {
    if (query) {
        //status
        // 0 - Pending
        // 1 - Pending invite
        // 2 - Accepted
        // 3 - Declined
        // 4 - All
        query = jsonKeySnakeCaseToCamelCase(query);
        let params = "";
        let keys = Object.keys(query)
        for (let x = 0; x < keys.length; x++) {
            params = addParam(params, keys[x], query[keys[x]]);
        }
        let userid = await getSetUserId();
        return await axios.get(`${apiImageUrl}/notifications?userCounter=0${userid ? "&feedId=" + userid : ""}&label=classgrouprequest${params ? "&" + params : ""}`,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function patchRequest(id, pk, status = -1, group, newFeedId = "") {
    if (id && pk && status >= 0 && group) {
        var item = {
            status: status,
            label: "classgrouprequest",
            group: group,
            group_pk: group["pk"],
            new_feed_id: newFeedId,
            user_id: group["user_id"]
        }
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                item["receiver_displayname"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["receiver_image"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                item["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/notifications/${id}`, item,
            {
                headers: {
                    'pk': pk ? pk : "",
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function getNotifications(query, feedId, items) {
    if (await getSetUserId()) {
        //sender!=userid
        //add token
        let userid = await getSetUserId();
        return await axios.get(`${apiImageUrl}/notifications?userCounter=0&!senderId=${userid}&!kind=pagevisit_views_personal${feedId ? "&feedId=" + feedId : "&feedId=" + userid}${items ? "&items=" + items : ""}`,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function readNotification(id, pk) {
    if (await getSetUserId() && id && pk) {
        var item = {
            is_read: true,
            is_seen: true,
            read: true,
        }
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                item["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                item["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
            }
        }
        return await axios.patch(`${apiImageUrl}/notifications/${id}`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk
            }
        });
    } else {
        return false;
    }
}
//use for testing, will be adjusted to be used later on for classgroup pages.
export async function getGroup(id, pk = "") {
    if (id) {
        if (!pk) {
            pk = await getSetUserId() + "-groups0"
        }
        return await axios.get(`${apiImageUrl}/groups/${id}`,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                    'pk': pk
                }
            });
    } else {
        return false;
    }
}

export async function getGroupMembers(id) {
    if (id) {
        return await axios.get(`${apiUrl}${apiVersion}/groupmembers/${id}?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'Authorization': "Bearer " + await getSetToken(),
                    'userid': await getSetUserId(),
                    'pk': id + "-classgroupmembers"
                }
            });
    } else {
        return false;
    }
}

export async function addClassSet(set: Set) {
    if (set) {
        var feedId = "";
        set["user_id"] = await getSetUserId();
        const localUser = localStorage.getItem("userdata");
        if (localUser) {
            const localUser = localStorage.getItem("userdata");
            const localProfile = localStorage.getItem("profile");
            feedId = cloneDeep(set["user_id"]);
            if (localProfile) {
                const localProfileJson = JSON.parse(localProfile);
                if (localProfileJson["id"]) {
                    set["user_id"] = localProfileJson["id"];
                    set["profile_id"] = localProfileJson["id"];
                    set["title_display"] = localProfileJson["is_user_display_handle_enabled"] ? localProfileJson["current_handle"] ? localProfileJson["current_handle"] : "" : localProfileJson["title_enabled"] ? localProfileJson["title_id"] ? localProfileJson["title_id"] : "" : "";
                    set["user_display_name"] = localProfileJson["display_name"] ? localProfileJson["display_name"] : "";
                    set["user_photo"] = localProfileJson["user_photo"] ? localProfileJson["user_photo"] : "";
                    set["user_country"] = localProfileJson["country"] ? localProfileJson["country"] : "";
                    set["user_state"] = localProfileJson["state"] ? localProfileJson["state"] : "";
                }
            } else {
                const localUserJson = JSON.parse(localUser);
                if (localUserJson["id"]) {
                    set["user_id"] = await getSetUserId();
                    set["title_display"] = localUserJson["is_user_display_handle_enabled"] ? localUserJson["current_handle"] ? localUserJson["current_handle"] : "" : localUserJson["title_enabled"] ? localUserJson["title_id"] ? localUserJson["title_id"] : "" : "";
                    set["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                    set["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                    set["user_country"] = localUserJson["country"] ? localUserJson["country"] : "";
                    set["user_state"] = localUserJson["state"] ? localUserJson["state"] : "";
                }
            }
        }
        if (set["group_id"]) {
            feedId = set["group_id"];
        }
        return await axios.post(`${apiImageUrl}/sets?userCounter=0${feedId ? "&feedId=" + feedId : "&feedId=" + set["user_id"]}`, set,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function updateClassSet(set) {
    if (set["id"] && set["pk"]) {
        return await axios.put(`${apiImageUrl}/sets/${set.id}`, set,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken()
                }
            });
    } else {
        return false;
    }
}

export async function deleteSet(set) {
    if (set["id"] && set["pk"]) {
        return await axios.delete(`${apiImageUrl}/sets/${set.id}`,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                    'pk': set.pk ? set.pk : "",
                }
            });
    } else {
        return false;
    }
}

export async function addTokPak(pak) {
    if (pak) {
        return true;
    } else {
        return false;
    }
}

export async function updateTokPak(pak) {
    if (pak["id"] && pak["pk"]) {
        return true
    } else {
        return false;
    }
}

export async function deleteTokPak(pak) {
    if (pak["id"] && pak["pk"]) {
        return true;
    } else {
        return false;
    }
}

export async function addClassToksToSet(setid, setpk, tokids = [], tokpks = []) {
    if (setid && setpk && tokids && tokpks) {
        return await axios.post(`${apiUrl}${apiVersion}/tokstoset/${setid}?code=${apiCode}`, "",
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'Authorization': "Bearer " + await getSetToken(),
                    'userid': await getSetUserId(),
                    'pk': setpk,
                    'tokids': JSON.stringify(tokids),
                    'tokpks': JSON.stringify(tokpks),
                }
            });
    } else {
        return false;
    }
}

export async function sendRequestGroup(request: GroupRequest) {
    if (request && request["receiver_id"]) {
        return await axios.post(`${apiImageUrl}/notifications?userCounter=0&feedId=${request["receiver_id"]}`, request,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function removeToksFromSet(set: Set, ids = new Array) {
    if (set && ids.length > 0) {
        return await axios.delete(`${apiUrl}${apiVersion}/toksfromset/${set["id"]}?code=${apiCode}`,
            {
                headers: {
                    'serviceid': 'tokket',
                    'deviceplatform': 'web',
                    'pk': set["pk"],
                    'tokids': JSON.stringify(ids),
                    'Authorization': "Bearer " + await getSetToken(),
                    'userid': await getSetUserId()
                }
            });
    } else {
        return false;
    }
}

export async function apiTest(array, limit) {
}