import * as React from 'react';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';
const Footer = (props) => {
    const disableAbsolute = props.disableAbsolute;
    return (
        <footer className={'dark-background-1 text-color-white'} style={{
            backgroundColor: "#faf4ff",
            position: disableAbsolute? "unset": "absolute",
            padding: "6px"
        }}>
            <Row>
                <Col xs={12} md={4} sm={12} style={{ padding: "unset" }}>
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", height: "165px" }}>
                        <img style={{ width: "220px" }} src={'https://tokketcontent.blob.core.windows.net/tokketweb/tokketlogowpurple.png'} alt={'Tokket'} />
                    </div>
                    <hr style={{ marginTop: "50px", marginBottom: "25px", width: "100%" }} />
                </Col>
                <Col xs={12} md={4} sm={12} style={{ padding: "unset" }}>
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", height: "165px" }}>
                        <Typography variant={"h6"} align={"center"} fontWeight={'bold'} marginBottom={'30px'}>Helpful Links</Typography>
                        <div style={{ display: "grid", justifyContent: "center", gridGap: "15px", justifyItems: "center" }}>
                            <NavLink color="inherit" to="/privacy" style={{ textDecoration: "none", color: "black" }}>
                                Privacy Policy
                            </NavLink>
                            <NavLink color="inherit" to="/terms" style={{ textDecoration: "none", color: "black" }}>
                                Terms of Services
                            </NavLink>
                            <NavLink color="inherit" to="/faq" style={{ textDecoration: "none", color: "black" }}>
                                Frequently Asked Questions
                            </NavLink>
                        </div>
                    </div>
                    <hr style={{ marginTop: "50px", marginBottom: "25px", width: "100%" }} />
                </Col>
                <Col xs={12} md={4} sm={12} style={{ padding: "unset" }}>
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", height: "165px" }}>
                        <Typography variant={"h6"} align={"center"} fontWeight={'bold'}>Contact Us</Typography>
                        <Typography className={'text-color-white'}
                            variant="subtitle1"
                            align="center"
                            color="textSecondary"
                            component="p"
                        >
                            info@tokket.com
                        </Typography>
                    </div>
                    <hr style={{ marginTop: "50px", marginBottom: "25px", width: "100%" }} />
                </Col>
                <Col xs={12}>
                    <Typography variant="body2" color="textSecondary" align="center" className={'text-color-white'}>
                        {"Copyright � 2023 All rights reserved | Made by "}
                        <Link color="inherit" href="https://tokket.com/">
                            Tokket
                        </Link>
                        {" team."}
                    </Typography>
                </Col>
            </Row>
        </footer>
    );
}

export default Footer;