import React, { useState } from 'react'
import '../styles.css'
import './css/QuizToks.css';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import arrayShuffle from 'array-shuffle';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const QuizToks = (props) => {
    var items = props.items;
    var displayScore = props.displayScore? displayScore: false;
    const [round, SetRound] = useState(0);
    const [point, SetPoint] = useState(0);
    const [wrong, SetWrong] = useState(0);

    const [questionAnswer, SetQuestionAnswer] = useState(() => {
        return randomizeQuestions(items);
    });

    function randomizeQuestions(array) {
        var questionsAnswers = new Array;
        var defaultArray = array;
        for (let x = 0; x < array.length; x++) {
            var tempShuffledAnswers = arrayShuffle(defaultArray);
            var shuffledAnswers = new Array;
            var hints = new Array;
            var tempHints = new Array;
            for (let y = 0, z = 0; y < tempShuffledAnswers.length; y++) {
                if (array[x]["id"] != tempShuffledAnswers[y]["id"]) {
                    shuffledAnswers.push(tempShuffledAnswers[y]);
                    z++;
                }
                if (z == 3) {
                    break;
                }
            }
            shuffledAnswers.push(array[x]);
            if (array[x]["tok_group"].toLowerCase() == "detail" || array[x]["tok_group"].toLowerCase() == "detailed") {
                tempHints = arrayShuffle(array[x]["details"].filter(n => n));
                for (let y = 0; y < tempHints.length; y++) {
                    hints.push(tempHints[y]);
                    if (y == 2) {
                        break;
                    }
                }
            } else {
                let separatedSecondary = array[x]["secondary_text"].split(/\s+/);
                let wordsPerLine = Math.ceil(separatedSecondary.length / 3);
                for (let x = 0; x < 3; x++) {
                    let hintTemp = new Array;
                    for (let y = 0; y < wordsPerLine; y++) {
                        let value = separatedSecondary[y + x * wordsPerLine]
                        hintTemp.push(value)
                    }
                    hints.push(hintTemp.join(" "));
                    hints = hints.filter(n => n);
                }
            }
            let question = {
                question: array[x],
                answers: arrayShuffle(shuffledAnswers),
                hints: hints,
                removeHints: []
            }
            questionsAnswers.push(question);
        }
        return questionsAnswers;
    }

    const selectAnswer = (btn) => {
        var count = Number(btn.target.getAttribute("data-count"));
        if (questionAnswer[round]["answers"][count]["id"] == questionAnswer[round]["question"]["id"]) {
            let currentPoints = point + 1;
            SetPoint(currentPoints);
            nextRound();
            MySwal.fire({
                title: <p>Correct.</p>,
                allowOutsideClick: false,
                icon: "success"
            }); 
        } else {
            let removeHints = questionAnswer[round]["removeHints"];
            let tempQuestionAnswer = questionAnswer;
            let currentWrong = wrong + 1;
            removeHints.push(count);
            tempQuestionAnswer[round]["removeHints"] = removeHints;
            SetQuestionAnswer(questionAnswer => ({
                ...questionAnswer,
                ...tempQuestionAnswer
            }));
            SetWrong(currentWrong);
            let guessCount = questionAnswer[round]["answers"].length - (1 + questionAnswer[round]["removeHints"].length);
            MySwal.fire({
                title: <p>Incorrect. You have {guessCount} guess{guessCount > 1 ? "es" : ""} left.</p>,
                allowOutsideClick: false,
                icon: "error"
            });
        }
    }
    const nextRound = () => {
        let currentRound = round + 1;
        if (currentRound < items.length) {
            SetRound(currentRound);
        } else {
            MySwal.fire({
                title: displayScore? <p>You have completed {point} toks in the set.</p>: <p>Practice Test Complete</p>,
                text: "You have completed " + items.length + " toks in the practice test.",
                allowOutsideClick: false,
                confirmButtonText: 'Redo Set',
                icon: "info",
                confirmButtonColor: "#007bff"
            }).then((result) => {
                if (result) {
                    let tempQuestionAnswer = questionAnswer;
                    for (let x = 0; x < tempQuestionAnswer.length; x++) {
                        tempQuestionAnswer[x]["removeHints"] = [];
                    }
                    SetQuestionAnswer(randomizeQuestions(items));
                    SetRound(0);
                    SetPoint(0);
                    SetWrong(0);
                }
            });
        }
    }
    
    return (
        <div>
            <Row style={{ justifyContent: "center", marginBottom: "10px" }}>
                <Col xs={'auto'} className={'quiz-border'} style={{ marginRight: "2.5px" }}>Category: {questionAnswer[round]["question"]["category"]}</Col>
                <Col xs={'auto'} className={'quiz-border'} style={{ marginLeft: "2.5px" }}>Tok #{round + 1} of {items.length}</Col>
            </Row>
            <Row className={'quiz-border'} style={{ padding: "10px 20px", marginBottom: "10px" }}>
                {questionAnswer[round]["hints"].map((hint, index) => (
                    <Col xs={12} className={'quiz-border quiz-answer'}>{hint? hint : "No Hint"}</Col>
                ))}
            </Row>
            <Row style={{ justifyContent: "center" }}>
                {questionAnswer[round]["answers"].map((answer, index) => (
                    <>
                        {!questionAnswer[round]["removeHints"].includes(index) ?
                            <Col xs={6}>
                                <div className='quiz-tok-option' onClick={selectAnswer} data-count={index}>
                                    {answer["primary_text"]}
                                </div>
                            </Col> : ""
                        }
                    </>

                ))}
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <div className='quiz-tok-option' style={{ minWidth: "25vh", width: "fit-content" }} onClick={nextRound}>Next Tok</div>
            </Row>
        </div>
    )
}

export default QuizToks;