import React from 'react';
import { Box } from '@mui/system';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles.css'
import TokView from './TokView';
import { createRef } from 'react';
const TokTileView = (props) => {
    const tok = props.tok;
    const groupId = props.groupId? props.groupId: "";
    const items = props.items? props.items: "";
    var hrefLink = "/classtoks/tok/" + tok.id + "/" + tok.pk;
    if(tok["level1"]){
        hrefLink = "/tokchannels/tok/" + tok.id + "/" + tok.pk;
    }
    window.history.pushState({}, "Tokket", hrefLink);
    const deleteTok = (data) =>{
        props.deleteTok(data);
    }
    const updateTok = (data) =>{
        props.updateTok(data);
    }
    const closeInfoView = () =>{
        props.closeInfoView();
    }
    const addViewTok = (data) =>{
        props.addViewTok(data);
    }
    return (
        <Box style={{ height: "100%", width: "100%", position: "fixed", top: "0", left: "0", overflow: "hidden", zIndex: "1300", backgroundColor: "white" }}>
            <AppBar style={{ backgroundColor: "#884bdf", minHeight: "65px" }}>
                <Toolbar>
                    <IconButton size="large"
                        color="inherit"
                        onClick={closeInfoView}
                    >
                        <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ fontWeight: "bold" }}>
                        Tok Info
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box style={{ marginTop: "65px", position: "relative", height: "100%" }}>
                <TokView linkZIndex={undefined} tok={tok} groupId={groupId} deleteTok={deleteTok} items={items} updateTok={updateTok} />
            </Box>
        </Box>
    )
}
export default TokTileView;