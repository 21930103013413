import arrayShuffle from 'array-shuffle';
import React, { createRef } from 'react'
import TokChannelNavbar from './TokChannelNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './../App.css';
import TokTileContainer from './TokTileContainer';

import TokChannelTitlebar from './TokChannelTitlebar';
import '../pages/TokChannel.css';
import { textAlign } from '@mui/system';

import Button from '@mui/material/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CommunitiesContainer from './CommunitiesContainer';

import ProxyTitleBar from './ProxyTitleBar';

import GroupsContainer from './GroupsContainer';
import TokInfoView from './TokInfoView';
import GroupInfoView from './GroupInfoView';
import { addGroup, deleteGroup, getGroups } from '../services/classServices';
import { idFormat, randomString, textDisplay, textDisplayFormat, upperCasesFirstLetter } from '../services/helperTools';
import { cloneDeep, shuffle } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import BootstrapButton from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Box } from '@mui/material';
import { Group } from '../interfaces/GroupInterface';
import { TokUser } from '../interfaces/TokUserInterface';
import { Tok } from '../interfaces/TokInterface';

const MySwal = withReactContent(Swal);
const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];

interface States {
    level1: string;
    level2: string;
    level3: string;
    tokid: string;
    tokpk: string;
    group: Group;
    localUser: TokUser;
    ShowCommunities: boolean;
    ActiveTokChannelTitlebar: boolean;
    tokData: {
        query: { [key: string]: any };
        items: Tok[];
        continuationToken: string;
    }[];
    ActiveTokChannelNavbar: boolean;
    query: { [key: string]: any };
    ShowTokTiles: boolean;
    items: Tok[];
    continuationToken: string;
    ClassChannel: boolean;
    familyEmptyWarning: boolean;
    activeAddFavoriteProgress: {
        level1: string;
        level2: string;
        level3: string;
    }[];
    currentFavoriteList: Group[];
    tokchannelNavbarKey: string;
    level1list: Group[];
    level2list: Group[];
    level3list: Group[];
    level1Colors: string[];
    level2Colors: string[];
    level3Colors: string[];
    colorCounter1: number;
    colorCounter2: number;
    colorCounter3: number;
    addChannelLevel: number;
    addChannelShow: boolean;
    newChannelName: string;
}

interface Props {
    level1: string;
    level2: string;
    level3: string;
    tokid: string;
    tokpk: string;
    group: Group;
    classchannel: boolean;
    loginCallTrigger: React.RefObject<any>;
    signupTrigger: React.RefObject<any>;
}

class TokChannelBody extends React.Component<Props, States> {
    tokchannelTile: React.RefObject<any>;
    tokchannelTitlebar: React.RefObject<any>;
    tokTileTitleBar: React.RefObject<any>;
    communityTitleBar: React.RefObject<any>;
    communityAddBtnProxy: React.RefObject<any>;
    proxyTitleBar: React.RefObject<any>;
    communityContainer: React.RefObject<any>;
    mainBody: React.RefObject<any>;
    tokchannelNavbarRef: React.RefObject<any>;
    tokchannelFavoriteNavbarRef: React.RefObject<any>;
    loginCallTrigger: React.RefObject<any>;
    signupTrigger: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);
        const level1 = props.level1;
        const level2 = props.level2;
        const level3 = props.level3;
        const tokid = props.tokid;
        const tokpk = props.tokpk;
        const group = props.group;
        const localUser = !(localStorage.getItem("userdata") === null) ? localStorage.getItem("userdata") : [];
        this.state = {
            level1: level1,
            level2: level2,
            level3: level3,
            tokid: tokid,
            tokpk: tokpk,
            group: group ? group : [],
            localUser: localUser.length != 0 ? JSON.parse(localUser as string) : {},
            ShowCommunities: false,
            ActiveTokChannelTitlebar: true,
            tokData: new Array,
            ActiveTokChannelNavbar: true,
            query: {
                level1: level1,
                level2: level2,
                level3: level3,
                pkbase: "channeltoks"
            },
            ShowTokTiles: true,
            items: [],
            continuationToken: "",
            ClassChannel: props.classchannel ? true : false,
            familyEmptyWarning: false,
            activeAddFavoriteProgress: new Array,
            currentFavoriteList: new Array,
            tokchannelNavbarKey: randomString(10),
            level1list: new Array,
            level2list: new Array,
            level3list: new Array,
            level1Colors: shuffle(colors),
            level2Colors: shuffle(colors),
            level3Colors: shuffle(colors),
            colorCounter1: 0,
            colorCounter2: 0,
            colorCounter3: 0,
            addChannelLevel: 0,
            addChannelShow: false,
            newChannelName: ""
        };
        this.tokchannelTile = createRef();
        this.tokchannelTitlebar = createRef();
        this.tokTileTitleBar = createRef();
        this.communityTitleBar = createRef();
        this.communityAddBtnProxy = createRef();
        this.proxyTitleBar = createRef();
        this.communityContainer = createRef();
        this.mainBody = createRef();
        this.tokchannelNavbarRef = createRef();

        this.handleDrawerTokChannelTitlebar = this.handleDrawerTokChannelTitlebar.bind(this);
        this.levelSet = this.levelSet.bind(this);
        this.sendData = this.sendData.bind(this);
        this.tokchannelData = this.tokchannelData.bind(this);

        this.handleCommunitiesHide = this.handleCommunitiesHide.bind(this);
        this.handleCommunitiesShow = this.handleCommunitiesShow.bind(this);
        this.saveTokData = this.saveTokData.bind(this);

        this.updateLeftNav = this.updateLeftNav.bind(this);

        this.addRemoveFavorite = this.addRemoveFavorite.bind(this);
        this.tokchannelFavoriteNavbarRef = createRef();
        this.updateFavoriteLeftNav = this.updateFavoriteLeftNav.bind(this);

        this.loginCallTrigger = props.loginCallTrigger;
        this.signupTrigger = props.signupTrigger;

        this.setFavorites = this.setFavorites.bind(this);

        this.initilizeLevels = this.initilizeLevels.bind(this);
        this.openAddChannel = this.openAddChannel.bind(this);
        this.closeAddChannel = this.closeAddChannel.bind(this);
        this.setAddChannelName = this.setAddChannelName.bind(this);
        this.postNewChannel = this.postNewChannel.bind(this);
        this.openDeleteChannel = this.openDeleteChannel.bind(this);
    }

    componentDidMount() {
        var { level1, level2 } = this.state;

        this.initilizeLevels();
    }
    openDeleteChannel() {
        const { level3, level2, level1, level1list, level2list, level3list } = this.state;
        var tokchannelDataSet = this.tokchannelData;
        MySwal.fire({
            title: <p>Are you sure want to delete channel {textDisplay(level3 ? level3 : level2 ? level2 : level1)}?</p>,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333",
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                let selectedChannel = cloneDeep(level3 ? level3list.filter(item => item["level2"] == level3 && item["level1"] == level2 && item["level0"] == level1)[0] : level2 ? level2list.filter(item => item["level1"] == level2 && item["level0"] == level1)[0] : level1 ? level1list.filter(item => item["level0"] == level1)[0] : {});
                let channelLevel = level3 ? 3 : level2 ? 2 : level1 ? 1 : 0;
                if (selectedChannel["id"]) {
                    let isSuccess = false;
                    var channelMsg = toast.loading('Deleting Channel');
                    let levelListUse = cloneDeep(level3 ? level3list : level2 ? level2list : level1 ? level1list : new Array);
                    let transferToChannel = cloneDeep(level3 ? level2list.filter(item => item["level1"] == level2 && item["level0"] == level1)[0] : level2 ? level1list.filter(item => item["level0"] == level1)[0] : level1 ? level1list.filter(item => item["level0"] == "mytoks")[0] : {});
                    deleteGroup(selectedChannel["id"], selectedChannel["pk"]).then(res => {
                        if(res){
                            if (res["data"]["status_code"] == 200) {
                                levelListUse = levelListUse.filter(item => item["id"] != selectedChannel["id"] && item["pk"] != selectedChannel["pk"])
                                isSuccess = true;
                            }
                        }
                    }).catch(err => {

                    }).finally(() => {
                        if (isSuccess) {
                            toast.success('Success!', {
                                id: channelMsg,
                            });
                            this.setState({
                                level1list: channelLevel == 1 ? levelListUse : level1list,
                                level2list: channelLevel == 2 ? levelListUse : level2list,
                                level3list: channelLevel == 3 ? levelListUse : level3list,
                            }, () => {
                                if (transferToChannel["id"]) {
                                    tokchannelDataSet(transferToChannel);
                                }
                            });
                        } else {
                            toast.error('Failed!', {
                                id: channelMsg,
                            });
                        }
                    });
                }

            }
        }).catch((result) => {
            MySwal.update({
                title: <p>An Error Occured</p>,
                icon: "warning"
            });
            MySwal.hideLoading();
        })
    }
    openAddChannel(data) {
        let level = data["level"] ? data["level"] : "";
        this.setState({
            addChannelLevel: level,
            addChannelShow: true
        })
    }

    closeAddChannel() {
        this.setState({
            addChannelLevel: 0,
            addChannelShow: false,
            newChannelName: ""
        })
    }

    async initilizeLevels() {
        let { level1Colors, level2Colors, level3Colors, colorCounter1, colorCounter2, colorCounter3, level1, level2, level3, level1list, level2list, level3list, tokchannelNavbarKey } = this.state;

        let tempLevel1list = new Array;
        let tempLevel2list = new Array;
        let tempLevel3list = new Array;

        var queryLevel1 = {
            "items": "channels",
            "label": "channel",
            "!level0": "null",
            "level1": "null",
            "level2": "",
            "user_id": "tokket"
        }
        var responseLvl1 = await getGroups(queryLevel1, "channel");
        if(responseLvl1){
            if (responseLvl1["data"]["status_code"] == 200) {
                if (responseLvl1["data"]["result"]) {
                    tempLevel1list = responseLvl1["data"]["result"];
                }
            }
        }
        if (level2list.filter(item => item["level0"] == level1).length == 0) {
            const queryLevel2 = {
                "items": "channels",
                "label": "channel",
                "level0": level1,
                "!level1": "null",
                "level2": "null",
                "user_id": "tokket"
            }
            var responseLvl2 = await getGroups(queryLevel2, "channel");
            if(responseLvl2){
                if (responseLvl2["data"]["status_code"] == 200) {
                    if (responseLvl2["data"]["result"]) {
                        tempLevel2list = responseLvl2["data"]["result"];
                    }
                }
            }
        }
        if (level3list.filter(item => item["level1"] == level2).length == 0) {
            const queryLevel3 = {
                "items": "channels",
                "label": "channel",
                "level0": level1,
                "level1": level2,
                "!level2": "null",
                "user_id": "tokket"
            }
            var responseLvl3 = await getGroups(queryLevel3, "channel");
            if(responseLvl3){
                if (responseLvl3["data"]["status_code"] == 200) {
                    if (responseLvl3["data"]["result"]) {
                        tempLevel3list = responseLvl3["data"]["result"];
                    }
                }
            }
        }

        tempLevel1list.forEach(level => {
            level["color"] = level1Colors[colorCounter1];
            colorCounter1++;
            if (colorCounter1 == 16) {
                colorCounter1 = 0;
            }
        });
        tempLevel2list.forEach(level => {
            level["color"] = level2Colors[colorCounter2];
            colorCounter2++;
            if (colorCounter2 == 16) {
                colorCounter2 = 0;
            }
        });
        tempLevel3list.forEach(level => {
            level["color"] = level3Colors[colorCounter3];
            colorCounter3++;
            if (colorCounter3 == 16) {
                colorCounter3 = 0;
            }
        });
        this.setState({
            level1list: level1list.concat(tempLevel1list),
            level2list: level2list.concat(tempLevel2list),
            level3list: level3list.concat(tempLevel3list),
            colorCounter1: colorCounter1,
            colorCounter2: colorCounter2,
            colorCounter3: colorCounter3,
            tokchannelNavbarKey: level1list.length == 0 ? randomString(10) : tokchannelNavbarKey
        })
    }

    setFavorites(list) {
        this.setState({
            currentFavoriteList: list
        });
    }
    handleDrawerTokChannelTitlebar() {
        const { ActiveTokChannelTitlebar } = this.state;
        this.setState({
            ActiveTokChannelTitlebar: !ActiveTokChannelTitlebar
        });
    }

    levelSet() {
    }
    sendData(e) {
        this.setState({
            ActiveTokChannelNavbar: e,
        });
    }
    handleCommunitiesShow() {
        this.setState({
            ShowCommunities: false,
        });
        if (this.communityAddBtnProxy.current) {
            this.communityAddBtnProxy.current.style.display = "none";
        }
    }
    handleCommunitiesHide() {
        this.setState({
            ShowCommunities: true,
        });
        if (this.communityAddBtnProxy.current) {
            this.communityAddBtnProxy.current.style.display = "flex";
        }
    }

    tokchannelData(data) {
        var { query, level1, level2, level3, tokData, ShowCommunities, ShowTokTiles, ClassChannel } = this.state;
        let color = "";
        let hrefLink = ClassChannel ? "/classchannels/" : "/tokchannels/";
        let items = null;
        let continuationToken = null;
        level1 = "";
        level2 = "";
        level3 = "";
        if (data) {
            query = data;
        }
        query["pkbase"] = "channeltoks";

        if (data["level0"]) {
            if (data["level0"] == "bookmarks") {
                hrefLink = data["level0"];
                level1 = data["level0"];
            } else {
                level1 = data["level0"];
                hrefLink = hrefLink + data["level0"]
                if (data["level1"]) {
                    level2 = data["level1"];
                    hrefLink = hrefLink + "/" + data["level1"]
                    if (data["level2"]) {
                        level3 = data["level2"];
                        hrefLink = hrefLink + "/" + data["level2"]
                    }
                }
            }
        }
        // User for saving Tok Data via reference to Levels
        for (let t = 0; t < tokData.length; t++) {
            let tempQueryTokData = {
                level1: tokData[t].query["level1"] ? tokData[t].query["level1"] : "",
                level2: tokData[t].query["level2"] ? tokData[t].query["level2"] : "",
                level3: tokData[t].query["level3"] ? tokData[t].query["level3"] : ""
            }
            if (tempQueryTokData["level1"] == query["level0"] && tempQueryTokData["level2"] == query["level1"] && tempQueryTokData["level3"] == query["level2"]) {
                items = tokData[t].items;
                continuationToken = tokData[t].continuationToken;
                break;
            }
        }
        // User for saving Tok Data via reference to Levels - End
        window.history.pushState({}, "Tokket", hrefLink);
        addGroup({
            label: "recentchannel",
            name: data["name"] ? data["name"] : level3 ? level3 : level2 ? level2 : level1,
            is_community: false,
            level0: level1,
            level1: level2,
            level2: level3,
            user_id: null
        }, "", "recentchannels");

        this.setState({
            group: {} as Group,
            query: query,
            level1: level1,
            level2: level2,
            level3: level3,
            tokid: "",
            tokpk: "",
            ShowCommunities: ShowCommunities,
            ShowTokTiles: ShowTokTiles,
            items: items
        }, () => {
            this.initilizeLevels();
        });
    }
    updateLeftNav(query) {
        this.tokchannelNavbarRef.current.setAttribute("data-level1", query["level0"]);
        this.tokchannelNavbarRef.current.setAttribute("data-level2", query["level1"]);
        this.tokchannelNavbarRef.current.setAttribute("data-level3", query["level2"]);
        this.tokchannelNavbarRef.current.setAttribute("data-name", query["name"]);
        this.tokchannelNavbarRef.current.click();
    }
    updateFavoriteLeftNav(query) {
        this.tokchannelFavoriteNavbarRef.current.setAttribute("data-level1", query["level1"]);
        this.tokchannelFavoriteNavbarRef.current.setAttribute("data-level2", query["level2"]);
        this.tokchannelFavoriteNavbarRef.current.setAttribute("data-level3", query["level3"]);
        this.tokchannelFavoriteNavbarRef.current.setAttribute("data-name", query["name"]);
        this.tokchannelFavoriteNavbarRef.current.setAttribute("data-remove", query["remove"] ? "true" : "false");
        this.tokchannelFavoriteNavbarRef.current.click();
    }
    saveTokData(data) {
        const { tokData } = this.state;
        let tempTokData = tokData;
        let match = false;
        let newQueryData = {
            level1: data.query["level1"] ? data.query["level1"] : "",
            level2: data.query["level2"] ? data.query["level2"] : "",
            level3: data.query["level3"] ? data.query["level3"] : ""
        }
        for (let t = 0; t < tempTokData.length; t++) {
            let tempQueryTokData = {
                level1: tempTokData[t].query["level1"] ? tempTokData[t].query["level1"] : "",
                level2: tempTokData[t].query["level2"] ? tempTokData[t].query["level2"] : "",
                level3: tempTokData[t].query["level3"] ? tempTokData[t].query["level3"] : ""
            }

            if (tempQueryTokData["level1"] == newQueryData["level1"] && tempQueryTokData["level2"] == newQueryData["level2"] && tempQueryTokData["level3"] == newQueryData["level3"]) {
                match = true;
                tempTokData[t] = data;
                break;
            }
        }
        if (!match) {
            tempTokData.push(data);
        }
        this.setState({
            tokData: tempTokData,
            familyEmptyWarning: newQueryData['level1'] == "familyfriends" && !data["items"].length
        });
    }
    addRemoveFavorite(e) {
        var { level1, level2, level3, activeAddFavoriteProgress, currentFavoriteList, level1list, level2list, level3list } = this.state;
        let progress = {
            level1: level1,
            level2: level2,
            level3: level3
        }
        let name = "";
        if (level3) {
            let filteredItem = level3list.filter(item => item["level2"] == level3);
            if (filteredItem.length > 0) {
                name = filteredItem[0]["name"] ? filteredItem[0]["name"] : level3
            } else {
                name = level3;
            }
        } else if (level3) {
            let filteredItem = level2list.filter(item => item["level1"] == level2);
            if (filteredItem.length > 0) {
                name = filteredItem[0]["name"] ? filteredItem[0]["name"] : level2
            } else {
                name = level2;
            }
        } else {
            let filteredItem = level1list.filter(item => item["level0"] == level1);
            if (filteredItem.length > 0) {
                name = filteredItem[0]["name"] ? filteredItem[0]["name"] : level1
            } else {
                name = level1;
            }
        }
        if (activeAddFavoriteProgress.filter(active => active == progress).length == 0) {
            if (e) {
                let newActiveAddFavoriteProgress = cloneDeep(activeAddFavoriteProgress);
                newActiveAddFavoriteProgress.push(progress);
                this.setState({
                    activeAddFavoriteProgress: newActiveAddFavoriteProgress,
                }, () => {
                    addGroup({
                        label: "favoritechannel",
                        name: name,
                        is_community: false,
                        level0: idFormat(level1),
                        level1: idFormat(level2),
                        level2: idFormat(level3),
                        user_id: null
                    }, "", "favoritechannels").then(res => {
                        var { currentFavoriteList } = this.state;
                        if(res){
                            if (res["data"]["status_code"] == 200) {
                                if (res["data"]["result"]) {
                                    toast.success('Added to Favorites');
                                    let item = {
                                        level1: res["data"]["result"]["level0"] ? res["data"]["result"]["level0"] : "",
                                        level2: res["data"]["result"]["level1"] ? res["data"]["result"]["level1"] : "",
                                        level3: res["data"]["result"]["level1"] ? res["data"]["result"]["level2"] : ""
                                    }
                                    this.updateFavoriteLeftNav(item);
                                    currentFavoriteList.push(res["data"]["result"]);
                                    this.setState({
                                        currentFavoriteList: currentFavoriteList,
                                    });
                                } else {
                                    toast.error('An Error Occured');
                                }
                            } else {
                                toast.error('An Error Occured');
                            }
                        }
                    }).catch(err => {
                        toast.error('An Error Occured');
                    })
                        .finally(() => {
                            var { activeAddFavoriteProgress } = this.state;
                            this.setState({
                                activeAddFavoriteProgress: activeAddFavoriteProgress.filter(active => active != progress),
                            });
                        });
                });
            } else {
                let newActiveAddFavoriteProgress = cloneDeep(activeAddFavoriteProgress);
                newActiveAddFavoriteProgress.push(progress);
                this.setState({
                    activeAddFavoriteProgress: newActiveAddFavoriteProgress,
                }, () => {
                    let favorites = currentFavoriteList.filter(item => item["level0"] == (level1 ? level1 : "") && item["level1"] == (level2 ? level2 : "") && item["level2"] == (level3 ? level3 : ""));
                    if (favorites.length > 0) {
                        let favorite = favorites[0];
                        deleteGroup(favorite["id"], favorite["pk"]).then(res => {
                            if(res){
                                if (res["data"]["status_code"] == 200) {
                                    toast.success('Removed from Favorites');
                                    var { currentFavoriteList } = this.state;
                                    let item = {
                                        level1: level1 ? level1 : "",
                                        level2: level2 ? level2 : "",
                                        level3: level3 ? level3 : "",
                                        remove: true
                                    }
    
                                    let newList = cloneDeep(currentFavoriteList.filter(item => item["id"] != favorite["id"]));
    
                                    this.setState({
                                        currentFavoriteList: newList
                                    });
                                    this.updateFavoriteLeftNav(item);
                                } else {
                                    toast.error('An Error Occured');
                                }
                            }
                        }).catch(err => {
                            toast.error('An Error Occured');
                        })
                            .finally(() => {
                                var { activeAddFavoriteProgress } = this.state;
                                this.setState({
                                    activeAddFavoriteProgress: activeAddFavoriteProgress.filter(active => active != progress),
                                });
                            })

                    }
                });
            }
        }
    }
    setAddChannelName(e) {
        const value = e.target.value;
        this.setState({
            newChannelName: value
        });
    }
    postNewChannel() {
        const { newChannelName, level1, level2, addChannelLevel, level1list, level2list, level3list, colorCounter1, colorCounter2, colorCounter3, level1Colors, level2Colors, level3Colors } = this.state;
        if (newChannelName) {
            var channel = {
                "label": "channel",
                "is_community": false,
                "name": newChannelName
            }
            let levelListUse = new Array;
            let colorCounter = 0;
            let levelColors = new Array;
            let isSuccess = false;
            if (addChannelLevel == 1) {
                channel["level0"] = idFormat(newChannelName);
                channel["level1"] = "";
                channel["level2"] = "";
                colorCounter = cloneDeep(colorCounter1);
                levelColors = cloneDeep(level1Colors);
                levelListUse = cloneDeep(level1list);
            } else if (addChannelLevel == 2) {
                channel["level0"] = level1;
                channel["level1"] = idFormat(newChannelName);
                channel["level2"] = "";
                colorCounter = cloneDeep(colorCounter2);
                levelColors = cloneDeep(level2Colors);
                levelListUse = cloneDeep(level2list);
            } else if (addChannelLevel == 3) {
                channel["level0"] = level1;
                channel["level1"] = level2;
                channel["level2"] = idFormat(newChannelName);
                colorCounter = cloneDeep(colorCounter3);
                levelColors = cloneDeep(level3Colors);
                levelListUse = cloneDeep(level3list);
            }
            var channelMsg = toast.loading('Adding Channel');
            addGroup(channel, "channel", "channels").then(res => {
                if(res){
                    if (res["data"]["status_code"] == 200) {
                        if (res["data"]["result"]) {
                            levelListUse.push(res["data"]["result"]);
                            isSuccess = true;
                        }
                    }
                }
            }).catch(err => {

            }).finally(() => {
                if (isSuccess) {
                    toast.success('Success!', {
                        id: channelMsg,
                    });
                    levelListUse.forEach(level => {
                        level["color"] = levelColors[colorCounter];
                        colorCounter++;
                        if (colorCounter == 16) {
                            colorCounter = 0;
                        }
                    });
                    this.setState({
                        level1list: addChannelLevel == 1 ? levelListUse : level1list,
                        level2list: addChannelLevel == 2 ? levelListUse : level2list,
                        level3list: addChannelLevel == 3 ? levelListUse : level3list,
                        colorCounter1: addChannelLevel == 1 ? colorCounter : colorCounter1,
                        colorCounter2: addChannelLevel == 2 ? colorCounter : colorCounter2,
                        colorCounter3: addChannelLevel == 3 ? colorCounter : colorCounter3,
                        level1Colors: addChannelLevel == 1 ? levelColors : level1Colors,
                        level2Colors: addChannelLevel == 2 ? levelColors : level2Colors,
                        level3Colors: addChannelLevel == 3 ? levelColors : level3Colors,
                    }, () => {
                        this.closeAddChannel();
                    });
                } else {
                    toast.error('Failed!', {
                        id: channelMsg,
                    });
                }
            })
        }
    }
    render() {
        var { localUser, addChannelLevel, addChannelShow, level1list, level2list, level3list, tokchannelNavbarKey, level1, level2, level3, currentFavoriteList, familyEmptyWarning, ClassChannel, items, continuationToken, level1, level2, level3, tokid, tokpk, group, localUser, ShowCommunities, ActiveTokChannelNavbar, ActiveTokChannelTitlebar, query, ShowTokTiles } = this.state;

        if (query["level1"] != "mytoks") {
            query["publicfeed"] = true;
        }
        var proxyKey = randomString(10);
        let currentColor = "";
        let selectedLevel = new Array;
        let activeLevel = 0;
        let addChannelTitle = "";
        let selectedTitle = "";
        if (level3) {
            selectedLevel = level3list.filter(channel => channel["level0"] == level1 && channel["level1"] == level2 && channel["level2"] == level3);
            if (selectedLevel[0]) {
                selectedTitle = selectedLevel[0]["name"] ? selectedLevel[0]["name"] : selectedLevel[0]["level2"];
            } else {
                selectedTitle = level3;
            }
            activeLevel = 3;
        } else if (level2) {
            selectedLevel = level2list.filter(channel => channel["level0"] == level1 && channel["level1"] == level2);
            if (selectedLevel[0]) {
                selectedTitle = selectedLevel[0]["name"] ? selectedLevel[0]["name"] : selectedLevel[0]["level2"];
            } else {
                selectedTitle = level2;
            }
            addChannelTitle = upperCasesFirstLetter(textDisplayFormat(level2));
            activeLevel = 2;
        } else {
            selectedLevel = level1list.filter(channel => channel["level0"] == level1);
            if (selectedLevel[0]) {
                selectedTitle = selectedLevel[0]["name"] ? selectedLevel[0]["name"] : selectedLevel[0]["level2"];
            } else {
                selectedTitle = level1;
            }
            addChannelTitle = upperCasesFirstLetter(textDisplayFormat(level1));
            activeLevel = 1;
        }
        if (selectedLevel.length > 0) {
            currentColor = "#" + selectedLevel[0]["color"];
        }
        let level2NavbarList = new Array;
        let level3NavbarList = new Array;
        try {
            level2NavbarList = level2list.filter(channel => channel["level0"] == level1).sort((x, y) => x["level1"].localeCompare(y["level1"]));
        } catch (err) { }
        try {
            level3NavbarList = level3list.filter(channel => channel["level1"] == level2).sort((x, y) => x["level2"].localeCompare(y["level2"]));
        } catch (err) { }
        return (
            <>
                {addChannelShow ?
                    <Modal size='lg' show={addChannelShow} onHide={this.closeAddChannel} style={{ zIndex: "1206" }}>
                        <Modal.Header>
                            <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={this.closeAddChannel} />
                                Add Level {addChannelLevel} Channel{/*addChannelTitle ? " for " + addChannelTitle : ""*/}
                                <BootstrapButton variant="success" onClick={this.postNewChannel}>Post</BootstrapButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <TextField style={{ backgroundColor: "white", color: "black", borderRadius: "4px", width: "100%" }} label={`Channel Name`} onChange={this.setAddChannelName} />
                        </Modal.Body>
                    </Modal> : ""
                }
                {!ClassChannel ?
                    <TokChannelNavbar openAddChannel={this.openAddChannel} key={tokchannelNavbarKey} level1list={level1list} setFavorites={this.setFavorites} signupTrigger={this.signupTrigger} loginCallTrigger={this.loginCallTrigger} sendData={this.sendData} tokchannelData={this.tokchannelData} tokchannelNavbarRef={this.tokchannelNavbarRef} tokchannelFavoriteNavbarRef={this.tokchannelFavoriteNavbarRef} /> : ""
                }
                <main ref={this.mainBody} style={{ marginLeft: ClassChannel ? "" : ActiveTokChannelNavbar ? '280px' : '75px' }}>
                    <div ref={this.proxyTitleBar} style={{ display: level1 != "mygroups" && group["id"] ? "none" : "" }}>
                        <ProxyTitleBar enableDeleteChannel={currentColor ? localUser.id == selectedLevel[0]["user_id"] : false} openDeleteChannel={this.openDeleteChannel} openAddChannel={this.openAddChannel} addChannelOption={level1 != "bookmarks" && level1 != "mytoks" && level1 != "myprogress"} activeLevel={activeLevel} key={proxyKey} activeFavorite={currentFavoriteList.filter(item => item["level0"] == (level1 ? level1 : "") && item["level1"] == (level2 ? level2 : "") && item["level2"] == (level3 ? level3 : "")).length > 0} addRemoveFavorite={this.addRemoveFavorite} disableAddTok={level1 == "bookmarks"} disableSearch={level1 == "bookmarks"} DisableNavbar={level1 == "bookmarks"} continuationToken={continuationToken} addTokTitle={level1 == "mytoks" ? "Tok" : ""} fontColor={"white"} bgColor={currentColor ? currentColor : "#0062cc"} title={selectedTitle} tokTileTitleBar={this.tokTileTitleBar} communityAddBtnProxy={this.communityAddBtnProxy} communityTitleBar={this.communityTitleBar} query={{
                            level1: level1 ? level1 : "",
                            level2: level2 ? level2 : "",
                            level3: level3 ? level3 : ""
                        }} />
                    </div>
                    <div ref={this.tokchannelTitlebar} className={ActiveTokChannelTitlebar ? "tokchannel-titlebar-container" : "tokchannel-titlebar-container close"}>
                        {query["level0"] == "familyfriends" && familyEmptyWarning ?
                            <div className={'col-12 row'} style={{ marginTop: "5px", marginLeft: "unset" }}>
                                <div style={{ width: "60px", paddingLeft: "unset" }}>
                                    <img src={"https://tokketcontent.blob.core.windows.net/tokketweb/tokstargirl1.jpg"} style={{ width: "100%" }} />
                                </div>
                                <Col>
                                    <div className={'speechbubble-doc'} style={{ fontStyle: "italic", fontWeight: "600", fontSize: "12px", height: "fit-content" }}>
                                        Post important memories to these channels. You, your family, and your friends will never miss a post whether the subchannels are checked once a day, once a week, once a month, or once a year.
                                    </div>
                                </Col>
                            </div> : ""
                        }
                        {!tokid && !tokpk && level1 != "bookmarks" ?
                            <div>
                                {level2list.length > 0 ?
                                    <>
                                        {level2list.filter(channel => channel["level0"] == level1).length > 1 ? <div onClick={this.handleDrawerTokChannelTitlebar} className={'tokchannel-dropdown-icon'}><FontAwesomeIcon icon={faChevronDown} /></div> : ""}
                                        {level2list.filter(channel => channel["level0"] == level1).length > 1 ? <TokChannelTitlebar classchannel={ClassChannel} key={level1 + level2 + level3 + "leveltitle1" + randomString(12)} tokchannelData={this.tokchannelData} updateLeftNav={this.updateLeftNav} LevelDetail={level2NavbarList} CurrentLevel="1" Level1={level1} Level2={level2} previousLevel={level1} /> : ""}
                                    </> : ""
                                }
                                {level3list.length > 0 ?
                                    <>
                                        {level3list.filter(channel => channel["level1"] == level2).length > 1 ? <div></div> : ""}
                                        {level3list.filter(channel => channel["level1"] == level2).length > 1 ? <TokChannelTitlebar classchannel={ClassChannel} key={level1 + level2 + level3 + "leveltitle2" + randomString(12)} tokchannelData={this.tokchannelData} updateLeftNav={this.updateLeftNav} LevelDetail={level3NavbarList} CurrentLevel="2" Level1={level1} Level2={level2} Level3={level3} previousLevel={level2} /> : ""}
                                    </> : ""
                                }
                            </div> : ""
                        }
                    </div>
                    {level3list.filter(channel => channel["level1"] == level2).length && level3 && localUser["id"] ?
                        <Row>
                            <Col style={{ padding: "unset", marginRight: "2.5px", }}>
                                <Button color={"success"} variant={ShowCommunities ? "outlined" : "contained"} style={{ width: "100%", borderColor: currentColor, color: ShowCommunities ? currentColor : "white", backgroundColor: ShowCommunities ? "" : currentColor }} onClick={this.handleCommunitiesShow}>{selectedTitle} Toks</Button>
                            </Col>
                            <Col style={{ padding: "unset", marginLeft: "2.5px" }}>
                                <Button color={"success"} variant={ShowCommunities ? "contained" : "outlined"} style={{ width: "100%", borderColor: currentColor, color: !ShowCommunities ? currentColor : "white", backgroundColor: !ShowCommunities ? "" : currentColor }} onClick={this.handleCommunitiesHide}>Communities</Button>
                            </Col>
                        </Row> : ""
                    }
                    {/* Hero unit */}
                    <Container sx={{
                        paddingBottom: (theme) => theme.spacing(8)
                    }} maxWidth={false}>
                        {/* End hero unit */}
                        <div style={{ textAlign: "center" }}></div>
                        <div ref={this.tokchannelTile} style={{ display: ShowCommunities && level3 ? "none" : "" }}>
                            {tokid && tokpk ?
                                <TokInfoView id={tokid} pk={tokpk} /> :
                                level1 != "mygroups" ?
                                    group["id"] ?
                                        <GroupInfoView group={group} id={undefined} pk={undefined} /> :
                                        level1 == "bookmarks" ?
                                            <TokTileContainer title={" "} items={items} itemBase={"bookmarks"} fontColor={"white"} saveTokData={this.saveTokData} bgColor={currentColor} proxyTitleBarActive={true} tokTileTitleBar={this.tokTileTitleBar} DisableNavbar={undefined} CardViewOption={undefined} levelTitle={undefined} CommunityId={undefined} CommunityName={undefined} groupName={undefined} query={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={undefined} />
                                            : <TokTileContainer items={items} key={level1 + level2 + level3 + "toks"} fontColor={"white"} saveTokData={this.saveTokData} bgColor={currentColor} levelTitle={true} tokTileTitleBar={this.tokTileTitleBar} proxyTitleBarActive={true} DisableNavbar={undefined} CardViewOption={undefined} CommunityId={undefined} CommunityName={undefined} groupName={undefined} itemBase={undefined} title={undefined} addClassTokRef={undefined} addExistingClassTokTransferRef={undefined}
                                                query={{
                                                    level1: level1 ? level1 : "",
                                                    level2: level2 ? level2 : "",
                                                    level3: level3 ? level3 : "",
                                                    pkbase: "channeltoks"
                                                }} />

                                    :
                                    <GroupsContainer key={level1 + level2 + level3 + "groups"} MenuKind={undefined} Navbar={undefined} CommunityId={undefined} disableUserId={undefined} CustomTitle={undefined} EnableTitle={undefined} groupJoinedTrigger={undefined} query={{ pk: localUser.id + "-channelgroups" }} displayWarning={level2 ? level2.toLowerCase() == "all" ? 'mygrouptokchannelall' : 'mygrouptokchannel' : "mygrouptokchannel"} />
                            }
                        </div>
                        <div ref={this.communityContainer} style={{ display: ShowCommunities && level3 ? "" : "none" }}>
                            <CommunitiesContainer key={level1 + level2 + level3 + "community"} Navbar={true} query={
                                {
                                    level1: level1 ? level1 : "",
                                    level2: level2 ? level2 : "",
                                    level3: level3 ? level3 : ""
                                }
                            } communityTitleBar={this.communityTitleBar} NavbarProxyActive={true} />
                        </div>
                    </Container>
                    <div className={"exception"}>
                        <Toaster />
                    </div>
                </main >
            </>
        );
    }
}
export default TokChannelBody; 