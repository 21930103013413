import React from 'react'
import { useEffect } from 'react';
import '../styles.css'
import './css/DocumentSlot.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faFile, faEllipsis, faTimes } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteTok } from '../services/tokServices';
import { useState } from 'react';
import { updateClassTok } from '../services/classServices';
import { TextField } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { getToken } from '../services/accountServices';

const MySwal = withReactContent(Swal);

const localUser = localStorage.getItem("userdata");

var userid = "";
if (localUser) {
    const localUserJson = JSON.parse(localUser);
    if (localUserJson.id !== undefined)
        userid = localUserJson.id;
    else
        userid = "";
}
const DocumentSlot = (props) => {
    const [card, setCard] = useState(props.document);
    const [renameShow, setRenameShow] = useState(false);
    const [tempDocument, setTempDocument] = useState("");
    function ViewDocument() {
        props.viewDocument(card)
    }

    const deleteDocumentCall = (e) => {
        MySwal.fire({
            title: <p>Delete Document</p>,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333",
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Document</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading()
                deleteTok(card.id).then(response => {
                    if(response){
                        if (response.status == 200) {
                            MySwal.update({
                                title: 'Deleted!',
                                icon: "success"
                            });
                            props.removeDocument(card);
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }
                }).catch(response => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }).finally(() => {
                    MySwal.hideLoading();
                });
            }
        })
    }

    const renameDcoumentCall = (e) => {
        setRenameShow(true);
        setTempDocument(card["primary_text"]);
    }
    const renameDcoumentClose = (e) => {
        setRenameShow(false);
    }
    const renameTempFileName = (e) => {
        var value = e.target.value;
        setTempDocument(value);
    }
    const updateDocumentTrigger = (e) => {
        MySwal.fire({
            title: <p>Updating Document.</p>,
            allowOutsideClick: false
        });
        MySwal.showLoading();
        let tempDoc = card;
        tempDoc["primary_text"] = tempDocument;
        getToken().then(response => {
            updateClassTok(tempDoc).then(response => {
                if(response){
                    if(response.status == 200){
                        MySwal.update({
                            title: <p>Update Successful.</p>,
                            icon: "success"
                        });
                        setCard(tempDoc);
                    } else {
                        MySwal.update({
                            title: <p>Update failed.</p>,
                            icon: "success"
                        });
                    }
                }
            }).catch(err => {
                MySwal.update({
                    title: <p>Update failed.</p>,
                    icon: "success"
                });
            }).finally(() => {
                MySwal.hideLoading();
            })
        }).catch(err => {
            MySwal.update({
                title: <p>Update failed.</p>,
                icon: "success"
            });
            MySwal.hideLoading();
        }).finally(() => {})
    }
    
    return (
        <Row className={'col-12 document-slot'}>
            <Modal size='lg' show={renameShow} style={{ zIndex: "1207" }}>
                <Modal.Header>
                    <div style={{ width: "100%", display: "contents", fontWeight: "600" }}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} color={'gray'} size={'lg'} icon={faTimes} onClick={renameDcoumentClose} />
                        Rename Document
                        <Button variant="success" onClick={updateDocumentTrigger}>Update</Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <TextField style={{ width: "100%" }} value={tempDocument} onChange={renameTempFileName} label="Document Name" />
                </Modal.Body>
            </Modal>
            <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={faFile} size={'3x'} />
                {card.primary_text}
            </Col>
            <Row className={'col-6'}>
                <Col className={'col-auto'} style={{ marginRight: "auto", display: "inline-flex", alignItems: "center" }}>
                    <div style={{ height: "46px", width: "46px", backgroundImage: `url(${card.user_photo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundColor: "white" }}></div>
                    {card.user_display_name}
                </Col>
                <Col className={'col-auto'}>
                    <Dropdown style={{ marginLeft: "auto" }}>
                        <Dropdown.Toggle className={'dropdown-remove'} style={{ backgroundColor: "unset", color: "black", border: "unset", padding: "unset" }}>
                            <FontAwesomeIcon icon={faEllipsis} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={ViewDocument}>View</Dropdown.Item>
                            <Dropdown.Item href={card.image} target='_blank'>Download</Dropdown.Item>
                            {card.user_id == userid ?
                                <>
                                    <Dropdown.Item onClick={renameDcoumentCall}>Rename</Dropdown.Item>
                                    <Dropdown.Item onClick={deleteDocumentCall}>Delete</Dropdown.Item>
                                </> : ""
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Row>
    )
}

export default DocumentSlot;