import React from 'react'
import '../styles.css'
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { getClassSet } from '../services/classServices';
import { getToken } from '../services/accountServices';
import SetViewContainer from './SetView';
import { Set } from '../interfaces/SetInterface';

interface States {
    id: string;
    pk: string;
    LoadActive: boolean;
    item: Set;
}

interface Props {
    removeSet: (data: any) => void;
    id: string;
    pk: string;
}

class SetInfoView extends React.Component<Props, States> {

    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            pk: props.pk,
            LoadActive: true,
            item: {} as Set
        };
        this.removeSet = this.removeSet.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData();
    }
    async getData() {
        var { id, pk } = this.state;
        var item: Set;
        await getToken();
        try {
            const response = await getClassSet(id, pk);
            if(response){
                if (response.data["status_code"] == 200) {
                    item = response.data["result"];
                }
            }
        } catch (err) {
        }
        this.setState({
            LoadActive: false,
            item: item
        });
    }
    removeSet() {
        this.props.removeSet(true);
    }
    render() {
        const { LoadActive, item } = this.state;

        return (
            <div>
                {LoadActive ?
                    <WaitSpinner /> :
                    <div>
                        <SetViewContainer set={item} closeButton={false} removeSet={this.removeSet} updateSet={undefined} closeView={undefined}/>
                    </div>
                }

            </div>

        );
    }
}

export default SetInfoView;