import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { usStateData, usStateFullState } from '../services/helperTools'

interface countryState {
  label: string;
  value: string;
}
interface States {
  country: countryState;
  state: countryState;
  countries: countryState[];
  usStates: countryState[];
}
interface Props {
  setUsState: any;
  setCountry: any;
  country: any;
  state: any;
}
class CountryPicker extends React.Component<Props, States> {
  constructor(props) {
    super(props);
    this.state = {
      country: props.country ? {
        label: countryList().getLabel(props.country.toUpperCase()),
        value: props.country.toUpperCase()
      } : {} as countryState,
      state: props.state ? usStateFullState(props.state.toUpperCase()) : {} as countryState,
      countries: countryList().getData(),
      usStates: usStateData(),
    }
    this.changeCountry = this.changeCountry.bind(this);
    this.changeUsState = this.changeUsState.bind(this);
  }
  changeCountry(e) {
    try {
      this.props.setCountry(e["value"]).toLowerCase();
    } catch (err) { }
    this.setState({
      country: e
    })
  }
  changeUsState(e) {
    try {
      this.props.setUsState(e["value"].toLowerCase());
    } catch (err) { }
    this.setState({
      state: e
    })
  }
  render() {
    const { country, state, countries, usStates } = this.state;
    return (
      <>
        <Select options={countries} defaultValue={country} value={country} onChange={this.changeCountry} />
        {country["value"] == "US" ?
          <div style={{ marginTop: "10px" }}>
            <Select options={usStates} defaultValue={state} value={state} onChange={this.changeUsState} />
          </div> : ""}
      </>
    )
  }
}

export default CountryPicker