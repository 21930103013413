import { BlobServiceClient } from "@azure/storage-blob";

const blobServiceClient = new BlobServiceClient(
    //will adjust temporary direct blob sas url
    "https://tokketcontent.blob.core.windows.net/?sv=2021-12-02&ss=b&srt=co&sp=rwdlax&se=2024-04-27T05:32:31Z&st=2023-04-26T21:32:31Z&spr=https&sig=mIXOhXcVBTk%2FLZirCWu%2FdGHBy4U%2B5smP7ysktbUJ9vQ%3D"
);


export async function uploadFile(file, id) {
    var fileName = "";
    if(id){
        fileName = id + "-" + file.name;
    }else{
        fileName = file.name;
    }
    const containerClient = blobServiceClient.getContainerClient("files");
    const blobClient = containerClient.getBlobClient(fileName);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        onProgress: ev =>  ProgressSet(ev, fileName, file)
    });
    if(result['_response']){
        return result._response;
    }else{
        return false;
    }
}

function ProgressSet(ev, fileName, file){
    try{
        let progressBarCount = "translateX(-" + (((ev.loadedBytes/file.size) * 100) - 100) + "%)";
        document.querySelector("."+ fileName.replace(/[^a-zA-Z0-9]/g, '')).querySelector(".classgroup-progress-bar span").style.transform = progressBarCount;
    } catch(err) { }
}