import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getSetToken, getSetUserId } from './accountServices';
import { addParam, jsonKeySnakeCaseToCamelCase } from './helperTools';

const apiUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;
const apiCode = process.env.REACT_APP_API_CODE;
const apiImageUrl = process.env.REACT_APP_API_IMAGE_URL;

export async function getReactions(reactionsQuery = null, feedId = "") {
    if (reactionsQuery) {
        reactionsQuery = jsonKeySnakeCaseToCamelCase(reactionsQuery);
        let params = "";
        let keys = Object.keys(reactionsQuery)
        for(let x = 0; x < keys.length; x++) {
            params = addParam(params, keys[x], reactionsQuery[keys[x]]);
        }
        return await axios.get(`${apiImageUrl}/reactions?userCounter=0${feedId ? "&feedId=" + feedId : ""}${params? "&"+ params: ""}`, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
            }
        });
    } else {
        return false;
    }

}

export async function addReaction(reaction, feedId, items = "") {
    if (reaction) {
        const localUser = localStorage.getItem("userdata");

        var userid = "";
        if (localUser) {
            const localUserJson = JSON.parse(localUser);
            if (localUserJson["id"]) {
                userid = localUserJson.id;
                reaction["user_id"] = userid;
                reaction["user_display_name"] = localUserJson["display_name"] ? localUserJson["display_name"] : "";
                reaction["user_photo"] = localUserJson["user_photo"] ? localUserJson["user_photo"] : "";
                reaction["user_country"] = localUserJson["country"] ? localUserJson["country"] : "";
                reaction["user_state"] = localUserJson["state"] ? localUserJson["state"] : "";
            }
        }
        return await axios.post(`${apiImageUrl}/reactions?userCounter=0${feedId ? "&feedId=" + feedId : "&feedId=" + reaction["user_id"]}${items ? "&items=" + items : ""}`, reaction,
            {
                headers: {
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function deleteReaction(id, pk = null) {
    if (id && pk) {
        return await axios.delete(`${apiImageUrl}/reactions/${id}`,
            {
                headers: {
                    'pk': pk,
                    'Authorization': "Bearer " + await getSetToken(),
                }
            });
    } else {
        return false;
    }
}

export async function updateReaction(reaction) {
    if (reaction) {
        try {
            return await axios.put(`${apiImageUrl}/reactions/${reaction.id}`, reaction,
                {
                    headers: {
                        'Authorization': "Bearer " + await getSetToken(),
                    }
                });
        } catch (ex) {
            return false;
        }

    } else {
        return false;
    }
}

export async function patchReaction(item = null, id = "", pk = "") {
    if (item && id && pk) {
        return await axios.patch(`${apiImageUrl}/reactions/${id}`, item, {
            headers: {
                'Authorization': "Bearer " + await getSetToken(),
                'pk': pk
            }
        });
    } else {
        return false;
    }
}