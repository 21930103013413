import React from 'react'
import '../styles.css'
import './css/TokPaksContainer.css';
import Grid from "@mui/material/Grid";
import WaitSpinner from './WaitSpinner';
import { createRef } from 'react';
import { TextField } from '@mui/material';
import ButtonReact from 'react-bootstrap/Button';
import { faFilter, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faCheckCircle, faChalkboard, faArrowLeft, faArrowDown, faSortDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from '@mui/material/Button';
import { Row, Col } from 'react-bootstrap';
import arrayShuffle from 'array-shuffle';
import { deleteTokPak, getTokPaks } from '../services/tokServices';
import { getToken } from '../services/accountServices';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dropdown from 'react-bootstrap/Dropdown';
import TokPakItem from './TokPakItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddEditTokPak from './AddEditTokPak';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import TokPakPresentationItem from './TokPakPresentationItem';
import VisibilitySensor from 'react-visibility-sensor';
import QuizToks from './QuizToks';
import { TokPak } from '../interfaces/TokpakInterface';

const colors = [
    "d32f2f", "C2185B", "7B1FA2", "512DA8",
    "303F9F", "1976D2", "0288D1", "0097A7",
    "00796B", "388E3C", "689F38", "AFB42B",
    "FBC02D", "FFA000", "F57C00", "E64A19"
];
const shuffleColors = arrayShuffle(colors);
var colorCounter = -1;
const MySwal = withReactContent(Swal);

interface States {
    items: TokPak[];
    LoadActive: boolean;
    continuationToken: string;
    query: { [key: string]: any };
    LoadMoreActive: boolean;
    MenuKind: boolean;
    Navbar: boolean;
    Kinds: {
        displayText: string;
        value: string;
        color: string;
    }[];
    CurrentKind: "" | number;
    TargetItem: TokPak;
    Preview: boolean;
    Edit: boolean;
    CurrentPresentionCount: number;
    ActivePresentationMode: string | 0;
    VisibilityItemFirstLoad: boolean;
    VisibilityItemScrollIntoOnce: boolean;
}

interface Props {
    addTokPakRef: React.RefObject<any>;
}
class TokPaksContainer extends React.Component<Props, States> {
    addTokPakRef: React.RefObject<any>;
    presentationCarouselRef: React.RefObject<any>;
    searchInput: React.RefObject<any>;
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            LoadActive: false,
            continuationToken: null,
            query: props.query,
            LoadMoreActive: false,
            MenuKind: props.MenuKind,
            Navbar: props.Navbar ? props.Navbar : false,
            Kinds: [
                { displayText: "All", value: "", color: "#00b0f0" },
                { displayText: "Presentation", value: "1", color: "#f711ec" },
                { displayText: "Papers", value: "0", color: "#4472c4" },
                { displayText: "Practice", value: "2", color: "#ffc000" }
            ],
            CurrentKind: "",
            TargetItem: {} as TokPak,
            Preview: false,
            Edit: false,
            CurrentPresentionCount: 0,
            ActivePresentationMode: 0,
            VisibilityItemFirstLoad: false,
            VisibilityItemScrollIntoOnce: false
        };

        this.reload = this.reload.bind(this);
        this.loadmore = this.loadmore.bind(this);

        this.setKind = this.setKind.bind(this);

        this.openPreview = this.openPreview.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.editItem = this.editItem.bind(this);

        this.addTokPakRef = props.addTokPakRef;

        this.editTargetItem = this.editTargetItem.bind(this);
        this.deleteTargetItem = this.deleteTargetItem.bind(this);
        this.closePresentation = this.closePresentation.bind(this);

        this.setPresentationMode = this.setPresentationMode.bind(this);
        this.closePresentationMode = this.closePresentationMode.bind(this);

        this.presentationCarouselRef = createRef();
        this.searchInput = createRef();
        this.presentationCarouselNext = this.presentationCarouselNext.bind(this);
        this.presentationCarouselPrevious = this.presentationCarouselPrevious.bind(this);

        this.visibilityItemCheck = this.visibilityItemCheck.bind(this);
        this.visibilityItemScrollIntoOnce = this.visibilityItemScrollIntoOnce.bind(this);
    }

    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        this.setState({
            LoadActive: true
        });
        this.getData({});
    }
    async getData(customQuery) {
        await getToken();
        try {
            var { query, items, continuationToken } = this.state;
            var result = await getTokPaks(query);
            if(result){
                if (result.status == 200) {
                    if (continuationToken) {
                        items = items.concat(result.data.resource.results);
                    } else {
                        items = result.data.resource.results;
                    }
                    this.setState({
                        items: items,
                        continuationToken: result.data.resource.continuationToken,
                        LoadActive: false,
                        LoadMoreActive: false
                    });
                }
            }
        } catch (err) { }
    }
    reload() {
        this.setState({
            LoadActive: true
        }, () => {
            this.getData({});
        });
    }
    loadmore() {
        this.setState({
            LoadMoreActive: true
        });
        var { query, continuationToken } = this.state;
        if (query) {
            query.pagination = continuationToken;
        } else {
            query = { "pagination": continuationToken }
        }

        this.getData(query)
    }
    setKind(btn) {
        const kind = btn.target.getAttribute("data-value");
        this.setState({
            LoadActive: true,
            items: [],
            CurrentKind: kind
        });
        var { query, items } = this.state;
        this.getData({})
    }

    openPreview(e) {
        this.setState({
            TargetItem: e,
            Preview: true,
            CurrentPresentionCount: 0,
            ActivePresentationMode: 0,
            VisibilityItemFirstLoad: false
        });
    }
    closePresentation() {
        this.setState({
            TargetItem: {} as TokPak,
            Preview: false
        });
    }
    deleteItem(e) {
        MySwal.fire({
            title: <p>Delete Tok Pak</p>,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            icon: "warning",
            confirmButtonColor: "#c82333",
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: <p>Deleting Tok Pak</p>,
                    allowOutsideClick: false
                });
                MySwal.showLoading()
                deleteTokPak(e.id, e.pk).then(response => {
                    if(response){
                        if (response.data.statusCode == 200) {
                            MySwal.update({
                                title: 'Deleted!',
                                icon: "success"
                            });
                            var { items } = this.state;
                            for (let x = 0; x < items.length; x++) {
                                if (items[x].id == e.id && items[x].pk == e.pk) {
                                    items.splice(x, 1);
                                    break;
                                }
                            }
                            this.setState({
                                items: items
                            });
                        } else {
                            MySwal.update({
                                title: <p>An Error Occured</p>,
                                icon: "warning"
                            });
                        }
                    }
                }).catch(response => {
                    MySwal.update({
                        title: <p>An Error Occured</p>,
                        icon: "warning"
                    });
                }).finally(() => {
                    MySwal.hideLoading();
                })
            }
        })
    }

    editItem(e) {
        this.setState({
            TargetItem: e,
            Edit: true
        });
    }
    editTargetItem() {
        this.setState({
            Edit: true
        });
    }
    deleteTargetItem() {
        const { TargetItem } = this.state;
        this.deleteItem(TargetItem);
    }
    setPresentationMode() {
        const { TargetItem } = this.state;
        var mode = "";
        var confirmSetPresentation = true;
        try {
            switch (TargetItem["tokpak_type"]) {
                case 0:
                    mode = "papers";
                    break;
                case 1:
                    mode = "presentation";
                    break;
                case 2:
                    mode = "practicetest";
                    confirmSetPresentation = false;
                    break;
            }
        } catch (err) { }
        if (mode == "practicetest" && TargetItem["classtoks"].length < 4) {
            MySwal.fire({
                title: <p>Practice Test requires at least 4 toks. Add {4 - TargetItem["classtoks"].length} more tok{(4 - TargetItem["classtoks"].length) > 1 ? "s" : ""} to play.</p>,
                icon: 'info',
                allowOutsideClick: false
            });
        } else {
            confirmSetPresentation = true;
        }
        if (confirmSetPresentation) {
            this.setState({
                ActivePresentationMode: mode,
                VisibilityItemFirstLoad: false,
                VisibilityItemScrollIntoOnce: false
            });
        }

    }
    closePresentationMode() {
        this.setState({
            ActivePresentationMode: 0
        });
    }
    presentationCarouselNext() {
        var { CurrentPresentionCount, TargetItem } = this.state;
        CurrentPresentionCount = CurrentPresentionCount < TargetItem["classtoks"].length - 1 ? CurrentPresentionCount + 1 : CurrentPresentionCount;

        this.setState({
            CurrentPresentionCount: CurrentPresentionCount
        });
    }
    presentationCarouselPrevious() {
        var { CurrentPresentionCount, TargetItem } = this.state;
        CurrentPresentionCount = CurrentPresentionCount >= 1 ? CurrentPresentionCount - 1 : CurrentPresentionCount;
        this.setState({
            CurrentPresentionCount: CurrentPresentionCount
        });
    }
    visibilityItemCheck(e) {
        const { VisibilityItemFirstLoad, TargetItem } = this.state;
        if (VisibilityItemFirstLoad) {
            this.setState({
                CurrentPresentionCount: e
            });
        } else {
            if (TargetItem["classtoks"].length == (e + 1)) {
                this.setState({
                    VisibilityItemFirstLoad: true
                });
            }
        }
    }
    visibilityItemScrollIntoOnce(e) {
        const { VisibilityItemScrollIntoOnce } = this.state;
        if (!VisibilityItemScrollIntoOnce) {
            var item = document.getElementById("presentation-item-" + e);
            item.scrollIntoView();
            this.setState({
                VisibilityItemScrollIntoOnce: true
            });
        }
    }
    render() {
        var { ActivePresentationMode, CurrentPresentionCount, TargetItem, Edit, Preview, LoadActive, items, LoadMoreActive, continuationToken, MenuKind, Navbar, Kinds, CurrentKind } = this.state;
        var loadmoreTrigger = this.loadmore;
        var visibilityItemScrollIntoOnce = this.visibilityItemScrollIntoOnce;
        document.querySelector("body").addEventListener("scroll", function () {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = document.querySelector('body').scrollTop + window.innerHeight;
            let modifier = 10;

            if (currentScroll + modifier > documentHeight) {
                if (continuationToken && !LoadMoreActive && !LoadActive) {
                    loadmoreTrigger();
                }
            }
        });

        if (ActivePresentationMode == "papers" && CurrentPresentionCount > 0) {
            setTimeout(function () {
                visibilityItemScrollIntoOnce(CurrentPresentionCount);
            }, 100);
        }

        return (
            <div>
                <Modal className={'modal-full-width'} show={Preview} style={{ zIndex: "1299" }}>
                    {ActivePresentationMode == 0 ?
                        <>
                            <Modal.Header style={{ justifyContent: "flex-start" }}>
                                <FontAwesomeIcon onClick={this.closePresentation} icon={faArrowLeft} size={'2xl'} style={{ cursor: "pointer", marginRight: "0.5rem" }} />
                                <div style={{ fontWeight: "600" }}>Preview Tok Pak</div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={12} style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                                        <ButtonReact variant={'success'} onClick={this.setPresentationMode}> <FontAwesomeIcon icon={faChalkboard} /> Presentation Mode</ButtonReact>

                                        <Dropdown style={{ marginLeft: "auto" }}>
                                            <Dropdown.Toggle className={'dropdown-remove'} style={{ backgroundColor: "unset", color: "black", border: "unset", padding: "unset" }}>
                                                <ButtonReact variant={'success'} style={{ display: "flex" }}>
                                                    Manage
                                                    <span style={{ display: "flex", alignItems: "center", marginLeft: "3px" }}><FontAwesomeIcon icon={faSortDown} /></span>
                                                </ButtonReact>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={this.editTargetItem}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={this.deleteTargetItem}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    {TargetItem["classtoks"] ?
                                        <>
                                            <Col xs={12} style={{ marginBottom: "10px", textAlign: "center" }}>{CurrentPresentionCount + 1}/{TargetItem["classtoks"] ? TargetItem["classtoks"].length : 1}</Col>
                                            {TargetItem["classtoks"].map((tok, index) => (
                                                <Col md={3} sm={12} className={'tokpak-item-container'}>
                                                    <Row className={'tokpak-item'} style={{ border: CurrentPresentionCount == index ? "5px solid #17a2b8" : "" }}>
                                                        <Col className={'tokpak-item-header'} xs={12}>
                                                            <div>{tok["primary_text"]}</div>
                                                            <div className={'tokpak-count'}>{index + 1}</div>
                                                        </Col>
                                                        <Col xs={12} className={'row'} style={{ margin: "unset" }}>
                                                            {tok["is_detail_based"] ?
                                                                <List dense={true} className={'tokpak-item-bullet'} style={{ textAlign: 'left', maxWidth: '100%', marginLeft: "20px" }}>
                                                                    {tok["details"].map((detail) => (
                                                                        <>
                                                                            {detail != null ?
                                                                                <ListItem style={{ padding: "unset", display: "list-item" }}>
                                                                                    <ListItemText
                                                                                        primary={detail}
                                                                                        style={{ margin: "unset" }}
                                                                                    />
                                                                                </ListItem> : ""
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </List>
                                                                : tok["secondary_text"]}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </> : <></>
                                    }
                                </Row>
                            </Modal.Body>
                        </> : ""
                    }
                    {ActivePresentationMode == "presentation" || ActivePresentationMode == "papers" || ActivePresentationMode == "practicetest" ?
                        <>
                            <Modal.Header>
                                <FontAwesomeIcon onClick={this.closePresentationMode} icon={faTimes} size={'2x'} style={{ cursor: "pointer", marginRight: "0.5rem" }} />
                            </Modal.Header>
                            <Modal.Body style={{ position: "relative", overflowY: ActivePresentationMode != "practicetest" ? "scroll" : "unset", height: ActivePresentationMode != "practicetest" ? "550px" : "" }}>
                                {ActivePresentationMode == "presentation" ?
                                    <>
                                        <Row style={{ justifyContent: "center" }}>
                                            <Col xs={'auto'} onClick={this.presentationCarouselPrevious}>
                                                <div className={'tokpak-nav-btn'}>
                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                </div>
                                            </Col>
                                            <Col xs={'auto'} style={{ marginBottom: "10px", textAlign: "center" }}>{CurrentPresentionCount + 1}/{TargetItem["classtoks"] ? TargetItem["classtoks"].length : 1}</Col>
                                            <Col xs={'auto'} onClick={this.presentationCarouselNext}>
                                                <div className={'tokpak-nav-btn'}>
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Carousel ref={this.presentationCarouselRef} activeIndex={CurrentPresentionCount} indicators={false} controls={false} interval={null}>
                                            {TargetItem["classtoks"].map((tok, index) => (
                                                <Carousel.Item>
                                                    <TokPakPresentationItem item={tok} />
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </> : ""
                                }
                                {ActivePresentationMode == "papers" ?
                                    <>
                                        {TargetItem["classtoks"].map((tok, index) => (
                                            <VisibilitySensor onChange={(e) => { this.visibilityItemCheck(index) }}>
                                                <div style={{ marginBottom: "10px" }} id={"presentation-item-" + index}>
                                                    <TokPakPresentationItem item={tok} />
                                                </div>
                                            </VisibilitySensor>
                                        ))}
                                    </> : ""
                                }
                                {ActivePresentationMode == "practicetest" ?
                                    <>
                                        {TargetItem["classtoks"].length > 4 ?
                                            <QuizToks items={TargetItem["classtoks"]} /> :
                                            ""
                                        }
                                    </> : ""
                                }
                            </Modal.Body>
                        </> : ""
                    }
                </Modal>
                <AddEditTokPak addTokPakRef={this.addTokPakRef} />
                {Navbar ?
                    <Grid className={'text-color-white'} container spacing={1} style={{ padding: "3px" }}>
                        <div style={{ position: "relative" }}>
                            <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
                        </div>
                        <ButtonReact variant='primary' style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                        </ButtonReact>
                        <div style={{ marginRight: "auto", display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "10px", fontSize: "21px", backgroundColor: "#112379", color: "white", borderRadius: "10px", textAlign: "center", textTransform: "capitalize", padding: "10px 40px" }}>
                            Tok Groups - {CurrentKind == "" ? "Owned" : CurrentKind}
                        </div>
                        {/* <AddClassTok customClass={customClass} /> */}

                        <div className={'refresh-btn'} onClick={this.reload}>
                            <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                        </div>
                    </Grid>
                    : ""}
                {MenuKind ?
                    <Row>
                        {Kinds.map((Kind, index) => (
                            <ButtonReact variant='secondary' onClick={this.setKind} className={CurrentKind != Kind.value ? "kind-option-btn col" : "kind-option-btn col active"} data-value={Kind.value} style={{ backgroundColor: Kind.color }}>
                                {Kind.displayText}
                            </ButtonReact>
                        ))}
                    </Row> :
                    ""
                }
                {LoadActive ?
                    <WaitSpinner /> :
                    <Row>
                        {items.map((item) => (
                            <>
                                {CurrentKind == "" || CurrentKind == item["tokpak_type"] ?
                                    <TokPakItem item={item} openPreview={this.openPreview} deleteItem={this.deleteItem} editItem={this.editItem} /> : ""
                                }
                            </>
                        ))}
                        {LoadMoreActive ? <WaitSpinner /> : ""}
                    </Row>
                }

            </div>

        );
    }
}

export default TokPaksContainer;