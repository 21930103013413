import React, { useState } from 'react'
import '../styles.css';
import './css/TokBack.css';
import ReactCardFlip from 'react-card-flip';
const TokBackCard = (props) => {
    const [block, SetBlock] = useState(props.block? props.block: []);
    const [isFlipped, setIsFlipped] = useState(block["flip"] ? block["flip"] : false);
    const flipCard = (e) => {
        var value = Number(e.target.getAttribute("data-index"));
        let tempTokbackBlocks = block;
        tempTokbackBlocks["flip"] = !tempTokbackBlocks["flip"];
        SetBlock(tempTokbackBlocks);
        setIsFlipped(tempTokbackBlocks["flip"]);
    }
    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <div className={'tokback-card tokback-card-front'} onClick={flipCard}>
                Click To Show
            </div>
            <div className={'tokback-card tokback-card-back'} onClick={flipCard}>
                {block["text"]}
            </div>
        </ReactCardFlip>
    )
}

export default TokBackCard;