import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import './../App.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';

const termLinks = [
    {
        displayText: "TOK BLITZ", secondary:
            [
                { primary: "What is the character limit for creating a team?" },
                {
                    bullet: [
                        "25 characters"
                    ]
                },
                { primary: "What is the character limit for a display name?" },
                {
                    bullet: [
                        "25 characters"
                    ]
                },
                { primary: "What is the character limit for a saved game?" },
                {
                    bullet: [
                        "25 characters"
                    ]
                },
                { primary: "What are strikes used for?" },
                {
                    bullet: [
                        "Strikes are used to eliminate wrong letters that are not apart of the answer"
                    ]
                },
                { primary: "What are saved games used for?" },
                {
                    bullet: [
                        "Saved games can be purchased in order to play the same quotes in the same order that it is saved in"
                    ]
                },
                { primary: "What are teams used for?" },
                {
                    bullet: [
                        "Teams are used for playing multiplayer. Different teams will have different images and team points."
                    ]
                },
                { primary: "What is a Personal Room?" },
                {
                    bullet: [
                        "Personal Room allows you to play against an opponent of your choice. You can enter the code of your opponents personal room or they can enter the code from your Personal Room. Each player must have the Personal Room feature bought in order to play a game inside a Personal Room."
                    ]
                },
                { primary: "What is the policy on names?" },
                {
                    bullet: [
                        "Names are not case sensitive, so two users cannot have the same username if one of the letters is capitalized."
                    ]
                },
                { primary: "Account name not working" },
                {
                    bullet: [
                        "If an account name does not work, than that means that the name is already taken by an existing user."
                    ]
                },
                { primary: "Multiplayer not working" },
                {
                    bullet: [
                        "We are sorry you are experiencing these problems. We will try to get this fixed as soon as possible."
                    ]
                },
                { primary: "Saved Game not working" },
                {
                    bullet: [
                        "We are sorry you are experiencing these problems. We will try to get this fixed as soon as possible."
                    ]
                },
                { primary: "How many strikes do you earn from Daily Bonus at first login?" },
                {
                    bullet: [
                        "You earn 7 strikes as a result of the Daily Bonus per day."
                    ]
                },
                { primary: "How do you earn coins in Tok Blitz?" },
                {
                    bullet: [
                        "You cannot earn coins in Tok Blitz, but you can earn coins in Tokkepedia, which can be used in Tok Blitz."
                    ]
                },
                { primary: "How do you earn Team Rank points in Tok Blitz?" },
                {
                    bullet: [
                        "Team Rank points by completing a multiplayer game. Your team earns 2 points if you win, 1 point if you lose, and 0 points if you exit before completion."
                    ]
                },
                { primary: "How do you earn points in Tok Blitz?" },
                {
                    bullet: [
                        "You earn 1 point in Tok Blitz for completing a single player game. Earning points will increase your patch rank."
                    ]
                },
                { primary: "What is a Personal Room?" },
                {
                    bullet: [
                        "Personal Room allows you to play against an opponent of your choice. You can enter the code of your opponents personal room or they can enter the code from your Personal Room. Each player must have the Personal Room feature bought in order to play a game inside a Personal Room."
                    ]
                },
                { primary: "How many strikes are you given in Multiplayer?" },
                {
                    bullet: [
                        "12 strikes."
                    ]
                },
                { primary: "How many rounds are there in a single-player game?" },
                {
                    bullet: [
                        "5 rounds."
                    ]
                },
                { primary: "How many rounds are there in a multiplayer game?" },
                {
                    bullet: [
                        "7 rounds."
                    ]
                },
                { primary: "When is Tok Blitz Mobile going to be ready?" },
                {
                    bullet: [
                        "Beta testing is in progress and Android and iOS will be ready in the next week or two."
                    ]
                }
        ],
    },
    {
        displayText: "TOKKET", secondary:
        [
            { primary: "What is the character limit for creating a title?" },
            {
                bullet: [
                    "25 characters"
                ]
            },
            { primary: "What is the purpose of a title?" },
            {
                bullet: [
                    "Titles are what differentiate you from any other user on Tokket."
                ]
            },
            { primary: "How much does a generic title cost?" },
            {
                bullet: [
                    "A generic title cost $2.99"
                ]
            },
            { primary: "How much does a unique title cost?" },
            {
                bullet: [
                    "A unique title cost $3.99"
                ]
            },
            { primary: "What is a group account?" },
            {
                bullet: [
                    "A group account is an account which you can use to make an account for an organization or a group with different subaccounts. This feature costs $2.99."
                ]
            },
            { primary: "What are subaccounts?" },
            {
                bullet: [
                    "Subaccounts are accounts that are under a bought group account. A group account costs $2.99."
                ]
            },
            { primary: "What is the policy on names?" },
            {
                bullet: [
                    "Names are not case sensitive, so two users cannot have the same username if one of the letters is capitalized."
                ]
            },
            { primary: "What are patches?" },
            {
                bullet: [
                    "Patches represent your rank, which goes up after completing games in Tok Blitz."
                ]
            },
            { primary: "How do you earn patches?" },
            {
                bullet: [
                    "You earn patches by gaining the specified points needed for a patch. The points are gained through completing games in Tok Blitz."
                ]
            },
            { primary: "What is the difference between a badge and a patch?" },
            {
                bullet: [
                    "Patches have to do with rank, with platform rank points, and can be displayed instead of a flag. Badges are rewarded for special contests or achievements or can be given such as membership badges or when something is purchased such as a royalty membership."
                ]
            },
            { primary: "How do the colors work for membership badges?" },
            {
                bullet: [
                    "The color of your membership badge represents the year you joined. Starting with 2020, the membership badge is colored black. For 2021, the membership badge is Green and for every subsequent year, the sequence is as follows: Baby Blue, Blue, Purple, Red, Pink, Orange. Therefore, you can only have one membership badge color depending on when your account was created."
                ]
            },
            { primary: "How do the colors work for royalty badges?" },
            {
                bullet: [
                    "The color of your membership badge represents how long you have been a Royalty member. Starting with your first year, the royalty badge is colored white. For every year you renew your royalty membership, you receive another badge. The sequence is as follows: Yellow, Orange, Green, Blue, Purple."
                ]
            },
        ]
    },
    {
        displayText: "TOKKEPEDIA", secondary:
        [
            { primary: "Will all of my purchases and tok data be retained after the Tokkepedia Beta is done?" },
            {
                bullet: [
                    "Yes. All the toks entered and purchases are production data. They will continue to be visible and available after beta."
                ]
            },
            { primary: "What are the limits for creating a title?" },
            {
                bullet: [
                    "Titles are not case-sensitive, so you cannot use uppercase to copy someone else’s title."
                ]
            },
            { primary: "What is the purpose of sets?" },
            {
                bullet: [
                    "Sets consists of selected Toks with the same Tok Group and Tok Type that allow you to play Tok Cards, Tok Match, and Tok Choice. At least 5 toks are needed to create a set."
                ]
            },
            { primary: "What is Tok Cards?" },
            {
                bullet: [
                    "Tok Cards is a feature which allows you to interact with toks in the form of a flashcard."
                ]
            },
            { primary: "What is Tok Match?" },
            {
                bullet: [
                    "Tok Match is a game in Tokkepedia that allows you to match Primary and Secondary details of Toks together."
                ]
            },
            { primary: "What is Tok Choice?" },
            {
                bullet: [
                    "Tok Choice is a game mode that allows you to play a multiple-choice game based off details from toks."
                ]
            },
            { primary: "What is Tok Back?" },
            {
                bullet: [
                    "Tok Back is a feature in Tokkepedia that allows you to view your Toks into smaller details."
                ]
            },
            { primary: "What is the purpose of sets?" },
            {
                bullet: [
                    "Sets are used to group different Toks together, so they can be played in Tok Cards, Tok Match, Tok Choice, and Tok Back."
                ]
            },
            { primary: "What is Tok?" },
            {
                bullet: [
                    "Tok is a feature that you can use to enter in notes for your classes. You can do the same things in tok as Tokkepedia like creating sets and playing games."
                ]
            },
            { primary: "What are class groups?" },
            {
                bullet: [
                    "Class groups are used so you can share your class information with other classmates in a group setting."
                ]
            },
            { primary: "What are class sets?" },
            {
                bullet: [
                    "Class sets operate the same way as regular Tokkepedia sets would, except they are exclusive for toks."
                ]
            },
            { primary: "What are Stickers used for?" },
            {
                bullet: [
                    "Stickers are displayed in front of a tile on the home feed. Stickers can be used to grab the attention of a user or to make the tile look more decorated."
                ]
            },
            { primary: "What are Tokmojis used for?" },
            {
                bullet: [
                    "Tokmojis are special images that can be used when you comment on a Tok."
                ]
            },
            { primary: "What are avatars used for?" },
            {
                bullet: [
                    "Avatars can be used as an alternative for a profile picture. They cost 3 coins."
                ]
            },
            { primary: "How do you use an avatar as a profile picture?" },
            {
                bullet: [
                    "You use an avatar as a profile picture by checking on the box that says “Use avatar as instead of profile picture” and then selecting the avatar of choice."
                ]
            },
            { primary: "What is a treasure chest used for?" },
            {
                bullet: [
                    "A treasure chest is a reaction used to give people 250 coins. It costs $3.00 to use a treasure chest."
                ]
            },
            { primary: "What are Stickers used for?" },
            {
                bullet: [
                    "Stickers are displayed in front of a tile on the home feed. Stickers can be used to grab the attention of a user or to make the tile look more decorated."
                ]
            },
            { primary: "What are patches?" },
            {
                bullet: [
                    "Patches represent your rank, which goes up after completing games in Tok Blitz."
                ]
            },
            { primary: "How do you earn patches?" },
            {
                bullet: [
                    "You earn patches by gaining the specified points needed for a patch. The points are gained through completing games in Tok Blitz."
                ]
            },
            { primary: "What is a badge and how do you get one?" },
            {
                bullet: [
                    "Badges are rewarded for special contests or achievements or can be given such as membership badges or when something is purchased such as a royalty membership."
                ]
            },
            { primary: "How do you use badges as a profile picture?" },
            {
                bullet: [
                    "You use a badge as a profile picture by checking on the box that says “Use badge as instead of profile picture” and then selecting the badge of choice."
                ]
            },
            { primary: "What are the benefits of a royalty membership?" },
            {
                bullet: [
                    ""
                ]
            },
            { primary: "How much does a royalty membership cost?" },
            {
                bullet: [
                    "A Royalty Membership includes features such as earning double the coins for posting toks, one free royalty title, and a royalty badge."
                ]
            },
            { primary: "Why is my account name not working?" },
            {
                bullet: [
                    "A royalty membership costs $4.99."
                ]
            },
            { primary: "How do you earn coins in Tokkepedia?" },
            {
                bullet: [
                    "If an account name does not work, then that means that the name is already taken by an existing user."
                ]
            },
            { primary: "How many coins do you earn from receiving a treasure?" },
            {
                bullet: [
                    "250 coins."
                ]
            },
            { primary: "When is Tokkepedia mobile going to be ready?" },
            {
                bullet: [
                    "In 3-4 months."
                ]
            },
            { primary: "How do you earn points in Tokkepedia?" },
            {
                bullet: [
                    "You earn points in Tokkepedia by playing Tok Match or Tok Back, and answering correctly for the provided questions."
                ]
            },
            { primary: "Why can't you use the name in the category and vice versa?" },
            {
                bullet: [
                    "You cannot use the word in the name as a category because category will be shown in a game which will reveal the answer."
                ]
            },
            { primary: "Why can't the Category be the same as the Tok Type?" },
            {
                bullet: [
                    "Do not use the Tok Type as the Category because it will be redundant. Instead, use the category to be more specific. e.g. If Tok Type is Music, enter 1980's, Hit Song, Rock, or The Beatles for Category."
                ]
            },
            { primary: "What is a badge and how do you get one?" },
            {
                bullet: [
                    "Badges are rewarded for special contests or achievements or can be given such as membership badges or when something is purchased such as a royalty membership."
                ]
            },
            { primary: "What is the difference between a badge and a patch?" },
            {
                bullet: [
                    "Patches have to do with rank, with platform rank points, and can be displayed instead of a flag. Badges are rewarded for special contests or achievements or can be given such as membership badges or when something is purchased such as a royalty membership."
                ]
            },
            { primary: "How do the colors work for membership badges?" },
            {
                bullet: [
                    "The color of your membership badge represents the year you joined. Starting with 2020, the membership badge is colored black. For 2021, the membership badge is Green and for every subsequent year, the sequence is as follows: Baby Blue, Blue, Purple, Red, Pink, Orange. Therefore, you can only have one membership badge color depending on when your account was created."
                ]
            },
            { primary: "How do the colors work for royalty badges?" },
            {
                bullet: [
                    "The color of your membership badge represents how long you have been a Royalty member. Starting with your first year, the royalty badge is colored white. For every year you renew your royalty membership, you receive another badge. The sequence is as follows: Yellow, Orange, Green, Blue, Purple"
                ]
            }
        ]
    }
]
function Terms() {
    return (
        <React.Fragment>
            <CssBaseline />

            <Navbar transparentTop={true} />
            <main style={{ paddingBottom: "unset" }}>
                <Container className={'banner'}>
                    <img src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header-aboutus.png" style={{ width: "100%", height: "570px" }} />
                    <div></div>
                    <Row className={'content-aboutus'}>
                        <Typography
                            align="center"
                            fontSize={'80px'}
                            fontWeight={'bold'}
                            color={'white'}
                            textTransform={'uppercase'}
                        >
                            Frequently Asked Questions
                        </Typography>
                        <Typography
                            align="center"
                            fontSize={'20px'}
                            color={'white'}
                        >
                            Tokket is a gamified social platform that blends social media, games, knowledge, and rewards.
                        </Typography>
                    </Row>

                </Container>
                <Container>
                    <Row style={{ marginBottom: "5px" }}>
                        <Col md={4} xs={12}>
                            <h4 style={{ fontSize: "45px" }}>Terms of Services</h4>
                            <List>
                                {termLinks.map((privacyLink, index) => (
                                    <>
                                        <ListItem className={'menu-list-link'}>
                                            <Link href={`#section-${index}`}>{privacyLink.displayText}</Link>
                                        </ListItem>
                                        <List style={{marginLeft: "40px"}}>
                                            {privacyLink["secondary"].map((secondaryLink, secondaryIndex) => (
                                                <ListItem className={'menu-list-link'}>
                                                    <Link href={`#section-${index}-${secondaryIndex}`}>{secondaryLink.primary}</Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                ))}
                            </List>
                        </Col>
                        <Col md={8} xs={12}>
                            <List>
                                {termLinks.map((termLink, index) => (
                                    <ListItem style={{ display: "block" }} id={`section-${index}`}>
                                        <h4 style={{ fontSize: "34px" }}>{termLink.displayText}</h4>
                                        <div style={{ fontSize: "16px", marginTop: "30px", color: "#818181", display: "grid", gridGap: "20px" }}>
                                            {termLink.secondary.map((secondary, secondaryIndex) => (
                                                <>
                                                    {secondary["primary"] ? <div id={`section-${index}-${secondaryIndex}}`}>{secondary["primary"]}</div> : ""}

                                                    {secondary["bullet"] ?
                                                        <ul style={{ listStyleType: 'circle' }}>
                                                            {secondary["bullet"].map((bullet, index) => (
                                                                <li>
                                                                    <div>{bullet}</div>
                                                                </li>
                                                            ))}
                                                        </ul> : ""}
                                                    {secondary["h4"] ? <h4>{secondary["h4"]}</h4> : ""}
                                                </>
                                            ))}
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </Col>
                    </Row>

                </Container>
            </main>
            {/* Footer */}
            <Footer disableAbsolute={true}/>
            {/* End footer */}
        </React.Fragment>
    );
}

export default Terms; 