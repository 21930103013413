import React, { useState } from 'react'
import '../styles.css'
import './css/TokCards.css';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import arrayShuffle from 'array-shuffle';
import TokCard from './TokCard';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faChevronRight, faCog, faPause, faPlay, faRandom, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-bootstrap';
import { createRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Switch from '@mui/material/Switch';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { RefObject } from 'react';
import { CarouselRef } from 'react-bootstrap/esm/Carousel';
const MySwal = withReactContent(Swal);

const TokCards = (props) => {
    const [items, SetItems] = useState(props.items);
    const [starItems, SetStarItems] = useState([]);
    const [tokCardNextInterval, SetTokCardNextInterval] = useState(null);
    const [playCarouselFlip, SetPlayCarouselFlip] = useState(null);
    const [showOptions, SetShowOptions] = useState(false);
    const [playStarOnly, SetPlayStarOnly] = useState(false);
    const [displayImage, SetDisplayImage] = useState(true);
    const [starItemCount, SetStarItemCount] = useState(0);
    const [itemCount, SetItemCount] = useState(0);
    const tokStarCardCarousel = createRef<CarouselRef>();
    const tokCardCarousel = createRef<CarouselRef>();
    const nextItem = () => {
        if (playStarOnly) {
            if (starItemCount < starItems.length - 1) {
                let tempCurrentCount = starItemCount + 1;
                SetStarItemCount(tempCurrentCount);
            } else {
                SetStarItemCount(0);
            }
            tokStarCardCarousel.current?.next();
        } else {
            if (itemCount < items.length - 1) {
                let tempCurrentCount = itemCount + 1;
                SetItemCount(tempCurrentCount);
            } else {
                SetItemCount(0);
            }
            tokCardCarousel.current?.next();
        }
    }
    const previousItem = (e) => {
        if (playStarOnly) {
            if (starItemCount == 0) {
                SetStarItemCount(starItems.length - 1);
            } else {
                SetStarItemCount(0);
            }
            tokStarCardCarousel.current.prev();
        } else {
            if (itemCount == 0) {
                SetItemCount(items.length - 1);
            } else {
                SetItemCount(0);
            }
            tokCardCarousel.current.prev();
        }
    }
    const playCarousel = (e) => {
        if (tokCardNextInterval == null) {
            SetTokCardNextInterval(
                setInterval(() => {
                    (tokCardCarousel.current.element.querySelector(".active.carousel-item")!.querySelector(".react-card-front")!.querySelector(".tok-card") as HTMLElement).click();
                    nextItem();
                }, 5000)
            );
            SetPlayCarouselFlip(
                setInterval(() => {
                    if (playStarOnly) {
                        (tokStarCardCarousel.current.element.querySelector(".active.carousel-item")!.querySelector(".react-card-front")!.querySelector(".tok-card") as HTMLElement).click();
                    } else {
                        (tokCardCarousel.current.element.querySelector(".active.carousel-item")!.querySelector(".react-card-front")!.querySelector(".tok-card") as HTMLElement).click();
                    }

                }, 3000)
            )
        } else {
            SetTokCardNextInterval(clearTimeout(tokCardNextInterval));
            SetPlayCarouselFlip(clearTimeout(playCarouselFlip));
        }
    }

    const shuffleItems = (e) => {
        if (playStarOnly) {
            SetStarItems(arrayShuffle(starItems));
        } else {
            SetItems(arrayShuffle(items));
        }
    }

    const flipAllCards = (e) => {
        const allCards = tokCardCarousel.current.element.querySelectorAll(".carousel-item");
        for (let x = 0; x < allCards.length; x++) {
            (allCards[x].querySelector(".react-card-front")!.querySelector(".tok-card") as HTMLElement).click();
        }
    }

    const closeOptions = (e) => {
        SetShowOptions(false);
    }

    const openOptions = (e) => {
        SetShowOptions(true);
    }
    const switchStarOnly = (e) => {
        SetPlayStarOnly(!playStarOnly);
    }
    const switchDisplayImage = (e) => {
        SetDisplayImage(!displayImage);
    }
    const setStarCard = (e) => {
        let starToks = new Array;
        for (let x = 0; x < items.length; x++) {
            if (items[x]["star"]) {
                starToks.push(items[x]);
            }
        }
        SetStarItems(starToks);
    }
    return (
        <Row style={{ justifyContent: "center" }}>
            <Modal show={showOptions} style={{ zIndex: "1300" }}>
                <Modal.Header style={{ fontWeight: "600" }}>
                    Options
                    <FontAwesomeIcon icon={faTimes} style={{ marginLeft: "auto", cursor: "pointer" }} onClick={closeOptions} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={6} style={{ paddingLeft: "unset" }}>
                            <Switch checked={playStarOnly} onClick={switchStarOnly} /> Play Starred Only
                        </Col>
                        <Col xs={6}>
                            <Switch checked={displayImage} onClick={switchDisplayImage} /> Show Images
                        </Col>
                        <Col xs={12}>
                            <Button>
                                <FontAwesomeIcon icon={faRefresh} /> Refresh Cards
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Col className={'row'} xs={'auto'} style={{ alignContent: "flex-start" }}>
                <Col className={'row'} xs={12} style={{ padding: "unset", margin: "unset", alignContent: "flex-start" }}>
                    <Col xs={12}>
                        <LinearProgress color='primary' variant="determinate" value={playStarOnly ? ((starItemCount + 1) / starItems.length) * 100 : ((itemCount + 1) / items.length) * 100} />
                    </Col>
                    <Col xs={12} style={{ display: "flex", justifyContent: "center", fontWeight: "600" }}>
                        {playStarOnly ? <>{starItemCount + 1}</> : <>{itemCount + 1}</>}/{playStarOnly ? <>{starItems.length}</> : <>{items.length}</>}
                    </Col>
                </Col>
                <Col className={'row'} xs={6} style={{ alignContent: "flex-start", flexDirection: "column", margin: "unset", padding: "unset" }}>
                    <Col xs={12} style={{ marginBottom: "15px" }}>
                        <Button onClick={playCarousel} style={{ width: "100%" }} variant={tokCardNextInterval == null ? "primary" : "danger"}>
                            <FontAwesomeIcon icon={tokCardNextInterval == null ? faPlay : faPause} /> {tokCardNextInterval == null ? "Play" : "Stop"}
                        </Button>
                    </Col>

                    <Col xs={12}>
                        <Button style={{ width: "100%" }} onClick={openOptions}>
                            <FontAwesomeIcon icon={faCog} /> Options
                        </Button>
                    </Col>
                </Col>
                <Col className={'row'} xs={6} style={{ alignContent: "flex-start", flexDirection: "column", margin: "unset", padding: "unset" }}>
                    <Col xs={12} style={{ marginBottom: "15px" }}>
                        <Button style={{ width: "100%" }} onClick={shuffleItems}>
                            <FontAwesomeIcon icon={faRandom} /> Shuffle
                        </Button>
                    </Col>

                    <Col xs={12}>
                        <Button style={{ width: "100%" }} onClick={flipAllCards}>
                            Flip All Cards
                        </Button>
                    </Col>
                </Col>
            </Col>
            <Col className={'row'} style={{ maxWidth: "470px" }}>
                <Col xs={12}>
                    <Carousel ref={tokStarCardCarousel} indicators={false} controls={false} interval={null} style={{ display: playStarOnly ? "" : "none" }}>
                        {starItems.map((item, index) => (
                            <Carousel.Item key={item["id"] + index + "cards"}>
                                <TokCard tok={item} setStarCard={setStarCard} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <Carousel ref={tokCardCarousel} indicators={false} controls={false} interval={null} style={{ display: playStarOnly ? "none" : "" }}>
                        {items.map((item, index) => (
                            <Carousel.Item key={item["id"] + index + "cards"}>
                                <TokCard tok={item} setStarCard={setStarCard} />
                            </Carousel.Item>
                        ))}

                    </Carousel>
                </Col>
                <Col className={'row'} xs={12} style={{ justifyContent: "center", marginTop: "15px" }}>
                    <div className={'navigate-card-btn'} onClick={previousItem} style={{ marginRight: "7.5px" }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div className={'navigate-card-btn'} onClick={nextItem} style={{ marginLeft: "7.5px" }}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </Col>
            </Col>

        </Row>
    )
}

export default TokCards;