import { cloneDeep } from "lodash";

const SECOND = 1;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY;
const text = [
    { ref: "familyfriends", display: "Family & Friends" },
    { ref: "newhome", display: "New Home" },
    { ref: "socialevents", display: "Social Events" },
    { ref: "newage", display: "New Age" },
    { ref: "howto", display: "How To" },
    { ref: "healthcare", display: "Health Care" },
    { ref: "humanservices", display: "Human Services" },
    { ref: "projectmanagement", display: "Project Management" },
    { ref: "juniorschool", display: "Junior School" },
    { ref: "highschool", display: "High School" },
    { ref: "socialstudies", display: "Social Studies" },
    { ref: "specialdays", display: "Special Days" },
    { ref: "bibleverses", display: "Bible Verses" },
    { ref: "artificialintelligence", display: "Artificial Intelligence" },
    { ref: "mylearning", display: "My Learning" },
    { ref: "myprogress", display: "My Progress" },
    { ref: "mytoks", display: "My Toks" },
    { ref: "mygroups", display: "My Groups" },
]

const usStateList = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
]

export function convertRelative(date) {
    const dateData = new Date() - new Date(date);
    const ts = new Date(dateData);
    const seconds = dateData / 1000;
    const minutes = seconds / MINUTE;
    const hours = seconds / HOUR;
    const days = seconds / DAY;
    if (seconds < 1 * MINUTE)
        return seconds == 1 ? "1s" : ts.Seconds + "s";

    if (seconds < 2 * MINUTE)
        return "1m";

    if (seconds < 45 * MINUTE)
        return Math.floor(minutes) + "m";

    if (seconds < 90 * MINUTE)
        return "1h";

    if (seconds < 24 * HOUR)
        return Math.floor(hours) + "h";

    if (seconds < 48 * HOUR)
        return "1d";

    if (seconds < 30 * DAY)
        return Math.floor(days) + "d";

    if (seconds < 12 * MONTH) {
        const months = Math.floor(days / 30);
        return months <= 1 ? ts.Days + "d" : months + "mth";
    }
    else {
        const years = Math.floor(days / 365);
        return years <= 1 ? years + "yrs" : years + "yrs";
    }
}

export function upperCasesFirstLetter(string) {
    if (string)
        return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
    else
        return string;
}

export function textDisplay(string) {
    for (let x = 0; x < text.length; x++) {
        if (string.toLowerCase().includes(text[x].ref)) {
            return string.toLowerCase().replace(text[x].ref, text[x].display);
        }
    }
    return upperCasesFirstLetter(string);
}

export function textDisplayReverse(string) {
    for (let x = 0; x < text.length; x++) {
        if (string.toLowerCase().includes(text[x].display)) {
            return string.toLowerCase().replace(text[x].display, text[x].ref);
        }
    }
    return string;
}

export function notifcationMsg(string) {
    const text = [
        { ref: "heart", display: "reacted" },
        { ref: "sad", display: "reacted" },
        { ref: "gemb", display: "reacted" },
        { ref: "gemc", display: "reacted" },
        { ref: "accurate", display: "reacted" },
        { ref: "inaccurate", display: "reacted" },
        { ref: "comment", display: "commented" },
    ]
    for (let x = 0; x < text.length; x++) {
        if (string.toLowerCase().includes(text[x].ref)) {
            return text[x].display;
        }
    }
    return string;
}

export function idFormat(line) {
    return line.replace(/[^a-zA-Z0-9]/g, '').replace("/", "").replace(" ", "_").replace("&", "and").replace("é", "e");
}


export function randomString(count) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < count) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function isYoutubeLink(url) {
    var regExp = /(?:http?s?:\/\/)?(?:www.)?(?:m.)?(?:music.)?youtu(?:\.?be)(?:\.com)?(?:(?:\w*.?:\/\/)?\w*.?\w*-?.?\w*\/(?:embed|e|v|watch|.*\/)?\??(?:feature=\w*\.?\w*)?&?(?:v=)?\/?)([\w\d_-]{11})(?:\S+)?/;
    var match = url.match(regExp);
    if (match) {
        return true;
    }
    return false;
}

export function extractYoutubeID(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match[2]) {
        return match[2];
    }
    return "";
}

export function arrayEqualPartSplitter(array, limit) {
    if (array.length <= limit) return [array];
    const perChunk = Math.ceil(array.length / Math.ceil(array.length / limit));
    return [array.slice(0, perChunk)].concat(arrayEqualPartSplitter(array.slice(perChunk), limit));
}

export function addParam(currentString, newParam, paramValue) {
    if (currentString) {
        currentString = currentString + "&";
    }
    currentString = currentString + newParam + "=" + paramValue;
    return currentString;
}

export function unique(array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function jsonKeySnakeCaseToCamelCase(jsonFile) {
    let keys = Object.keys(jsonFile);
    let newJson = {};
    if (jsonFile) {
        for (let x = 0; x <= keys.length; x++) {
            try {
                let newKeyParts = keys[x].split(/([-_])/g);
                newKeyParts = removeItemArray(newKeyParts, "_");
                newKeyParts = removeItemArray(newKeyParts, "-");
                let newKey = newKeyParts[0].toLowerCase();
                if (newKeyParts.length > 0) {
                    for (let y = 1; y < newKeyParts.length; y++) {
                        newKey = newKey + capitalizeFirstLetter(newKeyParts[y]);
                    }
                }
                if (newKey) {
                    if (jsonFile[keys[x]]) {
                        newJson[newKey] = jsonFile[keys[x]];
                    }
                }
            } catch (err) {
                if (keys[x]) {
                    newJson[keys[x]] = jsonFile[keys[x]];
                }
            }
        }
    } else {
        return jsonFile;
    }
    return newJson;
}

export function removeItemArray(arr, value) {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

export function usStateData() {
    return usStateList;
}

export function usStateFullState(value) {
    if (value)
        return usStateList.filter(item => item.value === value)[0];
    else
        return value
}

export function textDisplayFormat(value) {
    if (value)
        return value.replace(/\n/g, "<br />");
    else
        return value
}