import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Navbar from '../components/Navbar';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Footer from '../components/Footer';
import Typography from "@mui/material/Typography";
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  });

// Get the action to complete.
const mode = params["mode"]; //getParameterByName('mode');
// Get the one-time code from the query parameter.
const actionCode = params["oobCode"]; //getParameterByName('oobCode');
// (Optional) Get the continue URL from the query parameter if available.
const continueUrl = params["continueUrl"]; //getParameterByName('continueUrl');
// (Optional) Get the language code if available.
const lang = params["lang"]; //getParameterByName('lang') || 'en';

console.log(mode);

const ChangePassword = (props) => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const onChangePassword = (e) => {
        e.preventDefault();
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            const accountEmail = email;

            confirmPasswordReset(auth, actionCode, newPassword).then(() => {
                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
                navigate('/', { replace: true });
            }).catch((error) => {
                // Error occurred during confirmation. The code might have expired or the
                // password is too weak.
                console.log(error);
            });
        }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            console.log(error);
        });
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <React.Fragment>
        <CssBaseline />

        <Navbar />

        <Container className={'banner'}>
        <img src="https://tokketcontent.blob.core.windows.net/tokketweb/background-header-aboutus.png" style={{ width: "100%", height: "570px" }} />
        <div></div>
        <Row className={'content-aboutus'}>
            <Typography
            align="center"
            fontSize={'80px'}
            fontWeight={'bold'}
            color={'white'}
            textTransform={'uppercase'}
            >
            Change Password
            </Typography>
        </Row>
        <Row>
        <TextField id={'newPassword'} type={showPassword ? 'text' : 'password'} style={{ width: "100%" }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} label="New Password"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end"> {/* Add position prop here */}
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
            <Button variant={'contained'} color={"primary"} style={{ width: "100%" }} onClick={onChangePassword}>Change Password</Button>
        </Row>
        </Container>
        </React.Fragment>
    )
}

export default ChangePassword