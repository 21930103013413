import React from 'react'
import '../styles.css'
import './css/TokTileContainer.css';
import Grid from "@mui/material/Grid";
import ButtonReact from 'react-bootstrap/Button';
import { createRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { faFilter, faTimes, faArrowDownShortWide, faMagnifyingGlass, faRefresh, faStar, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from '@mui/material/TextField';
import { textDisplay } from '../services/helperTools';
import Tooltip from '@mui/material/Tooltip';
import { cloneDeep } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
const filterOptions = [
    {
        displayText: "Feed Options", value: "publicfeed", options: [
            { displayText: "Public", value: true },
            { displayText: "My Toks", value: false }
        ]
    },
    {
        displayText: "Filter By", value: "FilterBy", options: [
            { displayText: "Category", value: "getCategories" },
            { displayText: "Type", value: "getType" }
        ]
    },
    {
        displayText: "Image", value: "imagetype", options: [
            { displayText: "Both", value: 0 },
            { displayText: "Image", value: 1 },
            { displayText: "No Image", value: 2 },
        ]
    },
]
const sortOptions = [
    {
        displayText: "Sort by Reference ID/#", value: "reference_id"
    },
    {
        displayText: "Sort by Title", value: "primary_text"
    },
]

interface States {
    ShowFilter: boolean;
    ShowSort: boolean;
    ActiveFavorite: boolean;
    AddTokTitle: string;
    DisableSearch: boolean;
    DisableAddTok: boolean;
    Level1: string;
    Level2: string;
    Level3: string;
    Title: string;
    bgColor: string;
    fontColor: string;
    addChannelOption: boolean;
    enableDeleteChannel: boolean;
    activeLevel: number;
}

interface Props {
    openAddChannel: (data: { [key: string]: any }) => void;
    openDeleteChannel: (data: boolean) => void;
    addRemoveFavorite: (data: boolean) => void;
    activeLevel: number;
    activeFavorite: boolean;
    disableAddTok: boolean;
    disableSearch: boolean;
    DisableNavbar: boolean;
    continuationToken: string;
    addTokTitle: string;
    fontColor: string;
    bgColor: string;
    title: string;
    tokTileTitleBar: React.RefObject<any>;
    communityAddBtnProxy: React.RefObject<any>;
    communityTitleBar: React.RefObject<any>;
    query: { [key: string]: any };
    enableDeleteChannel: boolean;
    addChannelOption: boolean;
}

class ProxyTitleBar extends React.Component<Props, States> {
    titleBarMainRef: React.RefObject<any>;
    communityTitleBarRef: React.RefObject<any>;
    searchInput: React.RefObject<any>;
    communityAddBtnProxy: React.RefObject<any>;
    constructor(props) {
        super(props);
        var Title = props.title ? props.title : "";
        var Level1 = "";
        var Level2 = "";
        var Level3 = "";
        if (props["query"]) {
            if (props.query["level1"]) {
                if (props.query["level1"] != "mytoks" && props.query["level1"] != "mygroups") {
                    Level1 = props.query["level1"].toLowerCase().charAt(0).toUpperCase() + props.query["level1"].slice(1);
                } else if (props.query["level1"] == "mygroups") {
                    Level1 = "My Groups"
                }
            }
            if (props.query["level2"]) {
                if (props.query["level2"] != "private" && props.query["level2"] != "public" && props.query["level2"] != "All") {
                    Level2 = props.query["level2"].toLowerCase().charAt(0).toUpperCase() + props.query["level2"].slice(1);
                } else if (props.query["level1"] == "mygroups" && props.query["level2"] != "All") {
                    Level2 = "Owned"
                } else if (props.query["level1"] == "mygroups" && props.query["level2"] == "All") {
                    Level2 = "Joined"
                }
            }
            if (props.query["level3"]) {
                Level3 = props.query["level3"].toLowerCase().charAt(0).toUpperCase() + props.query["level3"].slice(1);
            }
        }
        if (props.levelTitle) {
            Title = textDisplay(Level1);
            if (Level2) {
                Title = Title + " - " + textDisplay(Level2);
                if (Level3) {
                    Title = Title + " - " + textDisplay(Level3);
                }
            }
        }
        this.state = {
            ShowFilter: false,
            ShowSort: false,
            ActiveFavorite: props.activeFavorite ? true : false,
            AddTokTitle: props.addTokTitle ? props.addTokTitle : "",
            DisableSearch: props.disableSearch ? true : false,
            DisableAddTok: props.disableAddTok ? true : false,
            Level1: Level1,
            Level2: Level2,
            Level3: Level3,
            Title: Title,
            bgColor: props.bgColor ? props.bgColor : "",
            fontColor: props.fontColor ? props.fontColor : "",
            addChannelOption: props.addChannelOption ? true : false,
            enableDeleteChannel: props.enableDeleteChannel ? true : false,
            activeLevel: props.activeLevel ? props.activeLevel : 0
        };
        this.titleBarMainRef = props.tokTileTitleBar;
        this.communityTitleBarRef = props.communityTitleBar;
        this.searchInput = createRef();
        this.communityAddBtnProxy = props.communityAddBtnProxy;

        this.setTokSearch = this.setTokSearch.bind(this);
        this.reload = this.reload.bind(this);
        this.showHideFilter = this.showHideFilter.bind(this);
        this.showHideSort = this.showHideSort.bind(this);
        this.addRemoveFavorite = this.addRemoveFavorite.bind(this);
        this.proxyFilterOption = this.proxyFilterOption.bind(this);
        this.proxySortOption = this.proxySortOption.bind(this);
        this.proxyAddTok = this.proxyAddTok.bind(this);
        this.proxyAddCommunity = this.proxyAddCommunity.bind(this);

        this.openAddChannel = this.openAddChannel.bind(this);
        this.openDeleteChannel = this.openDeleteChannel.bind(this);
    }
    openAddChannel() {
        var { activeLevel } = this.state;
        this.props.openAddChannel({ level: activeLevel + 1 });
    }
    openDeleteChannel() {
        this.props.openDeleteChannel(true);
    }
    setTokSearch() {
        const search = this.searchInput.current.querySelector("input").value;
        this.titleBarMainRef.current.querySelector(".search-field").querySelector("input").value = search;
        this.titleBarMainRef.current.querySelector(".search-btn").click();
    }

    reload() {
        if (this.communityTitleBarRef) {
            if (this.communityAddBtnProxy.current.style.display == "flex") {
                this.communityTitleBarRef.current.querySelector(".refresh-btn").click();
            } else {
                this.titleBarMainRef.current.querySelector(".refresh-btn").click();
            }
        } else {
            this.titleBarMainRef.current.querySelector(".refresh-btn").click();
        }
    }

    showHideFilter() {
        var { ShowFilter } = this.state;
        this.setState({
            ShowFilter: cloneDeep(!ShowFilter)
        });
    }

    showHideSort() {
        var { ShowSort } = this.state;
        this.setState({
            ShowSort: cloneDeep(!ShowSort)
        });
    }

    addRemoveFavorite() {
        var { ActiveFavorite } = this.state;
        try {
            this.props.addRemoveFavorite(!ActiveFavorite);
        } catch (err) { }
        this.setState({
            ActiveFavorite: cloneDeep(!ActiveFavorite)
        });
    }

    proxyFilterOption(e) {
        const target = e.currentTarget.getAttribute("data-target");
        const value = e.currentTarget.getAttribute("data-value");

        const refTarget = "." + target + "-" + value;
        this.titleBarMainRef.current.querySelector(".filter-options").querySelector(refTarget).click();
    };

    proxySortOption(e) {
        const target = e.target.getAttribute("data-target");
        const value = e.target.getAttribute("data-value");

        const refTarget = "." + target + "-" + value;
        this.titleBarMainRef.current.querySelector(".sort-options").querySelector(refTarget).click();
    };

    proxyAddTok(e) {
        this.titleBarMainRef.current.querySelector(".toktile-add-btn").click();
    }

    proxyAddCommunity(e) {
        this.communityTitleBarRef.current.querySelector(".add-community-btn").click();
    }

    render() {
        const { enableDeleteChannel, activeLevel, fontColor, addChannelOption, bgColor, ShowFilter, ShowSort, ActiveFavorite, AddTokTitle, DisableSearch, DisableAddTok, Level1, Level2, Level3, Title } = this.state;
        return (
            <>
                <Grid className={'text-color-white'} container spacing={1} style={{ backgroundColor: bgColor, color: fontColor, padding: "3px", marginTop: "10px" }}>
                    <div style={{ marginRight: "10px", display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "10px", fontSize: "21px" }}>
                        {textDisplay(Title)}
                    </div>
                    <div style={{ marginRight: !addChannelOption ? "auto" : "", display: "flex", alignItems: "center", paddingBottom: "5px" }}>
                        <Tooltip title={ActiveFavorite ? 'Remove to favorites' : 'Add to favorites'}>
                            <span onClick={this.addRemoveFavorite} style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faStar} size={'xl'} color={ActiveFavorite ? '#ffd100' : '#adb5bd'} />
                            </span>
                        </Tooltip>
                    </div>
                    {(addChannelOption && activeLevel > 0 && activeLevel < 3) || enableDeleteChannel ?
                        <div style={{ marginLeft: "5px", marginRight: "auto", display: "flex", alignItems: "center", paddingBottom: "10px" }}>
                            <Dropdown>
                                <Dropdown.Toggle className={'dropdown-remove proxy-hover'} style={{ backgroundColor: "unset", color: "black", border: "unset", padding: "unset" }}>
                                    <div style={{ border: "3px solid", borderColor: fontColor, height: "35px", width: "35px", display: "flex", alignItems: "center", borderRadius: "100%", justifyContent: "center", backgroundColor: fontColor }}>
                                        <FontAwesomeIcon icon={faEllipsis} size={"xl"} color={bgColor} />
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {activeLevel > 0 && activeLevel < 3 ?
                                        <Dropdown.Item onClick={this.openAddChannel}>Add Level {activeLevel + 1} Channel</Dropdown.Item> : ""
                                    }
                                    {enableDeleteChannel ?
                                        <Dropdown.Item onClick={this.openDeleteChannel}>Delete</Dropdown.Item> : ""
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> : ""
                    }
                    {!DisableSearch ?
                        <>
                            <div style={{ position: "relative" }}>
                                <TextField ref={this.searchInput} className={"text-field-default-color exception"} style={{ backgroundColor: "white", color: "black", borderRadius: "4px" }} label={`Search`} />
                            </div>
                            <ButtonReact variant='primary' onClick={this.setTokSearch} style={{ marginRight: "5px" }}>
                                <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faMagnifyingGlass} />
                            </ButtonReact>
                        </> : ""
                    }

                    {/* Temporary, will be converted to a scroll event */}
                    {Level1 != "mygroups" && !DisableAddTok ?
                        <ButtonReact variant="success" onClick={this.proxyAddTok} style={{ display: "flex", alignItems: "center" }}>
                            <div className={'parrot-write-icon'}></div>
                            <span>Add {AddTokTitle ? AddTokTitle : <>Tok</>}</span>
                        </ButtonReact> : ""
                    }

                    <ButtonReact className={"margin-right-btn"} variant="success" ref={this.communityAddBtnProxy} onClick={this.proxyAddCommunity} style={{ display: "none", marginLeft: "5px", alignItems: "center" }}>
                        <div className={'parrot-write-icon'}></div>
                        <span>Add Community</span>
                    </ButtonReact>
                    <div className={'refresh-btn'} style={{ marginLeft: "auto" }} onClick={this.reload}>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faRefresh} />
                    </div>
                    <div onClick={this.showHideFilter} style={{ display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
                        FILTER
                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faFilter} />
                    </div>
                    <div onClick={this.showHideSort} style={{ display: "flex", alignItems: "center", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
                        Sort
                        <FontAwesomeIcon style={{ cursor: "pointer" }} size={'xl'} icon={faArrowDownShortWide} />
                    </div>
                </Grid>
                {ShowFilter ?
                    <div className={'col-12'}>
                        <Row style={{ justifyContent: "center" }}>
                            {filterOptions.map((filterOption, index) => (
                                <Col md='auto' sm={6} xs={6}>
                                    <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{filterOption.displayText}</div>
                                    {filterOption.options.map((option) => (
                                        <div style={{ cursor: "pointer" }} onClick={this.proxyFilterOption} data-target={filterOption.value} data-value={option.value}>{option.displayText}</div>
                                    ))}
                                </Col>
                            ))}
                        </Row>
                    </div> : ""}
                {ShowSort ?
                    <div className={'col-12'}>
                        <Row style={{ justifyContent: "center" }}>
                            {sortOptions.map((sortOption, index) => (
                                <Col md='auto' sm={6} xs={6}>
                                    <div style={{ fontWeight: "bold", textTransform: "uppercase", marginBottom: "10px" }}>{sortOption.displayText}</div>
                                    <div style={{ cursor: "pointer" }} onClick={this.proxySortOption} data-target={sortOption.value} data-value={false}>Ascending</div>
                                    <div style={{ cursor: "pointer" }} onClick={this.proxySortOption} data-target={sortOption.value} data-value={true}>Descending</div>
                                </Col>
                            ))}
                        </Row>
                    </div> : ""}
            </>
        );
    }
}

export default ProxyTitleBar;